import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Grid, styled, Tooltip, tooltipClasses } from "@mui/material";
import styles from "../Dasr.module.css";
import DDML from "./DDML";
import SearchBar from "./SearchBar";
import ActiveFilters from "./ActiveFilters";
import {
  addingDate,
  calcBalanceCD,
  calcBalanceFC,
  calcBalanceFH,
  calculateBalanceDay,
  calculateCalendarBalance,
  calculateCalendarExpiryDate,
  formatDateDasr,
} from "pages/elevade/component/Helpers";
import MUIPagination from "pages/elevade/component/Pagination";
import { makeStyles } from "@mui/styles";
import { makeAPIRequestMDB } from "common/helpers/makeAPIRequestMDB";
import Overlay from "../../component/Overlay";
import NoData from "pages/elevade/component/DataUnavailable";
import IconAirplaneLimitation from "common/images/icons/elevade/Icon-AirplaneLimitation.svg";
import IconReInspection from "common/images/icons/elevade/Icon-ReInspection.svg";
import { CheckBoxField, CheckBoxFieldMock } from "pages/elevade/component/InputForm";
import ElevadeDrawer from "../component/Drawer";
import DialogApprove from "./DialogApprove";
import DateToggle from "../component/DateToggle";
import AirplaneLoader from "pages/elevade/component/AirplaneLoader";
import IconBackArrow from "common/images/icons/elevade/Icon-BackArrow.svg";
import { makeAPIRequestRun } from "common/helpers";
import { Helmet } from "react-helmet";

const ClosedAircraftReport = ({ aoc, isLoading, setIsLoading }) => {
  const { userPermission } = useSelector((state) => state.userEntity);
  const summaryPermission = userPermission.filter((perm) => perm.code === "DASR-004");
  const [subLoading, setSubLoading] = useState(true);
  const [type, setType] = useState("1");
  const [value, setValue] = useState("1");
  const [open, setOpen] = useState(false);
  const [openTitle, setOpenTitle] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState([]);
  const [dataForm, setDataForm] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [index, setIndex] = useState();
  const [edit, setEdit] = useState(true);
  const [openNotification, setOpenNotification] = useState(false);
  const [newForm, setNewForm] = useState(false);
  const [triggerApi, setTriggerApi] = useState(true);
  const [activeIndex, setActiveIndex] = useState("");
  const [selectToMove, setSelectToMove] = useState([]);
  const [openDialogApprove, setOpenDialogApprove] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [isSearch, setIsSearch] = useState([]);
  const InitialFilters = {
    search: [],
    location: [],
    aircraftReg: [],
    assignedTo: [],
    raisedDate: [],
    expiryDate: [],
    summaryStatus: [],
  };
  const [isFilters, setIsFilters] = useState(InitialFilters);
  const [filteredData, setFilteredData] = useState([]);
  const [date, setDate] = useState(new Date());
  const [today, setToday] = useState(new Date());
  const [isDate, setIsDate] = useState(new Date());
  const [tableLoading, setTableLoading] = useState(false);
  const [dataFHFC, setDataFHFC] = useState([]);
  const [countPending, setCountPending] = useState(0);
  const [countApproved, setCountApproved] = useState(0);
  const [filterOptions, setFilterOptions] = useState();
  const [queryFilter, setQueryFilter] = useState();

  let sliceStart = page > 0 ? perPage * (page - 1) : 0;

  useEffect(() => {
    setIsLoading(false);
    // eslint-disable-next-line
  }, []);

  function setInitialDate() {
    var currentDate = new Date();
    var options = { month: "long", day: "2-digit", year: "numeric" };
    var formattedDate = currentDate.toLocaleDateString("en-US", options);
    setIsDate(formattedDate);
    setToday(formattedDate);
    // Set yesterday's date
    currentDate.setDate(currentDate.getDate() - 1);
    // setYesterday(currentDate.toLocaleDateString("en-US", options));
  }

  useEffect(() => {
    setInitialDate();
  }, []);

  useEffect(() => {
    let theTab = "";
    if (value === "1") theTab = "closed";
    else if (value === "2") theTab = "approved";
    // setTotalRows(filteredData?.filter((e) => e.formStatus === theTab).length);
    // setTotalPage(Math.floor(filteredData?.filter((e) => e.formStatus === theTab).length / perPage) + 1);
  }, [filteredData, perPage, value]);

  useEffect(() => {
    fetch();
    fetchFilters();
    // TODO: to include "value" as dependencies when api ready
  }, [openNotification, triggerApi, aoc, date, value, page, perPage, queryFilter]);

  useEffect(() => {
    if (!open) setActiveIndex();
  }, [open]);

  useEffect(() => {
    setSelectToMove([]);
    setCheckAll(false);
  }, [value]);

  // Applying filters
  useEffect(() => {
    let search = "";
    let station = "";
    let aircraft_reg = "";
    let department = "";
    let raised_date = "";
    let expiry_date = "";
    if (isFilters.search.length > 0) {
      search = "&search_ddml=" + isFilters.search.join(",");
    }
    if (isFilters.location.length > 0) {
      station = "&station=" + isFilters.location.join(",");
    }
    if (isFilters.aircraftReg.length > 0) {
      aircraft_reg = "&aircraft_reg=" + isFilters.aircraftReg.join(",");
    }
    if (isFilters.assignedTo.length > 0) {
      department = "&department=" + isFilters.assignedTo.join(",");
    }
    if (isFilters.raisedDate.length > 0) {
      raised_date = "&raised_date=" + isFilters.raisedDate.join(",");
    }
    if (isFilters.expiryDate.length > 0) {
      expiry_date = "&expiry_date=" + isFilters.expiryDate.join(",");
    }
    let query = search + station + aircraft_reg + department + raised_date + expiry_date;
    if (query === "") setQueryFilter();
    else setQueryFilter(query);
    setPage(1);
  }, [isFilters]);

  useEffect(() => {
    if (selectToMove.length === 0) setCheckAll(false);
  }, [selectToMove]);

  async function fetchFHFC() {
    // setSubLoading(true);
    const fetch = makeAPIRequestRun(`get`, `acarsdata/fleetgetfcfh/null`);
    fetch
      .then((response) => {
        setDataFHFC(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  useEffect(() => {
    fetchFHFC();
  }, []);

  function formatDate(dateString) {
    // Parse the input date string
    const dateObj = new Date(dateString);

    // Get the year, month, and day components
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getDate()).padStart(2, "0");

    // Construct the formatted date string
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  }

  async function fetch(status) {
    const currentDate = new Date(isDate);
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const isToday = `${year}-${month}-${day}`;

    setTableLoading(true);

    let theTab = "";
    if (value === "1") theTab = "closed";
    else if (value === "2") theTab = "approved";
    let dateParams = ``;
    let dateColumn = ``;
    if (value === "2") {
      dateParams = `&date=${formatDate(date)}`;
      dateColumn = `&date_column=approvedAt`;
    }
    // if (theTab === "closed") dateParams = `&date=${isToday}`;
    // if (theTab === "closed") dateColumn = `&date_column=updatedAt`;

    function calculateTotal(obj) {
      let total = 0; // Variable to store the total sum
      Object.values(obj).forEach((value) => {
        total += value; // Add each value to the total
      });
      return total;
    }

    if (isToday !== "NaN-NaN-NaN") {
      let params = `api/ddml?aoc=${aoc}${dateParams}${dateColumn}&form_status=${theTab}&page=${page}&limit=${perPage}${
        queryFilter ? queryFilter : ""
      }&date=${formatDate(date)}&date_column=closedAt`;

      const fetch = makeAPIRequestMDB(`get`, params);
      fetch
        .then((response) => {
          setData(response.data?.ddmls_list);
          setFilteredData(response.data?.ddmls_list?.filter((e) => e.formStatus === theTab));
          setTotalRows(calculateTotal(response.data.count_by_form_type));
          setTotalPage(Math.floor(calculateTotal(response.data.count_by_form_type) / perPage) + 1);
          setSubLoading(false);
          setOpen(false);
          setOpenNotification(false);
          setTriggerApi(false);
          setTableLoading(false);
        })
        .catch((error) => {
          console.log("error", error);
          setSubLoading(false);
        });
    }
  }

  async function fetchFilters() {
    const collection_name = "ddml";
    const form_status = "closed";
    const filter_fields = "location&filter_fields=aircraftReg&filter_fields=assignedTo";
    const fetch = makeAPIRequestMDB(
      `get`,
      `/api/ddml/collection_filter_list_data?collection_name=${collection_name}&aoc=${aoc}&form_status=${form_status}&filter_fields=${filter_fields}`
    );
    fetch
      .then((response) => {
        setFilterOptions(response.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  useEffect(() => {
    let theTab = "";
    if (value === "1") theTab = "closed";
    else if (value === "2") theTab = "approved";
    let filteredData = data?.filter((e) => e.formStatus === theTab);
    setFilteredData(filteredData);
    if (value === "1") setCountPending(filteredData.length);
  }, [value, data]);

  // Custom styles
  const useStyles = makeStyles(() => ({
    ul: {
      "& .Mui-selected": {
        color: "#fff",
        backgroundColor: "#088FD1",
        border: "1px solid #088FD1",
        "&:hover": {
          backgroundColor: "#088FD1",
        },
      },
    },
    gridItem: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-box-orient": "vertical",
      "-webkit-line-clamp": 2, // Number of lines to show
      fontSize: "14px",
    },
    gridItemStatus: {
      display: "-webkit-box",
      WebkitLineClamp: 2,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "pre",
    },
  }));

  const classes = useStyles();

  const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme, ...props }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#FFFEE8",
        color: "rgba(0, 0, 0, 0.87)",
        boxShadow: theme.shadows[1],
        fontSize: 11,
        whiteSpace: props.breakLine || "normal",
      },
    })
  );

  const AircraftReportList = ({ type }) => {
    return (
      <>
        <Grid container columns={4} sx={{ marginTop: "0px" }}>
          {/* <Grid item xs={4} className={styles.gridTitle}>
            {type} List
          </Grid> */}
          {/* {data?.length > 0 && ( */}
          <>
            <Grid item xs={4} sx={{ paddingTop: "32px", paddingLeft: "16px" }}>
              <SearchBar
                open={openModal}
                setOpen={setOpenModal}
                data={filterOptions}
                isSearch={isSearch}
                setIsSearch={setIsSearch}
                isFilters={isFilters}
                setIsFilters={setIsFilters}
                openTitle={openTitle}
                setOpenTitle={setOpenTitle}
              />
              <ActiveFilters
                isFilters={isFilters}
                setIsFilters={setIsFilters}
                initialFilters={InitialFilters}
                setOpen={setOpenModal}
              />
            </Grid>
            <Grid item xs={4} sx={{ paddingTop: "16px" }} onClick={() => setOpenModal(false)}>
              {subLoading ? <Overlay /> : <TableList type={type} />}
            </Grid>
          </>
          {/* )} */}
          {filteredData?.length === 0 && !subLoading && (
            <Grid item xs={4} sx={{ display: "flex", justifyContent: "center" }}>
              <NoData />
            </Grid>
          )}
          {tableLoading && (
            <Grid item xs={4} sx={{ display: "flex", justifyContent: "center" }}>
              <AirplaneLoader />
            </Grid>
          )}
        </Grid>
      </>
    );
  };

  function handleClick(e, index) {
    setOpen(true);
    setIndex(index);
    setDataForm(e);
    setEdit(false);
    setActiveIndex(index);
    setNewForm(false);
  }

  function handleSelect() {
    if (checkAll) return <CheckBoxFieldMock isCheckedAll={true} setIsCheckedAll={setCheckAll} />;
    else if (selectToMove.length > 0 && selectToMove.length < totalRows) {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          onClick={() => setCheckAll(!checkAll)}
          style={{ cursor: "pointer" }}
        >
          <rect width="20" height="20" rx="4" fill="#088FD1" />
          <path d="M6 10L14 10" stroke="white" stroke-width="3" stroke-linecap="round" />
        </svg>
      );
    } else {
      return summaryPermission.some((perm) => perm.canUpdate === true) ? (
        <CheckBoxFieldMock
          isCheckedAll={selectToMove.length > 0 && totalRows > 0 && selectToMove.length === totalRows}
          setIsCheckedAll={setCheckAll}
        />
      ) : (
        <CheckBoxField id={""} selected={selectToMove} setSelected={setSelectToMove} disabled={true} />
      );
    }
  }

  useEffect(() => {
    if (checkAll) {
      handleCheckAll();
    } else handleUnCheckAll();
    // eslint-disable-next-line
  }, [checkAll]);

  useEffect(() => {
    if (selectToMove.length > 0 && totalRows > 0 && selectToMove.length === totalRows) setCheckAll(true);
  }, [selectToMove, totalRows]);

  function handleCheckAll() {
    let array = [];

    filteredData.map((e) => {
      return array.push(e._id);
    });
    setSelectToMove(array);
  }

  function handleUnCheckAll() {
    setSelectToMove([]);
  }

  const formattedDate = today;

  function calculateBalance(e, dataFHFC, formattedDate) {
    const getCurrentFlightInfo = (reg, type) => dataFHFC.find((item) => item.AC_REG === reg)?.[type];
    const calculateExpiryDate = () => calculateCalendarExpiryDate(e.calendarDaysStartDate, e.calendarDaysInterval);
    const isFormType = (type) => e.formType === type;

    let calendarDaysInterval = e.calendarDaysInterval || 0;
    let calendarDaysStartDate = e.calendarDaysStartDate || e.raisedDate;
    let expiry = addingDate(calendarDaysStartDate, calendarDaysInterval);
    let date1 = isFormType("AMD") ? e.calendarDaysStartDate : formattedDate?.split("T")[0];
    let date2 = expiry;
    let balance = calculateBalanceDay(date1, date2);
    let currentFlightHour = getCurrentFlightInfo(e.aircraftReg, "flight_hour");
    let currentFlightCycle = getCurrentFlightInfo(e.aircraftReg, "flight_cycle");

    if (e.showInTable === "fh" && e.flightHours && isFormType("STR/FC/FH")) {
      balance = calcBalanceFH(e.flightHoursInterval, e.flightHoursRaisedAt, currentFlightHour);
    } else if (e.showInTable === "fc" && e.flightCycle && isFormType("STR/FC/FH")) {
      balance = calcBalanceFC(e.flightCycleInterval, e.flightCycleRaisedAt, currentFlightCycle);
    } else if (e.showInTable === "" && isFormType("STR/FC/FH")) {
      balance = "-";
    }

    let fcfhIndicator = " (FC)";
    if (e.showInTable === "fh" && e.flightHours) fcfhIndicator = " (FH)";
    else if (e.showInTable === "fc" && e.flightCycle) fcfhIndicator = " (FC)";

    if (!e.flightCycle && !e.flightHours && !e.calendarDays && isFormType("STR/FC/FH")) {
      balance = "-";
    }

    if (isFormType("AMD") || isFormType("Fuel Sampling") || isFormType("Hydraulic Sampling")) {
      balance = calculateCalendarBalance(e.calendarDaysStartDate, e.calendarDaysInterval);
    }

    let expiryDate_ = formatDateDasr(e.expiryDate);
    if (!isFormType("STR/FC/FH")) {
      expiryDate_ = calculateExpiryDate();
    }

    if (e.calendarDays) balance = calcBalanceCD(calendarDaysStartDate, calendarDaysInterval);

    let currentDate = new Date();
    let timeDifferenceMs = new Date(e.expiryDate) - currentDate;
    let remainingDays = Math.ceil(timeDifferenceMs / (1000 * 60 * 60 * 24));

    let balanceSTR = !isNaN(parseInt(balance)) && balance !== "-" ? balance + fcfhIndicator : "-";
    if (e.calendarDays) balanceSTR = balance + " Days";

    let color = "";
    if (isFormType("STR/FC/FH")) {
      if (e.flightHours && balance <= 20) color = "#E95048";
      else if (e.calendarDays && balance <= 3) color = "#E95048";
    } else if (isFormType("MEL CAT") && remainingDays <= 3) {
      color = "#E95048";
    } else if (remainingDays <= 3) {
      color = "#E95048";
    }

    return { balance, balanceSTR, color };
  }

  const TableList = ({ type }) => {
    // const status = value === "1" ? "closed" : "approved";
    return (
      !tableLoading &&
      filteredData.length > 0 && (
        <>
          <div
            style={{
              width: "100%",
              borderRadius: "12px",
              overflow: "hidden",
              border: "1px solid #E8E8E8",
            }}
          >
            <Grid container columns={13}>
              <Grid item xs={0.3} className={styles.tableHeader}></Grid>
              <Grid item xs={0.5} className={styles.tableHeader}>
                Station
              </Grid>
              <Grid item xs={1} className={styles.tableHeader}>
                A/C Reg.
              </Grid>
              <Grid item xs={3.2} className={`${styles.tableHeader} ${styles.gridJustifyStart}`}>
                Defect Description
              </Grid>
              <Grid item xs={1} className={styles.tableHeader}>
                DDML No.
              </Grid>
              <Grid item xs={1} className={styles.tableHeader}>
                Ref.
              </Grid>
              <Grid item xs={1} className={styles.tableHeader}>
                Raised Date
              </Grid>
              <Grid item xs={1} className={styles.tableHeader}>
                Expiry/Balance
              </Grid>
              <Grid item xs={1} className={styles.tableHeader}>
                Closed By
              </Grid>
              <Grid item xs={3} className={`${styles.tableHeader} ${styles.gridJustifyStart}`}>
                Rectification Status
              </Grid>
              {filteredData &&
                filteredData
                  // .filter((e) => e.formStatus === type)
                  .map((e, index) => {
                    const { balance, balanceSTR, color } = calculateBalance(e, dataFHFC, formattedDate);
                    let status = getRectificationStatus(e);
                    return (
                      <>
                        <Grid item xs={13}>
                          <Grid
                            container
                            columns={13}
                            className={`${styles.lineItems} ${activeIndex === index ? styles.selectedIndex : ""}`}
                            sx={{ zIndex: 1 }}
                          >
                            <Grid item xs={0.3} className={`${styles.tableBody}`} onClick={() => handleClick(e, index)}>
                              <LightTooltip
                                title={e.limitations ? "Limitation triggered" : ""}
                                followCursor
                                placement="bottom-start"
                              >
                                {e.limitations && (
                                  <img
                                    src={IconAirplaneLimitation}
                                    alt={"warning-circle"}
                                    height={20}
                                    style={{ marginLeft: "4px" }}
                                  />
                                )}
                              </LightTooltip>
                              <LightTooltip
                                title={e.reInspection ? "Reinspection" : ""}
                                followCursor
                                placement="bottom-start"
                              >
                                {e.reInspection && (
                                  <img
                                    src={IconReInspection}
                                    alt={"icon-reinspection"}
                                    height={20}
                                    style={{ marginLeft: "4px" }}
                                  />
                                )}
                              </LightTooltip>
                            </Grid>
                            <Grid
                              item
                              xs={0.5}
                              className={`${styles.tableBody} ${styles.toUpperCase}`}
                              onClick={() => handleClick(e, index)}
                            >
                              {e.location}
                            </Grid>
                            <Grid
                              item
                              xs={1}
                              className={`${styles.tableBody} ${styles.toUpperCase}`}
                              onClick={() => handleClick(e, index)}
                            >
                              {e.aircraftReg}
                            </Grid>
                            <Grid
                              item
                              xs={3.2}
                              className={`${styles.tableBody} ${styles.toUpperCase}`}
                              sx={{ display: "flex", justifyContent: "flex-start" }}
                              onClick={() => handleClick(e, index)}
                            >
                              <LightTooltip title={e.defectDesc} followCursor placement="bottom-start">
                                <div className={classes.gridItem}>{e.defectDesc}</div>
                              </LightTooltip>
                            </Grid>
                            <Grid
                              item
                              xs={1}
                              className={`${styles.tableBody} ${styles.toUpperCase}`}
                              onClick={() => handleClick(e, index)}
                            >
                              {e.ddmlNo}
                            </Grid>
                            <Grid
                              item
                              xs={1}
                              className={`${styles.tableBody} ${styles.toUpperCase}`}
                              onClick={() => handleClick(e, index)}
                            >
                              <center>{e.melRef}</center>
                            </Grid>
                            <Grid item xs={1} className={`${styles.tableBody}`} onClick={() => handleClick(e, index)}>
                              <center>{formatDateDasr(e.raisedDate)}</center>
                            </Grid>
                            <Grid
                              item
                              xs={1}
                              className={`${styles.tableBody}`}
                              sx={{
                                color: color,
                              }}
                              onClick={() => handleClick(e, index)}
                            >
                              <center>
                                {e.formType === "STR/FC/FH"
                                  ? balanceSTR
                                  : e.formType === "MEL CAT" || type === "AMD"
                                  ? formatDateDasr(e.expiryDate)
                                  : calculateCalendarExpiryDate(e.calendarDaysStartDate, e.calendarDaysInterval)}
                              </center>
                            </Grid>
                            <Grid
                              item
                              xs={1}
                              className={`${styles.tableBody} ${styles.toCapitalize} ${styles.flexCenter}`}
                              onClick={() => handleClick(e, index)}
                            >
                              {e?.closedByFullname}
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              className={`${styles.tableBody} ${styles.toUpperCase} ${styles.flexStart}`}
                              sx={{ paddingRight: "20px" }}
                              onClick={() => handleClick(e, index)}
                            >
                              <LightTooltip
                                title={status}
                                followCursor
                                placement="bottom-start"
                                breakLine={/\r|\n/.test(status) ? "pre-wrap" : "wrap"}
                              >
                                <div className={/\r|\n/.test(status) ? classes.gridItemStatus : classes.gridItem}>
                                  {status}
                                </div>
                              </LightTooltip>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    );
                  })}
              <Grid item xs={13} className={`${styles.tableBodyWhite}`}>
                <MUIPagination
                  page={parseInt(page)}
                  setPage={setPage}
                  setPerPage={setPerPage}
                  totalPage={parseInt(totalPages)}
                  classes={{ ul: classes.ul }}
                  totalRows={parseInt(totalRows)}
                  sliceStart={sliceStart}
                  initialPerPage={perPage}
                />
              </Grid>
            </Grid>
          </div>
        </>
      )
    );
  };

  function getRectificationStatus(item) {
    // Check for active Summary Status selection
    if (isFilters.summaryStatus.length > 0) {
      let status =
        isFilters.summaryStatus[0] === "Rectification Status"
          ? item?.updateStatusLog?.rectificationUpdate
          : isFilters.summaryStatus[0] === "Material Summary"
          ? item?.updateStatusLog?.materialUpdate
          : isFilters.summaryStatus[0] === "Planning/Tech Service"
          ? item?.updateStatusLog?.ppcUpdate
          : isFilters.summaryStatus[0] === "MOC Remarks"
          ? item?.mocHighlight
          : item?.actionBy145 || item?.updateStatusLog?.rectificationUpdate;
      return status;
    } else {
      if (item?.statusLog?.pushToSummary === "") return undefined;
      else {
        return item?.statusLog?.updateLogDesc;
      }
    }
  }

  return !isLoading && !subLoading ? (
    <>
      <div id="main" className={`${styles.container}`}>
        <>
          <Helmet>
            <title>Royal Brunei Airlines - ELEVADE - Closed Aircraft Reports</title>
          </Helmet>
          <Grid container columns={8.2} className={styles.gridContainer}>
            <Grid item xs={4.2} className={styles.gridTitle} onClick={() => setOpenModal(false)}>
              <Link to={"/fleet/dasr"}>
                <img src={IconBackArrow} alt="icon-back" style={{ marginRight: "14px", marginTop: "-4px" }} />
                Closed Aircraft Reports
              </Link>
            </Grid>
            <Grid
              item
              xs={4}
              className={styles.gridJustifyEnd}
              sx={{ fontSize: "14px", paddingBottom: "16px", minHeight: "48px" }}
              onClick={() => setOpenModal(false)}
            >
              <DateToggle date={date} setDate={setDate} today={today} setToday={setToday} />
            </Grid>
            <Grid item xs={8.2} sx={{ paddingTop: "10px" }}>
              {isLoading && <AirplaneLoader />}
              <AircraftReportList type={"closed"} />
            </Grid>
          </Grid>
        </>
      </div>
      <ElevadeDrawer
        open={open}
        setOpen={setOpen}
        data={data}
        Component={DDML}
        aoc={aoc}
        type={type}
        index={index}
        dataForm={dataForm}
        edit={edit}
        setEdit={setEdit}
        openNotification={openNotification}
        setOpenNotification={setOpenNotification}
        newForm={newForm}
        setNewForm={setNewForm}
        triggerApi={triggerApi}
        setTriggerApi={setTriggerApi}
        setSelectToMove={setSelectToMove}
        hideButton={true}
      />
    </>
  ) : (
    <AirplaneLoader />
  );
};

export default ClosedAircraftReport;
