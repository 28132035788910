import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, IconButton, MenuItem, Pagination, Paper, Select, Skeleton, Typography } from '@mui/material';
import { Fragment } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { tableHeaderBackgroundColor } from 'pages/elevade/cabinMonitoring/js/constants/styles';


/**
 * @typedef {Object} NestedOptionsProps
 * @property {string} propRefName
 * @property {boolean} nestedEnabled
 * @property {Function} onClickRowNested
 * @property {Function} itemDisplayFormatter
 */

/**
 * @typedef {Object} GeneralTableProps
 * @property {Object[]} rows
 * @property {Object[]} [raws]
 * @property {string[]} headers
 * @property {number} totalCount
 * @property {Function} onClickRow
 * @property {Function} onChangeRowPerPage
 * @property {Function} onChangePage
 * @property {boolean} isLoading
 * @property {boolean} paginationEnabled
 * @property {NestedOptionsProps} [nestedOptions]
 */

/**
 * General Table Properties
 * @param {GeneralTableProps} props 
 */

export default function GeneralTable(props) {

	const {
		rows = [], // object[]
		headers = [], // string[]
		totalCount,
		onClickRow,
		onChangeRowPerPage,
		onChangePage,
		raws = [],
		isLoading = false,
		nestedOptions,
		paginationEnabled = true,
	} = props ?? {};

	const [page, setPage] = React.useState(1);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);

	const handleChangePage = (newPageValue) => {
		setPage(newPageValue);
		onChangePage(newPageValue);
	};

	const handleChangeRowsPerPage = (value) => {
		onChangeRowPerPage(value)
		setRowsPerPage(value);
		setPage(1);
	};

	if (isLoading) {
		return <LoadingTable itemsPerPage={rowsPerPage} />
	}

	return (
		<div sx={{ width: '100%', overflow: 'hidden' }}>

			<TableContainer
				sx={{
					boxShadow: "none",
					borderRadius: "12px",
					border: "1px solid #E8E8E8",
				}}
			>
				<Table stickyHeader aria-label="sticky table">
					<TableHead>
						<TableRow
							sx={{ backgroundColor: "#F2F7FF", }}>
							{headers.map((header) => {

								if (nestedOptions && nestedOptions?.nestedEnabled && nestedOptions?.propRefName === header) {
									return null
								}
								return (
									<TableCell
										// key={header?.label}
										// align={header?.align}
										//   style={{ minWidth: column.minWidth }}
										sx={{ backgroundColor: "#F2F7FF", }}
									>
										{header}
									</TableCell>
								)
							})}
						</TableRow>
					</TableHead>
					<TableBody>
						{rows && rows.length < 1 && (
							<TableRow>
								<TableCell colSpan={9} component="th" scope="row">
									No record(s) found.
								</TableCell>
							</TableRow>
						)}
						{rows && rows?.map && rows?.map((row, index) => {

							const firstDisplayItem = nestedOptions?.itemDisplayFormatter ? nestedOptions?.itemDisplayFormatter(row[nestedOptions?.propRefName]?.[0]) : row[nestedOptions?.propRefName]?.[0]
							const innerHeaders = Object.keys(firstDisplayItem ?? {})

							return (
								<Fragment>
									{
										(nestedOptions && nestedOptions?.nestedEnabled) ? (
											<ExpendableTableRowWithInnerTable
												innerTableProps={{
													headers: innerHeaders,
													onClickRow: nestedOptions?.onClickRowNested,
													rows: nestedOptions?.itemDisplayFormatter ? row[nestedOptions?.propRefName].map((item) => nestedOptions?.itemDisplayFormatter(item)) : row[nestedOptions?.propRefName],
													raws: raws[index][nestedOptions?.propRefName],
													paginationEnabled: false,
												}}
												rowProps={{
													onClickRow: onClickRow,
													row,
													raws,
													index,
													nestedOptions: nestedOptions,
												}}
											/>
										) : (
											<CustomTableRow
												onClickRow={onClickRow}
												row={row}
												nestedOptions={nestedOptions}
												raws={raws}
												index={index}
											/>
										)
									}
								</Fragment>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
			{paginationEnabled && (
				<TablePaginationCustom
					// rowsPerPageOptions={[10, 25, 100]}
					// component="div"
					// count={rows.length}
					// rowsPerPage={rowsPerPage}
					itemsPerPage={rowsPerPage}
					setItemsPerPage={handleChangeRowsPerPage}
					curPage={page}
					setCurPage={handleChangePage}
					numOfPages={Math.ceil(totalCount / rowsPerPage)}
				// onPageChange={handleChangePage}
				// onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			)}
		</div>
	);
}

/**
 * @typedef {Object} CustomTableRowProps
 * @property {string} onClickRow
 * @property {Object} row
 * @property {Object[]} raws
 * @property {number} index
 * @property {NestedOptionsProps} nestedOptions
 * @property {import('react').ReactNode} [firstColumnIcon]
 */
/**
 * CustomTableRow params
 * @param {Object} props 
 */
export function CustomTableRow(props) {

	const {
		onClickRow,
		row,
		nestedOptions,
		firstColumnIcon,
		raws,
		index,
	} = props

	return (
		<TableRow
			hover
			role="checkbox"
			onClick={() => {
				if (onClickRow) {
					// onClickRow(row)
					onClickRow(raws ? raws[index] : row)
				}
			}}
			sx={{
				cursor: onClickRow ? 'pointer' : 'default',
			}}
		>
			{Object.keys(row)?.map((key, index) => {
				const value = row[key] ?? '-'
				if (nestedOptions && nestedOptions?.nestedEnabled && nestedOptions?.propRefName === key) {
					return null
				} else if (!isValidReactChild(value)) {
					return (
						<TableCell>
							<Typography sx={{ fontStyle: "italic" }}>
								Unable to render this data
							</Typography>
						</TableCell>
					)
				} else {
					return (
						<TableCell
						// key={column.id}
						// align={column.align}
						>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
									justifyContent: 'flex-start',
								}}
							>
								{(firstColumnIcon && index === 0) && (
									firstColumnIcon
								)}
								{value}
							</div>
						</TableCell>
					);
				}
			})}

		</TableRow>
	)
}

/**
 * @typedef {Object} ExpendableTableRowWithInnerTableProps
 * @property {GeneralTableProps} innerTableProps
 * @property {CustomTableRowProps} rowProps
 */
/**
 * General Table Properties
 * @param {ExpendableTableRowWithInnerTableProps} props
 */
export function ExpendableTableRowWithInnerTable(props) {

	const {
		innerTableProps,
		rowProps,
	} = props

	const [isExpanded, setIsExpanded] = useState(false)

	return (
		<Fragment>
			<CustomTableRow
				{...rowProps}
				firstColumnIcon={
					<IconButton
						sx={{ padding: '0px', marginRight: '10px', height: '14px', width: '14px' }}
						onClick={() => {
							setIsExpanded(!isExpanded)
						}}>
						{isExpanded ? (<ExpandLessIcon sx={{ padding: '0px' }} />) : (<ExpandMoreIcon sx={{ padding: '0px' }} />)}
					</IconButton>
				}
			/>
			{isExpanded && (
				<TableRow>
					<TableCell colSpan={999} >
						<GeneralTable
							{...innerTableProps}
						/>
					</TableCell>
				</TableRow>
			)}
		</Fragment>
	)
}

export function LoadingTable({ itemsPerPage = 10 }) {
	return (
		<TableContainer
			component={Paper}
			sx={{
				boxShadow: "none",
				borderRadius: "12px",
				border: "1px solid #E8E8E8",
			}}
		>
			<Table>
				<TableBody>
					<TableRow
						sx={{
							backgroundColor: tableHeaderBackgroundColor,
						}}
					>
						<TableCell
							sx={{
								paddingTop: "16px",
								paddingBottom: "16px",
							}}
							colSpan={100}
							component="th"
							scope="row"
						>
							<Skeleton
								sx={{ height: "20px" }}
								animation="wave"
								variant="text"
							/>
						</TableCell>
					</TableRow>
					{Array.apply(null, { length: itemsPerPage })
						?.map(Number.call, Number)
						?.map((item) => {
							return (
								<TableRow
									key={`loadingTable-tablerow-${item}`}
								>
									<TableCell
										sx={{
											paddingTop: "16px",
											paddingBottom: "16px",
										}}
										colSpan={100}
										component="th"
										scope="row"
									>
										<Skeleton
											sx={{ height: "20px" }}
											animation="wave"
											variant="text"
										/>
									</TableCell>
								</TableRow>
							);
						})}
				</TableBody >
			</Table>
		</TableContainer>
	)
}

/**
 * @typedef {Object} TablePaginationCustomProps
 * @property {number} curPage (page)
 * @property {Function} setCurPage 
 * @property {number} itemsPerPage (limit)
 * @property {Function} setItemsPerPage
 * @property {number} numOfPages
 */
/**
 * @param {TablePaginationCustomProps} props
 * @returns {React.ReactNode} table
 */
function TablePaginationCustom(props) {

	const {
		curPage,
		itemsPerPage,
		setCurPage,
		setItemsPerPage,
		numOfPages,
	} = props

	return (
		<Fragment>
			<Box
				sx={{
					width: "100%",
					display: "flex",
					flexDirection: "row",
					justifyContent: "right",
					marginTop: "1em",
				}}
			>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						marginRight: "1em",
					}}
				>
					Rows Per Page
				</Box>
				<Select
					sx={{
						padding: "0px",
						"& .MuiSelect-select": {
							padding: "0px",
							paddingLeft: "1em",
						},
						marginRight: "1em",
					}}
					value={itemsPerPage}
					onChange={(e) => {
						setItemsPerPage(e.target.value)
					}}
				>
					{['1', "10", "20", "50", "100"].map((pageNumber) => (
						<MenuItem
							sx={{
								padding: "0px",
							}}
							key={pageNumber}
							value={pageNumber}
						>
							{pageNumber}
						</MenuItem>
					))}
				</Select>
				<Pagination
					sx={{
						"& .Mui-selected": {
							background: "#0AAEFF",
							color: "#FFFFFF",
						},
						"& .Mui-selected:hover": {
							background: "#0AAEFF",
							color: "#FFFFFF",
						},
					}}
					page={curPage}
					count={numOfPages}
					onChange={(e, value) => {
						setCurPage(value)
					}}
					variant="outlined"
					shape="rounded"
				/>
			</Box>
			<Box sx={{ width: "100%", height: "2em" }} />
		</Fragment>
	)
}

/**
 * check if the value is renderable by react
 * @param {any} value 
 * @returns {boolean} true/false
 */
function isValidReactChild(value) {
	if (React.isValidElement(value)) {
		return true
	}
	return (
		value !== null &&
		value !== undefined &&
		typeof value !== 'boolean' &&
		typeof value !== 'object'
	);
}