
import React from "react";
// import Lottie from "lottie-react";
// import loadingAP from "./../../components/loading/airplane.json";
import AirplaneLoader from "pages/elevade/component/AirplaneLoader";
const AirplaneLoading = () => {

	return <AirplaneLoader/>

	// return <Lottie animationData={loadingAP} loop={true} />;
};
export default AirplaneLoading;
