import { HttpPostEntity } from "common/library/apiAxiosWrapper";
import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import _ from "lodash";

export const getToken = createAsyncThunk("auth/getToken", async ({ token, history }) => {
  try {
    const result = await HttpPostEntity({
      url: `/api/v1/token/verify`,
      body: { token },
      baseUrl: process.env.REACT_APP_ENTITY_API_URL,
    });

    const tokens = result?.data?.tokens;
    const profile = result?.data?.profile;

    // If tokens are empty or undefined, clear cookies, local storage and redirect to '/fleet'
    if (_.isEmpty(tokens)) {
      const cookiesToRemove = [
        "elevadeToken",
        "elevadeAvatar",
        "elevadeRefreshToken",
        "fullname-url-elevade",
        "cabin-mon-token-fm-atj",
      ];

      cookiesToRemove.forEach(Cookies.remove);
      localStorage.clear();
      history.push("/fleet");
    } else {
      // If an avatar exists in the profile, store it in local storage
      if (profile?.avatar) {
        localStorage.setItem("avatar", profile.avatar);
        localStorage.setItem("displayName", profile.name ?? "");
      }

      const avatar = localStorage.getItem("avatar");

      // If tokens exist and are not empty, prepare the response data
      if (!_.isEmpty(tokens)) {
        const responseData = {
          userName: profile?.email ?? "",
          token: tokens.token ?? "",
          avatar: profile?.avatar ?? avatar,
          displayName: profile?.name ?? "",
        };

        return responseData;
      } else {
        history.push("/fleet");
      }
    }
  } catch (err) {
    // Throw a new error with an appropriate message
    const errorMessage = err?.message ?? err?.response?.data?.message ?? "Login Failed";
    throw new Error(errorMessage);
  }
});

export const logOut = createAsyncThunk("auth/logout", async () => {
  try {
    return true;
  } catch (err) {
    throw new Error("Logout failed");
  }
});
