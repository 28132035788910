
import React from 'react';
import { Box, Typography } from "@mui/material";
import { gothamMedium, gothamNormal } from "../../../../constants/fonts";
import { commonFontSizes } from "../../../../constants/styles";
import CustomToolTip from "../../../components/tooltips/CustomToolTip";
import {
	ActiveWidget,
	MissingWidget,
	ErrorWidget,
	ExpiredWidget,
	NearExpiryWidget,
	UnutilizedWidget,
} from "./icons";

/**
 * @typedef {Object} WidgetPlaneStatusMainPropsData
 * @property {string} latestLocation
 * @property {string} engineType
 * @property {number} totalActive
 * @property {number} totalLost
 * @property {number} totalTagError
 * @property {number} totalExpired
 * @property {number} totalRemind
 * @property {number} totalUnutilisedSeat
 * @property {number} totalStatus
 */
/**
 * @typedef {Object} WidgetPlaneStatusMainProps
 * @property {string} title
 * @property {WidgetPlaneStatusMainPropsData} data
 * @property {boolean} isLoading
 */

/**
 * @param {WidgetPlaneStatusMainProps} props
 */
export default function WidgetPlaneStatusMain(props) {

	const { title, data, isLoading } = props

	const totalIsLoading = isLoading;
	const {
		latestLocation,
		engineType,
		totalActive,
		totalLost,
		totalTagError,
		totalExpired,
		totalRemind,
		totalUnutilisedSeat,
		totalStatus,
	} = data;
	return (
		<Box
			sx={{
				border: "1px solid #E8E8E8",
				borderRadius: "12px",
				paddingTop: "16px",
				paddingLeft: "16px",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					paddingLeft: "10px",
				}}
			>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<svg
						width="18"
						height="18"
						viewBox="0 0 18 18"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M9 15.875L5.875 17.125V14.625L7.125 13.375V10.875L0.875 12.125V9.625L7.125 6.5V2.75C7.125 2.25272 7.32254 1.77581 7.67417 1.42417C8.02581 1.07254 8.50272 0.875 9 0.875C9.49728 0.875 9.97419 1.07254 10.3258 1.42417C10.6775 1.77581 10.875 2.25272 10.875 2.75V6.5L17.125 9.625V12.125L10.875 10.875V13.375L12.125 14.625V17.125L9 15.875Z"
							stroke="#394458"
							stroke-width="1.5"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
					<Typography
						variant="body1"
						sx={{
							fontFamily: gothamMedium,
							// fontWeight: "bold",
							// flexGrow: 1,
							display: "flex",
							flexDirection: "row",
							// justifyContent:'center',
							alignItems: "center",
							fontSize: commonFontSizes.m,
							paddingLeft: "10px",
						}}
					>
						{title ?? "N/A"}
					</Typography>
				</Box>

				<CustomToolTip title={"Engine Type"} marginLeft="44px">
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							marginLeft: "44px",
						}}
					>
						<svg
							width="16"
							height="16"
							viewBox="0 0 16 16"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<g clip-path="url(#clip0_20171_56492)">
								<path
									d="M10.5935 12.5403C9.75293 12.4231 9.02284 11.8173 8.78934 10.9459L8.45117 9.68377M9.50951 8.87151L10.6412 9.52489C11.4217 9.97551 12.3651 9.88825 13.0422 9.37815M7.54883 6.31622L7.21065 5.05413C6.97715 4.18271 6.24709 3.57693 5.40645 3.4597M6.49049 7.12849L5.35882 6.47511C4.57833 6.02449 3.6349 6.11175 2.95776 6.62185M12.5403 5.40646C12.4231 6.24707 11.8173 6.97716 10.9459 7.21066L9.68377 7.54883M6.31623 8.45117L5.05411 8.78934C4.18269 9.02284 3.57694 9.75293 3.45971 10.5935M9.37816 2.95774C9.88826 3.63488 9.97552 4.57832 9.5249 5.3588L8.87152 6.49048M7.12849 9.50951L6.47511 10.6412C6.02449 11.4217 6.11175 12.3651 6.62185 13.0422M15 8C15 11.8499 11.8499 15 8 15C4.15006 15 1 11.8499 1 8C1 4.15006 4.15006 1 8 1C11.8499 1 15 4.15006 15 8ZM8 13.2282C5.11699 13.2282 2.77178 10.883 2.77178 8C2.77178 5.11699 5.11699 2.77178 8 2.77178C10.883 2.77178 13.2282 5.11699 13.2282 8C13.2282 10.883 10.883 13.2282 8 13.2282ZM9.74274 8C9.74274 8.96249 8.96249 9.74274 8 9.74274C7.03751 9.74274 6.25726 8.96249 6.25726 8C6.25726 7.03751 7.03751 6.25726 8 6.25726C8.96249 6.25726 9.74274 7.03751 9.74274 8Z"
									stroke="#394458"
									stroke-width="0.8"
									stroke-miterlimit="10"
								/>
							</g>
							<defs>
								<clipPath id="clip0_20171_56492">
									<rect width="16" height="16" fill="white" />
								</clipPath>
							</defs>
						</svg>
						<Typography
							sx={{
								fontFamily: gothamNormal,
								// justifyContent:'center',
								alignItems: "center",
								fontSize: commonFontSizes.s,
								paddingLeft: "10px",
							}}
						>
							{engineType ?? "N/A"}
						</Typography>
					</Box>
				</CustomToolTip>

				<CustomToolTip title={"Inspection Location"} marginLeft="44px">
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							marginLeft: "44px",
						}}
					>
						<svg
							width="16"
							height="16"
							viewBox="0 0 16 16"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<g clip-path="url(#clip0_20171_56496)">
								<path
									d="M11 2.5H13.5V5M5 13.5H2.5V11M13.5 11V13.5H11M2.5 5V2.5H5M5.5 5H10.5C10.7761 5 11 5.22386 11 5.5V10.5C11 10.7761 10.7761 11 10.5 11H5.5C5.22386 11 5 10.7761 5 10.5V5.5C5 5.22386 5.22386 5 5.5 5Z"
									stroke="#394458"
									stroke-width="0.8"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</g>
							<defs>
								<clipPath id="clip0_20171_56496">
									<rect width="16" height="16" fill="white" />
								</clipPath>
							</defs>
						</svg>
						<Typography
							sx={{
								fontFamily: gothamNormal,
								// justifyContent:'center',
								alignItems: "center",
								fontSize: commonFontSizes.s,
								paddingLeft: "10px",
							}}
						>
							{latestLocation ?? "N/A"}
						</Typography>
					</Box>
				</CustomToolTip>
			</Box>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
				}}
			>
				<ActiveWidget
					active={totalActive}
					total={totalStatus}
					isLoadingActive={isLoading}
					isLoadingTotal={totalIsLoading}
				/>
				<Box
					sx={{
						display: "flex",
						width: "100%",
						gap: "1em",
						flexDirection: "row",
						flexWrap: "wrap",
						justifyContent: "space-evenly",
						alignItems: "center",
					}}
				>
					<CustomBoxItem>
						<MissingWidget
							value={totalLost}
							isLoading={isLoading}
						/>
					</CustomBoxItem>
					<CustomBoxItem>
						<ErrorWidget
							value={totalTagError}
							isLoading={isLoading}
						/>
					</CustomBoxItem>
					<CustomBoxItem>
						<ExpiredWidget
							value={totalExpired}
							isLoading={isLoading}
						/>
					</CustomBoxItem>
					<CustomBoxItem>
						<NearExpiryWidget
							value={totalRemind}
							isLoading={isLoading}
						/>
					</CustomBoxItem>
					<CustomBoxItem>
						<UnutilizedWidget
							value={totalUnutilisedSeat}
							isLoading={isLoading}
						/>
					</CustomBoxItem>
					{/* <CustomBoxItem>
        <DefectsWidget value={totalDefects} isLoading={isLoading} />
    </CustomBoxItem> */}
				</Box>
			</Box>
		</Box>
	);
}
function CustomBoxItem({ children }) {
	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				minWidth: "170px",
			}}
		>
			{children}
		</Box>
	);
}
