
import React, { Fragment } from 'react';
import { Box, Grid, Skeleton, Typography } from "@mui/material";
import { useContext } from "react";
import useGetAPI from "../../../../models/useGetAPI";
import { ACExtraDetails } from "./ACExtraDetails";
import ActiveCountWidget from "./ActiveCountWidget";
import Widgets from "./Widgets";
import { gothamBold } from "../../../../constants/fonts";
import { LifebouyIcon } from "../../icons/statusIcons";
import { useGetAllFleetByCompanyCode } from "../../../../models/custom/fleet-model";
import { useGetAllFlight } from "../../../../models/custom/flight-model";
import { useGetAllInvStatus } from 'pages/elevade/cabinMonitoring/js/models/custom/inventory-model';

/**
 * @typedef { Object } DetailAllScannerProps
 * @property {boolean} showDetail
 */
/**
 * DetailAllScanner
 * @param {DetailAllScannerProps} props 
 */
export default function DetailAllAC(props) {

	const {
		showDetail = false,
	} = props

	const { data, isLoading } = useGetAllFleetByCompanyCode();
	const { data: flightData, isLoading: flightIsLoading } = useGetAllFlight()

	// useEffect(() => {
	//     //@ts-ignore
	//     // console.log({ flightData: flightData.rows })
	// }, [flightData])

	const usingData = data?.map((node) => {
		return node;
	}) ?? [];

	const allFleetsInvsStatus = useGetAllInvStatus();

	return (
		<Box>
			<Grid container>
				<Grid item xs={12} md={3}>
					<ActiveCountWidget
						isLoading={allFleetsInvsStatus?.isLoading}
						showDetail={false}
						totalIsLoading={allFleetsInvsStatus?.isLoading}
						active={allFleetsInvsStatus?.data?.data?.allFleets?.totalAircraftActive}
						expired={allFleetsInvsStatus?.data?.data?.allFleets?.totalAircraftExpired}
						missing={allFleetsInvsStatus?.data?.data?.allFleets?.totalAircraftLost}
						remind={allFleetsInvsStatus?.data?.data?.allFleets?.totalAircraftNearExpiry}
						tagerror={allFleetsInvsStatus?.data?.data?.allFleets?.totalAircraftTagError}
						totalCount={allFleetsInvsStatus?.data?.data?.allFleets?.totalAircraftStatus}
					/>
				</Grid>
				<Grid item xs={12} md={9} sx={{ paddingRight: "1em" }}>
					<Box
						sx={{
							width: "100%",
							display: "flex",
							overflow: showDetail ? "auto" : "hidden",
							flexDirection: "row",
							opacity: showDetail ? "1" : "0",
							height: showDetail ? "100%" : "0px",
							// transition: 'height 0.5s ease-in-out, opacity 0.5s ease 0.5s',
							transition: "opacity 0.5s ease-in-out",
						}}
					>
						{(isLoading || flightIsLoading) && (
							<div>
								<Skeleton variant="text" animation="wave" />
							</div>
						)}
						{usingData &&
							!isLoading &&
							!flightIsLoading &&
							usingData?.map &&
							usingData?.map(
								({
									regNo,
									model,
									totalActive,
									totalStatus,
									totalLost,
									totalTagError,
									totalExpired,
									totalRemind,
									totalDefect,
								}) => {
									const flights = flightData;
									const flightArr = flights?.rows?.[0] ?? [];
									const flightInfo =
										flightArr.find((item) => {
											return (
												(typeof item.AC_REG === "string"
													? item.AC_REG.toLocaleLowerCase()
													: item.AC_REG) ===
												(typeof regNo === "string"
													? regNo.toLocaleLowerCase()
													: regNo)
											);
										}) ?? {};
									const {
										departure_airport,
										destination_airport,
										on_datetime,
										flight_no,
									} = flightInfo ?? {};
									const arrDate =
										on_datetime && on_datetime.value
											? new Date(
												on_datetime.value + "000z"
											).toLocaleDateString("en-US", {
													/* year: 'numeric', */ month: "short",
												day: "2-digit",
											})
											: undefined;
									const arrTime =
										on_datetime && on_datetime.value
											? new Date(
												on_datetime.value + "000z"
											).toLocaleTimeString("en-US", {
												hour12: false,
												hour: "2-digit",
												minute: "2-digit",
											})
											: undefined;
									return (
										<Box
											className="DetailWrapper"
											sx={{
												height: "fit-content",
												paddingBottom: "1em",
											}}
										>
											{/* {on_datetime}<br /> */}
											{/* {arrDate}<br/>{arrTime}<br/> */}
											<DetailBox
												from={departure_airport}
												dest={destination_airport}
												arrDateStr={
													/* on_datetime && on_datetime.value ? `${arrDate} ${arrTime}` :  */ "N/A"
												}
												regNo={regNo}
												// model={model}
												model={flight_no}
												missing={totalLost}
												tagerror={totalTagError}
												expired={totalExpired}
												remind={totalRemind}
												defects={totalDefect}
												active={totalActive}
												total={totalStatus}
											/>
										</Box>
									);
								}
							)}
					</Box>
					<Box
						sx={{
							width: "100%",
							display: "flex",
							overflow: !showDetail ? "auto" : "hidden",
							flexDirection: "row",
							opacity: !showDetail ? "1" : "0",
							height: !showDetail ? "100%" : "0px",
							// height: !showDetail ? '100%' : "0px",
							// transition: 'height 0.5s ease-in-out, opacity 0.5s ease 0.5s',
							transition: "opacity 0.5s ease-in-out",
							paddingLeft: "2em",
						}}
					>
						<Widgets
							isLoading={allFleetsInvsStatus?.isLoading}
							expired={allFleetsInvsStatus?.data?.data?.allFleets?.totalAircraftExpired}
							missing={allFleetsInvsStatus?.data?.data?.allFleets?.totalAircraftLost}
							remind={allFleetsInvsStatus?.data?.data?.allFleets?.totalAircraftNearExpiry}
							tagerror={allFleetsInvsStatus?.data?.data?.allFleets?.totalAircraftTagError}
						/>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
}

function ExpandedView() {

	const usingData = data?.map(({ node }) => {
		return node;
	}) ?? [];

	return (
		<Fragment>
			{
				usingData &&
				!isLoading &&
				!flightIsLoading &&
				usingData?.map &&
				usingData?.map(
					({
						regNo,
						model,
						totalActive,
						totalStatus,
						totalLost,
						totalTagError,
						totalExpired,
						totalRemind,
						totalDefect,
					}) => {
						const flights = flightData;
						const flightArr = flights?.rows?.[0] ?? [];
						const flightInfo = flightArr.find((item) => {
							return (
								(typeof item.AC_REG === "string" ? item.AC_REG.toLocaleLowerCase() : item.AC_REG) ===
								(typeof regNo === "string" ? regNo.toLocaleLowerCase() : regNo)
							);
						}) ?? {};
						const {
							departure_airport,
							destination_airport,
							on_datetime,
							flight_no,
						} = flightInfo ?? {};
						const arrDate =
							on_datetime && on_datetime.value
								? new Date(
									on_datetime.value + "000z"
								).toLocaleDateString("en-US", {
													/* year: 'numeric', */ month: "short",
									day: "2-digit",
								})
								: undefined;
						const arrTime =
							on_datetime && on_datetime.value
								? new Date(
									on_datetime.value + "000z"
								).toLocaleTimeString("en-US", {
									hour12: false,
									hour: "2-digit",
									minute: "2-digit",
								})
								: undefined;
						return (
							<Box
								className="DetailWrapper"
								sx={{
									height: "fit-content",
									paddingBottom: "1em",
								}}
							>
								{/* {on_datetime}<br /> */}
								{/* {arrDate}<br/>{arrTime}<br/> */}
								<DetailBox
									from={departure_airport}
									dest={destination_airport}
									arrDateStr={
													/* on_datetime && on_datetime.value ? `${arrDate} ${arrTime}` :  */ "N/A"
									}
									regNo={regNo}
									// model={model}
									model={flight_no}
									missing={totalLost}
									tagerror={totalTagError}
									expired={totalExpired}
									remind={totalRemind}
									defects={totalDefect}
									active={totalActive}
									total={totalStatus}
								/>
							</Box>
						);
					}
				)}
		</Fragment>
	)
}

function DetailBox({
	from,
	dest,
	arrDateStr,
	regNo,
	model,
	active,
	total,
	missing,
	tagerror,
	expired,
	remind,
	defects,
}) {
	return (
		<Box
			sx={{
				minWidth: "250px",
				paddingRight: "1em",
				paddingLeft: "1em",
				border: "1px solid #E8E8E8",
				borderRadius: "12px",
				marginLeft: "0.5em",
				paddingTop: "1em",
				paddingBottom: "1em",
				height: "100%",
			}}
		>
			<Box sx={{ display: "flex", flexDirection: "row" }}>
				<Typography
					fontSize="18px"
					fontFamily={gothamBold}
					fontWeight="bold"
				>
					{regNo}
				</Typography>
				{/* <Typography fontSize='12px' sx={{ flexGrow: 1, display: 'flex', justifyContent: 'end' }}>{model}</Typography> */}
			</Box>
			{/* <Box sx={{ display: 'flex', flexDirection: 'row', paddingTop: '1em', marginBottom: '1em' }}>
            <Typography fontSize='12px' >{(from && dest) ? `${from} -> ${dest}` : 'N/A'}</Typography>
        </Box> */}
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					paddingLeft: "2em",
					alignItems: "center",
					justifyContent: "left",
				}}
			>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						paddingRight: "1em",
					}}
				>
					<LifebouyIcon />
				</Box>
				<Box
					sx={{
						padding: "1em",
						paddingLeft: "0px",
						display: "flex",
						flexDirection: "row",
						alignItems: "flex-end",
					}}
				>
					<Box>
						<Typography
							fontFamily={gothamBold}
							fontWeight="bold"
							fontSize="20px"
							sx={{ textAlign: "left" }}
						>
							{active}
						</Typography>
						<Typography color="#72767E" sx={{ textAlign: "left" }}>
							Active
						</Typography>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							justifyContent: "center",
							paddingLeft: "2em",
							paddingRight: "1em",
						}}
					>
						<Typography sx={{ textAlign: "left" }}>
							{total}
						</Typography>
						<Typography color="#72767E" sx={{ textAlign: "left" }}>
							Total
						</Typography>
					</Box>
				</Box>
			</Box>
			<ACExtraDetails
				missing={missing}
				tagerror={tagerror}
				expired={expired}
				remind={remind}
				defects={defects}
			/>
		</Box>
	);
}
