
import React from 'react';
import { Box, Skeleton, Typography } from "@mui/material";
import { useContext } from "react";
import { SquareMissingIcon } from "../../icons/statusIcons";
import { CountContext } from "./Main";
import { gothamBold } from "../../../../constants/fonts";
// import { gothamBold } from "../../../constants/fonts";

export default function Widgets() {

	const { isLoading, totalInactive, } = useContext(CountContext);

	return (
		<Box sx={{
			display: "flex",
			flexDirection: 'row',
			flexWrap: 'wrap',
		}}>
			<CustomBoxItem>
				<MissingWidget value={totalInactive} isLoading={isLoading} />
			</CustomBoxItem>
		</Box>
	);
}

function MissingWidget({ value, isLoading }) {
	return (
		<WidgetContainer icon={<SquareMissingIcon />} isLoading={isLoading} label={"Inactive"} value={value} />
	);
}

function WidgetContainer({ icon, isLoading, value, label }) {
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'flex-start',
				gap: '1em',
			}}
		>
			<Box sx={{
				paddingTop: '0.3em'
			}}>
				{icon}
			</Box>
			<Box>
				<Typography fontFamily={gothamBold} fontWeight='bold' fontSize='24px' sx={{ textAlign: "left" }}>
					{isLoading && <Skeleton variant="text" animation="wave" />}
					{/* {!isLoading && ((value?.toLocaleString && value?.toLocaleString()) ?? 0)} */}
					{!isLoading && ((value?.toLocaleString && value?.toLocaleString()) ?? 0)}
				</Typography>
				<Typography color='#72767E' sx={{ textAlign: "left" }}>
					{label}
				</Typography>
			</Box>
		</Box>
	);
}

function CustomBoxItem({ children }) {
	return (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'left',
				minWidth: "150px",
			}}
		>
			{children}
		</Box>
	);
}
