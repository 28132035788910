
export const PERMISSIONS = {
	cabinManageLopa: {
		code: 'fleet-cbm-001',
		enabled: true,
	}, // {canCreate: bool} {canRetrieve: bool} {canUpdate: bool} {canDelete: bool}
	cabinManageEquipment: {
		code: 'fleet-cbm-002',
		enabled: true,
	}, // {canCreate: bool} {canRetrieve: bool} {canUpdate: bool} {canDelete: bool}
	cabinManageInspectionReport: {
		code: 'fleet-cbm-003',
		enabled: true,
	}, // {canCreate: bool} {canRetrieve: bool} {canUpdate: bool} {canDelete: bool}
	cabinAccess: { // isCabinMonitring 
		code: "CABIN-001", // access to this module
		enabled: true,
	}
}

/**
 userPermissions : Array of {
	id:string,
	name:string,
	code:string,
	moduleCode:string,
	moduleName: string,
	canCreate: boolean,
	canRetrieve: boolean,
	canUpdate: boolean,
	canDelete: boolean,
}
 */
export function checkPermission(
	userPermissions,
	appPermission,
	crud,
) {

	if (appPermission?.enabled === false) {
		return true
	}

	const foundPermission = userPermissions?.find(({
		code: codeInner,
		// id, name, moduleCode, moduleName,
		// canCreate, canRetrieve, canUpdate, canDelete,
	}) => {
		return codeInner === appPermission.code
	})

	let isCrudComply = true

	if (crud && Object.keys(crud)?.length > 0) {
		Object.keys(crud).map((key) => {
			if (
				foundPermission?.[key] === undefined ||
				foundPermission[key] !== crud[key]
			) {
				isCrudComply = false
			}
		})
	}

	return foundPermission !== undefined && isCrudComply;
}

/**
 * 
 * @param {
		id:string,
		name:string,
		code:string,
		moduleCode:string,
		moduleName: string,
		canCreate: boolean,
		canRetrieve: boolean,
		canUpdate: boolean,
		canDelete: boolean,
	}[] : userPermissions 
 * @description will return an object of : {
	isAllowedCreateEquipment : boolean, 
	isAllowedViewEquipmentList : boolean, 
	isAllowedUpdateEquipment : boolean, 
	isAllowedViewInspectionReport : boolean,
	isAllowedViewLopa : boolean,
	isAllowedUpdateEqStatusLopa : boolean,
 }
 */
export function cabinCheckPermission(userPermissions) {

	return {
		isAllowedViewEquipmentList:
			// true, // testing
			userPermissions ? checkPermission(userPermissions, PERMISSIONS.cabinManageEquipment, { canRetrieve: true }) : false,
		isAllowedCreateEquipment:
			// true, // testing
			userPermissions ? checkPermission(userPermissions, PERMISSIONS.cabinManageEquipment, { canCreate: true, canRetrieve: true }) : false,
		isAllowedUpdateEquipment:
			// true, // testing
			userPermissions ? checkPermission(userPermissions, PERMISSIONS.cabinManageEquipment, { canUpdate: true, canRetrieve: true }) : false,
		isAllowedViewInspectionReport:
			// true, // testing
			userPermissions ? checkPermission(userPermissions, PERMISSIONS.cabinManageInspectionReport, { canCreate: true, canRetrieve: true, }) : false,
		isAllowedViewLopa:
			// true, // testing
			userPermissions ? checkPermission(userPermissions, PERMISSIONS.cabinManageInspectionReport, { canRetrieve: true, }) : false,
		isAllowedUpdateEqStatusLopa:
			// true, // testing
			userPermissions ? checkPermission(userPermissions, PERMISSIONS.cabinManageInspectionReport, { canRetrieve: true, canUpdate: true, }) : false,
	}

}