
import React, { useEffect } from 'react';
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import PageTemplate from "../../components/templates/page-template";
import WidgetItemsStatus from "../../components/common-component/WidgetItemsStatus";
import InventoryListAll from "./InventoryListAll";
import { useAppSelector } from '../../../app/hooks';
import { PERMISSIONS, cabinCheckPermission, checkPermission } from '../../../controllers/permissions/permission-codes';
import { useHistory } from 'react-router';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';

export default function EqManagementPageMain() {

	const [showAdd, setShowAdd] = useState(false);

	const userEntity = useAppSelector((state) => state.userEntity)
	const permissions = userEntity?.userPermission

	const history = useHistory();

	useEffect(() => {
		const isUserAllowed = cabinCheckPermission(permissions).isAllowedViewEquipmentList
		if (!isUserAllowed) {
			history.push("/error-403")
		}
	}, [permissions])

	const isUserAllowedCreate = cabinCheckPermission(permissions).isAllowedCreateEquipment

	return (
		<Fragment>
			<Helmet>
				<title>ELEVADE - Cabin Monitoring Equipment Management</title>
			</Helmet>

			<PageTemplate
				title="Equipment Management"
				topRightActions={[
					{
						component: (
							<Button
								disabled={!isUserAllowedCreate}
								onClick={() => {
									setShowAdd(true);
								}}
								sx={{
									margin: "1em",
									marginRight: "0px",
									textTransform: "none",
								}}
								variant="contained"
							>
								+ Add new Equipment
							</Button>
						),
					},
				]}
			>
				<Box sx={{ position: "relative" }}>
					<Box
						sx={{
							width: "100%",
							display: "flex",
							justifyContent: "right",
						}}
					></Box>
					<Box
						sx={{
							marginBottom: "1em",
						}}
					>
						<WidgetItemsStatus />
					</Box>
					<Box sx={{ marginBottom: "1em" }} />
					<InventoryListAll showAdd={showAdd} setShowAdd={setShowAdd} />
					<Box sx={{ width: "100%", height: "2em" }} />
				</Box>
			</PageTemplate>
		</Fragment>
	);
}
