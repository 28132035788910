import React, { useEffect, useState } from "react";
import IconClose from "common/images/icons/elevade/Icon-CloseGray.svg";
import IconCloseWhite from "common/images/icons/elevade/Icon-ControlCloseWhite.svg";
import IconZoomIn from "common/images/icons/elevade/Icon-ZoomIn.svg";
import IconZoomOut from "common/images/icons/elevade/Icon-ZoomOut.svg";
import IconRotate from "common/images/icons/elevade/Icon-Rotate.svg";
import IconLineSeparator from "common/images/icons/elevade/Icon-LineSeparator.svg";
import Drawer from "@mui/material/Drawer";
import styles from "./Preview.module.css";

const Preview = ({ open, setOpen, fileLink, fileName }) => {
  const handleClose = () => setOpen(false);
  const [scale, setScale] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [dragging, setDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    setScale(1);
    setRotation(0);
    setDragging(false);
    setPosition({ x: 0, y: 0 });
    setStartPosition({ x: 0, y: 0 });
  }, [open]);

  useEffect(() => {
    setPosition({ x: 0, y: 0 });
  }, [rotation]);

  const handleZoomIn = () => {
    setScale(scale + 0.3);
  };

  const handleZoomOut = () => {
    if (scale > 0.1) {
      setScale(scale - 0.3);
    }
  };

  const handleRotate = () => {
    setRotation(rotation + 90);
  };

  const handleMouseDown = (e) => {
    setDragging(true);
    setStartPosition({ x: e.clientX, y: e.clientY });
  };

  const handleMouseMove = (e) => {
    if (dragging) {
      const deltaX = e.clientX - startPosition.x;
      const deltaY = e.clientY - startPosition.y;
      setPosition({ x: position.x + deltaX, y: position.y + deltaY });
      setStartPosition({ x: e.clientX, y: e.clientY });
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  const ControlButton = () => {
    return (
      <div className={styles.controlContainer}>
        <img
          src={IconZoomIn}
          alt="icon-zoom-in"
          className={`${styles.controlButton} ${styles.controlButtonHover}`}
          onClick={() => handleZoomIn()}
        />
        <img
          src={IconZoomOut}
          alt="icon-zoom-out"
          className={`${styles.controlButton} ${styles.controlButtonHover}`}
          onClick={() => handleZoomOut()}
        />
        <img src={IconLineSeparator} alt="icon-line-separator" className={`${styles.controlButton}`} />
        <img
          src={IconRotate}
          alt="icon-rotate"
          className={`${styles.controlButton} ${styles.controlButtonHover}`}
          onClick={() => handleRotate()}
        />
        <img
          src={IconCloseWhite}
          alt="icon-close"
          className={`${styles.controlButton} ${styles.controlButtonHover}`}
          onClick={handleClose}
        />
      </div>
    );
  };

  return (
    <>
      {open && (
        <div
          style={{
            position: "fixed",
            backgroundColor: "rgba(51, 51, 51, 0.95)",
            width: "50%",
            height: "100%",
            left: "00px",
            top: "60px",
            overflow: "hidden",
          }}
        >
          <div
            style={{ position: "relative", width: "100%", height: "100%", cursor: dragging ? "grabbing" : "grab" }}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
          >
            {!fileLink?.includes(".pdf?") && (
              <div style={{ position: "absolute", top: "32px", left: "64px", color: "#ffffff" }}>{fileName}</div>
            )}
            {fileLink?.includes(".pdf?") ? (
              <iframe src={fileLink} title="PDF Preview" style={{ width: "96%", height: "100%", marginLeft: "4%" }} />
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  height: "100%",
                  marginLeft: "24px",
                  alignItems: "center",
                  transform: `scale(${scale}) rotate(${rotation}deg) translate(${position.x}px, ${position.y}px)`,
                  transformOrigin: "center",
                  transition: !dragging ? "transform 0.2s ease-in-out" : "",
                }}
                id="imageContainer"
              >
                <img
                  src={fileLink}
                  alt={`Preview`}
                  style={{ objectFit: "contain", width: "90%", height: "90%" }}
                  id={"image"}
                  onMouseDown={handleMouseDown}
                />
              </div>
            )}
            <ControlButton />
          </div>
        </div>
        // </Drawer>
      )}
    </>
  );
};

export default Preview;
