import { useMediaQuery } from "@mui/material";
import React, { useEffect, useRef } from "react";
import Hydra_fluid_iconsvg from "common/images/icons/elevade/Hydra_fluid_iconsvg.svg";
import styles from "../../predictiveMaintenance/PredictiveMaintenance.module.css";
// import TableauReport from 'tableau-viz-react';
const { tableau } = window;

const HydraulicDashboard = () => {
  const isMobile = useMediaQuery("(max-width:744px)");
  const isTablet = useMediaQuery("(min-width:744px)");
  var dashboardDevice = isMobile ? "phone" : isTablet ? "tablet" : "desktop";
  const url =
    "https://10az.online.tableau.com/t/airasia/views/HydraulicQualityMonitoringDashboard-ADE/HydraulicQualityMonitoring-ADE";
  const ref = useRef(null);
  const options = {
    device: dashboardDevice,
    hideShareOptions: true,
    hideTabs: false,
    hideToolbar: true,
  };

  function initViz() {
    new tableau.Viz(ref.current, url, options);
  }

  useEffect(() => {
    initViz();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="pt-4">
      <img src={Hydra_fluid_iconsvg} alt="Hydra_fluid_iconsvg" />
      <h2 className={`mb-4  px-4 d-inline ${styles.predictiveMainTitle}`}>ATA 29: Hydraulics Quality Monitoring</h2>

      <div ref={ref}></div>
    </div>
  );
};

export default HydraulicDashboard;
