/* eslint-disable no-unused-expressions */
import React from 'react';
import {
	Chip,
	Paper,
	Skeleton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import _ from "lodash";
import PageTitle from "../../components/titles/PageTitle";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import {
	gothamMedium,
	gothamNormal,
	gothamBold,
} from "../../../constants/fonts";
// import { useGetAllInvByACRegNo } from "../../../models/custom/inventory-model";
import { useGetAllLocations } from '../../../models/custom/location-model';
import dayjs from 'dayjs';
import GeneralTable from '../../components/table/GeneralTable';

export default function DetailView({ obj }) {

	const allLocationsByRegNo = useGetAllLocations({ regNo: obj?.fleet?.regNo })

	const scannedTags = [];
	obj?.inventories?.map(({ tag }) => {
		scannedTags.push(tag);
	});

	// const usingData = obj?.inventories?.map((node) => {
	// 	return {
	// 		node: {
	// 			tag: node?.tag,
	// 			serialNo: node?.serialNo,
	// 			itemCode: node?.type?.code,
	// 			itemName: node?.type?.name,
	// 			// ..._.omit(node, ["aircraftRegNo", "aircraftModel", "entityName"]),
	// 			location: 'seatNo',
	// 			exist: true,
	// 		},
	// 	};
	// });
	// if (isLoading) {
	//     return (
	//         <Fragment>
	//             <PageTitle
	//                 title={obj?.batchNo ?? "N/A"}
	//                 style={{
	//                     marginBottom: "1em"
	//                 }}
	//             />
	//             loading...
	//         </Fragment>
	//     )
	// }
	// const dateInspectionLocaleDate = new Date(
	// 	obj?.dateInspection
	// ).toLocaleDateString("en-US", {
	// 	year: "numeric",
	// 	month: "short",
	// 	day: "2-digit",
	// });
	// const dateInspectionLocaleTime = new Date(
	// 	obj?.dateInspection
	// ).toLocaleTimeString("en-US", { hour12: false });
	return (
		<Box>
			{/* <pre>
				{JSON.stringify({ obj, allLocationsByRegNo, scannedTags }, null, 4)}
			</pre> */}
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					gap: "0.5em",
					marginBottom: "1em",
				}}
			>
				<AssignmentOutlinedIcon fontSize="medium" />
				<PageTitle
					title={obj?.batchNo ?? "N/A"}
					style={{
						fontSize: "18px",
					}}
				/>
			</Box>

			<TableContainer
				sx={{
					border: "1px solid #E8E8E8",
					borderRadius: "12px",
					marginBottom: "16px",
					width: "99.5%",
					padding: "10px",
					"& .MuiTableRow-root:hover": {
						backgroundColor: "#F7F7F7",
					},
				}}
			>
				<InfoBox
					total={obj?.inventories?.length ?? "N/A"}
					invCount={allLocationsByRegNo?.isLoading ? (
						<Skeleton
							variant="text"
							animation="wave"
							sx={{
								width: "35px",
								height: "30px",
								marginLeft: "5px",
							}}
						/>
					) : (
						allLocationsByRegNo?.totalCountFiltered ?? "N/A"
					)}
					regNo={obj?.fleet?.regNo ?? "N/A"}
					model={undefined}
					location={obj?.locationCode ?? "N/A"}
					scannedTime={obj?.dateInspection && dayjs(obj?.dateInspection).isValid() ? `${dayjs(obj?.dateInspection).format('MMM DD, YYYY HH:mm:ss')}` : "N/A"}
					scannedBy={obj?.createdBy ?? "N/A"}
					OpDate={obj?.dateInspection && dayjs(obj?.dateInspection).isValid() ? `${dayjs(obj?.dateInspection).format('MMM DD, YYYY HH:mm:ss')}` : "N/A"}
					syncTime={obj?.dateInspection && dayjs(obj?.dateInspection).isValid() ? `${dayjs(obj?.dateInspection).format('MMM DD, YYYY HH:mm:ss')}` : "N/A"}
					syncDate={obj?.dateInspection && dayjs(obj?.dateInspection).isValid() ? `${dayjs(obj?.dateInspection).format('MMM DD, YYYY HH:mm:ss')}` : "N/A"}
				/>
			</TableContainer>

			{allLocationsByRegNo?.totalCountFiltered < 1 && (
				<Typography>
					No records on inventory for this aircraft...
				</Typography>
			)}
			{/* <DetailTable edges={usingData ?? []} isLoading={allLocationsByRegNo?.isLoading} /> */}
			<GeneralTable
				paginationEnabled={false}
				headers={[
					'Tag ID',
					'Serial No.',
					'Equipment Type',
					'Location',
					'Status',
				]}
				rows={allLocationsByRegNo?.data?.items?.map((item) => {
					return {
						'Tag ID': item?.inventory?.tag ? item?.inventory?.tag?.slice(-12) : "N/A",
						'Serial No.': item?.inventory?.serialNo,
						'Equipment Type': item?.inventory?.type?.name,
						'Location': item?.seatNo ? item?.seatNo : item?.locationType?.name,
						'Status': scannedTags?.includes(item?.inventory?.tag) ? <Active /> : <Missing />,
					}
				})}
			/>
		</Box>
	);
}

function InfoBox(props) {
	const {
		model,
		regNo,
		location,
		scannedTime,
		scannedBy,
		OpDate,
		syncTime,
		syncDate,
		invCount,
		total,
	} = props;
	const iconStyle = {};
	const titleStyle = {
		fontFamily: gothamMedium,
		width: "150px",
		paddingLeft: "10px",
		fontSize: "12px",
		fontWeight: "normal",
		justifyContent: "left",
	};
	const valueStyle = {
		fontFamily: gothamNormal,
		fontSize: "12px",
		justifyContent: "left",
		paddingLeft: "0.5em",
	};
	const doubleDotStyle = {
		paddingLeft: "0.5em",
		fontFamily: gothamBold,
		fontWeight: "normal",
		fontSize: "12px",
		justifyContent: "left",
	};
	return (
		<table
			style={{ border: "none", padding: "24px", paddingTop: "5px" }}
			align="left"
		>
			<tr style={{ height: "3em" }}>
				<InfoTd>
					<SmallAcIcon />
				</InfoTd>
				<td
					colSpan={14}
					style={{
						...titleStyle,
						fontSize: "16px",
						paddingLeft: "0.5em",
					}}
				>
					{regNo}
				</td>
			</tr>
			<tr style={{}}>
				<InfoTd style={iconStyle}>
					<SmallCalendarIcon />
				</InfoTd>
				<InfoTd style={titleStyle}>Operation Date</InfoTd>
				<InfoTd style={doubleDotStyle}>:</InfoTd>
				<InfoTd style={valueStyle}>{OpDate}</InfoTd>
				<td style={{ width: "2em" }}></td>

				<InfoTd style={iconStyle}>
					<SmallGridIcon />
				</InfoTd>
				<InfoTd style={titleStyle}>Location</InfoTd>
				<InfoTd style={doubleDotStyle}>:</InfoTd>
				<InfoTd style={valueStyle}>{location}</InfoTd>
				<td style={{ width: "2em" }}></td>

				<InfoTd style={iconStyle}>
					<SmallScanIcon />
				</InfoTd>
				<InfoTd style={titleStyle}>Scan Time</InfoTd>
				<InfoTd style={doubleDotStyle}>:</InfoTd>
				<InfoTd style={valueStyle}>{scannedTime}</InfoTd>
				<td style={{ width: "2em" }}></td>
			</tr>
			<tr>
				<InfoTd style={iconStyle}>
					<SmallPersonIcon />
				</InfoTd>
				<InfoTd style={{ ...titleStyle, width: "120px" }}>
					Scanned by
				</InfoTd>
				<InfoTd style={doubleDotStyle}>:</InfoTd>
				<InfoTd style={valueStyle}>{scannedBy}</InfoTd>
				<td style={{ width: "2em" }}></td>

				<InfoTd style={iconStyle}>
					<SmallFloatyIcon />
				</InfoTd>
				<InfoTd style={titleStyle}>Total count (Inventory)</InfoTd>
				<InfoTd style={doubleDotStyle}>:</InfoTd>
				<InfoTd style={valueStyle}>{invCount}</InfoTd>
				<td style={{ width: "2em" }}></td>

				<InfoTd style={iconStyle}>
					<SmallTotalScannedItemIcon />
				</InfoTd>
				<InfoTd style={titleStyle}>Total Item Scanned</InfoTd>
				<InfoTd style={doubleDotStyle}>:</InfoTd>
				<InfoTd style={valueStyle}>{total}</InfoTd>
				<td style={{ width: "2em" }}></td>
			</tr>
		</table>
	);
}

function InfoTd({ children, style }) {
	return (
		<td>
			<Box
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					...style,
				}}
			>
				{children}
			</Box>
		</td>
	);
}
// function DetailTable({ edges, onClickRow, isLoading }) {
// 	const usingData = {
// 		edges: (edges ?? []).map(({ node }) => {
// 			return {
// 				node: _.omit(node, ["id"]),
// 			};
// 		}),
// 	};
// 	const headerStyle = {
// 		fontFamily: gothamMedium,
// 		background: "#F2F7FF",
// 	};
// 	return (
// 		<TableContainer
// 			component={Paper}
// 			sx={{
// 				borderRadius: "12px",
// 				border: "1px solid #E2E2E2",
// 				"& .MuiTableRow-root:hover": {
// 					backgroundColor: "#F7F7F7",
// 				},
// 			}}
// 		>
// 			<Table>
// 				<TableHead>
// 					<TableRow>
// 						<TableCell align="center" sx={headerStyle}>
// 							Tag ID
// 						</TableCell>
// 						<TableCell align="center" sx={headerStyle}>
// 							Serial No.
// 						</TableCell>
// 						<TableCell align="center" sx={headerStyle}>
// 							Equipment Type
// 						</TableCell>
// 						<TableCell align="center" sx={headerStyle}>
// 							Location
// 						</TableCell>
// 						<TableCell align="center" sx={headerStyle}>
// 							Status
// 						</TableCell>
// 					</TableRow>
// 				</TableHead>
// 				<TableBody>
// 					{isLoading && (
// 						<Fragment>
// 							{Array.apply(null, { length: 5 })
// 								.map(Number.call, Number)
// 								.map((item) => {
// 									return (
// 										<TableRow>
// 											<TableCell
// 												sx={{
// 													paddingTop: "16px",
// 													paddingBottom: "16px",
// 												}}
// 												colSpan={9}
// 												component="th"
// 												scope="row"
// 											>
// 												<Skeleton
// 													sx={{ height: "39px" }}
// 													animation="wave"
// 													variant="text"
// 												/>
// 											</TableCell>
// 										</TableRow>
// 									);
// 								})}
// 						</Fragment>
// 					)}
// 					{usingData?.edges.map(({ node }, index) => {
// 						return (
// 							<TableRow
// 								sx={{
// 									"&:last-child td, &:last-child th": {
// 										border: 0,
// 									},
// 									"&:hover": {
// 										background: "#D1D1D1",
// 									},
// 									cursor: onClickRow ? "pointer" : "default",
// 								}}
// 								onClick={() => {
// 									if (onClickRow) {
// 										onClickRow(node);
// 									}
// 								}}
// 							>
// 								<TableCell align="center">
// 									{node?.tag ? node?.tag?.slice(-12) : "N/A"}
// 								</TableCell>
// 								<TableCell align="center">
// 									{node?.serialNo ?? "N/A"}
// 								</TableCell>
// 								<TableCell align="center">
// 									{node?.itemCode ?? "N/A"}
// 								</TableCell>
// 								<TableCell align="center">
// 									{node?.location ?? "N/A"}
// 								</TableCell>
// 								<TableCell align="center">
// 									{node?.exist ? <Active /> : <Missing />}
// 								</TableCell>
// 							</TableRow>
// 						);
// 					})}
// 				</TableBody>
// 			</Table>
// 		</TableContainer>
// 	);
// }

function Active() {
	return (
		<Chip
			sx={{
				backgroundColor: "#FFECEB",
			}}
			label={
				<Typography fontSize="12px" fontWeight="bold" color="black">
					Detected
				</Typography>
			}
		/>
	);
}

function Missing() {
	return (
		<Chip
			sx={{
				backgroundColor: "#FFECEB",
			}}
			label={
				<Typography fontSize="12px" fontWeight="bold" color="#E95048">
					Missing
				</Typography>
			}
		/>
	);
}


function SmallAcIcon() {
	return (
		<svg
			width="18"
			height="18"
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M9 15.875L5.875 17.125V14.625L7.125 13.375V10.875L0.875 12.125V9.625L7.125 6.5V2.75C7.125 2.25272 7.32254 1.77581 7.67417 1.42417C8.02581 1.07254 8.50272 0.875 9 0.875C9.49728 0.875 9.97419 1.07254 10.3258 1.42417C10.6775 1.77581 10.875 2.25272 10.875 2.75V6.5L17.125 9.625V12.125L10.875 10.875V13.375L12.125 14.625V17.125L9 15.875Z"
				stroke="#333333"
				stroke-width="1.2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	)
}

function SmallCalendarIcon() {
	return (
		<svg
			width="17"
			height="16"
			viewBox="0 0 17 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M13.3809 2.5H3.38086C3.10472 2.5 2.88086 2.72386 2.88086 3V13C2.88086 13.2761 3.10472 13.5 3.38086 13.5H13.3809C13.657 13.5 13.8809 13.2761 13.8809 13V3C13.8809 2.72386 13.657 2.5 13.3809 2.5Z"
				stroke="#394458"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M11.3809 1.5V3.5"
				stroke="#394458"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M5.38086 1.5V3.5"
				stroke="#394458"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M2.88086 5.5H13.8809"
				stroke="#394458"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	)
}

function SmallGridIcon() {
	return (
		<svg
			width="16"
			height="17"
			viewBox="0 0 16 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12.75 3.25H3.25C2.97386 3.25 2.75 3.47386 2.75 3.75V13.25C2.75 13.5261 2.97386 13.75 3.25 13.75H12.75C13.0261 13.75 13.25 13.5261 13.25 13.25V3.75C13.25 3.47386 13.0261 3.25 12.75 3.25Z"
				stroke="#394458"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M8 3.25V13.75"
				stroke="#394458"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M13.25 8.5H2.75"
				stroke="#394458"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	)
}

function SmallScanIcon() {
	return (
		<svg
			width="17"
			height="16"
			viewBox="0 0 17 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M11.3809 2.5H13.8809V5"
				stroke="#394458"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M5.38086 13.5H2.88086V11"
				stroke="#394458"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M13.8809 11V13.5H11.3809"
				stroke="#394458"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M2.88086 5V2.5H5.38086"
				stroke="#394458"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M10.8809 5H5.88086C5.60472 5 5.38086 5.22386 5.38086 5.5V10.5C5.38086 10.7761 5.60472 11 5.88086 11H10.8809C11.157 11 11.3809 10.7761 11.3809 10.5V5.5C11.3809 5.22386 11.157 5 10.8809 5Z"
				stroke="#394458"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	)
}

function SmallPersonIcon() {
	return (
		<svg
			width="17"
			height="16"
			viewBox="0 0 17 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clip-path="url(#clip0_13868_83560)">
				<path
					d="M8.61621 10C10.8253 10 12.6162 8.20914 12.6162 6C12.6162 3.79086 10.8253 2 8.61621 2C6.40707 2 4.61621 3.79086 4.61621 6C4.61621 8.20914 6.40707 10 8.61621 10Z"
					stroke="#394458"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M2.61621 13.5C3.82684 11.4081 6.02559 10 8.61621 10C11.2068 10 13.4056 11.4081 14.6162 13.5"
					stroke="#394458"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_13868_83560">
					<rect
						width="16"
						height="16"
						fill="white"
						transform="translate(0.616211)"
					/>
				</clipPath>
			</defs>
		</svg>
	)
}

function SmallFloatyIcon() {
	return (
		<svg
			width="21"
			height="21"
			viewBox="0 0 21 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M10.873 18.4648C15.0152 18.4648 18.373 15.107 18.373 10.9648C18.373 6.82271 15.0152 3.46484 10.873 3.46484C6.73091 3.46484 3.37305 6.82271 3.37305 10.9648C3.37305 15.107 6.73091 18.4648 10.873 18.4648Z"
				stroke="#394458"
				stroke-width="1.2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M10.873 14.0898C12.5989 14.0898 13.998 12.6907 13.998 10.9648C13.998 9.23895 12.5989 7.83984 10.873 7.83984C9.14716 7.83984 7.74805 9.23895 7.74805 10.9648C7.74805 12.6907 9.14716 14.0898 10.873 14.0898Z"
				stroke="#394458"
				stroke-width="1.2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M8.66211 8.75391L5.56836 5.66016"
				stroke="#394458"
				stroke-width="1.2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M13.084 8.75391L16.1777 5.66016"
				stroke="#394458"
				stroke-width="1.2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M13.084 13.1758L16.1777 16.2695"
				stroke="#394458"
				stroke-width="1.2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M8.66211 13.1758L5.56836 16.2695"
				stroke="#394458"
				stroke-width="1.2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	)
}

function SmallTotalScannedItemIcon() {
	return (
		<svg
			width="21"
			height="21"
			viewBox="0 0 21 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M10.873 18.4648C15.0152 18.4648 18.373 15.107 18.373 10.9648C18.373 6.82271 15.0152 3.46484 10.873 3.46484C6.73091 3.46484 3.37305 6.82271 3.37305 10.9648C3.37305 15.107 6.73091 18.4648 10.873 18.4648Z"
				stroke="#394458"
				stroke-width="1.2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M10.873 14.0898C12.5989 14.0898 13.998 12.6907 13.998 10.9648C13.998 9.23895 12.5989 7.83984 10.873 7.83984C9.14716 7.83984 7.74805 9.23895 7.74805 10.9648C7.74805 12.6907 9.14716 14.0898 10.873 14.0898Z"
				stroke="#394458"
				stroke-width="1.2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M8.66211 8.75391L5.56836 5.66016"
				stroke="#394458"
				stroke-width="1.2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M13.084 8.75391L16.1777 5.66016"
				stroke="#394458"
				stroke-width="1.2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M13.084 13.1758L16.1777 16.2695"
				stroke="#394458"
				stroke-width="1.2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M8.66211 13.1758L5.56836 16.2695"
				stroke="#394458"
				stroke-width="1.2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	)
}