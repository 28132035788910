
import React from 'react';
import { Box, Typography, Skeleton } from "@mui/material";
import { Fragment, useContext } from "react";
import { ACExtraDetails } from "./ACExtraDetails";
import { LifebouyIcon } from "../../icons/statusIcons";
import { gothamBold } from "../../../../constants/fonts";

/**
 * 
 * @param {import('../WidgetItemsStatus/ActiveCountWidget').ActiveCountWidgetProps} props 
 * @returns 
 */
export default function ActiveCountWidget(props) {

	const {
		totalActive,
		isLoading,
		totalInactive,
		showDetail,
		totalCount,
		totalIsLoading
	} = props;

	return (
		<Fragment>
			<Box sx={{
				display: 'flex',
				flexDirection: 'row',
				paddingLeft: '2em',
				alignItems: 'center',
				justifyContent: 'left',
			}}>
				<Box sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					paddingRight: '1em',
				}}>
					<LifebouyIcon />
				</Box>
				<Box sx={{
					padding: '1em',
					paddingLeft: '0px',
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'flex-end',
				}}>
					<Box>
						<Typography fontFamily={gothamBold} fontWeight='bold' fontSize='24px' sx={{ textAlign: "left" }}>
							{isLoading && <Skeleton variant="text" animation="wave" />}
							{!isLoading && (totalActive?.toLocaleString() ?? 0)}
						</Typography>
						<Typography color='#72767E' sx={{ textAlign: "left" }}>
							Active
						</Typography>
					</Box>
					<Box sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						paddingLeft: '2em',
						paddingRight: '1em',
					}}>
						<Typography sx={{ textAlign: "left" }}>
							{totalIsLoading && (<Skeleton variant="text" animation="wave" width='24px' />)}
							{!totalIsLoading && (totalCount?.toLocaleString() ?? 0)}
						</Typography>
						<Typography color='#72767E' sx={{ textAlign: "left" }}>
							Total
						</Typography>
					</Box>
				</Box>
			</Box>
			<Box sx={{
				height: showDetail ? 'fit-content' : '0px',
				overflow: 'hidden',
				transition: 'height 0.5s ease-in-out',
			}}>
				<Box sx={{ padding: '1em', paddingRight: '3em' }}>
					<ACExtraDetails inactive={totalInactive} />
				</Box>
			</Box>
		</Fragment>
	);
}
