import React from "react";
import { Grid, Modal, Box, Typography, Fade, InputBase } from "@mui/material";
import styles from "./DDML.module.css";
import { useState, useEffect, useRef } from "react";
import { InputField, NewFileUploadComponent } from "pages/elevade/component/InputForm";
import { AdeButton } from "common/components";
import { makeAPIRequestMDB } from "common/helpers/makeAPIRequestMDB";
import { useSelector } from "react-redux";
import {
  formatDateDasr,
  addingDate,
  calculateBalanceDay,
  calculateCalendarBalance,
} from "pages/elevade/component/Helpers";
import Button from "pages/elevade/component/Button";
import IconBriefcase from "common/images/icons/elevade/Icon-Briefcase.svg";
import IconBriefcaseWhite from "common/images/icons/elevade/Icon-BriefcaseWhite.svg";
import IconBriefcaseDisabled from "common/images/icons/elevade/Icon-BriefcaseDisabled.svg";
import IconCheckCircleWhite from "common/images/icons/elevade/Icon-CheckCircleWhite.svg";
import IconCheckCircleDisabled from "common/images/icons/elevade/Icon-CheckCircleDisabled.svg";
import IconLockOpenWhite from "common/images/icons/elevade/Icon-LockOpenWhite.svg";
import IconSendButton from "common/images/icons/elevade/Icon-SendButton.svg";
import IconAttachment from "common/images/icons/elevade/Icon-Attachment.svg";
import firebase from "firebase/app";
import "firebase/storage";
import { showNotificationElevade } from "common/helpers";
import IconClose from "common/images/icons/elevade/Icon-Close.svg";
import SummaryDDMLForm from "./SummaryDDMLForm";
import NewDDMLForm from "./NewDDMLForm";
import { makeStyles, styled } from "@mui/styles";
import { useHistory, useLocation } from "react-router-dom";
import IconPDF from "common/images/icons/elevade/Icon-PDFAttachment.svg";
import IconImage from "common/images/icons/elevade/Icon-Image.svg";
import Preview from "../component/Preview";

const DDMLForm = ({
  aoc,
  type,
  open,
  setOpen,
  index,
  dataForm,
  edit,
  setEdit,
  openNotification,
  setOpenNotification,
  newForm,
  setNewForm,
  formError,
  setFormError,
  triggerApi,
  setTriggerApi,
  setSelectToMove,
  setOpenDialogMoveToAssignment,
  DDMLPermission,
  ...props
}) => {
  const entity = process.env.REACT_APP_ENTITY;
  let data = dataForm;
  if (dataForm?.ddml) data = dataForm?.ddml;
  const { userProfile } = useSelector((state) => state.authEntity);
  const { userProfile: userEntity, userPermission } = useSelector((state) => state.userEntity);
  const rectificationPermission = userPermission.filter((perm) => perm.code === "DASR-005");
  const materialPermission = userPermission.filter((perm) => perm.code === "DASR-006");
  const ppcPermission = userPermission.filter((perm) => perm.code === "DASR-007");
  const DDMLPermissions = userPermission.filter((perm) => perm.code === "DASR-003");
  const DASRMOC = userPermission.filter((perm) => perm.code === "DASR-004");
  const [limitation, setLimitation] = useState(false);
  const focusRef = useRef();
  const today = new Date();
  const formattedDate = today.toISOString();

  const location = useLocation();
  const isTaskAssignmentPage = location.pathname.includes("/task-assignment");
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const paramsId = urlParams.get("id");
  const history = useHistory();
  const pathname = useLocation().pathname;

  const [ntc, setNtc] = useState("No");
  const [reInspection, setReInspection] = useState(false);
  const [file, setFile] = useState([]);
  const [fileDDMLClose, setFileDDMLClose] = useState([{}]);
  const [value, setValue] = useState("1");
  const [id, setId] = useState("");
  const [openPreview, setOpenPreview] = useState(false);
  const [openPreviewComment, setOpenPreviewComment] = useState(false);
  const [openPreviewSummary, setOpenPreviewSummary] = useState(false);
  const [openConfirmCloseDDML, setConfirmCloseDDML] = useState(false);
  const [openConfirmReopenTicket, setConfirmReopenTicket] = useState(false);
  const handleOpen = () => setOpenPreview(true);
  const handleOpenSummary = () => setOpenPreviewSummary(true);
  const handleClose = () => setOpenPreview(false);
  const handleCloseSummary = () => setOpenPreviewSummary(false);
  const handleCloseComment = () => setOpenPreviewComment(false);
  const handleCloseConfirmCopy = () => setOpenConfirmCopy(false);
  const handleCloseConfirmCloseDDML = () => {
    setConfirmCloseDDML(false);
    setCloseDDMLForm(InitialCloseDDMLData);
    setFormError([]);
  };
  const handleCloseConfirmReopenTicket = () => setConfirmReopenTicket(false);
  const avatar = localStorage.getItem("avatar");
  const [commentData, setCommentData] = useState([
    {
      // ddmlId: data?._id,
      updateLogCategory: "",
      updateLogDesc: "",
      userCreated: {
        name: userProfile?.displayname || userEntity.fullName,
        avatarLink: userProfile?.avatar || avatar,
      },
      createdAt: formattedDate,
      attachmentLog: [
        {
          fileName: "",
          fileType: "",
          fileLink: "",
        },
      ],
    },
  ]);

  const commentRef = useRef(null);
  const [formType, setFormType] = useState();
  const [DDMLLogs, setDDMLLogs] = useState([]);
  const [aircraftList, setAircraftList] = useState([]);
  const [commentFileLink, setCommentFileLink] = useState();
  const [openConfirmCopy, setOpenConfirmCopy] = useState(false);
  const [commentPushSummaryId, setCommentPushSummaryId] = useState("");
  const [commentPushSummary, setCommentPushSummary] = useState("");
  const [category, setCategory] = useState();
  const [DDMLId, setDDMLId] = useState();
  const [departmentList, setDepartmentList] = useState([]);

  const InitialData = [
    {
      formType: formType,
      formStatus: "open",
      aircraftReg: "",
      aoc: aoc,
      location: "",
      ddmlNo: "",
      defectDesc: "",
      melCatType: "",
      melRef: "",
      mocHighlight: "",
      actionBy145: "",
      limitations: false,
      limitationsDesc: "",
      noticeToCrew: false,
      raisedDate: formattedDate,
      expiryDateCheck: true,
      expiryDate: null,
      assignedTo: "",
      attachment: [{ fileName: "", fileType: "", fileLink: "" }],
      flightHours: false,
      flightHoursInterval: "",
      flightHoursRaisedAt: "",
      flightHoursCurrent: "",
      flightCycle: false,
      flightCycleInterval: "",
      flightCycleRaisedAt: "",
      flightCycleCurrent: "",
      flightDays: false,
      flightDaysInterval: "",
      flightDaysStartDate: formattedDate,
      calendarDays: false,
      calendarDaysInterval: "",
      calendarDaysStartDate: formattedDate,
      createdAt: formattedDate,
      reInspection: false,
      // updatedAt: formattedDate,
      createdBy: userProfile?.userName || userEntity?.fullName,
      // AMD
      remainingType: "",
      // Structure
      showInTable: "",
    },
  ];

  const InitialCloseDDMLData = [
    {
      ddmlId: id,
      rectificationCategory: "",
      attachment: [],
      createdBy: userProfile?.userName || userEntity?.fullName,
      fullName: userEntity?.fullName,
    },
  ];

  const [formData, setFormData] = useState(InitialData);
  const [melCatType, setMelCatType] = useState(formData[0]?.melCatType);
  const [amdType, setAmdType] = useState(formData[0]?.amdType);
  const [closeDDMLForm, setCloseDDMLForm] = useState(InitialCloseDDMLData);
  const [raisedDate, setRaisedDate] = useState(formData[0].raisedDate);
  const [summaryPreviewUrl, setSummaryPreviewUrl] = useState("");
  const [isExpiry, setIsExpiry] = useState("-");
  const [isBalance, setIsBalance] = useState("-");
  const [selectedFile, setSelectedFile] = useState();
  const [previewUrl, setPreviewUrl] = useState();
  const [error, setError] = useState(false);
  const storageRef = firebase.storage().ref();
  const [uploadProgress, setUploadProgress] = useState(0);
  const [fileComment, setFileComment] = useState({});
  const [uploadComment, setUploadComment] = useState(false);
  const [inputCommentError, setInputCommentError] = useState(false);
  const [duplicate, setDuplicate] = useState(false);
  const [fileName, setFileName] = useState();

  const temporaryDepartmentList = [
    {
      id: "MOC (MAA)",
      name: "MOC (MAA)",
    },
    {
      id: "Planning (MAA)",
      name: "Planning (MAA)",
    },
    {
      id: "Material (MAA)",
      name: "Material (MAA)",
    },
    {
      id: "TSE (MAA)",
      name: "TSE (MAA)",
    },
    {
      id: "Maintenance (ADE)",
      name: "Maintenance (ADE)",
    },
    {
      id: "Purchasing (ADE)",
      name: "Purchasing (ADE)",
    },
    {
      id: "MP CWS (ADE)",
      name: "MP CWS (ADE)",
    },
    {
      id: "TSE (ADE)",
      name: "TSE (ADE)",
    },
    {
      id: "PPC (ADE)",
      name: "PPC (ADE)",
    },
    {
      id: "Planning (ADE)",
      name: "Planning (ADE)",
    },
  ];

  // Custom styles
  const useStyles = makeStyles(() => ({
    splitItems: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontSize: "12px",
      // display: "-webkit-box",
      // "-webkit-box-orient": "vertical",
      // "-webkit-line-clamp": 2, // Number of lines to show
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    // Set to open first tab
    setValue("1");
    // Clear the file state
    setFile([]);
    // Clear the melCatType state
    setMelCatType();
    // Clear the amdType state
    setAmdType();
    // Clear the form error state
    setFormError([]);
    // Set the initial raisedDate in the formData object
    setRaisedDate(formData[0].raisedDate);
    // set focus on the dummy input field
    focusRef.current.focus();
    // Set the DDML Type based on selected tab
    setFormData(updateArrayColumn("formType", formType));
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    setFormError([]);
    setFormData(updateArrayColumn("formType", formType));
    // eslint-disable-next-line
  }, [formType]);

  useEffect(() => {
    let array = aircraftList.filter((e) => e.name === formData[0].aircraftReg);
    setFormData(updateArrayColumn("location", array[0]?.location));
  }, [formData[0].aircraftReg]);

  useEffect(() => {
    // An asynchronous function to fetch the aircraft-daily-status data
    // Extract the aircraft reg to be used as a list in select components
    async function FetchData() {
      var currentDate = new Date();
      var options = { month: "long", day: "2-digit", year: "numeric" };
      var formattedDate = currentDate.toLocaleDateString("en-US", options);
      currentDate = new Date(formattedDate);
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const day = String(currentDate.getDate()).padStart(2, "0");
      const isToday = `${year}-${month}-${day}`;
      if (isToday !== "NaN-NaN-NaN") {
        const fetch = makeAPIRequestMDB(`get`, `api/aircraft/daily-status?aoc=${aoc}&date=${isToday}`);
        fetch
          .then((response) => {
            let theList = [];
            // Sort the list alphabetically
            let list = response.data?.sort((a, b) => a.aircraftReg?.localeCompare(b.aircraftReg));
            // TODO: to check back on this later
            // eslint-disable-next-line no-unused-expressions
            list?.map((e) => {
              // Create an object to hold the list, and push it onto "theList" object
              return theList.push({ code: e.aircraftReg, name: e.aircraftReg, location: e.status.hub });
            });
            // Set the object to the state
            setAircraftList(theList);
          })
          .catch((error) => {
            console.log("error", error);
          });
      }
    }

    async function getDepartmentList() {
      const fetch = makeAPIRequestMDB(`get`, `api/entity/departments?aoc=${aoc}`);
      fetch.then((response) => {
        let theList = [];
        // Sort the list alphabetically
        let list = response.data?.sort((a, b) => a.name?.localeCompare(b.name));
        // TODO: to check back on this later
        // eslint-disable-next-line no-unused-expressions
        list?.map((e) => {
          // Create an object to hold the list, and push it onto "theList" object
          return theList.push({ id: e.name.toUpperCase(), name: e.name.toUpperCase() });
        });
        // Set the object to the state
        if (list?.length > 0) setDepartmentList(theList);
        else setDepartmentList(temporaryDepartmentList);
      });
    }
    FetchData();
    getDepartmentList();
    // eslint-disable-next-line
  }, [aoc]);

  // On type (tab in Aircraft Report) change, set the "formType"
  useEffect(() => {
    if (type === "1") setFormType("MEL CAT");
    else if (type === "2") setFormType("AMD");
    else if (type === "3") setFormType("STR/FC/FH");
    else if (type === "4") setFormType("Hydraulic Sampling");
    else if (type === "5") setFormType("Fuel Sampling");
  }, [type]);

  useEffect(() => {
    // let data = edit && data.length === 0 ? formData : edit && data?.length !== 0 ? [data] : formData;
    let data_ = edit && data.length === 0 ? formData : edit && data?.length !== 0 ? [data] : formData;
    // ! Uncomment this to reset form on drawer close event
    if (edit && data?.length === 0) data_ = InitialData;
    setFormData(data_);
    if (data?.length !== 0) {
      setId(data?._id);
      // Push the object id onto the "moveToAssignment" state, this will enable the "Move to Assignment Draft List button"
      if (data.moveToAssignment === false || data.moveToAssignment === null) setSelectToMove([data?._id]);
      // else setSelectToMove([]);
    }
    // Set the ddmlId in the "commentData" object
    // setCommentData(updateArrayColumnComment("ddmlId", data?._id));
    // eslint-disable-next-line
  }, [edit, data, open, value]);

  useEffect(() => {
    // setFormData(updateArrayColumn("updatedAt", formattedDate));
  }, [edit]);

  // On file state change, update the "attachment" key in "formData" object
  useEffect(() => {
    setFormData(updateArrayColumn("attachment", file));
    // eslint-disable-next-line
  }, [file]);

  // A function to update the "attachment" key in "closeDDMLForm" object
  useEffect(() => {
    setCloseDDMLForm(updateArrayColumnDDMLClose("attachment", fileDDMLClose));
    // eslint-disable-next-line
  }, [fileDDMLClose]);

  // A function to update the "ddmlId" key in "closeDDMLForm" object
  useEffect(() => {
    setCloseDDMLForm(updateArrayColumnDDMLClose("ddmlId", id ? id : data.id));
    // eslint-disable-next-line
  }, [data, id]);

  // A function to set "updateLogCategory" key in "commentData" object based on the tab selected in the DDML form
  useEffect(() => {
    let Category = "";
    if (value === "2") Category = "Rectification Update";
    else if (value === "3") Category = "Material/Tools Update";
    else if (value === "4") Category = "PPC";
    setCommentData(updateArrayColumnComment("updateLogCategory", Category));
    // eslint-disable-next-line
  }, [value]);

  // Set "raisedDate" state on "formData" object state change
  useEffect(() => {
    setRaisedDate(formData[0].raisedDate);
    setMelCatType(formData[0].melCatType);
    setAmdType(formData[0].amdType);
  }, [formData]);

  // Calculate "expiryDate" based on "melCatType" and "raisedDate" state change, the update it in "formData" object
  useEffect(() => {
    const expiryDate = calculateExpiryDate(melCatType, raisedDate);
    if (!formData[0].flightDays) setFormData(updateArrayColumn("expiryDate", expiryDate));
    // eslint-disable-next-line
  }, [melCatType, raisedDate]);

  // Calculate "expiryDate" based on "amdType" state change, the update it in "formData" object
  useEffect(() => {
    if (formData[0].formType === "AMD") setFormData(updateArrayColumn("expiryDate", calcExpiryDateAMD()));
  }, [amdType, raisedDate]);

  // A function to update "attachmentLog" key in "commentData" object on "fileComment" change
  useEffect(() => {
    setCommentData(updateArrayColumnComment("attachmentLog", file));
    // eslint-disable-next-line
  }, [fileComment]);

  // On "id" of the object change, on drawer open, call an API for ddml-log list
  useEffect(() => {
    if (open && !edit) {
      DDMLLogList();
    }
    // eslint-disable-next-line
  }, [id, data?.id]);

  // Calculate the expiry & balance for AMD Deferment Expiry
  useEffect(() => {
    let calendarDaysInterval = formData[0].calendarDaysInterval || 0;
    let calendarDaysStartDate = formData[0].calendarDaysStartDate || formData[0].raisedDate;
    let expiry = addingDate(calendarDaysStartDate, calendarDaysInterval);
    let balance = calculateBalanceDay(calendarDaysStartDate?.split("T")[0], expiry?.split("T")[0]);

    if (formData[0].formType === "Fuel Sampling" || formData[0].formType === "Hydraulic Sampling")
      balance = calculateCalendarBalance(formData[0].calendarDaysStartDate, formData[0].calendarDaysInterval);

    if (formData[0].flightDays) {
      expiry = addingDate(formData[0].flightDaysStartDate, formData[0].flightDaysInterval);
      if (expiry !== undefined) setFormData(updateArrayColumn("expiryDate", expiry));
    }
    // if (!formData[0].flightDays) setFormData(updateArrayColumn("expiryDate", expiry));
    setIsExpiry(formatDateDasr(expiry));
    setIsBalance(balance);
    // eslint-disable-next-line
  }, [
    formData[0].raisedDate,
    formData[0].calendarDaysInterval,
    formData[0].calendarDaysStartDate,
    formData[0].flightDays,
    formData[0].flightDaysInterval,
    formData[0].flightDaysStartDate,
  ]);

  function calcExpiryDateAMD() {
    let add = amdType === "AMD" ? 120 : amdType === "CDL" ? 45 : formData[0]?.calendarDaysInterval;
    let startDate = formData[0].raisedDate;
    if (formData[0].calendarDaysStartDate !== null && amdType === "Other")
      startDate = formData[0].calendarDaysStartDate;
    return addingDate(startDate, add);
  }

  function handleCloseAndRedirect() {
    setOpen(false);
    if (paramsId) history.replace(pathname);
  }

  // Function to calculate expiry date based on CAT Type & Raised Date
  function calculateExpiryDate(melCatType, RaisedDate) {
    let daysToAdd;

    switch (melCatType) {
      case "CAT B":
        daysToAdd = 3;
        break;
      case "CAT C":
        daysToAdd = 10;
        break;
      case "CAT D":
        daysToAdd = 120;
        break;
      default:
        daysToAdd = 1;
        break;
    }

    // const date = new Date(RaisedDate);
    // date.setUTCDate(date.getUTCDate() + daysToAdd);

    // Ensure the time is set to 00:00:00.000 to match the required format
    // date.setUTCHours(0, 0, 0, 0);

    return addingDate(RaisedDate, daysToAdd);

    // Format the date to match 2023-08-05T00:00:00.000+00:00
    // return date.toISOString().replace("Z", "+00:00");
  }

  // Function to calculate expiry date for "calendarDays" attribute
  function calculateCalendarExpiryDate(raisedDate, interval) {
    if (raisedDate !== null && raisedDate !== "" && interval !== null && interval !== "") {
      let date = new Date(raisedDate);

      // Adding interval
      date.setDate(date.getDate() + parseInt(interval));
      // Return formatted date based on format "Month Day, Year"
      return formatDateDasr(date.toISOString());
    }
    return "-";
  }

  // !An important function to set, update and remove any value in the array of object : formData
  function updateArrayColumn(column, value) {
    return formData.map((item) => {
      if (column === "attachment") {
        return {
          ...item,
          [column]: value,
        };
      } else if (column === "raisedDate") {
        return { ...item, [column]: value + "T00:00:00.000+00:00" };
      }
      // Check if the item has the specified column and update it if exists
      else if (item.hasOwnProperty(column)) {
        return { ...item, [column]: value }; // Create a new object with updated column
      } else return { ...item, [column]: value }; // If the specified column doesn't exist, return the original item unchanged
    });
  }

  // !An important function to set, update and remove any value in the array of object : commentData
  function updateArrayColumnComment(column, value) {
    return commentData.map((item) => {
      if (column === "attachmentLog") {
        return {
          ...item,
          [column]: [
            {
              fileName: fileComment?.File?.name,
              fileType: fileComment?.File?.type,
              fileSize: fileComment?.File?.size,
              fileLink: fileComment?.Link,
            },
          ],
        };
      }
      // Check if the item has the specified column and update it if exists
      else if (item.hasOwnProperty(column)) {
        return { ...item, [column]: value }; // Create a new object with updated column
      } else return { ...item, [column]: value }; // If the specified column doesn't exist, return the original item unchanged
    });
  }

  // !An important function to set, update and remove any value in the array of object : closeDDMLForm
  function updateArrayColumnDDMLClose(column, value) {
    return closeDDMLForm.map((item) => {
      // Check if the item has the specified column and update it if exists
      if (item.hasOwnProperty(column)) {
        return { ...item, [column]: value }; // Create a new object with updated column
      } else return { ...item, [column]: value }; // If the specified column doesn't exist, return the original item unchanged
    });
  }

  // An async function to upload files to the server
  async function UploadFile(file) {
    if (file) {
      const uploadTask = storageRef.child(`dasr/uploads/${file.name}`).put(file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        },
        (error) => {
          console.error("Error uploading file:", error);
        },
        async () => {
          console.log("File uploaded successfully");
          const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
          setFileComment({ File: file, Link: downloadURL });
          setUploadProgress(0);
        }
      );
    }
  }

  async function UploadRenamedFile(file) {
    if (file) {
      // Generate a timestamp string.
      const timestamp = new Date().toISOString().replace(/:/g, "-");

      // Append the timestamp to the original filename.
      // This assumes file.name is a string like 'example.txt'.
      const fileNameWithTimestamp = `${file.name.split(".").slice(0, -1).join(".")}_${timestamp}.${file.name
        .split(".")
        .pop()}`;

      // Start the upload process with the new filename.
      const uploadTask = storageRef.child(`dasr/uploads/${id}/${fileNameWithTimestamp}`).put(file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        },
        (error) => {
          console.error("Error uploading file:", error);
        },
        async () => {
          console.log("File uploaded successfully");
          const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
          setFileComment({ File: file, Link: downloadURL });
          setUploadProgress(0);
        }
      );
    }
  }

  // A function to post the form data "formData" to the server
  // This function will be called when the user clicks on the "Add DDML" button
  function handleSubmit() {
    const params = `api/ddml`;
    async function CreateData() {
      const fetch = makeAPIRequestMDB(`post`, `${params}`, sanitizing(formData[0]));
      fetch
        .then((response) => {
          if (response.detail.includes("success")) {
            setOpenNotification(true);
            showNotificationElevade("success", "New DDML successfully created!", [
              "Your successfully created new report",
            ]);
          } else {
            setOpenNotification(true);
            showNotificationElevade("danger", "An error occured!", ["Please contact your system administartor"]);
          }
          setOpen(false);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    if (validateForm()) CreateData();
  }

  // A function to handle form data when user update the form data
  function handleUpdate() {
    const formData_ = formData[0];
    delete formData_.updatedAt;
    delete formData_.updatedBy;
    delete formData_.ddmlStatus;

    setFormData(updateArrayColumn("updatedAt", formattedDate));
    const params = `api/ddml`;
    async function UpdateData() {
      let formId = id !== undefined ? id : data.id;
      const fetch = makeAPIRequestMDB(`put`, `${params}/${formId}`, sanitizing(formData_));
      fetch
        .then((response) => {
          if (response.detail.includes("success")) {
            setOpenNotification(true);
            showNotificationElevade("success", "DDML successfully updated!", ["You successfully saved an update"]);
            setTriggerApi(true);
          } else {
            setOpenNotification(true);
            showNotificationElevade("danger", "An error occured!", ["Please contact your system administartor"]);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    // Validate the data to check for the  mandatory fields
    if (validateForm()) UpdateData();
  }

  // A function to validate for the mandatory fields
  function validateForm() {
    if (entity === "RBA") return true;
    // Set mandatory fields
    let array = [];
    // All forms
    if (formData[0].formType === "") array.push("formType");
    if (formData[0].aircraftReg === "") array.push("aircraftReg");
    if (formData[0].location === "") array.push("location");
    if (formData[0].ddmlNo === "") array.push("ddmlNo");
    if (formData[0].raisedDate === "" || formData[0].raisedDate === null) array.push("raisedDate");
    if (formData[0].attachment?.length === 0 || (formData[0].attachment === null && !DASRMOC)) array.push("attachment");

    // Additional based on form type
    if (formData[0].formType === "MEL CAT") {
      if (formData[0].limitations === "") array.push("limitations"); // MEL CAT Only
      if (formData[0].limitations === true && formData[0].limitationsDesc === "") array.push("limitationsDesc");
      if (formData[0].noticeToCrew === "") array.push("noticeToCrew"); // MEL CAT Only
      if (
        formData[0].melCatType === "CAT A" &&
        formData[0].flightDays === true &&
        (formData[0].flightDaysInterval === null ||
          formData[0].flightDaysInterval === "0" ||
          formData[0].flightDaysInterval === "")
      )
        array.push("flightDaysInterval");
    }

    if (formData[0].formType === "STR/FC/FH") {
      if (formData[0].limitations === true && formData[0].limitationsDesc === "") array.push("limitationsDesc");
    }

    if (formData[0].formType !== "MEL CAT") {
      if (
        formData[0].remainingType === "1" &&
        (formData[0].flightHoursInterval === "" ||
          formData[0].flightHoursRaisedAt === "" ||
          formData[0].flightHoursCurrent === "")
      )
        array.push("flightHours"); // Except MEL CAT
      if (
        formData[0].remainingType === "2" &&
        (formData[0].flightCycleInterval === "" ||
          formData[0].flightCycleRaisedAt === "" ||
          formData[0].flightCycleCurrent === "")
      )
        array.push("flightCycle"); // Except MEL CAT
      if (
        formData[0].remainingType === "3" &&
        (formData[0].calendarDaysInterval === "" || formData[0].calendarDaysDaisedAt === "")
      )
        array.push("calendarDays"); // Except MEL CAT
    }

    if (formData[0].formType === "AMD") {
      if (formData[0].amdType === "" || formData[0].amdType === undefined || formData[0].amdType === null)
        array.push("amdType");
      if (formData[0].amdType === "Other") {
        if (
          formData[0].calendarDaysInterval === "" ||
          formData[0].calendarDaysInterval === null ||
          formData[0].calendarDaysInterval === undefined
        )
          array.push("calendarDaysInterval");
        if (
          formData[0].calendarDaysStartDate === "" ||
          formData[0].calendarDaysStartDate === null ||
          formData[0].calendarDaysStartDate === undefined
        )
          array.push("calendarDaysStartDate");
      }
      if (formData[0].limitations === true && formData[0].limitationsDesc === "") array.push("limitationsDesc");
    }

    setFormError(array);
    if (array?.length === 0 && !duplicate) return true;
    else return false;
  }

  // A function to sanitizing, recalculating and updating data in the array
  // to ensure the data is properly formatted and updated
  function sanitizing(data) {
    function formatExpiryDate(date) {
      return date ? date.split("T")[0] + "T00:00:00" : null;
    }

    function handleMELCatA(data) {
      let expiryDate = addingDate(data.flightDaysStartDate, data.flightDaysInterval);
      expiryDate = formatExpiryDate(expiryDate);
      return { ...data, expiryDate: expiryDate };
    }

    function handleMELCatBtoD(data) {
      return { ...data, expiryDate: calculateExpiryDate(data.melCatType, data.raisedDate) };
    }

    function handleSampling(data) {
      let expiryDate = addingDate(data.calendarDaysStartDate, data.calendarDaysInterval);
      expiryDate = formatExpiryDate(expiryDate);
      return { ...data, expiryDate: expiryDate };
    }

    function handleAMD(data) {
      return { ...data, expiryDate: calcExpiryDateAMD() };
    }

    if (data.flightDays && data.formType === "MEL CAT" && data.melCatType === "CAT A") {
      return handleMELCatA(data);
    } else if (data.melCatType === "CAT B" || data.melCatType === "CAT C" || data.melCatType === "CAT D") {
      return handleMELCatBtoD(data);
    } else if (data.formType === "Hydraulic Sampling" || data.formType === "Fuel Sampling") {
      return handleSampling(data);
    } else if (data.formType === "AMD") {
      return handleAMD(data);
    }

    return data;
  }

  // A function to remove the element from the array
  // This function is specifically used for removing elements during validate form for mandatory fields
  function removeFromArray(col) {
    const indexToRemove = formError.indexOf(col);
    if (indexToRemove !== -1) {
      formError.splice(indexToRemove, 1);
    }
  }

  // A function to return if the field is exist in the "formError" array
  function validateError(col) {
    return formError.includes(col);
  }

  // A function used to push comment into the summary tab
  function handleCopy(e, id, category, ddmlId) {
    setOpenConfirmCopy(true);
    setCommentPushSummaryId(id);
    setCommentPushSummary(e);
    setCategory(category);
    setDDMLId(ddmlId);
  }

  // API called once the push to summary is confirmed
  async function PushToSummary() {
    const body = {
      logId: commentPushSummaryId,
      collectionId: DDMLId,
      category: category,
      pushToSummary: true,
    };
    const params = `api/ddml/logs/summary`;
    async function UpdateData() {
      const fetch = makeAPIRequestMDB(
        `put`,
        `${params}`,
        body
        // `${params}/${commentPushSummaryId}/true/${DDMLId}/${encodeURIComponent(category.replaceAll("/", "_"))}`
      );
      fetch
        .then((response) => {
          if (response.detail.includes("success")) {
            showNotificationElevade("success", "Update successfully pushed to summary!", [
              "Your successfully pushed new update to summary",
            ]);
            DDMLLogList();
          } else {
            showNotificationElevade("danger", "An error occured!", ["Please contact your system administartor"]);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    UpdateData();
  }

  // An API called to get the "ddml-logs" list of the selected DDML
  async function DDMLLogList() {
    let formId = id !== undefined ? id : data.id;
    if (formId !== "") {
      const params = `api/ddml/logs/${formId}`;
      const fetch = makeAPIRequestMDB(`get`, `${params}`);
      fetch.then((response) => {
        setDDMLLogs(response);
        setUploadComment(false);
      });
    }
  }

  // A function to set the file links for previewing event
  function handleOpenComment(e) {
    setCommentFileLink(e);
    setOpenPreviewComment(true);
  }

  function ConvertDate(date1_str) {
    // Convert the date string to a Date object
    var date1 = new Date(date1_str);

    // Function to get the month abbreviation
    function getMonthAbbreviation(month) {
      var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      return months[month];
    }

    // Function to format the time as HH:MM AM/PM
    function formatTime(date) {
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12;
      return hours + ":" + (minutes < 10 ? "0" : "") + minutes + " " + ampm;
    }

    // Convert the date to UTC+8 timezone
    var dateUtcPlus8 = new Date(date1.getTime() + 8 * 60 * 60 * 1000);
    // var dateUtcPlus8 = new Date(date1); // UTC datetime

    // Format the date into the desired format
    var formattedDate =
      getMonthAbbreviation(dateUtcPlus8.getUTCMonth()) +
      " " +
      dateUtcPlus8.getUTCDate() +
      ", " +
      dateUtcPlus8.getUTCFullYear() +
      " at " +
      formatTime(dateUtcPlus8);

    return formattedDate;
  }

  function handleOpenSummaryPreview(e) {
    setSummaryPreviewUrl(e);
    handleOpen();
  }

  function handleOpenPreview(fileLink, fileName) {
    setSummaryPreviewUrl(fileLink);
    setFileName(fileName);
    setOpenPreview(true);
  }

  function splitToChucks(str) {
    return (
      <div
        onClick={() => handleOpenPreview(str?.Link, str?.File?.name)}
        style={{
          cursor: "pointer",
          height: "100%",
          display: "flex",
          alignItems: "center",
          width: "340px",
          marginLeft: "8px",
        }}
      >
        {/* {firstChunk}
        <br />
        {otherChunks} */}
        <Typography className={classes.splitItems}>{str?.fileName || str?.File?.name}</Typography>
      </div>
    );
  }

  function getSummary(id, category, descr) {
    let ddmlLogs = DDMLLogs?.data?.filter((e) => e?.pushToSummary === true && e?.updateLogCategory === category);
    let latest = ddmlLogs?.at(-1);
    return latest?.updateLogDesc;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        fontSize: "12px",
      },
      children: `${name?.substring(0, 3)}`,
    };
  }

  function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string?.length; i += 1) {
      hash = string?.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
  }

  function validateCloseDDMLForm() {
    let array = [];
    if (closeDDMLForm[0].rectificationCategory === "") array.push("rectificationCategory");
    if (closeDDMLForm[0].attachment?.length === 0 || closeDDMLForm[0].attachment === "") array.push("attachment");

    setFormError(array);
    if (array?.length === 0) return true;
    else return false;
  }

  function handleSubmitComment() {
    // if (commentRef.current.value === "") {
    //   setInputCommentError(true);
    //   commentRef.current.focus();
    // } else {
    setUploadComment(true);
    setInputCommentError(false);

    SubmitComment();
    // }
  }

  async function SubmitComment() {
    let formId = id !== undefined ? id : data.id;
    const params = `api/ddml/logs`;
    const fetch = makeAPIRequestMDB(`post`, `${params}/${formId}`, commentData[0]);
    fetch
      .then((response) => {
        setSelectedFile("");
        setFileComment("");
        DDMLLogList();
        handleClearInput();
        setCommentData(updateArrayColumnComment("updateLogDesc", ""));
      })
      .catch((error) => {
        console.log("error", error);
        setUploadComment(false);
      });
  }

  // check permission for summary DDML Edit
  function SummaryDDMLFormPermission(summaryButton) {
    const summaryPermission = DDMLPermission.filter((perm) => perm.code === "DASR-004");
    if (summaryPermission.some((perm) => perm.canUpdate === true)) {
      switch (summaryButton) {
        case "Edit":
          return data?.formStatus === "closed" ? "disabled" : "none";
        case "Assignment":
          return data?.moveToAssignment === true || data?.formStatus === "closed" ? "disabled" : "outlined";
        // case "Close DDML":
        //   return data?.formStatus === "closed" ? "disabled" : "fill";
      }
    } else {
      return "disabled";
    }
  }

  //DDML action permission
  function DDMLPermissionUpdate() {
    if (DDMLPermissions.some((perm) => perm.canUpdate === true)) {
      return data?.formStatus === "closed" ? "disabled" : "fill";
    } else {
      return "disabled";
    }
  }

  function StatusDDMLFormPermission(tab) {
    // check permission for rectification, material and ppc add comment
    if (tab === "2") {
      const rectificationPermission = DDMLPermission.filter((perm) => perm.code === "DASR-005");
      // return rectificationPermission.some((perm) => perm.canUpdate === true) ? false : true;
      if (rectificationPermission.some((perm) => perm.canUpdate === true)) {
        return <>{addCommentStatus()}</>;
      } else {
        return <></>;
      }
    } else if (tab === "3") {
      const materialPermission = DDMLPermission.filter((perm) => perm.code === "DASR-006");
      if (materialPermission.some((perm) => perm.canUpdate === true)) {
        return <>{addCommentStatus()}</>;
      } else {
        return <></>;
      }
    } else if (tab === "4") {
      const PPCPermission = DDMLPermission.filter((perm) => perm.code === "DASR-007");
      if (PPCPermission.some((perm) => perm.canUpdate === true)) {
        return <>{addCommentStatus()}</>;
      } else {
        return <></>;
      }
    } else {
      return <></>;
    }
  }

  // Function to handle the action that should clear the input
  const handleClearInput = () => {
    // Set the value of the input directly using the ref
    commentRef.current.value = "";
  };

  const handleChange = (event, newValue) => {
    // Permission to view tab
    if (newValue === "2" && rectificationPermission.some((perm) => perm.canRetrieve === true)) {
      setValue(newValue);
    } else if (newValue === "3" && materialPermission.some((perm) => perm.canRetrieve === true)) {
      setValue(newValue);
    } else if (newValue === "4" && ppcPermission.some((perm) => perm.canRetrieve === true)) {
      setValue(newValue);
    } else if (newValue === "1") {
      setValue(newValue);
    }
    // setValue(newValue);
  };

  const acceptedFileTypes = ["application/pdf", "image/jpeg", "image/png", "image/tiff"];
  const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (!file) {
      setSelectedFile(null);
      setPreviewUrl(null);
      return;
    }

    if (file.size <= maxFileSize && acceptedFileTypes.includes(file.type)) {
      setError(false);
      setSelectedFile(file);
      setPreviewUrl(URL.createObjectURL(file));
      UploadRenamedFile(file);
    } else {
      setError(true);
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  const handleComment = (event) => {
    setCommentData(updateArrayColumnComment("updateLogDesc", event.target.value.toUpperCase()));
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (event.target.value !== "") {
        // handleSubmitComment();
      }
      // event.currentTarget.value = "";
    }
  };

  const handleConfirmCopy = () => {
    PushToSummary();
    setOpenConfirmCopy(false);
    setTriggerApi(true);
  };

  const DDMLTitle = () => {
    return (
      <>
        <span
          style={{
            border: "1px solid #C3C3C3",
            borderRadius: "6px",
            padding: "4px 8px",
            marginRight: "8px",
            whiteSpace: "nowrap",
          }}
        >
          {data?.aircraftReg}
        </span>
        <span style={{ textTransform: "uppercase" }}>{data?.ddmlNo}</span>
      </>
    );
  };

  const handleConfirmCloseDDML = () => {
    let params = `api/ddml/close`;
    async function closeDDML() {
      const fetch = makeAPIRequestMDB(`put`, `${params}/${closeDDMLForm[0].ddmlId}`, closeDDMLForm[0]);
      fetch
        .then((response) => {
          if (response.detail.includes("success")) {
            showNotificationElevade("success", "DDML successfully closed!", ["Your successfully close DDML"]);
            handleCloseConfirmCloseDDML();
            setTriggerApi(!triggerApi);
            setOpen(false);
            setCloseDDMLForm([
              {
                ddmlId: id,
                rectification_category: "",
                attachment: "",
                createdBy: userProfile?.userName || userEntity?.fullName,
              },
            ]);
            setFileDDMLClose([{}]);
          } else {
            showNotificationElevade("danger", "An error occured!", ["Please contact your system administartor"]);
            handleCloseConfirmCloseDDML();
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    if (validateCloseDDMLForm()) closeDDML();
  };

  const handleConfirmReopenTicket = (id, ddmlNo) => {
    let params = `api/ddml/reopen_record`;
    const body = {
      formId: id,
      formStatus: "open",
    };
    async function reopenDML() {
      const fetch = makeAPIRequestMDB(`put`, `${params}/${id}`, body);
      fetch
        .then((response) => {
          if (response.detail.includes("success")) {
            showNotificationElevade("success", `Successfully Reopened: DDML No. ${ddmlNo}`, [
              `Your DDML No. ${ddmlNo} has been successfully reopened!`,
            ]);
            handleCloseConfirmReopenTicket();
            setTriggerApi(!triggerApi);
            setOpen(false);
          } else {
            showNotificationElevade("danger", "An error occured!", ["Please contact your system administartor"]);
            handleCloseConfirmCloseDDML();
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    reopenDML();
  };

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
      // borderRadius: 8,
      position: "relative",
      backgroundColor: "#ffffff",
      border: "0px solid",
      borderColor: "#E0E3E7",
      fontSize: 14,
      padding: "4px",
      transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
      fontFamily: ["Gotham-Book"].join(","),
      "&:focus": {
        borderColor: theme.palette.primary.main,
      },
      textTransform: "uppercase",
    },
  }));

  const addCommentStatus = () => {
    return (
      <>
        <div
          style={{
            width: "100%",
            position: "absolute",
            bottom: "0px",
            left: "0px",
            borderTop: "1px solid #D1D1D1",
            backgroundColor: "#ffffff",
            borderRadius: "8px 8px 0px 0px",
            // padding: "16px",
            zIndex: 2,
          }}
        >
          <Grid container columns={6}>
            <Grid item xs={0.5} sx={{ padding: "16px" }}>
              <label style={{ cursor: "pointer" }}>
                <input type="file" onChange={handleFileChange} style={{ display: "none" }} />
                <img src={IconAttachment} alt="attachment" />
              </label>
            </Grid>
            <Grid item xs={5} sx={{ padding: "8px 0" }}>
              {selectedFile && (
                <>
                  <div
                    style={{
                      height: "35px",
                      borderRadius: "4px",
                      marginRight: "10px",
                      display: "flex",
                    }}
                  >
                    {fileComment?.Link?.includes(".pdf?") ? (
                      <img src={IconPDF} alt="icon-pdf" />
                    ) : (
                      <img src={IconImage} alt={`Preview`} style={{ width: "40px", height: "35px" }} />
                    )}
                    <div onClick={handleOpenSummary} style={{ cursor: "pointer" }}>
                      {splitToChucks(fileComment)}
                    </div>
                  </div>
                </>
              )}
            </Grid>
            <Grid item xs={0.5} sx={{ padding: "8px 0" }}>
              {selectedFile && (
                <img
                  src={IconClose}
                  alt="icon-close"
                  height={15}
                  style={{ marginTop: "2px", cursor: "pointer" }}
                  onClick={() => setSelectedFile("")}
                />
              )}
            </Grid>
            <Grid item xs={6} sx={{ padding: "8px 16px", borderTop: "1px solid  #D1D1D1" }}>
              <BootstrapInput
                // ref={inputComment}
                className={`${styles.inputComment} ${inputCommentError ? styles.inputCommentError : ""}`}
                placeholder="Comment here"
                onBlur={(e) => handleComment(e)}
                onKeyDown={handleKeyDown}
                defaultValue={commentData[0].updateLogDesc}
                ref={commentRef}
                multiline={true}
                // value={comment.toUpperCase()}
              />
              <img
                src={IconSendButton}
                alt="post-comment"
                style={{ cursor: "pointer", position: "fixed", bottom: "20px", right: "20px" }}
                onClick={() => handleSubmitComment()}
              />
            </Grid>
          </Grid>
        </div>
      </>
    );
  };

  return (
    <>
      <div
        style={{
          paddingLeft: edit ? "20px" : "0px",
          marginRight: edit ? "20px" : "0px",
          paddingBottom: "120px",
          paddingRight: "0px",
          zIndex: 2,
          overflowY: "hidden",
          overflowX: "hidden",
          width: "100%",
          position: "relative",
        }}
        className={`${styles.noScrollbars}`}
      >
        <div
          style={{
            width: "50%",
            position: "fixed",
            top: "60px",
            backgroundColor: "#ffffff",
            padding: "16px",
            zIndex: 999,
            marginLeft: edit ? "-20px" : "",
            borderBottom: "0.5px solid #C3C3C3",
            overflowX: "auto",
            overflowY: "hidden",
          }}
        >
          <Grid container columns={2}>
            <Grid item xs={1} sx={{ padding: "4px" }} className={styles.title}>
              {edit && data?.length === 0 && "DDML Form"}
              {edit && data?.length !== 0 && <DDMLTitle />}
              {!edit && <DDMLTitle />}
            </Grid>
            <Grid
              item
              xs={1}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "4px",
              }}
            >
              <img
                src={IconClose}
                alt="icon-close"
                height={15}
                style={{ marginTop: "2px", cursor: "pointer" }}
                onClick={() => handleCloseAndRedirect()}
              />
            </Grid>
          </Grid>
        </div>
        {!edit && (
          <div
            style={{
              width: "100%",
              position: "",
              top: "16.3%",
              zIndex: 3,
              border: "0px solid red",
            }}
          >
            {/* The input used as a dummy autofocus, so the form is focus on top on open */}
            <input
              id="name"
              // autoFocus
              ref={focusRef}
              style={{ opacity: 1, height: 0, position: "absolute", top: "0px" }}
            />
            <SummaryDDMLForm
              value={value}
              handleChange={handleChange}
              data={data}
              initialData={dataForm}
              calculateCalendarExpiryDate={calculateCalendarExpiryDate}
              getSummary={getSummary}
              calculateCalendarBalance={calculateCalendarBalance}
              stringAvatar={stringAvatar}
              splitToChucks={splitToChucks}
              openPreview={openPreview}
              setOpenPreview={setOpenPreview}
              handleClose={handleClose}
              summaryPreviewUrl={summaryPreviewUrl}
              DDMLLogs={DDMLLogs}
              handleCopy={handleCopy}
              handleOpenComment={handleOpenComment}
              ConvertDate={ConvertDate}
              openPreviewComment={openPreviewComment}
              handleCloseComment={handleCloseComment}
              style={style}
              commentFileLink={commentFileLink}
              calculateExpiryDate={calculateExpiryDate}
              uploadComment={uploadComment}
              setUploadComment={setUploadComment}
              {...props}
            />
          </div>
        )}
        {edit && (
          <div style={{ paddingRight: "40px" }}>
            {/* The input used as a dummy autofocus, so the form is focus on top on open */}
            <input
              id="name"
              autoFocus
              ref={focusRef}
              style={{ opacity: 1, height: 0, position: "absolute", top: "-10px" }}
            />
            <NewDDMLForm
              formData={formData}
              setFormData={setFormData}
              updateArrayColumn={updateArrayColumn}
              setFormType={setFormType}
              removeFromArray={removeFromArray}
              formType={formType}
              aircraftList={aircraftList || props.aircraftList}
              validateError={validateError}
              melCatType={melCatType}
              setMelCatType={setMelCatType}
              isExpiry={isExpiry}
              isBalance={isBalance}
              limitation={limitation}
              setLimitation={setLimitation}
              ntc={ntc}
              setNtc={setNtc}
              reInspection={reInspection}
              setReInspection={setReInspection}
              file={file}
              setFile={setFile}
              departmentList={departmentList}
              validateForm={validateForm}
              setTriggerApi={setTriggerApi}
              duplicate={duplicate}
              setDuplicate={setDuplicate}
            />
          </div>
        )}
      </div>
      {value !== "1" && !newForm && ""}
      <div
        style={{
          position: "fixed",
          bottom: "0",
          right: "0",
          width: "50%",
          borderTop: "1px solid #D1D1D1",
          backgroundColor: "#ffffff",
          padding: "20px 24px 10px 24px",
          display: "flex",
          justifyContent: "flex-end",
          zIndex: 2,
        }}
      >
        {newForm && edit && !props.hideButton ? (
          <>
            <AdeButton
              variant="outlined"
              sx={{
                borderRadius: "8px",
                border: "0px solid #e8e8e8",
                fontFamily: "Gotham-Medium",
                pl: 6,
                pr: 6,
                mr: 4,
                "&.MuiButton-root:hover": { border: "transparent" },
              }}
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancel
            </AdeButton>
            <AdeButton
              variant="contained"
              sx={{
                borderRadius: "8px",
                fontFamily: "Gotham-Medium",
                pl: 6,
                pr: 6,
                "&.MuiButton-root": { border: "transparent" },
              }}
              onClick={!newForm ? handleUpdate : handleSubmit}
            >
              Add DDML
            </AdeButton>
            {/* <Grid container columns={2}>
                <Grid item xs={1} sx={{ paddingRight: "12px" }}>
                  
                </Grid>
                <Grid item xs={1} sx={{ paddingLeft: "12px" }}>
                  
                </Grid>
              </Grid> */}
          </>
        ) : !newForm && edit && !props.hideButton ? (
          <>
            <AdeButton
              variant="outlined"
              sx={{
                borderRadius: "8px",
                border: "0px solid #e8e8e8",
                fontFamily: "Gotham-Medium",
                pl: 6,
                pr: 6,
                mr: 4,
                "&.MuiButton-root:hover": { border: "transparent" },
              }}
              onClick={() => {
                handleCloseAndRedirect();
              }}
            >
              Cancel
            </AdeButton>
            <AdeButton
              variant="contained"
              sx={{
                borderRadius: "8px",
                fontFamily: "Gotham-Medium",
                pl: 6,
                pr: 6,
                "&.MuiButton-root": { border: "transparent" },
              }}
              onClick={handleUpdate}
            >
              Save Changes
            </AdeButton>
            {/* <Grid container columns={2}>
                <Grid item xs={1} sx={{ paddingRight: "12px" }}>
                  
                </Grid>
                <Grid item xs={1} sx={{ paddingLeft: "12px" }}>
                  
                </Grid>
              </Grid> */}
          </>
        ) : !newForm && !edit && value === "1" && !props.hideButton ? (
          <>
            <Button
              title="Edit"
              // variant={data.formStatus === "closed" ? "disabled" : "none"}
              variant={SummaryDDMLFormPermission("Edit")}
              onClick={() => setEdit(true)}
              marginTop="0px"
            />
            {!isTaskAssignmentPage && (
              <Button
                title="Assignment"
                icon={IconBriefcase}
                iconWhite={IconBriefcaseWhite}
                iconDisabled={IconBriefcaseDisabled}
                // variant={
                //   data.moveToAssignment === true || data.formStatus === "closed" ? "disabled" : "outlined"
                // }
                variant={SummaryDDMLFormPermission("Assignment")}
                onClick={() => setOpenDialogMoveToAssignment(true)}
                marginTop="0px"
              />
            )}
            <Button
              title="Close DDML"
              icon={IconCheckCircleWhite}
              iconDisabled={IconCheckCircleDisabled}
              // variant={data.formStatus === "closed" ? "disabled" : "fill"}
              // variant={SummaryDDMLFormPermission("Close DDML")}
              variant={DDMLPermissionUpdate()}
              onClick={() => setConfirmCloseDDML(true)}
              marginTop="0px"
            />
            {/* <Grid container columns={!isTaskAssignmentPage ? 3 : 2}>
                <Grid item xs={1} sx={{ paddingRight: "12px" }}></Grid>
                {!isTaskAssignmentPage && <Grid item xs={1} sx={{ paddingRight: "12px", paddingLeft: "12px" }}></Grid>}
                <Grid item xs={1} sx={{ paddingLeft: "12px" }}>
                  
                </Grid>
              </Grid> */}
          </>
        ) : data.formStatus === "deleted" ? (
          <>
            <Button
              title="Cancel"
              variant={"outlined"}
              onClick={() => handleCloseAndRedirect()}
              marginTop="0px"
              border="1px solid transparent"
            />
            <Button
              title="Reopen DDML"
              icon={IconLockOpenWhite}
              iconWhite={IconLockOpenWhite}
              iconDisabled={IconBriefcaseDisabled}
              // variant={SummaryDDMLFormPermission("Close DDML")}
              variant={DDMLPermissionUpdate()}
              onClick={() => setConfirmReopenTicket(true)}
              marginTop="0px"
            />
          </>
        ) : (
          ""
        )}
        {value !== "1" && !newForm && !props.hideButton && <>{StatusDDMLFormPermission(value)}</>}
      </div>
      {/* <Modal
        open={openPreviewSummary}
        onClose={handleCloseSummary}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <img
              src={previewUrl}
              alt={`Preview`}
              style={{
                width: "100%",
                maxWidth: "1000px",
                height: "100%",
                maxHeight: "700px",
              }}
            />}
          <div style={{ alignItems: "center" }}>
            <img src={previewUrl} alt={`Preview`} style={{ objectFit: "contain", width: "100%", height: "100%" }} />
          </div>
        </Box>
      </Modal> */}
      <Modal
        open={openConfirmCopy}
        onClose={handleCloseConfirmCopy}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            border: "0px solid #000",
            boxShadow: 24,
            padding: "16px 0px",
            borderRadius: "8px",
          }}
        >
          <Grid container columns={1}>
            <Grid
              item
              xs={0.8}
              sx={{
                padding: "0px 24px 16px 24px",
                borderBottom: "1px solid #D1D1D1",
              }}
            >
              <span style={{ fontFamily: "Gotham-Medium" }}>Copy and replace to Summary</span>
            </Grid>
            <Grid
              item
              xs={0.2}
              sx={{
                padding: "0px 24px 16px 24px",
                borderBottom: "1px solid #D1D1D1",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <img
                src={IconClose}
                alt="icon-close"
                height={12}
                style={{ cursor: "pointer" }}
                onClick={() => setOpenConfirmCopy(false)}
              />
            </Grid>
            <Grid item xs={1} sx={{ padding: "32px 64px" }}>
              <div
                style={{
                  fontFamily: "Gotham-Book",
                  marginBottom: "24px",
                  color: "#838383",
                  fontSize: "14px",
                }}
              >
                This will replace the existing rectification status in Summary Tab with this information:
              </div>
              <div style={{ fontFamily: "Gotham-medium", marginBottom: "24px" }}>“{commentPushSummary}”</div>
              <div style={{ fontFamily: "Gotham-Book", marginBottom: "0px", color: "#838383", fontSize: "14px" }}>
                Do you want to proceed?
              </div>
            </Grid>
            <Grid
              item
              xs={1}
              sx={{
                display: "flex",
                justifyContent: "center",
                paddingBottom: "16px",
              }}
            >
              <Button
                title="Cancel"
                variant=""
                onClick={() => {
                  setOpenConfirmCopy(false);
                }}
              />
              <Button title="Proceed" variant="fill" onClick={handleConfirmCopy} />
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Modal
        open={openConfirmCloseDDML}
        onClose={handleCloseConfirmCloseDDML}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 580,
            bgcolor: "background.paper",
            border: "0px solid #000",
            boxShadow: 24,
            padding: "32px 64px",
            borderRadius: "8px",
          }}
        >
          <Grid container columns={1}>
            <Grid
              item
              xs={1}
              sx={{
                paddingBottom: "16px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div style={{ fontFamily: "Gotham-Medium", fontSize: "16px" }}>Insert DDML Rectification Status</div>
            </Grid>
            <Grid item xs={1} sx={{ paddingBottom: "16px", color: "#838383" }}>
              <div style={{ fontFamily: "Gotham-Book", fontSize: "14px" }}>
                <center>Please insert rectification status before closing this DDML</center>
              </div>
            </Grid>
            <Grid item xs={1} sx={{ paddingTop: "16px" }}>
              <InputField
                fullWidth
                placeholder={"Insert text here"}
                formData={closeDDMLForm}
                setFormData={setCloseDDMLForm}
                col={"rectificationCategory"}
                updateArrayColumn={updateArrayColumnDDMLClose}
                error={validateError("rectificationCategory")}
              />
            </Grid>
            <Grid item xs={1} sx={{ paddingTop: "16px" }}>
              <NewFileUploadComponent
                formData={closeDDMLForm}
                file={fileDDMLClose}
                setFile={setFileDDMLClose}
                errorForm={validateError("attachment")}
              />
            </Grid>
            <Grid
              item
              xs={1}
              sx={{
                paddingTop: "16px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button title="Cancel" variant="outlined" onClick={handleCloseConfirmCloseDDML} />
              <Button title="Proceed" variant="fill" onClick={handleConfirmCloseDDML} />
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Modal
        open={openConfirmReopenTicket}
        onClose={handleCloseConfirmReopenTicket}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            border: "0px solid #000",
            boxShadow: 24,
            padding: "16px 0px",
            borderRadius: "8px",
          }}
        >
          <Grid container columns={1}>
            <Grid
              item
              xs={0.8}
              sx={{
                padding: "0px 24px 16px 24px",
                borderBottom: "1px solid #D1D1D1",
              }}
            >
              <span style={{ fontFamily: "Gotham-Medium" }}>Reopen Ticket Confirmation</span>
            </Grid>
            <Grid
              item
              xs={0.2}
              sx={{
                padding: "0px 24px 16px 24px",
                borderBottom: "1px solid #D1D1D1",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <img
                src={IconClose}
                alt="icon-close"
                height={12}
                style={{ cursor: "pointer" }}
                onClick={() => setOpenConfirmCopy(false)}
              />
            </Grid>
            <Grid item xs={1} sx={{ padding: "32px 64px" }}>
              <div
                style={{
                  fontFamily: "Gotham-Bold",
                  marginBottom: "24px",
                  color: "#838383",
                  fontSize: "14px",
                }}
              >
                Are you sure want to reopen DDML No. {data.ddmlNo}
              </div>
              <div style={{ fontFamily: "Gotham-Book", marginBottom: "0px", color: "#838383", fontSize: "14px" }}>
                Do you want to proceed?
              </div>
            </Grid>
            <Grid
              item
              xs={1}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                paddingBottom: "16px",
                paddingRight: "12px",
              }}
            >
              <Button
                title="Cancel"
                variant=""
                onClick={() => {
                  handleCloseConfirmReopenTicket(false);
                }}
              />
              <Button title="Proceed" variant="fill" onClick={() => handleConfirmReopenTicket(data._id, data.ddmlNo)} />
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Preview open={openPreview} setOpen={setOpenPreview} fileLink={summaryPreviewUrl} fileName={fileName} />
    </>
  );
};

export default DDMLForm;
