import React from "react";
import { ClickAwayListener } from "@mui/base";
import { Drawer, Slide } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";

const ElevadeDrawer = ({ open, setOpen, data, Component, ...props }) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const paramsId = urlParams.get("id");
  const history = useHistory();
  const pathname = useLocation().pathname;

  function handleClose() {
    setOpen(false);
    if (paramsId) history.replace(pathname);
  }

  return (
    <>
      <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={() => open && setOpen(true)}>
        <Drawer
          anchor={"right"}
          open={open}
          onEscapeKeyDown={() => {
            handleClose();
          }}
          PaperProps={{
            sx: {
              width: "50%",
              color: "black",
              height: props.height ?? "100%",
              top: props.top ?? "0%",
              boxShadow: "0px 8px 19px 0px rgba(0, 0, 0, 0.20)",
              backgroundColor: "#ffffff",
            },
          }}
          BackdropProps={{ invisible: true }}
          onClose={() => open && handleClose()}
          sx={{
            zIndex: 100,
          }}
          TransitionComponent={Slide}
          transitionDuration={{
            enter: 350,
            exit: 350,
          }}
        >
          <Component data={data} open={open} setOpen={setOpen} {...props} />
        </Drawer>
      </ClickAwayListener>
    </>
  );
};

export default ElevadeDrawer;
