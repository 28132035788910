import "@ade/global-component-web/dist/index.css";
import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
// import reportWebVitals from "./reportWebVitals";
import { store, persistor } from "./common/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "common/theme/theme";
import { NotificationProvider } from "@ade/global-component-web";

import { createRoot } from "react-dom/client";

const domNode = document.getElementById("root");
const root = createRoot(domNode);

// root.render(
//   <ThemeProvider theme={theme}>
//     <Provider store={store}>
//       <PersistGate persistor={persistor}>
//         <React.StrictMode>
//           <App />
//         </React.StrictMode>
//       </PersistGate>
//     </Provider>
//   </ThemeProvider>
// );

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <React.StrictMode>
          {/* <Helmet> */}
          <App />
          {/* </Helmet> */}
        </React.StrictMode>
      </PersistGate>
    </Provider>
  </ThemeProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
