import { useState, useEffect } from "react";
import axios from "axios";

/**
 * @typedef {Object} UseGetGQLProps
 * @property {string} gqlquery
 * @property {string} url
 * @property {string} querySign
 * @property {boolean} isEdges
 * @property {boolean} isUnfiltered
 * @property {boolean} defaultIsLoading
 * @property {string} [totalCountSign]
 * @property {string} [totalCountFilteredSign]
 */
/**
 * useGetGQL
 * @param {UseGetGQLProps} props 
 */
export default function useGetGQL(props) {

	const {
		gqlquery,
		url,
		querySign,
		isEdges = true,
		isUnfiltered = false,
		defaultIsLoading = false,
		totalCountSign = 'totalCount',
		totalCountFilteredSign = 'totalFilters'
	} = props

	const [data, setData] = useState([]);
	const [isLoading, setIsloading] = useState(defaultIsLoading);
	const [errorMessage, setErrorMessage] = useState(null);
	const [isError, setIsError] = useState(false);
	const [totalCount, setTotalCount] = useState();
	const [totalCountFiltered, setTotalCountFiltered] = useState();

	let controller = new AbortController();

	function fetch() {
		setIsloading(true);
		setErrorMessage(null);
		setIsError(false);
		axiosGraphqlRequest(url, gqlquery, {
			controller,
		}).then(({ data, isError, errorMessage, isCanceled }) => {
			setIsError(isError);
			setErrorMessage(errorMessage);
			let specficData = isEdges ? data?.data?.[querySign]?.edges : data?.data?.[querySign];
			if (isUnfiltered) {
				specficData = data
			}
			setData(specficData ?? []);
			setTotalCount((data?.data?.[querySign]?.[totalCountSign] ?? specficData?.length) ?? 0);
			setTotalCountFiltered((data?.data?.[querySign]?.[totalCountFilteredSign] ?? specficData?.length) ?? 0);
			if (!isCanceled) {
				setIsloading(false);
			}
		}).catch((err) => {
			setIsError(true);
			setErrorMessage(JSON.stringify({ err }));
			setIsloading(false);
		}); /* .finally(() => {
            setIsloading(false)
        }) */
	}

	useEffect(() => {
		fetch();
		return () => {
			controller.abort();
		};
	}, [gqlquery, querySign, url]);

	function refetch() {
		fetch();
	}

	return {
		data,
		isLoading,
		isError,
		errorMessage,
		refetch,
		totalCount: totalCount ?? 0,
		totalCountFiltered: totalCountFiltered ?? 0,
	};
}

export async function axiosGraphqlRequest(url, gqlQuery, options) {
	const graphqlQuery = {
		query: gqlQuery,
		variables: options && options.variables ? options.variables : {}
	};
	try {
		const { data } = await axios({
			url,
			headers: {
				authorization: options && options.jwtToken ? options.jwtToken : '',
				'Content-Type': 'application/json',
			},
			data: graphqlQuery,
			method: 'POST',
			signal: options?.controller?.signal,
		});
		if (data && data.errors && data.errors[0] && data.errors[0].message) {
			throw new Error(data.errors[0].message);
		}
		return {
			data,
			isError: false,
			errorMessage: null
		};
	}
	catch (err) {
		if (axios.isCancel(err)) {
			return {
				data: null,
				isError: false,
				isCanceled: true,
				errorMessage: 'Request canceled'
			};
		}
		return {
			data: null,
			isError: true,
			errorMessage: err.message
		};
	}
}
