import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./AircraftMonitoringSummary.module.css";
import IconDown from "common/images/icons/elevade/Icon-Down.svg";
import FleetCardHigh from "common/images/icons/elevade/Fleet-Card-High.svg";
import FleetCardMed from "common/images/icons/elevade/Fleet-Card-Med.svg";
import FleetCardLow from "common/images/icons/elevade/Fleet-Card-Low.svg";
import { uniqueList } from "../component/Helpers";
import useMediaQuery from "@mui/material/useMediaQuery";
import { AOCListV2 } from "common/constant/AOCListV2";

const AircraftMonitoringSummary = ({ data, opr, AOC }) => {
  const [accessLevel] = useState("Manager");
  const [open, setOpen] = useState(false);
  const [opr_, setOpr_] = useState(opr);
  // const isWeb = useMediaQuery("(min-width:900px)");
  // const isTablet = useMediaQuery("(min-width:760px)");
  const isMobile = useMediaQuery("(max-width:900px)");

  useEffect(() => {
    setOpr_(uniqueList(data, "Operator"));
  }, [data]);

  let operators = [];
  let operator = [
    ...new Set(
      data &&
        data.map((item) => {
          return item["Operator"];
        })
    ),
  ];
  let sorted = operator.sort();
  sorted.forEach((item) => {
    if (item !== null) {
      operators.push(
        <option key={item} value={item}>
          {item}
        </option>
      );
    }
  });

  let aircraftTypes = [];
  let aircraftType = [
    ...new Set(
      data &&
        data.map((item) => {
          return item["Type"];
        })
    ),
  ];
  sorted = aircraftType.sort();
  sorted.forEach((item) => {
    if (item !== null) {
      aircraftTypes.push(
        <option key={item} value={item}>
          {item}
        </option>
      );
    }
  });

  function GetOperatorName(operator) {
    switch (operator) {
      case "MAA":
        return "Malaysia AirAsia (MAA)";
      case "AAX":
        return "AirAsia X (AAX)";
      case "AAZ":
        return "Philippines AirAsia (AAZ)";
      case "PAA":
        return "Philippines AirAsia (PAA)";
      case "IAA":
        return "Indonesia AirAsia (IAA)";
      case "TAA":
        return "Thailand AirAsia (TAA)";
      case "TAAX":
        return "Thailand AirAsia X (TAAX)";
      case "AAC":
        return "AirAsia Cambodia (AAC)";
      default:
        return operator;
    }
  }

  function GetMultipleOperatorName(opr) {
    return (
      <div className={`${styles.tooltip}`}>
        {opr?.length === CountAoc("airasia")
          ? "All AirAsia"
          : opr?.length < 6
          ? opr?.toString().split(",").sort().join(", ")
          : "..."}
        <span data-html="true" data-toggle="tooltip" className={`${styles.tooltiptext}`}>
          {opr?.toString().split(",").join(", ")}
        </span>
      </div>
    );
  }

  function CountAoc(airline) {
    let array = AOCListV2.filter((e) => e.airline === airline);
    return array[0].AOC.length;
  }

  function GetTotalByPriority(priority) {
    const Total = data.filter((item) => item.priority === priority);
    return Total.length;
  }

  function GetTotalByPriorityHubs(operator, priority) {
    const Total = data.filter((item) => item.priority === priority && item.Operator === operator);
    return Total.length;
  }

  function GetTotalFaultManager(priority) {
    const Total = data.filter((item) => item.priority === priority);
    let FleetCard = FleetCardHigh;
    if (priority === "Medium") FleetCard = FleetCardMed;
    else if (priority === "Low") FleetCard = FleetCardLow;
    return !isMobile ? (
      <>
        <Grid container columns={4}>
          <Grid item xs={1.5} sm={1.5} md={1} lg={0.8} xl={0.8}>
            <img src={FleetCard} alt="High" />
          </Grid>
          <Grid item xs={2.5} sm={2.5} md={3} lg={3.2} xl={3.2}>
            <div className={styles.total}>{Total.length}</div>
            {!isMobile ? <div className={styles.totalName}>{priority} Fault Priority</div> : ""}
          </Grid>
          {isMobile && (
            <Grid item xs={4}>
              <div className={styles.totalName}>{priority} Fault Priority</div>
            </Grid>
          )}
        </Grid>
      </>
    ) : (
      <>
        <Grid container columns={2}>
          <Grid item xs={2} sx={{ marginLeft: "-8px" }}>
            <img src={FleetCard} alt="High" />
          </Grid>
          <Grid item xs={2} sx={{ marginLeft: "-16px" }}>
            <div className={styles.total}>{Total.length}</div>
          </Grid>
          <Grid item xs={2} sx={{ display: "flex", justifyContent: "center" }}>
            <div className={styles.totalName}>{priority} Fault Priority</div>
          </Grid>
        </Grid>
      </>
    );
  }

  function GetTotalFault(operator, priority) {
    const Total = data.filter((item) => item.Operator === operator.key && item.priority === priority);
    let FleetCard = FleetCardHigh;
    if (priority === "Medium") FleetCard = FleetCardMed;
    else if (priority === "Low") FleetCard = FleetCardLow;
    return !isMobile ? (
      <>
        <Grid container columns={4}>
          <Grid item xs={0.8}>
            <img src={FleetCard} alt="priority-icon" />
          </Grid>
          <Grid item xs={3.2}>
            <div className={styles.total}>{Total.length}</div>
            <div className={styles.totalName}>{priority} Fault Priority</div>
          </Grid>
        </Grid>
      </>
    ) : (
      <></>
    );
  }

  // eslint-disable-next-line
  function GetAircraftTypeByHubs(operator) {
    let dataHubs = data.filter((item) => item.Operator === operator);
    let aircraftTypes = [];
    let aircraftType = [
      ...new Set(
        dataHubs &&
          dataHubs.map((item) => {
            return item["Type"];
          })
      ),
    ];
    sorted = aircraftType.sort();
    sorted.forEach((item) => {
      if (item !== null) {
        aircraftTypes.push(
          <option key={item} value={item}>
            {item}
          </option>
        );
      }
    });
    return aircraftTypes;
  }

  const Summary = () => {
    return (
      <>
        <Grid item xs={12} className={styles.operatorTitle}>
          Operator
        </Grid>
        {accessLevel === "Manager" ? (
          <>
            <Grid item xs={3} className={styles.operatorName}>
              {opr_.length > 1
                ? GetMultipleOperatorName(opr_)
                : opr_.length === 0
                ? GetMultipleOperatorName(AOC)
                : GetOperatorName(opr_[0])}
            </Grid>
            <Grid item xs={0.6}></Grid>
            <Grid item xs={2.8} className={styles.operatorValue}>
              {GetTotalFaultManager("High")}
            </Grid>
            <Grid item xs={2.8} className={styles.operatorValue}>
              {GetTotalFaultManager("Medium")}
            </Grid>
            <Grid item xs={2.8} className={styles.operatorValue}>
              {GetTotalFaultManager("Low")}
            </Grid>
          </>
        ) : (
          <></>
        )}
        {accessLevel !== "Manager" &&
          operators &&
          operators.map((item) => {
            return (
              <>
                <Grid item xs={3} className={styles.operatorName}>
                  {GetOperatorName(item.key)}
                </Grid>
                <Grid item xs={3} className={styles.operatorValue}>
                  {GetTotalFault(item, "High")}
                </Grid>
                <Grid item xs={3} className={styles.operatorValue}>
                  {GetTotalFault(item, "Medium")}
                </Grid>
                <Grid item xs={3} className={styles.operatorValue}>
                  {GetTotalFault(item, "Low")}
                </Grid>
              </>
            );
          })}
      </>
    );
  };

  const SummaryDetails = () => {
    return (
      <>
        <Grid item xs={3} className={`${styles.operatorBordered}`}>
          <Grid container columns={5} sx={{ overflow: "auto" }}>
            <Grid item xs={5} className={`${styles.operatorTitle}`}>
              Operator
            </Grid>
            <Grid item xs={5} className={`${styles.operatorNameNoBorders}`}>
              {opr_.length > 1
                ? GetMultipleOperatorName(opr_)
                : opr_.length === 0
                ? GetMultipleOperatorName(AOC)
                : GetOperatorName(opr_[0])}
            </Grid>
            <Grid item xs={4} className={`${styles.summaryPriorityIcon}`}>
              <img src={FleetCardHigh} alt="High" style={{ marginRight: "18px" }} />
              High
            </Grid>
            <Grid item xs={1} className={`${styles.summaryPriorityIcon} ${styles.displayTotal}`}>
              {GetTotalByPriority("High")}
            </Grid>
            <Grid item xs={4} className={`${styles.summaryPriorityIcon}`}>
              <img src={FleetCardMed} alt="High" style={{ marginRight: "18px" }} />
              Medium
            </Grid>
            <Grid item xs={1} className={`${styles.summaryPriorityIcon} ${styles.displayTotal}`}>
              {GetTotalByPriority("Medium")}
            </Grid>
            <Grid item xs={4} className={`${styles.summaryPriorityIcon}`}>
              <img src={FleetCardLow} alt="High" style={{ marginRight: "18px" }} />
              Low
            </Grid>
            <Grid item xs={1} className={`${styles.summaryPriorityIcon} ${styles.displayTotal}`}>
              {GetTotalByPriority("Low")}
            </Grid>
            {/* <Grid item xs={1} className={`${styles.tableTitleType}`}>
              Type
            </Grid>
            <Grid item xs={1} className={`${styles.tableTitleByType}`}>
              OPS
            </Grid>
            <Grid item xs={1} className={`${styles.tableTitleByType}`}>
              AOG
            </Grid>
            <Grid item xs={1} className={`${styles.tableTitleByType}`}>
              MNT.
            </Grid>
            <Grid item xs={1} className={`${styles.tableTitleByType}`}>
              LTP
            </Grid>
            {aircraftTypes.map((item) => {
              return (
                <>
                  <Grid item xs={1} className={`${styles.aircraftType}`}>
                    {item}
                  </Grid>
                  <Grid item xs={1} className={`${styles.aircraftTypeValue}`}>
                    0
                  </Grid>
                  <Grid item xs={1} className={`${styles.aircraftTypeValue}`}>
                    0
                  </Grid>
                  <Grid item xs={1} className={`${styles.aircraftTypeValue}`}>
                    0
                  </Grid>
                  <Grid item xs={1} className={`${styles.aircraftTypeValue}`}>
                    0
                  </Grid>
                </>
              );
            })} */}
          </Grid>
        </Grid>
        <Grid item xs={9} sx={{ paddingLeft: "32px" }}>
          <Grid container columns={5}>
            {/* <Grid item xs={5} className={`${styles.operatorTitle}`}>
              Hubs
            </Grid> */}
            <Grid item xs={5} style={{ overflowX: "scroll", paddingBottom: "16px" }}>
              <Box sx={{ display: "flex" }}>
                {operators &&
                  operators.map((item) => {
                    // let aircraftTypeByHubs = GetAircraftTypeByHubs(item.key);
                    return (
                      <div className={`${styles.operatorCard}`}>
                        <Grid container columns={5}>
                          <Grid item xs={5} className={styles.hubsName}>
                            {item}
                          </Grid>
                          <Grid item xs={2} className={`${styles.summaryPriorityIcon}`}>
                            <img src={FleetCardHigh} alt="High" style={{ marginRight: "18px" }} />
                            High
                          </Grid>
                          <Grid item xs={3} className={`${styles.summaryPriorityIcon} ${styles.displayTotal}`}>
                            {GetTotalByPriorityHubs(item.key, "High")}
                          </Grid>
                          <Grid item xs={2} className={`${styles.summaryPriorityIcon}`}>
                            <img src={FleetCardMed} alt="High" style={{ marginRight: "18px" }} />
                            Medium
                          </Grid>
                          <Grid item xs={3} className={`${styles.summaryPriorityIcon} ${styles.displayTotal}`}>
                            {GetTotalByPriorityHubs(item.key, "Medium")}
                          </Grid>
                          <Grid item xs={2} className={`${styles.summaryPriorityIcon}`}>
                            <img src={FleetCardLow} alt="High" style={{ marginRight: "18px" }} />
                            Low
                          </Grid>
                          <Grid item xs={3} className={`${styles.summaryPriorityIcon} ${styles.displayTotal}`}>
                            {GetTotalByPriorityHubs(item.key, "Low")}
                          </Grid>
                          {/* <Grid item xs={1} className={`${styles.tableTitleType}`}>
                          Type
                        </Grid>
                        <Grid item xs={1} className={`${styles.tableTitleByType}`}>
                          OPS
                        </Grid>
                        <Grid item xs={1} className={`${styles.tableTitleByType}`}>
                          AOG
                        </Grid>
                        <Grid item xs={1} className={`${styles.tableTitleByType}`}>
                          MNT.
                        </Grid>
                        <Grid item xs={1} className={`${styles.tableTitleByType}`}>
                          LTP
                        </Grid>
                        {aircraftTypeByHubs.map((item) => {
                          return (
                            <>
                              <Grid item xs={1} className={`${styles.aircraftType}`}>
                                {item}
                              </Grid>
                              <Grid item xs={1} className={`${styles.aircraftTypeValue}`}>
                                0
                              </Grid>
                              <Grid item xs={1} className={`${styles.aircraftTypeValue}`}>
                                0
                              </Grid>
                              <Grid item xs={1} className={`${styles.aircraftTypeValue}`}>
                                0
                              </Grid>
                              <Grid item xs={1} className={`${styles.aircraftTypeValue}`}>
                                0
                              </Grid>
                            </>
                          );
                        })} */}
                        </Grid>
                      </div>
                    );
                  })}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <>
      <div
        className={`${open ? styles.containerOpen : styles.containerClose}`}
        style={{ marginTop: "33px", marginLeft: "29px" }}
      >
        <Grid container>
          <Grid item xs={9} className={styles.containerTitle} sx={{ whiteSpace: "nowrap" }}>
            Aircraft Monitoring Summary
          </Grid>
          <Grid item xs={3} sx={{ display: !isMobile ? "flex" : "none", justifyContent: "flex-end" }}>
            <img
              src={IconDown}
              alt="Icon-down"
              height={10}
              className={`${styles.iconOpen} ${open ? styles.rotate : styles.rotateToNormal}`}
              onClick={() => setOpen(!open)}
            />
          </Grid>
          <Grid item xs={12} sx={{ borderBottom: "1px solid #D1D1D1", minHeight: "24px", marginBottom: "24px" }}></Grid>
          {!open ? <Summary /> : <SummaryDetails />}
        </Grid>
      </div>
    </>
  );
};

export default AircraftMonitoringSummary;
