import React from 'react';
import { Box, Grid, Skeleton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import styles from "./Inbox.module.css";
import { makeAPIRequestRun } from "common/helpers";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import OOOIOut from "common/images/icons/elevade/OOOI-OUT.svg";
import OOOIOff from "common/images/icons/elevade/OOOI-OFF.svg";
import OOOIOn from "common/images/icons/elevade/OOOI-ON.svg";
import OOOIIn from "common/images/icons/elevade/OOOI-IN.svg";
import OOOITng from "common/images/icons/elevade/OOOI-T&G.svg";
import OOOIDr from "common/images/icons/elevade/OOOI-DR.svg";
import OOOIMnt from "common/images/icons/elevade/OOOI-MNT.svg";
import { formatDate, ConvertLocaleUTCDatetime, setAOCBasedOnPermission } from "../component/Helpers";
import MUIPagination from "../component/Pagination";
import DataUnavailable from "common/images/icons/elevade/Data-unavailable.svg";
import Sorting from "../component/Sorting";
import { subMinutes } from "date-fns";
import AirplaneLoader from "../component/AirplaneLoader";

const InboxOOOI = ({
  localTime,
  listStatus,
  setListStatus,
  isSearchInput,
  isACReg,
  setIsACReg,
  isOperator,
  startDate,
  endDate,
  isStatus,
  isLoading,
  setIsLoading,
}) => {
  const { profile: userProfile } = useSelector((state) => state.user);
  const { company: companyInfo } = useSelector((state) => state.company);
  const { companyAOC, userAOC, userPermission } = useSelector((state) => state.userEntity);
  // let AOC = companyInfo?.permission?.elevadeAOC;
  let AOC = companyAOC;
  // const [checkAOC, setCheckAOC] = useState(setAOCBasedOnPermission(AOC, userPermission));
  // const UserAOC = userProfile?.permission?.elevadeAOC;
  // const UserAOC = userAOC;
  // if (UserAOC && UserAOC !== null && UserAOC !== undefined && UserAOC?.length > 0) AOC = UserAOC;
  // AOC = checkAOC;
  const [Inbox, setInbox] = useState([]);
  const [isInbox, setIsInbox] = useState(Inbox);
  const [perPage, setPerPage] = useState(50);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPage] = useState(0);
  const [sortingColumn, setSortingColumn] = useState("event_datetime");
  const [sortingDirection, setSortingDirection] = useState("desc");

  let sliceStart = page > 0 ? perPage * (page - 1) : 0;
  if (sliceStart > totalRows) sliceStart = Math.floor(totalRows / perPage);

  let searchQuery = "null";
  if (isOperator !== "All" && isOperator.length > 0) searchQuery += `operator=${isOperator}|`;
  if (isStatus !== "All" && isStatus.length > 0) searchQuery += `msg_type=${isStatus}|`;
  if (isACReg !== "All" && isACReg.length > 0) searchQuery += `ac_reg=${isACReg}|`;
  if (isSearchInput !== undefined && isSearchInput !== "") searchQuery += `search=${isSearchInput}|`;
  if (startDate !== undefined && endDate !== undefined) {
    const start = localTime ? subMinutes(new Date(startDate), -new Date(startDate).getTimezoneOffset()) : startDate;
    const end = localTime ? subMinutes(new Date(endDate), -new Date(endDate).getTimezoneOffset()) : endDate;
    searchQuery += `event_datetime=${ConvertLocaleUTCDatetime(start)}~${ConvertLocaleUTCDatetime(end, "end")}|`;
  }
  // useEffect(() => {
  //   setIsLoading(true);
  //   //eslint-disable-next-line
  // }, [searchQuery]);

  useEffect(() => {
    setPage(1);
  }, [isSearchInput, isOperator, isStatus, isACReg, startDate, endDate, sortingColumn, sortingDirection]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [page]);

  useEffect(() => {
    async function fetchData() {
      const fetchInbox = makeAPIRequestRun(
        `get`,
        `acarsdata/acarsinbox/${AOC}/${perPage}/${sliceStart}/${encodeURIComponent(
          searchQuery
        )}/oooi/${sortingColumn}/${sortingDirection}`
      );
      fetchInbox
        .then((response) => {
          setInbox(response);
          setIsInbox(response);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    async function fetchTotalRows() {
      const fetchData = makeAPIRequestRun(
        "get",
        `acarsdata/acarsinboxtotalrows/${AOC}/${perPage}/${sliceStart}/${encodeURIComponent(searchQuery)}/oooi`
      );
      fetchData
        .then((response) => {
          setTotalRows(response[0]?.Total);
          setTotalPage(Math.floor(response[0]?.Total / perPage) + 1);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    setIsLoading(true);
    fetchData();
    fetchTotalRows();
    //eslint-disable-next-line
  }, [AOC, searchQuery, sliceStart, page, perPage, sortingColumn, sortingDirection]);

  useEffect(() => {
    async function fetchDistinctValue(col, tbl) {
      const fetchData = makeAPIRequestRun(
        "get",
        `acarsdata/getdistinctvalue/${AOC}/${encodeURIComponent(searchQuery)}/${col}/${tbl}/oooi`
      );
      fetchData
        .then((response) => {
          if (col === "msg_type") setListStatus(response);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    fetchDistinctValue("msg_type", "acars_inbox");
    // eslint-disable-next-line
  }, []);

  const skeletonLoader = (numberOfSkeleton) => {
    let skeletonArray = [];
    for (let i = 0; i < numberOfSkeleton; i++) {
      skeletonArray.push(<Skeleton animation="wave" height={60} sx={{ borderRadius: "0px", marginTop: "-16px" }} />);
    }
    return skeletonArray;
  };

  // Custom styles
  const useStyles = makeStyles(() => ({
    ul: {
      "& .Mui-selected": {
        color: "#fff",
        backgroundColor: "#088FD1",
        border: "1px solid #088FD1",
        "&:hover": {
          backgroundColor: "#088FD1",
        },
      },
    },
  }));

  const classes = useStyles();

  function getOOOI(data) {
    let status = "";
    data === "IN"
      ? (status = <img src={OOOIIn} alt="In" className={styles.oooiIcon} />)
      : data === "ON"
      ? (status = <img src={OOOIOn} alt="On" className={styles.oooiIcon} />)
      : data === "OFF"
      ? (status = <img src={OOOIOff} alt="Off" className={styles.oooiIcon} />)
      : data === "OUT"
      ? (status = <img src={OOOIOut} alt="Out" className={styles.oooiIcon} />)
      : data === "TNG"
      ? (status = <img src={OOOITng} alt="Touch and Go" className={styles.oooiIcon} />)
      : data === "DR"
      ? (status = <img src={OOOIDr} alt="Door" className={styles.oooiIcon} />)
      : data === "MNT"
      ? (status = <img src={OOOIMnt} alt="Maintainance" className={styles.oooiIcon} />)
      : (status = "");

    return status;
  }

  const DisplayDataHeader = () => {
    return (
      <>
        <div className={`row ${styles.displayHeader}`}>
          <Grid container spacing={0} columns={7} className={`${styles.displayHeaderLabel}`}>
            <Grid item xs={1} className={styles.nowrap}>
              <Sorting
                title={"Status"}
                col={"msg"}
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
              />
            </Grid>
            {/* <Grid item xs={1} className={styles.nowrap}>
              <Sorting
                title={"Message"}
                col={"msg"}
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
              />
            </Grid> */}
            <Grid item xs={1} className={styles.nowrap}>
              <Sorting
                title={"A/C Model"}
                col={"model"}
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
              />
            </Grid>
            <Grid item xs={1} className={styles.nowrap}>
              <Sorting
                title={"A/C Reg."}
                col={"ac_reg"}
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
              />
            </Grid>
            <Grid item xs={1} className={styles.nowrap}>
              <Sorting
                title={"Flight No."}
                col={"flight_no"}
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
              />
            </Grid>
            {/* <Grid item xs={1} className={styles.nowrap}>
              <Sorting
                title={"Phase"}
                col={"phase"}
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
              />
            </Grid>
            <Grid item xs={1} className={styles.nowrap}>
              <Sorting
                title={"ATA"}
                col={"ata_chaper"}
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
              />
            </Grid>
            <Grid item xs={1} className={styles.nowrap}>
              <Sorting
                title={"Source"}
                col={"source"}
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
              />
            </Grid> */}
            <Grid item xs={1.5} className={styles.nowrap}>
              <Sorting
                title={"Occurrence"}
                col={"event_datetime"}
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
              />
            </Grid>
            <Grid item xs={1.5} className={styles.nowrap}>
              <Sorting
                title={"Sent"}
                col={"time"}
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
              />
            </Grid>
          </Grid>
        </div>
      </>
    );
  };

  const NoDataAvailable = () => {
    const [show, setShow] = useState(false);
    useEffect(() => {
      const timeoutId = setTimeout(() => {
        setShow(true);
      }, 1000);

      return () => clearTimeout(timeoutId);
    }, []);
    return show ? (
      <>
        <div style={{ marginTop: "5%", marginBottom: "10%", textAlign: "center" }}>
          <div style={{ marginBottom: "16px" }}>
            <img src={DataUnavailable} alt="data-unavailable" />
          </div>
          <div style={{ fontFamily: "Gotham-Medium", fontSize: "14px" }}>Data Unavailable</div>
          <div style={{ fontFamily: "Gotham-Book", fontSize: "12px" }}>
            There is no data found. Please try a different search term.
          </div>
        </div>
      </>
    ) : (
      ""
    );
  };

  const DisplayData = () => {
    return isInbox.length > 0 ? (
      isInbox?.map((item, index) => {
        return (
          <>
            <Link to="#" key={index}>
              <div className={`row ${styles.dataItems}`}>
                <Grid container spacing={0} columns={7}>
                  <Grid item xs={1}>
                    {getOOOI(item.msg)}
                    <span style={{ fontFamily: "Gotham-Medium", marginLeft: "8px" }}>{item.msg}</span>
                  </Grid>
                  {/* <Grid item xs={1} className={`${styles.dataItemsText}`}>
                    <div className={`${styles.tooltip} ${styles.dataItemsText}`}>
                      {item.msg}
                      <span data-html="true" className={`${styles.tooltiptext} ${styles.tooltiptextEllipsis}`}>
                        {item.msg}
                      </span>
                    </div>
                  </Grid> */}
                  <Grid item xs={1} className={`${styles.dataItemsText}`}>
                    {item.model}
                  </Grid>
                  <Grid item xs={1} className={`${styles.dataItemsText}`}>
                    {item.ac_reg}
                  </Grid>
                  <Grid item xs={1} className={`${styles.dataItemsText}`}>
                    {item.flight_no}
                  </Grid>
                  {/* <Grid item xs={1} className={`${styles.dataItemsText}`}>
                    -
                  </Grid>
                  <Grid item xs={1} className={`${styles.dataItemsText}`}>
                    -
                  </Grid>
                  <Grid item xs={1} className={`${styles.dataItemsText}`}>
                    -
                  </Grid> */}
                  <Grid item xs={1.5} className={`${styles.dataItemsText}`}>
                    {formatDate(item.event_datetime?.value, "", localTime)}
                  </Grid>
                  <Grid item xs={1.5} className={`${styles.dataItemsText}`}>
                    {formatDate(item.time?.value, "", localTime)}
                  </Grid>
                </Grid>
              </div>
            </Link>
          </>
        );
      })
    ) : !isLoading && isInbox?.length === 0 && searchQuery !== "null" ? (
      <NoDataAvailable />
    ) : (
      ""
    );
  };

  return (
    <>
      <DisplayDataHeader />
      <DisplayData />
      {isInbox?.length !== 0 ? (
        <MUIPagination
          page={page}
          setPage={setPage}
          setPerPage={setPerPage}
          totalPage={totalPages}
          classes={{ ul: classes.ul }}
          totalRows={totalRows}
          sliceStart={sliceStart}
        />
      ) : (
        ""
      )}
      {isLoading && <AirplaneLoader />}
    </>
  );
};

export default InboxOOOI;
