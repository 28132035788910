
import React from 'react';

export default function Doors() {
	return (
		<g>
			<rect x="587" y="439" width="20" height="4" rx="2" fill="#394458" />
			<rect x="629" y="439" width="20" height="4" rx="2" fill="#394458" />
			<rect x="587" y="248" width="20" height="4" rx="2" fill="#394458" />
			<rect x="629" y="248" width="20" height="4" rx="2" fill="#394458" />
			<rect x="233" y="438" width="20" height="4" rx="2" fill="#394458" />
			<rect x="233" y="249" width="20" height="4" rx="2" fill="#394458" />
			<rect
				x="1220"
				y="431.176"
				width="20"
				height="4"
				rx="2"
				transform="rotate(-15 1220 431.176)"
				fill="#394458"
			/>
			<rect
				x="1221"
				y="256"
				width="20"
				height="4"
				rx="2"
				transform="rotate(15 1221 256)"
				fill="#394458"
			/>
			<rect
				x="10"
				y="2"
				width="1353"
				height="222"
				fill="url(#paint3_linear_4_45)"
			/>
			{/* <rect
				x="1363"
				y="689"
				width="1353"
				height="222"
				transform="rotate(-180 1363 689)"
				fill="url(#paint4_linear_4_45)"
			/> */}
		</g>
	);
}
