import { Grid, Fade } from "@mui/material";
import IconCheckGreen from "common/images/icons/elevade/Icon-CheckGreen.svg";
import IconCheckRed from "common/images/icons/elevade/Icon-NotiRed.svg";
import IconCheckYellow from "common/images/icons/elevade/Icon-NotiYellow.svg";
import IconXWhite from "common/images/icons/elevade/Icon-XWhite.svg";
import { useEffect } from "react";

const Notification = ({ variant, openNotification, setOpenNotification, message, subMessage, timeout }) => {
  // useEffect(() => {
  //   const timeoutId = setTimeout(() => {
  //     setOpenNotification(false);
  //   }, timeout);
  //   return () => clearTimeout(timeoutId);
  //   // eslint-disable-next-line
  // }, [variant, openNotification, message, timeout]);

  return (
    <>
      <Fade in={openNotification}>
        <Grid
          container
          columns={10}
          sx={{
            maxWidth: "370px",
            backgroundColor: "#333333",
            borderRadius: "12px",
            color: "#ffffff",
            padding: "16px",
            position: "fixed",
            top: "70px",
            left: "40%",
          }}
        >
          <Grid item xs={1} sx={{ paddingTop: "6px" }}>
            <img
              src={variant === "success" ? IconCheckGreen : variant === "error" ? IconCheckRed : IconCheckYellow}
              alt="icon-check-circle"
            />
          </Grid>
          <Grid item xs={8}>
            <div style={{ fontFamily: "Gotham-Book", fontSize: "14px", color: "#ffffff" }}>
              {message}
            </div>
            <div style={{ fontFamily: "Gotham-Book", fontSize: "10px", color: "#d1d1d1" }}>{subMessage}</div>
          </Grid>
          <Grid item xs={1} sx={{ display: "flex", justifyContent: "center", paddingTop: "10px" }}>
            <img
              src={IconXWhite}
              alt="icon-close"
              height={16}
              onClick={() => setOpenNotification(false)}
              style={{ cursor: "pointer" }}
            />
          </Grid>
        </Grid>
      </Fade>
    </>
  );
};

export default Notification;
