import React, { useState, useRef, useEffect } from "react";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import { Skeleton, Tooltip, tooltipClasses, Box, MenuItem, Popover, Paper, Modal, Fade } from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import styles from "./AircraftStatus.module.css";
import Printer from "common/images/icons/elevade/Printer.svg";
import CloseIcon from "common/images/icons/elevade/Close-Icon.svg";
import Swap from "common/images/icons/elevade/Swap-active.svg";
import Tag from "common/images/icons/elevade/TagSimple-active.svg";
import ExportIcon from "common/images/icons/elevade/Export-active.svg";
import Live from "./Live";
import Inactive from "./Inactive";
import { AdeButton } from "common/components";
import { Grid } from "@mui/material";
import { makeAPIRequestRun, showNotificationElevade } from "common/helpers";
import getTimezoneAbbreviation from "../component/TimeZoneAbbreviation";
import { useSelector } from "react-redux";
import ChangePriorityStatus from "./ChangePriorityStatus";
import { find, isEmpty } from "lodash";
import _ from "lodash";
import DataUnavailable from "common/images/icons/elevade/Data-unavailable.svg";
import MUIPagination from "../component/Pagination";
import ChangeLabel from "./ChangeLabel";
import { FaultJournalSummary } from "./FaultJournalSummary";
import GetPriorityIcon from "../component/GetPriorityIcon";
import ShareIcon from "common/images/icons/elevade/Share.svg";
import useMediaQuery from "@mui/material/useMediaQuery";
import Loading from "../component/Loading";
import DataMissing from "../component/DataMissing";
import { generatePdfForPfr } from "./generatePdf";
import Calendar from "common/images/icons/elevade/CalendarBlank.svg";
import defectFlagOff from "common/images/icons/elevade/defectFlagOff.svg";
import defectFlagFull from "common/images/icons/elevade/defectFlagFull.svg";
import defectFlagOffV2 from "common/images/icons/elevade/defectFlagOffV2.svg";
import { ConvertUTCDatetime, changeFormatDate, setAOCBasedOnPermission } from "pages/elevade/component/Helpers";
import { ReportPreview } from "../component/ReportPreview";
import { FormatListBulleted, KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";
import { SearchBarAircraftStatus } from "../component/SearchBarInbox";
import ActiveFilters from "../component/ActiveFilters";

const FlightReport = ({
  isLegs,
  setIsLegs,
  isSearchInput,
  isFaultPriority,
  setOOOOIData,
  aircraftList,
  previouslegsList,
  previouslegs,
  setActiveOOOI,
  isLoading,
  setIsLoading,
  warningReports,
  previousLegsWrn,
  page,
  setPage,
  perPage,
  setPerPage,
  localTime,
  isRepetitive,
  isAcarsTab,
  setIsAcarsTab,
  isAcmsTab,
  setIsAcmsTab,
  latestFlight,
  setLatestFlight,
  latestDate,
  setLatestDate,
  OOOIData,
  setShowSchedule,
  showSchedule,
  useCurrentTime,
  rawData,
  callApi,
  setFlightReportPriority, //TBD
  setListFaultPriority, //TBD
  ...props
}) => {
  const id = useParams().id;
  const pathname = window.location.pathname;
  const history = useHistory();
  const [openCogwheel, setOpenCogwheel] = useState(false);
  const [open, setOpen] = useState(false);
  const [openAcms, setOpenAcms] = useState(false);
  const [faultLeg, setFautlLeg] = useState(0);
  const [activeLeg, setActivelLeg] = useState();
  const [isWarningReportsFiltered, setIsWarningReportsFiltered] = useState(warningReports);
  const [faultReportsFiltered, setFaultReportsFiltered] = useState(warningReports);
  const [msgType, setMsgType] = useState();
  const [selectedLeg, setSelectedLeg] = useState("0");
  const [activeLine, setActiveLine] = useState("0");
  const [activeLegNo, setActiveLegNo] = useState(1);
  const [legsAll] = useState([]);
  const [legs] = useState([]);
  const [faultLegs] = useState([]);
  const [timeZone, setTimeZone] = useState();
  const [timeZoneAfter] = useState("'UTC'");
  const [labelList, setLabelList] = useState();
  const [label, setLabel] = useState();
  // const { profile: userProfile } = useSelector((state) => state.user);
  const { filters } = useSelector((state) => state.filters);
  const { companyAOC, userAOC, userPermission, userName, userProfile } = useSelector((state) => state.userEntity);
  // let AOC = companyInfo?.permission?.elevadeAOC;
  let AOC = companyAOC;
  // const UserAOC = userProfile?.permission?.elevadeAOC;
  // const UserAOC = userAOC;
  // if (UserAOC && UserAOC !== null && UserAOC !== undefined && UserAOC?.length > 0) AOC = UserAOC;
  // AOC = setAOCBasedOnPermission(AOC, userPermission);
  const [openPriorityStatus, setOpenPriorityStatus] = useState(false);
  const [eventMessage, setEventMessage] = useState();
  const [ataChapter, setAtaChapter] = useState();
  const [priority, setPriority] = useState();
  const [openMenu, setOpenMenu] = useState(false);
  const [contextMenu, setContextMenu] = useState(null);
  const [departureTime, setDepartureTime] = useState(null);
  const [aircraft, setAircraft] = useState(null);
  const [arrivalTime, setArrivalTime] = useState(null);
  const [flightNo, setFlightNo] = useState(null);
  const [route, setRoute] = useState(null);
  const [Inbox] = useState([]);
  const [isInbox, setIsInbox] = useState(Inbox);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPage] = useState(0);
  const [openLabel, setOpenLabel] = useState(false);
  const [acType, setAcType] = useState();
  const [tab, setTab] = useState(1);
  const location = useLocation();
  const paramsString = location.search;
  const searchParams = new URLSearchParams(paramsString);
  const [openLeg, setOpenLeg] = useState(false);
  const [isAcmsLoading, setIsAcmsLoading] = useState(true);
  const [isLastLegs, setIsLastLegs] = useState({
    start_flight_no: null,
    start_start_datetime: null,
    end_flight_no: null,
    end_start_datetime: null,
  });
  const [isLegsArray, setIsLegsArray] = useState([]);
  let msgFromInbox = JSON.parse(localStorage.getItem("inboxMsg"));
  let prevURLNewTab = JSON.parse(localStorage.getItem("prevURLNewTab"));
  const [msgIndex, setMsgIndex] = useState();
  const [msgIndexes, setMsgIndexes] = useState();
  const [checkSpec, setCheckSpec] = useState(true);
  const prevURL = history.location.state?.from ? history.location.state?.from : "";
  const [checkOnce, setCheckOnce] = useState(true);
  const [missingData, setMissingData] = useState(false);
  const [legData, setLegData] = useState([]);
  const [clickLegs, setClickLegs] = useState(isLegs);
  const [openFlagDefect, setOpenFlagDefect] = useState(false);
  const [defectModalData, setDefectModalData] = useState([]);
  const [flaggedData, setFlaggedData] = useState([]);
  const [msgId, setMsgId] = useState();
  const [openPfrReportPreview, setOpenPfrReportPreview] = useState(false);
  const [pfrReportData, setPfrReportData] = useState({});
  const [isDisplayFlag, setIsDisplayFlag] = useState(true); // TODO: set true once RDD feature is stable

  if (prevURL) prevURLNewTab = ""; //if from fleet set prevURLNewTab from inbox url empty

  let legIndex = previouslegs?.findIndex((item) => {
    return (
      (item?.flight_no === msgFromInbox?.flightNo && item?.telex_sent_datetime_format === msgFromInbox?.telexTime) ||
      (item?.flight_no === msgFromInbox?.flightNo && item.date.value === msgFromInbox?.eventDate)
    );
  });
  if (!checkOnce) {
    legIndex = "";
  }

  const [isLoadingLegs, setIsLoadingLegs] = useState(true);
  const isWeb = useMediaQuery("(min-width:900px)");

  const Boeing_Toolbox_Url = process.env.REACT_APP_BOEING_REMOTE_TOOLBOX_URL;

  useEffect(() => {
    if (msgFromInbox) {
      handleOnMouseEnter(msgIndexes);
    } else {
      setOOOOIData({
        ...OOOIData,
        report: {
          ...OOOIData?.report,
          warnings: isWarningReportsFiltered,
          faults: faultReportsFiltered,
        },
      });
    }
    // eslint-disable-next-line
  }, [isWarningReportsFiltered, faultReportsFiltered]);

  useEffect(() => {
    setOpenAcms(false);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    // eslint-disable-next-line
  }, [page, perPage]);

  // highlight flight legs based on msg Inbox
  useEffect(() => {
    if (checkSpec && msgIndexes !== undefined && typeof msgIndexes !== "string") {
      handleResetActiveLeg(msgIndexes);

      let index = -1;
      const data = previouslegs[msgIndexes];

      for (let i = 0; i < previouslegs.length; i++) {
        const obj = previouslegs[i];
        const allFieldsMatch = Object.keys(data).every((key) => obj[key] === data[key]);
        if (allFieldsMatch) {
          index = i;
          break;
        }
      }
      handleOnMouseEnter(index);
    }
    // eslint-disable-next-line
  }, [msgIndexes, checkSpec]);

  useEffect(() => {
    if (!msgFromInbox) {
      setMsgIndexes("");
      setMsgIndex("");
    }
  }, [msgFromInbox]);

  // Scroll the page to the opened tab in leg details
  useEffect(() => {
    const element = document.getElementById("legDetails");
    let position = element?.getBoundingClientRect();
    window.scrollTo({ top: position?.top + window.scrollY - 174 });
  }, [location.pathname]);

  // Scroll the page into the selected legs details
  useEffect(() => {
    if (openLeg !== false) {
      const element = document.getElementById(openLeg);
      let position = element?.getBoundingClientRect();
      window.scrollTo({
        top: position?.top + window.scrollY - 106,
        behavior: "smooth",
      });
    }
  }, [openLeg]);

  // Scroll the page to the opened acms reports message
  useEffect(() => {
    const element = document.getElementById(openAcms);
    let position = element?.getBoundingClientRect();
    window.scrollTo({
      top: position?.top + window.scrollY - 102,
      behavior: "smooth",
    });
  }, [openAcms]);

  // Redirect user to /fleet if the user does not have access to the current aircraft reg.
  useEffect(() => {
    if (aircraftList?.length > 0) {
      let Operator = aircraftList[0].Operator;
      const found = AOC.includes(Operator);
      if (!found) window.location = "/fleet";
    }
  }, [id, AOC, aircraftList]);

  useEffect(() => {
    if (searchParams.get("tab")) {
      setIsAcarsTab(false);
      setIsAcmsTab(true);
    }
    // eslint-disable-next-line
  }, [location]);

  // Get the last legs data based on selected legs number
  useEffect(() => {
    const totalLegsData = previouslegs.length;

    let startLegs = 0;
    let lastLegs = 0;
    if (totalLegsData > 0) {
      if (totalLegsData >= 15 && isLegs === "15") lastLegs = 14;
      else if (totalLegsData >= 30 && isLegs === "30") lastLegs = 29;
      else if (totalLegsData >= 50 && isLegs === "50") lastLegs = 49;
      else if (totalLegsData >= 100 && isLegs === "100") {
        startLegs = 50;
        lastLegs = 99;
      } else if (totalLegsData >= 150 && isLegs === "150") {
        startLegs = 100;
        lastLegs = 149;
      } else if (totalLegsData >= 200 && isLegs === "200") {
        startLegs = 150;
        lastLegs = 199;
      }
      if (totalLegsData <= isLegs) {
        startLegs = isLegs - 50;
        lastLegs = totalLegsData - 1;
      }
      // Assign the value
      setIsLastLegs({
        start_flight_no: previouslegs[lastLegs]?.flight_no,
        start_start_datetime: previouslegs[lastLegs]?.start_time?.value,
        end_flight_no: previouslegs[startLegs]?.flight_no,
        end_start_datetime: previouslegs[startLegs]?.start_time?.value,
      });
    }
  }, [previouslegs, isLegs]);

  useEffect(() => {
    setIsLoading(true);
    setIsLoadingLegs(true);
    setOpen(false);
    setActivelLeg(null);
    setClickLegs(isLegs);
    setLatestFlight();
    setLatestDate();
    setSelectedLeg("0");
    setActiveLegNo("0");
    setActiveOOOI(false);
    // eslint-disable-next-line
  }, [isLegs]);

  // useEffect(() => {
  //   let warningReports_ = warningReports;
  //   console.log('-------',warningReports_);
  //   console.log('flaggeddata',flaggedData)
  //   if(flaggedData) {
  //     flaggedData.map((item, index) => {
  //       console.log('flaggeddata item', item)
  //       let flagg
  //     })
  //   }

  //   let a = "b5dc12c8-d88c-4609-ace6-43a67a8bfc34"
  //   const das = warningReports_.find((e) =>
  //     e.msg_id === a
  //   )
  //   console.log('das',das)
  // }, [callApi, warningReports])

  const style = {
    box: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 547,
      bgcolor: "background.paper",
      borderRadius: "12px",
      boxShadow: 24,
      p: 3,
      zIndex: 1502,
    },
  };

  const handleClick = (event) => {
    setOpenMenu(true);
    event.preventDefault();
    setContextMenu({
      mouseX: event.clientX,
      mouseY: event.clientY,
    });
  };
  const handleClose = (e) => {
    e.preventDefault();
    setOpenMenu(false);
  };

  // eslint-disable-next-line

  const handleClickLabel = (e) => {
    e.preventDefault();
    setOpenMenu(false);
    setOpenLabel(true);
  };

  let sliceStart = page > 0 ? perPage * (page - 1) : 0;
  if (sliceStart > totalRows) sliceStart = Math.floor(totalRows / perPage);

  let searchQuery = "null";
  if (isSearchInput !== undefined && isSearchInput !== "" && isSearchInput.length > 0)
    searchQuery += `search=${isSearchInput}|`;

  useEffect(() => {
    let flight = latestFlight ?? OOOIData?.flight_no;
    let date = latestDate ?? OOOIData?.start_time?.value;
    setIsAcmsLoading(true);
    const getACMS = makeAPIRequestRun(
      "get",
      "acarsdata/inboxacms/" + id + "/" + perPage + "/" + sliceStart + "/" + searchQuery + "/" + flight + "/" + date
    );
    getACMS.then((res) => {
      setIsInbox(res);
      setIsAcmsLoading(false);
    });
    const getACMSTotalRows = makeAPIRequestRun(
      "get",
      "acarsdata/inboxacmstotalrows/" + id + "/" + searchQuery + "/" + flight + "/" + date
    );
    getACMSTotalRows.then((res) => {
      setTotalRows(res[0]?.Total);
      setTotalPage(Math.floor(res[0]?.Total / perPage) + 1);
    });
    //eslint-disable-next-line
  }, [isAcmsTab, page, perPage, isSearchInput, selectedLeg]);

  useEffect(() => {
    const getLabelList = makeAPIRequestRun("get", "acarsdata/aircraftstatus/label/getLabel/" + AOC);
    getLabelList.then((res) => {
      setLabelList(res);
    });
    // eslint-disable-next-line
  }, [openLabel]);

  useEffect(() => {
    setTimeZone(getTimezoneAbbreviation(new Date()));
  }, [timeZoneAfter]);

  useEffect(() => {
    async function filteredLegs() {
      previouslegs &&
        // eslint-disable-next-line
        previouslegs.map((item) => {
          let legData = previousLegsWrn.filter(
            (e) =>
              item.flight_no === e.flight_no &&
              item.start_time?.value === e.start_time?.value &&
              (e.report_type === "WARNING" ||
                e.correlated === 1 ||
                e.report_type === "NONE" ||
                e.priority === "Missing")
          );
          if (legData?.length > 0 || previousLegsWrn.length > 0) legs.push(legData);
        });
      previouslegs &&
        // eslint-disable-next-line
        previouslegs.map((item) => {
          let legData = previousLegsWrn.filter(
            (e) =>
              item.flight_no === e.flight_no &&
              item.start_time?.value === e.start_time?.value &&
              e.report_type === "FAULT" &&
              e.correlated === 0
          );
          if (legData?.length > 0 || previousLegsWrn.length > 0) faultLegs.push(legData);
        });
      previouslegs &&
        // eslint-disable-next-line
        previouslegs.map((item) => {
          let legData = previousLegsWrn.filter(
            (e) => item.flight_no === e.flight_no && item.start_time?.value === e.start_time?.value
          );
          if (legData?.length > 0 || previousLegsWrn.length > 0) legsAll.push(legData);
        });
    }
    filteredLegs();
    setFlightNo(previouslegs[0]?.flight_no);
    setAircraft(previouslegs[0]?.AC_REG);
    setDepartureTime(formatDate(previouslegs[0]?.start_time.value));
    setArrivalTime(formatDate(previouslegs[0]?.end_time.value));
    setRoute(previouslegs[0]?.departure_airport + " - " + previouslegs[0]?.destination_airport);
    // eslint-disable-next-line
  }, [previousLegsWrn, previouslegs, faultLegs, legs, legsAll]);

  // A function to get the query variable in URL
  function getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] === variable) {
        return pair[1];
      }
    }
    return "";
  }

  // eslint-disable-next-line
  const handleChange = (e) => {
    const AircraftRegNo = e.target.value;
    history.replace(`/fleet/aircraft-status/${AircraftRegNo}/failure-messages`);
  };

  // Creating a skeleton loader
  const skeletonLoader = (numberOfSkeleton) => {
    let skeletonArray = [];
    for (let i = 0; i < numberOfSkeleton; i++) {
      skeletonArray.push(
        <Grid
          item
          sx={{
            height: "60px",
            width: "98.4%",
            marginLeft: "4px",
            marginBottom: "-20px",
            border: "0px solid #333",
            overflow: "hidden",
          }}
        >
          <Skeleton animation="wave" height={60} sx={{ marginLeft: "14px", borderRadius: "0px" }} />
        </Grid>
      );
    }
    return skeletonArray;
  };

  const skeletonLoaderForLegs = (numberOfSkeleton) => {
    let skeletonArray = [];
    for (let i = 0; i < numberOfSkeleton; i++) {
      skeletonArray.push(
        <Grid
          item
          xs={1}
          sx={{
            padding: "0 2px",
          }}
        >
          <Skeleton animation="wave" height={60} sx={{ marginTop: "-14px", borderRadius: "0px" }} />
        </Grid>
      );
    }
    return skeletonArray;
  };

  function getActivePath(path) {
    if (pathname.includes(path)) {
      return styles.activeTabLegDetails;
    } else {
      return "";
    }
  }

  // Custom styles
  const useStyles = makeStyles({
    // Styles for the card view's tooltip
    cardViewTooltip: {
      backgroundColor: "rgba(0, 21, 60, 0.75)",
      backdropFilter: "blur(10px)",
      webkitBackdropFilter: "blur(10px)",
      borderRadius: 8,
      boxShadow: "0px 3px 6px #00000029",
      maxWidth: 323,
      minWidth: 250,
      marginTop: "-2px",
      fontSize: "12px",
      zIndex: "1501",
    },
    messageTootltip: {
      background: "#fffac3 0% 0% no-repeat padding-box",
      color: "#333333",
      boxShadow: "0px 3px 6px #00000040",
    },
    flightNoTooltip: {
      backgroundColor: "rgba(0, 21, 60, 0.75)",
      zIndex: "0",
    },
    arrow: {
      color: "rgba(0, 21, 60, 0.75)",
    },
    ul: {
      "& .Mui-selected": {
        color: "#fff",
        backgroundColor: "#088FD1",
        border: "1px solid #088FD1",
        "&:hover": {
          backgroundColor: "#088FD1",
        },
      },
    },
  });

  const classes = useStyles();

  const StyledTooltip = styled(({ className, ...props }) => <Tooltip {...props} />)(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {},
  }));

  const TextPreview = styled("div")({
    textAlign: "center",
    borderRadius: "5px",
    height: "20px",
    // marginLeft: "50px",
    marginTop: "2px",
    zIndex: 1,
    // position: "absolute",
  });

  useEffect(() => {
    let warningReportsFiltered = "";
    let faultReportsFiltered = "";

    const ViewSelectedLegs = () => {
      // Filtering warning reports based on latest flight leg
      warningReportsFiltered = warningReports.filter(
        (item) =>
          // (item.flight_no === latestFlight &&
          //   // item.start_time?.value >= isLastLegs.start_start_datetime &&
          //   // item.start_time?.value < isLastLegs.end_start_datetime &&
          //   // (item?.report_type === "WARNING" || item?.correlated === 1) &&
          //   // item?.report_type !== "FAULT" &&
          //   item?.report_type !== "ACMS" &&
          //   item?.leg === activeLegNo) ||
          // (item?.correlated === 1 && item.flight_no === latestFlight)
          item.leg === activeLegNo
      );

      // Filtering fault reports based on latest flight leg
      faultReportsFiltered = warningReports.filter(
        (item) => item?.report_type === "FAULT" && item?.correlated === 0 && item?.leg === activeLegNo
      );

      // Uncomment this to show only unique warning/faults
      // warningReportsFiltered = [...new Map(warningReportsFiltered.map((item) => [item["text"], item])).values()];
      // faultReportsFiltered = [...new Map(faultReportsFiltered.map((item) => [item["text"], item])).values()];

      if (isFaultPriority.length > 0) {
        warningReportsFiltered = warningReportsFiltered.filter((item) => isFaultPriority.includes(item["priority"]));
        faultReportsFiltered = faultReportsFiltered.filter((item) => isFaultPriority.includes(item["priority"]));
      }

      if (isRepetitive.length === 2) {
        warningReportsFiltered = warningReportsFiltered.filter(
          (item) =>
            item["repetitive"] === 1 || item["multiple"] === 1 || (item["multiple"] === 0 && item["repetitive"] === 0)
        );
        faultReportsFiltered = faultReportsFiltered.filter(
          (item) => item["repetitive"] === 1 || item["multiple"] === 1
        );
      }

      if (isRepetitive.length === 1 && isRepetitive[0] === "Repetitive") {
        warningReportsFiltered = warningReportsFiltered.filter(
          (item) => item["multiple"] === 1 || item["repetitive"] === 1
        );
        faultReportsFiltered = faultReportsFiltered.filter(
          (item) => item["multiple"] === 1 || item["repetitive"] === 1
        );
      }

      if (isRepetitive.length === 1 && isRepetitive[0] === "No Repetitive") {
        warningReportsFiltered = warningReportsFiltered.filter(
          (item) => item["multiple"] === 0 && item["repetitive"] === 0
        );
        faultReportsFiltered = faultReportsFiltered.filter(
          (item) => item["multiple"] === 0 && item["repetitive"] === 0
        );
      }

      if (isSearchInput.length > 0) {
        warningReportsFiltered = warningReportsFiltered.filter(
          (item) =>
            isSearchInput.some((searchTerm) => item.text?.includes(searchTerm)) ||
            isSearchInput.some((searchTerm) => item.ata_chapter?.includes(searchTerm)) ||
            isSearchInput.some((searchTerm) => item.source?.includes(searchTerm))
        );
        faultReportsFiltered = faultReportsFiltered.filter(
          (item) =>
            isSearchInput.some((searchTerm) => item.text?.includes(searchTerm)) ||
            isSearchInput.some((searchTerm) => item.ata_chapter?.includes(searchTerm)) ||
            isSearchInput.some((searchTerm) => item.source?.includes(searchTerm))
        );
      }
    };

    // Filtering data for initial value

    let warningData = warningReports.filter(
      (item) =>
        item.start_time?.value >= isLastLegs.start_start_datetime &&
        item.start_time?.value <= isLastLegs.end_start_datetime &&
        item?.report_type !== "FAULT" &&
        item?.report_type !== "ACMS"
    );

    let warning = [
      ...new Map(
        warningData.map((item) => {
          let findUnique =
            item["text"] &&
            item["text"]?.concat(item["flight_report_type"]?.concat(item["ata_chapter"]?.concat(item["source"])));
          item = { ...item, uniqueWarning: findUnique };
          return [item["uniqueWarning"], item];
        })
      ).values(),
    ];

    let faultData = warningReports.filter(
      (item) =>
        item.start_time?.value >= isLastLegs.start_start_datetime &&
        item.start_time?.value <= isLastLegs.end_start_datetime &&
        item?.report_type === "FAULT" &&
        item?.flight_report_type === "CFR"
    );

    let fault = [
      ...new Map(
        faultData.map((item) => {
          let findUnique =
            item["text"] &&
            item["text"]?.concat(item["flight_report_type"]?.concat(item["ata_chapter"]?.concat(item["source"])));
          item = { ...item, uniqueFault: findUnique };
          return [item["uniqueFault"], item];
        })
      ).values(),
    ];

    // Apply filter
    if (isFaultPriority.length > 0) {
      warning = warning.filter((item) => isFaultPriority.includes(item["priority"]));
      fault = fault.filter((item) => isFaultPriority.includes(item["priority"]));
    }

    if (isRepetitive.length === 2) {
      warning = warning.filter(
        (item) =>
          item["repetitive"] === 1 || item["multiple"] === 1 || (item["multiple"] === 0 && item["repetitive"] === 0)
      );
      fault = fault.filter((item) => item["repetitive"] === 1 || item["multiple"] === 1);
    }

    if (isRepetitive.length === 1 && isRepetitive[0] === "Repetitive") {
      warning = warning.filter((item) => item["multiple"] === 1 || item["repetitive"] === 1);
      fault = fault.filter((item) => item["multiple"] === 1 || item["repetitive"] === 1);
    }

    if (isRepetitive.length === 1 && isRepetitive[0] === "No Repetitive") {
      warning = warning.filter((item) => item["multiple"] === 0 && item["repetitive"] === 0);
      fault = fault.filter((item) => item["multiple"] === 0 && item["repetitive"] === 0);
    }

    if (isSearchInput.length > 0) {
      warning = warning.filter(
        (item) =>
          isSearchInput.some((searchTerm) => item.text?.includes(searchTerm)) ||
          isSearchInput.some((searchTerm) => item.ata_chapter?.includes(searchTerm)) ||
          isSearchInput.some((searchTerm) => item.source?.includes(searchTerm))
      );
      fault = fault.filter(
        (item) =>
          isSearchInput.some((searchTerm) => item.text?.includes(searchTerm)) ||
          isSearchInput.some((searchTerm) => item.ata_chapter?.includes(searchTerm)) ||
          isSearchInput.some((searchTerm) => item.source?.includes(searchTerm))
      );
    }

    function dynamicSort(property) {
      // const priority = ["High", "Medium", "Low", "OK", "Nuisance", "None", "Undefined", "Null"];
      // const sorted = list.sort((a, b) => priority.indexOf(a) - priority.indexOf(b));
      var sortOrder = -1;
      if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
      }
      return function (a, b) {
        var result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
        return result * sortOrder;
      };
    }

    // Sort according to the priority level
    let sortedWarning = warning.sort(dynamicSort("priority_level"));
    let sortedFault = fault.sort(dynamicSort("priority_level"));
    // Uncomment this to apply initial view all warning & fault messages
    if (latestFlight === undefined && latestDate === undefined) {
      setIsWarningReportsFiltered(sortedWarning);
      let getWarningPriority = [...new Set(sortedWarning.map((item) => item.priority))];
      let getFaultPriority = [...new Set(sortedFault.map((item) => item.priority))];
      let warningFaultPriority = [...new Set([...getWarningPriority], ...getFaultPriority)];
      setFlightReportPriority(warningFaultPriority);
      setListFaultPriority(warningFaultPriority);
      setFaultReportsFiltered(sortedFault);
      setIsLoadingLegs(false);
      setIsLoading(false);
    } else {
      ViewSelectedLegs();
      setIsLoadingLegs(false);
      setIsLoading(false);
      setIsWarningReportsFiltered(warningReportsFiltered);
      setFaultReportsFiltered(faultReportsFiltered);
      // Uncomment this to apply initial view all warning & fault messages
    }
    // eslint-disable-next-line
  }, [
    latestDate,
    latestFlight,
    warningReports,
    isLastLegs,
    isLegs,
    isFaultPriority,
    isRepetitive,
    isSearchInput,
    activeLegNo,
    callApi,
  ]);

  // Function to detect mouse click outside a component
  function useOutsideClicker(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpenCogwheel(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideClicker(wrapperRef);

  const getLabelAndTitle = (ata, message) => {
    const label = find(labelList, (res) => {
      return res.ata_chapter === ata && res.message === message;
    });
    const title = (
      <>
        {label?.label}
        <br />
        Remarks: {label?.remarks}
      </>
    );
    return { label: label, title: title };
  };

  //display label
  const displayLabel = (margin, label, title, correlated) => {
    return (
      <>
        {label && (
          <Grid>
            <StyledTooltip
              title={title}
              placement="bottom-start"
              enterDelay={500}
              followCursor={false}
              classes={{ tooltip: classes.messageTootltip }}
            >
              <TextPreview
                style={{
                  border: `1px solid ${label?.tag_colour}`,
                  color: label?.text_colour,
                  fontSize: "12px",
                  width: "50px",
                  marginLeft: margin ?? "50px",
                  fontFamily: "Gotham-Book",
                  marginRight: "10px",
                }}
              >
                {label?.abbreviation}
              </TextPreview>
            </StyledTooltip>
          </Grid>
        )}
      </>
    );
  };

  //function to remove duplicates
  const removeDuplicateObjByKey = (key, array) => {
    const seen = new Set();
    return array.filter((el) => {
      const duplicate = seen.has(el[key]);
      seen.add(el[key]);
      return !duplicate;
    });
  };

  // Function to split ATA chapter
  function getAtaChapter(ata, report_type) {
    if (ata && report_type === "FAULT")
      return ata.substring(0, 2) + "-" + ata.substring(2, 4) + "-" + ata.substring(4, 6);
    else if (ata && report_type === "WARNING") return ata.substring(0, 2) + "-" + ata.substring(2, 4);
    else if (ata) return ata.substring(0, 2) + "-" + ata.substring(2, 4) + "-" + ata.substring(4, 6);
  }

  function isNumeric(value) {
    return /^-?\d+$/.test(value);
  }

  function isCombineNumericLetters(value) {
    return /\d/.test(value);
  }

  function cssClassName(match, index, indexes, selectedLeg, length, leg) {
    let theSelectedLeg = indexes;
    if (parseInt(isLegs) === 100) {
      theSelectedLeg = indexes + 50;
    } else if (parseInt(isLegs) === 150) {
      theSelectedLeg = indexes - 100;
    } else if (parseInt(isLegs) === 200) {
      theSelectedLeg = indexes - 150;
    }
    if (match === 1) {
      if (selectedLeg === theSelectedLeg && isLegs === clickLegs) {
        if (index === 0) {
          return styles.prevLegsShadowBottom;
        } else if (index === length - 1) {
          return styles.prevLegsShadow;
        } else {
          return styles.prevLegsShadowBottom;
        }
      } else {
        return styles.prevLegs;
      }
    } else if (match === 2) {
      return styles.prevLegsMissing;
    } else if (match === 3) {
      if (selectedLeg === theSelectedLeg && isLegs === clickLegs) {
        if (index === 0) {
          return styles.prevLegsUnidentifiedChapterShadowBottom;
        } else if (index === length - 1) {
          return styles.prevLegsUnidentifiedChapterShadow;
        } else {
          return styles.prevLegsUnidentifiedChapterShadowBottom;
        }
      } else {
        return styles.prevLegsUnidentifiedChapter;
      }
    } else {
      if (selectedLeg === theSelectedLeg && isLegs === clickLegs) {
        if (index === 0) {
          return styles.prevLegsUnavailableShadowBottom;
        } else if (index === length - 1) {
          return styles.prevLegsUnavailableShadow;
        } else {
          return styles.prevLegsUnavailableShadowBottom;
        }
      } else {
        return styles.prevLegsUnavailable;
      }
    }
  }

  // Function to convert timestamp
  const formatDate = (dateString, type) => {
    // If date is null, return n/a
    if (dateString === null || dateString === undefined || dateString === "") {
      return "n/a";
    }
    // Options for date formatting
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    let returnValue = "";
    let returnDate = "";
    let returnTime = "";
    let returnDatetime = "";
    let returnTimeShort = "";
    if (localTime) {
      if (dateString.includes("Z") === false) dateString = dateString + ".000Z";
      const date = new Date(dateString).toLocaleDateString(undefined, options); // Convert date to local date
      const DateUTC = date.toLocaleString("en-US", options).split(",")[0]; // Split date to get UTC date
      const TimeUTC = date.toLocaleString().split(",")[1]; // Split date to get UTC time
      returnDatetime =
        DateUTC?.split("/")[2] +
        "-" +
        DateUTC?.split("/")[1]?.padStart(2, "0") +
        "-" +
        DateUTC?.split("/")[0]?.padStart(2, "0") +
        " " +
        TimeUTC?.split(":")[0] +
        ":" +
        TimeUTC?.split(":")[1];
      returnDate =
        DateUTC?.split("/")[2] +
        "-" +
        DateUTC?.split("/")[1]?.padStart(2, "0") +
        "-" +
        DateUTC?.split("/")[0]?.padStart(2, "0");
      returnTime = TimeUTC?.split(":")[0] + ":" + TimeUTC?.split(":")[1] + ":" + TimeUTC?.split(":")[2];
      returnTimeShort = TimeUTC?.split(":")[0] + ":" + TimeUTC?.split(":")[1];
    } else {
      let timeOnly = dateString?.split("T")[1];
      returnDatetime = dateString?.split("T")[0] + " " + timeOnly?.split(":")[0] + ":" + timeOnly?.split(":")[1];
      returnTime = timeOnly?.split(":")[0] + ":" + timeOnly?.split(":")[1];
      returnDate = dateString?.split("T")[0];
      returnValue = returnDatetime;
      returnTimeShort = returnTime;
    }
    type === "date"
      ? (returnValue = returnDate)
      : type === "time"
      ? (returnValue = returnTime)
      : type === "timeShort"
      ? (returnValue = returnTimeShort)
      : (returnValue = returnDatetime);
    return returnValue;
  };

  // Function to get the card view contents
  // eslint-disable-next-line
  function getLegsOverview(data, ata_chapter, text) {
    const legsData = data.filter((item) => item.ata_chapter === ata_chapter);
    return (
      <React.Fragment>
        <div style={{ padding: "14px" }}>
          <div style={{ fontFamily: "Gotham-Medium", textDecoration: "underline" }}>Message</div>
          <div style={{ fontFamily: "Gotham-Book", textTransform: "uppercase" }}>{text}</div>
          <div
            style={{
              fontFamily: "Gotham-Medium",
              textDecoration: "underline",
              marginTop: "14px",
            }}
          >
            Flight No.
          </div>
          <div style={{ fontFamily: "Gotham-Book" }}>{legsData[0]?.flight_no}</div>
          <div
            style={{
              fontFamily: "Gotham-Medium",
              textDecoration: "underline",
              marginTop: "14px",
            }}
          >
            Flight Designator
          </div>
          <div style={{ fontFamily: "Gotham-Book" }}>{"-"}</div>
          <div
            style={{
              fontFamily: "Gotham-Medium",
              textDecoration: "underline",
              marginTop: "14px",
            }}
          >
            Call Sign
          </div>
          <div style={{ fontFamily: "Gotham-Book" }}>-</div>
          <div
            style={{
              fontFamily: "Gotham-Medium",
              textDecoration: "underline",
              marginTop: "14px",
            }}
          >
            OOOI Message
          </div>
          <div
            style={{
              fontFamily: "Gotham-Medium",
              width: "90px",
              float: "left",
            }}
          >
            OUT ({legsData[0]?.departure_airport?.replace(" ", "")})
          </div>
          <div style={{ fontFamily: "Gotham-Book" }}>{formatDate(legsData[0]?.out_datetime?.value) || "n/a"}</div>
          <div
            style={{
              fontFamily: "Gotham-Medium",
              width: "90px",
              float: "left",
            }}
          >
            OFF
          </div>
          <div style={{ fontFamily: "Gotham-Book" }}>{formatDate(legsData[0]?.off_datetime?.value) || "n/a"}</div>
          <div
            style={{
              fontFamily: "Gotham-Medium",
              width: "90px",
              float: "left",
            }}
          >
            ETA
          </div>
          <div style={{ fontFamily: "Gotham-Book" }}>{formatDate(legsData[0]?.eta_datetime?.value) || "n/a"}</div>
          <div
            style={{
              fontFamily: "Gotham-Medium",
              width: "90px",
              float: "left",
            }}
          >
            ON
          </div>
          <div style={{ fontFamily: "Gotham-Book" }}>{formatDate(legsData[0]?.on_datetime?.value) || "n/a"}</div>
          <div
            style={{
              fontFamily: "Gotham-Medium",
              width: "90px",
              float: "left",
            }}
          >
            IN ({legsData[0]?.destination_airport?.replace(" ", "")})
          </div>
          <div style={{ fontFamily: "Gotham-Book" }}>{formatDate(legsData[0]?.in_datetime?.value) || "n/a"}</div>
        </div>
      </React.Fragment>
    );
  }

  // Function to get the card view contents for unavailable data on selected legs
  // eslint-disable-next-line
  function getLegsOverviewUnavailable(data, index) {
    return (
      <React.Fragment>
        <div style={{ padding: "14px" }}>
          <div
            style={{
              fontFamily: "Gotham-Medium",
              textDecoration: "underline",
              marginTop: "14px",
            }}
          >
            Flight No.
          </div>
          <div style={{ fontFamily: "Gotham-Book" }}>{data[index]?.flight_no}</div>
          <div
            style={{
              fontFamily: "Gotham-Medium",
              textDecoration: "underline",
              marginTop: "14px",
            }}
          >
            OOOI Message
          </div>
          <div
            style={{
              fontFamily: "Gotham-Medium",
              width: "90px",
              float: "left",
            }}
          >
            OUT ({data[index]?.departure_airport})
          </div>
          <div style={{ fontFamily: "Gotham-Book" }}>{formatDate(data[index]?.out_datetime?.value) || "n/a"}</div>
          <div
            style={{
              fontFamily: "Gotham-Medium",
              width: "90px",
              float: "left",
            }}
          >
            OFF
          </div>
          <div style={{ fontFamily: "Gotham-Book" }}>{formatDate(data[index]?.off_datetime?.value) || "n/a"}</div>
          <div
            style={{
              fontFamily: "Gotham-Medium",
              width: "90px",
              float: "left",
            }}
          >
            ETA
          </div>
          <div style={{ fontFamily: "Gotham-Book" }}>{formatDate(data[index]?.eta_datetime?.value) || "n/a"}</div>
          <div
            style={{
              fontFamily: "Gotham-Medium",
              width: "90px",
              float: "left",
            }}
          >
            ON
          </div>
          <div style={{ fontFamily: "Gotham-Book" }}>{formatDate(data[index]?.on_datetime?.value) || "n/a"}</div>
          <div
            style={{
              fontFamily: "Gotham-Medium",
              width: "90px",
              float: "left",
            }}
          >
            IN ({data[index]?.destination_airport})
          </div>
          <div style={{ fontFamily: "Gotham-Book" }}>{formatDate(data[index]?.in_datetime?.value) || "n/a"}</div>
        </div>
      </React.Fragment>
    );
  }

  const generatePfrPDF = () => {
    const info = previouslegs[selectedLeg];
    const leg = legsAll[selectedLeg];
    const pfrIndex =
      leg.findIndex((x) => {
        return x.flight_report_type?.match("PFR") && x.flight_no === info.flight_no;
      }) === -1
        ? 0
        : leg.findIndex((x) => x.flight_report_type?.match("PFR") && x.flight_no === info.flight_no);

    const messageId = leg[pfrIndex]?.msg_id;
    const rawMsg = rawData.find((item) => item.msg_id === messageId);

    setPfrReportData({
      warnings: isWarningReportsFiltered,
      faults: faultReportsFiltered,
      raw_msg: rawMsg.raw_msg,
      ac_reg: info.AC_REG,
      telex_gen: info.telex_gen,
      flight_no: info.flight_no,
      departure_airport: info.departure_airport,
      destination_airport: info.destination_airport,
      name: userName,
    });
    setOpenPfrReportPreview(true);
  };

  const handleClickRepetitiveDefect = async () => {
    try {
      const body = {
        flaggedData: flaggedData,
        updated_at: new Date().toISOString(),
        email: userName,
        name: userProfile.fullName,
        msg_id: msgId,
      };
      const add = makeAPIRequestRun("post", "acarsdata/repetitivedefect/store/", body);
      add.then((response) => {
        if (response.error) {
          console.log("error");
          showNotificationElevade("danger", "Failed!", ["Failure in flagging Repetitive Defect"]);
        } else {
          // setCallApi(!callApi);
          showNotificationElevade("success", "Success", ["Repetitive defect flagged"]);
          setLatestFlight();
          setLatestDate();
          setSelectedLeg("0");
          setActiveLegNo("0");
          setActiveOOOI(false);
        }
      });
    } catch (error) {
      console.log("error flag rdd", error);
      showNotificationElevade("danger", "Failed!", ["Failure flagging Repetitive Defect"]);
    }
  };

  // Check if query params exist in url
  let querySearch = getQueryVariable("search");
  let queryAircraftType = getQueryVariable("at");
  let queryOperator = getQueryVariable("op");
  let queryFaultPriority = getQueryVariable("fp");
  let queryDepartureAirport = getQueryVariable("da");
  let queryArrivalAirport = getQueryVariable("aa");
  let queryAcTab = getQueryVariable("actab");

  let query = "?";
  if (querySearch !== "") query += "search=" + querySearch;
  if (queryAircraftType !== "") query += "&at=" + queryAircraftType;
  if (queryOperator !== "") query += "&op=" + queryOperator;
  if (queryFaultPriority !== "") query += "&fp=" + queryFaultPriority;
  if (queryDepartureAirport !== "") query += "&da=" + queryDepartureAirport;
  if (queryArrivalAirport !== "") query += "&aa=" + queryArrivalAirport;
  if (queryAcTab !== "") query += "&actab=" + queryAcTab;

  const ReportsTab = () => {
    return (
      <>
        <div
          style={{
            marginTop: "33px",
            marginLeft: isWeb ? "0px" : "36px",
            display: "flex",
          }}
        >
          <div
            style={{
              marginRight: "25px",
              paddingBottom: "24px",
              paddingLeft: "24px",
              paddingRight: "24px",
              cursor: "pointer",
            }}
            className={`${isAcarsTab ? styles.activeTab : ""}`}
            onClick={() => {
              setIsAcarsTab(true);
              setIsAcmsTab(false);
              setOpen();
            }}
          >
            ACARS Reports
          </div>
          <div
            style={{
              marginRight: "25px",
              paddingBottom: "24px",
              paddingLeft: "24px",
              paddingRight: "24px",
              cursor: "pointer",
            }}
            className={`${isAcmsTab ? styles.activeTab : ""}`}
            onClick={() => {
              setIsAcarsTab(false);
              setIsAcmsTab(true);
              setOpen();
            }}
          >
            ACMS Reports
            {<span className={`${styles.countTooltip}`}>{totalRows ?? 0}</span>}
          </div>
          {/* {!showSchedule ? (
            <div
              className="small-font gotham-medium"
              style={{
                marginLeft: "auto",
                marginRight: "17px",
                marginTop: "-6px",
                cursor: "pointer",
              }}
              onClick={() => setShowSchedule(!showSchedule)}
            >
              <img src={Calendar} height="20px" alt="calendar" style={{ marginRight: "8px", marginTop: "-2px" }} />
              <span>Show Schedule</span>
            </div>
          ) : (
            <></>
          )} */}
        </div>
        <div className={styles.tabLiner}></div>
      </>
    );
  };

  function getLegsLabel(count) {
    if (count === "15" || count === "30" || count === "50") return count;
    else if (count === "100") return "51 - 100";
    else if (count === "150") return "101 - 150";
    else if (count === "200") return "151 - 200";
  }

  const DisplayDataHeader = () => {
    return isLoadingLegs ? (
      ""
    ) : (
      // return (
      <>
        <div className={`row ${styles.displayHeader}`}>
          <Grid container spacing={1} columns={24} className={`${styles.displayHeaderLabel}`}>
            <Grid
              item
              // xs={latestDate === undefined && isLegs <= 30 ? 6.5 : latestDate === undefined && isLegs > 30 ? 8 : 4.5}
              xs={latestDate === undefined && isLegs > 30 ? 7 : isLegs <= 30 ? 7 : 6.5}
              className={styles.nowrap}
            >
              Priority <span style={{ marginLeft: "52px" }}>Message</span>
            </Grid>
            <Grid item xs={isLegs <= 30 ? 2 : 1.5} className={styles.nowrap}>
              ATA
            </Grid>
            <Grid
              item
              xs={latestDate === undefined && isLegs <= 30 ? 6.5 : isLegs <= 30 ? 2 : 1.5}
              className={styles.nowrap}
            >
              Source
            </Grid>
            <Grid
              item
              xs={isLegs <= 30 ? 2 : 1.5}
              className={styles.nowrap}
              sx={{ display: latestDate === undefined ? "none" : "" }}
            >
              Phase
            </Grid>
            <Grid
              item
              xs={isLegs <= 30 ? 2.5 : 2}
              className={styles.nowrap}
              sx={{ display: latestDate === undefined ? "none" : "" }}
            >
              Time
              {localTime ? " (" + timeZone + ")" : " (UTC)"}
            </Grid>
            <Grid item xs={1} className={styles.nowrap}>
              Rep.
            </Grid>
            <Grid
              item
              xs={
                latestDate === undefined && isLegs > 30
                  ? 13
                  : latestDate !== undefined && isLegs > 30
                  ? 10
                  : latestDate === undefined && isLegs <= 30
                  ? 7.5
                  : latestDate !== undefined && isLegs <= 30
                  ? 7.5
                  : 7
              }
              className={styles.nowrap}
              style={{ display: "flex", justifyContent: "center" }}
            >
              Previous {getLegsLabel(isLegs)} Legs
            </Grid>
            {OOOIData?.flight_report_type?.match("PFR") && (
              <Grid
                item
                xs={0.5}
                // className={styles.nowrap}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  position: "relative",
                }}
              >
                <span className={`${styles.displayHeaderLabel}`}>
                  <img src={Printer} className={styles.cogWheelIcon} onClick={() => generatePfrPDF()} alt="" />
                </span>
              </Grid>
            )}
          </Grid>
        </div>
      </>
    );
  };

  function showDetailsRawData(msgId) {
    let rawMsg = rawData.find((e) => e?.msg_id === msgId);
    return rawMsg?.raw_msg;
  }

  const GeneralInfo = ({
    legId,
    type,
    failure_messages,
    ata_chapter,
    text,
    parentText,
    parentCode,
    source,
    time,
    telexGen,
  }) => {
    let legsData = isLegsArray;
    legsData = isLegsArray.filter(
      // (item) => item.ata_chapter === ata_chapter && item.source === source && item.text === text
      (item) => item.text === text && item.flight_report_type === "CFR"
    );
    setLegData(legsData[0]);
    const displayPriority =
      isEmpty(legsData[0]?.priority) || legsData[0]?.priority === "null" || legsData[0]?.priority === "None"
        ? "No"
        : legsData[0]?.priority;

    setEventMessage(legsData[0]?.text);
    setAtaChapter(ata_chapter);
    setAcType(legsData[0]?.Type);
    const label = getLabelAndTitle(legsData[0]?.ata_chapter, legsData[0]?.text);
    const mel = [...new Set(legsData[0]?.mel?.split(","))];

    return (
      <div className={`row ${styles.legsBodyContent}`}>
        <Grid container columns={2}>
          <Grid item xs={1}>
            <Grid sx={{ fontFamily: "Gotham-Medium" }} style={{ textTransform: "uppercase", paddingBottom: "15px" }}>
              {legsData[0]?.text}
            </Grid>
            <Grid>
              <Box sx={{ overflowY: "scroll", border: 1, borderColor: "#E8E8E8" }} height="150px" width="100%">
                <span className={`${styles.legsDetailsHeader}`}>
                  Telex Raw Data: <br />
                </span>
                <span className={`${styles.legsDetailsText}`} style={{ display: "block" }}>
                  {showDetailsRawData(legsData[0]?.msg_id)}
                </span>
              </Box>
            </Grid>
            <Grid container columns={5}>
              <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} xl={1.5} className={`${styles.legsDetailsHeader}`}>
                Troubleshooting Manual: <br />
                <TroubleshootingRef
                  legId={legId}
                  type={msgType}
                  failure_messages={failure_messages}
                  ata_chapter={ata_chapter}
                  search_suggestion={legsData[0]?.search_suggestion}
                  parentText={parentText}
                  parentCode={parentCode}
                  legsData={legsData[0]?.Type}
                  filteredData={isWarningReportsFiltered.filter((e) => e.report_type !== "FAULT" || e.correlated === 1)}
                />
              </Grid>
              <Grid item xs={1.75} sm={1.75} md={1.75} lg={1.75} xl={1.75} className={`${styles.legsDetailsHeader}`}>
                <Grid>
                  Technical Follow-Up: <br />
                  <DisplayTSITFU leg={legsData[0]} isTFU={true} />
                </Grid>
              </Grid>
              <Grid item xs={1.75} sm={1.75} md={1.75} lg={1.75} xl={1.75} className={`${styles.legsDetailsHeader}`}>
                In-Service Information: <br />
                <DisplayTSITFU leg={legsData[0]} isTFU={false} />
              </Grid>
            </Grid>
            <Grid container columns={5}>
              <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} xl={1.5} className={`${styles.legsDetailsHeader}`}>
                Minimum Equipment List: <br />
                {mel.length > 0
                  ? mel.map((mel, index) => {
                      return (
                        <>
                          <Grid container key={index} sx={{ color: "black" }}>
                            {mel}
                          </Grid>
                        </>
                      );
                    })
                  : "-"}
              </Grid>
              <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} xl={1.5} className={`${styles.legsDetailsHeader}`}>
                Aircraft Location: <br />
                <Grid container columns={2}>
                  <Grid item xs={0.2}>
                    <span>
                      <img
                        src={ShareIcon}
                        alt="external-frame-link"
                        style={{
                          backgroundColor: "#088FD108",
                          paddingRight: "8px",
                          height: "16px",
                          marginTop: "10px",
                        }}
                      />
                    </span>{" "}
                  </Grid>
                  <Grid item xs={1.8}>
                    <Grid container sx={{ pt: "10px" }}>
                      <Link
                        target="_blank"
                        to={{
                          pathname: `https://flightaware.com/live/flight/${id}`,
                        }}
                        rel="noreferrer"
                        className={`${styles.linkBlue}`}
                        style={{ backgroundColor: "#088FD108" }}
                      >
                        flightaware.com/live/flight
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1}>
            <Grid>
              <Grid container columns={3} sx={{ paddingBottom: "24px" }}>
                <Grid item xs={1}>
                  <span className={`${styles.legsDetailsHeader}`}>ATA</span> <br />
                  <span className={`${styles.legsDetailsText}`}>
                    {legsData[0]?.ata_chapter === "UNIDENTIFIED"
                      ? isNumeric(legsData[0]?.ata_chapter_raw) || isCombineNumericLetters(legsData[0]?.ata_chapter_raw)
                        ? getAtaChapter(legsData[0]?.ata_chapter_raw) ?? "-"
                        : legsData[0]?.ata_chapter_raw ?? "-"
                      : getAtaChapter(legsData[0]?.ata_chapter) ?? "-"}
                  </span>
                </Grid>
                <Grid item xs={1}>
                  <span className={`${styles.legsDetailsHeader}`}> Flight:</span> <br />
                  <span className={`${styles.legsDetailsText}`}> {legsData[0]?.flight_no ?? "-"}</span>
                </Grid>
                <Grid item xs={1}>
                  <span className={`${styles.legsDetailsHeader}`}> Priority:</span> <br />
                  <span className={`${styles.legsDetailsText}`}>
                    {displayPriority === "High" ? (
                      <span style={{ color: "#FC3F3F" }}>High Priority</span>
                    ) : (
                      displayPriority + " Priority "
                    )}
                  </span>
                </Grid>
              </Grid>
            </Grid>
            <Grid>
              <Grid container columns={3} sx={{ paddingBottom: "24px" }}>
                <Grid item xs={1}>
                  <span className={`${styles.legsDetailsHeader}`}> Departure:</span> <br />
                  <span className={`${styles.legsDetailsText}`}> {legsData[0]?.departure_airport ?? "-"}</span>
                </Grid>
                <Grid item xs={1}>
                  <span className={`${styles.legsDetailsHeader}`}> Arrival:</span> <br />
                  <span className={`${styles.legsDetailsText}`}> {legsData[0]?.destination_airport ?? "-"}</span>
                </Grid>
                <Grid item xs={1}>
                  {legsData[0]?.Type === "A330" ? (
                    <>
                      <span className={`${styles.legsDetailsHeader}`}> Class:</span> <br />
                      <span className={`${styles.legsDetailsText}`}>
                        {legsData[0]?.flr_class ? legsData[0]?.flr_class : "-"}
                      </span>
                    </>
                  ) : (
                    <>
                      <span className={`${styles.legsDetailsHeader}`}> Label:</span> <br />
                      {displayLabel("10px", label.label, label.title, "")}
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid>
              <Grid container columns={3}>
                <Grid item xs={1}>
                  <span className={`${styles.legsDetailsHeader}`}> Occurrence:</span> <br />
                  <span className={`${styles.legsDetailsText}`}>
                    {" "}
                    {ConvertUTCDatetime(changeFormatDate(legsData[0]?.timestamp?.value), localTime)}
                  </span>
                </Grid>
                <Grid item xs={1}>
                  <span className={`${styles.legsDetailsHeader}`}> Flight Phase:</span> <br />
                  <span className={`${styles.legsDetailsText}`} style={{ display: "block" }}>
                    {legsData[0]?.flight_phase_id + "-" + legsData[0]?.flight_phase}
                  </span>
                </Grid>
                <Grid item xs={1}>
                  {legsData[0]?.Type === "A330" ? (
                    <>
                      <span className={`${styles.legsDetailsHeader}`}> Label:</span> <br />
                      {displayLabel("-45px", label.label, label.title, "")}
                    </>
                  ) : (
                    <></>
                  )}
                  {/* <span className={`${styles.legsDetailsHeader}`}> Flight Report:</span> <br />
                  <span className={`${styles.legsDetailsText}`}> {legsData[0]?.flight_report_type}</span> */}
                </Grid>
              </Grid>
            </Grid>
            <Grid>
              <Grid container columns={3} sx={{ paddingBottom: "24px" }}>
                <Grid item xs={1}>
                  <span className={`${styles.legsDetailsHeader}`}> Telex Generated:</span> <br />
                  <span className={`${styles.legsDetailsText}`}>
                    {" "}
                    {ConvertUTCDatetime(changeFormatDate(legsData[0]?.telex_gen?.value), localTime)}
                  </span>
                </Grid>
                <Grid item xs={1}>
                  <span className={`${styles.legsDetailsHeader}`}> Telex Sent:</span> <br />
                  <span className={`${styles.legsDetailsText}`}>
                    {" "}
                    {ConvertUTCDatetime(changeFormatDate(legsData[0]?.telex_sent?.value), localTime)}
                  </span>
                </Grid>
              </Grid>
            </Grid>
            <DisplayLegsDetailsButton
              type={type}
              correlated={legsData[0]?.correlated}
              ata_chapter={legsData[0]?.ata_chapter}
              priority={legsData[0]?.priority}
              failure_message={legsData[0]?.text}
            />
          </Grid>
        </Grid>
      </div>
    );
  };

  const TroubleshootingRef = ({
    legId,
    type,
    failure_messages,
    ata_chapter,
    search_suggestion,
    parentCode,
    legsData,
    filteredData,
  }) => {
    let nextActiveLine = activeLine + 1;
    let description1 = "";
    let description2 = "";
    let additionalQuery = "";
    let code1 = "";
    let code2 = "";
    let cont = true;
    let i = 2;
    let nextFailureMsgId = legId + 2;
    while (cont === true && failure_messages[activeLine + 1]?.correlated === 1 && filteredData.length < activeLine) {
      if (filteredData[nextFailureMsgId]?.correlated === 1) {
        additionalQuery += `&code${i + 1}=${getAtaChapter(filteredData[nextFailureMsgId]?.ata_chapter)}&description${
          i + 1
        }=${filteredData[nextFailureMsgId]?.search_suggestion}`;
      } else cont = false;
      nextFailureMsgId++;
      i++;
    }

    let mpfData = legs[activeLeg];
    let typeIdentifier = "N";
    let ata_chapter_ = ata_chapter;
    if (type === "faults") mpfData = faultLegs[activeLeg];
    const actype = mpfData[activeLine]?.Type ?? legsData?.Type;
    const tailNumber = mpfData[activeLine]?.MSN ?? legsData?.MSN;
    const faultType = filteredData[activeLine]?.report_type === "WARNING" ? "ECAMALERT" : "FAULTMESSAGE";
    const text = type === "warning" ? mpfData[activeLine]?.text : failure_messages[activeLine]?.text;
    const manufacturer =
      type === "warning" ? mpfData[activeLine]?.Manufacturer : failure_messages[activeLine]?.Manufacturer;
    if (filteredData[activeLine]?.report_type === "WARNING") {
      description1 = search_suggestion;
      description2 = filteredData[nextActiveLine]?.search_suggestion;
      code1 = getAtaChapter(filteredData[activeLine]?.ata_chapter, "WARNING");
      code2 = getAtaChapter(filteredData[nextActiveLine]?.ata_chapter);
    } else if (filteredData[activeLine]?.report_type === "FAULT") {
      description2 = search_suggestion;
      description1 = filteredData[legId - 1]?.search_suggestion;
      code1 =
        parentCode !== undefined
          ? getAtaChapter(parentCode, "WARNING")
          : getAtaChapter(filteredData[activeLine]?.ata_chapter);
      code2 = getAtaChapter(filteredData[activeLine]?.ata_chapter);
    }
    if (filteredData[activeLine]?.Type === "A330") typeIdentifier = "F";
    if (filteredData[activeLine]?.report_type === "WARNING") ata_chapter_ = getAtaChapter(ata_chapter, "WARNING");
    else ata_chapter_ = getAtaChapter(ata_chapter);
    if (code2?.endsWith("00") && code2?.length > 5) code2 = code2?.substring(0, 5);
    if (code1?.endsWith("00") && code1?.length > 5) code1 = code1?.substring(0, 5);
    if (ata_chapter_?.endsWith("00") && ata_chapter_?.length > 5) ata_chapter_ = ata_chapter_?.substring(0, 5);

    return (
      <>
        <Grid container columns={5} sx={{ paddingTop: "10px" }}>
          <Grid
            item
            sx={1}
            style={{
              display: "contents",
              marginBottom: "10px",
              justifyContent: "flex-start",
            }}
          >
            <span>
              <img
                src={ShareIcon}
                alt="external-frame-link"
                style={{
                  marginRight: "10px",
                  marginTop: "-2px",
                  backgroundColor: "#088FD108",
                }}
              />
            </span>
          </Grid>
          <Grid
            item
            sx={4}
            style={{
              display: "contents",
              marginBottom: "10px",
              justifyContent: "flex-start",
            }}
          >
            <Link
              target="_blank"
              to={{
                pathname:
                  manufacturer === "Boeing"
                    ? type === "warning"
                      ? `${Boeing_Toolbox_Url}#/FaultFinder/ap/34785/fc/${
                          extractWarningCode(text) !== null ? extractWarningCode(text) : extractFaultCode(text)
                        }`
                      : `${Boeing_Toolbox_Url}#/FaultFinder/ap/34785/mn/${extractFaultCode(text)}`
                    : "https://w3.airbus.com/1T40/troubleshooting?wc=actype:" +
                      actype +
                      ";tailNumber:" +
                      typeIdentifier +
                      tailNumber +
                      "&type=" +
                      faultType +
                      "&description1=" +
                      search_suggestion +
                      "&code1=" +
                      ata_chapter_ +
                      "*",
              }}
              rel="noreferrer"
              className={`${styles.linkBlue}`}
              style={{ backgroundColor: "#088FD108" }}
            >
              TSM Reference
            </Link>
          </Grid>
        </Grid>
        {manufacturer === "AIRBUS" && (
          <Grid container columns={5}>
            {(filteredData[nextActiveLine]?.correlated === 1 || filteredData[activeLine]?.correlated === 1) &&
              type === "warning" && (
                <>
                  <Grid
                    item
                    xs={1}
                    style={{
                      display: "contents",
                      marginBottom: "10px",
                      justifyContent: "flex-start",
                    }}
                  >
                    <span>
                      <img
                        src={ShareIcon}
                        alt="external-frame-link"
                        style={{
                          marginRight: "10px",
                          marginTop: "-2px",
                          backgroundColor: "#088FD108",
                        }}
                      />
                    </span>
                  </Grid>
                  <Grid item xs={4}>
                    <Link
                      target="_blank"
                      to={{
                        pathname:
                          manufacturer === "Boeing"
                            ? type === "warning"
                              ? `${Boeing_Toolbox_Url}#/FaultFinder/ap/34785/fc/${
                                  extractWarningCode(text) !== null ? extractWarningCode(text) : extractFaultCode(text)
                                }`
                              : `${Boeing_Toolbox_Url}#/FaultFinder/ap/34785/mn/${extractFaultCode(text)}`
                            : "https://w3.airbus.com/1T40/troubleshooting?wc=actype:" +
                              actype +
                              ";tailNumber:" +
                              typeIdentifier +
                              tailNumber +
                              "&type=FULLSEARCH" +
                              "&code1=" +
                              code1 +
                              "*" +
                              "&code2=" +
                              code2 +
                              "&description1=" +
                              description1 +
                              "&description2=" +
                              description2 +
                              additionalQuery,
                      }}
                      rel="noreferrer"
                      className={`${styles.linkBlue}`}
                      style={{ backgroundColor: "#088FD108" }}
                    >
                      TSM Reference with Correlation
                    </Link>
                  </Grid>
                </>
              )}
          </Grid>
        )}
      </>
    );
  };

  function extractFaultCode(str) {
    const match = str.match(/\[(\d{2})-(\d{5})\]$/);
    return match ? match[1] + match[2] : null;
  }

  function extractWarningCode(str) {
    const match = str.match(/\[(\d{3}) (\d{3}) (\d{2})\]$/);
    return match ? match[1] + match[2] + match[3] : null;
  }

  const DisplayTSITFU = ({ leg, isTFU }) => {
    // eslint-disable-next-line
    const [showMoreTFU, setShowMoreTFU] = useState(false);
    if (!isEmpty(open)) {
      if (open === false) setShowMoreTFU(false);
    }
    const urls = leg?.isi_url?.split(",");
    const type = leg?.isi_type?.split(",");
    const title = leg?.isi_title?.split(",");

    let isiTfuList = [];

    for (var i = 0; i < urls?.length; i++) {
      isiTfuList[i] = {
        url: urls[i],
        ref: type[i]?.split(" ")[1],
        type: type[i]?.split(" ")[0],
        title: title[i],
      };
    }

    let tfu = isiTfuList.filter((data, index) => {
      return isTFU ? data.type === "TFU" : data.type === "ISI";
    });

    tfu = removeDuplicateObjByKey("title", tfu);

    return (
      <Grid>
        <Grid container columns={2}>
          <Grid item xs={2}>
            {tfu?.length > 1 ? (
              tfu?.map((res, index) => {
                return (
                  <Grid container key={index} columns={2}>
                    <Grid item xs={0.2}>
                      <span>
                        <img
                          src={ShareIcon}
                          alt="external-frame-link"
                          style={{
                            backgroundColor: "#088FD108",
                            paddingRight: "8px",
                            height: "16px",
                            marginTop: "10px",
                          }}
                        />
                      </span>{" "}
                    </Grid>
                    <Grid item xs={1.8}>
                      <Grid container sx={{ pt: "10px" }}>
                        <Link
                          target="_blank"
                          to={{
                            pathname: `${res?.url}`,
                          }}
                          rel="noreferrer"
                          className={`${styles.linkBlue}`}
                          style={{ backgroundColor: "#088FD108" }}
                        >
                          {res.type + " " + res.ref}
                        </Link>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })
            ) : tfu.length > 0 ? (
              <Grid container columns={2}>
                <Grid item xs={0.2}>
                  <span>
                    <img
                      src={ShareIcon}
                      alt="external-frame-link"
                      style={{
                        backgroundColor: "#088FD108",
                        paddingRight: "8px",
                        height: "16px",
                        marginTop: "10px",
                      }}
                    />
                  </span>{" "}
                </Grid>
                <Grid item sx={{ pt: "10px" }} xs={1.8}>
                  <Link
                    target="_blank"
                    to={{
                      pathname: `${tfu[0]?.url}`,
                    }}
                    rel="noreferrer"
                    className={`${styles.linkBlue}`}
                    style={{ backgroundColor: "#088FD108" }}
                  >
                    {tfu[0]?.type + " " + tfu[0]?.ref}
                  </Link>
                </Grid>
              </Grid>
            ) : (
              "-"
            )}
          </Grid>
          {/* <Grid item sx={{ pt: "10px", alignContent: "start" }} xs={0.2}>
            {tfu.length > 1 ? (
              <>
                <button
                  className={`${styles.linkBlue}`}
                  style={{
                    backgroundColor: "#088FD108",
                    fontSize: "14px",
                    border: "none",
                    height: "16px",
                  }}
                  onClick={() => {
                    setShowMoreTFU(!showMoreTFU);
                  }}
                >
                  {!showMoreTFU ? <>+{tfu.length - 1}</> : <ExpandLess fontSize="small" />}
                </button>
              </>
            ) : (
              ""
            )}
          </Grid> */}
        </Grid>
      </Grid>
    );
  };

  const DisplayLegsDetailsButton = ({ type, correlated, failure_message, ata_chapter, priority }) => {
    const getLabel = find(labelList, (res) => {
      return res.ata_chapter === ata_chapter && res.message === failure_message;
    });
    setLabel(getLabel);
    return (
      <>
        <div
          style={{
            position: "absolute",
            bottom: "0",
            right: "20px",
            paddingTop: "20px",
          }}
        >
          {correlated === 0 && type === "warning" ? (
            <AdeButton
              startIcon={<img src={Swap} alt="" style={{ filter: "grayscale(1)" }} />}
              variant="text"
              sx={{
                ml: 0,
                mr: "16px",
                color: "#088FD1",
                fontFamily: "Gotham-Book",
                fontSize: "16px",
              }}
              fullWidth={false}
              onClick={() => {
                setOpenPriorityStatus(true);
                setEventMessage(failure_message);
                setAtaChapter(ata_chapter);
                setPriority(priority);
                setAcType(type);
              }}
              disabled
            >
              Change Priority
            </AdeButton>
          ) : (
            ""
          )}
          <AdeButton
            startIcon={<FormatListBulleted />}
            variant="text"
            fullWidth={false}
            sx={{
              ml: 0,
              mr: "16px",
              color: "#088FD1",
              fontFamily: "Gotham-Book",
              fontSize: "16px",
            }}
            onClick={handleClick}
            // disabled
            endIcon={openMenu ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
          >
            More Options{" "}
          </AdeButton>
          <Popover
            open={openMenu}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 200,
              horizontal: 500,
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            anchorReference="anchorPosition"
            anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
          >
            <Paper>
              <MenuItem sx={{ fontSize: "14px", fontFamily: "Gotham-Book" }} onClick={handleClickLabel}>
                Add Label
              </MenuItem>
              <MenuItem sx={{ fontSize: "14px", fontFamily: "Gotham-Book" }} onClick={handleClose} disabled>
                Add Supporting Document
              </MenuItem>
              <MenuItem sx={{ fontSize: "14px", fontFamily: "Gotham-Book" }} onClick={handleClose} disabled>
                Add Link
              </MenuItem>
            </Paper>
          </Popover>

          <AdeButton
            startIcon={<img src={ExportIcon} alt="" style={{ filter: "grayscale(1)" }} />}
            variant="text"
            fullWidth={false}
            sx={{
              ml: 0,
              color: "#088FD1",
              fontFamily: "Gotham-Book",
              fontSize: "16px",
            }}
            // onClick={handlePdfClick}
            disabled
          >
            Export
          </AdeButton>
        </div>
      </>
    );
  };

  const DisplayLegsDetails = ({
    legId,
    open,
    type,
    text,
    ata_chapter,
    failure_messages,
    parentText,
    parentCode,
    source,
    time,
    telexGen,
  }) => {
    return open === legId && type === msgType ? (
      <>
        <div className={`${styles.displayLegsDetails}`} style={{ zIndex: "9999" }}>
          <Grid container columns={12}>
            <Grid item xs={10} className={`${styles.displayLegsDetailsHeader}`}>
              <div
                style={{
                  minHeight: "48px",
                }}
                className={`${styles.link} ${styles.legsDetailsHeaderText}`}
                id="legDetails"
              >
                <Link
                  to={`/fleet/aircraft-status/${id}/failure-messages/general-info${query}`}
                  className={`${styles.legsDetailsHeaderTextColor} ${getActivePath("general-info")} ${
                    pathname.endsWith("/failure-messages") ||
                    pathname.includes("/failure-messages/q") ||
                    pathname.includes("/failure-messages/general-info")
                      ? styles.activeTabLegDetails
                      : ""
                  }`}
                >
                  General Info
                </Link>
              </div>
              {/* <div
                style={{
                  minHeight: "48px",
                }}
                className={`${styles.link} ${styles.legsDetailsHeaderText}`}
              >
                <Link
                  to={`/fleet/aircraft-status/${id}/failure-messages/hold-items-list${query}`}
                  className={`${styles.legsDetailsHeaderTextColor} ${getActivePath("hold-items-list")} ${
                    pathname.includes("/failure-messages/hold-items-list") ? styles.activeTabLegDetails : ""
                  } ${styles.disabledlink}`}
                >
                  Hold Items List
                </Link>
              </div>
              <div
                style={{
                  minHeight: "48px",
                }}
                className={`${styles.link} ${styles.legsDetailsHeaderText}`}
              >
                <Link
                  to={`/fleet/aircraft-status/${id}/failure-messages/label${query}`}
                  className={`${styles.legsDetailsHeaderTextColor} ${getActivePath("label")} ${
                    pathname.includes("/failure-messages/label") ? styles.activeTabLegDetails : ""
                  } ${styles.disabledlink}`}
                >
                  Label
                </Link>
              </div>
              <div
                style={{
                  minHeight: "48px",
                }}
                className={`${styles.link} ${styles.legsDetailsHeaderText}`}
              >
                <Link
                  to={`/fleet/aircraft-status/${id}/failure-messages/mel${query}`}
                  className={`${styles.legsDetailsHeaderTextColor} ${getActivePath("mel")} ${
                    pathname.includes("/failure-messages/mel") ? styles.activeTabLegDetails : ""
                  } ${styles.disabledlink}`}
                >
                  MEL
                </Link>
              </div> */}
            </Grid>
            <Grid item xs={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
              <img
                src={CloseIcon}
                height="20px"
                className={`${styles.closeIcon}`}
                onClick={() => {
                  setOpen(false);
                  setFautlLeg(null);
                  setActivelLeg(null);
                }}
                alt="close"
              />
            </Grid>
          </Grid>
          <div style={{ position: "relative" }}>
            {getActivePath("general-info") !== "" ||
            pathname.endsWith("/failure-messages") ||
            pathname.includes("/failure-messages/q") ? (
              <GeneralInfo
                legId={legId}
                type={msgType}
                failure_messages={failure_messages}
                ata_chapter={ata_chapter}
                text={text}
                parentText={parentText}
                parentCode={parentCode}
                source={source}
                time={time}
                telexGen={telexGen}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </>
    ) : (
      ""
    );
  };

  const handleResetActiveLeg = (id) => {
    let legs = id + 1;
    // Uncomment this to apply initial view all warning & fault messages
    if (latestFlight !== previouslegs[id]?.flight_no && latestDate !== previouslegs[id]?.start_time?.value) {
      setLatestFlight(previouslegs[id]?.flight_no);
      setLatestDate(previouslegs[id]?.start_time?.value);
      setOpen(false);
      // Uncomment this to apply initial view all warning & fault messages
      setActivelLeg(null);
      setFautlLeg(null);
      setSelectedLeg(id);
      setActiveLegNo(legs);
      setFlightNo(previouslegs[id]?.flight_no);
      setAircraft(previouslegs[id]?.AC_REG);
      setDepartureTime(formatDate(previouslegs[id]?.start_time.value));
      setArrivalTime(formatDate(previouslegs[id]?.end_time.value));
      setRoute(previouslegs[id]?.departure_airport + " - " + previouslegs[id]?.destination_airport);
      // if (!prevURLNewTab)
      setActiveOOOI(true);
      if (previouslegs[id]?.priority === "Missing") setMissingData(true);
      else setMissingData(false);
      // Uncomment this to apply initial view all warning & fault messages
    } else {
      setLatestFlight();
      setLatestDate();
      setSelectedLeg("0");
      setActiveLegNo("0");
      setActiveOOOI(false);
    }
  };

  function getMatch(item, leg, index, isLegs) {
    let legsNum = parseInt(isLegs);
    if (legsNum <= 50) legsNum = 0;
    else legsNum = legsNum - 50;
    let data = "";
    let index_ = index + legsNum + 1;
    let array_ = item?.legs?.includes(",") ? item?.legs?.split(",") : item?.legs;
    // return index_;
    if (item.priority === "Missing")
      array_ = item?.missing_legs?.includes(",") ? item?.missing_legs?.split(",") : item?.missing_legs;
    if (latestFlight === undefined && latestDate === undefined) {
      if (leg[0]?.priority === "Missing") {
        return 2;
      } else if (
        item?.ata_chapter === "UNIDENTIFIED" &&
        Boolean(
          leg.find((a) => {
            return a.ata_chapter === "UNIDENTIFIED";
          })
        ) &&
        Boolean(
          leg.find((a) => {
            return a.text === "NO WARNING OR FAULT MESSAGE";
          })
        ) === false
      ) {
        return 3;
      } else if (Array.isArray(array_)) {
        if (array_?.filter((e) => e === index_.toString()).length > 0) return 1;
      } else if (array_ === index_.toString()) return 1;
      return 0;
    } else {
      data = leg.filter(
        (e) =>
          e.text === item.text &&
          e.ata_chapter === item.ata_chapter &&
          e.source === item.source &&
          item.legs?.includes(index_)
      );
      if (data.length > 0 && item.ata_chapter !== "UNIDENTIFIED") return 1;
      else if (item.priority === "Missing" && index_ === item.leg) return 2;
      else if (item.ata_chapter === "UNIDENTIFIED") {
        if (Object.values(array_)?.filter((e) => e === index_.toString()).length > 0) return 3;
      } else return 0;
    }
  }

  function GetRepetitive(data) {
    let rep = "";
    if (data.repetitive === 1) rep = "C";
    else if (data.multiple === 1) rep = "NC";
    return (
      rep !== "" && (
        <>
          <span className={styles.dataItemsRep}>{rep}</span>
        </>
      )
    );
  }

  function handleOnMouseEnter(leg) {
    if (leg !== undefined && leg !== -1) {
      // let newLeg = leg; //to cater for isLegs 50-100, 101-150, 151-200
      // if (msgFromInbox) {
      //   if (isLegs === "100") {
      //     newLeg += 50;
      //   } else if (isLegs === "150") {
      //     newLeg += 100;
      //   } else if (isLegs === "200") {
      //     newLeg += 150;
      //   }
      // }

      const pfrIndex =
        legsAll[leg]?.findIndex((x) => {
          return x.flight_report_type?.match("PFR") && x.flight_no === previouslegs[leg].flight_no;
        }) === -1
          ? 0
          : legsAll[leg]?.findIndex(
              (x) => x.flight_report_type?.match("PFR") && x.flight_no === previouslegs[leg].flight_no
            );
      const legData = legsAll[leg];
      const messageId = legData[pfrIndex].msg_id;
      const rawMessage = rawData.find((item) => item.msg_id === messageId);

      setOOOOIData({
        start_time: previouslegs[leg]?.start_time,
        flight_no: previouslegs[leg]?.flight_no,
        departure_airport: previouslegs[leg]?.departure_airport,
        destination_airport: previouslegs[leg]?.destination_airport,
        latitude_dec: previouslegs[leg]?.latitude_dec,
        longitude_dec: previouslegs[leg]?.longitude_dec,
        out_datetime_format: previouslegs[leg]?.out_datetime_format,
        off_datetime_format: previouslegs[leg]?.off_datetime_format,
        on_datetime_format: previouslegs[leg]?.on_datetime_format,
        in_datetime_format: previouslegs[leg]?.in_datetime_format,
        eta_datetime_format: previouslegs[leg]?.eta_datetime_format,
        on_datetime_format_view: previouslegs[leg]?.on_datetime_format_view,
        MSN: previouslegs[leg]?.MSN,
        Operator: previouslegs[leg]?.Operator,
        Manufacturing_Date: previouslegs[leg]?.Manufacturing_Date,
        Type: previouslegs[leg]?.Type,
        Model: previouslegs[leg]?.Model,
        flight_hour: previouslegs[leg]?.flight_hour,
        flight_cycle: previouslegs[leg]?.flight_cycle,
        fhc_updated_datetime: previouslegs[leg]?.fhc_updated_datetime,
        flight_report_type: previouslegsList[leg]?.flight_report_type,
        leg: leg + 1,
        AC_REG: previouslegs[leg]?.AC_REG,
        oooi: {
          out_datetime: previouslegsList[leg]?.out_datetime,
          off_datetime: previouslegsList[leg]?.off_datetime,
          off_datetime_format: previouslegs[leg]?.off_datetime_format,
          on_datetime: previouslegsList[leg]?.on_datetime,
          on_datetime_format: previouslegs[leg]?.on_datetime_format,
          on_datetime_format_view: previouslegs[leg]?.on_datetime_format_view,
          in_datetime: previouslegsList[leg]?.in_datetime,
          eta_datetime: previouslegsList[leg]?.eta_datetime,
          eta_datetime_format: previouslegs[leg]?.eta_datetime_format,
          eta_datetime_format_view: previouslegs[leg]?.eta_datetime_format_view,
        },
        report: {
          warnings: isWarningReportsFiltered,
          faults: faultReportsFiltered,
          raw_msg: rawMessage.raw_msg,
          name: userProfile?.fullName,
          ac_reg: legData && legData[pfrIndex]?.AC_REG,
          flight_no: legData && legData[pfrIndex]?.flight_no,
          departure_airport: legData && legData[pfrIndex]?.departure_airport,
          destination_airport: legData && legData[pfrIndex]?.destination_airport,
          telex_gen: legData && legData[pfrIndex]?.telex_gen,
        },
      });
    }
  }

  function NoWarningFaultsMsg(type, time) {
    const [show, setShow] = useState(false);
    useEffect(() => {
      const timeoutId = setTimeout(() => {
        setShow(true);
      }, time);

      return () => clearTimeout(timeoutId);
      // eslint-disable-next-line
    }, []);
    return (
      <>
        <div className={styles.dataItemsUnavailable}>
          <Grid container columns={24}>
            <Grid
              item
              xs={latestDate === undefined && isLegs > 30 ? 14.5 : isLegs <= 30 ? 16.5 : 14}
              className={`${styles.dataItemsTextUnavailable}`}
              sx={{ marginTop: "8px" }}
            >
              {show ? (
                <span style={{ marginLeft: "12px" }}>No {type === "warning" ? "Warning" : "Fault"} Message</span>
              ) : (
                <span style={{ marginLeft: "12px" }}>Loading...</span>
              )}
            </Grid>
            <Grid
              item
              xs={
                latestDate === undefined && isLegs > 30
                  ? 13
                  : latestDate !== undefined && isLegs > 30
                  ? 10
                  : latestDate === undefined && isLegs <= 30
                  ? 7.5
                  : latestDate !== undefined && isLegs <= 30
                  ? 7.5
                  : 6.5
              }
              style={{
                display: "flex",
                justifyContent: "left",
                paddingTop: "2px",
              }}
              id="warningLegs"
            ></Grid>
          </Grid>
        </div>
      </>
    );
  }

  function handleSetOpenLegDetails(index, indexes, type, isOpen, item, text) {
    if (isOpen) {
      const pfrIndex =
        item?.findIndex((x) => x?.flight_report_type?.match("PFR")) === -1
          ? 0
          : item?.findIndex((x) => x?.flight_report_type?.match("PFR"));
      const res = warningReports.filter((ele) => ele.msg_id === item[pfrIndex]?.msg_id);
      const warning = res?.filter((item) => item.report_type === "WARNING");
      const fault = res?.filter((item) => item.report_type === "FAULT");
      handleSetActiveLeg(index, indexes, type);
      setOpen(index);
      setMsgType(type);
      setSelectedLeg(indexes);
      setOpenLeg(type + "-" + index);
      setOOOOIData({
        ...item[pfrIndex],
        leg: indexes + 1,
        AC_REG: item[pfrIndex]?.AC_REG,
        start_time: item[pfrIndex]?.start_time,
        report: {
          warnings: warning,
          faults: fault,
          raw_msg: rawData[pfrIndex]?.raw_msg,
          name: userProfile?.fullName,
          ac_reg: item[pfrIndex]?.AC_REG,
          flight_no: item[pfrIndex]?.flight_no,
          departure_airport: item[pfrIndex]?.departure_airport,
          destination_airport: item[pfrIndex]?.destination_airport,
          telex_gen: item[pfrIndex]?.telex_gen,
        },
      });
      setActiveOOOI(true);
      setIsLegsArray(item);
      // setLatestFlight(item[0]?.flight_no);
      // setLatestDate(item[0]?.start_time?.value);
    }
  }

  function chkNoWarningFaultMsg(type) {
    let data = type === "warning" ? isWarningReportsFiltered : faultReportsFiltered;
    const msg =
      type === "warning"
        ? data.filter((e) => e.report_type === "WARNING" || e.correlated === 1 || e.report_type === "NONE")
        : faultReportsFiltered;
    if (msg.length === 1 && msg[0]?.text === "NO WARNING OR FAULT MESSAGE") return true;
    if (msg.length === 0) return true;
    else return false;
  }

  const handleSetActiveLeg = (index, indexes, type) => {
    setFautlLeg(index);
    setActivelLeg(indexes);
    setMsgType(type);
  };

  const handleActiveLeg = (index, indexes, type, ataChapter) => {
    setActiveLine(faultLeg);
    if (faultLeg === index && activeLeg === indexes && type === msgType) {
      if (ataChapter === "UNIDENTIFIED") {
        return styles.activeLegsUnidentifiedChapter;
      } else {
        return styles.activeLegs;
      }
    }
  };

  let startSlice = 0;
  let endSlice = parseInt(isLegs);

  if (isLegs > 50) {
    startSlice = isLegs - 50;
    endSlice = parseInt(isLegs);
  }

  if (legIndex) {
    if (_.inRange(legIndex, 0, 14)) {
      endSlice = 15;
      setIsLegs("15");
      setCheckOnce(false);
    } else if (_.inRange(legIndex, 15, 29)) {
      endSlice = 30;
      setIsLegs("30");
      setCheckOnce(false);
    } else if (_.inRange(legIndex, 30, 49)) {
      endSlice = 50;
      setIsLegs("50");
      setCheckOnce(false);
    } else if (_.inRange(legIndex, 50, 99)) {
      endSlice = 100;
      setIsLegs("100");
      setCheckOnce(false);
    } else if (_.inRange(legIndex, 100, 149)) {
      endSlice = 150;
      setIsLegs("150");
      setCheckOnce(false);
    } else if (_.inRange(legIndex, 150, 199)) {
      endSlice = 200;
      setIsLegs("200");
      setCheckOnce(false);
    }
  }

  const NoWarningFaultMsg = ({ type }) => {
    return (
      <>
        <span
          style={{
            marginLeft: "24px",
            fontStyle: "italic",
            fontSize: "12px",
            textTransform: "capitalize",
          }}
        >
          No {type} Message
        </span>
      </>
    );
  };

  function flaggedMessage(data) {
    let tempData = [];
    tempData.push(data);
    isWarningReportsFiltered
      .filter((elem) => (elem.report_type !== "FAULT" || elem.correlated === 1) && elem.flight_report_type !== "PFR")
      .map((item, index, element) => {
        if (item.correlated_wrn_text === data.text && item.leg === data.leg) {
          tempData.push(item);
        }
      });
    setFlaggedData(tempData);
  }

  const displayFlag = (item) => {
    return (
      isDisplayFlag && (
        <>
          {latestFlight !== undefined && latestDate !== undefined ? (
            item?.defect_flag === 0 ? (
              <img
                className="defectFlagOffV2"
                src={defectFlagOffV2}
                alt="defectFlagOffV2"
                style={{
                  left: "166px",
                  height: "18px",
                  marginTop: "4px",
                  zIndex: 1,
                  position: "absolute",
                  cursor: "pointer",
                }}
                onMouseEnter={(e) => (e.currentTarget.src = defectFlagFull)}
                onMouseLeave={(e) => (e.currentTarget.src = defectFlagOffV2)}
                onClick={() => {
                  setOpenFlagDefect(!openFlagDefect);
                  setDefectModalData(item);
                  setMsgId(item.msg_id);
                  flaggedMessage(item);
                }}
              />
            ) : (
              <StyledTooltip
                title={"Mark as Repetitive Defect"}
                placement="bottom"
                enterDelay={500}
                followCursor={false}
                classes={{ tooltip: classes.messageTootltip }}
              >
                <img
                  className="defectFlagFull"
                  src={defectFlagFull}
                  alt="defectFlagFull"
                  style={{
                    left: "180px",
                    height: "18px",
                    marginTop: "4px",
                    zIndex: 1,
                    position: "absolute",
                  }}
                />
              </StyledTooltip>
            )
          ) : item?.defect_flag === 1 ? (
            <StyledTooltip
              title={"Mark as Repetitive Defect"}
              placement="bottom"
              enterDelay={500}
              followCursor={false}
              classes={{ tooltip: classes.messageTootltip }}
            >
              <img
                className="defectFlagFull"
                src={defectFlagFull}
                alt="defectFlagFull"
                style={{
                  left: "180px",
                  height: "18px",
                  marginTop: "4px",
                  zIndex: 1,
                  position: "absolute",
                }}
              />
            </StyledTooltip>
          ) : (
            <></>
          )}
        </>
      )
    );
  };

  const DisplayDataBody = () => {
    let parentText = "";
    let parentCode = "";

    return isLoadingLegs ? (
      ""
    ) : (
      <>
        <div className={`row ${styles.displayLegsHeader}`}>
          <Grid container spacing={1} columns={24}>
            <Grid
              item
              xs={
                latestDate === undefined && isLegs > 30
                  ? 11
                  : latestDate !== undefined && isLegs > 30
                  ? 14
                  : latestDate === undefined && isLegs <= 30
                  ? 16.5
                  : latestDate !== undefined && isLegs <= 30
                  ? 16.5
                  : 17.5
              }
              sx={{ fontFamily: "Gotham-Medium" }}
            >
              <div style={{ position: "absolute", top: "-10px" }}>Warnings & Correlated Faults</div>
            </Grid>
            <Grid
              item
              xs={
                latestDate === undefined && isLegs > 30
                  ? 13
                  : latestDate !== undefined && isLegs > 30
                  ? 10
                  : latestDate === undefined && isLegs <= 30
                  ? 7.5
                  : latestDate !== undefined && isLegs <= 30
                  ? 7.5
                  : 6.5
              }
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "42px",
              }}
            >
              <Grid container columns={isLegs <= 50 ? isLegs : 50}>
                {previouslegsList?.slice(startSlice, endSlice)?.map((item, index) => {
                  if (item.flight_no === msgFromInbox?.flightNo && item.date.value === msgFromInbox?.eventDate) {
                    setMsgIndexes(index);
                  }
                  if (
                    item.flight_no === msgFromInbox?.flightNo &&
                    item.telex_sent_datetime_format === msgFromInbox?.telexTime
                  ) {
                    setMsgIndexes(index);
                  }
                  let index_ = index;
                  if (isLegs > 50) index_ = parseInt(index) + parseInt(isLegs) - 50;
                  return (
                    <Tooltip
                      title={previouslegsList[index_]?.flight_no || "n/a"}
                      placement="top"
                      arrow
                      classes={{
                        tooltip: classes.flightNoTooltip,
                        arrow: classes.arrow,
                      }}
                      open={!openLabel ? selectedLeg === index_ : ""}
                      sx={{ zIndex: "0" }}
                    >
                      <>
                        <Tooltip
                          title={previouslegsList[index_]?.flight_no || "n/a"}
                          placement="top"
                          arrow
                          classes={{
                            tooltip: classes.flightNoTooltip,
                            arrow: classes.arrow,
                          }}
                          sx={{ zIndex: "1" }}
                        >
                          <Grid
                            items
                            xs={1}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                            onClick={() => {
                              localStorage.removeItem("inboxMsg");
                              setMsgIndex("");
                              setMsgIndexes("");
                              setCheckSpec(false);
                              handleResetActiveLeg(index_);
                              handleOnMouseEnter(index_);
                              // (legs[index_].length > 0 || faultLegs[index_]?.length > 0) && handleResetActiveLeg(index_);
                              // (legs[index_].length > 0 || faultLegs[index_]?.length > 0) &&
                              //   handleOnMouseEnter(previouslegs[index_]);
                            }}
                          >
                            <div
                              className={`${styles.legHeaderCountLabel} ${styles.legColumnHover1} ${
                                selectedLeg === index_ ? styles.activeLegHeader : ""
                              } ${
                                isLegs === "30"
                                  ? styles.legHeaderCountLabel30
                                  : isLegs === "50" || isLegs === "100" || isLegs === "150" || isLegs === "200"
                                  ? styles.legHeaderCountLabel50
                                  : ""
                              } ${item.pfr_received === 1 ? styles.legsWithPFR : ""}`}
                              style={{
                                backgroundColor:
                                  (item.flight_no === msgFromInbox?.flightNo &&
                                    item.date.value === msgFromInbox?.eventDate) ||
                                  (item.flight_no === msgFromInbox?.flightNo &&
                                    item.telex_sent_datetime_format === msgFromInbox?.telexTime)
                                    ? "#e0f5ff"
                                    : "",
                                cursor: "pointer",
                                // legs[index_].length > 0 || faultLegs[index_]?.length > 0 ? "pointer" : "default",
                              }}
                            >
                              {index === 0 && isLegs <= 50 && OOOIData.active_72hrs !== 0 ? (
                                <Live
                                  style={{
                                    height: "24px",
                                    position: "absolute",
                                    top: "0px",
                                  }}
                                />
                              ) : index === 0 && isLegs <= 50 && OOOIData.active_72hrs === 0 ? (
                                <Inactive
                                  style={{
                                    height: "24px",
                                    position: "absolute",
                                    top: "0px",
                                  }}
                                />
                              ) : (
                                "-" + index_
                              )}
                            </div>
                          </Grid>
                        </Tooltip>
                      </>
                    </Tooltip>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </div>
        {isLoadingLegs ? (
          <>
            <Grid container spacing={0}>
              {skeletonLoader(2)}
            </Grid>
          </>
        ) : (
          <>
            {isWarningReportsFiltered && isWarningReportsFiltered.length > 0
              ? isWarningReportsFiltered
                  .filter((e) => e.report_type !== "FAULT" || e.correlated === 1)
                  .map((item, index, element) => {
                    if (item.msg_id === msgFromInbox?.msgId) setMsgIndex(index);
                    if (item.text === msgFromInbox?.msg) setMsgIndex(index);
                    const label = getLabelAndTitle(item.ata_chapter, item.text);
                    let num = index + 1;
                    let nextElement = element[num];
                    let prevElement = element[index - 1];
                    if (item?.report_type === "WARNING" && nextElement?.correlated === 1) {
                      parentText = item?.text;
                      parentCode = item?.ata_chapter;
                    }
                    let margin = "";
                    margin = label?.label ? (item.correlated ? "135px" : "120px") : "120px";
                    return item.priority_def !== "OK" &&
                      item.priority !== "Missing" &&
                      item.flight_report_type !== "PFR" &&
                      (item.report_type === "WARNING" || item.correlated === 1) ? (
                      <>
                        <div
                          className={`row ${
                            faultLeg === index && msgType === "warning" && nextElement?.correlated === 1
                              ? styles.dataItemsActiveCorrelatedParent
                              : faultLeg === index &&
                                msgType === "warning" &&
                                item?.report_type === "FAULT" &&
                                item?.correlated === 1
                              ? styles.dataItemsActiveCorrelatedChild
                              : faultLeg === index &&
                                msgType === "warning" &&
                                nextElement?.correlated === 0 &&
                                nextElement?.text !== undefined &&
                                nextElement?.text !== null
                              ? styles.dataItemsActive
                              : faultLeg === index &&
                                msgType === "warning" &&
                                nextElement?.correlated === 0 &&
                                nextElement?.text === undefined
                              ? styles.dataItemsActiveNoCorrelated
                              : faultLeg === index && msgType === "warning" && nextElement?.correlated === undefined
                              ? styles.dataItemsActive
                              : ""
                          }
                      ${
                        item?.correlated === 1
                          ? styles.correlated
                          : item?.report_type === "WARNING" && nextElement?.correlated === 1
                          ? styles.dataItemsParent
                          : styles.dataItems
                      } 
                      ${
                        nextElement?.correlated === 1 && item?.report_type === "WARNING"
                          ? styles.correlatedParent
                          : item?.report_type === "WARNING"
                          ? styles.warning
                          : ""
                      }`}
                          key={index}
                          id={`warning-${index}`}
                          style={{
                            backgroundColor:
                              item.msg_id === msgFromInbox?.msgId && msgFromInbox?.msgId !== undefined
                                ? "#e0f5ff"
                                : item.text === msgFromInbox?.msg && msgFromInbox?.msgId === undefined
                                ? item.text === msgFromInbox?.msg &&
                                  msgFromInbox?.eventTime === formatDate(item?.timestamp?.value, "timeShort")
                                  ? "#e0f5ff"
                                  : ""
                                : "",
                          }}
                        >
                          <Grid container spacing={1} columns={24} sx={{ zIndex: "1" }}>
                            <Grid
                              item
                              xs={latestDate === undefined && isLegs > 30 ? 7 : isLegs <= 30 ? 7 : 6.5}
                              className={`${styles.dataItemsText}`}
                            >
                              {item?.priority === "Missing" ? (
                                <NoWarningFaultMsg type={"Warning"} />
                              ) : (
                                <GetPriorityIcon priority={item.priority} correlated={item?.correlated} />
                              )}
                              {displayFlag(item)}
                              {item?.correlated === 1 && prevElement?.correlated === 0 ? (
                                <div className={styles.correlatedLine}></div>
                              ) : item?.correlated === 1 && prevElement?.correlated === 1 ? (
                                <div className={styles.correlatedLineSub}></div>
                              ) : (
                                ""
                              )}
                              <Grid
                                sx={{
                                  marginTop: item.correlated ? "-24px" : "0px",
                                }}
                                className={`${styles.dataItemsText}`}
                              >
                                {/* {displayLabel("", label.label, label.title, item.correlated)} */}
                                <Grid
                                  // className="lantai"
                                  // sx={{marginLeft: "95px"}}
                                  sx={{
                                    position: "relative",
                                  }}
                                ></Grid>
                                <span
                                  style={{
                                    marginLeft: margin,
                                    display: "inline-flex",
                                  }}
                                >
                                  {displayLabel("", label.label, label.title, item.correlated)}
                                  {/* </span> */}
                                  <StyledTooltip
                                    title={item?.text}
                                    placement="bottom"
                                    enterDelay={500}
                                    followCursor={false}
                                    classes={{ tooltip: classes.messageTootltip }}
                                  >
                                    <span
                                    // style={{
                                    //   marginLeft: margin,
                                    //   display: "inline-flex",
                                    // }}
                                    >
                                      {/* {displayLabel(
                                        "",
                                        label.label,
                                        label.title,
                                        item.correlated
                                      )} */}
                                      {item?.priority === "Missing" ? "" : item?.text}
                                    </span>
                                  </StyledTooltip>
                                </span>
                              </Grid>
                            </Grid>
                            <Grid item xs={isLegs <= 30 ? 2 : 1.5} className={`${styles.dataItemsText}`}>
                              {item.ata_chapter === "UNIDENTIFIED"
                                ? isNumeric(item?.ata_chapter_raw) || isCombineNumericLetters(item?.ata_chapter_raw)
                                  ? getAtaChapter(item?.ata_chapter_raw, item.report_type) ?? "-"
                                  : item?.ata_chapter_raw ?? "-"
                                : getAtaChapter(item.ata_chapter, item.report_type)}
                            </Grid>
                            <Grid
                              item
                              xs={latestDate === undefined && isLegs <= 30 ? 6.5 : isLegs <= 30 ? 2 : 1.5}
                              className={`${styles.dataItemsText}`}
                            >
                              <StyledTooltip
                                title={item?.source}
                                placement="bottom"
                                enterDelay={500}
                                followCursor={false}
                                classes={{ tooltip: classes.messageTootltip }}
                              >
                                <span>{item?.source}</span>
                              </StyledTooltip>
                            </Grid>
                            <Grid
                              item
                              xs={isLegs <= 30 ? 2 : 1.5}
                              className={`${styles.dataItemsText}`}
                              sx={{
                                display: latestDate === undefined ? "none" : "",
                              }}
                            >
                              {item?.flight_phase_id}
                              {/* {"-"}
                              {item.correlated} */}
                            </Grid>
                            <Grid
                              item
                              xs={isLegs <= 30 ? 2.5 : 2}
                              className={`${styles.dataItemsText}`}
                              sx={{
                                display: latestDate === undefined ? "none" : "",
                              }}
                            >
                              {item?.priority !== "Missing" ? formatDate(item?.timestamp?.value, "timeShort") : ""}
                            </Grid>
                            {item.rep !== "" ? (
                              <Grid item xs={1} className={`${styles.dataItemsText}`}>
                                {GetRepetitive(item)}
                              </Grid>
                            ) : (
                              <Grid item xs={1}></Grid>
                            )}
                            <Grid
                              item
                              xs={
                                latestDate === undefined && isLegs > 30
                                  ? 13
                                  : latestDate !== undefined && isLegs > 30
                                  ? 10
                                  : latestDate === undefined && isLegs <= 30
                                  ? 7.5
                                  : latestDate !== undefined && isLegs <= 30
                                  ? 7.5
                                  : 6.5
                              }
                              style={{
                                display: "flex",
                                justifyContent: "left",
                                paddingTop: "2px",
                              }}
                              id="warningLegs"
                            >
                              <Grid container columns={isLegs <= 50 ? isLegs : 50}>
                                {isLoadingLegs ? (
                                  <>
                                    <Grid container spacing={0} columns={isLegs}>
                                      {skeletonLoaderForLegs(isLegs)}
                                    </Grid>
                                  </>
                                ) : (
                                  <>
                                    {legsAll &&
                                      legsAll?.slice(startSlice, endSlice)?.map((leg, indexes) => {
                                        let OpenLegDetails = true;
                                        // let match = getMatch(item, indexes);
                                        let match = getMatch(item, leg, indexes, isLegs);
                                        if (match === 0) OpenLegDetails = false;
                                        let indexes_ = indexes;
                                        if (isLegs === "100") indexes_ = indexes + 50;
                                        else if (isLegs === "150") indexes_ = indexes + 100;
                                        else if (isLegs === "200") indexes_ = indexes + 150;
                                        return (
                                          <>
                                            <Grid item xs={1} sx={{ padding: "0 2px" }}>
                                              {/* <StyledTooltip
                                            title={
                                              match === 1
                                                ? getLegsOverview(leg, item.ata_chapter, item.text)
                                                : getLegsOverviewUnavailable(previouslegs, indexes)
                                            }
                                            placement="right-start"
                                            enterDelay={500}
                                            key={index}
                                            followCursor={false}
                                            classes={{ tooltip: classes.cardViewTooltip }}
                                          > */}
                                              <div
                                                className={`${
                                                  // match === 1
                                                  //   ? styles.prevLegs
                                                  //   : match === 2
                                                  //   ? styles.prevLegsMissing
                                                  //   : match === 3
                                                  //   ? styles.prevLegsUnidentifiedChapter
                                                  //   : styles.prevLegsUnavailable
                                                  cssClassName(
                                                    match,
                                                    index,
                                                    indexes,
                                                    selectedLeg,
                                                    isWarningReportsFiltered.length,
                                                    leg
                                                  )
                                                } ${handleActiveLeg(index, indexes, "warning", item.ata_chapter)}`}
                                                key={indexes}
                                                onClick={() => {
                                                  localStorage.removeItem("inboxMsg");
                                                  setMsgIndex("");
                                                  setMsgIndexes("");
                                                  setCheckSpec(false);
                                                  handleSetOpenLegDetails(
                                                    index,
                                                    indexes_,
                                                    "warning",
                                                    OpenLegDetails,
                                                    leg,
                                                    item.text
                                                  );
                                                  setClickLegs(isLegs);
                                                }}
                                                style={{
                                                  boxShadow: indexes === msgIndexes ? "0px 10px #e0f5ff" : "",
                                                }}
                                                // onMouseEnter={() => handleOnMouseEnter(leg[indexes])}
                                              >
                                                {/* {match} */}
                                                {/* {isLegs !== "50" ? GetPreviousLegsReportType(indexes, "warning") : ""} */}
                                              </div>
                                              {/* </StyledTooltip> */}
                                            </Grid>
                                          </>
                                        );
                                      })}
                                  </>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        </div>
                        <DisplayLegsDetails
                          legId={index}
                          open={open}
                          type="warning"
                          text={item?.text}
                          ata_chapter={item?.ata_chapter}
                          failure_messages={isWarningReportsFiltered}
                          parentText={parentText}
                          parentCode={parentCode}
                          source={item?.source}
                          time={item?.timestamp?.value}
                          telexGen={item?.telex_gen?.value}
                        />
                      </>
                    ) : latestFlight !== undefined && latestDate !== undefined && item.flight_report_type !== "PFR" ? (
                      ""
                    ) : (
                      ""
                    );
                  })
              : ""}
            {isWarningReportsFiltered.filter(
              (e) =>
                e.flight_report_type === "CFR" &&
                e.priority !== "Missing" &&
                e.text !== "" &&
                e.report_type === "WARNING"
            ).length === 0 && latestFlight !== undefined
              ? NoWarningFaultsMsg("warning", 0)
              : chkNoWarningFaultMsg("warning")
              ? NoWarningFaultsMsg("warning", 0)
              : ""}
          </>
        )}
        <div
          className={`row ${styles.displayDataBody}`}
          style={{
            marginBottom: "-4px",
            marginTop: "-4px",
            minHeight: "70px",
            zIndex: "1",
          }}
        >
          <Grid container spacing={1} columns={24}>
            <Grid
              item
              xs={
                latestDate === undefined && isLegs > 30
                  ? 11
                  : latestDate !== undefined && isLegs > 30
                  ? 14
                  : latestDate === undefined && isLegs <= 30
                  ? 16.5
                  : latestDate !== undefined && isLegs <= 30
                  ? 16.5
                  : 17.5
              }
              sx={{ paddingLeft: "20px" }}
            >
              <div
                style={{
                  fontFamily: "Gotham-Medium",
                  padding: "24px 0 6px 14px",
                }}
              >
                Faults
              </div>
            </Grid>
            <Grid
              item
              xs={
                latestDate === undefined && isLegs > 30
                  ? 13
                  : latestDate !== undefined && isLegs > 30
                  ? 10
                  : latestDate === undefined && isLegs <= 30
                  ? 7.5
                  : latestDate !== undefined && isLegs <= 30
                  ? 7.5
                  : 6.5
              }
            >
              <Grid container columns={isLegs <= 50 ? isLegs : 50}>
                {legs &&
                  legs?.slice(startSlice, endSlice)?.map((leg, indexes) => {
                    if (isLegs > 50) indexes = parseInt(indexes) + parseInt(isLegs) - 50;
                    return (
                      <Grid item xs={1} sx={{ paddingLeft: "5.5px" }}>
                        <div
                          style={{
                            minHeight: "70px",
                            zIndex: "1",
                            marginRight: "-1.5px",
                            fontSize: "6px",
                            backgroundColor: indexes === msgIndexes ? "#e0f5ff" : "",
                          }}
                          className={cssActiveLeg(selectedLeg, indexes)}
                        ></div>
                      </Grid>
                    );
                  })}
              </Grid>
            </Grid>
          </Grid>
        </div>
        {isLoadingLegs ? (
          <>
            <Grid container>{skeletonLoader(4)}</Grid>
          </>
        ) : (
          <>
            {faultReportsFiltered && faultReportsFiltered.length > 0
              ? faultReportsFiltered.map((item, index) => {
                  return item.flight_report_type !== "PFR" ? (
                    <>
                      <div
                        className={`row ${styles.dataItems} ${
                          faultLeg === index && msgType === "faults" ? styles.dataItemsActiveNoCorrelated : ""
                        }`}
                        key={index}
                        id={`faults-${index}`}
                        style={{
                          backgroundColor:
                            item.msg_id === msgFromInbox?.msgId && msgFromInbox?.msgId !== undefined
                              ? "#e0f5ff"
                              : item.text === msgFromInbox?.msg && msgFromInbox?.msgId === undefined
                              ? item.text === msgFromInbox?.msg &&
                                msgFromInbox?.eventTime === formatDate(item?.timestamp?.value, "timeShort")
                                ? "#e0f5ff"
                                : ""
                              : "",
                        }}
                      >
                        <Grid container spacing={1} columns={24}>
                          <Grid
                            item
                            xs={latestDate === undefined && isLegs > 30 ? 7 : isLegs <= 30 ? 7 : 6.5}
                            className={`${styles.dataItemsText}`}
                          >
                            <GetPriorityIcon priority={item.priority} />
                            {displayFlag(item)}
                            <StyledTooltip
                              title={item?.text}
                              placement="bottom"
                              enterDelay={500}
                              followCursor={false}
                              classes={{ tooltip: classes.messageTootltip }}
                            >
                              <span style={{ marginLeft: "120px" }}>{item?.text}</span>
                            </StyledTooltip>
                          </Grid>
                          <Grid item xs={isLegs <= 30 ? 2 : 1.5} className={`${styles.dataItemsText}`}>
                            {item.ata_chapter === "UNIDENTIFIED"
                              ? isNumeric(item?.ata_chapter_raw) || isCombineNumericLetters(item?.ata_chapter_raw)
                                ? getAtaChapter(item?.ata_chapter_raw, item.report_type) ?? "-"
                                : item?.ata_chapter_raw ?? "-"
                              : getAtaChapter(item.ata_chapter, item.report_type) ?? "-"}
                          </Grid>
                          <Grid
                            item
                            xs={latestDate === undefined && isLegs <= 30 ? 6.5 : isLegs <= 30 ? 2 : 1.5}
                            className={`${styles.dataItemsText}`}
                          >
                            <StyledTooltip
                              title={item?.source}
                              placement="bottom"
                              enterDelay={500}
                              followCursor={false}
                              classes={{ tooltip: classes.messageTootltip }}
                            >
                              <span>{item?.source}</span>
                            </StyledTooltip>
                          </Grid>
                          <Grid
                            item
                            xs={isLegs <= 30 ? 2 : 1.5}
                            className={`${styles.dataItemsText}`}
                            sx={{
                              display: latestDate === undefined ? "none" : "",
                            }}
                          >
                            {item?.flight_phase_id}
                          </Grid>
                          <Grid
                            item
                            xs={isLegs <= 30 ? 2.5 : 2}
                            className={`${styles.dataItemsText}`}
                            sx={{
                              display: latestDate === undefined ? "none" : "",
                            }}
                          >
                            {ConvertUTCDatetime(changeFormatDate(item?.timestamp?.value), localTime).split(" ")[1]}
                          </Grid>
                          {item.rep !== "" ? (
                            <Grid item xs={1} className={`${styles.dataItemsText}`}>
                              {GetRepetitive(item)}
                            </Grid>
                          ) : (
                            <Grid item xs={isLegs <= 30 ? 1 : 0.5}></Grid>
                          )}
                          <Grid
                            item
                            xs={
                              latestDate === undefined && isLegs > 30
                                ? 13
                                : latestDate !== undefined && isLegs > 30
                                ? 10
                                : latestDate === undefined && isLegs <= 30
                                ? 7.5
                                : latestDate !== undefined && isLegs <= 30
                                ? 7.5
                                : 6.5
                            }
                            style={{
                              display: "flex",
                              justifyContent: "left",
                              paddingTop: "2px",
                            }}
                            id="faultLegs"
                          >
                            <Grid container columns={isLegs <= 50 ? isLegs : 50}>
                              {isLoadingLegs ? (
                                <>
                                  <Grid container spacing={0} columns={isLegs}>
                                    {skeletonLoaderForLegs(isLegs)}
                                  </Grid>
                                </>
                              ) : (
                                <>
                                  {legsAll
                                    ? legsAll?.slice(startSlice, endSlice)?.map((leg, indexes) => {
                                        let OpenLegDetails = true;
                                        // let match = getMatch(item, indexes);
                                        let match = getMatch(item, leg, indexes, isLegs);
                                        if (match !== 1 && match !== 3) OpenLegDetails = false;
                                        let indexes_ = indexes;
                                        if (isLegs === "100") indexes_ = indexes + 50;
                                        else if (isLegs === "150") indexes_ = indexes + 100;
                                        else if (isLegs === "200") indexes_ = indexes + 150;
                                        return (
                                          <>
                                            <Grid item xs={1} sx={{ padding: "0 2px" }}>
                                              {/* <StyledTooltip
                                                title={
                                                  match === 1
                                                    ? getLegsOverview(leg, item.ata_chapter, item.text)
                                                    : getLegsOverviewUnavailable(previouslegs, indexes)
                                                }
                                                placement="right-start"
                                                enterDelay={500}
                                                key={index}
                                                followCursor={false}
                                                classes={{ tooltip: classes.cardViewTooltip }}
                                              > */}
                                              <div
                                                className={`${
                                                  // match === 1
                                                  //   ? styles.prevLegs
                                                  //   : match === 2
                                                  //   ? styles.prevLegsMissing
                                                  //   : match === 3
                                                  //   ? styles.prevLegsUnidentifiedChapter
                                                  //   : styles.prevLegsUnavailable
                                                  cssClassName(
                                                    match,
                                                    index,
                                                    indexes,
                                                    selectedLeg,
                                                    faultReportsFiltered.length
                                                  )
                                                } ${handleActiveLeg(index, indexes, "faults", item.ata_chapter)}`}
                                                key={indexes}
                                                onClick={() => {
                                                  localStorage.removeItem("inboxMsg");
                                                  setMsgIndex("");
                                                  setMsgIndexes("");
                                                  setCheckSpec(false);
                                                  handleSetOpenLegDetails(
                                                    index,
                                                    indexes_,
                                                    "faults",
                                                    OpenLegDetails,
                                                    leg,
                                                    item.text
                                                  );
                                                  setClickLegs(isLegs);
                                                }}
                                                style={{
                                                  boxShadow:
                                                    indexes === msgIndexes
                                                      ? faultReportsFiltered.length === index + 1
                                                        ? "0px 0px #e0f5ff"
                                                        : "0px 10px #e0f5ff"
                                                      : "",
                                                }}
                                                // onMouseEnter={() => handleOnMouseEnter(leg[indexes])}
                                              >
                                                {/* {match} */}
                                                {/* {isLegs !== "50" ? GetPreviousLegsReportType(indexes, "faults") : ""} */}
                                              </div>
                                              {/* </StyledTooltip> */}
                                            </Grid>
                                          </>
                                        );
                                      })
                                    : "No messages available for current selection."}
                                </>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                      <DisplayLegsDetails
                        legId={index}
                        open={open}
                        type="faults"
                        text={item?.text}
                        ata_chapter={item.ata_chapter}
                        failure_messages={faultReportsFiltered}
                        source={item?.source}
                        time={item?.timestamp?.value}
                        telexGen={item?.telex_gen?.value}
                      />
                    </>
                  ) : latestFlight !== undefined && latestDate !== undefined && item.flight_report_type !== "PFR" ? (
                    ""
                  ) : (
                    ""
                  );
                })
              : ""}
            {faultReportsFiltered.filter(
              (e) =>
                e.flight_report_type === "CFR" && e.priority !== "Missing" && e.text !== "" && e.report_type === "FAULT"
            ).length === 0 && latestFlight !== undefined
              ? NoWarningFaultsMsg("fault", 0)
              : chkNoWarningFaultMsg("fault")
              ? NoWarningFaultsMsg("fault", 0)
              : ""}
          </>
        )}
      </>
    );
  };

  const DisplayDataHeaderAcms = () => {
    return (
      <>
        <div className={`row ${styles.displayHeader}`} style={{ marginBottom: "0" }}>
          <Grid container spacing={0} columns={9} className={`${styles.displayHeaderLabel}`}>
            {/* <Grid item xs={0.4} className={styles.nowrap}>
              Prio.
            </Grid> */}
            <Grid item xs={1} className={styles.nowrap}>
              A/C Reg.
            </Grid>
            <Grid item xs={1} className={styles.nowrap}>
              Report No.
            </Grid>
            <Grid item xs={2} className={styles.nowrap}>
              Report Title
            </Grid>
            <Grid item xs={1} className={styles.nowrap}>
              Trigger Code
            </Grid>
            <Grid item xs={1} className={styles.nowrap}>
              Flight No.
            </Grid>
            <Grid item xs={1.5} className={styles.nowrap}>
              Sent Time
            </Grid>
            <Grid item xs={1.5} className={styles.nowrap}>
              Generation Time
            </Grid>
          </Grid>
        </div>
      </>
    );
  };

  const NoDataAvailable = () => {
    const [show, setShow] = useState(false);
    useEffect(() => {
      const timeoutId = setTimeout(() => {
        setShow(true);
      }, 1500);

      return () => clearTimeout(timeoutId);
    }, []);
    return show ? (
      <>
        <div style={{ marginTop: "5%", marginBottom: "10%", textAlign: "center" }}>
          <div style={{ marginBottom: "16px" }}>
            <img src={DataUnavailable} alt="data-unavailable" />
          </div>
          <div style={{ fontFamily: "Gotham-Medium", fontSize: "14px" }}>Data Unavailable</div>
          <div style={{ fontFamily: "Gotham-Book", fontSize: "12px" }}>
            There is no data found. Please try again later.
          </div>
        </div>
      </>
    ) : (
      <Grid container>{skeletonLoader(4)}</Grid>
    );
  };

  function Loader(data) {
    return data?.length === 0 && useCurrentTime === false ? (
      <>
        <div style={{ minHeight: "460px", marginTop: "100px" }}>
          <Grid container columns={1}>
            <Grid item xs={1} sx={{ display: "flex", justifyContent: "center" }}>
              <Loading style={{ width: "200px" }} />
            </Grid>
            <Grid
              item
              xs={1}
              sx={{
                display: "flex",
                justifyContent: "center",
                fontSize: "18px",
                fontFamily: "Gotham-Medium",
              }}
            >
              Loading...
            </Grid>
          </Grid>
        </div>
      </>
    ) : data?.length <= 0 && useCurrentTime === true ? (
      ""
    ) : (
      ""
    );
  }

  const MessageDetails = ({ open, id, item }) => {
    const StyledHTML = ({ children }) => (
      <div
        style={{
          // display: "flex",
          // justifyContent: "center",
          alignItems: "center",
          // border: "1px solid red",
          maxHeight: "320px",
          overflow: "auto",
        }}
        dangerouslySetInnerHTML={{ __html: children.replace(/\n/g, "<br />") }}
      />
    );

    return open === id ? (
      <>
        <div className={`${styles.displayMsgDetails}`}>
          <Grid container columns={12}>
            <Grid item xs={8} sx={{ borderBottom: "1px solid #D1D1D1" }}>
              <div className={`${styles.msgHeaderTab} ${tab === 1 ? styles.active : ""}`} onClick={() => setTab(1)}>
                Report Details
              </div>
              <div className={`${styles.msgHeaderTab} ${tab === 2 ? styles.active : ""}`} onClick={() => setTab(2)}>
                Raw Data
              </div>
            </Grid>
            {/* <Grid item xs={1}>
              Raw
            </Grid> */}
            <Grid item xs={3} sx={{ borderBottom: "1px solid #D1D1D1" }}></Grid>
            <Grid
              item
              xs={1}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                borderBottom: "1px solid #D1D1D1",
              }}
            >
              <img
                src={CloseIcon}
                height="20px"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setOpenAcms(false);
                }}
                alt="close"
              />
            </Grid>
            <Grid item xs={12} sx={{ padding: "30px 20px" }}>
              {tab === 1 ? (
                <center>
                  <StyledHTML>{item.raw_msg}</StyledHTML>
                </center>
              ) : (
                <div style={{ maxHeight: "320px", overflow: "auto" }}>{item.raw_msg}</div>
              )}
            </Grid>
          </Grid>
        </div>
      </>
    ) : (
      ""
    );
  };

  const DisplayDataAcms = () => {
    return isAcmsLoading ? (
      <Grid container>{skeletonLoader(4)}</Grid>
    ) : isInbox?.length > 0 ? (
      isInbox?.map((item, index) => {
        return (
          <>
            <div
              className={`row ${styles.dataItems} ${styles.dataItemsHover}`}
              onClick={() => {
                setOpenAcms(index);
              }}
              id={index}
              style={{ paddingLeft: "17px", paddingRight: "17px" }}
            >
              <Grid container spacing={0} columns={9}>
                {/* <Grid item xs={0.4}>
                  {getACMS(item.acms_priority)}
                </Grid> */}
                <Grid item xs={1} className={`${styles.dataItemsText}`}>
                  {item.AC_REG}
                </Grid>
                <Grid item xs={1} className={`${styles.dataItemsText}`}>
                  {item?.text?.split(" - ")[0] || item.text}
                </Grid>
                <Grid item xs={2} className={`${styles.dataItemsText}`}>
                  {item?.text?.split(" - ")[1] || item.report_type}
                </Grid>
                <Grid item xs={1} className={`${styles.dataItemsText}`}>
                  {item.code}
                </Grid>
                <Grid item xs={1} className={`${styles.dataItemsText}`}>
                  {item.flight_no}
                </Grid>
                <Grid item xs={1.5} className={`${styles.dataItemsText}`}>
                  {ConvertUTCDatetime(changeFormatDate(item?.telex_sent?.value), localTime)}
                </Grid>
                <Grid item xs={1.5} className={`${styles.dataItemsText}`}>
                  {ConvertUTCDatetime(changeFormatDate(item?.telex_gen?.value), localTime)}
                </Grid>
              </Grid>
            </div>
            <MessageDetails id={index} open={openAcms} item={item} />
          </>
        );
      })
    ) : (
      <NoDataAvailable />
    );
  };

  function cssActiveLeg(selectedLeg, indexes) {
    if (
      (selectedLeg === indexes ||
        selectedLeg + 50 === indexes ||
        selectedLeg + 100 === indexes ||
        selectedLeg + 150 === indexes) &&
      isLegs === clickLegs
    ) {
      return styles.activeLeg;
    } else {
      return "";
    }
  }

  return (
    <>
      <div
        style={{ overflow: "hidden", minHeight: "940px" }}
        id="main"
        className={`${styles.main} ${styles.mainBodyData}`}
      >
        {!isLoadingLegs && <ReportsTab />}
        {Loader(legs)}
        {isAcarsTab && (
          <div id="data">
            {!isLoading && !isLoadingLegs && legs?.length > 0 && (
              <>
                {isAcarsTab && (
                  <SearchBarAircraftStatus
                    listFaultPriority={props.listFaultPriority}
                    listRepetitive={props.listRepetitive}
                    isSearchInput={isSearchInput}
                    setIsSearchInput={props.setIsSearchInput}
                    isFaultPriority={isFaultPriority}
                    setIsFaultPriority={props.setIsFaultPriority}
                    isRepetitive={isRepetitive}
                    setIsRepetitive={props.setIsRepetitive}
                    isLegs={isLegs}
                    setIsLegs={setIsLegs}
                    froms={"aircraftStatus"}
                  />
                )}
                {(isFaultPriority.length > 0 || isRepetitive.length > 0 || isSearchInput.length > 0) && (
                  <div style={{ paddingRight: "0px" }}>
                    <ActiveFilters
                      isSearchInput={isSearchInput}
                      setIsSearchInput={props.setIsSearchInput}
                      isFaultPriority={isFaultPriority}
                      setIsFaultPriority={props.setIsFaultPriority}
                      isRepetitive={isRepetitive}
                      setIsRepetitive={props.setIsRepetitive}
                      filter={props.filter}
                    />
                  </div>
                )}
                <DisplayDataHeader />
                <DisplayDataBody />
                {missingData && latestFlight !== undefined && <DataMissing />}
              </>
            )}
          </div>
        )}
        {isAcmsTab && (
          <>
            {isInbox?.length !== 0 && <DisplayDataHeaderAcms />}
            {isAcmsLoading && isAcmsTab && <Grid container>{skeletonLoader(perPage)}</Grid>}
            {!isAcmsLoading && <DisplayDataAcms />}
            {isInbox?.length !== 0 ? (
              <MUIPagination
                page={page}
                setPage={setPage}
                setPerPage={setPerPage}
                totalPage={totalPages}
                classes={{ ul: classes.ul }}
                totalRows={totalRows}
                sliceStart={sliceStart}
              />
            ) : (
              ""
            )}
          </>
        )}
        <ChangePriorityStatus
          open={openPriorityStatus}
          setOpen={setOpenPriorityStatus}
          eventMessage={eventMessage}
          ataChapter={ataChapter}
          priority={priority}
          data={legData}
        />
        <ChangeLabel
          message={eventMessage}
          ataChapter={ataChapter}
          openLabel={openLabel}
          setOpenLabel={setOpenLabel}
          acType={acType}
          label={label}
        />
        <Grid
          id="headers"
          style={{ maxWidth: "500px", fontSize: "3px" }}
          alignItems="center"
          alignContent="center"
          hidden
        >
          <FaultJournalSummary
            aircraft={aircraft ?? ""}
            flightNo={flightNo ?? ""}
            departureTime={departureTime ?? ""}
            arrivalTime={arrivalTime ?? ""}
            route={route ?? ""}
            filters={filters ?? ""}
          />
        </Grid>
        <Modal
          open={openFlagDefect}
          onClose={() => {
            setOpenFlagDefect(!openFlagDefect);
          }}
          BackdropProps={{
            style: {
              boxShadow: "none",
            },
          }}
        >
          <Fade in={openFlagDefect}>
            <Box sx={style.box}>
              <div className="gotham-medium medium-font" style={{ marginBottom: "16px" }}>
                Repetitive Defect Database
              </div>
              <Grid container columns={2}>
                <Grid item xs={2} sx={{ borderBottom: "1px solid #D1D1D1" }}></Grid>
                <Grid
                  className="medium-font"
                  item
                  xs={2}
                  sx={{
                    fontFamily: "Gotham-Book",
                    marginTop: "16px",
                    marginBottom: "32px",
                  }}
                >
                  <div>
                    <span>This action will mark </span>
                    <span className="gotham-medium">{defectModalData.text} </span>
                    <span>as a repetitive defect. </span>
                  </div>
                  <br />
                  <div>
                    <span>This information will be stored in the </span>
                    <span className="gotham-medium">Repetitive Defect Database</span>
                  </div>
                  <br />
                  <div>
                    <span>Are you sure you want to proceed?</span>
                  </div>
                </Grid>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  <AdeButton
                    variant="outlined"
                    fullWidth={false}
                    style={{ marginRight: "16px" }}
                    onClick={() => {
                      setDefectModalData([]);
                      setMsgId();
                      setOpenFlagDefect(!defectModalData);
                    }}
                  >
                    Cancel
                  </AdeButton>
                  <AdeButton
                    variant="contained"
                    fullWidth={false}
                    onClick={() => {
                      handleClickRepetitiveDefect();
                      setOpenFlagDefect(!defectModalData);
                    }}
                  >
                    Proceed
                  </AdeButton>
                </div>
              </Grid>
            </Box>
          </Fade>
        </Modal>
      </div>
      <ReportPreview
        data={pfrReportData}
        openModal={openPfrReportPreview}
        setOpenModal={setOpenPfrReportPreview}
        reportType={"PFR"}
        localTime={localTime}
      />
    </>
  );
};

export default FlightReport;
