
export const a320CeoBoxConfigs = [
	{
		title: 'Flight Deck',
		code: 'FD',
		boxSettings: {
			// itemsInARowCap: 5,
			// rowCountThreshold : undefined,
			boxX: 10,
			boxY: 80,
			popupXOffset: -217,
			popupYOffset: -410,
			minWidth: 140,
			pointerSettings: {
				// pathTranslate : {x: 0,y: 0,},
				pointerPath: 'M145.5 346H32C28.134 346 25 342.866 25 339V104.5',
				pointerPosX: 145,
				pointerPosY: 346,
			},
		}
	},
	{
		title: 'FWD Galley',
		code: 'FWD',
		boxSettings: {
			// itemsInARowCap: undefined,
			// rowCountThreshold : 5, // this cause border missing if no item
			boxX: 290,
			boxY: 80,
			popupXOffset: -217,
			popupYOffset: -410,
			minWidth: 140,
			pointerSettings: {
				// pathTranslate : {x: 0,y: 0,},
				pointerPath: "M 213.5 286.5 V 213.5 C 213.5 200 220 200 235 200 H 290 C 294 200 297 197 297 193 V 90",
				pointerPosX: 214,
				pointerPosY: 287,
			},
		}
	},
	{
		title: '1L ATT. STN.',
		code: '1L',
		boxSettings: {
			// itemsInARowCap: 5,
			boxX: 110,
			boxY: 520,
			popupXOffset: -217,
			popupYOffset: -500,
			minWidth: 140,
			pointerSettings: {
				// pathTranslate : {x: 0,y: 0,},
				pointerPath: 'M231 427V493C231 496.866 227.866 500 224 500H178',
				pointerPosX: 231,
				pointerPosY: 426,
			},
		}
	},

	{
		title: 'AFT Galley',
		code: 'AFT',
		boxSettings: {
			// itemsInARowCap: 5,
			boxX: 1140,
			boxY: 80,
			popupXOffset: -503,
			popupYOffset: -410,
			minWidth: 150,
			pointerSettings: {
				// pathTranslate : {x: 0,y: 0,},
				pointerPath: 'M 1260.5 346.5 V 88 C 1260.5 84 1257 81 1254 81 H 1247',
				pointerPosX: 1260,
				pointerPosY: 347,
			},
		}
	},
	{
		title: 'OHB Row 01ABC',
		code: 'OHB-ABC',
		boxSettings: {
			// itemsInARowCap: 5,
			boxX: 305,
			boxY: 520,
			popupXOffset: -217,
			popupYOffset: -500,
			minWidth: 150,
			pointerSettings: {
				// pathTranslate : {x: 0,y: 0,},
				pointerPath: 'M277 397V493C277 496.866 280.134 500 284 500H322',
				pointerPosX: 277,
				pointerPosY: 396,
			},
		}
	},
	// {
	// 	title: 'OHB Row 01DEF',
	// 	code: 'OHB-DEF',
	// 	boxSettings: {
	// 		// itemsInARowCap: 5,
	// 		boxX: 520,
	// 		boxY: 80,
	// 		popupXOffset: -217,
	// 		popupYOffset: -410,
	// 		minWidth: 140,
	// 		pointerSettings: {
	// 			// pathTranslate : {x: 0,y: 0,},
	// 			pointerPath: 'M277 295V245.827C277 241.961 280.134 238.827 284 238.827H565 C568.866 238.827 572 235.693 572 231.827V103',
	// 			pointerPosX: 277,
	// 			pointerPosY: 295,
	// 		},
	// 	}
	// },
	{
		title: 'Behind Last Row 31ABC',
		code: 'BLR-ABC',
		boxSettings: {
			// itemsInARowCap: 5,
			boxX: 690,
			boxY: 510,
			popupXOffset: -503,
			popupYOffset: -500,
			minWidth: 170,
			pointerSettings: {
				// pathTranslate : {x: 0,y: 0,},
				pointerPath: 'M1172 397V442C1172 445.866 1168.87 449 1165 449H811C807.134 449 804 452.134 804 456V485.5',
				pointerPosX: 1172,
				pointerPosY: 396,
			},
		}
	},
	{
		title: 'Behind Last row 31DEF',
		code: 'BLR-DEF',
		boxSettings: {
			// itemsInARowCap: 5,
			boxX: 750,
			boxY: 120,
			popupXOffset: -217,
			popupYOffset: -410,
			minWidth: 160,
			pointerSettings: {
				// pathTranslate : {x: 0,y: 0,},
				pointerPath: 'M1174.5 294.5V243.5C1174.5 239.634 1171.37 236.5 1167.5 236.5H812C808.134 236.5 805 233.366 805 229.5V138.5',
				pointerPosX: 1175,
				pointerPosY: 295,
			},
		}
	},
	{
		title: '2R ATT. Swivel Seat',
		code: '2R',
		boxSettings: {
			// itemsInARowCap: 5,
			boxX: 950,
			boxY: 100,
			popupXOffset: -503,
			popupYOffset: -410,
			minWidth: 140,
			pointerSettings: {
				// pathTranslate : {x: 0,y: 0,},
				pointerPath: 'M1222 310V225.626C1222 221.76 1218.87 218.626 1215 218.626H1027C1023.13 218.626 1020 215.492 1020 211.626V120',
				pointerPosX: 1222,
				pointerPosY: 309,
			},
		}
	},
	{
		title: '2L ATT. STN.',
		code: '2L',
		boxSettings: {
			// itemsInARowCap: 5,
			boxX: 910,
			boxY: 510,
			popupXOffset: -503,
			popupYOffset: -500,
			minWidth: 150,
			pointerSettings: {
				// pathTranslate : {x: 0,y: 0,},
				pointerPath: 'M1201 421V451.327C1201 455.193 1197.87 458.327 1194 458.327H962C958.13 458.327 955 461.461 955 465.327V478',
				pointerPosX: 1201,
				pointerPosY: 420,
			},
		}
	},
	{
		title: 'Behind 2L ATT. STN.',
		code: 'B2L',
		boxSettings: {
			// itemsInARowCap: 5,
			itemsInARowCap: 5,
			boxX: 1120,
			boxY: 510,
			popupXOffset: -503,
			popupYOffset: -500,
			minWidth: 140,
			pointerSettings: {
				// pathTranslate : {x: 0,y: 0,},
				pointerPath: 'M 1223 420 V 477 C 1223 481 1220 484 1216 484 H 1196',
				pointerPosX: 1223,
				pointerPosY: 419,
			},
		}
	},
]