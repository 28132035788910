import { seatsA } from "./seatsA";
import { seatsB } from "./seatsB";
import { seatsC } from "./seatsC";
import { seatsD } from "./seatsD";
import { seatsE } from "./seatsE";
import { seatsF } from "./seatsF";

/**
 * seat position will be based on model and the strcture will be like this :
 * {
 * 	x : number,
 * 	y : number,
 * 	col : number,
 * 	row : character,
 * 	rotation : number, 360degree
 * } 
 */
const seats = [
    ...seatsA,
    ...seatsB,
    ...seatsC,
    ...seatsD,
    ...seatsE,
    ...seatsF,
];
export default seats;

