
import React from 'react';
import {
	Typography,
	Box,
	TextField,
	Divider,
	Grid,
	Avatar,
} from "@mui/material";
import { Fragment, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import ItemTemplate from "./ItemTemplate";
import HistoryTemplate from "./HistoryTemplate";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import { useGetAllInventoryHistory, useGetAllInventoryHistoryAdv } from "../../../models/custom/inventory-history-model";

export default function InventoryHistoryTab({ node = {} }) {
	const [search, setSearch] = useState(undefined);
	const itemTitleStyle = {
		textAlign: "left",
		fontSize: "14px",
		color: "#838383",
		paddingRight: "1em",
	};
	const history = useGetAllInventoryHistoryAdv(
	// 	{
	// 	search,
	// 	inventoryId: node?.id,
	// }
); // useGetAllInventoryHistory({id: node?.id,search,});
	// const datas: InventoriesHistorySet = history.data

	if (history?.data?.items?.length < 1) {
		return (
			<Box
				sx={{
					width: "94%",
					marginLeft: "1em",
					marginRight: "1em",
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
				}}
			>
				<Typography fontSize={'14px'} fontStyle='italic' color='GrayText' >No record(s) found</Typography>
			</Box>
		)
	}

	return (
		<Fragment>
			<Box
				sx={{
					width: "94%",
					marginLeft: "1em",
					marginRight: "1em",
					display: "flex",
					flexDirection: "row",
					justifyContent: "flex-end",
					alignItems: "center",
				}}
			>
				<Typography sx={{ ...itemTitleStyle }}>Newest First</Typography>
				<VerticalAlignBottomIcon />
			</Box>
			<Box sx={{ width: "94%", marginLeft: "1em", marginRight: "1em" }}>
				<TextField
					sx={{
						"& input::placeholder": {
							fontSize: "12px",
						},
					}}
					fullWidth
					variant="standard"
					placeholder="Search"
					value={search} // Bind the search state as the value
					onChange={(event) => setSearch(event.target.value)} // Update the search state on change
					InputProps={{
						disableUnderline: true,
						startAdornment: (
							<SearchIcon
								sx={{
									fontSize: 50,
									cursor: "pointer",
									padding: "0.3em",
									"&:hover": {
										background: "whitesmoke",
										borderRadius: "1.5em",
									},
								}}
							/>
						),
					}}
				/>
				<Divider />
			</Box>
			<Box sx={{ width: "100%" }}>
				{history?.data?.items?.map((item) => {
					return <NodeBox historyDetailsSet={item} />;
				})}
				{/* {inFleetLocation?.inventory?.id} */}
			</Box>
		</Fragment>
	);
}
function NodeBox({ historyDetailsSet }) {
	return (
		<Fragment>
			<Divider />
			<Grid container sx={{ marginTop: "2em", marginBottom: "1em" }}>
				<Grid item xs={12} md={6}>
					<ItemTemplate
						icon={null}
						title={"Date/Time"}
						value={
							formatDatetime(historyDetailsSet?.createdAt) ?? ""
						}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<ItemTemplate
						icon={
							historyDetailsSet?.avatarCreated ? (
								<Avatar
									src={historyDetailsSet?.avatarCreated}
								/>
							) : (
								<Avatar />
							)
						}
						title={"Updated By"}
						value={historyDetailsSet.createdBy ?? ""}
					/>
				</Grid>
				<Grid item sx={{ marginTop: "1em", marginBottom: "1em" }}>
					<ItemTemplate
						icon={null}
						title={"Description"}
						value={historyDetailsSet?.descriptions?.map(
							(descStr) => {
								return (
									<HistoryTemplate
										icon={null}
										value={descStr}
									/>
								);
							}
						)}
					/>
				</Grid>
			</Grid>
		</Fragment>
	);
}
function formatDatetime(datetimeString) {
	const datetime = new Date(datetimeString);
	const month = datetime.toLocaleString("en-US", { month: "long" });
	const day = datetime.getDate();
	const year = datetime.getFullYear();
	const hour = datetime.getHours();
	const minute = datetime.getMinutes();
	return `${month} ${day}, ${year} ${hour}:${minute}`;
}
