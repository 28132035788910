/* eslint-disable no-unused-expressions */
import React from "react";
import { Box, Button, Divider, Grid, Tooltip, Typography } from "@mui/material";
import { Fragment, useState } from "react";
import StyledTextField from "../../../components/inputs/text";
import StyledDropDown from "../../../components/inputs/dropdowns/StyledDropDown";
import LoadingInput from "../../../components/inputs/LoadingInput";
import StyledDateInput from "../../../components/inputs/dates/StyledDateInput";
import { getMonth2D, getDate2D } from "../../../utils/dates";
import { useNotification } from "../../../components/notifications/UniversalNotification";
import { useDialog } from "../../../components/dialog-box/DialogInterface";
import useAddEqStates from "./useAddEqStates";
import { validateInputStates } from "../../../components/inputs/useInputState";
import { useAppSelector } from "../../../../app/hooks";
import { useCreateInventory } from "../../../../models/custom/inventory-model";
import { replaceLocation } from "../../../../models/custom/inventory-model";
import { useGetAllInventoryTypes } from "pages/elevade/cabinMonitoring/js/models/custom/inventory-item-model";
import { useGetAllLocationTypes } from "pages/elevade/cabinMonitoring/js/models/custom/location-inventory-model";
import { useGetAllPassangerSeats } from "pages/elevade/cabinMonitoring/js/models/custom/passanger-seat-model";
import {
  useGetAllFleetByCompanyCodeSearchedByModel,
  useGetAllFleetDistinctedByModel,
} from "pages/elevade/cabinMonitoring/js/models/custom/fleet-model";
import { useGetAllItemStatuses } from "pages/elevade/cabinMonitoring/js/models/custom/ac-item-model";

export default function AddEquipmentMain({ handleClose, refetch }) {
  const { userProfile } = useAppSelector((state) => state.userEntity);
  const fullname = userProfile?.fullName;
  const photo = userProfile?.photo;

  const [searchSeat, setSearchSeat] = useState();
  const openNotification = useNotification();
  const openDialog = useDialog();

  // inputs
  const addEqStates = useAddEqStates();

  // datas
  const allInventoryTypes = useGetAllInventoryTypes();
  const allLocationTypes = useGetAllLocationTypes();
  const allFleetsFilteredByModel = useGetAllFleetByCompanyCodeSearchedByModel({
    inputModel: addEqStates?.inputModel?.value,
  });
  const allPassangerSeats = useGetAllPassangerSeats({
    model: addEqStates?.inputModel?.value,
    regNo: addEqStates?.inputRegNo?.value,
    search: searchSeat,
  });
  const allItemStatuses = useGetAllItemStatuses();
  const allFleetsWithDistinctModel = useGetAllFleetDistinctedByModel();

  // crud
  const createInventory = useCreateInventory({
    addEqStates,
    allItemStatuses,
    allInventoryTypes,
    allLocationTypes,
    allFleetsFilteredByModel,
  });

  // @TODO seat capacity as limit for allPassangerSeat

  return (
    <Box
      style={{
        width: "100%",
        borderRadius: "0px",
        height: "100%",
        position: "relative",
      }}
    >
      <Box style={{ padding: "1em" }}>
        <Grid container spacing="1em">
          {[
            {
              isRequired: addEqStates.tagId.isMandatory,
              title: "Tag ID",
              inputComp: (
                <StyledTextField
                  inputRef={addEqStates.tagId.inputRef}
                  onChange={(e) => {
                    addEqStates.tagId.setValue(e?.target?.value);
                  }}
                  fullWidth
                  error={addEqStates.tagId.isError}
                  helperText={addEqStates.tagId.errorMessage}
                />
              ),
            },
            {
              isRequired: addEqStates.serialNo.isMandatory,
              title: "Serial No.",
              inputComp: (
                <StyledTextField
                  inputRef={addEqStates.serialNo.inputRef}
                  onChange={(e) => {
                    addEqStates.serialNo.setValue(e?.target?.value);
                  }}
                  fullWidth
                  error={addEqStates.serialNo.isError}
                  helperText={addEqStates.serialNo.errorMessage}
                />
              ),
            },
            {
              isRequired: addEqStates.eqType.isMandatory,
              title: "Equipment Type",
              inputComp: allInventoryTypes.isLoading ? (
                <LoadingInput />
              ) : (
                <StyledDropDown
                  inputRef={addEqStates.eqType.inputRef}
                  // defaultValue={itemTypeData[0]?.node?.id}
                  value={addEqStates.eqType.value}
                  handleChange={(e) => {
                    addEqStates.eqType.setValue(e.target.value);
                  }}
                  options={allInventoryTypes?.data?.map(({ name, code }) => {
                    return {
                      label: name,
                      value: code,
                    };
                  })}
                  error={addEqStates.eqType.isError}
                  helperText={addEqStates.eqType.errorMessage}
                />
              ),
            },
            {
              isRequired: addEqStates.status.isMandatory,
              title: "Status",
              inputComp: allItemStatuses.isLoading ? (
                <LoadingInput />
              ) : (
                <Fragment>
                  <StyledDropDown
                    inputRef={addEqStates.status.inputRef}
                    // defaultValue={statusesData[0]?.node?.id}
                    value={addEqStates.status.value}
                    error={addEqStates.status.isError}
                    helperText={addEqStates.status.errorMessage}
                    handleChange={(e) => {
                      addEqStates.status.setValue(e.target.value);
                    }}
                    options={allItemStatuses?.data?.map((node) => {
                      const { name, id, code } = node ?? {};
                      let labelHolder = getLabelByCode(code, name);
                      return {
                        label: labelHolder,
                        value: code,
                      };
                    })}
                  />
                </Fragment>
              ),
            },
            {
              isRequired: addEqStates.mfgDate.isMandatory,
              title: "MFG Date",
              inputComp: (
                <StyledDateInput
                  value={addEqStates.mfgDate.value}
                  onChange={(date) => {
                    addEqStates.mfgDate.setValue(date);
                  }}
                  fullwidth
                />
              ),
            },
            {
              isRequired: addEqStates.expDate.isMandatory,
              title: "Expiry Date",
              inputComp: (
                <Fragment>
                  <StyledDateInput
                    value={addEqStates.expDate.value}
                    onChange={(date) => {
                      addEqStates.expDate.setValue(date);
                    }}
                    fullwidth
                  />
                </Fragment>
              ),
            },
            {
              isRequired: addEqStates.inputModel.isMandatory,
              title: "A/C Model",
              inputComp: (
                <StyledDropDown
                  inputRef={addEqStates.inputModel.inputRef}
                  value={addEqStates.inputModel.value}
                  handleChange={(e) => {
                    addEqStates.inputModel.setValue(e.target.value);
                  }}
                  options={
                    allFleetsWithDistinctModel && !allFleetsWithDistinctModel.isLoading
                      ? allFleetsWithDistinctModel.data?.map((node) => {
                          return {
                            value: node?.model,
                            label: node?.model,
                          };
                        })
                      : []
                  }
                  error={addEqStates.inputModel.isError}
                  helperText={addEqStates.inputModel.errorMessage}
                />
              ),
            },
            {
              isRequired: addEqStates.inputRegNo.isMandatory,
              title: "A/C Reg",
              inputComp: (
                <Fragment>
                  <StyledDropDown
                    inputRef={addEqStates.inputRegNo.inputRef}
                    disabled={addEqStates?.inputModel?.value ? false : true}
                    value={addEqStates.inputRegNo.value}
                    handleChange={(e) => {
                      addEqStates.inputRegNo.setValue(e.target.value);
                    }}
                    options={
                      allFleetsFilteredByModel && !allFleetsFilteredByModel.isLoading
                        ? allFleetsFilteredByModel?.data?.map((node) => {
                            return {
                              value: node?.regNo,
                              label: node?.regNo,
                            };
                          })
                        : []
                    }
                    error={addEqStates.inputRegNo.isError}
                    helperText={addEqStates.inputRegNo.errorMessage}
                  />
                </Fragment>
              ),
            },
            {
              isRemoved: addEqStates?.eqType?.value?.toLowerCase() !== "pax",
              isRequired: addEqStates.locationType.isMandatory,
              title: "Location Type",
              inputComp: (
                <StyledDropDown
                  inputRef={addEqStates.locationType.inputRef}
                  // disabled={addEqStates.inputRegNoId.value ? false : true}
                  value={addEqStates.locationType.value}
                  handleChange={(e) => {
                    addEqStates.locationType.setValue(e.target.value);
                  }}
                  options={
                    allLocationTypes?.data
                      ?.map(({ code, name }) => {
                        return { label: name, value: code };
                      })
                      ?.filter(({ value }) => value?.toLowerCase() === "ps") ?? []
                  }
                  error={addEqStates.locationType.isError}
                  helperText={addEqStates.locationType.errorMessage}
                />
              ),
            },
            {
              isRemoved:
                addEqStates?.locationType?.value?.toLowerCase() !== "ps" ||
                addEqStates?.eqType?.value?.toLowerCase() !== "pax",
              isRequired: addEqStates.locationDetailsId.isMandatory,
              title: "Location Number",
              inputComp: (
                <StyledDropDown
                  onSearch={(val) => {
                    setSearchSeat(val);
                  }}
                  inputRef={addEqStates.locationDetailsId.inputRef}
                  disabled={addEqStates.locationType.value ? false : true}
                  value={addEqStates.locationDetailsId.value}
                  handleChange={(e) => {
                    addEqStates.locationDetailsId.setValue(e.target.value);
                    setSearchSeat(undefined);
                  }}
                  options={
                    allPassangerSeats.data && !allPassangerSeats.isLoading
                      ? allPassangerSeats?.data?.map(
                          ({
                            // id,
                            seatNo,
                            occupied,
                          }) => {
                            return {
                              label: <DropDownOptionsWithAvailability occupied={occupied} seatNo={seatNo} />,
                              value: seatNo,
                            };
                          }
                        )
                      : []
                  }
                  error={addEqStates.locationDetailsId.isError}
                  helperText={addEqStates.locationDetailsId.errorMessage}
                />
              ),
            },
          ].map(({ title, inputComp, isRequired, isRemoved }) => {
            if (isRemoved) {
              return null;
            }
            return <GridItem title={title} inputComp={inputComp} isRequired={isRequired} />;
          })}
          <Box
            sx={{
              position: "absolute",
              bottom: "0px",
              width: "100%",
            }}
          >
            {/* <pre>
							{JSON.stringify(allPassangerSeats, null, 4)}
						</pre> */}
            <Divider />
            <Grid container>
              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  onClick={() => {
                    handleClose();
                  }}
                  sx={{ height: "42px", margin: "1em", width: "100%", borderRadius: "8px" }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ height: "42px", margin: "1em", width: "100%", borderRadius: "8px" }}
                  onClick={() => {
                    handleOnSubmit({
                      addEqStates,
                      openNotification,
                      createInventory,
                      handleClose,
                      refetch,
                      replaceLocation,
                    });
                  }}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
}

function handleOnSubmit({ addEqStates, openNotification, createInventory, handleClose, refetch, replaceLocation }) {
  const { isOk } = validateInputStates(
    {
      tagId: addEqStates.tagId,
      serialNo: addEqStates.serialNo,
      eqType: addEqStates.eqType,
      status: addEqStates.status,
      // mfgDate: addEqStates.mfgDate,
      // expDate: addEqStates.expDate,
      // locationDetailsId: addEqStates.locationDetailsId,
      locationType: addEqStates.locationType,
      inputRegNoId: addEqStates.inputRegNo,
    },
    true
  );
  if (isOk) {
    createInventory?.mutate().then((res) => {
      const { isError, errorMessage, data } = res;
      if (!isError) {
        openNotification("Equipment saved!", {
          variant: "info",
          onClose: () => {
            handleClose();
            refetch();
          },
        });
      } else if (errorMessage.includes("inventory_inventories_tag_key") && errorMessage.includes("already exist")) {
        openNotification("Saving failed!", {
          variant: "error",
          helperText: "Duplicated tag id, please use other value.",
        });
      } else if (errorMessage.includes("created but the Location you try to assign the item already occupied")) {
        openDialog(
          "Location is occupied",
          <Typography>
            Item has been
            <span style={{ color: "blue", fontWeight: "bolder" }}>created</span> but the location you are trying to
            assign is already occupied, would you like to
            <span style={{ color: "red", fontWeight: "bolder" }}>replace</span> the existing one?
          </Typography>,
          {
            onConfirm: () => {
              openNotification(`Are you sure?`, {
                variant: "warning",
                onConfirm: () => {
                  replaceLocation(
                    addEqStates?.tagId?.value,
                    addEqStates?.locationDetailsId?.value,
                    addEqStates?.inputRegNo?.value,
                    fullname,
                    photo
                  ).then(({ isError, errorMessage, data }) => {
                    if (!isError) {
                      openNotification("Successfully replaced", {
                        variant: "success",
                      });
                    } else {
                      openNotification(`Error ${errorMessage}`, { variant: "error" });
                    }
                  });
                },
              });
            },
          }
        );
      } else {
        openNotification("Saving failed!", {
          variant: "error",
          helperText: `Error ${errorMessage}`,
        });
      }
    });
  } else {
    // openNotification('Please fill in all the required field(s)', { variant: 'warning' })
    // do nothing instead
  }
}

export function DropDownOptionsWithAvailability({ occupied, seatNo }) {
  return (
    <Box sx={{ width: "100%" }}>
      <Grid container>
        <Grid item xs={2}>
          {occupied === "True" ? (
            <Tooltip title="Occupied">
              <span style={{ cursor: "pointer" }}>
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M5.875 9.625L7.75 11.5L12.125 7.125M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9Z"
                    stroke="#6AB47B"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </Tooltip>
          ) : (
            <Tooltip title="Available">
              <span style={{ cursor: "pointer" }}>
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z"
                    stroke="#C3C3C3"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </Tooltip>
          )}
        </Grid>
        <Grid item xs={10}>
          <Typography>{seatNo}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

function GridItem({ title, inputComp, isRequired = true }) {
  return (
    <Grid item xs={6}>
      <Typography
        sx={{
          fontSize: "14px",
          marginBottom: "0.3em",
        }}
      >
        {title}
        {isRequired ? <span style={{ color: "red" }}>*</span> : ""}
      </Typography>
      {inputComp}
    </Grid>
  );
}

function getLabelByCode(code, defaultVal) {
  let label = defaultVal;
  switch (code) {
    case "A":
      label = "Active";
      break;
    case "L":
      label = "Missing";
      break;
    case "R":
      label = "Near Expiry";
      break;
    case "E":
      label = "Expired";
      break;
    case "T":
      label = "Error";
      break;
    case "D":
      label = "Defect";
      break;
    default:
      label = "Active";
      break;
  }
  return label;
}
