import { env_var_REACT_APP_API_URL, env_var_REACT_APP_API_URL_ADV } from "../../envVar/vars";
import useGetGQL from "../useGetGQL";

/**
 * @description Status : migrated!
 * @changed useGetAllInventoryItems => useGetAllInventoryTypes
 */
export function useGetAllInventoryTypes(props) {

	const {
		searchEqType,
	} = props ?? {}

	// const data = useGetGQL({
	// 	gqlquery: `
	// 	query eqType {
	// 		allInventoryItems
	// 		${searchEqType ? '(' : ''}

	// 		${searchEqType ? ` icontainsName:"${searchEqType}" ` : ""}
		
	// 		${searchEqType ? ')' : ''}
	// 		{
	// 		  edges {
	// 			node {
	// 			  id
	// 			  code
	// 			  name
	// 			  description
	// 			}
	// 		  }
	// 		}
	// 	  }
	// 	`,
	// 	querySign: 'allInventoryItems',
	// 	url: env_var_REACT_APP_API_URL ?? '',
	// 	defaultIsLoading: true,
	// 	isEdges: true,
	// });

	const dataAdv = useGetGQL({
		gqlquery: `
		query allInventoryTypes {
			allInventoryTypes
			${searchEqType ? '(' : ''}

			${searchEqType ? ` search:"${searchEqType}" ` : ""}
		
			${searchEqType ? ')' : ''}
			{
			  items {
				_id
				name
				code
			  }
			}
		  }
		`,
		querySign: 'allInventoryTypes',
		url: env_var_REACT_APP_API_URL_ADV ?? '',
		defaultIsLoading: true,
		isEdges: false,
	});

	function convertFromNew(dataAdv) {
		return {
			...dataAdv,
			data: dataAdv?.data?.items?.map((node) => {
				return {
					...node,
					id: node?._id
				}
			}),
		}
	}

	// function convertFromOld(data) {
	// 	return {
	// 		...data,
	// 		data: data?.data?.map(({ node }) => {
	// 			return node
	// 		})
	// 	}
	// }

	// console.log({
	// 	title: 'useGetAllInventoryItems => useGetAllInventoryTypes',
	// 	data,
	// 	dataAdv,
	// 	convertedOld: convertFromOld(data),
	// 	convertedNew: convertFromNew(dataAdv),
	// })

	// return convertFromOld(data);
	return convertFromNew(dataAdv);
}

/**
 * @description Status : migrating... = combining with useGetAllInventoryTypes
 */
export function useGetSearchedInvItem({ searchEqType }) {
	const data = useGetGQL({
		gqlquery: `
        query allitems {
            allInventoryItems
            ${searchEqType ? ` (icontainsName:"${searchEqType}") ` : ""}
            {
              edges{
                node{
                  id
                  code
                  name
                }
              }
            }
          }
        `,
		querySign: "allInventoryItems",
		url: env_var_REACT_APP_API_URL ?? "",
		defaultIsLoading: true,
	});

	const dataAdv = useGetGQL({
		gqlquery: `
        query allitems {
            allInventoryItems
            ${searchEqType ? ` (icontainsName:"${searchEqType}") ` : ""}
            {
              edges{
                node{
                  id
                  code
                  name
                }
              }
            }
          }
        `,
		querySign: "allInventoryItems",
		url: env_var_REACT_APP_API_URL_ADV ?? "",
		defaultIsLoading: true,
	});

	// console.log({
	// 	title: 'useGetAllInventoryItems => useGetAllInventoryTypes',
	// 	data,
	// 	dataAdv,
	// 	convertedOld: convertFromOld(data),
	// 	convertedNew: convertFromNew(dataAdv),
	// })


	return data;
}