import React, { useState } from "react";
import styles from "./ScheduleTask.module.css";
import { Helmet } from "react-helmet";
import { Grid, styled, Tab } from "@mui/material";
import Button from "../component/Button";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import SearchBar from "./SearchBar";
import ActiveFilters from "./ActiveFilters";
import { CheckBoxField, CheckBoxFieldMock } from "pages/elevade/component/InputForm";
import { useSelector } from "react-redux";
import Sorting from "../component/Sorting";
import { makeAPIRequestMDB, makeAPIRequestRun } from "common/helpers";
import IconScheduleTaskWhite from "common/images/icons/elevade/Icon-ScheduleTaskWhite.svg";
import IconScheduleTaskDisabled from "common/images/icons/elevade/Icon-ScheduleTaskDisabled.svg";
import IconAllTasks from "common/images/icons/elevade/Icon-AllTasks.svg";
import IconAboutToExpire from "common/images/icons/elevade/Icon-AboutToExpire.svg";
import IconCalendarGreen from "common/images/icons/elevade/Icon-CalendarGreen.svg";
import IconCalendarRed from "common/images/icons/elevade/Icon-CalendarRed.svg";
// import IconPendingApproval from "common/images/icons/elevade/Icon-PendingApproval.svg";
import IconBriefcase from "common/images/icons/elevade/Icon-Briefcase.svg";
import IconBriefcaseWhite from "common/images/icons/elevade/Icon-BriefcaseWhite.svg";
import IconBriefcaseDisabled from "common/images/icons/elevade/Icon-BriefcaseDisabled.svg";
// import IconShareOut from "common/images/icons/elevade/Icon-ShareOut.svg";
import { useEffect } from "react";
import { setAOCBasedOnPermission } from "../component/Helpers";
import AirplaneLoader from "pages/elevade/component/AirplaneLoader";
import NoData from "pages/elevade/component/DataUnavailable";

const ScheduleTask = () => {
  const { companyAOC, userPermission } = useSelector((state) => state.userEntity);
  const { aocToggle } = useSelector((state) => state.aocToggle);
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [value, setValue] = useState("1");
  const [isCardActive, setIsCardActive] = useState("All Tasks");
  const [filterOptions, setFilterOptions] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [isSearch, setIsSearch] = useState([]);
  const [openTitle, setOpenTitle] = useState(false);
  const [selectToMove, setSelectToMove] = useState([]);
  const [openDialogMoveToAssignment, setOpenDialogMoveToAssignment] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [sortingColumn, setSortingColumn] = useState("");
  const [sortingDirection, setSortingDirection] = useState("asc");
  const [isAircraftStatus, setIsAircraftStatus] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  let AOC = companyAOC;
  const [aoc, setAoc] = useState(setAOCBasedOnPermission(AOC, userPermission));
  const InitialFilters = {
    search: [],
    location: [],
    aircraftReg: [],
    raisedDate: [],
    expiryDate: [],
    acStatus: [],
  };
  const [isFilters, setIsFilters] = useState({
    search: [],
    location: [],
    aircraftReg: [],
    raisedDate: [],
    expiryDate: [],
    acStatus: [],
  });

  const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: "rgba(0, 0, 0, 0.85)",
    fontFamily: ["Gotham-Book"].join(","),
    "&:hover": {
      color: "#333333",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#333333",
      fontFamily: "Gotham-Medium",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  }));

  useEffect(() => {
    if (aocToggle.value !== undefined) setAoc(aocToggle.value);
  }, [aocToggle]);

  useEffect(() => {
    fetchData();
    setIsCardActive("All Tasks");
  }, [aoc, value]);

  useEffect(() => {
    FetchDASRData();
  }, []);

  useEffect(() => {
    let data_ = data;
    if (isCardActive === "All Tasks") data_ = data;
    else if (isCardActive === "About to Expire")
      data_ = data.filter((e) => e.total_minutes > 60 && e.total_minutes <= 360);
    else if (isCardActive === "Active") data_ = data.filter((e) => e.total_minutes > 360);
    else if (isCardActive === "Expired") data_ = data.filter((e) => e.total_minutes <= 60);
    setFilteredData(data_);
    setFilterOptions({
      ...filterOptions,
      aircraftReg: data_.map((e) => e.ac_registr),
      acStatus: data_.map((e) => e.status),
      location: data_.map((e) => e.station),
    });
  }, [isCardActive]);

  useEffect(() => {
    SearchAndFilters(data);
  }, [isFilters]);

  useEffect(() => {
    MatchingArray(data, isAircraftStatus);
    setFilterOptions({
      ...filterOptions,
      aircraftReg: data.map((e) => e.ac_registr),
      acStatus: data.map((e) => e.status),
      location: data.map((e) => e.station),
    });
  }, [data, isAircraftStatus]);

  // Filters
  function SearchAndFilters(data) {
    let filteredData = data;
    if (isFilters.search.length !== 0) {
      filteredData = filteredData.filter(
        (e) =>
          isFilters.search.some((searchTerm) => e.ac_registr?.includes(searchTerm)) ||
          isFilters.search.some((searchTerm) => e.event_perfno_i.toString()?.includes(searchTerm))
      );
    }

    if (isFilters.aircraftReg.length !== 0) {
      filteredData = filteredData.filter((item) => isFilters.aircraftReg.includes(item["ac_registr"]));
    }
    if (isFilters.location.length !== 0) {
      filteredData = filteredData.filter((item) => isFilters.location.includes(item["station"]));
    }
    if (isFilters.acStatus.length !== 0) {
      filteredData = filteredData.filter((item) => isFilters.acStatus.includes(item["status"]));
    }
    if (isFilters.expiryDate.length > 0)
      filteredData = filteredData.filter((e) => e.next_exp_time_utc.value.startsWith(isFilters.expiryDate));
    setFilteredData(filteredData);
    setIsCardActive("All Tasks");
  }

  const fetchData = () => {
    setLoading(true);
    setIsTableLoading(true);
    let type = value === "1" ? "36hour" : "weekly";
    const fetch = makeAPIRequestRun(`get`, `scheduletask/${aoc}/1/${type}`);
    fetch
      .then((response) => {
        setData(response.data);
        setFilteredData(response.data);
        setTotalRecords(response.totalRows[0].Total);
        setIsTableLoading(false);
        setLoading(false);
        SearchAndFilters(response.data);
      })
      .catch((error) => {
        console.log("error", error);
        setIsTableLoading(false);
        setLoading(false);
      });
  };

  async function FetchDASRData() {
    var currentDate = new Date();
    var options = { month: "long", day: "2-digit", year: "numeric" };
    var formattedDate = currentDate.toLocaleDateString("en-US", options);
    currentDate = new Date(formattedDate);
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const isToday = `${year}-${month}-${day}`;
    const fetch = makeAPIRequestMDB(
      `get`,
      `api/aircraft/daily-status?date=${isToday}&aoc=${companyAOC.join(
        ",",
        setAOCBasedOnPermission(AOC, userPermission).join(",")
      )}`
    );
    fetch
      .then((response) => {
        setIsAircraftStatus(
          response.data.map((e) => {
            return {
              aircraftReg: e.aircraftReg,
              station: e.status.hub,
              status: e.status.status,
            };
          })
        );
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  function MatchingArray(data, aircraftData) {
    data.forEach((item1) => {
      aircraftData.forEach((item2) => {
        // Extract the suffix from item2.aircraftReg
        let suffix = item2.aircraftReg.split("-")[1];

        // Compare and update if there's a match
        if (item1.ac_registr === suffix) {
          item1.station = item2.station;
          item1.status = item2.status;
        }
      });
    });
    setFilteredData(data);
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const Tips = ({ val }) => {
    const total = val.split("|")[0];
    const color = val.split("|")[1];
    const bgColor = val.split("|")[2];
    return (
      <>
        <span
          style={{
            borderRadius: "30px",
            color: color,
            border: `1px solid ${bgColor}`,
            backgroundColor: bgColor,
            fontFamily: "Gotham-Book",
            fontSize: "12px",
            marginLeft: "8px",
            padding: "4px 8px 2px 8px",
          }}
        >
          {total !== undefined ? total : 0}
        </span>
      </>
    );
  };

  function handleSelect() {
    if (checkAll) return <CheckBoxFieldMock isCheckedAll={true} setIsCheckedAll={setCheckAll} />;
    else if (selectToMove.length > 0 && selectToMove.length < totalRows) {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          onClick={() => setCheckAll(!checkAll)}
          style={{ cursor: "pointer" }}
        >
          <rect width="20" height="20" rx="4" fill="#088FD1" />
          <path d="M6 10L14 10" stroke="white" stroke-width="3" stroke-linecap="round" />
        </svg>
      );
    } else {
      // return summaryPermission.some((perm) => perm.canUpdate === true) ? (
      //   <CheckBoxFieldMock
      //     isCheckedAll={selectToMove.length > 0 && totalRows > 0 && selectToMove.length === totalRows}
      //     setIsCheckedAll={setCheckAll}
      //   />
      // ) : (
      <CheckBoxField id={""} selected={selectToMove} setSelected={setSelectToMove} disabled={true} />;
      // );
    }
  }

  // const lastRowRef = useCallback(
  //   (node) => {
  //     if (loading) return;
  //     if (observer.current) observer.current.disconnect();
  //     observer.current = new IntersectionObserver(
  //       (entries) => {
  //         if (entries[0].isIntersecting) {
  //           // TODO: To fix this later, the page is freeze due to warning throw in browser
  //           // setPage((prevPage) => prevPage + 1);
  //         }
  //       },
  //       {
  //         rootMargin: "300px", // Load more data when 300px before the last row
  //       }
  //     );
  //     if (node) observer.current.observe(node);
  //   },
  //   [loading]
  // );

  const TableList = () => {
    return !isTableLoading ? (
      <div
        style={{
          width: "100%",
          borderRadius: "12px",
          overflow: "hidden",
          border: data.length > 0 ? "1px solid #E8E8E8" : "none",
        }}
      >
        {data.length > 0 && (
          <Grid container columns={value === "1" ? 13 : 11}>
            <Grid item xs={0.5} className={styles.tableHeader}>
              {handleSelect()}
            </Grid>
            <Grid item xs={0.3} className={styles.tableHeader}></Grid>
            <Grid item xs={0.8} className={styles.tableHeader}>
              <Sorting
                col="location"
                title="Station"
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
              />
            </Grid>
            <Grid item xs={1.2} className={styles.tableHeader}>
              <Sorting
                col="aircraftReg"
                title="A/C Reg."
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
              />
            </Grid>
            <Grid item xs={1} className={styles.tableHeader}>
              <Sorting
                col="jmlNo"
                title="JML No."
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
              />
            </Grid>
            {value === "1" && (
              <Grid item xs={2} className={styles.tableHeader}>
                <Sorting
                  col="carriedOutDate"
                  title="Date Carried Out (UTC)"
                  sortingColumn={sortingColumn}
                  setSortingColumn={setSortingColumn}
                  sortingDirection={sortingDirection}
                  setSortingDirection={setSortingDirection}
                />
              </Grid>
            )}
            <Grid item xs={2} className={styles.tableHeader}>
              <Sorting
                col="expiryDate"
                title="Expiry Date (UTC)"
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
              />
            </Grid>
            <Grid item xs={2} className={styles.tableHeader}>
              <Sorting
                col="expiryDate"
                title="Expiry Date (LT)"
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
              />
            </Grid>
            <Grid item xs={0.8} className={styles.tableHeader}>
              <Sorting
                col="remainingHours"
                title="Rem. Hours"
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
              />
            </Grid>
            <Grid item xs={2} className={styles.tableHeader}>
              <Sorting
                col="aircraftStatus"
                title="A/C Status"
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
              />
            </Grid>
            <Grid item xs={0.4} className={styles.tableHeader}></Grid>
            {filteredData &&
              filteredData.map((e, index) => {
                // let station = chkDASRData(e.operator, e.ac_registr, "station");
                // let status = chkDASRData(e.operator, e.ac_registr, "status");
                if (filteredData.length === index + 1) {
                  return (
                    <Grid item xs={value === "1" ? 13 : 11} key={index + 1} id={index + 1}>
                      <Grid
                        container
                        columns={value === "1" ? 13 : 11}
                        className={`${styles.lineItems}`}
                        sx={{
                          zIndex: 1,
                        }}
                      >
                        <Grid item xs={0.5} className={`${styles.tableBody}`} sx={{ zIndex: 3 }}>
                          <CheckBoxField
                            id={e._id}
                            selected={selectToMove}
                            setSelected={setSelectToMove}
                            disabled={true}
                            marginTop="-20px"
                          />
                        </Grid>
                        <Grid item xs={0.3} className={`${styles.tableBody}`}></Grid>
                        <Grid item xs={0.8} className={`${styles.tableBody}`}>
                          {e.station}
                        </Grid>
                        <Grid item xs={1.2} className={`${styles.tableBody}`}>
                          {e.operator === "MAA" || e.operator === "AAX"
                            ? "9M-"
                            : e.operator === "TAA" || e.operator === "TAAX"
                            ? "HS-"
                            : e.operator === "IAA"
                            ? "PX-"
                            : e.operator === "PAA"
                            ? "RP-"
                            : e.operator === "AAC"
                            ? "XU-"
                            : ""}
                          {e.ac_registr}
                        </Grid>
                        <Grid item xs={1} className={`${styles.tableBody}`}>
                          {e.event_perfno_i}
                        </Grid>
                        {value === "1" && (
                          <Grid item xs={2} className={`${styles.tableBody}`}>
                            {e.last_check_utc.value.split("T")[0] +
                              " " +
                              e.last_check_utc.value.split("T")[1].split(":")[0] +
                              ":" +
                              e.last_check_utc.value.split("T")[1].split(":")[1]}
                          </Grid>
                        )}
                        <Grid item xs={2} className={`${styles.tableBody}`}>
                          {e.next_exp_time_utc_24h}
                        </Grid>
                        <Grid item xs={2} className={`${styles.tableBody}`}>
                          {e.next_exp_time_lt_24h}
                        </Grid>
                        <Grid
                          item
                          xs={0.8}
                          className={`${styles.tableBody} ${styles.bold} ${
                            e.total_minutes < 60 && status !== "Maintenance" && status !== "AOG"
                              ? styles.red
                              : e.total_minutes > 60 && e.total_minutes <= 360
                              ? styles.yellow
                              : e.total_minutes > 360 && e.total_minutes < 720
                              ? styles.amber
                              : styles.green
                          }`}
                        >
                          {e.remaining_hours}
                        </Grid>
                        <Grid item xs={2} className={`${styles.tableBody}`}>
                          {e.status}
                        </Grid>
                        <Grid item xs={0.4} className={`${styles.tableBody}`}>
                          {/* <img src={IconShareOut} alt="icon-share-out" /> */}
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                } else {
                  return (
                    <Grid item xs={value === "1" ? 13 : 11} key={index + 1} id={index + 1}>
                      <Grid
                        container
                        columns={value === "1" ? 13 : 11}
                        className={`${styles.lineItems}`}
                        sx={{
                          zIndex: 1,
                        }}
                      >
                        <Grid item xs={0.5} className={`${styles.tableBody}`} sx={{ zIndex: 3 }}>
                          <CheckBoxField
                            id={e._id}
                            selected={selectToMove}
                            setSelected={setSelectToMove}
                            disabled={true}
                            marginTop="-20px"
                          />
                        </Grid>
                        <Grid item xs={0.3} className={`${styles.tableBody}`}></Grid>
                        <Grid item xs={0.8} className={`${styles.tableBody}`}>
                          {e.station}
                        </Grid>
                        <Grid item xs={1.2} className={`${styles.tableBody}`}>
                          {e.operator === "MAA" || e.operator === "AAX"
                            ? "9M-"
                            : e.operator === "TAA" || e.operator === "TAAX"
                            ? "HS-"
                            : e.operator === "IAA"
                            ? "PX-"
                            : e.operator === "PAA"
                            ? "RP-"
                            : e.operator === "AAC"
                            ? "XU-"
                            : ""}
                          {e.ac_registr}
                        </Grid>
                        <Grid item xs={1} className={`${styles.tableBody}`}>
                          {e.event_perfno_i}
                        </Grid>
                        {value === "1" && (
                          <Grid item xs={2} className={`${styles.tableBody}`}>
                            {e.last_check_utc.value.split("T")[0] +
                              " " +
                              e.last_check_utc.value.split("T")[1].split(":")[0] +
                              ":" +
                              e.last_check_utc.value.split("T")[1].split(":")[1]}
                          </Grid>
                        )}
                        <Grid item xs={2} className={`${styles.tableBody}`}>
                          {e.next_exp_time_utc_24h}
                        </Grid>
                        <Grid item xs={2} className={`${styles.tableBody}`}>
                          {e.next_exp_time_lt_24h}
                        </Grid>
                        <Grid
                          item
                          xs={0.8}
                          className={`${styles.tableBody} ${styles.bold} ${
                            e.total_minutes < 60 && status !== "Maintenance" && status !== "AOG"
                              ? styles.red
                              : e.total_minutes > 60 && e.total_minutes <= 360
                              ? styles.yellow
                              : e.total_minutes > 360 && e.total_minutes < 720
                              ? styles.amber
                              : styles.green
                          }`}
                        >
                          {e.remaining_hours}
                        </Grid>
                        <Grid item xs={2} className={`${styles.tableBody}`}>
                          {e.status}
                        </Grid>
                        <Grid item xs={0.4} className={`${styles.tableBody}`}>
                          {/* <img src={IconShareOut} alt="icon-share-out" /> */}
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                }
              })}
          </Grid>
        )}
        {!isTableLoading && data.length === 0 && <NoData />}
        {!isTableLoading && filteredData.length === 0 && data.length && <NoData />}
      </div>
    ) : (
      <AirplaneLoader />
    );
  };

  const HourCheck = () => {
    const card = [
      { title: "All Tasks", icon: IconAllTasks, className: styles.cardIconBgBlue, value: getTotalCount("All Tasks") },
      {
        title: "About to Expire",
        icon: IconAboutToExpire,
        className: styles.cardIconBgYellow,
        value: getTotalCount("About to Expire"),
      },
      { title: "Active", icon: IconCalendarGreen, className: styles.cardIconBgGreen, value: getTotalCount("Active") },
      { title: "Expired", icon: IconCalendarRed, className: styles.cardIconBgRed, value: getTotalCount("Expired") },
      // { title: "Saperator", value: 15 },
      // {
      //   title: "Pending Approval",
      //   icon: IconPendingApproval,
      //   className: styles.cardIconBgPurple,
      //   value: getTotalCount("Pending Approval"),
      // },
    ];
    return (
      <Grid container columns={1}>
        <Grid item xs={1} sx={{ display: "flex" }} onClick={() => setOpenModal(false)}>
          {card.map((e) => {
            return e.title !== "Saperator" ? (
              <div
                className={`${styles.card} ${isCardActive === e.title ? styles.cardActive : ""}`}
                onClick={() => setIsCardActive(e.title)}
              >
                <div className={`${styles.cardIcon} ${e.className}`}>
                  <div className={styles.cardImg}>
                    <img src={e.icon} alt={e.title} />
                  </div>
                </div>
                <div className={styles.cardContent}>
                  <div className={styles.cardTitle}>{e.title}</div>
                  <div className={styles.cardValue}>{e.value}</div>
                </div>
              </div>
            ) : (
              <div className={styles.saperator}></div>
            );
          })}
        </Grid>
        <Grid item xs={1} className={styles.searchBar}>
          <SearchBar
            open={openModal}
            setOpen={setOpenModal}
            data={filterOptions}
            isSearch={isSearch}
            setIsSearch={setIsSearch}
            isFilters={isFilters}
            setIsFilters={setIsFilters}
            openTitle={openTitle}
            setOpenTitle={setOpenTitle}
            aoc={AOC}
          />
          <span onClick={() => setOpenModal(false)}>
            <ActiveFilters
              isFilters={isFilters}
              setIsFilters={setIsFilters}
              initialFilters={InitialFilters}
              setOpen={setOpenModal}
            />
          </span>
        </Grid>
        <Grid item xs={1} sx={{ padding: "16px 0" }} onClick={() => setOpenModal(false)}>
          <Button
            title="Move to Assignment Draft List"
            icon={IconBriefcase}
            iconWhite={IconBriefcaseWhite}
            iconDisabled={IconBriefcaseDisabled}
            variant={selectToMove.length > 0 ? "outlined" : "disabled"}
            onClick={() => setOpenDialogMoveToAssignment(true)}
          />
        </Grid>
        <Grid item xs={1} onClick={() => setOpenModal(false)}>
          <TableList />
        </Grid>
      </Grid>
    );
  };

  function getTotalCount(status) {
    if (status === "All Tasks") return data.length;
    else if (status === "About to Expire")
      return data.filter((e) => e.total_minutes > 60 && e.total_minutes <= 360).length;
    else if (status === "Expired") return data.filter((e) => e.total_minutes <= 60).length;
    else if (status === "Active") return data.filter((e) => e.total_minutes > 360).length;
    else return 0;
  }

  return (
    <div className={styles.container}>
      <Helmet>
        <title>ELEVADE - Schedule Task</title>
      </Helmet>
      <Grid container columns={3}>
        <Grid item xs={1} className={styles.gridTitle} onClick={() => setOpenModal(false)}>
          Scheduled Task
        </Grid>
        <Grid
          item
          xs={3}
          className={styles.gridJustifyEnd}
          sx={{ fontSize: "14px" }}
          onClick={() => setOpenModal(false)}
        >
          <Button
            title="Update Scheduled task"
            icon={IconScheduleTaskWhite}
            iconWhite={IconScheduleTaskWhite}
            iconDisabled={IconScheduleTaskDisabled}
            variant="disabled"
            flexend
          />
        </Grid>
        <Grid item xs={3}>
          <TabContext value={value}>
            <TabList onChange={handleChange} aria-label="lab API tabs example" onClick={() => setOpenModal(false)}>
              <AntTab
                label="36-Hour Check"
                value="1"
                icon={<Tips val={`${getTotalCount("Expired")}|#ffffff|#E95048`} unread={true} />}
                iconPosition="end"
              />
              <AntTab
                label="Weekly Check"
                value="2"
                icon={<Tips val={`${getTotalCount("Expired")}|#ffffff|#E95048`} unread={true} />}
                iconPosition="end"
              />
            </TabList>
            <div
              style={{
                width: "100%",
                height: "2px",
                backgroundColor: "#d1d1d1",
                marginTop: "-2px",
              }}
            ></div>
            <div
              style={{
                marginLeft: "-20px",
                marginRight: "-20px",
              }}
            >
              <TabPanel value="1">
                <HourCheck />
              </TabPanel>
              <TabPanel value="2">
                <HourCheck />
              </TabPanel>
            </div>
          </TabContext>
        </Grid>
      </Grid>
    </div>
  );
};

export default ScheduleTask;
