
export function getColorSeatFillByInvStatus(statusCode) {
	let fill = 'white';
	switch (statusCode) {
		case "A":
			fill = 'white';
			break;
		case "L":
			fill = 'red';
			break;
		case "T":
			fill = 'orange';
			break;
		case "E":
			fill = 'blue';
			break;
		case "R":
			fill = 'grey';
			break;
		case "D":
			fill = 'dot';
			break;
		case "NA":
			fill = 'black';
			break;
		default:
			fill = 'white';
			break;
	}
	return fill;
}

