import React, { useEffect, useRef, useState } from "react";
import styles from "../aircraftReport/DDML.module.css";
import { Box, Grid, Modal, styled, Tab, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import IconClose from "common/images/icons/elevade/Icon-Close.svg";
import IconCheckCircleWhite from "common/images/icons/elevade/Icon-CheckCircleWhite.svg";
import IconCheckCircleDisabled from "common/images/icons/elevade/Icon-CheckCircleDisabled.svg";
import Button from "pages/elevade/component/Button";
import { useSelector } from "react-redux";
import { formatDateDasr, formatTimeDasr } from "pages/elevade/component/Helpers";
import DialogConfirm from "../component/DialogConfirm";
import DDMLUpdateLogs from "../aircraftReport/DDMLUpdateLogs";
import { makeAPIRequestMDB } from "common/helpers/makeAPIRequestMDB";
import IconSendButton from "common/images/icons/elevade/Icon-SendButton.svg";
import IconAttachment from "common/images/icons/elevade/Icon-Attachment.svg";
import firebase from "firebase/app";
import "firebase/storage";
import { showNotificationElevade } from "common/helpers";
import { useLocation } from "react-router-dom";

const RepetitiveDefectSummary = ({ data, open, setOpen, setEdit, setTriggerApi, dataForm, ...props }) => {
  const tabRef = useRef(null);
  const commentRef = useRef(null);
  const { userProfile } = useSelector((state) => state.authEntity);
  const { userProfile: userEntity } = useSelector((state) => state.userEntity);
  const { userPermission } = useSelector((state) => state.userEntity);
  const DDMLPermission = userPermission.filter(
    (perm) =>
      perm.code === "DASR-004" || perm.code === "DASR-005" || perm.code === "DASR-006" || perm.code === "DASR-007"
  );

  const [value, setValue] = useState("1");
  const [DDMLLogs, setDDMLLogs] = useState([]);
  const [uploadComment, setUploadComment] = useState(false);
  const [openConfirmCopy, setOpenConfirmCopy] = useState(false);
  const [commentPushSummaryId, setCommentPushSummaryId] = useState("");
  const [commentPushSummary, setCommentPushSummary] = useState("");
  const [category, setCategory] = useState();
  const [DDMLId, setDDMLId] = useState();
  const [openPreviewComment, setOpenPreviewComment] = useState(false);
  const handleCloseComment = () => setOpenPreviewComment(false);
  const [commentFileLink, setCommentFileLink] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [previewUrl, setPreviewUrl] = useState();

  const unreadLogs = {
    materialTool: [],
    planningTech: [],
    rectification: [],
  };

  let dataList = props?.dataList;
  dataList[0].unreadLogs = {
    materialTool: [],
    planningTech: [],
    rectification: [],
  };

  // An API called to get the "ddml-logs" list of the selected DDML
  async function DDMLLogList() {
    const params = `api/rdd/logs/${dataForm?._id ? dataForm._id : dataForm.id}`;
    const fetch = makeAPIRequestMDB(`get`, `${params}`);
    fetch.then((response) => {
      setDDMLLogs(response);
      setUploadComment(false);
    });
  }

  // A function used to push comment into the summary tab
  function handleCopy(e, id, category, ddmlId) {
    setOpenConfirmCopy(true);
    setCommentPushSummaryId(id);
    setCommentPushSummary(e);
    setCategory(category);
    setDDMLId(ddmlId);
  }

  // A function to set the file links for previewing event
  function handleOpenComment(e) {
    setCommentFileLink(e);
    setOpenPreviewComment(true);
  }

  function ConvertDate(date1_str) {
    // Convert the date string to a Date object
    var date1 = new Date(date1_str);

    // Function to get the month abbreviation
    function getMonthAbbreviation(month) {
      var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      return months[month];
    }

    // Function to format the time as HH:MM AM/PM
    function formatTime(date) {
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12;
      return hours + ":" + (minutes < 10 ? "0" : "") + minutes + " " + ampm;
    }

    // Convert the date to UTC+8 timezone
    var dateUtcPlus8 = new Date(date1.getTime() + 8 * 60 * 60 * 1000);
    // var dateUtcPlus8 = new Date(date1); // UTC datetime

    // Format the date into the desired format
    var formattedDate =
      getMonthAbbreviation(dateUtcPlus8.getUTCMonth()) +
      " " +
      dateUtcPlus8.getUTCDate() +
      ", " +
      dateUtcPlus8.getUTCFullYear() +
      " at " +
      formatTime(dateUtcPlus8);

    return formattedDate;
  }

  function SummaryDDMLFormPermission(summaryButton) {
    const summaryPermission = DDMLPermission.filter((perm) => perm.code === "DASR-004");
    if (summaryPermission.some((perm) => perm.canUpdate === true)) {
      switch (summaryButton) {
        case "Edit":
          return dataForm?.formStatus === "closed" ? "disabled" : "none";
        case "Assignment":
          return dataForm?.moveToAssignment === true || dataForm?.formStatus === "closed" ? "disabled" : "outlined";
        case "Close Task":
          return dataForm?.formStatus === "closed" ? "disabled" : "fill";
      }
    } else {
      return "disabled";
    }
  }

  useEffect(() => {
    DDMLLogList();
  }, [dataForm]);

  const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: "rgba(0, 0, 0, 0.85)",
    fontFamily: ["Gotham-Book"].join(","),
    fontSize: "14px",
    "&:hover": {
      color: "#333333",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#333333",
      fontFamily: "Gotham-Medium",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
      paddingBottom: "10px",
    },
    "&.MuiTab-root": {
      marginRight: "10px",
      marginTop: "-10px",
      marginBottom: "-10px",
    },
  }));

  const useStyles = makeStyles(() => ({
    splitItems: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      // display: "-webkit-box",
      // "-webkit-box-orient": "vertical",
      // "-webkit-line-clamp": 2, // Number of lines to show
    },
    customTabsStyle: {
      "& .MuiTabScrollButton-root:first-child": {
        marginTop: "-6px",
      },
      "& .MuiTabScrollButton-root:last-child": {
        marginTop: "-6px",
      },
    },
  }));

  const classes = useStyles();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  const DDMLTitle = () => {
    return (
      <>
        <span
          style={{
            border: "1px solid #C3C3C3",
            borderRadius: "6px",
            padding: "4px 8px",
            marginRight: "8px",
            whiteSpace: "nowrap",
            marginLeft: "8px",
          }}
        >
          {dataForm?.aircraftReg}
        </span>
        {/* <span>{dataForm?.ddmlNo}</span> */}
      </>
    );
  };

  const BodySummary = () => {
    return (
      <>
        <div
          style={{
            position: "relative",
            marginLeft: "16px",
            marginTop: "190px",
            paddingRight: "32px",
          }}
          className={styles.container}
        >
          <Grid container columns={2}>
            <Grid item xs={1}>
              <div className={styles.titleSummary}>Aircraft Registration</div>
              <div>{dataForm?.aircraftReg}</div>
            </Grid>
            <Grid item xs={1}>
              <div className={styles.titleSummary}>Station</div>
              <div>{dataForm?.location || "-"}</div>
            </Grid>
            <Grid item xs={1}>
              <div className={styles.titleSummary}>Raised Date</div>
              <div>{formatDateDasr(dataForm?.createdAt)}</div>
            </Grid>
            <Grid item xs={1}>
              <div className={styles.titleSummary}>ETS</div>
              <div>{formatDateDasr(dataForm?.ets)}</div>
            </Grid>
            <Grid item xs={2}>
              <div className={styles.titleSummary}>Messages</div>
              <div className={styles.bodySummary}>{dataForm?.text}</div>
            </Grid>
            <Grid item xs={2}>
              <div className={styles.titleSummary}>Rectification Status</div>
              <div className={styles.bodySummary}>{dataForm?.rectificationStatus || "-"}</div>
            </Grid>
            <Grid item xs={1}>
              <div className={styles.titleSummary}>
                Created {formatDateDasr(data.createdAt)} at {formatTimeDasr(data.createdAt)}
                <br />
                {"Latest updated on " +
                  formatDateDasr(data.updatedAt ?? data.createdAt) +
                  " at " +
                  formatTimeDasr(data.updatedAt ?? data.createdAt)}
              </div>
            </Grid>
            <Grid item xs={1} sx={{ paddingLeft: "10px" }}>
              <div className={styles.titleSummary}>
                {data.updatedAt !== null
                  ? `Updated ${formatDateDasr(data.updatedAt)} at ${formatTimeDasr(data.updatedAt)}`
                  : ""}
              </div>
            </Grid>
          </Grid>
        </div>
      </>
    );
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Grid
        container
        columns={4}
        sx={{
          borderBottom: "1px solid #c3c3c3",
          position: "fixed",
          zIndex: 9,
          backgroundColor: "#ffffff",
          top: "60px",
        }}
        className={`${styles.macScrollbar}`}
      >
        <Grid item xs={3} sx={{ padding: "24px" }} className={styles.title} ref={tabRef}>
          Task Details <DDMLTitle />
        </Grid>
        <Grid item xs={1} sx={{ display: "flex", justifyContent: "flex-end", padding: "24px" }}>
          <img
            src={IconClose}
            alt="icon-close"
            height={15}
            style={{ marginTop: "2px", cursor: "pointer" }}
            onClick={() => setOpen(false)}
          />
        </Grid>
      </Grid>
      <Grid container columns={4}>
        <Grid item xs={4} sx={{ paddingBottom: "100px" }}>
          <TabContext value={value} sx={{ marginLeft: "-60px" }}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              variant="scrollable"
              scrollButtons={"auto"}
              classes={{ root: classes.customTabsStyle }}
              sx={{
                paddingTop: "10px",
                // paddingRight: "10px",
                position: "fixed",
                top: "121px",
                transform: "translateX(-0%)",
                zIndex: "10",
                backgroundColor: "#ffffff",
                borderBottom: "2px solid #e8e8e8",
                borderTop: "0.5px solid #C3C3C3",
              }}
            >
              <AntTab label="Summary" value="1" />
              <AntTab
                label="Rectification Update"
                value="2"
                icon={
                  dataForm?.unreadLogs?.rectification.length > 0 && (
                    <Tips val={dataForm?.unreadLogs?.rectification.length} />
                  )
                }
                iconPosition="end"
              />
              <AntTab
                label="Material/Tools Update"
                value="3"
                icon={
                  dataForm?.unreadLogs?.materialTool.length > 0 && (
                    <Tips val={dataForm?.unreadLogs?.materialTool.length} />
                  )
                }
                iconPosition="end"
              />
              <AntTab
                label="Planning/Tech Service"
                value="4"
                icon={
                  dataForm?.unreadLogs?.planningTech.length > 0 && (
                    <Tips val={dataForm?.unreadLogs?.planningTech.length} />
                  )
                }
                iconPosition="end"
              />
            </TabList>
            <div
              style={{
                marginLeft: "0px",
                marginRight: "-20px",
                paddingBottom: "60px",
                width: "100%",
                overflow: "auto",
              }}
            >
              <TabPanel value="1" sx={{ padding: "0px" }}>
                <BodySummary />
              </TabPanel>
              <TabPanel value="2" sx={{ padding: "150px 0 0 0", position: "relative" }}>
                <DDMLUpdateLogs
                  tab={"Rectification Update"}
                  DDMLLogs={DDMLLogs}
                  handleCopy={handleCopy}
                  handleOpenComment={handleOpenComment}
                  ConvertDate={ConvertDate}
                  openPreviewComment={openPreviewComment}
                  handleCloseComment={handleCloseComment}
                  style={style}
                  commentFileLink={commentFileLink}
                  uploadComment={uploadComment}
                  setUploadComment={setUploadComment}
                  ddmlStatus={dataForm?.ddmlStatus}
                  initialData={data}
                  data={dataList}
                  // data={props.dataList}
                  // unreadLogs={dataForm?.unreadLogs?.rectification}
                  unreadLogs={unreadLogs?.rectification}
                />
              </TabPanel>
              <TabPanel value="3" sx={{ padding: "150px 0 0 0" }}>
                <DDMLUpdateLogs
                  tab={"Material/Tools Update"}
                  DDMLLogs={DDMLLogs}
                  handleCopy={handleCopy}
                  handleOpenComment={handleOpenComment}
                  ConvertDate={ConvertDate}
                  openPreviewComment={openPreviewComment}
                  handleCloseComment={handleCloseComment}
                  style={style}
                  commentFileLink={commentFileLink}
                  uploadComment={uploadComment}
                  setUploadComment={setUploadComment}
                  initialData={data[0] || data}
                  ddmlStatus={dataForm?.ddmlStatus}
                  data={props.dataList}
                  unreadLogs={dataForm?.unreadLogs?.materialTool}
                />
              </TabPanel>
              <TabPanel value="4" sx={{ padding: "150px 0 0 0" }}>
                <DDMLUpdateLogs
                  tab={"PPC"}
                  DDMLLogs={DDMLLogs}
                  handleCopy={handleCopy}
                  handleOpenComment={handleOpenComment}
                  ConvertDate={ConvertDate}
                  openPreviewComment={openPreviewComment}
                  handleCloseComment={handleCloseComment}
                  style={style}
                  commentFileLink={commentFileLink}
                  uploadComment={uploadComment}
                  setUploadComment={setUploadComment}
                  ddmlStatus={dataForm?.ddmlStatus}
                  initialData={data}
                  data={props.dataList}
                  unreadLogs={dataForm?.unreadLogs?.planningTech}
                />
              </TabPanel>
            </div>
          </TabContext>
          <div
            style={{
              position: "fixed",
              bottom: "0",
              width: "540px",
              borderTop: "1px solid #D1D1D1",
              backgroundColor: "#ffffff",
              padding: "16px 24px 14px 24px",
              display: "flex",
              justifyContent: "center",
              zIndex: 2,
            }}
          >
            {value === "1" && (
              <>
                <Grid container columns={2}>
                  <Grid item xs={1} sx={{ paddingRight: "12px" }}>
                    <Button
                      title="Edit"
                      // variant={dataForm?.formStatus === "closed" ? "disabled" : "none"}
                      variant={SummaryDDMLFormPermission("Edit")}
                      onClick={() => setEdit(true)}
                      fullWidth={true}
                      marginTop="0px"
                    />
                  </Grid>
                  <Grid item xs={1} sx={{ paddingLeft: "12px" }}>
                    <Button
                      title="Close task"
                      icon={IconCheckCircleWhite}
                      iconWhite={IconCheckCircleWhite}
                      iconDisabled={IconCheckCircleDisabled}
                      // variant={
                      //   dataForm?.moveToAssignment === true || dataForm?.formStatus === "closed" ? "disabled" : "outlined"
                      // }
                      variant={SummaryDDMLFormPermission("Close Task")}
                      onClick={() => setOpenDialogConfirm(true)}
                      fullWidth={true}
                      marginTop="0px"
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default RepetitiveDefectSummary;
