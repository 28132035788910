
import React from 'react';
import {
	TableContainer,
	Paper,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Pagination,
	MenuItem,
	Select,
	Typography,
	Tabs,
	Tab,
} from "@mui/material";
import { Box } from "@mui/system";
import { Fragment, useEffect, useState } from "react";
import useGetGQL from "../../../models/useGetGQL";
import SearchAdv from "./SearchAdv";
import { Active, Expired, Missing, NearExpiry, Error } from "./Icons";
import UpdateEquipment from "./UpdateEquipment";
import AddEquipment from "./AddEquipment";
import { gothamBold } from "../../../constants/fonts";
import RightDrawer from "../../components/modals/RightDrawer";
import TabPanel from "./TabPanel";
import ProductLoading from "./Loading";
// import axios from "axios";
import { paragraphSpacing, tableRowFontSize } from "../../../constants/styles";
import { getCabinMonitDateFormat } from "../../utils/dates";
import { useGetFilteredInventory } from "../../../models/custom/inventory-model";
import InventoryHistoryTab from './InventoryHistoryTab';
import AirplaneLoading from '../OverviewPage/AirplaneLoading';

const GQLNODESLIMIT = 300;
export default function InventoryListAll({ showAdd, setShowAdd }) {

	const [itemToUpdate, setItemToUpdate] = useState(undefined);

	// searching & filters
	const [search, setSearch] = useState(undefined);
	const [filterRegnos, setFilterRegnos] = useState([]);
	const [filterAcModels, setFilterAcModels] = useState([]);
	const [filterStatuses, setFilterStatuses] = useState([]);
	const [filterEqTypes, setFilterEqTypes] = useState([]);
	const [filterLocationExist, setFilterLocationExist] = useState(true);
	// const [clickedPageNumber, setClickedPageNumber] = useState(false);

	// const [fetchCount, setFetchCount] = useState(1);
	// const offset = GQLNODESLIMIT * (fetchCount - 1);


	const [itemsPerPage, setItemsPerPage] = useState(10);
	const [curPage, setCurPage] = useState(1);
	// const upperThreshold = (GQLNODESLIMIT / itemsPerPage) * fetchCount;
	// const lowerThreshold = (GQLNODESLIMIT / itemsPerPage) * (fetchCount - 1);
	// const upperIndex = curPage * itemsPerPage - GQLNODESLIMIT * (fetchCount - 1);
	// const lowerIndex = (curPage - 1) * itemsPerPage - GQLNODESLIMIT * (fetchCount - 1);

	const allInventoryLists = useGetFilteredInventory({
		// offset,
		search,
		page: curPage,
		limit: itemsPerPage,
		filterRegnos,
		filterAcModels,
		filterStatuses,
		filterEqTypes,
		filterLocationExist,
	});

	// useEffect(() => {
	// 	// reset page back to 1 if data changed
	// 	// setCurPage(1);
	// 	// if (!clickedPageNumber) {
	// 	// 	setFetchCount(1);
	// 	// }
	// 	// setClickedPageNumber(false);
	// }, [allInventoryLists.data]);

	const numOfPages = allInventoryLists.totalCountFiltered ? Math.ceil(allInventoryLists.totalCountFiltered / itemsPerPage) : 1;

	return (
		<Box sx={{ height: "100%", }} >
			<AddItemDrawer
				showAdd={showAdd}
				setShowAdd={setShowAdd}
				allInventoryLists={allInventoryLists}
			/>
			<UpdateItemDrawer
				itemToUpdate={itemToUpdate}
				setItemToUpdate={setItemToUpdate}
				allInventoryLists={allInventoryLists}
			/>
			<TabButtons setFilterLocationExist={setFilterLocationExist} />
			<SearchAdv
				filterEqTypes={filterEqTypes}
				setFilterEqTypes={setFilterEqTypes}
				search={search}
				filterRegnos={filterRegnos}
				filterAcModels={filterAcModels}
				filterStatuses={filterStatuses}
				filterLocationExist={filterLocationExist}
				setFilterRegnos={setFilterRegnos}
				setFilterAcModels={setFilterAcModels}
				setFilterStatuses={setFilterStatuses}
				setSearch={(item) => {
					setSearch(item);
					setCurPage(1);
					// setFetchCount(1);
				}}
				setFilterLocationExist={setFilterLocationExist}
			/>
			{allInventoryLists.isLoading ? (
				<AirplaneLoading />
			) : (
				<Fragment>
					<TableContainer
						component={Paper}
						sx={{
							boxShadow: "none",
							borderRadius: "12px",
							border: "1px solid #E8E8E8",
							"& .MuiTableRow-root:hover": {
								backgroundColor: "#F7F7F7",
							},
						}}
					>
						<Table
							sx={{/* minWidth: 650 */ }}
							aria-label="simple table"
						>
							<TableHeaderForEqList />
							<TableBodyForEqList
								allInventoryLists={allInventoryLists}
								// upperIndex={upperIndex}
								// lowerIndex={lowerIndex}
								setItemToUpdate={setItemToUpdate}
							/>
						</Table>
					</TableContainer>
					<TablePaginationBoxEqList
						setCurPage={setCurPage}
						itemsPerPage={itemsPerPage}
						setItemsPerPage={setItemsPerPage}
						curPage={curPage}
						numOfPages={numOfPages}
					// setClickedPageNumber={setClickedPageNumber}
					// upperThreshold={upperThreshold}
					// lowerThreshold={lowerThreshold}
					/>
				</Fragment>
			)}
			<Box sx={{ width: "100%", height: "2em" }} />
		</Box>
	);
}

function TabButtons({
	setFilterLocationExist,
}) {

	const [tabEquPost, setTabEquPost] = useState(0);

	const handleTabChange = (event, newValue) => {
		setTabEquPost(newValue);
		// Update the filterLocationExist state based on the selected tab value
		setFilterLocationExist(newValue === 0); // If the second tab is selected, set filterLocationExist to false
	};

	return (
		<Box sx={{ marginBottom: "1em" }}>
			<Tabs
				TabIndicatorProps={{
					style: {
						backgroundColor: "#088FD1",
					},
				}}
				value={tabEquPost}
				onChange={handleTabChange}
			>
				<Tab
					label="Utilised Equipment"
					sx={{
						color: "#333333",
						"&.Mui-selected": {
							color: "#333333",
							fontWeight: "bold",
						},
						textTransform: "None",
					}}
				/>
				<Tab
					label="Unutilised Equipment"
					sx={{
						color: "#333333",
						"&.Mui-selected": {
							color: "#333333",
							fontWeight: "bold",
						},
						textTransform: "None",
					}}
				/>
			</Tabs>
		</Box>
	)
}

function UpdateItemDrawer({
	itemToUpdate,
	setItemToUpdate,
	allInventoryLists,
}) {

	const [tabPost, setTabPost] = useState(0);

	const handleChange = (event, newValue) => {
		setTabPost(newValue);
	};

	return (
		<RightDrawer
			open={itemToUpdate === undefined ? false : true}
			setClose={() => {
				setItemToUpdate(undefined);
			}}
			title={
				<Typography
					sx={{
						paddingBottom: "1em",
						paddingLeft: "1em",
						paddingTop: "1em",
						fontFamily: gothamBold,
					}}
				>
					Update Equipment
				</Typography>
			}
		>
			<Box
				sx={{
					height: "94%",
					paddingTop: "0px",
				}}
			>
				<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
					<Tabs value={tabPost} onChange={handleChange}>
						<Tab label="Equipment Details" />
						<Tab label="History" />
					</Tabs>
				</Box>
				<TabPanel value={tabPost} index={0}>
					{/* here! */}
					{itemToUpdate && (
						<UpdateEquipment
							node={itemToUpdate}
							refetch={allInventoryLists.refetch}
							onClose={() => {
								setItemToUpdate(undefined);
							}}
						/>
					)}
				</TabPanel>
				<TabPanel value={tabPost} index={1}>
					<InventoryHistoryTab node={itemToUpdate} />
				</TabPanel>
			</Box>
		</RightDrawer>
	)
}

function AddItemDrawer({ showAdd, setShowAdd, allInventoryLists, }) {
	return (
		<RightDrawer
			open={showAdd}
			setClose={() => {
				setShowAdd(false);
			}}
			title={
				<Typography
					sx={{
						paddingBottom: "1em",
						paddingLeft: "1em",
						paddingTop: "1em",
						fontFamily: gothamBold,
					}}
				>
					Add New Equipment
				</Typography>
			}
		>
			{showAdd && ( // force it to rerender to < Typography sx={{ whiteSpace: 'nowrap', marginLeft:'10px' }} >Clear all</Typography> inputs
				<AddEquipment
					handleClose={() => {
						setShowAdd(false);
					}}
					refetch={allInventoryLists.refetch}
				/>
			)}
		</RightDrawer>
	)
}

function TablePaginationBoxEqList({
	itemsPerPage,
	setItemsPerPage,
	numOfPages,
	curPage,
	setCurPage,
	// upperThreshold,
	// lowerThreshold,
	// setClickedPageNumber,
}) {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "right",
				marginTop: "1em",
			}}
		>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					marginRight: "1em",
				}}
			>
				Rows Per Page
			</Box>
			<Select
				sx={{
					padding: "0px",
					"& .MuiSelect-select": {
						padding: "0px",
						paddingLeft: "1em",
					},
					marginRight: "1em",
				}}
				value={itemsPerPage}
				onChange={(e) => {
					setItemsPerPage(e.target.value);
					setCurPage(1);
				}}
			>
				{["10", "20", "50", "100"].map((pageNumber) => (
					<MenuItem
						sx={{
							padding: "0px",
						}}
						key={pageNumber}
						value={pageNumber}
					>
						{pageNumber}
					</MenuItem>
				))}
			</Select>
			<Pagination
				page={curPage}
				count={numOfPages}
				onChange={(e, value) => {
					setCurPage(value);
					// setClickedPageNumber(true);
					// if (value > upperThreshold) {
					// 	setFetchCount(
					// 		Math.ceil(
					// 			(value * itemsPerPage) /
					// 			GQLNODESLIMIT
					// 		)
					// 	);
					// } else if (value <= lowerThreshold) {
					// 	setFetchCount(
					// 		Math.ceil(
					// 			(value * itemsPerPage) /
					// 			GQLNODESLIMIT
					// 		)
					// 	);
					// }
				}}
				variant="outlined"
				shape="rounded"
			/>
		</Box>
	)
}

function getStatus(statuscode) {
	switch (statuscode) {
		case "A":
			return Active;
		case "L":
			return Missing;
		case "T":
			return Error;
		case "E":
			return Expired;
		case "R":
			return NearExpiry;
		case "D":
			return () => (
				<Typography
					style={{
						fontWeight: "bold",
						border: "1px solid black",
						borderRadius: "20px",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						fontSize: "14px",
						height: "28px",
						verticalAlign: "middle",
						textAlign: "center",
						padding: "auto",
						minWidth: "108px",
					}}
				>
					Defects
				</Typography>
			);
		default:
			return Active;
	}
}

function NoItems({ allInventoryLists }) {
	return (
		<Fragment >
			{allInventoryLists.data && allInventoryLists.data.length < 1 && !allInventoryLists.isLoading && (
				<TableRow>
					<TableCell
						colSpan={9}
						component="th"
						scope="row"
					>
						No record(s) found.
					</TableCell>
				</TableRow>
			)}
		</Fragment>
	)
}

function TableHeaderForEqList() {

	function HeaderCell({ title, align }) {
		return (
			<TableCell
				align={align}
				sx={{
					fontSize: tableRowFontSize,
					lineHeight: paragraphSpacing,
				}}
			>
				{title}
			</TableCell>
		)
	}

	return (
		<TableHead>
			<TableRow sx={{
				backgroundColor: "#F2F7FF",
			}}>
				<HeaderCell title="Tag ID" />
				<HeaderCell title="Serial No." />
				<HeaderCell title="Eq. Type" />
				<HeaderCell align="center" title="Status" />
				<HeaderCell title="Expiry Date" />
				<HeaderCell title="MFG Date" />
				<HeaderCell title="A/C Model" />
				<HeaderCell title="A/C Reg" />
				<HeaderCell title="Location" />
			</TableRow>
		</TableHead>
	)
}

function TableBodyForEqList({
	allInventoryLists,
	// upperIndex,
	// lowerIndex,
	setItemToUpdate,
}) {
	
	// console.log({allInventoryLists})

	function BodyCell({ val, sx, align }) {
		return (
			<TableCell
				sx={{
					fontSize: tableRowFontSize,
					lineHeight: paragraphSpacing,
					...sx,
				}}
				component="th"
				scope="row"
				align={align}
			>
				{val}
			</TableCell>
		)
	}

	return (
		<TableBody>
			<NoItems allInventoryLists={allInventoryLists} />
			{allInventoryLists.data && !allInventoryLists.isLoading && allInventoryLists.data.map((item, index) => {
				const {
					id,
					tag,
					serialNo,
					expiryDate,
					manufactureDate,
					model,
					regNo,
					seatNo,
					// updatedBy,
					// createdBy,
				} = item;
				const statuscode = item?.status?.code ?? "";
				const eqType = item?.type?.code ?? "";
				let StatusHolder = getStatus(statuscode);

				return (
					<TableRow
						sx={{
							cursor: "pointer",
							"&:last-child td, &:last-child th": { border: 0, },
							"&:hover": { background: "#D1D1D1", },
							fontSize: tableRowFontSize,
							lineHeight: paragraphSpacing,
						}}
						onClick={() => {
							setItemToUpdate(item);
						}}
					>
						<BodyCell
							sx={{
								maxWidth: "150px",
								minWidth: "150px",
								wordBreak: "break-all",
								position: "relative",
							}}
							val={tag ?? '-'}
							// val={tag?.slice(-12) ?? '-'}
						/>
						<BodyCell val={serialNo} />
						<BodyCell align="center" val={eqType} />
						<BodyCell val={<StatusHolder />} />
						<BodyCell val={expiryDate ? getCabinMonitDateFormat(expiryDate) : "N/A"} />
						<BodyCell val={manufactureDate ? getCabinMonitDateFormat(manufactureDate) : "N/A"} />
						<BodyCell val={model ?? "N/A"} />
						<BodyCell val={regNo ?? "N/A"} />
						<BodyCell val={seatNo ?? "N/A"} />
					</TableRow>
				);

				// if (index >= lowerIndex && index <= upperIndex) {
				// if (
				// 	index > lowerIndex - 1 &&
				// 	index <= upperIndex - 1
				// ) {

				// } else {
				// 	return null;
				// }
			}
			)}
		</TableBody>
	)
}

function LoadingBoxEqList() {
	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				width: "100%",
				height: "100%",
			}}
		>
			<Box
				sx={{
					maxWidth: "600px",
					maxHeight: "600px",
				}}
			>
				<ProductLoading />
			</Box>
		</Box>
	)
}