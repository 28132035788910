
import React from 'react';
import ACTemplate from './lopa-commons/components/ACTemplate';
import A320NeoSkeleton from './A320Neo/A320NeoSkeleton';
import A320CeoSkeleton from './A320Ceo/A320CeoSkeleton';
import { a320CeoBoxConfigs } from './A320Ceo/a320Ceo-box-settings';
import { a320NeoBoxConfigs } from './A320Neo/a320Neo-box-settings';
import seats180 from './A320Ceo/seats-180/seats';
import seats186 from './A320Neo/seats-186/seats';
import FilterDefsA320Ceo from './A320Ceo/A320CeoSkeleton/FilterDefs';
import FilterDefsA320Neo from './A320Neo/A320NeoSkeleton/FilterDefs';
import { generalizedCategories, inventoryLocationCategoriesA320Ceo, inventoryLocationCategoriesA320Neo } from './lopa-commons/utils/item-data-categorizer';
import { generalizedBoxes } from './lopa-commons/utils/generalized-box-settings';
import { Typography } from '@mui/material';

// @TODO add seatCount which should be return by API
// layout for AirCraft is determined by how many seats in it.

// @TODO fix the popup positioning if 2 columns exist in a box (rowCountThreshold)

export default function Lopa(props) {

	const { model, fleetId, itemsData, onClickItem, fleetNode } = props;
	const { engineType } = fleetNode ?? {}

	// const seatCount = 180;
	// const foundModelTemplate = getModelTemplate({
	// 	model,
	// 	fleetNode,
	// 	seatCount,
	// })

	const skeleton = skeletonList?.find((skeletonItem) => {

		const isThisOne = (
			skeletonItem.model?.toLowerCase() === model?.toLowerCase() &&
			skeletonItem.engineType?.toLowerCase() === engineType?.toLowerCase()
		)

		return isThisOne
	})

	if (skeleton) {
		return (
			<div style={{ paddingBottom: '-10px' }}>
				{/* <pre>
					{JSON.stringify(props,null,4)}
				</pre> */}
				<ACTemplate
					// 	itemsData,
					// onClickItem,
					{...props}
					translateCoord={{
						translateX: 0,
						translateY: -180,
					}}
					isGeneralized={true}
					// skeleton={<A320CeoSkeleton />}
					skeleton={skeleton?.skeletonComponent}
					boxesConfig={generalizedBoxes}
					seatsConfig={skeleton?.seatConfig}
					filters={<g><FilterDefsA320Ceo /><filter id='boxshadow' color-interpolation-filters="sRGB"><feDropShadow dx="-1.5" dy="1.5" stdDeviation="4" flood-opacity="0.1" /></filter></g>}
					categories={generalizedCategories}
				/>
			</div>
		)
	} else {
		return (
			<div style={{
				width: '100%',
				height: '100%',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				padding: '50px',
			}} >
				<Typography fontStyle="italic">
					No available preview for this model {model} {fleetNode?.engineType ? `(${fleetNode?.engineType})` : ''}
				</Typography>
			</div>
		);
	}


	// if (isEmpty(foundModelTemplate)) {
	// 	return (
	// 		<Typography fontStyle="italic">
	// 			No available preview for this model {model} {fleetNode?.engineType ? `(${fleetNode?.engineType})` : ''}
	// 		</Typography>
	// 	);
	// } else {
	// 	const ModelTemplated = foundModelTemplate?.lopa ?? ((args) => <div></div>);
	// 	return (
	// 		<div style={{ paddingBottom: '50px' }}>
	// 			<ModelTemplated
	// 				fleetId={fleetId}
	// 				itemsData={itemsData}
	// 				onClickItem={onClickItem}
	// 			/>
	// 		</div>
	// 	);
	// }
}

// function getModelTemplate({
// 	model,
// 	fleetNode,
// 	seatCount,
// }) {

// 	// A320Ceo typeA has 180 seats but A320Ceo typeB (new gen) has 186 seats
// 	if (model?.toLowerCase() === 'a320' && fleetNode?.engineType?.toLowerCase() === 'ceo') {
// 		if (seatCount && seatCount === 186) {
// 			return availableModelTemplates.find(({ model, engineType }) => {
// 				return model.toLocaleLowerCase() === 'a320' && engineType.toLocaleLowerCase() === 'neo'
// 			})
// 		} else {
// 			return availableModelTemplates.find(({ model, engineType }) => {
// 				return model.toLocaleLowerCase() === 'a320' && engineType.toLocaleLowerCase() === 'ceo'
// 			})
// 		}
// 	}

// 	// others
// 	return availableModelTemplates.find(({
// 		model: availableModel,
// 		engineType: availableEngineType,
// 	}) => {
// 		return (
// 			availableModel?.toLowerCase() === model?.toLowerCase() &&
// 			fleetNode?.engineType?.toLowerCase() === availableEngineType?.toLowerCase()
// 		)
// 	});
// }

// export const availableModelTemplates = [
// 	{
// 		engineType: 'CEO',
// 		model: "A320",
// 		lopa: (props) => (
// 			<ACTemplate
// 				// 	itemsData,
// 				// onClickItem,
// 				{...props}
// 				skeleton={<A320CeoSkeleton />}
// 				boxesConfig={a320CeoBoxConfigs}
// 				seatsConfig={seats180}
// 				filters={<g><FilterDefsA320Ceo /><filter id='boxshadow' color-interpolation-filters="sRGB"><feDropShadow dx="-1.5" dy="1.5" stdDeviation="4" flood-opacity="0.1" /></filter></g>}
// 				categories={inventoryLocationCategoriesA320Ceo}
// 			/>
// 		),
// 	},
// 	{
// 		engineType: 'NEO',
// 		model: "A320",
// 		lopa: (props) => (
// 			<ACTemplate
// 				{...props}
// 				skeleton={<A320NeoSkeleton />}
// 				boxesConfig={a320NeoBoxConfigs}
// 				seatsConfig={seats186}
// 				filters={<g><FilterDefsA320Neo /><filter id='boxshadow' color-interpolation-filters="sRGB"><feDropShadow dx="-1.5" dy="1.5" stdDeviation="4" flood-opacity="0.1" /></filter></g>}
// 				categories={inventoryLocationCategoriesA320Neo}
// 			/>
// 		),
// 	},
// ];

/**
 * @typedef {Object} SkeletonListItem
 * @property {string} model
 * @property {number} seat
 * @property {string} engineType
 * @property {any} seatConfig
 * @property {React.ReactNode} skeletonComponent
 */
/**
 * @type {SkeletonListItem[]} skeletonList
 */
const skeletonList = [
	{
		model: 'A320',
		seat: 180,
		engineType: 'CEO',
		seatConfig: seats180,
		skeletonComponent: <A320CeoSkeleton />,
	},
	{
		model: 'A320',
		seat: 186,
		engineType: 'CEO',
		seatConfig: seats186,
		skeletonComponent: <A320NeoSkeleton />,
	},
	{
		model: 'A320',
		seat: 186,
		engineType: 'NEO',
		seatConfig: seats186,
		skeletonComponent: <A320NeoSkeleton />,
	}
]