import React from "react";
import { Grid } from "@mui/material";
import styles from "../Dasr.module.css";

const DDMLSummary = ({ data, aoc, loading }) => {
  const STN = ["BWN"];

  function calcByLocation(loc, category) {
    if (category !== "TOTAL") {
      let Obj = data.find((obj) => obj.hasOwnProperty(category));
      let count = 0;
      if (Obj) {
        let ObjCount = Obj[category].find((obj) => obj.location === loc);
        if (ObjCount) {
          count = ObjCount.count;
        }
      }
      return count;
    } else {
      let totalCount = 0;
      data.forEach((obj) => {
        Object.values(obj).forEach((subArray) => {
          subArray.forEach((entry) => {
            if (entry.location === loc) {
              totalCount += entry.count;
            }
          });
        });
      });

      let totalCountHydraulicSampling = 0;
      let totalCountFuelSampling = 0;

      data.forEach((obj) => {
        if (obj.hasOwnProperty("Hydraulic Sampling")) {
          obj["Hydraulic Sampling"].forEach((entry) => {
            if (entry.location === loc) {
              totalCountHydraulicSampling += entry.count;
            }
          });
        }
      });

      data.forEach((obj) => {
        if (obj.hasOwnProperty("Fuel Sampling")) {
          obj["Fuel Sampling"].forEach((entry) => {
            if (entry.location === loc) {
              totalCountFuelSampling += entry.count;
            }
          });
        }
      });

      return totalCount - totalCountHydraulicSampling - totalCountFuelSampling;
    }
  }

  function calcByCategory(category) {
    if (category !== "TOTAL") {
      let totalCount = 0;
      data.forEach((obj) => {
        if (obj.hasOwnProperty(category)) {
          obj[category].forEach((entry) => {
            if (entry.location !== null) {
              totalCount += entry.count;
            }
          });
        }
      });
      return totalCount;
    } else {
      let totalCountNonNull = 0;

      data.forEach((obj) => {
        Object.values(obj).forEach((subArray) => {
          subArray.forEach((entry) => {
            if (entry.location !== null) {
              totalCountNonNull += entry.count;
            }
          });
        });
      });

      let totalCountHydraulicSampling = 0;
      let totalCountFuelSampling = 0;

      data.forEach((obj) => {
        if (obj.hasOwnProperty("Hydraulic Sampling")) {
          obj["Hydraulic Sampling"].forEach((entry) => {
            if (entry.location !== null) {
              totalCountHydraulicSampling += entry.count;
            }
          });
        }
      });

      data.forEach((obj) => {
        if (obj.hasOwnProperty("Fuel Sampling")) {
          obj["Fuel Sampling"].forEach((entry) => {
            if (entry.location !== null) {
              totalCountFuelSampling += entry.count;
            }
          });
        }
      });

      return totalCountNonNull - totalCountHydraulicSampling - totalCountFuelSampling;
    }
  }

  return data ? (
    <>
      <div className={styles.divCard}>
        <Grid container columns={2}>
          <Grid item xs={1} className={styles.gridTitle} style={{ paddingBottom: "24px" }}>
            DDML Summary
          </Grid>
          <Grid item xs={2}>
            {loading ? (
              <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>Loading...</div>
            ) : (
              <div className={styles.tableDiv}>
                <Grid container columns={4}>
                  <Grid item xs={1} className={styles.tableHeaderGradient}>
                    CAT
                  </Grid>
                  <Grid item xs={1} className={styles.tableHeaderGradient}>
                    AMD
                  </Grid>
                  <Grid item xs={1} className={styles.tableHeaderGradient}>
                    STR/FC/FH
                  </Grid>
                  <Grid item xs={1} className={styles.tableHeaderGradient}>
                    TOTAL
                  </Grid>
                  <Grid container columns={4}>
                    {STN.map((e, index) => {
                      return (
                        <>
                          <Grid item xs={1} className={`${styles.tableBody}`} key={index + 1}>
                            {calcByLocation(e, "MEL CAT") || 0}
                          </Grid>
                          <Grid item xs={1} className={`${styles.tableBody}`} key={index + 2}>
                            {calcByLocation(e, "AMD") || 0}
                          </Grid>
                          <Grid item xs={1} className={`${styles.tableBody}`} key={index + 3}>
                            {calcByLocation(e, "STR/FC/FH") || 0}
                          </Grid>
                          <Grid item xs={1} className={`${styles.tableBody}`} key={index + 4}>
                            {calcByLocation(e, "TOTAL") || 0}
                          </Grid>
                        </>
                      );
                    })}
                  </Grid>
                </Grid>
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    </>
  ) : (
    ""
  );
};

export default DDMLSummary;
