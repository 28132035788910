import SidebarElevadeDashboardIcon from "common/images/icons/elevade/SidebarElevadeDashboard-Icon.svg";
import SidebarElevadeFleetIcon from "common/images/icons/elevade/SidebarElevadeFleet-Icon.svg";
import SidebarElevadePeopleIcon from "common/images/icons/elevade/SidebarElevadePeople-Icon.svg";
import SidebarElevadeEducadeIcon from "common/images/icons/elevade/SidebarElevadeEducade-Icon.svg";
import SidebarElevadeDocumentIcon from "common/images/icons/elevade/SidebarElevadeDocument-Icon.svg";
import SidebarEntityIcon from "common/images/icons/elevade/SidebarEntity-Icon.svg";
import SidebarSettingIcon from "common/images/icons/elevade/SidebarSetting-Icon.svg";
import SidebarElevadeFleetHightlightIcon from "common/images/icons/elevade/SidebarElevadeFleetHighlightIcon.svg";

import {
  home,
  homeActive,
  fleetMonitoring,
  prescreptiveMaintenance,
  inbox,
  scheduleTask,
  fleetSchedule,
  repetitiveDefect,
  workOrder,
  cabinMonitoring,
  inspectionReport,
  equipmentManagement,
  radar,
  DASR,
  predefinedDashboard,
  customReport,
  warningManagement,
  warningManagementAircraft,
  warningManagementEmail,
  aircraftList,
  aircraftReports,
  assigment,
  assignmentDraft,
  assignmentTask,
  assignmentReport,
  closedReports,
  dasrOverview,
  aircraftListActive,
  aircraftReportsActive,
  cabinMonitoringActive,
  closedReportsActive,
  customReportActive,
  DASRActive,
  dasrOverviewActive,
  fleetMonitoringActive,
  fleetScheduleActive,
  inboxActive,
  predefinedDashboardActive,
  prescreptiveMaintenanceActive,
  radarActive,
  repetitiveDefectActive,
  scheduledTaskActive,
  warningManagementActive,
  workOrderActive,
  inspectionReportActive,
  equipmentManagementActive,
  archiveIcon,
  archiveIconActive,
} from "common/images/icons/elevade/fleetSidebar";

export const ModuleMenu = [
  {
    label: "DASHBOARD",
    url: `${window.location.origin}/main`,
    icon: SidebarElevadeDashboardIcon,
  },
  {
    label: "divider",
    isDivider: true,
  },
  {
    label: "FLEET",
    url: "#",
    icon: SidebarElevadeFleetHightlightIcon,
  },
  {
    label: "PEOPLE",
    url: `${window.location.origin}/people`,
    icon: SidebarElevadePeopleIcon,
  },
  // {
  //   label: "LEARNING",
  //   url: "https://lms-client-stg-uuf4aplkpa-as.a.run.app/",
  //   icon: SidebarElevadeEducadeIcon,
  // },
  // {
  //   label: "DOCUMENT",
  //   url: "https://airasia-stg.elevade.io/document",
  //   icon: SidebarElevadeDocumentIcon,
  // },
  {
    label: "ENTITY",
    url: `${window.location.origin}/people`,
    icon: SidebarEntityIcon,
  },
  // {
  //   label: "divider",
  //   isDivider: true,
  // },
  // {
  //   label: "SETTINGS",
  //   url: "https://airasia-stg.elevade.io/main",
  //   icon: SidebarSettingIcon,
  // },
];

export const shrinkFleetMenu = [
  {
    label: "home",
    icon: home,
    activeIcon: homeActive,
    disable: false,
    url: "/fleet/home",
    visible: true,
  },
  {
    label: "seperator",
  },
  {
    label: "fleetMonitoring",
    icon: fleetMonitoring,
    activeIcon: fleetMonitoringActive,
    disable: false,
    url: "/fleet/aircraft-monitoring",
    visible: true,
    feature: "isAircraftMonitoring",
  },
  {
    label: "prescreptiveMaintenance",
    icon: prescreptiveMaintenance,
    activeIcon: prescreptiveMaintenanceActive,
    disable: true,
    visible: false,
  },
  {
    label: "inbox",
    icon: inbox,
    activeIcon: inboxActive,
    disable: false,
    url: "/fleet/inbox",
    visible: true,
    feature: "isInbox",
  },
  {
    label: "fleetSchedule",
    icon: fleetSchedule,
    activeIcon: fleetScheduleActive,
    disable: true,
    visible: false,
  },
  {
    label: "repetitiveDefect",
    icon: repetitiveDefect,
    activeIcon: repetitiveDefectActive,
    disable: true,
    url: "/fleet/repetitive-defect-database",
    visible: true,
    feature: "isRepetitiveDefect",
  },
  {
    label: "seperator",
  },
  {
    label: "predefinedDashboard",
    icon: predefinedDashboard,
    activeIcon: predefinedDashboardActive,
    disable: false,
    url: "/fleet/analytics",
    visible: true,
    feature: "isAnalytics",
  },
  {
    label: "customReport",
    icon: customReport,
    activeIcon: customReportActive,
    disable: true,
    visible: true,
  },
  {
    label: "seperator",
  },
  {
    label: "DASR",
    icon: dasrOverview,
    activeIcon: dasrOverviewActive,
    disable: false,
    url: "/fleet/dasr",
    visible: true,
    feature: "isDASR",
  },
  {
    label: "Aircraft List",
    icon: aircraftList,
    activeIcon: aircraftListActive,
    disable: false,
    visible: true,
    url: "/fleet/dasr/aircraft-list",
    feature: "isDASR",
  },
  {
    label: "Aircraft Reports",
    icon: aircraftReports,
    activeIcon: aircraftReportsActive,
    disable: false,
    visible: true,
    url: "/fleet/dasr/aircraft-report",
    feature: "isDASR",
  },
  {
    label: "Closed Reports",
    icon: closedReports,
    activeIcon: closedReportsActive,
    disable: false,
    visible: true,
    url: "/fleet/dasr/aircraft-report-closed",
    feature: "isDASR",
  },
  {
    label: "scheduleTask",
    icon: scheduleTask,
    activeIcon: scheduledTaskActive,
    disable: false,
    url: "/fleet/schedule-task",
    visible: true,
    feature: "isScheduledCheck",
  },
  {
    label: "Assignment",
    icon: assigment,
    disable: false,
    visible: true,
  },
  {
    label: "seperator",
  },
  // {
  //   label: "DASR",
  //   icon: DASR,
  //   activeIcon: DASRActive,
  //   disable: false,
  //   url: "/fleet/dasr",
  //   visible: true,
  // },
  // {
  //   label: "Aircraft List",
  //   icon: aircraftList,
  //   activeIcon: aircraftListActive,
  //   disable: false,
  //   visible: true,
  //   url: "/fleet/dasr/aircraft-list",
  // },
  // {
  //   label: "Aircraft Reports",
  //   icon: aircraftReports,
  //   activeIcon: aircraftReportsActive,
  //   disable: false,
  //   visible: true,
  //   url: "/fleet/dasr/aircraft-report",
  // },
  // {
  //   label: "Closed Reports",
  //   icon: closedReports,
  //   activeIcon: closedReportsActive,
  //   disable: false,
  //   visible: true,
  //   url: "/fleet/dasr/aircraft-report-closed",
  // },
  // {
  //   label: "Assignment",
  //   icon: assigment,
  //   disable: false,
  //   visible: true,
  // },
  // {
  //   label: "seperator"
  // },
  {
    label: "workOrder",
    icon: workOrder,
    activeIcon: workOrderActive,
    disable: true,
    visible: false,
  },
  {
    label: "cabinMonitoring",
    icon: cabinMonitoring,
    activeIcon: cabinMonitoringActive,
    disable: true,
    visible: true,
  },
  {
    label: "seperator",
  },
  {
    label: "radar",
    icon: radar,
    activeIcon: radarActive,
    disable: true,
    visible: true,
  },
  {
    label: "warningManagement",
    icon: warningManagement,
    activeIcon: warningManagementActive,
    disable: false,
    visible: true,
  },
];

export const expandFleetMenu = [
  {
    label: "Home",
    icon: home,
    disable: false,
    visible: true,
    url: "/fleet/home",
  },
  {
    label: "MONITORING",
    children: [
      {
        label: "Aircraft Monitoring",
        icon: fleetMonitoring,
        disable: false,
        visible: true,
        url: "/fleet/aircraft-monitoring",
        dropdown: false,
        module: "MONITORING",
        feature: "isAircraftMonitoring",
      },
      {
        label: "Prescreptive Maintenance",
        icon: prescreptiveMaintenance,
        disable: true,
        visible: false,
        dropdown: false,
        module: "MONITORING",
      },
      {
        label: "Inbox",
        icon: inbox,
        disable: false,
        visible: true,
        url: "/fleet/inbox",
        dropdown: false,
        module: "MONITORING",
        feature: "isInbox",
      },
      {
        label: "Fleet Schedule",
        icon: fleetSchedule,
        disable: true,
        visible: false,
        dropdown: false,
        module: "MONITORING",
      },
      // {
      //   label: "Fleet Schedule",
      //   icon: fleetSchedule,
      //   disable: true,
      //   visible: false,
      //   dropdown: false,
      //   module: "MONITORING"
      // },
      {
        label: "Repetitive Defect",
        icon: repetitiveDefect,
        disable: true,
        visible: true,
        url: "/fleet/repetitive-defect-database",
        dropdown: false,
        module: "MONITORING",
        feature: "isRepetitiveDefect",
      },
      {
        label: "Work Order",
        icon: workOrder,
        disable: true,
        visible: false,
        dropdown: false,
        module: "MONITORING",
      },
      // {
      //   label: "Work Order",
      //   icon: workOrder,
      //   disable: true,
      //   visible: false,
      //   dropdown: false,
      //   module: "MONITORING"
      // },
      // {
      //   label: "Cabin Monitoring",
      //   icon: cabinMonitoring,
      //   disable: true,
      //   visible: true,
      //   dropdown: true,
      //   module: "MONITORING",
      //   children: [
      //     {
      //       label: "Overview",
      //       disable: true,
      //       dropdown: false,
      //       module: "MONITORING"
      //     },
      //     {
      //       label: "Inspection Reports",
      //       disable: true,
      //       dropdown: false,
      //       module: "MONITORING"
      //     },
      //     {
      //       label: "Equipment Management",
      //       disable: true,
      //       dropdown: false,
      //       module: "MONITORING"
      //     },
      //   ],
      // },
      {
        label: "Radar",
        icon: radar,
        disable: true,
        visible: false,
        dropdown: false,
        module: "MONITORING",
      },
    ],
  },
  {
    label: "ANALYTICS",
    children: [
      {
        label: "Predefined Dashboard",
        icon: predefinedDashboard,
        disable: false,
        visible: true,
        url: "/fleet/analytics",
        dropdown: false,
        module: "ANALYTICS",
        feature: "isAnalytics",
      },
      {
        label: "Custom Report",
        icon: customReport,
        disable: true,
        visible: true,
        dropdown: false,
        module: "ANALYTICS",
      },
    ],
  },
  {
    label: "DASR",
    children: [
      {
        label: "DASR Overview",
        icon: dasrOverview,
        disable: false,
        visible: true,
        url: "/fleet/dasr",
        dropdown: false,
        module: "DASR",
        feature: "isDASR",
      },
      {
        label: "Aircraft List",
        icon: aircraftList,
        disable: false,
        visible: true,
        url: "/fleet/dasr/aircraft-list",
        dropdown: false,
        module: "DASR",
        feature: "isDASR",
      },
      {
        label: "Maintenance Status",
        icon: aircraftReports,
        disable: false,
        visible: true,
        url: "/fleet/dasr/aircraft-status",
        dropdown: false,
        module: "DASR",
        feature: "isDASR",
      },
      {
        label: "DDML Reports",
        icon: aircraftReports,
        disable: false,
        visible: true,
        url: "/fleet/dasr/aircraft-report",
        dropdown: false,
        module: "DASR",
        feature: "isDASR",
      },
      {
        label: "Closed Reports",
        icon: closedReports,
        disable: false,
        visible: true,
        url: "/fleet/dasr/aircraft-report-closed",
        dropdown: false,
        module: "DASR",
        feature: "isDASR",
      },
      {
        label: "Archive Records",
        icon: archiveIcon,
        disable: false,
        visible: true,
        url: "/fleet/dasr/aircraft-report-archive",
        dropdown: false,
        module: "DASR",
        feature: "isDASR",
      },

      {
        label: "Schedule Task",
        icon: scheduleTask,
        disable: true,
        visible: true,
        url: "/fleet/schedule-task",
        dropdown: false,
        module: "MONITORING",
        feature: "isScheduledCheck",
      },
      {
        label: "Assignment",
        icon: assigment,
        disable: false,
        visible: true,
        url: "#",
        dropdown: true,
        module: "DASR",
        children: [
          {
            label: "Assignment Drafts",
            icon: assignmentDraft,
            disable: false,
            url: "/fleet/dasr/assignment",
            dropdown: false,
            module: "DASR",
            feature: "isDASR",
          },
          {
            label: "Task Assignment",
            icon: assignmentTask,
            disable: false,
            url: "/fleet/dasr/task-assignment",
            dropdown: false,
            module: "DASR",
            feature: "isDASR",
          },
          {
            label: "Assignment Report",
            icon: assignmentReport,
            disable: false,
            url: "/fleet/dasr/assignment-report",
            dropdown: false,
            module: "DASR",
            feature: "isDASR",
          },
        ],
      },
    ],
  },
  {
    label: "CABIN MONITORING",
    children: [
      {
        label: "Overview",
        icon: cabinMonitoring,
        disable: true,
        visible: true,
        module: "CABIN MONITORING",
        feature: "isCabinMonitoring",
      },
      {
        label: "Inspection Reports",
        icon: inspectionReport,
        disable: true,
        visible: true,
        module: "CABIN MONITORING",
        feature: "isCabinMonitoring",
      },
      {
        label: "Equipment Manegement",
        icon: equipmentManagement,
        disable: true,
        visible: true,
        module: "CABIN MONITORING",
        feature: "isCabinMonitoring",
      },
    ],
  },

  {
    label: "MODULE SETTINGS",
    children: [
      {
        label: "Warning Management",
        icon: warningManagement,
        disable: false,
        visible: true,
        dropdown: true,
        module: "MODULE SETTINGS",
        children: [
          {
            label: "A320 Family",
            icon: warningManagementAircraft,
            disable: false,
            url: "/fleet/warning-message-management/A320",
            dropdown: false,
            module: "MODULE SETTINGS",
            feature: "isModuleSettings",
          },
          {
            label: "B788 Family",
            icon: warningManagementAircraft,
            disable: false,
            url: "/fleet/warning-message-management/B788",
            dropdown: false,
            module: "MODULE SETTINGS",
            feature: "isModuleSettings",
          },
          {
            label: "Manuals Collection",
            icon: warningManagementAircraft,
            disable: false,
            url: "/fleet/manuals-collection",
            dropdown: false,
            module: "MODULE SETTINGS",
            feature: "isModuleSettings",
          },
          {
            label: "Email Management",
            icon: warningManagementEmail,
            disable: false,
            url: "/fleet/email-alert-management",
            dropdown: false,
            module: "MODULE SETTINGS",
            feature: "isModuleSettings",
          },
        ],
      },
    ],
  },
];
