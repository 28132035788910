import { env_var_REACT_APP_API_URL, env_var_REACT_APP_API_URL_ADV } from "../../envVar/vars";
import useGetGQL from "../useGetGQL";
import useMutateGQL from "../useMutateGQL";
import { useAppSelector } from "../../app/hooks";
import { generateGQLQuery } from "./utils";

/**
 * @description Status : migrated!
 */
export function useGetAllLocations(props) {

	const { regNo } = props ?? {}

	const dataAdv = useGetGQL({
		gqlquery: generateGQLQuery({
			gqlStringQueryType: 'query',
			queryUnsignificantName: 'allLocations',
			querySign: 'allLocations',
			inputList: [
				{
					name: "limit",
					value: 300,
					type: 'number',
				},
				{
					name: "aircraftRegNo",
					value: regNo,
					type: 'string',
				},
			],
			outputList: {
				totalFilters: true,
				items: {
					_id: true,
					seatNo: true,
					fleet: {
						regNo: true,
					},
					locationType: {
						_id: true,
						name: true,
						code: true,
					},
					inventory: {
						_id: true,
						aocCode: true,
						tag: true,
						serialNo: true,
						manufactureDate: true,
						expiryDate: true,
						createdAt: true,
						createdBy: true,
						updatedAt: true,
						updatedBy: true,
						type: {
							_id: true,
							name: true,
							code: true,
						},
						status: {
							_id: true,
							name: true,
							code: true,
						},
					},
				},
			}
		}),
		isEdges: false,
		querySign: 'allLocations',
		url: env_var_REACT_APP_API_URL_ADV ?? "",
		totalCountFilteredSign: 'totalFilters',
	})

	// const data = useGetGQL({
	// 	gqlquery: `
	// query allLocationList{
	//   allLocationLists(
	// 	fleetId:"${fleetId}",
	//  	${locationId ? `locationId:"${locationId}"` : ""} 
	//   ){
	//     edgeCount
	// 	totalCount
	// 	edges {
	// 		node {
	// 			id
	// 			inventory {
	// 			id
	// 			tag
	// 			serialNo
	// 			expiryDate
	// 			manufactureDate
	// 			status {
	// 				id
	// 				code
	// 				name
	// 			}
	// 			item {
	// 				id
	// 				code
	// 				name
	// 			}
	// 			}
	// 			locationId {
	// 			id
	// 			seatNo
	// 			inventoryLocation {
	// 				id
	// 				name
	// 				code
	// 			}
	// 			}
	// 		}
	// 	}
	//   }
	// }    
	//     `,
	// 	querySign: "allLocationLists",
	// 	url: env_var_REACT_APP_API_URL ?? "",
	// 	defaultIsLoading: true,
	// 	isEdges: false,
	// });

	return dataAdv;
}


export function useUpdateLocation({
	inFleetLocationId,
	changedStatus,
	allStatuses,
}) {

	const statusIdFromCode = allStatuses.find(({ id, name, code }) => {
		return (code === changedStatus)
	})?.id

	const { userProfile } = useAppSelector((state) => state.userEntity);
	const fullName = userProfile?.fullName;
	const photo = userProfile?.photo;

	const data = useMutateGQL({
		gqlquery: () => {
			// const dateFormattedmfgDate: string | undefined = mfgDate ? `${new Date(mfgDate).getFullYear()}-${getMonth2D(new Date(mfgDate))}-${getDate2D(new Date(mfgDate))}` : undefined
			// const dateFormattedexpDate: string | undefined = expDate ? `${new Date(expDate).getFullYear()}-${getMonth2D(new Date(expDate))}-${getDate2D(new Date(expDate))}` : undefined
			return `
              mutation updateEQ {
                updateLocationList (input:{
                  id:"${inFleetLocationId ?? ""}"
                  ${changedStatus ? `status:"${statusIdFromCode}"` : ''}
                  ${photo ? `userAvatar:"${photo}"` : ''}
                  userName:"${fullName}"
                }){
                locationList {
                    id
                  }
                }
              }
            `;
		},
		url: env_var_REACT_APP_API_URL ?? "",
	});

	return data
}