import React from "react";
import { Grid } from "@mui/material";
import IconClose from "common/images/icons/elevade/Icon-Close.svg";
import styles from "../aircraftReport/DDML.module.css";
import { useState, useRef, useEffect } from "react";
import {
  InputField,
  InputFieldCalendar,
  SelectField,
  SelectFieldAutocomplete,
} from "pages/elevade/component/InputForm";
import { AdeButton } from "common/components";
import { useSelector } from "react-redux";
import { makeAPIRequestMDB } from "common/helpers/makeAPIRequestMDB";
import { showNotificationElevade } from "common/helpers";
import { useLocation } from "react-router-dom";

const AdditionalTaskForm = ({ data, edit, open, setOpen, newForm, ...props }) => {
  const { currentUser } = useSelector((state) => state.authEntity);
  const [formError, setFormError] = useState([]);
  const tabRef = useRef(null);
  const [aircraftList, setAircraftList] = useState([]);
  const location = useLocation();
  const isTaskAssignmentPage = location.pathname.includes("/task-assignment");
  const InitialData = {
    formStatus: "open",
    aircraftReg: "",
    aoc: props.aoc,
    location: "",
    raisedDate: null,
    ets: null,
    defectDesc: "",
    status: "",
    rectificationStatus: "",
    remarks: "",
    createdBy: currentUser.userName,
  };
  const [formData, setFormData] = useState([
    newForm
      ? InitialData
      : isTaskAssignmentPage
      ? props.dataForm
      : props.dataForm.ddml
      ? props.dataForm.ddml
      : props.dataForm
      ? props.dataForm
      : data,
  ]);
  const [id, setId] = useState(
    isTaskAssignmentPage
      ? props.dataForm.id
      : props.dataForm.ddml
      ? props.dataForm.ddml?._id
      : props.dataForm._id
      ? props.dataForm._id
      : data._id
  );

  useEffect(() => {
    setFormError([]);
    FetchData();
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    let array = aircraftList.filter((e) => e.name === formData[0].aircraftReg);
    if (array.length > 0) setFormData(updateArrayColumn("location", array[0]?.location));
  }, [formData[0].aircraftReg]);

  const DDMLTitle = () => {
    return (
      <>
        <span
          style={{
            border: "1px solid #C3C3C3",
            borderRadius: "6px",
            padding: "4px 8px",
            marginRight: "8px",
            whiteSpace: "nowrap",
            marginLeft: "8px",
          }}
        >
          {props.dataForm.ddml ? props.dataForm.ddml?.aircraftReg : props.dataForm.aircraftReg}
        </span>
        {/* <span>{props.dataForm.ddml?.ddmlNo}</span> */}
      </>
    );
  };

  function updateArrayColumn(column, value) {
    return formData.map((item) => {
      if (column === "attachment") {
        return {
          ...item,
          [column]: value,
        };
      } else if (column === "raisedDate") {
        return { ...item, [column]: value + "T00:00:00.000+00:00" };
      }
      // Check if the item has the specified column and update it if exists
      else if (item.hasOwnProperty(column)) {
        return { ...item, [column]: value }; // Create a new object with updated column
      } else return { ...item, [column]: value }; // If the specified column doesn't exist, return the original item unchanged
    });
  }

  function removeFromArray(col) {
    const indexToRemove = formError.indexOf(col);
    if (indexToRemove !== -1) {
      formError.splice(indexToRemove, 1);
    }
  }

  async function FetchData() {
    var currentDate = new Date();
    var options = { month: "long", day: "2-digit", year: "numeric" };
    var formattedDate = currentDate.toLocaleDateString("en-US", options);
    currentDate = new Date(formattedDate);
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const isToday = `${year}-${month}-${day}`;
    if (isToday !== "NaN-NaN-NaN") {
      const fetch = makeAPIRequestMDB(`get`, `api/aircraft/daily-status?aoc=${props.aoc}&date=${isToday}`);
      fetch
        .then((response) => {
          let theList = [];
          // Sort the list alphabetically
          let list = response.data?.sort((a, b) => a.aircraftReg?.localeCompare(b.aircraftReg));
          // TODO: to check back on this later
          // eslint-disable-next-line no-unused-expressions
          list?.map((e) => {
            // Create an object to hold the list, and push it onto "theList" object
            return theList.push({ code: e.aircraftReg, name: e.aircraftReg, location: e.status.hub });
          });
          // Set the object to the state
          setAircraftList(theList);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  }

  function handleSubmit() {
    const params = `api/assignment/adhoc`;
    async function CreateData() {
      const fetch = makeAPIRequestMDB(`post`, `${params}`, formData[0]);
      fetch
        .then((response) => {
          if (response.detail.includes("success")) {
            showNotificationElevade("success", "New Additional Task successfully created!", [
              "Your successfully created new report",
            ]);
            setOpen(false);
            props.setTriggerApi(true);
          } else {
            showNotificationElevade("danger", "An error occured!", ["Please contact your system administartor"]);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    if (validateForm()) CreateData();
  }

  function handleUpdate() {
    const formData_ = formData[0];
    delete formData_.updatedAt;
    delete formData_.updatedBy;
    const params = `api/assignment/adhoc/${id}`;
    async function UpdateData() {
      const fetch = makeAPIRequestMDB(`put`, `${params}`, formData_);
      fetch
        .then((response) => {
          if (response.detail.includes("success")) {
            props.setTriggerApi(true);
            showNotificationElevade("success", "DDML successfully updated!", ["You successfully saved an update"]);
          } else {
            showNotificationElevade("danger", "An error occured!", ["Please contact your system administartor"]);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    if (validateForm()) UpdateData();
  }

  function validateForm() {
    // Set mandatory fields
    let array = [];
    // All forms
    if (formData[0].aircraftReg === "") array.push("aircraftReg");
    if (formData[0].location === "") array.push("location");
    if (formData[0].raisedDate === "") array.push("raisedDate");
    if (formData[0].ets === "") array.push("ets");
    if (formData[0].defectDesc === "") array.push("defectDesc");
    if (formData[0].status === "") array.push("status");
    if (formData[0].rectificationStatus === "") array.push("rectificationStatus");

    setFormError(array);
    if (array.length === 0) return true;
    else return false;
  }

  function validateError(col) {
    return formError.includes(col);
  }

  return (
    <>
      <Grid
        container
        columns={4}
        sx={{
          position: "fixed",
          zIndex: 9,
          borderBottom: "1px solid #c3c3c3",
          backgroundColor: "#ffffff",
          top: "60px",
        }}
      >
        <Grid item xs={3} sx={{ padding: "24px" }} className={styles.title} ref={tabRef}>
          {newForm ? "Create Adhoc Task" : "Task Details"}
          {!newForm && <DDMLTitle />}
        </Grid>
        <Grid item xs={1} sx={{ display: "flex", justifyContent: "flex-end", padding: "24px" }}>
          <img
            src={IconClose}
            alt="icon-close"
            height={15}
            style={{ marginTop: "2px", cursor: "pointer" }}
            onClick={() => setOpen(false)}
          />
        </Grid>
      </Grid>
      <Grid container columns={4}>
        <Grid item xs={4} sx={{ paddingBottom: "100px" }}>
          <div
            style={{
              marginTop: isTaskAssignmentPage ? "120px" : "80px",
              position: "relative",
              marginLeft: "16px",
              marginRight: "16px",
              paddingRight: "32px",
              width: "100%",
              paddingBottom: "100px",
            }}
            className={styles.container}
          >
            <Grid container columns={4} id={"ddml-form"} sx={{ zIndex: 3 }}>
              <Grid item xs={2} className={`${styles.rowTitle} ${styles.rowInputStart}`}>
                Aircraft Registration<span className={styles.red}>*</span>
              </Grid>
              <Grid item xs={2} className={`${styles.rowTitle} ${styles.rowInputEnd}`}>
                Station<span className={styles.red}>*</span>
              </Grid>
              <Grid item xs={2} className={`${styles.rowInputStart}`}>
                <SelectFieldAutocomplete
                  placeholder="Select A/C"
                  fullWidth
                  options={aircraftList}
                  formData={formData}
                  col={"aircraftReg"}
                  onChange={(e) => {
                    setFormData(updateArrayColumn("aircraftReg", e.target.textContent || e));
                    removeFromArray("aircraftReg");
                  }}
                  value={formData[0].aircraftReg}
                  error={validateError("aircraftReg")}
                />
              </Grid>
              <Grid item xs={2} className={`${styles.rowInputEnd} ${styles.padTop12}`}>
                {formData[0].location}
              </Grid>
              <Grid item xs={2} className={`${styles.rowTitle} ${styles.rowInputStart}`}>
                Raised Date<span className={styles.red}>*</span>
              </Grid>
              <Grid item xs={2} className={`${styles.rowTitle} ${styles.rowInputEnd}`}>
                ETS<span className={styles.red}>*</span>
              </Grid>
              <Grid item xs={2} className={`${styles.rowInputStart}`}>
                <InputFieldCalendar
                  fullWidth
                  placeholder={"Select date"}
                  formData={formData}
                  setFormData={setFormData}
                  col={"raisedDate"}
                  error={validateError("raisedDate")}
                  disableFuture
                />
              </Grid>
              <Grid item xs={2} className={`${styles.rowInputEnd}`}>
                <InputFieldCalendar
                  fullWidth
                  placeholder={"Select date"}
                  formData={formData}
                  setFormData={setFormData}
                  col={"ets"}
                  error={validateError("ets")}
                  startDate={formData[0].raisedDate}
                />
              </Grid>
              <Grid item xs={4} className={`${styles.rowTitle} ${styles.rowInputStart}`}>
                Task Description<span className={styles.red}>*</span>
              </Grid>
              <Grid item xs={4} className={``}>
                <InputField
                  fullWidth
                  placeholder={"Insert text"}
                  formData={formData}
                  setFormData={setFormData}
                  col={"defectDesc"}
                  updateArrayColumn={updateArrayColumn}
                  error={validateError("defectDesc")}
                  multiline
                />
              </Grid>
              <Grid item xs={4} className={`${styles.rowTitle} ${styles.rowInputStart}`}>
                Status<span className={styles.red}>*</span>
              </Grid>
              <Grid item xs={4} className={``}>
                <InputField
                  fullWidth
                  placeholder={"Insert text"}
                  formData={formData}
                  setFormData={setFormData}
                  col={"status"}
                  updateArrayColumn={updateArrayColumn}
                  error={validateError("status")}
                  multiline
                />
              </Grid>
              <Grid item xs={4} className={`${styles.rowTitle} ${styles.rowInputStart}`}>
                Rectification Status<span className={styles.red}>*</span>
              </Grid>
              <Grid item xs={4} className={``}>
                <InputField
                  fullWidth
                  placeholder={"Insert text"}
                  formData={formData}
                  setFormData={setFormData}
                  col={"rectificationStatus"}
                  updateArrayColumn={updateArrayColumn}
                  error={validateError("rectificationStatus")}
                  multiline
                />
              </Grid>
              <Grid item xs={4} className={`${styles.rowTitle} ${styles.rowInputStart}`}>
                Remarks
              </Grid>
              <Grid item xs={4} className={``}>
                <InputField
                  fullWidth
                  placeholder={"Insert text"}
                  formData={formData}
                  setFormData={setFormData}
                  col={"remarks"}
                  updateArrayColumn={updateArrayColumn}
                  error={validateError("remarks")}
                  multiline
                />
              </Grid>
            </Grid>
          </div>
          <div
            style={{
              position: "fixed",
              bottom: "0",
              width: "50%",
              borderTop: "1px solid #D1D1D1",
              backgroundColor: "#ffffff",
              padding: "20px 24px 14px 24px",
              display: "flex",
              justifyContent: "flex-end",
              zIndex: 2,
            }}
          >
            <AdeButton
              variant="outlined"
              sx={{
                borderRadius: "8px",
                border: "0px solid #e8e8e8",
                fontFamily: "Gotham-Medium",
                pl: 6,
                pr: 6,
                mr: 4,
                "&.MuiButton-root:hover": { border: "transparent" },
              }}
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancel
            </AdeButton>
            <AdeButton
              variant="contained"
              sx={{
                borderRadius: "8px",
                fontFamily: "Gotham-Medium",
                pl: 6,
                pr: 6,
                "&.MuiButton-root": { border: "transparent" },
              }}
              onClick={!newForm ? handleUpdate : handleSubmit}
            >
              {newForm ? "Add Task" : "Save Changes"}
            </AdeButton>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default AdditionalTaskForm;
