import { Grid, Drawer, Tab, Button, Chip, Stack, Select, MenuItem, Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { styled } from "@mui/material/styles";
import { format } from "date-fns";
import styles from "../../dasr/aircraftReport/DDML.module.css";

import { makeAPIRequestMDB, makeAPIRequestRun } from "common/helpers";
import { dateFnsFormats } from "common/constant";
import CloseButton from "./CloseButton";
import { showNotificationElevade } from "common/helpers";
import DDMLUpdateLogs from "pages/elevade/dasr/aircraftReport/DDMLUpdateLogs";
import { AdeButton } from "common/components";

import ExternalLink from "common/images/icons/elevade/externalLink.svg";
import solidCloseIcon from "common/images/icons/elevade/solidCloseIcon.svg";
import SaveIcon from "common/images/icons/elevade/saveIcon.svg";
import IconErrorAirBalloon from "common/images/icons/elevade/Icon-ErrorAirBalloon.svg";

const EditDrawer = ({
  isDrawerOpen,
  setIsDrawerOpen,
  drawerDetail,
  setRepetitiveDefect,
  repetitiveDefect,
  activeTab,
  opts,
  newEntry,
  isLoading,
  setIsLoading,
  setCallApi,
  callApi,
  location,
  // repetitiveDefectDASR,
  setTriggerApi,
}) => {
  const { userName, userProfile } = useSelector((state) => state.userEntity);
  const scrollingDivRef = useRef(null);
  const [tabValue, setTabValue] = useState("1");
  const [isEdit, setIsEdit] = useState(false);
  const [flr, setFlr] = useState([]);
  const [removedFlr, setRemovedFlr] = useState([]);
  const [selectValue, setSelectValue] = useState();
  const [tsmValue, setTsmValue] = useState("");
  const [ecamWarning, setEcamWarning] = useState("");
  const [receivers, setReceivers] = useState([]);
  const [dasrRdd, setDasrRdd] = useState(false);

  useEffect(() => {
    if (repetitiveDefect) {
      let arr = repetitiveDefect.filter((obj) => drawerDetail?.acreg_msgid === obj.acreg_msgid && obj.correlated === 1);
      setFlr(arr);
    }
  }, [repetitiveDefect, drawerDetail]);

  useEffect(() => {
    if (isDrawerOpen === true) {
      // const inDasr = repetitiveDefectDASR.filter((e) => e.aircraftRegMsgId === drawerDetail?.acreg_msgid && e.formStatus === "open")
      // inDasr.length > 0 && setDasrRdd(true)
      if (drawerDetail?.dasr_assignment_status === "open") {
        setDasrRdd(true);
      } else {
        setDasrRdd(false);
      }
    } else {
      setDasrRdd(false);
    }
  }, [isDrawerOpen]);

  useEffect(() => {
    switch (activeTab) {
      case 1:
        setSelectValue(opts[0]?.value);
        break;
      case 2:
        setSelectValue(opts[1]?.value);
        break;
      case 3:
        setSelectValue(opts[2]?.value);
        break;
      default:
        setSelectValue(opts[0]?.value);
    }
  }, [opts, activeTab]);

  const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      // minWidth: "50%",
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: "rgba(0, 0, 0, 0.85)",
    fontFamily: ["Gotham-Book"].join(","),
    "&:hover": {
      color: "#333333",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#333333",
      fontFamily: "Gotham-Medium",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  }));

  const StyledButton = styled(Button)(() => ({
    height: "50px",
    // outline: "none",
    padding: "24px",
    textTransform: "none",
    // "&&:focus": {
    //   outline: "none",
    // },
    color: "#333",
    boxShadow: "none",
    borderRadius: "8px",
    border: "1px solid #C3C3C3",
    width: "148px",
    "&:hover": {
      backgroundColor: "#088FD1",
      color: "#FFFFFF",
    },
  }));

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function getAtaChapter(ata, report_type) {
    if (ata && report_type === "FAULT")
      return ata.substring(0, 2) + "-" + ata.substring(2, 4) + "-" + ata.substring(4, 6);
    else if (ata && report_type === "WARNING") return ata.substring(0, 2) + "-" + ata.substring(2, 4);
    else if (ata) return ata.substring(0, 2) + "-" + ata.substring(2, 4) + "-" + ata.substring(4, 6);
  }

  function getAircraftLocation(acreg) {
    const locationa = location.find((elem) => elem?.aircraftReg === acreg);
    return locationa?.status?.hub || "-";
  }

  const handleSubmit = async () => {
    setIsLoading(true);
    let combine = [];
    removedFlr.map((item, index) => {
      return (combine = [...combine, ...item]);
    });
    if (newEntry === true) {
      try {
        const body = {
          text: ecamWarning,
          defectStatus: selectValue,
          faultList: receivers,
          tsm: tsmValue,
          updated_at: new Date().toISOString(),
          email: userName,
          name: userProfile.fullName,
          dasr_assignment_status: "",
        };
        await makeAPIRequestRun("post", "acarsdata/repetitivedefect/new", body);
        showNotificationElevade("success", "", ["Success insert New Repetitive Defect"]);
        window.location.reload();
      } catch (error) {
        console.log("error new rdd", error);
      }
    } else {
      try {
        const body = {
          removedFlr: combine,
          defectStatus: selectValue,
          tsm: tsmValue,
          updated_at: new Date().toISOString(),
          email: userName,
          name: userProfile.fullName,
          acreg_msgid: drawerDetail.acreg_msgid,
        };
        const update = makeAPIRequestRun("post", "acarsdata/repetitivedefect/update", body);
        update.then((response) => {
          setIsLoading(false);
          setIsDrawerOpen(false);
          if (response.error) {
            setCallApi(!callApi);
            showNotificationElevade("danger", "Failed!", [response?.message]);
          } else {
            setCallApi(!callApi);
            showNotificationElevade("success", "Success", ["Success in Updating Repetitive Defect"]);
          }
        });
      } catch (error) {
        showNotificationElevade("danger", "Failed!", ["Failure updating Repetitive Defect"]);
      }
    }
  };

  function handleStatusChange(e) {
    setSelectValue(e?.target?.value);
  }

  function submitDisabled() {
    if (isEdit === true) {
      return false;
    } else {
      return true;
    }
  }

  function handleAssignmentSubmit() {
    let combine = [];
    removedFlr.map((item, index) => {
      return (combine = [...combine, ...item]);
    });
    const params = `api/assignmentRDD`;
    const data = {
      aircraftReg: drawerDetail?.ac_reg,
      text: drawerDetail?.text,
      ataChapter: drawerDetail?.ata_chapter,
      tsmRef: drawerDetail?.tsm_ref,
      department: "null",
      remark: "null",
      rectificationStatus: "null",
      correlatedWrnText: "null",
      correlated: drawerDetail?.correlated,
      email: userName,
      aoc: drawerDetail?.aoc,
      msgId: drawerDetail?.msgId,
      status: "open",
      aircraftRegMsgId: drawerDetail?.acreg_msgid,
      reportType: drawerDetail?.report_type,
      occurrence: drawerDetail?.occurence,
    };

    const body = {
      removedFlr: combine,
      defectStatus: selectValue,
      tsm: tsmValue,
      updated_at: new Date().toISOString(),
      email: userName,
      name: userProfile.fullName,
      acreg_msgid: drawerDetail.acreg_msgid,
      dasr_assignment_status: "open",
    };
    async function CreateData() {
      const fetch = makeAPIRequestMDB(`post`, `${params}`, data);

      fetch
        .then((response) => {
          if (response.detail.includes("success")) {
            setDasrRdd(true);
            setIsDrawerOpen(false);
            showNotificationElevade("success", "New Repetitive Defect successfully created!", [
              "Your successfully created new report",
            ]);
            setTriggerApi(true);
            // setOpen(false);
          } else {
            showNotificationElevade("danger", "An error occured!", ["Please contact your system administartor"]);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });

      const update = makeAPIRequestRun("post", "acarsdata/repetitivedefect/update", body);

      update
        .then((response) => {
          if (response.error) {
            // showNotificationElevade("danger", "", "Fail to update Repetitive Defect");
            showNotificationElevade("danger", "Failed!", [response?.message]);
          } else {
            showNotificationElevade("success", "Success", ["Success in Updating Repetitive Defect"]);
          }
          setIsLoading(true);
        })
        .catch((error) => {
          console.log("error update rdd", error);
          showNotificationElevade("danger", "Failed!", ["Failure updating Repetitive Defect"]);
        });
    }

    CreateData();
  }

  const Summary = () => {
    return (
      <>
        <Grid container columns={4}>
          <Grid item xs={2} style={{ marginBottom: "20px" }}>
            <span className="xsmall=font" style={{ color: "#838383" }}>
              Station
            </span>
          </Grid>
          <Grid item xs={2} style={{ marginBottom: "20px" }}>
            <span className="xsmall=font" style={{ color: "#838383" }}>
              A/C Registration
            </span>
            <br />
            <span
              className="medium-font"
              style={{ fontFamily: "Gotham-book" }}
              // onClick={() => {
              //   setIsEdit(true);
              // }}
            >
              {drawerDetail?.ac_reg}
            </span>
          </Grid>
          <Grid item xs={2}>
            <span className="xsmall=font" style={{ color: "#838383" }}>
              ATA Chapter
            </span>
            <br />
            <span className="medium-font" style={{ fontFamily: "Gotham-book" }}>
              {getAtaChapter(drawerDetail?.ata_chapter, drawerDetail?.report_type)}
            </span>
          </Grid>
          <Grid item xs={2}>
            <span className="xsmall=font" style={{ color: "#838383" }}>
              Flagged Date
            </span>
            <br />
            <span className="medium-font" style={{ fontFamily: "Gotham-book" }}>
              {drawerDetail ? format(new Date(drawerDetail?.occurence), dateFnsFormats.STANDARD_DATE) : ""}
            </span>
          </Grid>

          {/* Ecam Warning */}
          <Grid item xs={4}>
            <div className="xsmall-font gotham-book" style={{ color: "#838383", marginBottom: "12px" }}>
              ECAM Warning
            </div>
            <div
              style={{
                padding: "16px",
                borderRadius: "8px",
                backgroundColor: "#F7F7F7",
              }}
            >
              {drawerDetail?.text}
            </div>
          </Grid>

          {/* Fault Message */}
          <Grid item xs={4}>
            <div className="xsmall-font gotham-book" style={{ color: "#838383", marginBottom: "12px" }}>
              Fault Message
            </div>
            <div
              style={{
                padding: "14px 16px",
                borderRadius: "8px",
                backgroundColor: "#F7F7F7",
                border: "1px solid #E8E8E8",
              }}
              onClick={() => setIsEdit(true)}
            >
              {flr && flr.length > 0 ? (
                <Stack direction="row" spacing={1}>
                  {flr.map((item, index) => {
                    return <Chip label={item?.text} key={index} />;
                  })}
                </Stack>
              ) : (
                <>-</>
              )}
            </div>
          </Grid>

          {/* RDD Status */}
          <Grid item xs={12} sx={{ marginBottom: "24px" }}>
            <div className="xsmall-font gotham-book" style={{ color: "#838383", marginBottom: "12px" }}>
              Status
            </div>
            <Select
              id="defectStatus"
              defaultValue={selectValue}
              name={selectValue}
              sx={{
                width: "100%",
                " & > div": {
                  borderRadius: "8px",
                  border: "1px solid #e8e8e8",
                },
                "& > fieldset": {
                  border: "none",
                },
              }}
              onChange={(e) => {
                setIsEdit(true);
                handleStatusChange(e);
              }}
            >
              {opts?.map((item, index) => {
                return (
                  <MenuItem value={item?.value} name={item?.name} key={index}>
                    {item?.name}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
        </Grid>
      </>
    );
  };

  const BodySummary = () => {
    return (
      <>
        <div
          style={{
            position: "relative",
            marginLeft: "16px",
            // marginTop: "190px",
            paddingRight: "32px",
          }}
          className={styles.container}
        >
          <Grid container columns={3}>
            <Grid item xs={1}>
              <div className={styles.titleSummary}>Aircraft Registration</div>
              <div>{drawerDetail?.ac_reg}</div>
            </Grid>
            <Grid item xs={1}>
              <div className={styles.titleSummary}>Station</div>
              <div>{getAircraftLocation(drawerDetail?.ac_reg)}</div>
            </Grid>
            <Grid item xs={1}>
              <div className={styles.titleSummary}>ATA Chapter</div>
              <div>{getAtaChapter(drawerDetail?.ata_chapter, drawerDetail?.report_type)}</div>
            </Grid>
            <Grid item xs={1}>
              <div className={styles.titleSummary}>Flagged Date</div>
              <div> {drawerDetail ? format(new Date(drawerDetail?.occurence), dateFnsFormats.STANDARD_DATE) : ""}</div>
            </Grid>
            <Grid item xs={3}>
              <div className={styles.titleSummary}>Ecam warning</div>
              <div className={styles.bodySummary}>{drawerDetail?.text}</div>
            </Grid>
            <Grid item xs={3}>
              <div className={styles.titleSummary}>Fault Messages</div>
              {/* <div
                style={{
                  padding: "14px 16px",
                  borderRadius: "8px",
                  backgroundColor: "#F7F7F7",
                  border: "1px solid #E8E8E8",
                }}
                onClick={() => setIsEdit(true)}
              > */}
              {flr && flr.length > 0 ? (
                <>
                  {flr.map((item, index) => {
                    // return <Chip label={item?.text} key={index} />;
                    return (
                      <div
                        style={{
                          padding: "14px 16px",
                          borderRadius: "8px",
                          backgroundColor: "#F7F7F7",
                          border: "1px solid #E8E8E8",
                          marginBottom: "2px",
                        }}
                        // className={styles.bodySummary}
                      >
                        {item?.text}
                      </div>
                    );
                  })}
                </>
              ) : (
                <>-</>
              )}
              {/* </div> */}
            </Grid>
            <Grid item xs={3}>
              <div className={styles.titleSummary}>Rectification Status</div>
              <div className={styles.bodySummary}>
                {/* {dataForm?.formStatus === "closed" || dataForm?.formStatus === "approved"
                  ? dataForm?.ddmlStatus.rectificationCategory
                  : getSummary(dataForm?._id ? dataForm._id : dataForm.id, "Rectification Update", "") ||
                dataForm?.rectificationStatus} */}
                {/* {dataForm_?.rectificationStatus} */}
                {/* {dataForm_?.statusLog?.pushToSummary ? dataForm_?.statusLog?.updateLogDesc :""} */}-
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className={styles.titleSummary}>TSM Reference</div>
              <div
                // style={{
                //   padding: "14px 16px",
                //   borderRadius: "8px",
                //   backgroundColor: "#FFF",
                //   border: "1px solid #E8E8E8",
                //   cursor: "pointer",
                // }}
                className={styles.bodySummary}
              >
                {/* {drawerDetail?.tsm_ref} */}-
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className={styles.titleSummary}>Material Summary</div>
              <div className={styles.formTextAreaDisable}>-</div>
            </Grid>
            <Grid item xs={3}>
              <div className={styles.titleSummary}>Planning/Tech Service Summary</div>
              <div className={styles.formTextAreaDisable}>-</div>
            </Grid>
            <Grid item xs={3}>
              <div className={styles.titleSummary}>Status</div>
              <div>
                <Select
                  id="defectStatus"
                  defaultValue={selectValue}
                  name={selectValue}
                  sx={{
                    width: "100%",
                    " & > div": {
                      borderRadius: "8px",
                      border: "1px solid #e8e8e8",
                    },
                    "& > fieldset": {
                      border: "none",
                    },
                  }}
                  onChange={(e) => {
                    setIsEdit(true);
                    handleStatusChange(e);
                  }}
                >
                  {opts?.map((item, index) => {
                    return (
                      <MenuItem value={item?.value} name={item?.name} key={index}>
                        {item?.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
            </Grid>
            {/* <Grid item xs={1}>
              <div className={styles.titleSummary}>
                Created {formatDateDasr(drawerDetail?.createdAt)} at {formatTimeDasr(drawerDetail?.createdAt)}
                <br />
                Created by {drawerDetail?.createdBy}
              </div>
            </Grid>
            <Grid item xs={1} sx={{ paddingLeft: "10px" }}>
              <div className={styles.titleSummary}>
                {drawerDetail?.updatedAt !== null
                  ? `Updated ${formatDateDasr(drawerDetail?.updatedAt)} at ${formatTimeDasr(drawerDetail?.updatedAt)}`
                  : ""}
              </div>
            </Grid> */}
          </Grid>
        </div>
      </>
    );
  };

  const newEntryForm = () => {
    return (
      <>
        <Grid
          container
          columns={6}
          style={{
            padding: "24px",
          }}
        >
          <Grid item xs={5}>
            <span className="small-font">Repetitive Defect Details </span>
            {/* <span 
              className="small-font"
              style={{
                padding:"4px 8px",
                borderRadius: "6px",
                border: "1px solid #C3C3C3"
              }}
            >
              {drawerDetail?.ac_reg}
            </span> */}
          </Grid>
          <Grid item xs={0.5} style={{ borderRight: "1px solid #D1D1D1" }}>
            <img
              src={ExternalLink}
              alt="ExternalLink"
              style={{
                width: "20px",
                height: "20px",
              }}
            />
          </Grid>
          <Grid item xs={0.5} style={{ textAlign: "end" }}>
            <img
              src={solidCloseIcon}
              alt="solidCloseIcon"
              style={{
                width: "20px",
                height: "20px",
                cursor: "pointer",
              }}
              onClick={() => setIsDrawerOpen(false)}
            />
          </Grid>
        </Grid>
        <hr style={{ borderBottom: "1px solid #e8e8e8", margin: 0 }} />
        <Grid container style={{ padding: "0px 16px 16px 16px" }}>
          <Grid item xs={12}>
            <TabContext value={tabValue}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <AntTab label="Summary" value="1" />
                {/* <AntTab label="Rectification Update" value="2" />
                <AntTab label="Material/Tools Update" value="3" />
                <AntTab label="Planning/Tech Service" value="4" /> */}
              </TabList>
              <div style={{ width: "100%", height: "2px", backgroundColor: "#d1d1d1", marginTop: "-1px" }}></div>
              <div style={{ marginLeft: "-20px", marginRight: "-20px", marginTop: "-24px" }}>
                <TabPanel value="1">{/* <Summary /> */}</TabPanel>
                <TabPanel value="2">{/* <Rectification Updata /> */}</TabPanel>
              </div>
            </TabContext>
            {/* <hr style={{ borderBottom: '1px solid #e8e8e8'}} /> */}
          </Grid>
          <Grid item xs={6} style={{ marginBottom: "24px" }}>
            <div>
              <span className="xsmall-font" style={{ color: "#838383", marginBottom: "12px" }}>
                Station
              </span>
              <br />
              <span className="medium-font" style={{ fontFamily: "Gotham-book" }}>
                ---Station---
              </span>
            </div>
          </Grid>
          <Grid item xs={6} style={{ marginBottom: "24px" }}>
            <span className="xsmall-font" style={{ color: "#838383", marginBottom: "12px" }}>
              A/C Registration
            </span>
            <br />
            <span
              className="medium-font"
              style={{ fontFamily: "Gotham-book" }}
              onClick={() => {
                setIsEdit(true);
              }}
            >
              {drawerDetail?.ac_reg}
            </span>
          </Grid>
          <Grid item xs={6} style={{ marginBottom: "24px" }}>
            <span className="xsmall-font" style={{ color: "#838383", marginBottom: "12px" }}>
              ATA Chapter
            </span>
            <br />
            <span className="medium-font" style={{ fontFamily: "Gotham-book" }}>
              {getAtaChapter(drawerDetail?.ata_chapter, drawerDetail?.report_type)}
            </span>
          </Grid>
          <Grid item xs={6} style={{ marginBottom: "24px" }}>
            <span className="xsmall-font" style={{ color: "#838383", marginBottom: "12px" }}>
              Flagged Date
            </span>
            <br />
            <span className="medium-font" style={{ fontFamily: "Gotham-book" }}>
              {drawerDetail ? format(new Date(drawerDetail?.occurence), dateFnsFormats.STANDARD_DATE) : ""}
            </span>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: "24px" }}>
            <div className="xsmall-font gotham-book" style={{ color: "#838383", marginBottom: "12px" }}>
              ECAM Warning
            </div>
            <input
              style={{
                border: "1px solid #E8E8E8",
                borderRadius: "8px",
                padding: "14px 16px",
                width: "100%",
              }}
              placeholder="Ecam Warning"
              // defaultValue={drawerDetail?.tsm_reference}
              onChange={(e) => {
                setIsEdit(true);
                setEcamWarning(e.target.value);
              }}
              required
            />
          </Grid>
          <Grid item xs={12} style={{ marginBottom: "24px" }}>
            <div className="xsmall-font gotham-book" style={{ color: "#838383", marginBottom: "12px" }}>
              Fault Message
            </div>
            <Autocomplete
              clearIcon={false}
              options={[]}
              freeSolo
              multiple
              renderTags={(value, props) =>
                value.map((option, index) => <Chip label={option} {...props({ index })} key={index} />)
              }
              onChange={(e, value) => setReceivers((state) => value)}
              renderInput={(params) => <TextField label="Add Fault Message" {...params} />}
            />
          </Grid>
          <Grid item xs={12} sx={{ marginBottom: "24px" }}>
            <div className="xsmall-font gotham-book" style={{ color: "#838383", marginBottom: "12px" }}>
              Status
            </div>
            <Select
              id="defectStatus"
              defaultValue={selectValue}
              name={selectValue}
              sx={{
                width: "100%",
                " & > div": {
                  borderRadius: "8px",
                  border: "1px solid #e8e8e8",
                },
                "& > fieldset": {
                  border: "none",
                },
              }}
              onChange={(e) => {
                setIsEdit(true);
                handleStatusChange(e);
              }}
            >
              {opts?.map((item, index) => {
                return (
                  <MenuItem value={item?.value} name={item?.name} key={index}>
                    {item?.name}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: "24px" }}>
            <div className="xsmall-font gotham-book" style={{ color: "#838383", marginBottom: "12px" }}>
              TSM Reference
            </div>
            <input
              style={{
                border: "1px solid #E8E8E8",
                borderRadius: "8px",
                padding: "14px 16px",
                width: "100%",
              }}
              placeholder="TSM Reference"
              onChange={(e) => {
                setTsmValue(e.target.value);
              }}
              onClick={setIsEdit(true)}
            ></input>
          </Grid>
        </Grid>
        <hr style={{ borderBottom: "1px solid #e8e8e8", margin: 0 }} />
        <div className="liams" style={{ padding: "16px", display: "inline-flex" }}>
          <CloseButton
            isDrawerOpen={isDrawerOpen}
            setIsDrawerOpen={setIsDrawerOpen}
            setIsEdit={setIsEdit}
            isEdit={isEdit}
            setRemovedFlr={setRemovedFlr}
          />
          <StyledButton type="submit" onClick={handleSubmit} disabled={submitDisabled()}>
            <img
              src={SaveIcon}
              alt="SaveIcon"
              style={{
                marginRight: "8px",
              }}
            />
            Save
          </StyledButton>
        </div>
      </>
    );
  };

  const ErrorAirBallon = () => {
    return (
      <>
        <div ref={scrollingDivRef} className={styles.errorAirBallon}>
          <center>
            <img src={IconErrorAirBalloon} alt="error-air-ballon" />
            <div className={styles.errsorTitle}>No updates yet</div>
            <div className={styles.errosrSubTitle}>
              It looks like no one has made any updates yet.
              <br />
              Insert your update in the comment section below.
            </div>
          </center>
        </div>
      </>
    );
  };

  const editEntryForm = (drawerDetail) => {
    return (
      <>
        <Grid
          container
          columns={6}
          style={{
            padding: "24px",
          }}
        >
          <Grid item xs={5}>
            <span className="small-font">Repetitive Defect Details </span>
            <span
              className="small-font"
              style={{
                padding: "4px 8px",
                borderRadius: "6px",
                border: "1px solid #C3C3C3",
              }}
            >
              {drawerDetail?.ac_reg}
            </span>
          </Grid>
          <Grid item xs={1} style={{ textAlign: "end" }}>
            <img
              src={solidCloseIcon}
              alt="solidCloseIcon"
              style={{
                width: "20px",
                height: "20px",
                cursor: "pointer",
              }}
              onClick={() => setIsDrawerOpen(false)}
            />
          </Grid>
        </Grid>
        <hr style={{ borderBottom: "1px solid #e8e8e8", margin: 0 }} />
        <Grid container style={{ padding: "0px 16px 16px 16px" }}>
          <Grid item xs={12}>
            <TabContext value={tabValue}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <AntTab label="Summary" value="1" />
                {/* <AntTab label="Rectification Update" value="2" />
                <AntTab label="Material/Tools Update" value="3" />
                <AntTab label="Planning/Tech Service" value="4" /> */}
              </TabList>
              <div style={{ width: "100%", height: "2px", backgroundColor: "#d1d1d1", marginTop: "-1px" }}></div>
              <div style={{ marginLeft: "-20px", marginRight: "-20px", marginTop: "-24px" }}>
                <TabPanel value="1">
                  <BodySummary />
                </TabPanel>
                <TabPanel value="2">
                  {/* <DDMLUpdateLogs tab={"Rectification Update"} ddmlStatus={null} /> */}
                  <ErrorAirBallon />
                </TabPanel>
                <TabPanel value="3">
                  {/* <DDMLUpdateLogs tab={"Material/Tools Update"} ddmlStatus={null} /> */}
                  <ErrorAirBallon />
                </TabPanel>
                <TabPanel value="4">
                  {/* <DDMLUpdateLogs tab={"PPC"} ddmlStatus={null} /> */}
                  <ErrorAirBallon />
                </TabPanel>
              </div>
            </TabContext>
            {/* <hr style={{ borderBottom: '1px solid #e8e8e8'}} /> */}
          </Grid>
          {/* <Grid item xs={6} style={{ marginBottom: "24px" }}>
            <div>
              <span className="xsmall-font" style={{ color: "#838383", marginBottom: "12px" }}>
                Station
              </span>
              <br />
              <span className="medium-font" style={{ fontFamily: "Gotham-book" }}>
                ---Station---
              </span>
            </div>
          </Grid>
          <Grid item xs={6} style={{ marginBottom: "24px" }}>
            <span className="xsmall-font" style={{ color: "#838383", marginBottom: "12px" }}>
              A/C Registration
            </span>
            <br />
            <span
              className="medium-font"
              style={{ fontFamily: "Gotham-book" }}
              onClick={() => {
                setIsEdit(true);
              }}
            >
              {drawerDetail?.ac_reg}
            </span>
          </Grid>
          <Grid item xs={6} style={{ marginBottom: "24px" }}>
            <span className="xsmall-font" style={{ color: "#838383", marginBottom: "12px" }}>
              ATA Chapter
            </span>
            <br />
            <span className="medium-font" style={{ fontFamily: "Gotham-book" }}>
              {getAtaChapter(drawerDetail?.ata_chapter, drawerDetail?.report_type)}
            </span>
          </Grid>
          <Grid item xs={6} style={{ marginBottom: "24px" }}>
            <span className="xsmall-font" style={{ color: "#838383", marginBottom: "12px" }}>
              Flagged Date
            </span>
            <br />
            <span className="medium-font" style={{ fontFamily: "Gotham-book" }}>
              {drawerDetail ? format(new Date(drawerDetail?.occurence), dateFnsFormats.STANDARD_DATE) : ""}
            </span>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: "24px" }}>
            <div className="xsmall-font gotham-book" style={{ color: "#838383", marginBottom: "12px" }}>
              ECAM Warning
            </div>
            <div
              style={{
                padding: "16px",
                borderRadius: "8px",
                backgroundColor: "#F7F7F7",
              }}
            >
              {drawerDetail?.text}
            </div>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: "24px" }}>
            <div className="xsmall-font gotham-book" style={{ color: "#838383", marginBottom: "12px" }}>
              Fault Message
            </div>
            <div
              style={{
                padding: "14px 16px",
                borderRadius: "8px",
                backgroundColor: "#F7F7F7",
                border: "1px solid #E8E8E8",
              }}
              onClick={() => setIsEdit(true)}
            >
              {flr && flr.length > 0 ? (
                <Stack direction="row" spacing={1}>
                  {flr.map((item, index) => {
                    return <Chip label={item?.text} key={index} />;
                  })}
                </Stack>
              ) : (
                <>-</>
              )}
            </div>
          </Grid>
          <Grid item xs={12} sx={{ marginBottom: "24px" }}>
            <div className="xsmall-font gotham-book" style={{ color: "#838383", marginBottom: "12px" }}>
              Status
            </div>
            <Select
              id="defectStatus"
              defaultValue={selectValue}
              name={selectValue}
              sx={{
                width: "100%",
                " & > div": {
                  borderRadius: "8px",
                  border: "1px solid #e8e8e8",
                },
                "& > fieldset": {
                  border: "none",
                },
              }}
              onChange={(e) => {
                setIsEdit(true);
                handleStatusChange(e);
              }}
            >
              {opts?.map((item, index) => {
                return (
                  <MenuItem value={item?.value} name={item?.name} key={index}>
                    {item?.name}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: "24px" }}>
            <div className="xsmall-font gotham-book" style={{ color: "#838383", marginBottom: "12px" }}>
              TSM Reference
            </div>
            {!isEdit ? (
              <div
                style={{
                  padding: "14px 16px",
                  borderRadius: "8px",
                  backgroundColor: "#FFF",
                  border: "1px solid #E8E8E8",
                  cursor: "pointer",
                }}
                onClick={() => setIsEdit(true)}
              >
                {drawerDetail?.tsm_ref ? drawerDetail?.tsm_ref : "TSM Reference"}
              </div>
            ) : (
              <input
                style={{
                  border: "1px solid #E8E8E8",
                  borderRadius: "8px",
                  padding: "14px 16px",
                  width: "100%",
                }}
                // placeholder="TSM Reference"
                // defaultValue={drawerDetail?.tsm_reference}
                value={drawerDetail?.tsm_reference}
                onChange={(e) => {
                  setTsmValue(e.target.value);
                }}
              ></input>
            )}
          </Grid> */}
        </Grid>
        <hr style={{ borderBottom: "1px solid #e8e8e8", margin: 0 }} />
        {tabValue === "1" && (
          <div className="liams" style={{ padding: "16px", display: "inline-flex", width: "100%" }}>
            <CloseButton
              isDrawerOpen={isDrawerOpen}
              setIsDrawerOpen={setIsDrawerOpen}
              setIsEdit={setIsEdit}
              isEdit={isEdit}
              setRemovedFlr={setRemovedFlr}
            />
            <AdeButton
              type="submit"
              onClick={handleSubmit}
              disabled={submitDisabled()}
              fullWidth
              variant="outlined"
              sx={{
                borderRadius: "8px",
                border: "0px solid #e8e8e8",
                fontFamily: "Gotham-Medium",
                pl: 6,
                pr: 6,
                mr: 4,
                "&.MuiButton-root:hover": { border: "transparent" },
              }}
            >
              <img
                src={SaveIcon}
                alt="SaveIcon"
                style={{
                  marginRight: "8px",
                }}
              />
              Save
            </AdeButton>
            <AdeButton
              fullWidth
              variant="contained"
              disabled={dasrRdd}
              sx={{
                borderRadius: "8px",
                fontFamily: "Gotham-Medium",
                pl: 6,
                pr: 6,
                "&.MuiButton-root": { border: "transparent" },
              }}
              onClick={handleAssignmentSubmit}
            >
              Assignment
            </AdeButton>
          </div>
        )}
      </>
    );
  };

  return (
    <Drawer
      variant="temporary"
      anchor={"right"}
      open={isDrawerOpen}
      onBackdropClick={() => {
        setIsEdit(false);
        setIsDrawerOpen(false);
        setRemovedFlr([]);
      }}
      onEscapeKeyDown={() => {
        setIsEdit(false);
        setIsDrawerOpen(false);
        setRemovedFlr([]);
      }}
      // onClose={setIsDrawerOpen(!isDrawerOpen)}
      style={{
        marginTop: "60px",
        maxWidth: "800px",
      }}
      PaperProps={{
        sx: {
          paddingTop: "60px",
          maxWidth: "800px",
        },
      }}
    >
      <div className="drawer-main">
        {
          newEntry === true
            ? newEntryForm()
            : // <>Bndarwe</>
              editEntryForm(drawerDetail)
          // <>ITKAS</>
        }
      </div>
    </Drawer>
  );
};

export default EditDrawer;
