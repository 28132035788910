/* eslint-disable no-unused-expressions */
import React from "react";
import { Box, Grid, Typography, Divider, Button } from "@mui/material";
import LoadingInput from "../../../components/inputs/LoadingInput";
import StyledDateInput from "../../../components/inputs/dates/StyledDateInput";
import StyledDropDown from "../../../components/inputs/dropdowns/StyledDropDown";
import StyledTextField from "../../../components/inputs/text";
import { useEffect, useState } from "react";
import { useUpdateEquipmentDatas } from "./useUpdateEquipmentDatas";
import { useNotification } from "../../../components/notifications/UniversalNotification";
import GridItem from "./GridItem";
import useUpdateEquipmentStates from "./useUpdateEquipmentStates";
import { getStrDateTimeUtcBased } from "../../../utils/dates";
import { useDialog } from "../../../components/dialog-box/DialogInterface";
import features from "pages/elevade/cabinMonitoring/js/controllers/permissions/features";
import {
  PERMISSIONS,
  cabinCheckPermission,
  checkPermission,
} from "pages/elevade/cabinMonitoring/js/controllers/permissions/permission-codes";
import { useAppSelector } from "pages/elevade/cabinMonitoring/js/app/hooks";
import { useGetAllLocationTypes } from "pages/elevade/cabinMonitoring/js/models/custom/location-inventory-model";
import { useGetAllInventoryTypes } from "pages/elevade/cabinMonitoring/js/models/custom/inventory-item-model";
import { useGetAllItemStatuses } from "pages/elevade/cabinMonitoring/js/models/custom/ac-item-model";
import {
  useGetAllFleetDistinctedByModel,
  useGetAllFleets,
} from "pages/elevade/cabinMonitoring/js/models/custom/fleet-model";
import { recognizedInventoryTypeToLocationCode } from "pages/elevade/cabinMonitoring/js/constants/mappings";
import { useGetAllPassangerSeats } from "pages/elevade/cabinMonitoring/js/models/custom/passanger-seat-model";
import { useDeleteInventory, useUpdateInventory } from "pages/elevade/cabinMonitoring/js/models/custom/inventory-model";
import { DropDownOptionsWithAvailability } from "../AddEquipment/AddEquipmentMain";

export default function UpdateEquipment({ node, refetch, onClose }) {
  const [searchSeatNo, setSearchSeatNo] = useState();
  const updateEqStates = useUpdateEquipmentStates();
  const openDialog = useDialog();
  const [isOccupied, setIsOccupied] = useState(false);

  const userEntity = useAppSelector((state) => state.userEntity);
  const permissions = userEntity?.userPermission;

  const allLocationTypes = useGetAllLocationTypes();
  const allInventoryTypes = useGetAllInventoryTypes();
  const allItemStatuses = useGetAllItemStatuses();
  const allFleets = useGetAllFleets();
  const allFleetDistinctedByModel = useGetAllFleetDistinctedByModel();

  const currentFleet = allFleets?.data?.find((item) => {
    return node?.regNo === item?.regNo;
  });

  const selectedFleet = allFleets?.data?.find((item) => {
    return updateEqStates?.fleetId?.value === item?.id;
  });

  const allPassangerSeats = useGetAllPassangerSeats({
    model: selectedFleet?.fleetType?.model,
    regNo: selectedFleet?.regNo,
    search: searchSeatNo,
  });

  const { mutate: mutateDelete } = useDeleteInventory({ invId: node?.id });

  const { mutate: mutateUpdate } = useUpdateInventory({
    invId: node?.id,
    serialNo: updateEqStates?.serialNo?.value,
    eqType: updateEqStates?.eqType?.value,
    status: updateEqStates?.status?.value,
    fleetId: updateEqStates?.fleetId?.value,
    inputLocNo: updateEqStates?.inputLocNo?.value,
    mfgDate: updateEqStates?.mfgDate,
    expDate: updateEqStates?.expDate,
    inputLocType: updateEqStates?.inputLocType,
    seatNo: updateEqStates?.inputLocNo?.value,
  });

  // const {
  // 	// locNoData,
  // 	// regNosData,
  // 	// locNoIsLoading,
  // 	// locNoOpts,
  // 	mutateUpdate,
  // 	mutateDelete,
  // 	// checkIsLocationOccupiedIsLoading,
  // } = useUpdateEquipmentDatas({
  // 	node,
  // 	serialNo: updateEqStates?.serialNo?.value,
  // 	eqType: updateEqStates?.eqType?.value,
  // 	status: updateEqStates?.status?.value,
  // 	fleetId: updateEqStates?.fleetId?.value,
  // 	inputLocNo: updateEqStates?.inputLocNo?.value,
  // 	mfgDate: updateEqStates?.mfgDate,
  // 	expDate: updateEqStates?.expDate,
  // 	inputLocType: updateEqStates?.inputLocType,
  // 	seatNo: updateEqStates?.inputLocNo?.value,
  // 	// inputModel: updateEqStates?.inputModel,
  // });

  // const currentLocationCode = recognizedInventoryTypeToLocationCode[node?.type?.code]

  useEffect(() => {
    updateEqStates?.tagId?.setValue(node?.tag);
    updateEqStates?.serialNo?.setValue(node?.serialNo);
    updateEqStates?.eqType?.setValue(node?.type?.code);
    updateEqStates?.fleetId?.setValue(currentFleet?.id);
    updateEqStates?.inputLocNo?.setValue(node?.seatNo);
    updateEqStates?.setMfgDate(node?.manufactureDate);
    updateEqStates?.setExpDate(node?.expiryDate);
    updateEqStates?.setInputModel(currentFleet?.fleetType?.model);
    updateEqStates?.setInputLocType(node?.type?.code === "PAX" ? "PS" : undefined);
    updateEqStates?.status?.setValue(node?.status?.code);
  }, [node, currentFleet]);

  // const isLocationOccupied = isOccupisOccupiedChecked(updateEqStates.inputLocNo, node, checkIsLocationOccupiedData)
  // const isLocationOccupied = isOccupied;
  const openNotification = useNotification();

  const isUserAllowedUpdate = cabinCheckPermission(permissions).isAllowedUpdateEquipment;

  return (
    <Box
      style={{
        width: "100%",
        borderRadius: "0px",
        height: "100%",
        position: "relative",
      }}
    >
      {/* <pre>
				{JSON.stringify({
					// node,
					allPassangerSeats,
					// allFleets,
					// currentFleet,
					// selectedFleet,
				}, null, 4)}
			</pre> */}
      {/* <pre>
				{JSON.stringify({
					currentFleet,
					node,
					toUpdate: {
						serialNo: updateEqStates?.serialNo?.value,
						eqType: updateEqStates?.eqType?.value,
						status: updateEqStates?.status?.value,
						mfgDate: updateEqStates?.mfgDate?.value,
						expDate: updateEqStates?.expDate?.value,
						inputLocNo: updateEqStates?.inputLocNo?.value,
						inputLocType: updateEqStates?.inputLocType,
						inputModel: updateEqStates?.inputModel,
						fleetId: updateEqStates?.fleetId?.value,
						seatNo: updateEqStates?.inputLocNo?.value
					}
				}, null, 4)}
			</pre> */}
      <Box
        style={{
          padding: "1em",
          paddingTop: "0px",
        }}
      >
        <Grid container spacing="1em">
          <GridItem
            title="Tag ID"
            fullWidth={true}
            inputComp={
              <Typography
                sx={{
                  border: "1px solid #D1D1D1",
                  borderRadius: "8px",
                  padding: "0.7em",
                  backgroundColor: "lightgrey",
                  wordWrap: "break-word",
                  wordBreak: "break-all",
                  textAlign: "start",
                }}
              >
                {/* {tagId ? tagId.slice(-12) : ''} */}
                {updateEqStates?.tagId?.value ?? ""}
              </Typography>
            }
          />
          <GridItem
            title="Serial No."
            fullWidth={true}
            inputComp={
              <StyledTextField
                value={updateEqStates?.serialNo.value}
                onChange={(e) => {
                  updateEqStates?.serialNo.setValue(e.target.value);
                }}
                fullWidth
                error={updateEqStates.serialNo?.isError}
                helperText={updateEqStates.serialNo?.errorMessage ?? undefined}
              />
            }
          />
          <GridItem
            title="Equipment Type"
            fullWidth={false}
            inputComp={
              allInventoryTypes?.isLoading ? (
                <LoadingInput />
              ) : (
                <StyledDropDown
                  error={updateEqStates.eqType?.isError}
                  helperText={updateEqStates.eqType?.errorMessage ?? undefined}
                  value={updateEqStates?.eqType?.value}
                  handleChange={(e) => {
                    updateEqStates?.eqType.setValue(e.target.value);
                  }}
                  options={allInventoryTypes?.data?.map(({ name, code }) => {
                    return {
                      label: name,
                      value: code,
                    };
                  })}
                />
              )
            }
          />
          <GridItem
            title="Status"
            fullWidth={false}
            isRequired={false}
            inputComp={
              !allItemStatuses?.isLoading ? (
                <StyledDropDown
                  error={updateEqStates.status?.isError}
                  helperText={updateEqStates.status?.errorMessage ?? undefined}
                  value={updateEqStates?.status?.value}
                  handleChange={(e) => {
                    updateEqStates?.status?.setValue(e.target.value);
                  }}
                  options={allItemStatuses?.data?.map(({ code }) => {
                    return {
                      label: statusGetLabelFromCode(code),
                      value: code,
                    };
                  })}
                />
              ) : (
                <LoadingInput />
              )
            }
          />
          <GridItem
            isRequired={false}
            title="MFG Date"
            fullWidth={false}
            inputComp={
              <StyledDateInput
                value={updateEqStates?.mfgDate}
                onChange={(date) => {
                  updateEqStates?.setMfgDate(date);
                }}
                fullwidth
              />
            }
          />
          <GridItem
            isRequired={false}
            title="Expiry Date"
            fullWidth={false}
            inputComp={
              <StyledDateInput
                value={updateEqStates?.expDate}
                onChange={(date) => {
                  updateEqStates?.setExpDate(date);
                }}
                fullwidth
              />
            }
          />
          <GridItem
            title="A/C Model"
            inputComp={
              allFleetDistinctedByModel?.isLoading ? (
                <LoadingInput />
              ) : (
                <StyledDropDown
                  value={updateEqStates?.inputModel ? updateEqStates?.inputModel : ""}
                  handleChange={(e) => {
                    updateEqStates?.setInputModel(e.target.value);
                  }}
                  options={
                    allFleetDistinctedByModel && !allFleetDistinctedByModel?.isLoading
                      ? allFleetDistinctedByModel?.data?.map(({ model }) => {
                          return {
                            label: model,
                            value: model,
                          };
                        })
                      : []
                  }
                />
              )
            }
            isRequired={true}
          />
          <GridItem
            title="A/C Reg"
            inputComp={
              allFleets?.isLoading ? (
                <LoadingInput />
              ) : (
                <StyledDropDown
                  disabled={updateEqStates?.inputModel ? false : true}
                  value={
                    allFleets?.data?.find((fleet) => {
                      const { id } = fleet;
                      return id === updateEqStates?.fleetId?.value;
                    })?.regNo
                  }
                  handleChange={(e) => {
                    const fleetRegNoFromInput = e?.target?.value;
                    const selectedFleetId = allFleets?.data?.find((fleet) => {
                      const { id, regNo } = fleet;
                      return regNo === fleetRegNoFromInput;
                    })?.id;
                    updateEqStates?.fleetId?.setValue(selectedFleetId);
                  }}
                  options={
                    allFleets && !allFleets?.isLoading
                      ? allFleets?.data?.map(({ regNo }) => {
                          return {
                            label: regNo,
                            value: regNo,
                          };
                        })
                      : []
                  }
                />
              )
            }
            isRequired={true}
          />
          {updateEqStates?.eqType?.value?.toLowerCase() === "pax" && (
            <GridItem
              title="Location Type"
              inputComp={
                allLocationTypes?.isLoading ? (
                  <LoadingInput />
                ) : (
                  <StyledDropDown
                    disabled={updateEqStates?.fleetId?.value ? false : true}
                    value={updateEqStates?.inputLocType}
                    handleChange={(e) => {
                      updateEqStates?.setInputLocType(e.target.value);
                    }}
                    options={allLocationTypes?.data
                      ?.map(({ name, code }) => {
                        return {
                          label: name,
                          value: code,
                        };
                      })
                      ?.filter(({ value }) => value?.toLowerCase() === "ps")}
                  />
                )
              }
              isRequired={true}
            />
          )}
          {updateEqStates?.eqType?.value?.toLowerCase() === "pax" &&
            updateEqStates?.inputLocType?.toLowerCase() === "ps" && (
              <GridItem
                title="Location Number"
                inputComp={
                  // locNoIsLoading && searchSeatNo === undefined ? (
                  // 	<LoadingInput />
                  // ) : (
                  <StyledDropDown
                    onSearch={(val) => {
                      setSearchSeatNo(val);
                    }}
                    disabled={updateEqStates?.inputLocType ? false : true}
                    value={updateEqStates?.inputLocNo?.value}
                    handleChange={(e) => {
                      // const foundNode = locNoData.find(
                      // 	({ node }) => {
                      // 		return (
                      // 			node?.id === e.target.value
                      // 		);
                      // 	}
                      // );
                      // if (node?.locationlistSet?.edges?.[0]?.node?.locationId?.id === e.target.value) {
                      // 	setIsOccupied(false);
                      // } else if (foundNode?.node?.occupied === null) {
                      // 	setIsOccupied(false);
                      // } else {
                      // 	setIsOccupied(true);
                      // }
                      updateEqStates?.inputLocNo.setValue(e.target?.value);
                      setSearchSeatNo(undefined);
                    }}
                    options={allPassangerSeats?.data?.map((item) => {
                      const { seatNo, occupied } = item ?? {};
                      return {
                        label: <DropDownOptionsWithAvailability occupied={occupied} seatNo={seatNo} />,
                        value: seatNo,
                      };
                    })}
                  />
                }
                isRequired={true}
              />
            )}
          <Grid
            item
            xs={6}
            sx={{
              marginBottom: "0.5em",
              paddingTop: "10px !important",
            }}
          >
            <Typography sx={{ fontSize: "12px", color: "#B1B1B1" }}>
              Created on {getStrDateTimeUtcBased(new Date(node?.createdAt))} <br />
              by {node?.createdBy}
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ paddingTop: "10px !important" }}>
            <Typography sx={{ fontSize: "12px", color: "#B1B1B1" }}>
              updated on {getStrDateTimeUtcBased(new Date(node?.updatedAt))} <br />
              by {node?.updatedBy}
            </Typography>
          </Grid>
        </Grid>

        <Box
          sx={{
            position: "relative",
            bottom: "0px",
            left: "0px",
            width: "100%",
          }}
        >
          <Divider sx={{ marginTop: "1em" }} />
          <Grid container>
            <Grid
              item
              xs={4}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={() => {
                  onClose();
                }}
                sx={{
                  height: "37px",
                  marginLeft: "1em",
                  marginRight: "1em",
                  marginTop: "1em",
                  marginBottom: "0px",
                  width: "100%",
                  borderRadius: "8px",
                }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid
              item
              xs={4}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                disabled={!isUserAllowedUpdate}
                sx={{
                  height: "37px",
                  marginLeft: "1em",
                  marginRight: "1em",
                  marginTop: "1em",
                  marginBottom: "0px",
                  width: "100%",
                  borderRadius: "8px",
                }}
                onClick={() => {
                  if (
                    updateEqStates?.tagId?.value &&
                    updateEqStates?.serialNo?.value &&
                    updateEqStates?.eqType.value
                    // && mfgDate && expDate
                  ) {
                    const updateItem = () => {
                      openNotification("Update this item?", {
                        variant: "info",
                        onConfirm: () => {
                          mutateUpdate().then(({ isError, errorMessage }) => {
                            if (!isError) {
                              openNotification("Equipment updated!", {
                                variant: "info",
                                onClose: () => {
                                  onClose();
                                  refetch();
                                },
                              });
                            } else {
                              openNotification("Saving failed!", {
                                variant: "error",
                                helperText: `There was an error saving you changes. Error ${errorMessage}`,
                              });
                            }
                          });
                        },
                      });
                    };
                    updateItem();
                    // if (!isLocationOccupied) {
                    // 	updateItem();
                    // } else {
                    // 	openDialog(
                    // 		<Typography
                    // 			sx={{
                    // 				fontWeight: "bold",
                    // 				justifyContent:
                    // 					"center",
                    // 			}}
                    // 		>
                    // 			Equipment Replacement
                    // 		</Typography>,
                    // 		<Typography>
                    // 			The selected location is assigned with an equipment. Doing so will replace it. Are you sure you want to proceed?
                    // 		</Typography>,
                    // 		{
                    // 			onConfirm: () => {
                    // 				updateItem();
                    // 			},
                    // 		}
                    // 	);
                    // }
                  } else {
                    // openNotification('Please fill in all the required field(s)', { variant: 'warning' })
                    // do nothing instead
                  }
                }}
              >
                {/* {checkIsLocationOccupiedIsLoading ? "Loading..." : "Save"} */}
                Save
              </Button>
            </Grid>
            {features.deleteEquipment.isEnabled && (
              <Grid
                item
                xs={4}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  disabled={!features.deleteEquipment.isEnabled}
                  variant="contained"
                  color="warning"
                  sx={{
                    height: "37px",
                    marginLeft: "1em",
                    marginRight: "1em",
                    marginTop: "1em",
                    marginBottom: "0px",
                    width: "100%",
                    borderRadius: "8px",
                  }}
                  onClick={() => {
                    openNotification(`Delete item with tag : ${node?.tag.slice(-12)} ?`, {
                      variant: "warning",
                      onConfirm: () => {
                        mutateDelete().then(({ isError, errorMessage }) => {
                          if (!isError) {
                            openNotification("Successfully deleted", {
                              variant: "info",
                              onClose: () => {
                                onClose();
                                refetch();
                              },
                            });
                          } else {
                            openNotification(`Error : ${errorMessage}`, {
                              variant: "error",
                            });
                          }
                        });
                      },
                    });
                  }}
                >
                  Delete
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
function statusGetLabelFromCode(code) {
  let labelHolder = "Active";
  switch (code) {
    case "A":
      labelHolder = "Active";
      break;
    case "L":
      labelHolder = "Missing";
      break;
    case "R":
      labelHolder = "Near Expiry";
      break;
    case "E":
      labelHolder = "Expired";
      break;
    case "T":
      labelHolder = "Error";
      break;
    case "D":
      labelHolder = "Defect";
      break;
    default:
      labelHolder = "Active";
      break;
  }
  return labelHolder;
}
// function isOccupiedCheck(inputLocNo: UseInputStateReturnProps, node: ToBeUpdateNode, checkIsLocationOccupiedData: any): boolean {
//     let isLocationOccupied = true;
//     if (inputLocNo?.value === node?.locationlistSet?.edges[0]?.node?.locationId?.id) {
//         isLocationOccupied = false
//     } else if (checkIsLocationOccupiedData?.edges?.length < 1) {
//         isLocationOccupied = false
//     } else if (checkIsLocationOccupiedData?.edges?.length > 0 && checkIsLocationOccupiedData?.edges[0]?.node?.inventory === null) {
//         isLocationOccupied = false
//     } else {
//         isLocationOccupied = true
//     }
//     return isLocationOccupied;
// }

function PSLocationMenuItem() {
  return (
    <Box sx={{ width: "100%" }}>
      <Grid container>
        <Grid item xs={2}>
          {innerNode?.occupied === true ? (
            <Tooltip title="Occupied">
              <span style={{ cursor: "pointer" }}>
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M5.875 9.625L7.75 11.5L12.125 7.125M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9Z"
                    stroke="#6AB47B"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </Tooltip>
          ) : (
            <Tooltip title="Available">
              <span style={{ cursor: "pointer" }}>
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z"
                    stroke="#C3C3C3"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </Tooltip>
          )}
        </Grid>
        <Grid item xs={10}>
          <Typography>{innerNode?.seatNo}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

function PSLocationMenuItemNoInfoForAvailability() {
  return (
    <Box sx={{ width: "100%" }}>
      <Grid container>
        <Grid item xs={2}>
          {/* nothing here */}
        </Grid>
        <Grid item xs={10}>
          <Typography>
            <span
              style={{
                fontWeight: "bolder",
              }}
            >
              {innerNode?.seatNo}
            </span>
            {/* <Chip label={'current'} size="small" color="info" sx={{ marginLeft: '0.5em' }} /> */}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
