import { useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";

export default function usePostRecentActivity(title, subModule, pageName) {
  async function postRecentActivity() {
    try {
      const result = await axios.post(
        process.env.REACT_APP_ELEVADE_IO_API_URL + "/api/activity",
        {
          title: title,
          module: "Fleet",
          subModule: subModule,
          pageName: pageName,
          redirectUrl: window.location.href,
        },
        {
          headers: {
            token: Cookies.get("elevadeToken"),
            accept: "application/json",
            "Content-Type": "application/json",
          },
        },
      );
    } catch (error) {
      console.log("Error while saving recent activity: ", error);
    }
  }
  useEffect(() => {
    postRecentActivity();
  }, []);
}
