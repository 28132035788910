import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Grid, styled, Tab, Tooltip, tooltipClasses } from "@mui/material";
import styles from "../Dasr.module.css";
import IconErrorAirBalloon from "common/images/icons/elevade/Icon-ErrorAirBalloon.svg";
import DDML from "./DDML";
import SearchBar from "./SearchBar";
import ActiveFilters from "./ActiveFilters";
import {
  addingDate,
  calculateBalanceDay,
  calculateCalendarBalance,
  calculateCalendarExpiryDate,
  formatDateDasr,
  formatTimeLocal,
} from "pages/elevade/component/Helpers";
import MUIPagination from "pages/elevade/component/Pagination";
import { makeStyles } from "@mui/styles";
import { makeAPIRequestMDB } from "common/helpers/makeAPIRequestMDB";
import Overlay from "../../component/Overlay";
import NoData from "pages/elevade/component/DataUnavailable";
import IconAirplaneLimitation from "common/images/icons/elevade/Icon-AirplaneLimitation.svg";
import IconReInspection from "common/images/icons/elevade/Icon-ReInspection.svg";
import ElevadeDrawer from "../component/Drawer";
import DialogApprove from "./DialogApprove";
import AirplaneLoader from "pages/elevade/component/AirplaneLoader";
import { makeAPIRequestRun } from "common/helpers";
import { Helmet } from "react-helmet";
import { TabContext, TabList, TabPanel } from "@mui/lab";

const ArchiveAircraftReport = ({ aoc, isLoading, setIsLoading }) => {
  const [subLoading, setSubLoading] = useState(true);
  const [type, setType] = useState("1");
  const [value, setValue] = useState("1");
  const [open, setOpen] = useState(false);
  const [openTitle, setOpenTitle] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState([]);
  const [data_, setData_] = useState([]);
  const [dataForm, setDataForm] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [index, setIndex] = useState();
  const [edit, setEdit] = useState(true);
  const [openNotification, setOpenNotification] = useState(false);
  const [newForm, setNewForm] = useState(false);
  const [triggerApi, setTriggerApi] = useState(true);
  const [activeIndex, setActiveIndex] = useState("");
  const [selectToMove, setSelectToMove] = useState([]);
  const [openDialogApprove, setOpenDialogApprove] = useState(false);
  const [openDialogMoveToAssignment, setOpenDialogMoveToAssignment] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [isSearch, setIsSearch] = useState([]);
  const InitialFilters = {
    search: [],
    location: [],
    aircraftReg: [],
    assignedTo: [],
    raisedDate: [],
    expiryDate: [],
    summaryStatus: [],
  };
  const [isFilters, setIsFilters] = useState(InitialFilters);
  const [filteredData, setFilteredData] = useState([]);
  const [date, setDate] = useState(new Date());
  const [today, setToday] = useState(new Date());
  const [isDate, setIsDate] = useState(new Date());
  const [tableLoading, setTableLoading] = useState(false);
  const [dataFHFC, setDataFHFC] = useState([]);
  const [filterOptions, setFilterOptions] = useState();
  const [queryFilter, setQueryFilter] = useState();
  const [maintenanceStatus, setMaintenanceStatus] = useState([]);
  const [filteredMaintenanceStatus, setFilteredMaintenanceStatus] = useState([]);
  const [limitationData, setLimitationData] = useState([]);
  const [ddmlCountFormType, setDDMLCountFormType] = useState([]);
  const [countFormType, setCountFormType] = useState({ AOG: 0, Maintenance: 0, Standby: 0, Storage: 0 });

  let sliceStart = page > 0 ? perPage * (page - 1) : 0;

  useEffect(() => {
    setIsLoading(false);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (value === "1") setFilterOptions(filteredData);
    else {
      let filterOptions = { location: [], aircraftReg: [] };
      maintenanceStatus.forEach((item) => {
        if (!filterOptions.aircraftReg.includes(item.aircraftReg)) {
          filterOptions.aircraftReg.push(item.aircraftReg);
        }
        if (!filterOptions.location.includes(item.location)) {
          filterOptions.location.push(item.location);
        }
      });
      if (!checkAnyKeyHasValue(isFilters)) setFilterOptions(filterOptions);
    }
  }, [value, isFilters, maintenanceStatus]);

  function setInitialDate() {
    var currentDate = new Date();
    var options = { month: "long", day: "2-digit", year: "numeric" };
    var formattedDate = currentDate.toLocaleDateString("en-US", options);
    setIsDate(formattedDate);
    setToday(formattedDate);
    // Set yesterday's date
    currentDate.setDate(currentDate.getDate() - 1);
    // setYesterday(currentDate.toLocaleDateString("en-US", options));
  }

  useEffect(() => {
    setInitialDate();
  }, []);

  useEffect(() => {
    setData_(data);
  }, [data, type]);

  useEffect(() => {
    fetch();
    if (!checkAnyKeyHasValue(isFilters)) fetchFilters();
    fetchLimitationData();
    // TODO: to include "value" as dependencies when api ready
  }, [openNotification, triggerApi, aoc, date, page, perPage, queryFilter]);

  useEffect(() => {
    if (!open) setActiveIndex();
  }, [open]);

  useEffect(() => {
    let formType =
      value === "2"
        ? "Maintenance"
        : value === "3"
        ? "AOG"
        : value === "4"
        ? "Standby"
        : value === "5"
        ? "Storage"
        : "Maintenance";
    if (formType !== null) fetchMaintenanceReport(formType);
    setSelectToMove([]);
    setCheckAll(false);
  }, [value, page, perPage, queryFilter]);

  // Applying filters
  useEffect(() => {
    let search = "";
    let station = "";
    let aircraft_reg = "";
    let department = "";
    let raised_date = "";
    let expiry_date = "";
    if (isFilters.search.length > 0) {
      search = "&search_ddml=" + isFilters.search.join(",");
    }
    if (isFilters.location.length > 0) {
      station = "&station=" + isFilters.location.join(",");
    }
    if (isFilters.aircraftReg.length > 0) {
      aircraft_reg = "&aircraft_reg=" + isFilters.aircraftReg.join(",");
    }
    if (isFilters.assignedTo.length > 0) {
      department = "&department=" + isFilters.assignedTo.join(",");
    }
    if (isFilters.raisedDate.length > 0) {
      raised_date = "&raised_date=" + isFilters.raisedDate.join(",");
    }
    if (isFilters.expiryDate.length > 0) {
      expiry_date = "&expiry_date=" + isFilters.expiryDate.join(",");
    }
    let query = search + station + aircraft_reg + department + raised_date + expiry_date;
    if (query === "") setQueryFilter();
    else setQueryFilter(query);
    setPage(1);
  }, [isFilters]);

  useEffect(() => {
    if (selectToMove.length === 0) setCheckAll(false);
  }, [selectToMove]);

  useEffect(() => {
    fetchFHFC();
  }, []);

  useEffect(() => {
    if (value === "1" && ddmlCountFormType.length > 0) {
      setTotalRows(calculateTotal(ddmlCountFormType.count_by_form_type));
      setTotalPage(Math.floor(calculateTotal(ddmlCountFormType.count_by_form_type) / perPage) + 1);
    } else {
      let formType =
        value === "2"
          ? "Maintenance"
          : value === "3"
          ? "AOG"
          : value === "4"
          ? "Standby"
          : value === "5"
          ? "Storage"
          : null;
      let totalRows = countFormType[formType];
      setTotalRows(totalRows);
      setTotalPage(Math.floor(totalRows / perPage) + 1);
    }
  }, [filteredData, filteredMaintenanceStatus, value, page]);

  async function fetchFHFC() {
    // setSubLoading(true);
    const fetch = makeAPIRequestRun(`get`, `acarsdata/fleetgetfcfh/null`);
    fetch
      .then((response) => {
        setDataFHFC(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  useEffect(() => {
    setPage(1);
  }, [value]);

  useEffect(() => {
    if (value === "1" && ddmlCountFormType.length > 0) {
      setTotalRows(calculateTotal(ddmlCountFormType.count_by_form_type));
      setTotalPage(Math.floor(calculateTotal(ddmlCountFormType.count_by_form_type) / perPage) + 1);
    } else {
      let formType =
        value === "2"
          ? "Maintenance"
          : value === "3"
          ? "AOG"
          : value === "4"
          ? "Standby"
          : value === "5"
          ? "Storage"
          : null;
      let totalRows = countFormType[formType];
      setTotalRows(totalRows);
      setTotalPage(Math.floor(totalRows / perPage) + 1);
    }
  }, [filteredData, filteredMaintenanceStatus, value]);

  function checkAnyKeyHasValue(obj) {
    return Object.values(obj).some((value) => value.length > 0);
  }

  function calculateTotal(obj) {
    let total = 0; // Variable to store the total sum
    Object.values(obj).forEach((value) => {
      total += value; // Add each value to the total
    });
    return total;
  }

  async function fetch(status) {
    const currentDate = new Date(isDate);
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const isToday = `${year}-${month}-${day}`;

    setTableLoading(true);

    let sortOrder = -1;
    let sortColumn = "deletedAt";

    let params = `api/ddml?aoc=${aoc}&form_status=deleted&page=${page}&limit=${perPage}${
      queryFilter ? queryFilter : ""
    }&column=${sortColumn}&order=${sortOrder}`;
    if (isToday !== "NaN-NaN-NaN") {
      const fetch = makeAPIRequestMDB(`get`, params);
      fetch
        .then((response) => {
          setData(response.data?.ddmls_list);
          setFilteredData(response.data?.ddmls_list);
          setDDMLCountFormType(response.data);
          // setTotalRows(calculateTotal(response.data.count_by_form_type));
          // setTotalPage(Math.floor(calculateTotal(response.data.count_by_form_type) / perPage) + 1);
          setSubLoading(false);
          setOpen(false);
          setOpenNotification(false);
          setTriggerApi(false);
          setTableLoading(false);
        })
        .catch((error) => {
          console.log("error", error);
          setSubLoading(false);
        });
    }
  }

  async function fetchFilters() {
    const collection_name = "ddml";
    const form_status = "deleted";
    const filter_fields = "location&filter_fields=aircraftReg&filter_fields=assignedTo";
    const fetch = makeAPIRequestMDB(
      `get`,
      `/api/ddml/collection_filter_list_data?collection_name=${collection_name}&aoc=${aoc}&form_status=${form_status}&filter_fields=${filter_fields}`
    );
    fetch
      .then((response) => {
        setFilterOptions(response.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  async function fetchMaintenanceReport(formType) {
    setTableLoading(true);
    let sortOrder = -1;
    let sortColumn = "deletedAt";
    let params = `api/aircraft/updated-status?aoc=${aoc}&form_type=${formType}&form_status=closed&isArchived=true&page=${page}&limit=${perPage}${
      queryFilter ? queryFilter : ""
    }&column=${sortColumn}&order=${sortOrder}`;
    const fetch = makeAPIRequestMDB(`get`, params);
    fetch
      .then((response) => {
        setCountFormType(response.data.count_by_form_type);
        setMaintenanceStatus(response.data.data_list);
        setFilteredMaintenanceStatus(response.data.data_list);
        setTriggerApi(false);
        setTableLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  async function fetchLimitationData() {
    const fetch = makeAPIRequestMDB(`get`, `api/ddml/limitations?aoc=${aoc}&form_status=open`);
    fetch
      .then((response) => {
        setLimitationData(response?.data?.ddmls_list?.filter((e) => e.limitations === true && e.formStatus === "open"));
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  useEffect(() => {
    let filteredData = data;
    setFilteredData(filteredData);
  }, [value, data]);

  // Custom styles
  const useStyles = makeStyles(() => ({
    ul: {
      "& .Mui-selected": {
        color: "#fff",
        backgroundColor: "#088FD1",
        border: "1px solid #088FD1",
        "&:hover": {
          backgroundColor: "#088FD1",
        },
      },
    },
    gridItem: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-box-orient": "vertical",
      "-webkit-line-clamp": 2, // Number of lines to show
      fontSize: "14px",
    },
  }));

  const classes = useStyles();

  const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    // padding: 0,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: "rgba(0, 0, 0, 0.85)",
    fontFamily: ["Gotham-Book"].join(","),
    "&:hover": {
      color: "#333333",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#333333",
      fontFamily: "Gotham-Medium",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  }));

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setType(newValue);
  };

  const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#FFFEE8",
        color: "rgba(0, 0, 0, 0.87)",
        boxShadow: theme.shadows[1],
        fontSize: 11,
      },
    })
  );

  const ErrorAirBallon = () => {
    return (
      <>
        <div className={styles.errorAirBallon}>
          <center>
            <img src={IconErrorAirBalloon} alt="error-air-ballon" />
            <div className={styles.errorTitle}>No Report Available</div>
            <div className={styles.errorSubTitle}></div>
          </center>
        </div>
      </>
    );
  };

  const Tips = ({ val, unread }) => {
    return (
      <>
        <span
          style={{
            borderRadius: "30px",
            color: unread ? "#ffffff" : "#838383",
            border: unread ? "1px solid #E95048" : "1px solid #E8E8E8",
            backgroundColor: unread ? "#E95048" : "#F7F7F7",
            fontFamily: "Gotham-Book",
            fontSize: "12px",
            marginLeft: "8px",
            padding: "4px 8px 2px 8px",
          }}
        >
          {val}
        </span>
      </>
    );
  };

  const AircraftReportList = ({ type }) => {
    return (
      <>
        <Grid container columns={4} sx={{ marginTop: "0px" }}>
          {/* <Grid item xs={4} className={styles.gridTitle}>
            {type} List
          </Grid> */}
          {/* {data_?.length > 0 && ( */}
          <>
            <Grid item xs={4} sx={{ paddingTop: "32px", paddingLeft: "16px" }}>
              <SearchBar
                open={openModal}
                setOpen={setOpenModal}
                data={filterOptions}
                isSearch={isSearch}
                setIsSearch={setIsSearch}
                isFilters={isFilters}
                setIsFilters={setIsFilters}
                openTitle={openTitle}
                setOpenTitle={setOpenTitle}
              />
              <ActiveFilters
                isFilters={isFilters}
                setIsFilters={setIsFilters}
                initialFilters={InitialFilters}
                setOpen={setOpenModal}
              />
            </Grid>
            <Grid item xs={4} sx={{ paddingTop: "32px" }} onClick={() => setOpenModal(false)}>
              {subLoading ? <Overlay /> : <TableList type={type} />}
            </Grid>
          </>
          {/* )} */}
          {filteredData?.length === 0 && !subLoading && (
            <Grid item xs={4} sx={{ display: "flex", justifyContent: "center" }}>
              <NoData />
            </Grid>
          )}
          {tableLoading && (
            <Grid item xs={4} sx={{ display: "flex", justifyContent: "center" }}>
              <AirplaneLoader />
            </Grid>
          )}
        </Grid>
      </>
    );
  };

  const MaintenanceReportList = ({ type }) => {
    return (
      <>
        <Grid container columns={4} sx={{ marginTop: "0px" }}>
          {/* {filteredMaintenanceStatus?.length > 0 && ( */}
          <>
            <Grid item xs={4} sx={{ paddingTop: "32px", paddingLeft: "16px" }}>
              <SearchBar
                open={openModal}
                setOpen={setOpenModal}
                data={filterOptions}
                isSearch={isSearch}
                setIsSearch={setIsSearch}
                isFilters={isFilters}
                setIsFilters={setIsFilters}
                openTitle={openTitle}
                setOpenTitle={setOpenTitle}
              />
              <ActiveFilters
                isFilters={isFilters}
                setIsFilters={setIsFilters}
                initialFilters={InitialFilters}
                setOpen={setOpenModal}
              />
            </Grid>
            <Grid item xs={4} sx={{ paddingTop: "32px" }} onClick={() => setOpenModal(false)}>
              {subLoading ? (
                <Overlay />
              ) : filteredMaintenanceStatus.filter((e) => e.formType === type).length > 0 ? (
                <TableListMaintenanceStatus type={type} />
              ) : (
                <NoData />
              )}
            </Grid>
          </>
          {/* )} */}
          {filteredMaintenanceStatus?.length === 0 && !subLoading && (
            <Grid item xs={4} sx={{ display: "flex", justifyContent: "center" }}>
              <NoData />
            </Grid>
          )}
          {tableLoading && (
            <Grid item xs={4} sx={{ display: "flex", justifyContent: "center" }}>
              <AirplaneLoader />
            </Grid>
          )}
        </Grid>
      </>
    );
  };

  function handleClick(e, index) {
    setOpen(true);
    setIndex(index);
    setDataForm(e);
    setEdit(false);
    setActiveIndex(index);
    setNewForm(false);
  }

  useEffect(() => {
    if (checkAll) {
      handleCheckAll();
    } else handleUnCheckAll();
    // eslint-disable-next-line
  }, [checkAll]);

  useEffect(() => {
    if (selectToMove.length > 0 && totalRows > 0 && selectToMove.length === totalRows) setCheckAll(true);
  }, [selectToMove, totalRows]);

  function handleCheckAll() {
    let array = [];

    filteredData.map((e) => {
      return array.push(e._id);
    });
    setSelectToMove(array);
  }

  function handleUnCheckAll() {
    setSelectToMove([]);
  }

  const formattedDate = today;

  function calcBalanceFH(interval, raisedAt, val) {
    let expiry = parseInt(interval) + parseInt(raisedAt);
    let current = val;
    let balance = parseInt(expiry) - parseInt(current);
    return parseInt(balance) || "-";
  }

  function calcBalanceFC(interval, raisedAt, val) {
    let expiry = parseInt(interval) + parseInt(raisedAt);
    let current = val;
    let balance = parseInt(expiry) - parseInt(current);
    return parseInt(balance) || "-";
  }

  function calcBalanceCD(calendarDaysStartDate, calendarDaysInterval) {
    let startDate = calendarDaysStartDate;
    let interval = calendarDaysInterval || 0;
    let expiry = addingDate(startDate, interval);
    // let today = setToday();
    let balance = calculateBalanceDay(today?.split("T")[0], expiry?.split("T")[0]);
    return balance;
  }

  const TableList = ({ type }) => {
    // const status = value === "1" ? "closed" : "approved";
    return (
      !tableLoading &&
      filteredData.length > 0 && (
        <>
          <div
            style={{
              width: "100%",
              borderRadius: "12px",
              overflow: "hidden",
              border: "1px solid #E8E8E8",
            }}
          >
            <Grid container columns={13}>
              <Grid item xs={0.5} className={styles.tableHeader}></Grid>
              <Grid item xs={0.5} className={styles.tableHeader}>
                Station
              </Grid>
              <Grid item xs={1} className={styles.tableHeader}>
                A/C Reg.
              </Grid>
              <Grid item xs={3} className={`${styles.tableHeader} ${styles.gridJustifyStart}`}>
                Defect Description
              </Grid>
              <Grid item xs={1} className={styles.tableHeader}>
                DDML No.
              </Grid>
              <Grid item xs={1} className={styles.tableHeader}>
                Ref.
              </Grid>
              <Grid item xs={1} className={styles.tableHeader}>
                Raised Date
              </Grid>
              <Grid item xs={1} className={styles.tableHeader}>
                Expiry/Balance
              </Grid>
              <Grid item xs={1} className={styles.tableHeader}>
                Delete by
              </Grid>
              <Grid item xs={3} className={`${styles.tableHeader} ${styles.gridJustifyStart}`}>
                Rectification Status
              </Grid>
              {filteredData &&
                filteredData.map((e, index) => {
                  let calendarDaysInterval = e.calendarDaysInterval || 0;
                  let calendarDaysStartDate = e.calendarDaysStartDate || e.raisedDate;
                  let expiry = addingDate(calendarDaysStartDate, calendarDaysInterval);
                  let date1 = e.formType === "AMD" ? e.calendarDaysStartDate : formattedDate?.split("T")[0];
                  let date2 = expiry;
                  let balance = calculateBalanceDay(date1, date2);
                  let showInTable = e.showInTable;
                  let currentFlightHour = dataFHFC.filter((item) => item.AC_REG === e.aircraftReg)[0]?.flight_hour;
                  let currentFlightCycle = dataFHFC.filter((item) => item.AC_REG === e.aircraftReg)[0]?.flight_cycle;
                  if (showInTable === "fh" && e.flightHours && e.formType === "STR/FC/FH")
                    balance = calcBalanceFH(e.flightHoursInterval, e.flightHoursRaisedAt, currentFlightHour);
                  else if (showInTable === "fc" && e.flightCycle && e.formType === "STR/FC/FH")
                    balance = calcBalanceFC(e.flightCycleInterval, e.flightCycleRaisedAt, currentFlightCycle);
                  else if (showInTable === "" && e.formType === "STR/FC/FH") balance = "-";

                  let fcfhIndicator = " (FC)";
                  if (showInTable === "fh" && e.flightHours) fcfhIndicator = " (FH)";
                  else if (showInTable === "fc" && e.flightCycle) fcfhIndicator = " (FC)";

                  if (!e.flightCycle && !e.flightHours && !e.calendarDays && e.formType === "STR/FC/FH") balance = "-";
                  if (e.formType === "AMD")
                    balance = calculateCalendarBalance(e.calendarDaysStartDate, e.calendarDaysInterval);
                  if (e.formType === "Fuel Sampling" || e.formType === "Hydraulic Sampling")
                    balance = calculateCalendarBalance(e.calendarDaysStartDate, e.calendarDaysInterval);
                  let expiryDate_ = formatDateDasr(e.expiryDate);
                  if (e.formType !== "STR/FC/FH") {
                    if (e.formType === "MEL CAT" && e.calendarDays)
                      // expiryDate_ = calculateCalendarBalanceDate(e.calendarDaysStartDate, e.calendarDaysInterval);
                      expiryDate_ = calculateCalendarExpiryDate(e.calendarDaysStartDate, e.calendarDaysInterval);
                    else if (e.formType !== "MEL CAT")
                      expiryDate_ = calculateCalendarExpiryDate(e.calendarDaysStartDate, e.calendarDaysInterval);
                  }
                  if (e.calendarDays) balance = calcBalanceCD(calendarDaysStartDate, calendarDaysInterval);
                  // Calculate the date difference
                  var currentDate = new Date();
                  var timeDifferenceMs = new Date(e.expiryDate) - currentDate;
                  var remainingDays = Math.ceil(timeDifferenceMs / (1000 * 60 * 60 * 24));

                  // let balanceSTR = balance;
                  let balanceSTR = !isNaN(parseInt(balance)) && balance !== "-" ? balance + fcfhIndicator : "-";
                  if (e.calendarDays) balanceSTR = balance + " Days";

                  let color = "";

                  if (e.formType === "STR/FC/FH" && (e.flightHours || e.flightHours) && balance <= 20)
                    color = "#E95048";
                  else if (e.formType === "STR/FC/FH" && e.calendarDays && balance <= 3) color = "#E95048";
                  else if (e.formType === "MEL CAT" && remainingDays <= 3) color = "#E95048";
                  else if (remainingDays <= 3) color = "#E95048";

                  return (
                    <>
                      <Grid item xs={13}>
                        <Grid
                          container
                          columns={13}
                          className={`${styles.lineItems} ${activeIndex === index ? styles.selectedIndex : ""}`}
                          sx={{ zIndex: 1 }}
                        >
                          <Grid item xs={0.5} className={`${styles.tableBody}`} onClick={() => handleClick(e, index)}>
                            <LightTooltip
                              title={e.limitations ? "Limitation triggered" : ""}
                              followCursor
                              placement="bottom-start"
                            >
                              {e.limitations && (
                                <img
                                  src={IconAirplaneLimitation}
                                  alt={"warning-circle"}
                                  height={20}
                                  style={{ marginLeft: "4px" }}
                                />
                              )}
                            </LightTooltip>
                            <LightTooltip
                              title={e.reInspection ? "Reinspection" : ""}
                              followCursor
                              placement="bottom-start"
                            >
                              {e.reInspection && (
                                <img
                                  src={IconReInspection}
                                  alt={"icon-reinspection"}
                                  height={20}
                                  style={{ marginLeft: "4px" }}
                                />
                              )}
                            </LightTooltip>
                          </Grid>
                          <Grid
                            item
                            xs={0.5}
                            className={`${styles.tableBody} ${styles.toUpperCase}`}
                            onClick={() => handleClick(e, index)}
                          >
                            {e.location}
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            className={`${styles.tableBody} ${styles.toUpperCase}`}
                            onClick={() => handleClick(e, index)}
                          >
                            {e.aircraftReg}
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            className={`${styles.tableBody} ${styles.toUpperCase}`}
                            sx={{ display: "flex", justifyContent: "flex-start" }}
                            onClick={() => handleClick(e, index)}
                          >
                            <LightTooltip title={e.defectDesc} followCursor placement="bottom-start">
                              <div className={classes.gridItem}>{e.defectDesc}</div>
                            </LightTooltip>
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            className={`${styles.tableBody} ${styles.toUpperCase}`}
                            onClick={() => handleClick(e, index)}
                          >
                            {e.ddmlNo}
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            className={`${styles.tableBody} ${styles.toUpperCase}`}
                            onClick={() => handleClick(e, index)}
                          >
                            <center>{e.melRef}</center>
                          </Grid>
                          <Grid item xs={1} className={`${styles.tableBody}`} onClick={() => handleClick(e, index)}>
                            <center>{formatDateDasr(e.raisedDate)}</center>
                            {/* {e.raisedDate} */}
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            className={`${styles.tableBody}`}
                            sx={{
                              color: color,
                            }}
                            onClick={() => handleClick(e, index)}
                          >
                            <center>
                              {e.formType === "STR/FC/FH"
                                ? balanceSTR
                                : e.formType === "MEL CAT"
                                ? formatDateDasr(e.expiryDate)
                                : calculateCalendarExpiryDate(e.calendarDaysStartDate, e.calendarDaysInterval)}
                            </center>
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            className={`${styles.tableBody} ${styles.toCapitalize} ${styles.flexCenter}`}
                            onClick={() => handleClick(e, index)}
                          >
                            <LightTooltip title={e?.deletedByFullName} followCursor placement="bottom-start">
                              <div className={classes.gridItem}>{e?.deletedByFullName}</div>
                            </LightTooltip>
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            className={`${styles.tableBody} ${styles.toUpperCase} ${styles.flexStart}`}
                            onClick={() => handleClick(e, index)}
                          >
                            <LightTooltip
                              title={getRectificationStatus(e) || e.actionBy145}
                              followCursor
                              placement="bottom-start"
                            >
                              <div className={classes.gridItem}>{getRectificationStatus(e) || e.actionBy145}</div>
                            </LightTooltip>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  );
                })}
              <Grid item xs={13} className={`${styles.tableBodyWhite}`}>
                <MUIPagination
                  page={parseInt(page)}
                  setPage={setPage}
                  setPerPage={setPerPage}
                  totalPage={parseInt(totalPages)}
                  classes={{ ul: classes.ul }}
                  totalRows={parseInt(totalRows)}
                  sliceStart={sliceStart}
                  initialPerPage={perPage}
                />
              </Grid>
            </Grid>
          </div>
        </>
      )
    );
  };

  const TableListMaintenanceStatus = ({ type }) => {
    return (
      !tableLoading &&
      filteredMaintenanceStatus.length > 0 && (
        <>
          <div
            style={{
              width: "100%",
              borderRadius: "12px",
              overflow: "hidden",
              border: "1px solid #E8E8E8",
            }}
          >
            <Grid container columns={12}>
              <Grid item xs={1} className={styles.tableHeader}>
                A/C Reg.
              </Grid>
              {(type === "Maintenance" || type === "AOG") && (
                <>
                  <Grid item xs={1} className={styles.tableHeader}>
                    Start
                  </Grid>
                  <Grid item xs={1} className={styles.tableHeader}>
                    Loc
                  </Grid>
                </>
              )}
              <Grid
                item
                xs={type === "Maintenance" || type === "AOG" ? 4 : 6}
                className={styles.tableHeader}
                sx={{ justifyContent: "flex-start" }}
              >
                {type === "Maintenance" || type === "Standby" ? "Details" : type === "AOG" ? "Defect Description" : ""}
              </Grid>
              {(type === "Maintenance" || type === "AOG") && (
                <>
                  <Grid item xs={1} className={styles.tableHeader}>
                    ETS Date
                  </Grid>
                  <Grid item xs={1} className={styles.tableHeader}>
                    ETS Time
                  </Grid>
                </>
              )}
              <Grid
                item
                xs={type === "Maintenance" || type === "AOG" ? 3 : 5}
                className={styles.tableHeader}
                sx={{ justifyContent: "flex-start" }}
              >
                {type === "Maintenance" || type === "AOG"
                  ? "Rectification Status"
                  : type === "Standby"
                  ? "Limitation"
                  : ""}
              </Grid>
              {filteredMaintenanceStatus
                .filter((e) => e.formType === type)
                .map((e) => {
                  return (
                    <Grid item xs={12}>
                      <Grid container columns={12}>
                        <Grid item xs={1} className={`${styles.tableBody} ${styles.toUpperCase}`}>
                          {e.aircraftReg}
                        </Grid>
                        {(type === "Maintenance" || type === "AOG") && (
                          <>
                            <Grid item xs={1} className={`${styles.tableBody}`}>
                              {formatDateDasr(e.inputDate)}
                            </Grid>
                            <Grid item xs={1} className={`${styles.tableBody} ${styles.toUpperCase}`}>
                              {e.location || "-"}
                            </Grid>
                          </>
                        )}
                        <Grid
                          item
                          xs={type === "Maintenance" || type === "AOG" ? 4 : 6}
                          className={`${styles.tableBody} ${styles.toUpperCase}`}
                          sx={{ alignItems: "center", justifyContent: "flex-start" }}
                        >
                          <LightTooltip title={e.defectDesc} followCursor placement="bottom-start">
                            <div className={classes.gridItem}>{e.defectDesc || "-"}</div>
                          </LightTooltip>
                        </Grid>
                        {(type === "Maintenance" || type === "AOG") && (
                          <>
                            <Grid item xs={1} className={`${styles.tableBody}`} sx={{ alignItems: "center" }}>
                              {e.tba === true ? "TBA" : formatDateDasr(e.ets)}
                            </Grid>
                            <Grid item xs={1} className={`${styles.tableBody}`} sx={{ alignItems: "center" }}>
                              {e.tba === true ? "TBA" : formatTimeLocal(e.etsTime)}
                            </Grid>
                          </>
                        )}
                        <Grid
                          item
                          xs={type === "Maintenance" || type === "AOG" ? 3 : 5}
                          className={`${styles.tableBody} ${styles.toUpperCase}`}
                          sx={{ justifyContent: "flex-start" }}
                        >
                          <LightTooltip title={e.rectificationStatus} followCursor placement="bottom-start">
                            <div className={classes.gridItem}>
                              {type === "Standby" ? getLimitations(e.aircraftReg) : e.rectificationStatus || "-"}
                            </div>
                          </LightTooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
              <Grid item xs={12} className={`${styles.tableBodyWhite}`}>
                <MUIPagination
                  page={parseInt(page)}
                  setPage={setPage}
                  setPerPage={setPerPage}
                  totalPage={totalPages}
                  classes={{ ul: classes.ul }}
                  totalRows={totalRows}
                  sliceStart={sliceStart}
                  initialPerPage={perPage}
                />
              </Grid>
            </Grid>
          </div>
        </>
      )
    );
  };

  function getRectificationStatus(item) {
    // Check for active Summary Status selection
    if (isFilters.summaryStatus.length > 0) {
      let status =
        isFilters.summaryStatus[0] === "Rectification Status"
          ? item.updateStatusLog.rectificationUpdate
          : isFilters.summaryStatus[0] === "Material Summary"
          ? item.updateStatusLog.materialUpdate
          : item.rectificationStatus;
      return status;
    } else {
      if (item?.statusLog?.pushToSummary === "") return undefined;
      else {
        return item?.statusLog?.updateLogDesc;
      }
    }
  }

  function getLimitations(aircraftReg) {
    let data = limitationData.filter((e) => e.aircraftReg === aircraftReg);
    if (data.length > 0) {
      return data[0].limitationsDesc;
    } else return "-";
  }

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return (
      name && {
        sx: {
          bgcolor: stringToColor(name),
          fontSize: "12px",
        },
        // children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        children: `${name.substring(0, 3)}`,
      }
    );
  }

  function calcTipsCount(type) {
    if (type === "DDML Reports") return calculateTotal(ddmlCountFormType.count_by_form_type);
    else return countFormType[type] || 0;
  }

  return !isLoading && !subLoading ? (
    <>
      <div id="main" className={`${styles.container}`}>
        <>
          <Helmet>
            <title>Royal Brunei Airlines - ELEVADE - Archive Records</title>
          </Helmet>
          <Grid container columns={8} className={styles.gridContainer}>
            <Grid item xs={8} className={styles.gridTitle} onClick={() => setOpenModal(false)}>
              <Link to={"/fleet/dasr"}>Archive Records</Link>
            </Grid>
            <Grid item xs={8}>
              <TabContext value={value}>
                <TabList onChange={handleChange} aria-label="lab API tabs example" onClick={() => setOpenModal(false)}>
                  <AntTab
                    label="DDML Reports"
                    value="1"
                    icon={<Tips val={calcTipsCount("DDML Reports")} unread={false} />}
                    iconPosition="end"
                  />
                  <AntTab
                    label="Maintenance"
                    value="2"
                    icon={<Tips val={calcTipsCount("Maintenance")} unread={false} />}
                    iconPosition="end"
                  />
                  <AntTab
                    label="AOG"
                    value="3"
                    icon={<Tips val={calcTipsCount("AOG")} unread={false} />}
                    iconPosition="end"
                  />
                  <AntTab
                    label="Standby"
                    value="4"
                    icon={<Tips val={calcTipsCount("Standby")} unread={false} />}
                    iconPosition="end"
                  />
                </TabList>
                <div
                  style={{
                    width: "100%",
                    height: "2px",
                    backgroundColor: "#d1d1d1",
                    marginTop: "-2px",
                  }}
                ></div>
                {tableLoading ? (
                  <AirplaneLoader />
                ) : (
                  <div
                    style={{
                      marginLeft: "-20px",
                      marginRight: "-20px",
                      marginTop: "-24px",
                    }}
                  >
                    <TabPanel value="1">
                      <AircraftReportList type={"approved"} />
                    </TabPanel>
                    <TabPanel value="2">
                      <MaintenanceReportList type={"Maintenance"} />
                    </TabPanel>
                    <TabPanel value="3">
                      <MaintenanceReportList type={"AOG"} />
                    </TabPanel>
                    <TabPanel value="4">
                      <MaintenanceReportList type={"Standby"} />
                    </TabPanel>
                  </div>
                )}
              </TabContext>
            </Grid>
          </Grid>
        </>
      </div>
      <ElevadeDrawer
        open={open}
        setOpen={setOpen}
        data={data}
        Component={DDML}
        aoc={aoc}
        type={type}
        index={index}
        dataForm={dataForm}
        edit={edit}
        setEdit={setEdit}
        openNotification={openNotification}
        setOpenNotification={setOpenNotification}
        newForm={newForm}
        setNewForm={setNewForm}
        triggerApi={triggerApi}
        setTriggerApi={setTriggerApi}
        setSelectToMove={setSelectToMove}
        setOpenDialogMoveToAssignment={setOpenDialogMoveToAssignment}
        hideButton={true}
      />
      <DialogApprove
        open={openDialogApprove}
        setOpen={setOpenDialogApprove}
        data={selectToMove}
        setTriggerApi={setTriggerApi}
        setData={setSelectToMove}
      />
    </>
  ) : (
    <AirplaneLoader />
  );
};

export default ArchiveAircraftReport;
