import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useHistory } from "react-router-dom";
import { Grid, styled, Tab, Avatar, Tooltip, tooltipClasses } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import styles from "../Dasr.module.css";
import IconBackArrow from "common/images/icons/elevade/Icon-BackArrow.svg";
import DDML from "./DDML";
import SearchBar from "./SearchBar";
import ActiveFilters from "./ActiveFilters";
import {
  formatDateDasr,
  calculateBalanceDay,
  addingDate,
  calculateCalendarBalance,
  calculateCalendarExpiryDate,
  formatDateDasrWithTime,
  ConvertUTCToLocalDatetime,
  getSummaryStatus,
  calcBalanceFH,
  calcBalanceFC,
  calcBalanceCD,
} from "pages/elevade/component/Helpers";
import MUIPagination from "pages/elevade/component/Pagination";
import { makeStyles } from "@mui/styles";
import Overlay from "../../component/Overlay";
import IconAirplaneLimitation from "common/images/icons/elevade/Icon-AirplaneLimitation.svg";
import IconDustbin from "common/images/icons/elevade/Icon-Dustbin.svg";
import IconDustbinWhite from "common/images/icons/elevade/Icon-DustbinWhite.svg";
import IconDustbinDisabled from "common/images/icons/elevade/Icon-DustbinDisabled.svg";
import IconBriefcase from "common/images/icons/elevade/Icon-Briefcase.svg";
import IconBriefcaseWhite from "common/images/icons/elevade/Icon-BriefcaseWhite.svg";
import IconBriefcaseDisabled from "common/images/icons/elevade/Icon-BriefcaseDisabled.svg";
import IconReInspection from "common/images/icons/elevade/Icon-ReInspection.svg";
import IconStandby from "common/images/icons/elevade/Icon-Standby.svg";
import IconUnderMaintenance from "common/images/icons/elevade/Icon-UnderMaintenance.svg";
import IconAOG from "common/images/icons/elevade/Icon-AOG.svg";
import IconStorage from "common/images/icons/elevade/Icon-Storage.svg";
import IconScheduled from "common/images/icons/elevade/Icon-Scheduled.svg";
import { CheckBoxField, CheckBoxFieldMock } from "pages/elevade/component/InputForm";
import ElevadeDrawer from "../component/Drawer";
import Button from "pages/elevade/component/Button";
import DialogMoveAssignment from "./DialogMoveAssigment";
import DialogDelete from "./DialogDelete";
import { makeAPIRequestRun, makeAPIRequestMDB } from "common/helpers";
import AirplaneLoader from "pages/elevade/component/AirplaneLoader";
import Sorting from "../component/Sorting";
import NoData from "pages/elevade/component/DataUnavailable";
import { Helmet } from "react-helmet";

const AircraftReport = ({ aoc, isLoading, setIsLoading }) => {
  const { userPermission } = useSelector((state) => state.userEntity);
  const summaryPermission = userPermission.filter((perm) => perm.code === "DASR-004");
  const DDMLPermissions = userPermission.filter((perm) => perm.code === "DASR-003");
  const [subLoading, setSubLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(true);
  const [type, setType] = useState("1");
  const [value, setValue] = useState("1");
  const [open, setOpen] = useState(false);
  const [openTitle, setOpenTitle] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [countByFormType, setCountByFormType] = useState(0);
  const [dataForm, setDataForm] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [index, setIndex] = useState();
  const [edit, setEdit] = useState(true);
  const [openNotification, setOpenNotification] = useState(false);
  const [newForm, setNewForm] = useState(false);
  const [triggerApi, setTriggerApi] = useState(true);
  const [activeIndex, setActiveIndex] = useState("");
  const [selectToMove, setSelectToMove] = useState([]);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [openDialogMoveToAssignment, setOpenDialogMoveToAssignment] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [isSearch, setIsSearch] = useState([]);
  const InitialFilters = {
    search: [],
    location: [],
    aircraftReg: [],
    assignedTo: [],
    raisedDate: [],
    expiryDate: [],
    status: [],
    summaryStatus: [],
  };
  const [isFilters, setIsFilters] = useState({
    search: [],
    location: [],
    aircraftReg: [],
    assignedTo: [],
    raisedDate: [],
    expiryDate: [],
    status: [],
    summaryStatus: [],
  });
  const [dataFHFC, setDataFHFC] = useState([]);
  const [sortingColumn, setSortingColumn] = useState("");
  const [sortingDirection, setSortingDirection] = useState("asc");
  const [filterOptions, setFilterOptions] = useState();
  const [queryFilter, setQueryFilter] = useState();
  const [ddmlFormType, setDddmlFormType] = useState();
  const [latestCollectionUpdate, setLatestCollectionUpdate] = useState("");

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const paramsId = urlParams.get("id");
  const history = useHistory();
  const pathname = useLocation().pathname;

  const today = new Date();
  const formattedDate = today.toISOString();

  let sliceStart = page > 0 ? perPage * (page - 1) : 0;

  useEffect(() => {
    setIsLoading(false);
    // eslint-disable-next-line
  }, []);

  // Fetching data based on form type
  async function fetchByType(formType, queryFilter, objectId, sortingColumn, sortingDirection) {
    setTableLoading(true);
    let sortOrder = 1;
    let sortColumn = "expiryDate";
    if (sortingColumn !== "" && sortingDirection !== "") {
      if (sortingDirection === "desc") sortOrder = -1;
      sortColumn = sortingColumn;
    }
    if (sortColumn === undefined) sortColumn = "expiryDate";
    let url =
      objectId !== "" && objectId !== undefined
        ? `api/ddml?aoc=${aoc}&form_status=open&page=${page}&limit=${perPage}${
            queryFilter ? queryFilter : ""
          }${objectId}&column=${sortColumn}&order=${sortOrder}`
        : `api/ddml?aoc=${aoc}&form_status=open&page=${page}&limit=${perPage}&form_type=${formType}${
            queryFilter ? queryFilter : ""
          }&column=${sortColumn}&order=${sortOrder}`;
    const fetch = makeAPIRequestMDB(`get`, url);
    fetch.then((response) => {
      setCountByFormType(response.data.count_by_form_type);
      if (paramsId === null) setOpen(false);
      setOpenNotification(false);
      setTriggerApi(false);
      setData(response.data.ddmls_list);
      // Set data for pagination options
      let totalRows = response.data.count_by_form_type[formType];
      let totalPage = Math.floor(totalRows / perPage);
      if (totalRows % perPage !== 0) totalPage = totalPage + 1;
      setTotalRows(totalRows);
      setTotalPage(totalPage);
      if (objectId) {
        if (response.data.ddmlFormType) {
          setDddmlFormType(response.data.ddmlFormType);
          setTotalRows(response.data.count_by_form_type[response.data.ddmlFormType]);
          setPage(response.data.ddmlPageNo);
        }
      }

      setSubLoading(false);
      setTableLoading(false);
    });
  }

  async function fetchFHFC() {
    const fetch = makeAPIRequestRun(`get`, `acarsdata/fleetgetfcfh/null`);
    fetch
      .then((response) => {
        setDataFHFC(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  useEffect(() => {
    fetchFHFC();
    fetchFilters();
    fetchLastestUpdateCollection();
  }, []);

  async function fetchFilters() {
    const collection_name = "ddml";
    const form_status = "open";
    const filter_fields = "location&filter_fields=aircraftReg&filter_fields=assignedTo&filter_fields=aircraftStatus";
    const fetch = makeAPIRequestMDB(
      `get`,
      `api/ddml/collection_filter_list_data?collection_name=${collection_name}&aoc=${aoc}&form_status=${form_status}&filter_fields=${filter_fields}`
    );
    fetch
      .then((response) => {
        setFilterOptions(response.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  async function fetchLastestUpdateCollection() {
    const fetch = makeAPIRequestRun(`get`, `dasr/conduce`);
    fetch
      .then((response) => {
        setLatestCollectionUpdate(response[0].ingested_at.value);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  // TODO: To check why drawer open twice
  useEffect(() => {
    if (paramsId !== null) {
      let data = filteredData.filter((e) => e._id === paramsId);

      if (data.length > 0) {
        let formType = ddmlFormType;
        if (formType === "MEL CAT") setValue("1");
        else if (formType === "AMD") setValue("2");
        else if (formType === "STR/FC/FH") setValue("3");
        else if (formType === "Hydraulic Sampling") setValue("4");
        else if (formType === "Fuel Sampling") setValue("5");
        setDataForm(data[0]);
        setOpen(true);
      } else {
        // setDataForm([]);
        // setOpen(false);
      }
      setEdit(false);
      setNewForm(false);
    }
  }, [paramsId, filteredData, ddmlFormType]);

  useEffect(() => {
    setPage(1);
  }, [type]);

  useEffect(() => {
    let objectId = "";
    let formType = "";
    if (value === "1") formType = "MEL CAT";
    else if (value === "2") formType = "AMD";
    else if (value === "3") formType = "STR/FC/FH";
    else if (value === "4") formType = "Hydraulic Sampling";
    else if (value === "5") formType = "Fuel Sampling";
    if (paramsId !== null) objectId = "&ddml_id=" + paramsId;
    if (triggerApi) fetchByType(formType, queryFilter, objectId, sortingColumn, sortingDirection);
    setCheckAll(false);
    // eslint-disable-next-line
  }, [triggerApi, aoc, queryFilter]);

  useEffect(() => {
    if (!open) setActiveIndex();
    setOpenModal(false);
  }, [open]);

  useEffect(() => {
    setTriggerApi(true);
  }, [queryFilter]);

  useEffect(() => {
    setSelectToMove([]);
    setCheckAll(false);
    setOpenModal(false);
    let formType = "";
    if (type === "1") formType = "MEL CAT";
    else if (type === "2") formType = "AMD";
    else if (type === "3") formType = "STR/FC/FH";
    else if (type === "4") formType = "Hydraulic Sampling";
    else if (type === "5") formType = "Fuel Sampling";
  }, [value]);

  // A useEffect funtion to fetch the data based on form type (value), page & per page value
  useEffect(() => {
    // if (queryFilter !== undefined) {
    if (value === "1") fetchByType("MEL CAT", queryFilter, "", sortingColumn, sortingDirection);
    else if (value === "2") fetchByType("AMD", queryFilter, "", sortingColumn, sortingDirection);
    else if (value === "3") fetchByType("STR/FC/FH", queryFilter, "", sortingColumn, sortingDirection);
    else if (value === "4") fetchByType("Hydraulic Sampling", queryFilter, "", sortingColumn, sortingDirection);
    else if (value === "5") fetchByType("Fuel Sampling", queryFilter, "", sortingColumn, sortingDirection);
    // }
  }, [page, perPage]);

  useEffect(() => {
    setTriggerApi(true);
  }, [sortingColumn, sortingDirection]);

  // Applying filters
  useEffect(() => {
    let theData = data;
    setFilteredData(theData);
  }, [data]);

  useEffect(() => {
    let search = "";
    let station = "";
    let aircraft_reg = "";
    let department = "";
    let raised_date = "";
    let expiry_date = "";
    let aircraft_status = "";
    if (isFilters.search.length > 0) {
      search = "&search_ddml=" + isFilters.search.join(",");
    }
    if (isFilters.location.length > 0) {
      station = "&station=" + isFilters.location.join(",");
    }
    if (isFilters.aircraftReg.length > 0) {
      aircraft_reg = "&aircraft_reg=" + isFilters.aircraftReg.join(",");
    }
    if (isFilters.assignedTo.length > 0) {
      department = "&department=" + isFilters.assignedTo.join(",");
    }
    if (isFilters.raisedDate.length > 0) {
      raised_date = "&raised_date=" + isFilters.raisedDate.join(",");
    }
    if (isFilters.expiryDate.length > 0) {
      expiry_date = "&expiry_date=" + isFilters.expiryDate.join(",");
    }
    if (isFilters.status.length > 0) {
      aircraft_status = "&aircraft_status=" + isFilters.status.join(",");
    }
    let query = search + station + aircraft_reg + department + raised_date + expiry_date + aircraft_status;
    if (query === "") setQueryFilter();
    else setQueryFilter(query);
    setPage(1);
  }, [isFilters]);

  useEffect(() => {
    if (selectToMove.length === 0) setCheckAll(false);
  }, [selectToMove]);

  useEffect(() => {
    if (checkAll) {
      handleCheckAll();
    } else handleUnCheckAll();
    // eslint-disable-next-line
  }, [checkAll]);

  useEffect(() => {
    if (selectToMove.length > 0 && filteredData.length > 0 && selectToMove.length === filteredData.length)
      setCheckAll(true);
  }, [selectToMove, filteredData]);

  // Custom styles
  const useStyles = makeStyles(() => ({
    ul: {
      "& .Mui-selected": {
        color: "#fff",
        backgroundColor: "#088FD1",
        border: "1px solid #088FD1",
        "&:hover": {
          backgroundColor: "#088FD1",
        },
      },
    },
    gridItem: {
      display: "-webkit-box",
      WebkitLineClamp: 2,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    gridItemStatus: {
      display: "-webkit-box",
      WebkitLineClamp: 2,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "pre-wrap",
    },
  }));

  const classes = useStyles();

  const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: "rgba(0, 0, 0, 0.85)",
    fontFamily: ["Gotham-Book"].join(","),
    "&:hover": {
      color: "#333333",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#333333",
      fontFamily: "Gotham-Medium",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  }));

  const handleChange = (event, newValue) => {
    if (paramsId) history.replace(pathname);
    setValue(newValue);
    setType(newValue);
    let formType = "";
    if (newValue === "1") formType = "MEL CAT";
    else if (newValue === "2") formType = "AMD";
    else if (newValue === "3") formType = "STR/FC/FH";
    else if (newValue === "4") formType = "Hydraulic Sampling";
    else if (newValue === "5") formType = "Fuel Sampling";
    fetchByType(formType, queryFilter, undefined, sortingColumn, sortingDirection);
  };

  const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme, ...props }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#FFFEE8",
        color: "rgba(0, 0, 0, 0.87)",
        boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.08)",
        fontSize: 11,
        whiteSpace: props.breakLine || "normal",
      },
    })
  );

  const Tips = ({ val, unread }) => {
    const total = val.split("|")[0];
    const color = val.split("|")[1];
    const bgColor = val.split("|")[2];
    return (
      <>
        <span
          style={{
            borderRadius: "30px",
            color: color,
            border: `1px solid ${bgColor}`,
            backgroundColor: bgColor,
            fontFamily: "Gotham-Book",
            fontSize: "12px",
            marginLeft: "8px",
            padding: "4px 8px 2px 8px",
          }}
        >
          {total !== undefined && total !== "undefined" ? total : 0}
        </span>
      </>
    );
  };

  const AircraftReportList = ({ type }) => {
    return (
      <>
        <Grid container columns={4} sx={{ marginTop: "0px" }}>
          {/* {data_ !== undefined && data_ !== "" && data_?.length > 0 && ( */}
          <>
            <Grid item xs={4} sx={{ paddingTop: "32px", paddingLeft: "16px" }}>
              <SearchBar
                open={openModal}
                setOpen={setOpenModal}
                data={filterOptions}
                isSearch={isSearch}
                setIsSearch={setIsSearch}
                isFilters={isFilters}
                setIsFilters={setIsFilters}
                openTitle={openTitle}
                setOpenTitle={setOpenTitle}
                aoc={aoc}
              />
              <span onClick={() => setOpenModal(false)}>
                <ActiveFilters isFilters={isFilters} setIsFilters={setIsFilters} initialFilters={InitialFilters} />
              </span>
            </Grid>
            {data?.length > 0 ? (
              <>
                <Grid item xs={4} sx={{ display: "flex", paddingTop: "32px" }} onClick={() => setOpenModal(false)}>
                  <Button
                    title="Delete"
                    icon={IconDustbin}
                    iconWhite={IconDustbinWhite}
                    iconDisabled={IconDustbinDisabled}
                    // variant={selectToMove.length > 0 ? "outlined" : "disabled"}
                    variant={DDMLPermissionDelete()}
                    onClick={() => setOpenDialogDelete(true)}
                  />
                  <Button
                    title="Move to Assignment Draft List"
                    icon={IconBriefcase}
                    iconWhite={IconBriefcaseWhite}
                    iconDisabled={IconBriefcaseDisabled}
                    // variant={selectToMove.length > 0 ? "outlined" : "disabled"}
                    variant={moveToAssignmentDraftPerm()}
                    onClick={() => setOpenDialogMoveToAssignment(true)}
                  />
                </Grid>
                <Grid item xs={4} sx={{ paddingTop: "16px" }} onClick={() => setOpenModal(false)}>
                  {subLoading ? <Overlay /> : tableLoading ? <AirplaneLoader /> : <TableList type={type} />}
                </Grid>
              </>
            ) : (
              <Grid item xs={4} sx={{ display: "flex", justifyContent: "center" }} onClick={() => setOpenModal(false)}>
                <NoData />
              </Grid>
            )}
          </>
          {/* )} */}
          {/* {(data?.length === 0 || data_?.length === 0) && !subLoading && (
              <Grid item xs={4} sx={{ display: "flex", justifyContent: "center" }} onClick={() => setOpenModal(false)}>
                <ErrorAirBallon type="type" />
              
            )} */}
        </Grid>
      </>
    );
  };

  function handleClick(e, index) {
    setOpen(true);
    setIndex(index);
    setDataForm(e);
    setEdit(false);
    setActiveIndex(index);
    setNewForm(false);
  }

  function handleSelect() {
    if (checkAll) return <CheckBoxFieldMock isCheckedAll={true} setIsCheckedAll={setCheckAll} />;
    else if (selectToMove.length > 0 && selectToMove.length < totalRows) {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          onClick={() => setCheckAll(!checkAll)}
          style={{ cursor: "pointer" }}
        >
          <rect width="20" height="20" rx="4" fill="#088FD1" />
          <path d="M6 10L14 10" stroke="white" stroke-width="3" stroke-linecap="round" />
        </svg>
      );
    } else {
      return summaryPermission.some((perm) => perm.canUpdate === true) ? (
        <CheckBoxFieldMock
          isCheckedAll={selectToMove.length > 0 && totalRows > 0 && selectToMove.length === totalRows}
          setIsCheckedAll={setCheckAll}
        />
      ) : (
        <CheckBoxField id={""} selected={selectToMove} setSelected={setSelectToMove} disabled={true} />
      );
    }
  }

  function handleCheckAll() {
    let array = [];
    let formType =
      value === "1"
        ? "MEL CAT"
        : value === "2"
        ? "AMD"
        : value === "3"
        ? "STR/FC/FH"
        : value === "4"
        ? "Hydraulic Sampling"
        : value === "5"
        ? "Fuel Sampling"
        : undefined;

    filteredData
      .filter(
        (e) =>
          e.formType === formType &&
          e.formStatus === "open" &&
          (e.moveToAssignment === null || e.moveToAssignment === false)
      )
      .map((e) => {
        return array.push(e._id);
      });
    setSelectToMove(array);
  }

  function handleUnCheckAll() {
    setSelectToMove([]);
  }

  function setToday() {
    // Create a new Date object
    const currentDate = new Date();

    // Get the individual components of the date
    const year = currentDate.getFullYear();
    // JavaScript months are 0-indexed, so we add 1 to get the correct month
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const day = currentDate.getDate().toString().padStart(2, "0");

    // Create a string in the desired format
    const formattedDate = `${year}-${month}-${day}T00:00:00`;
    return formattedDate;
  }

  const IconUnreadIndicator = ({ data }) => {
    let unreadLogs = data.unreadLogs;
    let count = unreadLogs?.materialTool.length + unreadLogs?.planningTech.length + unreadLogs?.rectification.length;

    return (
      count > 0 && (
        <div style={{ position: "absolute", right: "24px" }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
            <circle cx="5" cy="5" r="5" fill="#0ADDDD" />
          </svg>
        </div>
      )
    );
  };

  function getDate(value, fallback) {
    return value || fallback;
  }

  function getCurrentFlightData(reg, data) {
    if (data.length > 0) {
      const record = data.find((item) => item.AC_REG === reg);
      return record ? { flightHour: record.flight_hours, flightCycle: record.flight_cycle } : {};
    }
    return {};
  }

  function getBalance(e, calendarDaysStartDate, calendarDaysInterval, currentFlightHour, currentFlightCycle) {
    if (e.formType === "STR/FC/FH") {
      switch (e.showInTable) {
        case "fh":
          if (e.flightHours) return calcBalanceFH(e.flightHoursInterval, e.flightHoursRaisedAt, currentFlightHour);
          break;
        case "fc":
          if (e.flightCycle) return calcBalanceFC(e.flightCycleInterval, e.flightCycleRaisedAt, currentFlightCycle);
          break;
        default:
          return calcBalanceCD(calendarDaysStartDate, calendarDaysInterval);
      }
    }

    if (e.formType === "AMD" || e.formType === "Fuel Sampling" || e.formType === "Hydraulic Sampling") {
      return calculateCalendarBalance(e.calendarDaysStartDate, e.calendarDaysInterval);
    }

    if (!e.flightCycle && !e.flightHours && !e.calendarDays && e.formType === "STR/FC/FH") {
      return "-";
    }

    return calcBalanceCD(calendarDaysStartDate, calendarDaysInterval);
  }

  function getExpiryDate(e) {
    if (e.formType === "MEL CAT" && e.flightDays) {
      return calculateCalendarExpiryDate(e.flightDaysStartDate, e.flightDaysInterval);
    }

    if (e.calendarDays) {
      return calculateCalendarExpiryDate(e.calendarDaysStartDate, e.calendarDaysInterval);
    }

    return formatDateDasr(e.expiryDate);
  }

  function getFCFHIndicator(showInTable) {
    switch (showInTable) {
      case "fh":
        return " (FH)";
      case "fc":
        return " (FC)";
      default:
        return " Days";
    }
  }

  function getAircraftStatusIcon(status) {
    switch (status) {
      case "Scheduled":
        return (
          <LightTooltip title={status} followCursor placement="bottom-start">
            <img src={IconScheduled} alt="icon-scheduled" height={24} />
          </LightTooltip>
        );
      case "Maintenance":
        return (
          <LightTooltip title={status} followCursor placement="bottom-start">
            <img src={IconUnderMaintenance} alt="icon-maintenance" height={24} />
          </LightTooltip>
        );
      case "AOG":
        return (
          <LightTooltip title={status} followCursor placement="bottom-start">
            <img src={IconAOG} alt="icon-aog" height={24} />
          </LightTooltip>
        );
      case "Standby":
        return (
          <LightTooltip title={status} followCursor placement="bottom-start">
            <img src={IconStandby} alt="icon-standby" height={24} />
          </LightTooltip>
        );
      case "Utilised":
        return (
          <LightTooltip title={status} followCursor placement="bottom-start">
            <img src={IconStandby} alt="icon-standby" height={24} />
          </LightTooltip>
        );
      case "Storage":
        return (
          <LightTooltip title={status} followCursor placement="bottom-start">
            <img src={IconStorage} alt="icon-storage" height={24} />
          </LightTooltip>
        );
      default:
        return null;
    }
  }

  const TableList = ({ type }) => {
    return (
      <>
        <div
          style={{
            width: "100%",
            borderRadius: "12px",
            overflow: "hidden",
            border: "1px solid #E8E8E8",
          }}
        >
          <Grid container columns={13}>
            <Grid item xs={0.4} className={styles.tableHeader}>
              {handleSelect()}
            </Grid>
            <Grid item xs={0.3} className={styles.tableHeader}></Grid>
            <Grid item xs={0.3} className={styles.tableHeader}></Grid>
            <Grid item xs={0.5} className={styles.tableHeader}>
              <Sorting
                col="location"
                title="Station"
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
              />
            </Grid>
            <Grid item xs={0.8} className={styles.tableHeader}>
              <Sorting
                col="aircraftReg"
                title="A/C Reg."
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
              />
            </Grid>
            <Grid item xs={2.7} className={`${styles.tableHeader} ${styles.gridJustifyStart}`}>
              <Sorting
                col="defectDesc"
                title="Defect Description"
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
                align
              />
            </Grid>
            <Grid item xs={1} className={styles.tableHeader}>
              <Sorting
                col="ddmlNo"
                title="DDML No."
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
              />
            </Grid>
            <Grid item xs={1} className={styles.tableHeader}>
              <Sorting
                col="melRef"
                title="Ref."
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
              />
            </Grid>
            <Grid item xs={1} className={styles.tableHeader}>
              <Sorting
                col="raisedDate"
                title="Raised Date"
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
              />
            </Grid>
            <Grid item xs={1} className={styles.tableHeader}>
              <Sorting
                col="expiryDate"
                title={type === "STR/FC/FH" ? "Balance" : "Expiry"}
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
              />
            </Grid>
            <Grid item xs={1} className={styles.tableHeader}>
              <Sorting
                col="assignedTo"
                title="Department"
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
              />
            </Grid>
            <Grid item xs={3} className={`${styles.tableHeader} ${styles.gridJustifyStart}`}>
              {isFilters.summaryStatus[0] || "Rectification Status"}
            </Grid>
            {filteredData &&
              filteredData.map((e, index) => {
                let calendarDaysInterval = getDate(e.calendarDaysInterval, 0);
                let calendarDaysStartDate = getDate(e.calendarDaysStartDate, e.raisedDate);
                let expiry = addingDate(
                  calendarDaysStartDate?.includes("T") ? calendarDaysStartDate?.split("T")[0] : calendarDaysStartDate,
                  calendarDaysInterval
                );
                let date1 = e.formType === "AMD" ? e.calendarDaysStartDate : formattedDate?.split("T")[0];
                let date2 = expiry;
                let balance = calculateBalanceDay(date1, date2);
                let currentFlightData = getCurrentFlightData(e.aircraftReg, dataFHFC);
                balance = getBalance(
                  e,
                  calendarDaysStartDate,
                  calendarDaysInterval,
                  currentFlightData.flightHour,
                  currentFlightData.flightCycle
                );

                let fcfhIndicator = getFCFHIndicator(e.showInTable);
                let expiryDate_ = getExpiryDate(e);

                // Calculate the date difference
                let currentDate = new Date();
                let timeDifferenceMs = new Date(e.expiryDate) - currentDate;
                let remainingDays = Math.ceil(timeDifferenceMs / (1000 * 60 * 60 * 24));

                // let balanceSTR = balance;
                let balanceSTR = !isNaN(parseInt(balance)) && balance !== "-" ? balance + fcfhIndicator : "-";
                if (e.flightHours && e.showInTable === "fh") {
                  balanceSTR = balance + " (FH)";
                } else if (e.flightCycle && e.showInTable === "fc") {
                  balanceSTR = balance + " (FC)";
                } else if (e.calendarDays && e.showInTable === "cd") {
                  balanceSTR = balance + " Days";
                } else {
                  balanceSTR = "-";
                }

                let color = "";
                if (e.formType === "STR/FC/FH") {
                  if ((e.flightHours || e.flightCycle) && parseInt(balance) <= 20) {
                    color = "#E95048";
                  } else if (e.calendarDays && parseInt(balance) <= 3) {
                    color = "#E95048";
                  }
                } else if (e.formType === "MEL CAT" && remainingDays <= 3) {
                  color = "#E95048";
                } else if (e.formType !== "MEL CAT" && e.formType !== "STR/FC/FH" && remainingDays <= 3) {
                  color = "#E95048";
                }

                // let status = isFilters.summaryStatus.length > 0 ? getRectificationStatus(e) : e.actionBy145;
                let status = getSummaryStatus(e, isFilters);

                return (
                  <>
                    <Grid item xs={13}>
                      <Grid
                        container
                        columns={13}
                        className={`${styles.lineItems} ${activeIndex === index ? styles.selectedIndex : ""}`}
                        sx={{
                          zIndex: 1,
                        }}
                      >
                        <Grid item xs={0.4} className={`${styles.tableBody}`} sx={{ zIndex: 3 }} id={e._id}>
                          <CheckBoxField
                            id={e._id}
                            selected={selectToMove}
                            setSelected={setSelectToMove}
                            disabled={
                              summaryPermission.some((perm) => perm.canUpdate === true) ? e.moveToAssignment : true
                            }
                            marginTop="-20px"
                          />
                        </Grid>
                        <Grid item xs={0.3} className={`${styles.tableBody}`} onClick={() => handleClick(e, index)}>
                          <LightTooltip
                            title={e.limitations ? "Limitation triggered" : ""}
                            followCursor
                            placement="bottom-start"
                          >
                            {e.limitations && (
                              <img
                                src={IconAirplaneLimitation}
                                alt={"icon-limitation"}
                                height={20}
                                style={{ marginRight: "4px" }}
                              />
                            )}
                          </LightTooltip>
                          <LightTooltip
                            title={e.reInspection ? "Reinspection" : ""}
                            followCursor
                            placement="bottom-start"
                          >
                            {e.reInspection && (
                              <img
                                src={IconReInspection}
                                alt={"icon-reinspection"}
                                height={20}
                                style={{ marginLeft: "4px" }}
                              />
                            )}
                          </LightTooltip>
                        </Grid>
                        <Grid
                          item
                          xs={0.3}
                          className={`${styles.tableBody} ${styles.toUpperCase}`}
                          onClick={() => handleClick(e, index)}
                        >
                          {getAircraftStatusIcon(e.aircraftStatus)}
                        </Grid>
                        <Grid
                          item
                          xs={0.5}
                          className={`${styles.tableBody} ${styles.toUpperCase}`}
                          onClick={() => handleClick(e, index)}
                        >
                          {e.location || "-"}
                        </Grid>
                        <Grid
                          item
                          xs={0.8}
                          className={`${styles.tableBody} ${styles.toUpperCase}`}
                          onClick={() => handleClick(e, index)}
                        >
                          {e.aircraftReg || "-"}
                        </Grid>
                        <Grid
                          item
                          xs={2.7}
                          className={`${styles.tableBody} ${styles.toUpperCase} ${styles.flexStart}`}
                          onClick={() => handleClick(e, index)}
                        >
                          <LightTooltip title={e.defectDesc} followCursor placement="bottom-start">
                            <div className={classes.gridItem}>{e.defectDesc || "-"}</div>
                          </LightTooltip>
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          className={`${styles.tableBody} ${styles.toUpperCase}`}
                          onClick={() => handleClick(e, index)}
                        >
                          {e.ddmlNo || "-"}
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          className={`${styles.tableBody} ${styles.toUpperCase}`}
                          onClick={() => handleClick(e, index)}
                        >
                          {e.melRef && (
                            <center>
                              {e.melRef}{" "}
                              {e.formType === "MEL CAT" && (
                                <>
                                  <br /> {"(" + e.melCatType + ")"}
                                </>
                              )}
                            </center>
                          )}
                          {!e.melRef && "-"}
                        </Grid>
                        <Grid item xs={1} className={`${styles.tableBody}`} onClick={() => handleClick(e, index)}>
                          <center>{formatDateDasr(e.raisedDate)}</center>
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          className={`${styles.tableBody}`}
                          sx={{
                            color: color,
                          }}
                          onClick={() => handleClick(e, index)}
                        >
                          <center>
                            {type === "STR/FC/FH"
                              ? balanceSTR
                              : type === "MEL CAT" || type === "AMD"
                              ? formatDateDasr(e.expiryDate)
                              : formatDateDasr(addingDate(e.calendarDaysStartDate, e.calendarDaysInterval))}
                          </center>
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          className={`${styles.tableBody} ${styles.toUpperCase}`}
                          onClick={() => handleClick(e, index)}
                        >
                          {e.assignedTo !== "" ? <Avatar {...stringAvatar(e.assignedTo)} /> : "-"}
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          className={`${styles.tableBody} ${styles.toUpperCase}  ${styles.flexStart}`}
                          sx={{
                            position: "relative",
                            paddingRight: "34px",
                          }}
                          onClick={() => handleClick(e, index)}
                        >
                          <LightTooltip
                            title={status}
                            followCursor
                            placement="bottom-start"
                            breakLine={/\r|\n/.test(status) ? "pre-wrap" : "wrap"}
                          >
                            <div className={/\r|\n/.test(status) ? classes.gridItemStatus : classes.gridItem}>
                              {status || "-"}
                            </div>
                          </LightTooltip>
                          <IconUnreadIndicator data={e} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                );
              })}
            <Grid item xs={13} className={`${styles.tableBodyWhite}`}>
              <MUIPagination
                page={parseInt(page)}
                setPage={setPage}
                setPerPage={setPerPage}
                totalPage={parseInt(totalPages)}
                classes={{ ul: classes.ul }}
                totalRows={parseInt(totalRows)}
                sliceStart={sliceStart}
                initialPerPage={perPage}
              />
            </Grid>
          </Grid>
        </div>
      </>
    );
  };

  // function getRectificationStatus(item) {
  //   // Check for active Summary Status selection
  //   if (isFilters.summaryStatus.length > 0) {
  //     let status =
  //       isFilters.summaryStatus[0] === "Rectification Status"
  //         ? item.updateStatusLog.rectificationUpdate
  //         : isFilters.summaryStatus[0] === "Material Summary"
  //         ? item.updateStatusLog.materialUpdate
  //         : isFilters.summaryStatus[0] === "Planning/Tech Service"
  //         ? item.updateStatusLog.ppcUpdate
  //         : isFilters.summaryStatus[0] === "MOC Remarks"
  //         ? item.mocHighlight
  //         : "";
  //     return status;
  //   } else {
  //     return item.updateStatusLog.rectificationUpdate || item.actionBy145;
  //     // if (item?.statusLog?.pushToSummary === "") return undefined;
  //     // else {
  //     //   return item?.statusLog?.updateLogDesc;
  //     // }
  //   }
  // }

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return (
      name && {
        sx: {
          bgcolor: stringToColor(name),
          fontSize: "12px",
        },
        children: `${name.substring(0, 3)}`,
      }
    );
  }

  function calcTipsCount(type) {
    return countByFormType[type] + "|#838383|#F7F7F7";
  }

  //DDML action permission
  function DDMLPermissionDelete() {
    if (DDMLPermissions.some((perm) => perm.canDelete === true)) {
      return selectToMove.length > 0 ? "outlined" : "disabled";
    } else {
      return "disabled";
    }
  }

  function moveToAssignmentDraftPerm() {
    if (summaryPermission.some((perm) => perm.canUpdate === true)) {
      return selectToMove.length > 0 ? "outlined" : "disabled";
    } else {
      return "disabled";
    }
  }

  return !isLoading && !subLoading ? (
    <>
      <Helmet>
        <title>Royal Brunei Airlines - ELEVADE - DDML Reports</title>
      </Helmet>
      <div id="main" className={`${styles.container}`}>
        <>
          <Grid container columns={8.2} className={styles.gridContainer}>
            <Grid item xs={4.2} className={styles.gridTitle}>
              <Link to={"/fleet/dasr"}>
                <img src={IconBackArrow} alt="icon-back" style={{ marginRight: "14px", marginTop: "-4px" }} />
                {aoc} DDML Reports
              </Link>
            </Grid>
            <Grid item xs={4} className={styles.gridJustifyEnd} sx={{ fontSize: "14px", paddingBottom: "16px" }}>
              {/* <Export /> */}
              {/* <Summary /> */}
              {/* <NewReport
                open={open}
                setOpen={setOpen}
                title={"Add DDML"}
                icon={IconPlusCircleWhite}
                onClick={() => {
                  if (DDMLPermissions.some((perm) => perm.canCreate === true)) {
                    setDataForm([]);
                    setOpen(!open);
                    setEdit(true);
                    setNewForm(true);
                  }
                }}
                disabled={addDDMlPermission.some((perm) => perm.canCreate === true) ? false : true}
              /> */}
            </Grid>
            <Grid item xs={8.2} sx={{ paddingTop: "10px" }}>
              <TabContext value={value}>
                <TabList onChange={handleChange} aria-label="lab API tabs example" onClick={() => setOpenModal(false)}>
                  <AntTab
                    label="MEL CAT"
                    value="1"
                    icon={<Tips val={calcTipsCount("MEL CAT")} unread={calcTipsCount("MEL CAT") > 0 ? true : false} />}
                    iconPosition="end"
                  />
                  <AntTab
                    label="AMD"
                    value="2"
                    icon={<Tips val={calcTipsCount("AMD")} unread={calcTipsCount("AMD") > 0 ? true : false} />}
                    iconPosition="end"
                  />
                  <AntTab
                    label="STR/FC/FH"
                    value="3"
                    icon={
                      <Tips val={calcTipsCount("STR/FC/FH")} unread={calcTipsCount("STR/FC/FH") > 0 ? true : false} />
                    }
                    iconPosition="end"
                  />
                  {/* <AntTab
                    label="Hydraulic Sampling"
                    value="4"
                    icon={
                      <Tips
                        val={calcTipsCount("Hydraulic Sampling")}
                        unread={calcTipsCount("Hydraulic Sampling") > 0 ? true : false}
                      />
                    }
                    iconPosition="end"
                  />
                  <AntTab
                    label="Fuel Sampling"
                    value="5"
                    icon={
                      <Tips
                        val={calcTipsCount("Fuel Sampling")}
                        unread={calcTipsCount("Fuel Sampling") > 0 ? true : false}
                      />
                    }
                    iconPosition="end"
                  /> */}
                </TabList>
                <div
                  style={{
                    width: "100%",
                    height: "2px",
                    backgroundColor: "#d1d1d1",
                    marginTop: "-2px",
                  }}
                ></div>
                <div
                  style={{
                    marginLeft: "-20px",
                    marginRight: "-20px",
                    marginTop: "-24px",
                  }}
                >
                  <TabPanel value="1">
                    <AircraftReportList type={"MEL CAT"} />
                  </TabPanel>
                  <TabPanel value="2">
                    <AircraftReportList type={"AMD"} />
                  </TabPanel>
                  <TabPanel value="3">
                    <AircraftReportList type={"STR/FC/FH"} />
                  </TabPanel>
                  {/* <TabPanel value="4">
                    <AircraftReportList type={"Hydraulic Sampling"} />
                  </TabPanel>
                  <TabPanel value="5">
                    <AircraftReportList type={"Fuel Sampling"} />
                  </TabPanel> */}
                </div>
              </TabContext>
            </Grid>
          </Grid>
          <div className={styles.conduceIndicator}>
            *Data retrieved from <span className={styles.conduceWord}>Conduce</span>{" "}
            {latestCollectionUpdate !== ""
              ? `as of ${ConvertUTCToLocalDatetime(latestCollectionUpdate, "Asia/Kuala_Lumpur")}`
              : ""}
          </div>
        </>
      </div>
      <ElevadeDrawer
        open={open}
        setOpen={setOpen}
        data={data}
        Component={DDML}
        aoc={aoc}
        type={type}
        index={index}
        dataForm={dataForm}
        setDataForm={setDataForm}
        edit={edit}
        setEdit={setEdit}
        openNotification={openNotification}
        setOpenNotification={setOpenNotification}
        newForm={newForm}
        setNewForm={setNewForm}
        triggerApi={triggerApi}
        setTriggerApi={setTriggerApi}
        setSelectToMove={setSelectToMove}
        setOpenDialogMoveToAssignment={setOpenDialogMoveToAssignment}
        date={today}
      />
      <DialogMoveAssignment
        open={openDialogMoveToAssignment}
        setOpen={setOpenDialogMoveToAssignment}
        data={selectToMove}
        setTriggerApi={setTriggerApi}
        setData={setSelectToMove}
      />
      <DialogDelete
        open={openDialogDelete}
        setOpen={setOpenDialogDelete}
        data={selectToMove}
        setTriggerApi={setTriggerApi}
        setData={setSelectToMove}
      />
    </>
  ) : (
    <AirplaneLoader />
  );
};

export default AircraftReport;
