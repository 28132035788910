import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Paper,
  Grid,
  TableBody,
  TableHead,
  TableCell,
  tableCellClasses,
  TableRow,
  TableContainer,
  Table,
  Skeleton,
  TableSortLabel,
} from "@mui/material";
import styles from "./manualManagement.module.css";
import { makeAPIRequestRun } from "common/helpers";
import { isEmpty } from "lodash";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import MUIPagination from "../component/Pagination";
import { useSelector } from "react-redux";
import SortIconAscGray from "common/images/icons/elevade/SortIconAscGray.svg";
import SortIconDescGray from "common/images/icons/elevade/SortIconDescGray.svg";
import SortIconAscDescGray from "common/images/icons/elevade/SortIconAscDescGray.svg";
import { Link } from "react-router-dom";
import Overlay from "../component/Overlay";
import SearchBar from "./SearchBar";
import ActiveFilters from "./ActiveFilters";
import { setAOCBasedOnPermission } from "pages/elevade/component/Helpers";
import DataUnavailable from "common/images/icons/elevade/Data-unavailable.svg";

const StyledTableCell = styled(TableCell)({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F2F7FF",
    color: "#333",
    fontSize: 16,
    fontFamily: "Gotham-Medium",
    position: "start",
    "&:hover": {
      cursor: "pointer",
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
});

const useStyles = makeStyles(() => ({
  tableRow: {
    hover: {
      backgroundColor: "#fcfcfc",
    },
  },
  ul: {
    "& .Mui-selected": {
      color: "#fff",
      backgroundColor: "#088FD1",
      border: "1px solid #088FD1",
      "&:hover": {
        backgroundColor: "#088FD1",
      },
    },
  },
}));

const ManualManagement = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const [isSearchInput, setIsSearchInput] = useState("");
  const [status, setStatus] = useState([]);
  const [ACType, setACType] = useState([]);
  // const [ataChapter2Options, setAtaChapter2Options] = useState([]);
  const [ataChapter2, setAtaChapter2] = useState();
  const [totalRows, setTotalRows] = useState(0);
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();
  const [mouseX, setMouseX] = useState();
  const [countISI, setCountISI] = useState(0);
  const [countTFU, setCountTFU] = useState(0);
  const [isSearch, setIsSearch] = useState("");
  const [isFilters, setIsFilters] = useState({
    search: [],
    ac_type: [],
    ata_2d: [],
    article_status: [],
  });
  const [initialData, setInitialData] = useState([]);
  // const [first, setFirst] = useState(true);

  const classes = useStyles();

  // const { profile: userProfile } = useSelector((state) => state.user);
  // const { company: companyInfo } = useSelector((state) => state.company);
  const { companyAOC, userPermission } = useSelector((state) => state.userEntity);
  // let AOC = companyInfo?.permission?.elevadeAOC;
  let AOC = companyAOC;
  // const UserAOC = userProfile?.permission?.elevadeAOC;
  // const UserAOC = userAOC;

  // if (UserAOC && UserAOC !== null && UserAOC !== undefined && UserAOC?.length > 0) AOC = UserAOC;
  AOC = setAOCBasedOnPermission(AOC, userPermission);
  let _aoc = AOC;

  if (typeof AOC === "object") {
    _aoc = "";
    AOC.map((x, index) => {
      return (_aoc += `${x}${index + 1 === AOC.length ? "" : ","}`);
    });
  }

  useEffect(() => {
    setPage(1);
    //eslint-disable-next-line
  }, [status, ataChapter2, isSearchInput, order, ACType]);

  useEffect(() => {
    let filters = "null";
    let search = "null";
    let sort = "null";
    let ata2 = "null";
    let ata4 = "null";

    if (!isEmpty(status)) {
      status.map((x, index) => {
        return (filters += `article_status="${x}"${
          index + 1 === status.length ? "|" : " OR "
        }`);
      });
    }
    if (!isEmpty(ACType)) {
      ACType.map((x, index) => {
        return (filters += `ac_type="${x}"${
          index + 1 === ACType.length ? "|" : " OR "
        }`);
      });
    }
    if (!isEmpty(ataChapter2)) {
      ata2 = "";
      ataChapter2.map((x, index) => {
        return (ata2 += `"${x}"${index + 1 === ataChapter2.length ? "" : ","}`);
      });
    }
    if (!isEmpty(isSearchInput)) {
      search = isSearchInput;
    }
    if (!isEmpty(order)) {
      sort = orderBy + " " + order;
    }
    fetchFilteredManuals(filters, search, sort, ata2, ata4);
    //eslint-disable-next-line
  }, [status, page, limit, activeTab, ataChapter2, isSearchInput, order, ACType]);

  useEffect(() => {
    fetchManuals();
  }, []);

  useEffect(() => {
    setIsSearchInput(isFilters.search);
    setACType(isFilters.ac_type);
    setStatus(isFilters.article_status);
    setAtaChapter2(isFilters.ata_2d);
  }, [isFilters]);

  const UnavailableData = () => {
    return (
      <>
        <div
          style={{ marginTop: "5%", marginBottom: "10%", textAlign: "center" }}
        >
          <div style={{ marginBottom: "16px" }}>
            <img src={DataUnavailable} alt="data-unavailable" />
          </div>
          <div style={{ fontFamily: "Gotham-Medium", fontSize: "14px" }}>
            Data Unavailable
          </div>
          <div style={{ fontFamily: "Gotham-Book", fontSize: "12px" }}>
            There is no data found. Please try a different search term.
          </div>
        </div>
      </>
    );
  };

  const offsetValue = limit * (page - 1) > totalRows ? 0 : limit * (page - 1);
  const skeletonLoader = (numberOfSkeleton) => {
    let skeletonArray = [];
    for (let i = 0; i < numberOfSkeleton; i++) {
      skeletonArray.push(
        <Skeleton
          animation="wave"
          height={60}
          sx={{ borderRadius: "0px", marginTop: "-16px" }}
        />
      );
    }
    return skeletonArray;
  };

  async function fetchFilteredManuals(filters, search, sort, ata2, ata4) {
    const _search = encodeURIComponent(search);
    setLoading(true);
    const getData = makeAPIRequestRun(
      "get",
      `acarsdata/manualmessages/${activeTab}/${limit}/${offsetValue}/${filters}/"${_search}"/${sort}/${ata2}/${ata4}`
    );

    getData
      .then((response) => {
        setData(response.rows ?? []);
        // setInitialData(response.rows ?? []);
        setTotalRows(response.count[0]?.f0_ ?? 0);
        setCountISI(response.countISI ?? 0);
        setCountTFU(response.countTFU ?? 0);
        setLoading(false);
        if (sort !== "null") {
          document.getElementById("main-table").scrollLeft = mouseX;
        } else
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
      })
      .catch((err) => {
        setTotalRows(0);
        setLoading(false);
        console.log(err);
      });
  }

  async function fetchManuals() {
    const getData = makeAPIRequestRun("get", `acarsdata/manualmessages`);
    getData
      .then((response) => {
        // setList(response);
        setInitialData(response);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleActiveTab(tab) {
    setActiveTab(tab);
    setPage(1);
    setLimit(limit);
  }

  function handleSort(property) {
    if (property !== "addedBy") {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
      setMouseX(document.getElementById("main-table").scrollLeft);
    }
  }

  const headCells = [
    {
      id: "ac_type",
      label: "A/C Type",
    },
    {
      id: "ac_series",
      label: "A/C Series",
    },
    {
      id: "reference",
      label: "Reference",
      link: true,
    },
    {
      id: "title",
      label: "Title",
      width: "400px",
      position: "center",
    },
    {
      id: "ata_2d",
      label: "ATA",
    },
    {
      id: "ata_4d",
      label: "ATA Subtask",
    },
    {
      id: "fault",
      label: "Fault Code",
    },
    {
      id: "rev_reason",
      label: "Purpose / Reason for revision",
      width: "300px",
    },
    {
      id: "engine",
      label: "Engine",
    },
    {
      id: "fin",
      label: "FIN",
    },
    {
      id: "partno",
      label: "P/N",
    },
    {
      id: "supplier",
      label: "Supplier",
    },
    {
      id: "potential_impact",
      label: "Potential Impact",
    },
    {
      id: "key_info",
      label: "Key Information",
    },
    {
      id: "in_service_problem",
      label: "ISP",
    },
    {
      id: "other_article",
      label: "Link To Other Article",
      link: true,
      width: "400px",
    },
    {
      id: "fair_isp",
      label: "FAIR-ISP",
    },
    {
      id: "first_issue_date",
      label: "First Issue Date",
    },
    {
      id: "issue_date",
      label: "Issue Date",
    },
    {
      id: "last_check_date",
      label: "Last Check Date",
    },
    {
      id: "article_status",
      label: "Status",
    },
  ];

  const ViewBox = () => {
    return (
      <div
        className=""
        style={{
          marginTop: "33px",
          paddingBottom: "40px",
        }}
      >
        <Grid container columns={12} sx={{ marginBottom: 2 }}>
          <Grid item xs={8}>
            <div
              className={`${
                activeTab === 1
                  ? styles.activeAircraftTab
                  : styles.inactiveAircraftTab
              }`}
            >
              <p onClick={(e) => handleActiveTab(1)}>
                In-Service Information{" "}
                {
                  <span className={styles.toolTip}>
                    {countISI ? countISI[0]?.f0_ : 0}
                  </span>
                }
              </p>
            </div>
            <div
              className={`${
                activeTab === 2
                  ? styles.activeAircraftTab
                  : styles.inactiveAircraftTab
              }`}
            >
              <p onClick={(e) => handleActiveTab(2)}>
                Technical Follow-Up{" "}
                <span className={styles.toolTip}>
                  {countTFU ? countTFU[0]?.f0_ : 0}
                </span>
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sx={{ paddingTop: "32px" }}>
            <SearchBar
              data={initialData}
              isSearch={isSearch}
              setIsSearch={setIsSearch}
              isFilters={isFilters}
              setIsFilters={setIsFilters}
            />
            <ActiveFilters isFilters={isFilters} setIsFilters={setIsFilters} />
          </Grid>
        </Grid>
        <DisplayDataHeader />
      </div>
    );
  };

  const TableHeads = () => {
    return (
      <TableHead>
        <TableRow>
          {headCells.map((head, index) => (
            <StyledTableCell
              key={index}
              style={{
                whiteSpace: "nowrap",
                // position: head.position ?? "",
                // right: head.right ?? "",
                // zIndex: head.zIndex ?? "",
              }}
            >
              <TableSortLabel
                direction={order}
                onClick={(e) => {
                  handleSort(head.id);
                }}
                IconComponent={() =>
                  order === "asc" && orderBy === head.id ? (
                    <img
                      src={SortIconAscGray}
                      alt=""
                      style={{
                        marginLeft: head.label === "Title" ? "50%" : "10%",
                      }}
                    />
                  ) : order === "desc" && orderBy === head.id ? (
                    <img
                      src={SortIconDescGray}
                      alt=""
                      style={{
                        marginLeft: head.label === "Title" ? "50%" : "10%",
                      }}
                    />
                  ) : (
                    <img
                      src={SortIconAscDescGray}
                      alt=""
                      style={{
                        marginLeft: head.label === "Title" ? "50%" : "10%",
                      }}
                    />
                  )
                }
                active={orderBy === head.id}
              >
                {head.label}
              </TableSortLabel>
            </StyledTableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  const DisplayDataHeader = () => {
    return !loading && !isEmpty(data) ? (
      <TableContainer component={Paper} id="main-table">
        {loading && (
          <>
            <TableHeads />
            <Box
              sx={{
                width: "100%",
                marginLeft: "17px",
                paddingTop: "10px",
                paddingRight: "34px",
              }}
            >
              {skeletonLoader(limit)}
            </Box>
          </>
        )}
        {/* {!loading && isEmpty(data) && (
          <>
            <TableHeads />
            <Box sx={{ width: "100%", marginLeft: "17px", paddingTop: "10px", paddingRight: "34px" }}>
              No records found
            </Box>
          </>
        )} */}
        {!isEmpty(data) && !loading && (
          <Table stickyHeader aria-label="sticky table">
            <TableHeads />
            <DisplayData />
          </Table>
        )}
      </TableContainer>
    ) : isEmpty(data) && !loading ? (
      <UnavailableData />
    ) : (
      ""
    );
  };

  const DisplayData = () => {
    return data?.map((item, index) => {
      return (
        <>
          {isEmpty(item) ? (
            ""
          ) : (
            <TableBody key={index}>
              <TableRow classes={classes.tableRow} hover key={index}>
                {headCells.map((x, y) => {
                  return (
                    <StyledTableCell scope="row" disabled sx={{ minWidth: x.width ? x.width : "" }} key={y}>
                      {x.link ? (
                        <>
                          {x.id === "reference" ? (
                            <Link
                              target="_blank"
                              to={{
                                pathname: `${item.document_url}`,
                              }}
                              rel="noreferrer"
                              className={`${styles.linkBlue}`}
                              style={{ backgroundColor: "#088FD108" }}
                            >
                              {item[x.id]?.replace(/[|]/g, " $&")}
                            </Link>
                          ) : (
                            item.other_article?.split(",").map((otherRef, index) => {
                              return (
                                <Link
                                  target="_blank"
                                  to={{
                                    pathname: `${item.other_article_url.split(",")[index]}`,
                                  }}
                                  rel="noreferrer"
                                  className={`${styles.linkBlue}`}
                                  style={{ backgroundColor: "#088FD108" }}
                                  key={y}
                                >
                                  {otherRef + " "}
                                </Link>
                              );
                            })
                          )}
                        </>
                      ) : (
                        <>
                          {item[x.id]
                            ?.replace("nan", "")
                            .replace(/[|]/g, " $&")}
                        </>
                      )}
                    </StyledTableCell>
                  );
                })}
              </TableRow>
            </TableBody>
          )}
        </>
      );
    });
  };

  return (
    <>
      {loading && <Overlay />}
      <div
        className={`${styles.textLarge}`}
        style={{ marginBottom: "15px", marginLeft: "56px", marginTop: "20px" }}
      >
        Manuals Collection
      </div>
      <div
        style={{
          paddingRight: "50px",
          marginBottom: "32px",
          marginLeft: "26px",
        }}
      >
        <Container
          component={Paper}
          sx={{
            borderRadius: "12px",
            paddingTop: "24px",
            paddingBottom: "24px",
            marginLeft: "32px",
          }}
          elevation={3}
          maxWidth
        >
          <Box>
            {/* {SearchBox()} */}
            <ViewBox />
            {data?.length !== 0 && !loading ? (
              <MUIPagination
                page={page}
                setPage={setPage}
                setPerPage={setLimit}
                totalPage={totalRows ? Math.floor(totalRows / limit) + 1 : 0}
                classes={{ ul: classes.ul }}
                totalRows={totalRows}
                sliceStart={offsetValue}
              />
            ) : (
              ""
            )}
          </Box>
        </Container>
      </div>
    </>
  );
};

export default ManualManagement;
