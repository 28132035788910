import { env_var_REACT_APP_API_URL, env_var_REACT_APP_API_URL_ADV } from "../../envVar/vars";
import useGetGQL from "../useGetGQL";
import useMutateGQL from "../useMutateGQL";
import { useAppSelector } from "../../app/hooks";
import { generateGQLQuery } from "./utils";

/**
 * @deprecated
 * @see please use  {@link useGetLocationHistoryByIdWithHistorySearchAdv}
 */
export function useGetLocationHistoryByIdWithHistorySearch({ inFleetLocationId, historySearch }) {

	const history = useGetGQL({
		gqlquery: `
        query allLocationHistories {
            allLocationHistories(locationListId: "${inFleetLocationId}", 
            ${(historySearch && historySearch !== "" && historySearch !== null) ? `, historyDetails:"${historySearch}"` : ''}
            orderByCreatedAt:"-created_at") {
                edges {
                  node {
                    id
                    createdAt
                    createdBy
                    avatarCreated
                    locationhistorydetailsSet {
                      edges {
                        node {
                          description
                        }
                      }
                    }
                 }
               }
            }
        }
        `,
		querySign: 'allLocationHistories',
		url: env_var_REACT_APP_API_URL ?? "",
		isEdges: true,
	});

	return history
}

/**
 * 
 * @param {Object} param0 
 * @returns 
 */
export function useGetLocationHistoryByIdWithHistorySearchAdv(props) {

	const { inFleetLocationId, historySearch } = props ?? {}

	// `
	// query allLocationHistory{
	// 	allLocationHistories {
	// 	  items {
	// 		_id
	// 		descriptions
	// 		createdAt
	// 		avatarCreated
	// 		createdBy
	// 	  }
	// 	}
	//   }
	// `

	const history = useGetGQL({
		gqlquery: inFleetLocationId ? generateGQLQuery({
			gqlStringQueryType: 'query',
			inputList: [
				{
					name: "locationId",
					type: "string",
					value: inFleetLocationId,
				},
				{
					name: "search",
					type: "string",
					value: historySearch,
				},
			],
			outputList: {
				items: {
					_id: true,
					descriptions: true,
					createdAt: true,
					avatarCreated: true,
					createdBy: true,
				}
			},
			querySign: 'allLocationHistories',
			queryUnsignificantName: 'allLocationHistories',
		}) : '',
		querySign: 'allLocationHistories',
		url: env_var_REACT_APP_API_URL_ADV ?? "",
		isEdges: false,
	});

	return history
}