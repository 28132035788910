import { env_var_REACT_APP_API_URL, env_var_REACT_APP_API_URL_ADV } from "../../envVar/vars";
import useGetGQL from "../useGetGQL";

/**
 * @description Status : migrated!
 */
export function useGetAllItemStatuses() {

	// const data = useGetGQL({
	// 	gqlquery: `
	// 	query statuses {
	// 		allItemStatuses {
	// 		  edges {
	// 			node {
	// 			  id
	// 			  code
	// 			  name
	// 			}
	// 		  }
	// 		}
	// 	  }
	// 	`,
	// 	querySign: 'allItemStatuses',
	// 	url: env_var_REACT_APP_API_URL ?? "",
	// 	defaultIsLoading: true,
	// });

	const dataAdv = useGetGQL({
		gqlquery: `
		query allInventoryStatus {
			allInventoryStatus {
			  items {
				_id
				name
				code
			  }
			}
		  }
		`,
		querySign: 'allInventoryStatus',
		url: env_var_REACT_APP_API_URL_ADV ?? "",
		defaultIsLoading: true,
		isEdges: false,
	});

	// function convertFromOld(data) {
	// 	return {
	// 		...data,
	// 		data: data?.data?.map(({ node }) => {
	// 			return node
	// 		}) ?? [],
	// 	}
	// }
	function convertFromNew(dataAdv) {
		return {
			...dataAdv,
			data: dataAdv?.data?.items?.map((item) => {
				return {
					...item,
					id: item?._id,
				}
			}) ?? [],
		}
	}

	// console.log({
	// 	title: 'useGetAllItemStatuses',
	// 	data,
	// 	dataAdv,
	// 	convertedOld: convertFromOld(data),
	// 	convertedNew: convertFromNew(dataAdv),
	// })

	// return data
	return convertFromNew(dataAdv);

}
