import React from 'react';
import { Grid, Fade } from "@mui/material";
import styles from "./DialogBox.module.css";
import IconClose from "common/images/icons/elevade/Icon-CloseGray.svg";
import IconGoBack from "common/images/icons/elevade/Icon-GoBack.svg";
import Download from "common/images/icons/elevade/DownloadSimple.svg";
import Printer from "common/images/icons/elevade/PrinterBlack.svg";
import { BarCharts, TableChart } from "./ECharts";
import { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import { makeStyles } from "@mui/styles";
import { analyticsPDF } from "pages/elevade/aircraftMonitoring/generatePdf";

// -- table_id    table_description
// -- 1           Top ECAM Messages
// -- 2           Top ATA Chapters
// -- 3           Top High Priority ECAM Messages
// -- 4           Top Repetitive ECAM Messages
// -- 5           Top Repetitive Fault Messages
// -- 6           Selected ATA Chapter Defect Data
// -- 7           DAR Defect Data
// -- 8           Full ECAM Data
// -- 9           Full Fault Data
// --10           Top ECAM Messages by Aircraft
// --11           Full ATA Data
// --12           Top High Priority ECAM Messages by Aircraft
// --13           Top ATA Chapters by Aircraft

const useStyles = makeStyles((theme) => ({
  backdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.6)", // Set the initial color
    backdropFilter: "blur(2px)", // Set the blur effect
  },
}));

const DialogBox = ({
  chartIndex,
  dialogType,
  open,
  setOpen,
  title,
  refs,
  initialData,
  data,
  dataIndex,
  posX,
  posY,
  deep,
  setDeep,
  isACReg,
  params,
  color,
  tab,
  boardTitle,
  ...props
}) => {
  const [isData, setIsData] = useState(data);
  const [acReg, setAcReg] = useState();
  const [ataChapter, setAtaChapter] = useState();
  const [chartType, setChartType] = useState();
  const [tableData, setTableData] = useState();
  let posY_ = posY - 71;
  // let posX_ = posX + 20;

  // if (chartIndex && chartIndex === 3) {
  //   posX_ = posX - 334;
  // }

  let aoc = "";
  aoc = data[0] ? data[0]?.aoc || data?.[1]?.[3] : "";

  let ata_head = data[0]?.ata_head;

  const classes = useStyles();

  // Use effect

  useEffect(() => {
    setIsData(data);
  }, [data]);

  useEffect(() => {
    let tableId = chartType === "top_ata" ? 11 : chartType === "top_high_priority" ? 8 : 8;
    filterArray(tableId, deep);
    // eslint-disable-next-line
  }, [data, initialData, deep, acReg, chartType, params]);

  // Set chart type
  useEffect(() => {
    setChartType(props.tableId === 15 ? props.tableId : params?.data[0]);
    // eslint-disable-next-line
  }, [params]);

  function filterArray(tableId, deep) {
    let filteredData = "";
    if (initialData?.length > 0 && deep === 0) {
      if (tableId === 11) {
        if (isACReg?.length > 0)
          filteredData = initialData.filter(
            (e) => e.table_id === tableId && e.aoc === aoc && e.ata_head === ata_head && isACReg.includes(e["ac_reg"])
          );
        else
          filteredData = initialData.filter((e) => e.table_id === tableId && e.aoc === aoc && e.ata_head === ata_head);
      } else {
        if (isACReg?.length > 0)
          filteredData = initialData.filter(
            (e) => e.table_id === tableId && e.aoc === aoc && e.text === params?.name && isACReg.includes(e["ac_reg"])
          );
        else
          filteredData = initialData.filter((e) => e.table_id === tableId && e.aoc === aoc && e.text === params?.name);
      }
      // Merge array with the same text value
      let col = tableId === 11 || tableId === 8 ? "ac_reg" : "text";
      var holder = {};
      filteredData.forEach(function (d) {
        if (holder.hasOwnProperty(d[col])) {
          holder[d[col]] = holder[d[col]] + d.occurrence;
        } else {
          holder[d[col]] = d.occurrence;
        }
        holder["aoc"] = d.aoc;
        holder["ac_reg"] = d.ac_reg;
        if (tableId === 11) holder["ata_head"] = d.ata_head;
      });
      var obj2 = [];
      for (var prop in holder) {
        if (prop !== "aoc" && prop !== "ac_reg" && prop !== "ata_head" && prop !== "text") {
          obj2.push({
            text: prop,
            occurrence: holder[prop],
            aoc: holder.aoc,
            ac_reg: holder.ac_reg,
            ata_head: holder.ata_head,
          });
        }
      }
      // Sort by occurrence
      obj2.sort(function (a, b) {
        if (a.occurrence < b.occurrence) return -1;
        if (a.occurrence > b.occurrence) return 1;
        return 0;
      });
      setIsData(obj2);
    }
    if (initialData?.length > 0 && deep === 1) {
      tableId = 14;
      filteredData = initialData.filter(
        (e) => e.table_id === tableId && e.aoc === aoc && e.ata_head === ata_head && e.ac_reg === acReg
      );
      let col = "ata_4d";
      holder = {};
      filteredData.forEach(function (d) {
        if (holder.hasOwnProperty(d[col])) {
          holder[d[col]] = holder[d[col]] + d.occurrence;
        } else {
          holder[d[col]] = d.occurrence;
        }
        holder["aoc"] = d.aoc;
        holder["ac_reg"] = d.ac_reg;
        holder["ata_head"] = d.ata_head;
      });
      obj2 = [];
      for (prop in holder) {
        if (prop !== "aoc" && prop !== "ac_reg" && prop !== "ata_head") {
          obj2.push({
            text: prop,
            occurrence: holder[prop],
            aoc: holder.aoc,
            ac_reg: holder.ac_reg,
            ata_head: holder.ata_head,
          });
        }
      }
      // Sort by occurrence
      obj2.sort(function (a, b) {
        if (a.occurrence < b.occurrence) return -1;
        if (a.occurrence > b.occurrence) return 1;
        return 0;
      });
      setIsData(obj2);
    }
  }

  function formatACReg(e) {
    return (
      <span style={{ border: "1px solid #C3C3C3", borderRadius: "6px", padding: "8px 9px", marginLeft: "8px" }}>
        {e}
      </span>
    );
  }

  function ChartTitle(deep) {
    const type = params?.data[0];
    const name = params?.data[1];
    if (deep === 0) {
      if (type === "top_high_priority") return `Repetitive High Priority ECAM - ` + name;
      else if (type === "top_ecam") return `Repetitive ECAM Messages - ` + name;
      else if (type === "top_ata") return `Repetitive ATA Chapter ` + title.split(" ")[1];
      else return type + ": " + name;
    } else if (deep === 1) {
      if (type === "top_ata") return `Repetitive ATA Chapter ` + title.split(" ")[1];
    } else if (deep === 2) {
      if (type === "top_high_priority") return `List of messages - ` + name;
      else if (type === "top_ecam") return `List of messages - ` + name;
      else if (type === "top_ata")
        return `List of messages - ATA Chapter ` + ataChapter?.substr(0, 2) + `-` + ataChapter?.substr(2, 2);
      else return type + ": " + name;
    } else if (deep === 4) {
      if (type === "top_high_priority") return `Repetitive High Priority ECAM - ` + name;
      else if (type === "top_ecam") return `Repetitive ECAM Messages - ` + name;
      else if (type === "top_ata") return `Repetitive ATA Chapter ` + title.split(" ")[1];
      return name ? type + ": " + name : boardTitle;
    }
  }

  function TabName(tab) {
    let name = "";
    if (tab === 0) name = "REPETITIVE DEFECT";
    else if (tab === 1) name = "ECAM DISPATCH ALERT";
    return name;
  }

  let add = 1;
  if (chartType === "top_high_priority" || chartType === "top_ecam" || chartType === 15) add = 2;

  return (
    open && (
      <>
        {dialogType === 1 && (
          <div
            className={`${styles.container} ${chartIndex && chartIndex === 3 ? styles.containerLeft : ""}`}
            ref={refs}
            style={{ top: posY_ }}
          >
            <div
              className={`${styles.pointer} 
          ${chartIndex && chartIndex === 3 ? styles.pointerLeft : ""}`}
            ></div>
            <div className={styles.title}>{title}</div>
            <div className={styles.table}>
              <Grid container columns={2}>
                <Grid item xs={1} className={styles.tableHeader}>
                  A/C Registration
                </Grid>
                <Grid item xs={1} className={styles.tableHeader}>
                  Triggered
                </Grid>
                {data?.length > 0 &&
                  data.map((item) => {
                    return (
                      <>
                        <Grid item xs={1} className={styles.tableBody} align="center">
                          {item.ac_reg}
                        </Grid>
                        <Grid item xs={1} className={styles.tableBody} align="center">
                          {item.occurrence}
                        </Grid>
                      </>
                    );
                  })}
              </Grid>
            </div>
          </div>
        )}
        {dialogType !== 1 && (
          <Fade in={open}>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={open}
              className={classes.backdrop}
            >
              <>
                <div className={`${styles.containerCenter}`} ref={refs}>
                  <div style={{ padding: "16px 24px 0 24px", borderBottom: "1px solid #E8E8E8" }}>
                    <Grid container columns={3}>
                      <Grid item xs={2}>
                        {params && (
                          <img
                            src={IconGoBack}
                            alt="back"
                            height={16}
                            style={{ marginTop: "-2px", cursor: "pointer" }}
                            onClick={() => (deep === 4 ? setDeep(0) : setDeep(deep - add))}
                            className={`${deep > 0 ? styles.show : styles.hide}`}
                          />
                        )}
                        <div
                          className={`${styles.title} ${deep > 0 ? styles.marginTitle : styles.noMarginTitle}`}
                          style={{ display: "inline-block" }}
                        >
                          {ChartTitle(deep)}
                          {deep > 0 && deep !== 4 ? formatACReg(acReg) : ""}
                        </div>
                      </Grid>
                      <Grid item xs={1} sx={{ display: "flex", justifyContent: "flex-end" }}>
                        {deep < 1 && (
                          <>
                            <span
                              style={{
                                fontFamily: "Gotham-Medium",
                                marginRight: "30px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setDeep(4);
                              }}
                            >
                              View All Messages
                            </span>
                          </>
                        )}
                        {((deep === 2 && dialogType === 2) || deep === 4) && (
                          <Grid sx={{ px: "15px" }}>
                            <img
                              src={Download}
                              alt="Download"
                              height={16}
                              style={{ cursor: "pointer", paddingRight: "10px" }}
                              onClick={() =>
                                analyticsPDF(tableData, "save", boardTitle, "test", TabName(tab), params?.data[1])
                              }
                            />
                            <img
                              src={Printer}
                              alt="Print"
                              height={16}
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                analyticsPDF(tableData, "print", boardTitle, "test", TabName(tab), params?.data[1])
                              }
                            />
                          </Grid>
                        )}
                        <img
                          src={IconClose}
                          alt="Close"
                          height={16}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setOpen(false);
                            setDeep(0);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </div>
                  <div style={{ padding: "16px 24px", overflow: "auto" }} className={styles.macScrollbar}>
                    <Grid container columns={1}>
                      <Grid item xs={3}>
                        {dialogType === 2 && deep === 0 && (
                          <BarCharts
                            data={isData}
                            color={color}
                            deep={deep}
                            setDeep={setDeep}
                            acReg={acReg}
                            setAcReg={setAcReg}
                            chartType={chartType}
                          />
                        )}
                        {dialogType === 2 && deep === 1 && (
                          <BarCharts
                            data={isData}
                            color={color}
                            deep={deep}
                            setDeep={setDeep}
                            acReg={acReg}
                            setAcReg={setAcReg}
                            ataChapter={ataChapter}
                            setAtaChapter={setAtaChapter}
                            chartType={chartType}
                          />
                        )}
                        {dialogType === 2 && deep === 2 && (
                          <TableChart
                            data={isData}
                            initialData={initialData}
                            aoc={aoc}
                            acReg={acReg}
                            ataChapter={ataChapter}
                            chartType={chartType}
                            name={params?.name}
                            setTableData={setTableData}
                            open={open}
                          />
                        )}
                        {deep === 4 && (
                          <TableChart
                            data={isData}
                            initialData={initialData}
                            aoc={aoc}
                            acReg={acReg}
                            ataChapter={ataChapter}
                            chartType={"all"}
                            name={params?.name}
                            setTableData={setTableData}
                            tab={tab}
                            open={open}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </>
            </Backdrop>
          </Fade>
        )}
      </>
    )
  );
};

export default DialogBox;
