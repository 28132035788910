import React from "react";

import { Box, Button, Divider, Grid, IconButton, MenuItem, Select, Skeleton, Tooltip, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import ItemTemplate from "./ItemTemplate";
import { LocationIcon, TagIdIcon, SerialNoIcon, ExpiryDateIcon, MfgDateIcon } from "./icons";
import {
  LifebouyIcon,
  SquareMissingIcon,
  SquareRemindIcon,
  SquareExpiredIcon,
  SquareErrorIcon,
  SquareDefectIcon,
} from "../../../components/icons/statusIcons";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { useNotification } from "../../../components/notifications/UniversalNotification";
import { isEmpty } from "lodash";
import { commonFontSizes } from "../../../../constants/styles";
import { gothamMedium } from "../../../../constants/fonts";
import { useGetInventory } from "../../../../models/custom/inventory-model";
import { useUpdateLocation } from "../../../../models/custom/location-model";
import { useGetAllItemStatuses } from "../../../../models/custom/ac-item-model";
import { cabinCheckPermission } from "pages/elevade/cabinMonitoring/js/controllers/permissions/permission-codes";
import { useAppSelector } from "pages/elevade/cabinMonitoring/js/app/hooks";
import NoItemHere from "./NoItemHere";

/**
 * @typedef {Object} LocationTabProps
 * @property {import('./LocationDetailViewMain').Inventory} inventory
 * @property {import('./LocationDetailViewMain').Location} location
 * @property {Object} itemStyle
 * @property {Function} refetch
 * @property {Function} setDrawerItem
 * @property {Function} setClose
 */

/**
 * @param {LocationTabProps} props
 */
export default function LocationTab(props) {
  const {
    // inFleetLocation,
    inventory,
    location,
    itemStyle,
    refetch,
    setDrawerItem,
    setClose,
  } = props;
  const isNoItemAssigned = inventory === undefined || inventory === null || isEmpty(inventory);
  const openNotification = useNotification();

  const userEntity = useAppSelector((state) => state.userEntity);
  const permissions = userEntity?.userPermission;

  // const {
  // 	data: itemStatusData,
  // 	isLoading: itemStatusLoading,
  // 	refetch: itemStatusRefetch,
  // }
  const curItemStatus = useGetInventory({ inventoryId: inventory?.id });

  const expiryDateLocaleDate = new Date(inventory?.expiryDate).toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "2-digit",
  });
  // const expiryDateLocaleTime = new Date(inFleetLocation?.inventory?.expiryDate).toLocaleTimeString('en-US', { hour12: false })

  const mfgDateLocaleDate = inventory?.manufactureDate
    ? new Date(inventory?.manufactureDate).toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "2-digit",
      })
    : null;
  // const mfgDateLocaleTime = inFleetLocation?.inventory?.manufactureDate ? new Date(inFleetLocation?.inventory?.manufactureDate).toLocaleTimeString('en-US', { hour12: false }) : null

  const [changedStatus, setChangedStatus] = useState(curItemStatus?.data?.status?.code ?? "");

  useEffect(() => {
    if (curItemStatus?.data?.status?.code) {
      setChangedStatus(curItemStatus?.data?.status?.code);
    }
  }, [curItemStatus?.data?.status?.code]);

  const allStatusFromApi = useGetAllItemStatuses();

  const { mutate: mutateUpdate } = useUpdateLocation({
    inFleetLocationId: location?.id,
    changedStatus,
    allStatuses: allStatusFromApi.data.map(({ id, name, code }) => {
      return { id, name, code };
    }),
  });

  let StatusIcon = getStatusIcon(changedStatus ?? "A");

  const statusOpts = allStatusFromApi.data.map(({ id, name, code }) => {
    return {
      value: code,
      label: getStatus(name),
    };
  });

  return (
    <Box sx={{ position: "relative", height: "100%" }}>
      <Grid container>
        <Grid item xs={12} md={6} sx={{ ...itemStyle }}>
          <ItemTemplate icon={<LocationIcon />} title={"Location"} value={location?.seatNo} />
        </Grid>
        {!isNoItemAssigned && (
          <Fragment>
            <Grid item xs={12} md={6} sx={{ ...itemStyle }}>
              <ItemTemplate
                icon={
                  curItemStatus?.isLoading ? (
                    <Skeleton height={50} width={33} sx={{ marginTop: "-10px" }} />
                  ) : (
                    <StatusIcon />
                  )
                }
                title={"Status"}
                value={
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                    }}
                  >
                    {curItemStatus?.isLoading ? (
                      <Skeleton height={45} width="100%" sx={{ marginTop: "-10px" }} />
                    ) : (
                      <Fragment>
                        <DropDown
                          disabled={!cabinCheckPermission(permissions).isAllowedUpdateEqStatusLopa}
                          value={changedStatus}
                          onChange={(e) => {
                            setChangedStatus(e.target.value);
                          }}
                          label={""}
                          options={statusOpts}
                        />
                        {curItemStatus?.data?.status?.code !== changedStatus && (
                          <Tooltip title="Reset value">
                            <span>
                              <IconButton
                                size="small"
                                onClick={() => {
                                  setChangedStatus(curItemStatus?.data?.status?.code ?? "");
                                }}
                              >
                                <RestartAltIcon />
                              </IconButton>
                            </span>
                          </Tooltip>
                        )}
                      </Fragment>
                    )}
                  </span>
                }
              />
            </Grid>
            <Grid item xs={12} md={6} sx={{ ...itemStyle }}>
              <ItemTemplate
                icon={<TagIdIcon />}
                title={"Tag ID"}
                value={inventory?.tag ? inventory?.tag.slice(-12) : inventory?.tag}
              />
            </Grid>
            <Grid item xs={12} md={6} sx={{ ...itemStyle }}>
              <ItemTemplate icon={<SerialNoIcon />} title={"Serial No."} value={inventory?.serialNo} />
            </Grid>
            <Grid item xs={12} md={6} sx={{ ...itemStyle }}>
              <ItemTemplate
                icon={<ExpiryDateIcon />}
                title={"Expiry Date"}
                value={inventory?.expiryDate ? `${expiryDateLocaleDate}` : "N/A"}
              />
            </Grid>
            <Grid item xs={12} md={6} sx={{ ...itemStyle }}>
              <ItemTemplate
                icon={<MfgDateIcon />}
                title={"MFG Date"}
                value={inventory?.manufactureDate ? `${mfgDateLocaleDate}` : "N/A"}
              />
            </Grid>
          </Fragment>
        )}
      </Grid>

      {!isNoItemAssigned && (
        <Fragment>
          {curItemStatus?.data?.status?.code && (
            <Box
              sx={{
                position: "absolute",
                bottom: "0px",
                width: "100%",
              }}
            >
              <Divider />
              <Grid container width="100%" sx={{ marginTop: "1em" }}>
                <Grid item xs={6}>
                  <Button
                    sx={{ width: "100%" }}
                    onClick={() => {
                      if (setDrawerItem) {
                        setDrawerItem();
                      }
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    disabled={
                      curItemStatus?.data?.status?.code === changedStatus ||
                      !cabinCheckPermission(permissions).isAllowedUpdateEqStatusLopa
                    }
                    sx={{ width: "100%" }}
                    variant="contained"
                    onClick={() => {
                      mutateUpdate().then(({ isError, errorMessage }) => {
                        if (isError) {
                          openNotification(`Error : ${errorMessage}`, {
                            variant: "error",
                          });
                        } else {
                          openNotification("Equipment updated", {
                            variant: "success",
                            onClose: () => {
                              setClose();
                              if (refetch) {
                                refetch();
                                curItemStatus?.refetch();
                              }
                            },
                          });
                        }
                      });
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}
        </Fragment>
      )}

      {isNoItemAssigned && <NoItemHere />}
    </Box>
  );
}

function DropDown({ value: valueOutter, onChange, label, options, disabled }) {
  return (
    <Select
      disabled={disabled}
      value={valueOutter}
      label={label}
      onChange={onChange}
      sx={{
        height: "30px",
      }}
    >
      {options.map(({ value, label }) => {
        return (
          <MenuItem selected={value === valueOutter} value={value}>
            {label}
          </MenuItem>
        );
      })}
    </Select>
  );
}

function getStatusIcon(code) {
  let StatusIcon = LifebouyIcon;
  switch (code) {
    case "A":
      StatusIcon = LifebouyIcon;
      break;
    case "L":
      StatusIcon = SquareMissingIcon;
      break;
    case "R":
      StatusIcon = SquareRemindIcon;
      break;
    case "E":
      StatusIcon = SquareExpiredIcon;
      break;
    case "T":
      StatusIcon = SquareErrorIcon;
      break;
    case "D":
      StatusIcon = SquareDefectIcon;
      break;
    default:
      StatusIcon = LifebouyIcon;
      break;
  }
  return StatusIcon;
}
function getStatus(curVal) {
  let usingValue = curVal;
  if (curVal?.toLowerCase() === "tag error") {
    usingValue = "Error";
  }
  if (curVal?.toLowerCase() === "loss") {
    usingValue = "Missing";
  }
  if (curVal?.toLowerCase() === "defect") {
    usingValue = "Defect";
  }
  // if (curVal?.toLowerCase() === 'near expiry') {
  // }
  // if (curVal?.toLowerCase() === 'expired') {
  // }
  return usingValue;
}
