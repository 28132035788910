import { env_var_REACT_APP_API_URL, env_var_REACT_APP_API_URL_ADV } from "../../envVar/vars";
import useGetGQL from "../useGetGQL";
import useMutateGQL from "../useMutateGQL";
import { useAppSelector } from "../../app/hooks";
import { generateGQLQuery } from "./utils";


/**
 * @deprecated 
 * @see please use {@link useGetAllInventoryHistoryAdv}
 */
export function useGetAllInventoryHistory({ id, search }) {
	const history = useGetGQL({
		gqlquery: `
        query allInventoryHistories {
            allInventoryHistories(inventories: "${id}", 
            ${search && search !== "" && search !== null
				? `, historyDetails:"${search}"`
				: ""
			}
            orderByCreatedAt:"-created_at") {
                edges {
                  node {
                    id
                    createdAt
                    createdBy
                    avatarCreated
                    historydetailsSet {
                      edges {
                        node {
                          description
                        }
                      }
                    }
                 }
               }
            }
        }
        `,
		querySign: "allInventoryHistories",
		url: env_var_REACT_APP_API_URL ?? "",
		isEdges: true,
	});

	return history;
}


/**
 * @typedef {Object} UseGetAllInventoryHistoryAdvProps
 * @property {string} inventoryId
 * @property {string} search
 */
/**
 * 
 * @param {UseGetAllInventoryHistoryAdvProps} props 
 * @returns 
 */
export function useGetAllInventoryHistoryAdv(props) {

	const { inventoryId, search } = props ?? {}

	const history = useGetGQL({
		gqlquery: generateGQLQuery({
			gqlStringQueryType: 'query',
			inputList: [
				{
					name: "inventoryId",
					type: 'string',
					value: inventoryId,
				},
				{
					name: "search",
					type: 'string',
					value: search,
				},
			],
			outputList: {
				items: {
					_id: true,
					descriptions: true,
					avatarCreated: true,
					createdAt: true,
					inventory: {
						_id: true,
						aocCode: true,
						tag: true,
						serialNo: true,
						type: {
							_id: true,
							name: true,
							code: true,
						},
						status: {
							_id: true,
							name: true,
							code: true,
						},
					}
				}
			},
			querySign: "allInventoryHistories",
			queryUnsignificantName: 'allInventoryHistories',
		}),
		querySign: "allInventoryHistories",
		url: env_var_REACT_APP_API_URL_ADV ?? "",
		isEdges: false,
	});

	return history;
}