import React, { useEffect, useState } from "react";
import EChartss from "./component/ECharts";
import { Grid } from "@mui/material";
import { AddMoreTable, AnalyticsTableA01, AnalyticsTableA02 } from "./component/AnalyticsTable";
import AirplaneLoader from "../component/AirplaneLoader";
// import { uniqueList } from "../component/Helpers";
import Overlay from "../component/Overlay";
import { ataChapter } from "common/constant";
import { filterArray } from "./helpers/filterArray";
import { GetEnum } from "./helpers/getEnum";

// -- table_id    table_description
// -- 1           Top ECAM Messages
// -- 2           Top ATA Chapters
// -- 3           Top High Priority ECAM Messages
// -- 4           Top Repetitive ECAM Messages
// -- 5           Top Repetitive Fault Messages
// -- 6           Selected ATA Chapter Defect Data
// -- 7           DAR Defect Data
// -- 8           Full ECAM Data
// -- 9           Full Fault Data
// --10           Top ECAM Messages by Aircraft
// --11           Full ATA Data
// --12           Top High Priority ECAM Messages by Aircraft
// --13           Top ATA Chapters by Aircraft

export const RepetitiveDefect = (props) => {
  const { isLoading, setIsLoading, isLoadingLegs, setIsLoadingLegs, isOperator, data, initialAOC, setInitialAOC, tab } =
    props;

  const [isACReg, setIsACReg] = useState([]);
  const [dataA01, setDataA01] = useState([]); // Top 10 ECAM Messages
  const [dataA02, setDataA02] = useState([]); // Top 10 ATA Chapters
  const [dataA03, setDataA03] = useState([]); // Top 10 High Priority ECAM Messages
  const [dataA04, setDataA04] = useState([]); // Top Repetitive ECAM Messages
  const [dataA05, setDataA05] = useState([]); // Top Repetitive Fault Messages
  const [isATAList, setATAList] = useState(["21", "24", "32", "36", "49", "77"]);
  const [deep, setDeep] = useState(0);

  // Set data state for each of the charts or table
  useEffect(() => {
    if (data?.length > 0) {
      // Set data for A01: Top 10 ECAM Messages
      SetDataForCharts(data, 1);
      // Set data for A02: Top 10 ATA Chapters
      SetDataForCharts(data, 2);
      // Set data for A03: Top 10 High Priority ECAM Messages
      SetDataForCharts(data, 3);
      // Set data for A04: Top Repetitive ECAM Messages
      SetDataForCharts(data, 4);
      // Set data for A05: Top Repetitive Fault Messages
      SetDataForCharts(data, 5);

      // Set listACReg
      // SetDataListACReg(initialData);
      setIsLoading(false);
      setIsLoadingLegs(false);
    }
    // eslint-disable-next-line
  }, [data, initialAOC, isACReg]);

  function getDataByAta(ata) {
    return SetDataForCharts(data, 6, ata);
  }

  // Set operator based on selected value from the filters
  useEffect(() => {
    if (isOperator?.length > 0) setInitialAOC(isOperator);
    // eslint-disable-next-line
  }, [isOperator]);

  // A function to set data for chart drawing
  function SetDataForCharts(data, type, ata) {
    let data_ = [];
    let dataToArray =
      type === 1 || type === 2 || type === 3 ? [["type", "text", "occurrence", "aoc", "ac_reg", "ata_head"]] : [];
    let arrayHeader = ["type", "text", "occurrence", "aoc", "ac_reg", "ata_head"];
    data_ = data.filter((e) => e.table_id === type && e.aoc === initialAOC);
    if ((type === 1 || type === 2 || type === 3) && isACReg?.length > 0) {
      let tableId = type === 1 ? 10 : type === 2 ? 13 : 12;
      data_ = data.filter((e) => e.table_id === tableId && e.aoc === initialAOC && isACReg.includes(e["ac_reg"]));
      // Merge array with the same text value
      let col = type === 1 || type === 3 ? "text" : "ata_head";
      var holder = {};
      data_.forEach(function (d) {
        if (holder.hasOwnProperty(d[col])) {
          holder[d[col]] = holder[d[col]] + d.occurrence;
        } else {
          holder[d[col]] = d.occurrence;
        }
        holder["aoc"] = d.aoc;
        holder["ac_reg"] = d.ac_reg;
        holder["ata_head"] = d.ata_head;
      });
      var obj2 = [];
      for (var prop in holder) {
        if (prop !== "aoc" && prop !== "ac_reg" && prop !== "ata_head") {
          obj2.push({
            text: prop,
            occurrence: holder[prop],
            aoc: holder.aoc,
            ac_reg: holder.ac_reg,
            ata_head: prop,
          });
        }
      }
      data_ = obj2;
    } else if (ata && isACReg?.length === 0)
      data_ = data.filter((e) => e.table_id === type && e.aoc === initialAOC && e.ata_head === ata);
    else if (ata && isACReg?.length > 0)
      data_ = data.filter(
        (e) => e.table_id === type && e.aoc === initialAOC && e.ata_head === ata && isACReg.includes(e["ac_reg"])
      );
    else if (isACReg?.length > 0 && type === 4)
      data_ = data.filter((e) => e.table_id === 8 && e.aoc === initialAOC && isACReg.includes(e["ac_reg"]));
    else if (isACReg?.length > 0 && type === 5)
      data_ = data.filter((e) => e.table_id === 9 && e.aoc === initialAOC && isACReg.includes(e["ac_reg"]));

    data_
      .sort(function (a, b) {
        return type === 4 || type === 5 ? b.occurrence - a.occurrence : a.occurrence - b.occurrence;
      })
      // eslint-disable-next-line
      .map((e) => {
        if (type === 1) dataToArray.push(["top_ecam", e.text, e.occurrence, e.aoc, e.ac_reg, e.ata_head]);
        else if (type === 2)
          dataToArray.push(["top_ata", `ATA ` + e.ata_head, e.occurrence, e.aoc, e.ac_reg, e.ata_head]);
        else if (type === 3) dataToArray.push(["top_high_priority", e.text, e.occurrence, e.aoc, e.ac_reg, e.ata_head]);
        else if (type === 4) dataToArray.push(e);
        else if (type === 5) dataToArray.push(e);
        else if (type === 6) dataToArray.push(e);
      });
    if (type === 3) setDataA01(filterArray([arrayHeader, ...dataToArray.slice(-10)]));
    else if (type === 1) setDataA02(filterArray([arrayHeader, ...dataToArray.slice(-10)]));
    else if (type === 2) setDataA03(filterArray([arrayHeader, ...dataToArray.slice(-10)]));
    else if (type === 4) setDataA04(dataToArray);
    else if (type === 5) setDataA05(dataToArray);
    else return dataToArray;
  }

  return (
    <>
      {isLoadingLegs && !isLoading && <Overlay />}
      {/* <div
        style={{
          margin: "28px -32px 18px 20px",
          fontFamily: "Gotham-Medium",
          fontSize: "16px",
        }}
      >
        <Grid container columns={2}>
          <Grid item xs={2} sm={2} md={1} lg={1} xl={1} sx={{ paddingTop: "14px", paddingLeft: "12px" }}>
            {initialAOC} REPETITIVE DEFECT DASHBOARD
          </Grid>
        </Grid>
      </div> */}
      {isLoading && <AirplaneLoader />}
      {!isLoading && (
        <div
          style={{
            overflow: "hidden",
            margin: "12px -44px 18px 20px",
          }}
        >
          <Grid container columns={3.2}>
            <Grid item xs={3} sm={3} md={1.7} lg={1.2} xl={1.2} sx={{ padding: "12px" }}>
              <EChartss
                title="Top 10 High Priority ECAM Messages"
                data={dataA01}
                color={{ start: "rgb(10, 221, 221)", end: "rgb(8, 143, 209)" }}
                isLoading={isLoading}
                initialData={data}
                isACReg={isACReg}
                deep={deep}
                setDeep={setDeep}
                tab={tab}
              />
            </Grid>
            <Grid item xs={3} sm={3} md={1.5} lg={1} xl={1} sx={{ padding: "12px" }}>
              <EChartss
                title="Top 10 ECAM Messages"
                data={dataA02}
                color={{ start: "rgb(123, 201, 240)", end: "rgb(70, 81, 230)" }}
                isLoading={isLoading}
                initialData={data}
                isACReg={isACReg}
                deep={deep}
                setDeep={setDeep}
                tab={tab}
              />
            </Grid>
            <Grid item xs={3} sm={3} md={1.5} lg={1} xl={1} sx={{ padding: "12px" }}>
              <EChartss
                title="Top 10 ATA Repetitive"
                data={dataA03}
                color={{ start: "rgba(47, 178, 241, 0.6)", end: "rgb(7, 117, 171)" }}
                isLoading={isLoading}
                initialData={data}
                isACReg={isACReg}
                chartIndex={3}
                deep={deep}
                setDeep={setDeep}
                tab={tab}
              />
            </Grid>
            <Grid item xs={3} sx={{ padding: "12px", fontFamily: "Gotham-Medium", fontSize: "16px" }}>
              TOP 10 AIRCRAFT WITH HIGH REPETITIVE ECAMS & FAILURE MESSAGE
            </Grid>
            <Grid item xs={1.5} sx={{ padding: "12px" }}>
              <AnalyticsTableA01 title="Repetitive ECAM" data={dataA04} key={`dataA04`} />
            </Grid>
            <Grid item xs={1.5} sx={{ padding: "12px" }}>
              <AnalyticsTableA01 title="Repetitive Failure Message" data={dataA05} key={`dataA05`} />
            </Grid>
            <Grid item xs={3} sx={{ padding: "12px", fontFamily: "Gotham-Medium", fontSize: "16px" }}>
              DEFECT/FAULT UNDER MONITORING
            </Grid>
            {isATAList && isATAList?.length > 0
              ? isATAList.map((e, index) => {
                  return (
                    <Grid item xs={3} sm={3} md={1.5} lg={1} xl={1} sx={{ padding: "12px" }} key={index}>
                      <AnalyticsTableA02 title={GetEnum(ataChapter, e)} data={getDataByAta(e)} />
                    </Grid>
                  );
                })
              : ""}
            <Grid item xs={3} sm={3} md={1.5} lg={1} xl={1} sx={{ padding: "12px" }}>
              <AddMoreTable />
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};
