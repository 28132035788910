import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Grid, styled, Tab, Tooltip, tooltipClasses } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import styles from "../Dasr.module.css";
import IconErrorAirBalloon from "common/images/icons/elevade/Icon-ErrorAirBalloon.svg";
import DDML from "../aircraftReport/DDML";
import SearchBar from "./SearchBar";
import ActiveFilters from "./ActiveFilters";
import {
  addingDate,
  calcBalanceFC,
  calcBalanceFH,
  calculateBalanceDay,
  calculateCalendarBalance,
  calculateCalendarExpiryDate,
  formatDateDasr,
} from "pages/elevade/component/Helpers";
import MUIPagination from "pages/elevade/component/Pagination";
import { makeStyles } from "@mui/styles";
import { makeAPIRequestMDB } from "common/helpers/makeAPIRequestMDB";
import Overlay from "../../component/Overlay";
import IconAirplaneLimitation from "common/images/icons/elevade/Icon-AirplaneLimitation.svg";
import IconCheckCircleWhite from "common/images/icons/elevade/Icon-CheckCircleWhite.svg";
import IconCheckCircleDisabled from "common/images/icons/elevade/Icon-DustbinDisabled.svg";
import IconReInspection from "common/images/icons/elevade/Icon-ReInspection.svg";
import IconDownload from "common/images/icons/elevade/Icon-DownloadBtn.svg";
import IconDownloadWhite from "common/images/icons/elevade/Icon-DownloadWhite.svg";
import IconDownloadDisabled from "common/images/icons/elevade/Icon-DownloadDisabled.svg";
import ElevadeDrawer from "../component/Drawer";
import Button from "pages/elevade/component/Button";
import DateToggle from "../component/DateToggle";
import DialogPush145 from "./DialogPush145";
import DialogSubmitShiftReport from "./DialogSubmitShiftReport";
import AirplaneLoader from "pages/elevade/component/AirplaneLoader";
import { makeAPIRequestRun, showNotificationElevade } from "common/helpers";
import Assignee from "pages/elevade/component/Assignee";
import AdditionalTaskForm from "./AdditionalTaskForm";
import AdditionalTaskSummary from "./AdditionalTaskSummary";
import IconFlag from "common/images/icons/elevade/Icon-Flag.svg";
import IconFlagActive from "common/images/icons/elevade/Icon-FlagActive.svg";
import { Helmet } from "react-helmet";
import { TaskAssignmentPDF } from "pages/elevade/aircraftMonitoring/generatePdf";
import Notification from "pages/elevade/component/Notification";
import RepetitiveDefectSummary from "./RepetitiveDefectSummary";
import { useHistory, useLocation } from "react-router-dom";

const TaskAssignment = ({ aoc, isLoading, setIsLoading }) => {
  const { userProfile: userEntity, userPermission } = useSelector((state) => state.userEntity);
  const dasrMocPermission = userPermission.filter((perm) => perm.code === "DASR-004");
  const submitShiftReportPermsission = userPermission.filter((perm) => perm.code === "DASR-008");
  const [subLoading, setSubLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(true);
  const [type, setType] = useState("1");
  const [value, setValue] = useState("1");
  const [open, setOpen] = useState(false);
  const [openTitle, setOpenTitle] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState([]);
  const [data_, setData_] = useState([]);
  const [dataForm, setDataForm] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [index, setIndex] = useState();
  const [edit, setEdit] = useState(true);
  const [openNotification, setOpenNotification] = useState(false);
  const [newForm, setNewForm] = useState(false);
  const [triggerApi, setTriggerApi] = useState(true);
  const [activeIndex, setActiveIndex] = useState("");
  const [selectToMove, setSelectToMove] = useState([]);
  const [selectRdd, setSelectRdd] = useState([]);
  const [openDialogSubmitShiftReport, setOpenDialogSubmitShiftReport] = useState(false);
  const [openDialogMoveToAssignment, setOpenDialogMoveToAssignment] = useState(false);
  const [isSearch, setIsSearch] = useState([]);
  const InitialFilters = {
    search: [],
    location: [],
    aircraftReg: [],
    assignedTo: [],
    raisedDate: [],
    expiryDate: [],
    assignee: [],
    summaryStatus: [],
  };
  const [isFilters, setIsFilters] = useState(InitialFilters);
  const [filteredData, setFilteredData] = useState([]);
  const [today, setToday] = useState(new Date());
  const [date, setDate] = useState(new Date());
  const [tabbing, setTabbing] = useState(1);
  const [dataFHFC, setDataFHFC] = useState([]);
  const [isOpenAssignee, setIsOpenAssignee] = useState(false);
  const [isActiveLine, setIsActiveLine] = useState();
  const [checkAll, setCheckAll] = useState(false);
  const [isLoadingAssignee, setIsLoadingAssignee] = useState(true);
  const [isAssignee, setIsAssignee] = useState([]);
  const [isDownload, setIsDownload] = useState(false);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const paramsId = urlParams.get("id");
  const paramsDdmlNo = urlParams.get("ddmlno");
  const paramsShiftType = urlParams.get("shiftType");
  const paramsFormType = urlParams.get("formType");
  const history = useHistory();
  const pathname = useLocation().pathname;
  const [isTimeout, setIsTimeout] = useState(false);
  const [dataLocation, setDataLocation] = useState();

  const today_ = new Date();
  const formattedDate = today_.toISOString();

  let sliceStart = page > 0 ? perPage * (page - 1) : 0;
  let sliceEnd = parseInt(perPage) + sliceStart;

  useEffect(() => {
    setIsLoading(false);
    fetchAssignee();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setTotalRows(filteredData?.length);
    setTotalPage(Math.floor(filteredData?.length / perPage) + 1);
  }, [filteredData, perPage]);

  useEffect(() => {
    fetch();
    // eslint-disable-next-line
  }, [date, value, triggerApi, aoc]);
  //date, value, triggerApi, today, aoc
  //openNotification, triggerApi, date, value

  useEffect(() => {
    setTableLoading(true);
    setIsFilters(InitialFilters);
  }, [value]);

  useEffect(() => {
    filterDataBasedOnTabbing();
  }, [tabbing, value, data]);

  useEffect(() => {
    if (!open) {
      setActiveIndex();
      // if (paramsId) history.replace(pathname);
    }
  }, [open]);

  useEffect(() => {
    fetchFHFC();
  }, []);

  // If DDML No exist in url params, apply it as filter
  useEffect(() => {
    if (paramsDdmlNo) {
      setTimeout(function () {
        setIsFilters({ ...isFilters, search: [paramsDdmlNo] });
        setIsTimeout(true);
      }, 1500);
      return () => clearTimeout();
    }
  }, [paramsDdmlNo]);

  // Move the tab according to shift type (if exist in url params)
  useEffect(() => {
    if (paramsShiftType === "day") setValue("1");
    else if (paramsShiftType === "night") setValue("2");
    if (paramsFormType === "ddml") setTabbing(1);
    else if (paramsFormType === "rdd") setTabbing(2);
    else if (paramsFormType === "adhoc") setTabbing(3);
  }, [paramsShiftType, paramsFormType]);

  useEffect(() => {
    if (isTimeout && filteredData.length > 0) {
      setDataForm(filteredData[0]);
      setOpen(true);
      setEdit(false);
    }
  }, [isTimeout]);

  // Applying filters
  useEffect(() => {
    let data__ = [];
    let theData = data_;
    setFilteredData(theData);

    // Search input fields
    if (isFilters.search.length !== 0) {
      data__ = theData.filter(
        (item) =>
          isFilters.search.some((searchTerm) =>
            item.ddml.location?.toLowerCase()?.includes(searchTerm?.toLowerCase())
          ) ||
          isFilters.search.some((searchTerm) =>
            item.ddml.aircraftReg?.toLowerCase()?.includes(searchTerm?.toLowerCase())
          ) ||
          isFilters.search.some((searchTerm) => item.ddml.ddmlNo?.toLowerCase()?.includes(searchTerm?.toLowerCase())) ||
          isFilters.search.some((searchTerm) =>
            item.ddml.limitationsDesc?.toLowerCase()?.includes(searchTerm?.toLowerCase())
          ) ||
          isFilters.search.some((searchTerm) =>
            item.ddml.assignedTo?.toLowerCase()?.includes(searchTerm?.toLowerCase())
          )
      );
      setFilteredData(data__);
    }

    // Station (location)
    if (isFilters.location.length > 0)
      setFilteredData(theData.filter((e) => isFilters.location.includes(e.ddml.location)));
    // Aircraft Registration
    if (isFilters.aircraftReg.length > 0)
      setFilteredData(theData.filter((e) => isFilters.aircraftReg.includes(e.ddml.aircraftReg)));
    // Assigned To (Department)
    if (isFilters.assignedTo.length > 0)
      setFilteredData(theData.filter((e) => isFilters.assignedTo.includes(e.ddml.assignedTo)));
    // Raised Date
    if (isFilters.raisedDate.length > 0)
      setFilteredData(theData.filter((e) => e.ddml.raisedDate.startsWith(isFilters.raisedDate)));
    // Expiry Date
    if (isFilters.expiryDate.length > 0)
      setFilteredData(theData.filter((e) => e.ddml.expiryDate.startsWith(isFilters.expiryDate)));
    // Assignee
    if (isFilters.assignee.length > 0)
      setFilteredData(theData.filter((e) => isFilters.assignee.includes(e.assignee?.name)));
    // eslint-disable-next-line
  }, [isFilters]);

  // Put all record in selectToMove
  useEffect(() => {
    handleCheckAll();
    rddApi();
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (paramsId !== null) {
      let data = filteredData.filter((e) => e.formId === paramsId);

      if (data.length > 0) {
        setDataForm(data[0]);
        setOpen(true);
      }
      setEdit(false);
      setNewForm(false);
    }
  }, [paramsId, filteredData]);

  // A function to fetch all
  async function fetch() {
    setTableLoading(true);
    let formId = "";
    if (paramsId !== null) {
      formId = `&form_id=${paramsId}`;
    }
    // TODO: To update API to return based on selected AOC
    const fetch = makeAPIRequestMDB(
      `get`,
      `api/assignment?aoc=${aoc}&shift=${value === "1" ? "day" : "night"}&date=${formatDate(date)}${formId}`
    );
    fetch
      .then((response) => {
        if (response.detail !== "Record not found" && response.detail !== "DDML not found.") {
          let data = response?.data?.assignments_list?.filter((e) => e.status === "open"); // && e.ddml.formStatus !== "closed"
          setData(data);
          setData_(data);
          if (response?.data?.assignmentShiftType) {
            if (response?.data?.assignmentShiftType === "day") setValue("1");
            else setValue("2");
            if (response?.data?.assignmentFormType === "rdd") setTabbing(2);
            else if (response?.data?.assignmentFormType === "adhoc") setTabbing(3);
            else setTabbing(1);
          }
        } else {
          setData([]);
          setData_([]);
          setFilteredData([]);
        }
        setSubLoading(false);
        setOpenNotification(false);
        setTriggerApi(false);
        setTableLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        setSubLoading(false);
        setTableLoading(false);
      });

    var currentDate = new Date(date);
    var options = { month: "long", day: "2-digit", year: "numeric" };
    var formattedDate = currentDate.toLocaleDateString("en-US", options);
    currentDate = new Date(formattedDate);
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const isToday = `${year}-${month}-${day}`;

    // // get location for RDD
    if (isToday !== "NaN-NaN-NaN") {
      const fetch = makeAPIRequestMDB(`get`, `api/aircraft/daily-status?date=${isToday}&aoc=${aoc}`);
      fetch
        .then((response) => {
          setDataLocation(response.data.sort((a, b) => a.aircraftReg?.localeCompare(b.aircraftReg)));
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    // //////////////////////////
  }

  async function fetchFHFC() {
    // setSubLoading(true);
    const fetch = makeAPIRequestRun(`get`, `acarsdata/fleetgetfcfh/null`);
    fetch
      .then((response) => {
        setDataFHFC(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  async function fetchAssignee() {
    const fetch = makeAPIRequestMDB(`get`, `api/entity/users/lae?aoc=${aoc}`);
    fetch
      .then((response) => {
        setIsAssignee(
          // TODO: Uncomment this before deployment
          process.env.REACT_APP_ENV === "production"
            ? response.data.sort(function (a, b) {
                return a.name.localeCompare(b.name);
              })
            : [
                { name: "Ainul Roslie", email: "ainulroslie@airasia.com" },
                { name: "Khairulisam Ramli", email: "khairulisamramli@airasia.com" },
                { name: "Wan Mohd Aizat Wan Azmin", email: "wanmohdaizatwanazmi@airasia.com" },
                { name: "Abdul Rahman Saad", email: "abdulrahmansaad@airasia.com" },
                { name: "Mahendran Doriasamy", email: "mahendrandoriasamy@airasia.com" },
                { name: "Muhammad Ezuddin Azmi", email: "ezuddin@airasia.com" },
              ]
        );
        setIsLoadingAssignee(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  function filterDataBasedOnTabbing() {
    setSubLoading(true);
    let formType = "ddml";
    let shiftType = "day";
    if (tabbing === 1) formType = "ddml";
    else if (tabbing === 2) formType = "rdd";
    else if (tabbing === 3) formType = "adhoc";

    if (value === "1") shiftType = "day";
    if (value === "2") shiftType = "night";

    let filtered =
      data?.length > 0
        ? data.filter((e) => e.formType === formType && e.shiftType === shiftType && e.formType === formType)
        : [];

    if (tabbing === 2) {
      filtered.map((item) => {
        item.ddml.location = getAircraftLocation(item.ddml.aircraftReg);
      });
    }

    // setData_(filtered);
    setFilteredData(filtered);
    setSubLoading(false);
  }

  function handleCheckAll() {
    let array = [];

    // TODO: recheck on this later
    let array_ = data
      ?.filter((e) => e.status === "open")
      .map((e) => {
        return array.push(e._id);
      });
    setSelectToMove(array);
  }

  function rddApi() {
    let array = [];

    let array_ = data
      ?.filter((e) => e.status === "open" && e.formType === "rdd")
      .map((e) => {
        return array.push(e?.ddml.aircraftRegMsgId);
      });

    setSelectRdd(array);
  }

  function formatDate(dateString) {
    // Parse the input date string
    const dateObj = new Date(dateString);

    // Get the year, month, and day components
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getDate()).padStart(2, "0");

    // Construct the formatted date string
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  }

  function handleClick(e, index) {
    setOpen(true);
    setIndex(index);
    setDataForm(e);
    setEdit(false);
    setActiveIndex(index);
    setNewForm(false);
    setIsOpenAssignee(false);
  }

  function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string?.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        fontSize: "12px",
      },
      children: `${name?.substring(0, 3)}`,
    };
  }

  // Custom styles
  const useStyles = makeStyles(() => ({
    ul: {
      "& .Mui-selected": {
        color: "#fff",
        backgroundColor: "#088FD1",
        border: "1px solid #088FD1",
        "&:hover": {
          backgroundColor: "#088FD1",
        },
      },
    },
    gridItem: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-box-orient": "vertical",
      "-webkit-line-clamp": 2, // Number of lines to show
      fontSize: "14px",
      justifyContent: "flex-start",
    },
    gridItemStatus: {
      display: "-webkit-box",
      WebkitLineClamp: 2,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "pre",
    },
  }));

  const classes = useStyles();

  const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    // padding: 0,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: "rgba(0, 0, 0, 0.85)",
    fontFamily: ["Gotham-Book"].join(","),
    "&:hover": {
      color: "#333333",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#333333",
      fontFamily: "Gotham-Medium",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  }));

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setType(newValue);
  };

  const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme, ...props }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#FFFEE8",
        color: "rgba(0, 0, 0, 0.87)",
        boxShadow: theme.shadows[1],
        fontSize: 11,
        textTransform: "uppercase",
        whiteSpace: props.breakLine || "normal",
      },
    })
  );

  const ErrorAirBallon = () => {
    return (
      <>
        <div className={styles.errorAirBallon}>
          <center>
            <img src={IconErrorAirBalloon} alt="error-air-ballon" />
            <div className={styles.errorTitle}>No Task Assignment Available</div>
            <div className={styles.errorSubTitle}></div>
          </center>
        </div>
      </>
    );
  };

  const Tips = ({ val, unread }) => {
    return (
      <>
        <span
          style={{
            borderRadius: "30px",
            color: unread ? "#ffffff" : "#838383",
            border: unread ? "1px solid #E95048" : "1px solid #E8E8E8",
            backgroundColor: unread ? "#E95048" : "#F7F7F7",
            fontFamily: "Gotham-Book",
            fontSize: "12px",
            marginLeft: "8px",
            padding: "4px 8px 2px 8px",
          }}
        >
          {val || 0}
        </span>
      </>
    );
  };

  const handleSetTabbing = (e) => {
    setTabbing(e);
  };

  const TaskAssignmentList = ({ type }) => {
    return (
      <>
        <Grid container columns={4} sx={{ marginTop: "32px" }}>
          <Grid item xs={3} onClick={() => setOpenModal(false)}>
            <div
              style={{
                borderRadius: "4px",
                backgroundColor: "#F9FAFB",
                padding: "4px 2px",
                fontSize: "12px",
                display: "inline-block",
              }}
            >
              <span
                className={`${styles.tabbing} ${tabbing === 1 ? styles.tabbingActive : ""}`}
                onClick={() => handleSetTabbing(1)}
                style={{ float: "left" }}
              >
                DDML List <Tips val={data?.filter((e) => e.formType === "ddml")?.length} unread={false} />
              </span>
              <span
                className={`${styles.tabbing} ${tabbing === 2 ? styles.tabbingActive : ""}`}
                onClick={() => handleSetTabbing(2)}
                style={{ float: "left" }}
              >
                Repetitive Defect List <Tips val={data?.filter((e) => e.formType === "rdd")?.length} unread={false} />
              </span>
              <span
                className={`${styles.tabbing} ${tabbing === 3 ? styles.tabbingActive : ""}`}
                onClick={() => handleSetTabbing(3)}
                style={{ float: "left" }}
              >
                Additional Task List <Tips val={data?.filter((e) => e.formType === "adhoc")?.length} unread={false} />
              </span>
            </div>
          </Grid>
          <Grid item xs={1} sx={{ display: "flex", justifyContent: "flex-end" }} onClick={() => setOpenModal(false)}>
            <Button
              title="Submit Shift Report"
              icon={IconCheckCircleWhite}
              iconWhite={IconCheckCircleWhite}
              iconDisabled={IconCheckCircleDisabled}
              variant={
                submitShiftReportPermsission.some((perm) => perm.canUpdate === true)
                  ? filteredData?.length > 0
                    ? "fill"
                    : "disabled"
                  : "disabled"
              }
              onClick={() => setOpenDialogSubmitShiftReport(true)}
            />
            <Button
              title="Download"
              icon={IconDownload}
              iconWhite={IconDownloadWhite}
              iconDisabled={IconDownloadDisabled}
              variant={
                submitShiftReportPermsission.some((perm) => perm.canUpdate === true)
                  ? data?.length > 0
                    ? "outlined"
                    : "disabled"
                  : "disabled"
              }
              onClick={async () => {
                pdfPrep();
              }}
            />
          </Grid>
          {filteredData !== undefined && filteredData !== "" && filteredData?.length > 0 && (
            <>
              <Grid item xs={4} sx={{ paddingTop: "32px", paddingLeft: "16px" }}>
                <SearchBar
                  open={openModal}
                  setOpen={setOpenModal}
                  data={data_}
                  isSearch={isSearch}
                  setIsSearch={setIsSearch}
                  isFilters={isFilters}
                  setIsFilters={setIsFilters}
                  openTitle={openTitle}
                  setOpenTitle={setOpenTitle}
                  sub={true}
                />
                <ActiveFilters isFilters={isFilters} setIsFilters={setIsFilters} initialFilters={InitialFilters} />
              </Grid>
              <Grid item xs={4} sx={{ display: "flex", paddingTop: "32px" }}>
                {/* <Button
                  title="Delete"
                  icon={IconDustbin}
                  iconWhite={IconDustbinWhite}
                  iconDisabled={IconDustbinDisabled}
                  variant={selectToMove.length > 0 ? "outlined" : "disabled"}
                  onClick={() => setOpenDialogSubmitShiftReport(true)}
                />
                <Button
                  title="Push to 145"
                  icon={IconBriefcase}
                  iconWhite={IconBriefcaseWhite}
                  iconDisabled={IconBriefcaseDisabled}
                  variant={selectToMove.length > 0 ? "outlined" : "disabled"}
                  onClick={() => setOpenDialogMoveToAssignment(true)}
                /> */}
              </Grid>
              <Grid item xs={4} sx={{ paddingTop: "16px" }} onClick={() => setOpenModal(false)}>
                {subLoading ? <Overlay /> : <TableList type={type} />}
              </Grid>
            </>
          )}
          {(data?.length === 0 || filteredData?.length === 0) && !subLoading && !tableLoading && (
            <Grid item xs={4} sx={{ display: "flex", justifyContent: "center" }} onClick={() => setOpenModal(false)}>
              <ErrorAirBallon />
            </Grid>
          )}
          {tableLoading && (
            <Grid item xs={4} sx={{ display: "flex", justifyContent: "center" }} onClick={() => setOpenModal(false)}>
              <AirplaneLoader />
            </Grid>
          )}
        </Grid>
      </>
    );
  };

  const pdfPrep = async () => {
    setIsDownload(true);
    try {
      const tableHeader = [
        "A/C Reg",
        "Defect Desc.",
        "DDML No.",
        "MEL Ref.",
        "Raised Date",
        "Assignee",
        "Balance",
        "Rectification",
      ];
      const fileName = "Task Assignment List";
      const title = "Task Assignment List";
      const tableRows = [];

      // for each res pass all its data into an array
      // eslint-disable-next-line no-unused-expressions
      filteredData?.forEach((e) => {
        const pdfData = [
          e.aircraftReg,
          e.defectDesc,
          e.ddmlNo,
          e.melRef,
          e.raisedDate,
          e.assignee,
          e.expiryDate,
          e.rectificationStatus,
          e.ata_chapter,
          e.source,
        ];
        // push each data info into an array
        tableRows.push(pdfData);
      });
      TaskAssignmentPDF(date, value, data);
    } catch (err) {
      Notification("danger", "", [err]);
    }
    setTimeout(() => {
      setIsDownload(false);
    }, 1500);
  };

  function calcBalanceCD(calendarDaysStartDate, calendarDaysInterval) {
    let startDate = calendarDaysStartDate;
    let interval = calendarDaysInterval || 0;
    let expiry = addingDate(startDate, interval);
    let today = setToday();
    let balance = calculateBalanceDay(today?.split("T")[0], expiry?.split("T")[0]);
    return balance;
  }

  const IconUnreadIndicator = ({ data }) => {
    let unreadLogs = data.unreadLogs;
    let count = unreadLogs?.materialTool.length + unreadLogs?.planningTech.length + unreadLogs?.rectification.length;

    return (
      count > 0 && (
        <div style={{ position: "absolute", right: "24px" }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
            <circle cx="5" cy="5" r="5" fill="#0ADDDD" />
          </svg>
        </div>
      )
    );
  };

  function getDate(value, fallback) {
    return value || fallback;
  }

  function getCurrentFlightData(reg, data) {
    const record = data.find((item) => item.AC_REG === reg);
    return record ? { flightHour: record.flight_hour, flightCycle: record.flight_cycle } : {};
  }

  function getBalance(e, calendarDaysStartDate, calendarDaysInterval, currentFlightHour, currentFlightCycle) {
    if (e.ddml.formType === "STR/FC/FH") {
      switch (e.ddml.showInTable) {
        case "fh":
          return calcBalanceFH(e.ddml.flightHoursInterval, e.ddml.flightHoursRaisedAt, currentFlightHour);
        case "fc":
          return calcBalanceFC(e.ddml.flightCycleInterval, e.ddml.flightCycleRaisedAt, currentFlightCycle);
        default:
          return calcBalanceCD(calendarDaysStartDate, calendarDaysInterval);
      }
    }

    if (e.ddml.formType === "AMD" || e.ddml.formType === "Fuel Sampling" || e.ddml.formType === "Hydraulic Sampling") {
      return calculateCalendarBalance(e.ddml.calendarDaysStartDate, e.ddml.calendarDaysInterval);
    }

    if (!e.ddml.flightCycle && !e.ddml.flightHours && !e.ddml.calendarDays && e.ddml.formType === "STR/FC/FH") {
      return "-";
    }

    return calcBalanceCD(calendarDaysStartDate, calendarDaysInterval);
  }

  function getExpiryDate(e) {
    if (e.ddml.formType === "MEL CAT" && e.ddml.flightDays) {
      return calculateCalendarExpiryDate(e.ddml.flightDaysStartDate, e.ddml.flightDaysInterval);
    }

    if (e.ddml.calendarDays) {
      return calculateCalendarExpiryDate(e.ddml.calendarDaysStartDate, e.ddml.calendarDaysInterval);
    }

    return formatDateDasr(e.ddml.expiryDate);
  }

  function getFCFHIndicator(showInTable) {
    switch (showInTable) {
      case "fh":
        return " (FH)";
      case "fc":
        return " (FC)";
      default:
        return " Days";
    }
  }

  function handleSetPriority(id) {
    // Call API to set priority in assignment collection
    if (dasrMocPermission.some((perm) => perm.canUpdate === true)) setPriority(id);
  }

  // A function to set priority
  async function setPriority(id) {
    const body = {
      formId: id,
      formType: value === "1" ? "ddml" : value === "2" ? "rdd" : "adhoc",
    };
    const update = makeAPIRequestMDB(`put`, `api/assignment/set-priority`, body);
    update.then((response) => {
      if (response.detail.includes("success")) {
        setTriggerApi(true);
        return true;
      } else {
        showNotificationElevade("danger", "An error occured!", ["Please contact your system administartor"]);
        return false;
      }
    });
  }

  const TableList = () => {
    let formType = "";
    if (tabbing === 1) formType = "ddml";
    else if (tabbing === 2) formType = "rdd";
    else if (tabbing === 3) formType = "adhoc";
    return filteredData?.length > 0 && !tableLoading ? (
      <>
        <div
          style={{
            width: "100%",
            borderRadius: "12px",
            overflow: "visible",
            border: "1px solid #E8E8E8",
          }}
          onClick={() => setOpenModal(false)}
        >
          <Grid container columns={13}>
            <Grid item xs={0.5} className={styles.tableHeader} sx={{ borderRadius: "11px 0 0 0" }}></Grid>
            <Grid item xs={0.8} className={styles.tableHeader}>
              Station
            </Grid>
            {tabbing === 2 && (
              <>
                <Grid item xs={0.8} className={styles.tableHeader}>
                  TSM Ref.
                </Grid>
              </>
            )}
            <Grid item xs={0.9} className={styles.tableHeader}>
              A/C Reg.
            </Grid>
            {tabbing === 1 && (
              <>
                <Grid item xs={2.5} className={`${styles.tableHeader} ${styles.gridJustifyStart}`}>
                  Defect Description
                </Grid>
                <Grid item xs={1} className={styles.tableHeader}>
                  DDML No.
                </Grid>
                <Grid item xs={1} className={styles.tableHeader}>
                  Ref.
                </Grid>
                <Grid item xs={1} className={styles.tableHeader}>
                  Raised Date
                </Grid>
                <Grid item xs={1} className={styles.tableHeader}>
                  Expiry/Balance
                </Grid>
                <Grid item xs={1.3} className={styles.tableHeader}>
                  Assignee
                </Grid>
              </>
            )}
            {tabbing === 2 && (
              <>
                <Grid item xs={2.5} className={`${styles.tableHeader} ${styles.gridJustifyStart}`}>
                  Messages
                </Grid>
                <Grid item xs={1.3} className={styles.tableHeader}>
                  Date
                </Grid>
                <Grid item xs={2} className={styles.tableHeader}>
                  ATA
                </Grid>
                <Grid item xs={1.2} className={styles.tableHeader}>
                  Assignee
                </Grid>
              </>
            )}
            {tabbing === 3 && (
              <>
                <Grid item xs={2.5} className={`${styles.tableHeader} ${styles.gridJustifyStart}`}>
                  Task Description
                </Grid>
                <Grid item xs={1} className={styles.tableHeader}>
                  Status/DDML
                </Grid>
                <Grid item xs={2} className={styles.tableHeader}>
                  Raised Date
                </Grid>
                <Grid item xs={2} className={styles.tableHeader}>
                  ETS
                </Grid>
                <Grid item xs={1.3} className={styles.tableHeader}>
                  Assignee
                </Grid>
                {/* <Grid item xs={2} className={`${styles.tableHeader} ${styles.gridJustifyStart}`}>
                  Remarks
                </Grid> */}
              </>
            )}
            <Grid
              item
              xs={tabbing === 1 ? 3 : tabbing === 3 ? 2 : 3}
              className={`${styles.tableHeader} ${styles.gridJustifyStart}`}
              sx={{ borderRadius: "0 11px 0 0" }}
            >
              {isFilters.summaryStatus[0] || "Rectification Status"}
            </Grid>
            {filteredData &&
              filteredData
                .slice(sliceStart, sliceEnd)
                .filter((e) => e.formType === formType)
                .map((e, index) => {
                  let calendarDaysInterval = e.ddml.calendarDaysInterval || 0;
                  let calendarDaysStartDate = getDate(e.ddml.calendarDaysStartDate, e.ddml.raisedDate);
                  let expiry = addingDate(calendarDaysStartDate, calendarDaysInterval);
                  let date1 = e.ddml.formType === "AMD" ? calendarDaysStartDate : formattedDate?.split("T")[0];
                  let date2 = expiry;
                  let balance = calculateBalanceDay(date1, date2);
                  let currentFlightData = getCurrentFlightData(e.ddml.aircraftReg, dataFHFC);
                  balance = getBalance(
                    e,
                    calendarDaysStartDate,
                    calendarDaysInterval,
                    currentFlightData.flightHour,
                    currentFlightData.flightCycle
                  );
                  let fcfhIndicator = getFCFHIndicator(e.ddml.showInTable);
                  let expiryDate_ = getExpiryDate(e);

                  // Calculate the date difference
                  let currentDate = new Date();
                  let timeDifferenceMs = new Date(e.ddml.expiryDate) - currentDate;
                  let remainingDays = Math.ceil(timeDifferenceMs / (1000 * 60 * 60 * 24));

                  let status = getRectificationStatus(e.ddml) || e.ddml.actionBy145;
                  let statusRectification = getRectificationStatus(e.ddml) || e.ddml.rectificationStatus;
                  return (
                    <>
                      <Grid item xs={13}>
                        <Grid
                          container
                          columns={13}
                          className={`${styles.lineItems} ${activeIndex === index ? styles.selectedIndex : ""}`}
                          sx={{ zIndex: 1 }}
                        >
                          <Grid item xs={0.5} className={`${styles.tableBody} ${styles.divSpaceBetween}`}>
                            <LightTooltip title={"Priority"} followCursor placement="bottom-start">
                              <img
                                src={e.priority ? IconFlagActive : IconFlag}
                                alt="icon-flag"
                                height={18}
                                style={{ marginRight: "4px", marginTop: "1px" }}
                                onClick={() => handleSetPriority(e.formId)}
                              />
                            </LightTooltip>
                            <LightTooltip
                              title={e.ddml.limitations ? "Limitation triggered" : ""}
                              followCursor
                              placement="bottom-start"
                            >
                              {e.ddml.limitations && (
                                <img src={IconAirplaneLimitation} alt={"warning-circle"} height={20} />
                              )}
                            </LightTooltip>
                            <LightTooltip
                              title={e.ddml.reInspection ? "Reinspection" : ""}
                              followCursor
                              placement="bottom-start"
                            >
                              {e.ddml.reInspection && (
                                <img
                                  src={IconReInspection}
                                  alt={"icon-reinspection"}
                                  height={20}
                                  style={{ marginLeft: "4px" }}
                                />
                              )}
                            </LightTooltip>
                          </Grid>
                          <Grid
                            item
                            xs={0.8}
                            className={`${styles.tableBody} ${styles.toUpperCase}`}
                            onClick={() => handleClick(e, index)}
                          >
                            {tabbing === 2 ? getAircraftLocation(e?.ddml?.aircraftReg) : e.ddml.location}
                          </Grid>
                          {tabbing === 2 && (
                            <Grid
                              item
                              xs={0.8}
                              className={`${styles.tableBody} ${styles.toUpperCase}`}
                              onClick={() => handleClick(e, index)}
                            >
                              {e?.ddml?.tsmRef || "-"}
                            </Grid>
                          )}
                          <Grid
                            item
                            xs={0.9}
                            className={`${styles.tableBody} ${styles.toUpperCase}`}
                            onClick={() => handleClick(e, index)}
                          >
                            {e.ddml.aircraftReg}
                          </Grid>
                          {tabbing === 1 && (
                            <>
                              <Grid
                                item
                                xs={2.5}
                                className={`${styles.tableBody} ${styles.toUpperCase}`}
                                sx={{ display: "flex", justifyContent: "flex-start" }}
                                onClick={() => handleClick(e, index)}
                              >
                                <LightTooltip title={e.ddml.defectDesc} followCursor placement="bottom-start">
                                  <div className={classes.gridItem}>{e.ddml.defectDesc}</div>
                                </LightTooltip>
                              </Grid>
                              <Grid
                                item
                                xs={1}
                                className={`${styles.tableBody} ${styles.toUpperCase}`}
                                onClick={() => handleClick(e, index)}
                              >
                                {e.ddml.ddmlNo}
                              </Grid>
                              <Grid
                                item
                                xs={1}
                                className={`${styles.tableBody} ${styles.toUpperCase}`}
                                onClick={() => handleClick(e, index)}
                              >
                                {e.ddml.melRef && (
                                  <center>
                                    {e.ddml.melRef}{" "}
                                    {e.ddml.formType === "MEL CAT" && (
                                      <>
                                        <br /> {"(" + e.ddml.melCatType + ")"}
                                      </>
                                    )}
                                  </center>
                                )}
                              </Grid>
                              <Grid item xs={1} className={`${styles.tableBody}`} onClick={() => handleClick(e, index)}>
                                <center>{formatDateDasr(e.ddml.raisedDate)}</center>
                              </Grid>
                              <Grid
                                item
                                xs={1}
                                className={`${styles.tableBody}`}
                                sx={{
                                  color:
                                    e.formType === "MEL CAT"
                                      ? remainingDays <= 3
                                        ? "#E95048"
                                        : ""
                                      : e.formType === "STR/FC/FH"
                                      ? balance <= 20
                                        ? "#E95048"
                                        : ""
                                      : remainingDays <= 3
                                      ? "#E95048"
                                      : "",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                                onClick={() => handleClick(e, index)}
                              >
                                <center>
                                  {e.ddml.formType === "STR/FC/FH"
                                    ? balance + fcfhIndicator
                                    : e.ddml.formType === "MEL CAT"
                                    ? formatDateDasr(e.ddml.expiryDate)
                                    : expiryDate_}
                                </center>
                              </Grid>
                              <Grid
                                item
                                xs={1.3}
                                className={`${styles.tableBody} ${styles.toUpperCase} ${styles.flexStart}`}
                              >
                                <Assignee
                                  data={e}
                                  list={isAssignee}
                                  isOpen={isOpenAssignee}
                                  setIsOpen={setIsOpenAssignee}
                                  isActiveLine={isActiveLine}
                                  setIsActiveLine={setIsActiveLine}
                                  setTriggerApi={setTriggerApi}
                                  isLoading={isLoadingAssignee}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                className={`${styles.tableBody} ${styles.toUpperCase}`}
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  position: "relative",
                                  paddingRight: "34px",
                                }}
                                onClick={() => handleClick(e, index)}
                              >
                                <LightTooltip
                                  title={status}
                                  followCursor
                                  placement="bottom-start"
                                  breakLine={/\r|\n/.test(status) ? "pre-wrap" : "wrap"}
                                >
                                  <div className={/\r|\n/.test(status) ? classes.gridItemStatus : classes.gridItem}>
                                    {status}
                                  </div>
                                </LightTooltip>
                                <IconUnreadIndicator data={e.ddml} />
                              </Grid>
                            </>
                          )}
                          {tabbing === 2 && (
                            <>
                              <Grid
                                item
                                xs={2.5}
                                className={`${styles.tableBody} ${styles.toUpperCase} ${styles.flexStart}`}
                                onClick={() => handleClick(e, index)}
                              >
                                <LightTooltip title={status} followCursor placement="bottom-start">
                                  <div className={classes.gridItem}>{e?.ddml?.text}</div>
                                </LightTooltip>
                              </Grid>
                              <Grid
                                item
                                xs={1.3}
                                className={`${styles.tableBody} ${styles.toUpperCase}`}
                                onClick={() => handleClick(e, index)}
                              >
                                {formatDateDasr(e.ddml.createdAt)}
                              </Grid>
                              <Grid
                                item
                                xs={2}
                                className={`${styles.tableBody} ${styles.toUpperCase}`}
                                onClick={() => handleClick(e, index)}
                              >
                                {getAtaChapter(e?.ddml?.ataChapter, e?.ddml?.report_type)}
                              </Grid>
                              <Grid item xs={1.2} className={`${styles.tableBody} ${styles.toUpperCase}`}>
                                <Assignee
                                  data={e}
                                  list={isAssignee}
                                  isOpen={isOpenAssignee}
                                  setIsOpen={setIsOpenAssignee}
                                  isActiveLine={isActiveLine}
                                  setIsActiveLine={setIsActiveLine}
                                  setTriggerApi={setTriggerApi}
                                  isLoading={isLoadingAssignee}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                className={`${styles.tableBody} ${styles.toUpperCase}`}
                                sx={{ justifyContent: "flex-start", position: "relative", paddingRight: "34px" }}
                                onClick={() => handleClick(e, index)}
                              >
                                <LightTooltip
                                  title={statusRectification}
                                  followCursor
                                  placement="bottom-start"
                                  breakLine={/\r|\n/.test(statusRectification) ? "pre" : "wrap"}
                                >
                                  <div
                                    className={
                                      /\r|\n/.test(statusRectification) ? classes.gridItemStatus : classes.gridItem
                                    }
                                  >
                                    {statusRectification}
                                  </div>
                                </LightTooltip>
                                <IconUnreadIndicator data={e.ddml} />
                              </Grid>
                            </>
                          )}
                          {tabbing === 3 && (
                            <>
                              <Grid
                                item
                                xs={2.5}
                                className={`${styles.tableBody} ${styles.toUpperCase}`}
                                sx={{ display: "flex", justifyContent: "flex-start" }}
                                onClick={() => handleClick(e, index)}
                              >
                                <LightTooltip title={e.ddml.defectDesc} followCursor placement="bottom-start">
                                  <div className={classes.gridItem}>{e.ddml.defectDesc}</div>
                                </LightTooltip>
                              </Grid>
                              <Grid
                                item
                                xs={1}
                                className={`${styles.tableBody} ${styles.toUpperCase}`}
                                onClick={() => handleClick(e, index)}
                              >
                                <LightTooltip title={e.ddml.status} followCursor placement="bottom-start">
                                  <div className={classes.gridItem}>{e.ddml.status}</div>
                                </LightTooltip>
                              </Grid>
                              <Grid
                                item
                                xs={2}
                                className={`${styles.tableBody} ${styles.toUpperCase}`}
                                onClick={() => handleClick(e, index)}
                              >
                                {formatDateDasr(e.ddml.raisedDate)}
                              </Grid>
                              <Grid
                                item
                                xs={2}
                                className={`${styles.tableBody} ${styles.toUpperCase}`}
                                onClick={() => handleClick(e, index)}
                              >
                                {formatDateDasr(e.ddml.ets)}
                              </Grid>
                              <Grid
                                item
                                xs={1.3}
                                className={`${styles.tableBody} ${styles.toUpperCase} ${styles.flexStart}`}
                              >
                                <Assignee
                                  data={e}
                                  list={isAssignee}
                                  isOpen={isOpenAssignee}
                                  setIsOpen={setIsOpenAssignee}
                                  isActiveLine={isActiveLine}
                                  setIsActiveLine={setIsActiveLine}
                                  setTriggerApi={setTriggerApi}
                                  isLoading={isLoadingAssignee}
                                />
                              </Grid>
                              {/* <Grid
                              item
                              xs={2}
                              className={`${styles.tableBody} ${styles.toUpperCase}`}
                              sx={{ justifyContent: "flex-start" }}
                              onClick={() => handleClick(e, index)}
                            >
                              <LightTooltip title={e.ddml.remarks} followCursor placement="bottom-start">
                                <div className={`${styles.truncateText}`}>{e.ddml.remarks}</div>
                              </LightTooltip>
                            </Grid> */}
                              <Grid
                                item
                                xs={2}
                                className={`${styles.tableBody} ${styles.toUpperCase}`}
                                sx={{ justifyContent: "flex-start", position: "relative", paddingRight: "34px" }}
                                onClick={() => handleClick(e, index)}
                              >
                                <LightTooltip
                                  title={statusRectification}
                                  followCursor
                                  placement="bottom-start"
                                  breakLine={/\r|\n/.test(statusRectification) ? "pre" : "wrap"}
                                >
                                  <div
                                    className={
                                      /\r|\n/.test(statusRectification) ? classes.gridItemStatus : classes.gridItem
                                    }
                                  >
                                    {statusRectification}
                                  </div>
                                </LightTooltip>
                                <IconUnreadIndicator data={e.ddml} />
                              </Grid>
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </>
                  );
                })}
            <Grid item xs={13} className={`${styles.tableBodyWhite}`} sx={{ borderRadius: "0 0 11px 11px" }}>
              <MUIPagination
                page={parseInt(page)}
                setPage={setPage}
                setPerPage={setPerPage}
                totalPage={parseInt(totalPages)}
                classes={{ ul: classes.ul }}
                totalRows={parseInt(totalRows)}
                sliceStart={sliceStart}
                initialPerPage={perPage}
              />
            </Grid>
          </Grid>
        </div>
      </>
    ) : tableLoading ? (
      <AirplaneLoader />
    ) : (
      <ErrorAirBallon />
    );
  };

  function getRectificationStatus(item) {
    // Check for active Summary Status selection
    if (isFilters.summaryStatus.length > 0) {
      let status =
        isFilters.summaryStatus[0] === "Rectification Status"
          ? item?.updateStatusLog?.rectificationUpdate
          : isFilters.summaryStatus[0] === "Material Summary"
          ? item?.updateStatusLog?.materialUpdate
          : isFilters.summaryStatus[0] === "Planning/Tech Service"
          ? item?.updateStatusLog?.ppcUpdate
          : isFilters.summaryStatus[0] === "MOC Remarks"
          ? item?.mocHighlight
          : "";
      return status;
    } else {
      if (item?.statusLog?.pushToSummary === "") return undefined;
      else {
        return item?.statusLog?.updateLogDesc;
      }
    }
  }

  function getAtaChapter(ata, report_type) {
    if (ata && report_type === "FAULT")
      return ata.substring(0, 2) + "-" + ata.substring(2, 4) + "-" + ata.substring(4, 6);
    else if (ata && report_type === "WARNING") return ata.substring(0, 2) + "-" + ata.substring(2, 4);
    else if (ata) return ata.substring(0, 2) + "-" + ata.substring(2, 4) + "-" + ata.substring(4, 6);
  }

  function getAircraftLocation(acreg) {
    if (acreg) {
      const location = dataLocation.find((elem) => elem?.aircraftReg === acreg);
      return location?.status?.hub || "-";
    } else {
      return "-";
    }
  }

  return !isLoading && !subLoading ? (
    <>
      <Helmet>
        <title>Royal Brunei Airlines - ELEVADE - Task Assigment List</title>
      </Helmet>
      <div id="main" className={`${styles.container}`}>
        <>
          <Grid container columns={8.2} className={styles.gridContainer}>
            <Grid item xs={4.2} className={styles.gridTitle} onClick={() => setOpenModal(false)}>
              Task Assignment List
            </Grid>
            <Grid
              item
              xs={4}
              className={styles.gridJustifyEnd}
              sx={{ fontSize: "14px", paddingBottom: "16px" }}
              onClick={() => setOpenModal(false)}
            >
              <DateToggle date={date} setDate={setDate} today={today} setToday={setToday} />
            </Grid>
            <Grid item xs={8.2} sx={{ paddingTop: "10px" }}>
              <TabContext value={value}>
                <TabList onChange={handleChange} aria-label="lab API tabs example" onClick={() => setOpenModal(false)}>
                  <AntTab label="Day Shift" value="1" />
                  <AntTab label="Night Shift" value="2" />
                </TabList>
                <div
                  style={{
                    width: "100%",
                    height: "2px",
                    backgroundColor: "#d1d1d1",
                    marginTop: "-2px",
                  }}
                ></div>
                <div
                  style={{
                    marginLeft: "-20px",
                    marginRight: "-20px",
                    marginTop: "-24px",
                  }}
                >
                  <TabPanel value="1">{<TaskAssignmentList type={"DDML"} />}</TabPanel>
                  <TabPanel value="2">{<TaskAssignmentList type={"Adhoc"} />}</TabPanel>
                </div>
              </TabContext>
            </Grid>
          </Grid>
        </>
      </div>
      <ElevadeDrawer
        open={open}
        setOpen={setOpen}
        data={data}
        Component={
          tabbing === 1
            ? DDML
            : tabbing === 2
            ? RepetitiveDefectSummary
            : tabbing === 3
            ? edit
              ? AdditionalTaskForm
              : AdditionalTaskSummary
            : ""
        }
        aoc={aoc}
        type={type}
        index={index}
        dataForm={tabbing === 3 ? dataForm.ddml : dataForm}
        setDataForm={setDataForm}
        edit={edit}
        setEdit={setEdit}
        openNotification={openNotification}
        setOpenNotification={setOpenNotification}
        newForm={newForm}
        setNewForm={setNewForm}
        triggerApi={triggerApi}
        setTriggerApi={setTriggerApi}
        setSelectToMove={setSelectToMove}
        setOpenDialogMoveToAssignment={setOpenDialogMoveToAssignment}
        date={date}
        initialData={tabbing === 3 ? dataForm : dataForm.ddml}
        dataList={data}
        refetch={fetch}
        dataLocation={dataLocation}
      />
      <DialogPush145
        aoc={aoc}
        open={openDialogMoveToAssignment}
        setOpen={setOpenDialogMoveToAssignment}
        data={selectToMove}
        setTriggerApi={setTriggerApi}
        setData={setSelectToMove}
        setCheckAll={setCheckAll}
        tab={value}
      />
      <DialogSubmitShiftReport
        open={openDialogSubmitShiftReport}
        setOpen={setOpenDialogSubmitShiftReport}
        data={selectToMove}
        setTriggerApi={setTriggerApi}
        setData={setSelectToMove}
        selectRdd={selectRdd}
      />
    </>
  ) : (
    <AirplaneLoader />
  );
};

export default TaskAssignment;
