import { env_var_REACT_APP_API_URL, env_var_REACT_APP_API_URL_ADV } from "../../envVar/vars";
import useGetGQL from "../useGetGQL";
import { useAppSelector } from "../../app/hooks";
import { jsArraySortFunctionComparator } from "../../utils/arrayUtils";

/**
 * @typedef {Object} UseGetAllFleetsProps
 * @property {string} searchRegNo
 * @property {number} [limit]
 * @property {number} page
 * @property {string[]} filterModels
 * @property {string[]} filterEnType
 * @property {string[]} filterRegNos
 */
/**
 * @description Status : migrated!
 * @param {boolean} byCompanyCode - [default : true] is filtered by aocCode of currrent user
 * @param {UseGetAllFleetsProps} props
 */
export function useGetAllFleets(
	byCompanyCode = true,
	props,
) {

	const {
		searchRegNo,
		limit,
		page = 1,
		filterRegNos,
		filterModels,
		filterEnType,
	} = props ?? {}

	const { companyProfile } = useAppSelector((state) => state.userEntity);
	const code = companyProfile?.code;

	const data = useGetGQL({
		gqlquery: code ? `
        query allFleets {
            allFleets 
			${filterModels ||
				filterEnType ||
				filterRegNos ||
				page || limit || byCompanyCode || searchRegNo ? '(' : ''}
			
			${byCompanyCode ? `aocCode: "${code}"` : ''}
			${searchRegNo ? `icontainsRegNo:"${searchRegNo}"` : ""}
			${limit ? `limit:${limit}` : ""}
			${page ? `page:${page}` : ""}
			${filterModels?.length > 0 ? `multipleModel:${JSON.stringify(filterModels)}` : ""}
			${filterEnType?.length > 0 ? `multipleEngineType:${JSON.stringify(filterEnType)}` : ""}
			${filterRegNos?.length > 0 ? `multipleRegNo:${JSON.stringify(filterRegNos)}` : ""}

			${filterModels ||
				filterEnType ||
				filterRegNos ||
				page || limit || byCompanyCode || searchRegNo ? ')' : ''}
			{
				totalItems
        		totalFilters
				items {
					id
					regNo
					aocCode
					fleetType {
						_id
						model
						engineType
					}
					totalActive
					totalStatus
					totalNearExpiry
					totalTagError
					totalExpired
					totalLost
					totalUnassign
					latestPic
					latestSync
					latestOperationDate
					latestLocation	
				}
            }
        }
        ` : "",
		totalCountSign: 'totalItems',
		totalCountFilteredSign: 'totalFilters',
		querySign: "allFleets",
		url: env_var_REACT_APP_API_URL_ADV ?? "",
		defaultIsLoading: true,
		isEdges: false,
	});

	return {
		...data,
		data: data?.data?.items,
	};
}

export function useGetAllFleetByCompanyCode() {
	const { companyProfile } = useAppSelector((state) => state.userEntity);
	const code = companyProfile?.code;

	const data = useGetGQL({
		gqlquery: code ? `
        query allLAC{
            allFleets (
               aocCode: "${code}"
            ) {
              edges {
                node {
                  regNo
                  model
                  totalActive
                  totalLost
                  totalRemind
                  totalDefect
                  totalStatus
                  totalExpired
                  totalTagError
                }
              }
            }
          }
        ` : "",
		querySign: "allFleets",
		url: env_var_REACT_APP_API_URL ?? "",
		defaultIsLoading: true,
		isEdges: true,
	});

	return data;
}

/**
 * @description Status : migrated!
 * @changed useGetAllFleetDistincted => useGetAllFleetDistinctedByModel
 */
export function useGetAllFleetDistinctedByModel() {
	// const data = useGetGQL({
	// 	gqlquery: `
	//     query models{
	//         allFleets(distinctModel:true){
	//           edges{
	//             node{
	// 				id
	// 				model
	// 				engineType
	//             }
	//           }
	//         }
	//       }
	//     `,
	// 	querySign: "allFleets",
	// 	url: env_var_REACT_APP_API_URL ?? "",
	// 	defaultIsLoading: true,
	// });

	const dataAdv = useGetGQL({
		gqlquery: `
			query allFleetTypeDistinctModels {
				allFleetTypesDistinctModel (distinctModel:true) {
					items {
						engineType
						_id
						model
					}
				}
			}
        `,
		querySign: "allFleetTypesDistinctModel",
		url: env_var_REACT_APP_API_URL_ADV ?? "",
		defaultIsLoading: true,
		isEdges: false,
	});

	function convertFromNew(dataAdv) {
		return {
			...dataAdv,
			data: dataAdv?.data?.items?.map((item) => {
				return {
					...item,
					id: item?._id,
				}
			}).filter((item) => { return item?.model ? true : false })
		}
	}
	// function convertFromOld(data) {
	// 	return {
	// 		...data,
	// 		data: data?.data?.map(({ node }) => {
	// 			return {
	// 				...node,
	// 			}
	// 		}).filter((item) => { return item?.model ? true : false })
	// 	}
	// }

	// console.log({
	// 	title: "allFleets > allFleetTypesDistinctModel",
	// 	data,
	// 	dataAdv,
	// 	convertedNew: convertFromNew(dataAdv),
	// 	convertedOld: convertFromOld(data),
	// })

	// return data;
	return convertFromNew(dataAdv);
}

/**
 * @description Status : migrated!
 */
export function useGetAllFleetDistinctedEngine() {
	// const data = useGetGQL({
	// 	gqlquery: `
	//     query models{
	//         allFleets(distinctEngineType:true){
	//           edges{
	//             node{
	//               engineType
	//             }
	//           }
	//         }
	//       }
	//     `,
	// 	querySign: "allFleets",
	// 	url: env_var_REACT_APP_API_URL ?? "",
	// 	defaultIsLoading: true,
	// });

	const dataAdv = useGetGQL({
		gqlquery: `
			query allFleetTypesDistinctEngineType {
				allFleetTypesDistinctEngineType (distinctEngineType:true) {
					items {
						engineType
					}
				}
			}
        `,
		querySign: "allFleetTypesDistinctEngineType",
		url: env_var_REACT_APP_API_URL_ADV ?? "",
		defaultIsLoading: true,
		isEdges: false,
	});

	function convertNewToOld(dataAdv) {
		return {
			...dataAdv,
			data: dataAdv?.data?.items?.map((item) => {
				return {
					...item,
				}
			})
		}
	}

	// console.log({
	// 	title: "allFleets > allFleetTypesDistinctEngineType",
	// 	data,
	// 	dataAdv,
	// 	converted: convertNewToOld(dataAdv),
	// })

	// return data
	return convertNewToOld(dataAdv)

}

/**
 * @description deprecated...
 * @deprecated 
 * @see please use {@link useGetAllFleets}
 */
export function useGetAllFleetByCompanyCodeSearchedByRegNo({ searchRegNo }) {
	const { companyProfile } = useAppSelector((state) => state.userEntity);
	const code = companyProfile?.code;

	const data = useGetGQL({
		gqlquery: `
        query list{
            allFleets(
            aocCode: "${code}", 
			${searchRegNo ? `icontainsRegNo:"${searchRegNo}"` : ""}
            ){
              edges{
                node{
                  id
                  regNo
                }
              }
            }
          }
        `,
		querySign: "allFleets",
		url: env_var_REACT_APP_API_URL ?? "",
		defaultIsLoading: true,
		isEdges: true,
	});

	return data;
}

export function useGetAllFleetSearchedByRegNo({ searchRegNo }) {
	const { companyProfile } = useAppSelector((state) => state.userEntity);
	const code = companyProfile?.code;

	const data = useGetGQL({
		gqlquery: `
        query list{
            allFleets
            ${searchRegNo ? `(icontainsRegNo:"${searchRegNo}")` : ""}
            {
              edges{
                node{
                  id
                  regNo
                }
              }
            }
          }
        `,
		querySign: "allFleets",
		url: env_var_REACT_APP_API_URL ?? "",
		defaultIsLoading: true,
		isEdges: true,
	});

	return data;
}

/**
 * @description Status : migrated!
 */
export function useGetAllFleetByCompanyCodeSearchedByModel(props) {

	const { inputModel } = props ?? {};

	const { companyProfile } = useAppSelector((state) => state.userEntity);
	const code = companyProfile?.code;

	const data = useGetGQL({
		gqlquery: `
        query list{
            allFleets(
              aocCode: "${code}"
              ${inputModel ? `model:"${inputModel}"` : ""}
              ){
                edges {
					node {
						id
						model
						regNo
					}
                }
            }
        }
        `,
		querySign: "allFleets",
		url: env_var_REACT_APP_API_URL ?? "",
		defaultIsLoading: true,
	});

	const dataAdv = useGetGQL({
		gqlquery: `
		query allFleet {
			allFleets (
				aocCode:"${code}",
				${inputModel ? `model:"${inputModel}"` : ""}
			) {
			  items {
				id
				regNo
				fleetType {
				  _id
				  manufacturer
				  model
				  engineType
				}
				aocCode
				entityId
				seatCapacity
			  }
			}
		  }
        `,
		querySign: "allFleets",
		url: env_var_REACT_APP_API_URL_ADV ?? "",
		defaultIsLoading: true,
		isEdges: false,
	});

	function convertFromNew(dataAdv) {
		return {
			...dataAdv,
			data: dataAdv?.data?.items?.map((node) => {
				return {
					...node,
					// id: node?._id, // disabled since api returned 'id' not '_id'
					model: node?.fleetType?.model,
				}
			})?.filter((item) => { return item?.regNo })?.sort((a, b) => {
				return jsArraySortFunctionComparator(a, b, 'regNo')
			})
		}
	}

	function convertFromOld(data) {
		return {
			...data,
			data: data?.data?.map(({ node }) => {
				return node
			})?.filter((item) => { return item?.regNo })?.sort((a, b) => {
				return jsArraySortFunctionComparator(a, b, 'regNo')
			})
		}
	}

	// console.log({
	// 	title: 'useGetAllFleetByCompanyCodeSearchedByModel',
	// 	data,
	// 	dataAdv,
	// 	convertedNew: convertFromNew(dataAdv),
	// 	convertedOld: convertFromOld(data),
	// })

	return convertFromNew(dataAdv)
}

export function useGetWidgetData({ fleetId }) {
	const statusesForWidget = useGetGQL({
		url: env_var_REACT_APP_API_URL ?? "",
		gqlquery: `
        query fleetById {
            fleet(id:"${fleetId}"){
              id
              regNo
              model
              aocCode
              engineType
              latestLocation
              totalActive
              totalLost
              totalRemind
              totalDefect
              totalStatus
              totalExpired
              totalTagError
              totalUnutilisedSeat
            }
          }
        `,
		querySign: "fleet",
		isEdges: false,
	});

	return statusesForWidget;
}

export function getQueryAllFleetFiltered({
	code,
	search,
	filterModels,
	filterRegNos,
	filterEnType,
}) {
	return `
	query allFleet {
		allFleets (
			aocCode: "${code}"
			${search ? `searchBar:"${search}"` : ""}
			${filterModels?.length > 0 ? `multipleModel:"${JSON.stringify(filterModels)
			.replaceAll('"', "")
			.replaceAll("[", "")
			.replaceAll("]", "")}"
					` : ""
		}
			${filterEnType?.length > 0 ? `multipleEngineType:"${JSON.stringify(filterEnType)
			.replaceAll('"', "")
			.replaceAll("[", "")
			.replaceAll("]", "")}"
					` : ""
		}
			${filterRegNos?.length > 0 ? `multipleRegNo:"${JSON.stringify(filterRegNos)
			.replaceAll('"', "")
			.replaceAll("[", "")
			.replaceAll("]", "")}"
					`: ""
		}
		)
		 {
		  edges {
			node {
			  id
			  regNo
			  model
			  engineType
			  totalActive
			  totalLost
			  totalTagError
			  totalExpired
			  totalRemind
			  totalDefect
			  totalStatus
			  latestPic
			  latestOperationDate
			  latestSync
			}
		  }
		}
	  }
	`;
}
