import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import styles from "./AircraftStatus.module.css";
import ArrowLeft from "common/images/icons/elevade/Arrow-Left.svg";
import ArrowLeftActive from "common/images/icons/elevade/Arrow-Left-Active.svg";
import { makeAPIRequestRun } from "common/helpers";
import { LinearProgressBar, setAOCBasedOnPermission } from "../component/Helpers";
import getTimezoneAbbreviation from "../component/TimeZoneAbbreviation";
import { useSelector } from "react-redux";
import Switch from "react-switch";
import { SearchBarAircraftStatus } from "../component/SearchBarInbox";
import ActiveFilters from "../component/ActiveFilters";
import OOOIInfo from "../component/OOOIInfo";
import { elevadeActiveFilter } from "common/constant";
import FlightReport from "./FlightReport";
import FlightMaintenanceSchedule from "../component/FlightMaintenanceSchedule";
import { useMediaQuery } from "@mui/material";
import { isEmpty } from "lodash";
import { Helmet } from "react-helmet";

const AircraftStatus = () => {
  const id = useParams().id;
  const pathname = window.location.pathname;
  const history = useHistory();
  const [aircraftList, setAircraftList] = useState([]);
  const [warningReports, setWarningReports] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [previousLegsWrn, setPreviousLegsWrn] = useState([]);
  const [previouslegs, setPreviousLegs] = useState([]);
  const [previouslegsList, setPreviousLegsList] = useState([]);
  const [rawData, setRawData] = useState([]);
  const [localTime, setLocalTime] = useState(false);
  const [timeZone, setTimeZone] = useState();
  const [timeZoneAfter] = useState("'UTC'");
  const [isSearchInput, setIsSearchInput] = useState([]);
  const [isFaultPriority, setIsFaultPriority] = useState([]);
  const [isRepetitive, setIsRepetitive] = useState([]);
  const { profile: userProfile } = useSelector((state) => state.user);
  const { company: companyInfo } = useSelector((state) => state.company);
  const { companyAOC, userAOC, userPermission } = useSelector((state) => state.userEntity);
  // let AOC = companyInfo?.permission?.elevadeAOC;
  let AOC = companyAOC;
  const [checkAOC, setCheckAOC] = useState(setAOCBasedOnPermission(AOC, userPermission));
  // const UserAOC = userProfile?.permission?.elevadeAOC;
  // const UserAOC = userAOC;
  // if (UserAOC && UserAOC !== null && UserAOC !== undefined && UserAOC?.length > 0) AOC = UserAOC;
  // AOC = checkAOC;
  const [isLegs, setIsLegs] = useState("15");
  const [checked, setChecked] = useState(false);
  const [perPage, setPerPage] = useState(50);
  const [page, setPage] = useState(1);
  const [listRepetitive] = useState(["Repetitive", "No Repetitive"]);
  const [flightReportPriority, setFlightReportPriority] = useState(); //from flight report to get current priority
  const [listFaultPriority, setListFaultPriority] = useState();
  const [OOOIData, setOOOOIData] = useState();
  const [activeOOOI, setActiveOOOI] = useState(false);
  const [lastModifiedDT, setLastModifiedDT] = useState();
  const monthAbv = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const [isAcarsTab, setIsAcarsTab] = useState(true);
  const [isAcmsTab, setIsAcmsTab] = useState(false);
  const [latestFlight, setLatestFlight] = useState(); //selected leg
  const [latestDate, setLatestDate] = useState();
  const [aircraftScheduleTask, setAircraftScheduleTask] = useState([]);
  const [weeklyCheck, setWeeklyCheck] = useState([]);
  const [thirtySixHourCheck, setThirtySixHourCheck] = useState([]);
  const [eCheck, seteCheck] = useState([]);
  const [showSchedule, setShowSchedule] = useState(false);
  const [useCurrentTime, setUseCurrentTime] = useState(false);
  const [callApi, setCallApi] = useState(false);
  const isMobile = useMediaQuery("(max-width:900px)");

  // Redirect user to /fleet if the user does not have access to the current aircraft reg.
  useEffect(() => {
    if (aircraftList?.length > 0) {
      let Operator = aircraftList[0].Operator;
      const found = AOC.includes(Operator);
      if (!found) window.location = "/fleet";
    }
  }, [id, AOC, aircraftList]);

  useEffect(() => {
    async function fetchData() {
      const getAircraftList = makeAPIRequestRun("get", "acarsdata/oooi/" + id);
      getAircraftList.then((res) => {
        setAircraftList(res.rows[0]);
        // setOOOOIData({ ...res.rows[0][0], fromAircraftStatus: true });
        setLastModifiedDT(new Date(res?.last_modified_dt[0].last_modified_dt.value));
      });
      const getAircraftPreviousLegsList = makeAPIRequestRun("get", "acarsdata/previouslegslist/" + id);
      getAircraftPreviousLegsList.then((res) => {
        setPreviousLegsList(res);
        let startTime = whichStartTime(res);
        if (startTime !== undefined) {
          const getPreviousLegsWrn = makeAPIRequestRun("get", "acarsdata/fleetpreviouslegswrn/" + id + "/" + startTime);
          getPreviousLegsWrn.then((res) => {
            setPreviousLegsWrn(res);
            setWarningReports(res);
            let list = [...new Set(res.map((item) => item.priority))];
            const priority = ["High", "Medium", "Low", "OK", "Nuisance", "None", "Undefined", "Null"];
            const sorted = list.sort((a, b) => priority.indexOf(a) - priority.indexOf(b));
            if (sorted.length > 0) {
              let filteredFaultPriotiry = sorted.filter(
                (item) => item !== "Missing" && item !== "null" && item !== null
              );
              // setListFaultPriority(filteredFaultPriotiry);
            }
          });
          const getRawData = makeAPIRequestRun("get", "acarsdata/previousRawData/" + id + "/" + startTime);
          getRawData.then((res) => {
            if (res) {
              setRawData(res);
            }
          });
        }
      });
      const getAircraftPreviousLegs = makeAPIRequestRun("get", "acarsdata/previouslegs/" + id);
      getAircraftPreviousLegs.then((res) => {
        setPreviousLegs(res);
        setOOOOIData({ ...res[0], fromAircraftStatus: true });
      });
      const getAircraftScheduleTask = makeAPIRequestRun("get", "acarsdata/flightschedule/" + id);
      getAircraftScheduleTask.then((res) => {
        setAircraftScheduleTask(res);
      });
      const getMaintenanceSchedule = makeAPIRequestRun("get", "acarsdata/maintenanceSchedule/" + id.split("-").pop());
      getMaintenanceSchedule.then((res) => {
        if (res) {
          seteCheck(res?.eCheck[0]);
          setWeeklyCheck(res?.weeklyCheck[0]);
          setThirtySixHourCheck(res?.thirtySixHourCheck[0]);
        }
      });
    }
    fetchData();
    // eslint-disable-next-line
  }, [id, AOC, perPage, page]);

  useEffect(() => {
    setTimeZone(getTimezoneAbbreviation(new Date()));
  }, [timeZoneAfter]);

  useEffect(() => {
    if (!activeOOOI) {
      // setOOOOIData({ ...aircraftList[0], fromAircraftStatus: true });
    }
    // eslint-disable-next-line
  }, [activeOOOI]);

  useEffect(() => {
    if (!isEmpty(flightReportPriority)) {
      setListFaultPriority(flightReportPriority);
    }
  }, [flightReportPriority]);

  function whichStartTime(data) {
    if (data.filter((e) => e.start_time !== null).slice(-1)[0]?.start_time?.value) {
      return data.filter((e) => e.start_time !== null).slice(-1)[0]?.start_time?.value;
    } else {
      setUseCurrentTime(true);
      return new Date().toISOString().split(".")[0].replace("Z", "");
    }
  }

  // A function to get the query variable in URL
  function getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] === variable) {
        return pair[1];
      }
    }
    return "";
  }

  // eslint-disable-next-line
  const handleChange = (e) => {
    const AircraftRegNo = e.target.value;
    history.replace(`/fleet/aircraft-status/${AircraftRegNo}/failure-messages`);
  };

  function getActivePath(path) {
    if (pathname.includes(path)) {
      return styles.activeTabLegDetails;
    } else {
      return "";
    }
  }

  useEffect(() => {
    // if (listFaultPriority !== undefined) {
    if (flightReportPriority !== undefined) {
      let filteredFaultPriotiry = listFaultPriority.filter(
        (item) => item !== "Missing" && item !== "null" && item !== null
      );
      setListFaultPriority(filteredFaultPriotiry.sort());
    }
    // eslint-disable-next-line
  }, [isLoading]);

  // Function to convert timestamp
  const formatDate = (dateString, type) => {
    // If date is null, return n/a
    if (dateString === null || dateString === undefined || dateString === "") {
      return "n/a";
    }
    // Options for date formatting
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    let returnValue = "";
    let returnDate = "";
    let returnTime = "";
    let returnDatetime = "";
    let returnTimeShort = "";
    if (localTime) {
      if (dateString.includes("Z") === false) dateString = dateString + ".000Z";
      const date = new Date(dateString).toLocaleDateString(undefined, options); // Convert date to local date
      const DateUTC = date.toLocaleString("en-US", options).split(",")[0]; // Split date to get UTC date
      const TimeUTC = date.toLocaleString().split(",")[1]; // Split date to get UTC time
      returnDatetime =
        DateUTC?.split("/")[2] +
        "-" +
        DateUTC?.split("/")[1]?.padStart(2, "0") +
        "-" +
        DateUTC?.split("/")[0]?.padStart(2, "0") +
        " " +
        TimeUTC?.split(":")[0] +
        ":" +
        TimeUTC?.split(":")[1];
      returnDate =
        DateUTC?.split("/")[2] +
        "-" +
        DateUTC?.split("/")[1]?.padStart(2, "0") +
        "-" +
        DateUTC?.split("/")[0]?.padStart(2, "0");
      returnTime = TimeUTC?.split(":")[0] + ":" + TimeUTC?.split(":")[1] + ":" + TimeUTC?.split(":")[2];
      returnTimeShort = TimeUTC?.split(":")[0] + ":" + TimeUTC?.split(":")[1];
    } else {
      let timeOnly = dateString?.split("T")[1];
      returnDatetime = dateString?.split("T")[0] + " " + timeOnly?.split(":")[0] + ":" + timeOnly?.split(":")[1];
      returnTime = timeOnly?.split(":")[0] + ":" + timeOnly?.split(":")[1];
      returnDate = dateString?.split("T")[0];
      returnValue = returnDatetime;
      returnTimeShort = returnTime;
    }
    type === "date"
      ? (returnValue = returnDate)
      : type === "time"
      ? (returnValue = returnTime)
      : type === "timeShort"
      ? (returnValue = returnTimeShort)
      : (returnValue = returnDatetime);
    return returnValue;
  };

  // Function to get the card view contents
  // eslint-disable-next-line
  function getLegsOverview(data, ata_chapter, text) {
    const legsData = data.filter((item) => item.ata_chapter === ata_chapter);
    return (
      <React.Fragment>
        <div style={{ padding: "14px" }}>
          <div style={{ fontFamily: "Gotham-Medium", textDecoration: "underline" }}>Message</div>
          <div style={{ fontFamily: "Gotham-Book", textTransform: "uppercase" }}>{text}</div>
          <div style={{ fontFamily: "Gotham-Medium", textDecoration: "underline", marginTop: "14px" }}>Flight No.</div>
          <div style={{ fontFamily: "Gotham-Book" }}>{legsData[0]?.flight_no}</div>
          <div style={{ fontFamily: "Gotham-Medium", textDecoration: "underline", marginTop: "14px" }}>
            Flight Designator
          </div>
          <div style={{ fontFamily: "Gotham-Book" }}>{"-"}</div>
          <div style={{ fontFamily: "Gotham-Medium", textDecoration: "underline", marginTop: "14px" }}>Call Sign</div>
          <div style={{ fontFamily: "Gotham-Book" }}>-</div>
          <div style={{ fontFamily: "Gotham-Medium", textDecoration: "underline", marginTop: "14px" }}>
            OOOI Message
          </div>
          <div style={{ fontFamily: "Gotham-Medium", width: "90px", float: "left" }}>
            OUT ({legsData[0]?.departure_airport?.replace(" ", "")})
          </div>
          <div style={{ fontFamily: "Gotham-Book" }}>{formatDate(legsData[0]?.out_datetime?.value) || "n/a"}</div>
          <div style={{ fontFamily: "Gotham-Medium", width: "90px", float: "left" }}>OFF</div>
          <div style={{ fontFamily: "Gotham-Book" }}>{formatDate(legsData[0]?.off_datetime?.value) || "n/a"}</div>
          <div style={{ fontFamily: "Gotham-Medium", width: "90px", float: "left" }}>ETA</div>
          <div style={{ fontFamily: "Gotham-Book" }}>{formatDate(legsData[0]?.eta_datetime?.value) || "n/a"}</div>
          <div style={{ fontFamily: "Gotham-Medium", width: "90px", float: "left" }}>ON</div>
          <div style={{ fontFamily: "Gotham-Book" }}>{formatDate(legsData[0]?.on_datetime?.value) || "n/a"}</div>
          <div style={{ fontFamily: "Gotham-Medium", width: "90px", float: "left" }}>
            IN ({legsData[0]?.destination_airport?.replace(" ", "")})
          </div>
          <div style={{ fontFamily: "Gotham-Book" }}>{formatDate(legsData[0]?.in_datetime?.value) || "n/a"}</div>
        </div>
      </React.Fragment>
    );
  }

  // Function to get the card view contents for unavailable data on selected legs
  // eslint-disable-next-line
  function getLegsOverviewUnavailable(data, index) {
    return (
      <React.Fragment>
        <div style={{ padding: "14px" }}>
          <div style={{ fontFamily: "Gotham-Medium", textDecoration: "underline", marginTop: "14px" }}>Flight No.</div>
          <div style={{ fontFamily: "Gotham-Book" }}>{data[index]?.flight_no}</div>
          <div style={{ fontFamily: "Gotham-Medium", textDecoration: "underline", marginTop: "14px" }}>
            OOOI Message
          </div>
          <div style={{ fontFamily: "Gotham-Medium", width: "90px", float: "left" }}>
            OUT ({data[index]?.departure_airport})
          </div>
          <div style={{ fontFamily: "Gotham-Book" }}>{formatDate(data[index]?.out_datetime?.value) || "n/a"}</div>
          <div style={{ fontFamily: "Gotham-Medium", width: "90px", float: "left" }}>OFF</div>
          <div style={{ fontFamily: "Gotham-Book" }}>{formatDate(data[index]?.off_datetime?.value) || "n/a"}</div>
          <div style={{ fontFamily: "Gotham-Medium", width: "90px", float: "left" }}>ETA</div>
          <div style={{ fontFamily: "Gotham-Book" }}>{formatDate(data[index]?.eta_datetime?.value) || "n/a"}</div>
          <div style={{ fontFamily: "Gotham-Medium", width: "90px", float: "left" }}>ON</div>
          <div style={{ fontFamily: "Gotham-Book" }}>{formatDate(data[index]?.on_datetime?.value) || "n/a"}</div>
          <div style={{ fontFamily: "Gotham-Medium", width: "90px", float: "left" }}>
            IN ({data[index]?.destination_airport})
          </div>
          <div style={{ fontFamily: "Gotham-Book" }}>{formatDate(data[index]?.in_datetime?.value) || "n/a"}</div>
        </div>
      </React.Fragment>
    );
  }

  // Check if query params exist in url
  let querySearch = getQueryVariable("search");
  let queryAircraftType = getQueryVariable("at");
  let queryOperator = getQueryVariable("op");
  let queryFaultPriority = getQueryVariable("fp");
  let queryDepartureAirport = getQueryVariable("da");
  let queryArrivalAirport = getQueryVariable("aa");
  let queryAcTab = getQueryVariable("actab");

  let query = "?";
  if (querySearch !== "") query += "search=" + querySearch;
  if (queryAircraftType !== "") query += "&at=" + queryAircraftType;
  if (queryOperator !== "") query += "&op=" + queryOperator;
  if (queryFaultPriority !== "") query += "&fp=" + queryFaultPriority;
  if (queryDepartureAirport !== "") query += "&da=" + queryDepartureAirport;
  if (queryArrivalAirport !== "") query += "&aa=" + queryArrivalAirport;
  if (queryAcTab !== "") query += "&actab=" + queryAcTab;

  const handleChangeTimeZone = (nextChecked) => {
    setChecked(nextChecked);
    if (nextChecked) {
      setLocalTime(true);
    } else {
      setLocalTime(false);
    }
  };

  const Header = () => {
    return (
      <div>
        <div className={`${styles.header}`}>
          <div style={{ paddingTop: "14px" }}>
            <Link to={`/fleet/aircraft-monitoring`} className={`${styles.arrowLeft}`}>
              <img
                src={ArrowLeft}
                alt="arrow left"
                // onMouseOver={(e) => (e.currentTarget.src = ArrowLeftActive)}
                // onMouseOut={(e) => (e.currentTarget.src = ArrowLeft)}
              />
            </Link>
          </div>
          <div className={`${styles.headerText}`}>
            <Link to={`/fleet/aircraft-monitoring`} className={`${styles.link}`}>
              Flight Report
            </Link>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              right: "28px",
              paddingTop: "14px",
              border: "0px solid red",
            }}
          >
            {!isMobile && <span style={{ marginRight: "10px" }}>Time Zone</span>}
            <Switch
              onChange={handleChangeTimeZone}
              checked={checked}
              className="react-switch"
              height={22}
              width={64}
              offColor="#088FD1"
              onColor="#394458"
              handleDiameter={16}
              uncheckedIcon={
                <div
                  style={{
                    color: "#ffffff",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    marginRight: "8px",
                    fontFamily: "Gotham-Book",
                    fontSize: "12px",
                  }}
                >
                  UTC
                </div>
              }
              checkedIcon={
                <div
                  style={{
                    color: "#ffffff",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    marginLeft: "15px",
                    fontFamily: "Gotham-Book",
                    fontSize: "12px",
                  }}
                >
                  {timeZone}
                </div>
              }
            />
          </div>
        </div>
      </div>
      // <>
      //   <Grid container columns={24} className={`${styles.header}`}>
      //     <Grid item xs={1} className={`${styles.arrowLeft}`}>
      //       <Link to="/fleet/aircraft-status">
      //         <img
      //           src={ArrowLeft}
      //           alt="arrow left"
      //           onMouseOver={(e) => (e.currentTarget.src = ArrowLeftActive)}
      //           onMouseOut={(e) => (e.currentTarget.src = ArrowLeft)}
      //         />
      //       </Link>
      //     </Grid>
      //     <Grid item xs={5} className={`${styles.headerText} ${getActivePath("failure-messages")}`}>
      //       <Link to={`/fleet/aircraft-status/${id}/failure-messages`} className={`${styles.link}`}>
      //         Failure & Warnings Messages
      //       </Link>
      //     </Grid>
      //   </Grid>
      // </>
    );
  };

  function addZero(i) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  }

  return (
    <>
    <Helmet>
        <title>Royal Brunei Airlines - ELEVADE - Flight Report - {id}</title>
      </Helmet>
      {isLoading && <LinearProgressBar />}
      <div
        style={{ overflow: "hidden", padding: "0 20px 30px 30px", minHeight: "940px" }}
        id="main"
        className={`${styles.main}`}
      >
        <Header />
        <OOOIInfo id={id} data={OOOIData} localTime={localTime} activeOOOI={activeOOOI} aoc={AOC} />
        {showSchedule ? (
          <div style={{ marginTop: "24px" }}>
            <FlightMaintenanceSchedule
              aircraftScheduleTask={aircraftScheduleTask}
              setShowSchedule={setShowSchedule}
              showSchedule={showSchedule}
              setThirtySixHourCheck={setThirtySixHourCheck}
              thirtySixHourCheck={thirtySixHourCheck}
              setWeeklyCheck={setWeeklyCheck}
              weeklyCheck={weeklyCheck}
              localTime={localTime}
              data={OOOIData}
            />
          </div>
        ) : (
          <></>
        )}
        <FlightReport
          isLegs={isLegs}
          setIsLegs={setIsLegs}
          isSearchInput={isSearchInput}
          setIsSearchInput={setIsSearchInput}
          isFaultPriority={isFaultPriority}
          OOOIData={OOOIData}
          setOOOOIData={setOOOOIData}
          setActiveOOOI={setActiveOOOI}
          aircraftList={aircraftList}
          previouslegsList={previouslegsList}
          previouslegs={previouslegs}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
          listFaultPriority={listFaultPriority}
          warningReports={warningReports}
          previousLegsWrn={previousLegsWrn}
          page={page}
          setPage={setPage}
          perPage={perPage}
          setPerPage={setPerPage}
          localTime={localTime}
          isRepetitive={isRepetitive}
          isAcarsTab={isAcarsTab}
          setIsAcarsTab={setIsAcarsTab}
          isAcmsTab={isAcmsTab}
          setIsAcmsTab={setIsAcmsTab}
          latestFlight={latestFlight}
          setLatestFlight={setLatestFlight}
          latestDate={latestDate}
          setLatestDate={setLatestDate}
          setShowSchedule={setShowSchedule}
          showSchedule={showSchedule}
          useCurrentTime={useCurrentTime}
          rawData={rawData}
          setCallApi={setCallApi}
          callApi={callApi}
          setFlightReportPriority={setFlightReportPriority}
          setListFaultPriority={setListFaultPriority}
          listRepetitive={listRepetitive}
          setIsFaultPriority={setIsFaultPriority}
          setIsRepetitive={setIsRepetitive}
          filter={elevadeActiveFilter.ACARS_REPORTS}
        />
      </div>
      {lastModifiedDT ? (
        <div
          className="lastUpdated"
          style={{
            backgroundColor: "#FFFFFF",
            padding: "8px 0 8px 32px",
            position: "sticky",
            bottom: 0,
            fontStyle: "italic",
            fontSize: "12px",
            color: "#838383",
            zIndex: 9,
          }}
        >
          <span>&#42;Data displayed as of </span>
          {localTime ? (
            <>
              <span>
                {addZero(lastModifiedDT?.getHours()) +
                  ":" +
                  addZero(lastModifiedDT?.getMinutes()) +
                  ":" +
                  addZero(lastModifiedDT?.getSeconds())}{" "}
              </span>
              <span>
                {lastModifiedDT?.toLocaleString("default", { month: "short" }) +
                  " " +
                  addZero(lastModifiedDT?.getDate()) +
                  ", " +
                  lastModifiedDT?.getFullYear()}
              </span>
            </>
          ) : (
            <>
              <span>
                {addZero(lastModifiedDT?.getUTCHours()) +
                  ":" +
                  addZero(lastModifiedDT?.getUTCMinutes()) +
                  ":" +
                  addZero(lastModifiedDT?.getUTCSeconds())}{" "}
              </span>
              <span>
                {monthAbv[lastModifiedDT?.getUTCMonth()] +
                  " " +
                  addZero(lastModifiedDT?.getUTCDate()) +
                  ", " +
                  lastModifiedDT?.getUTCFullYear()}
              </span>
            </>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default AircraftStatus;

/*

        {isAcarsTab && (
          <SearchBarAircraftStatus
            listFaultPriority={listFaultPriority}
            listRepetitive={listRepetitive}
            isSearchInput={isSearchInput}
            setIsSearchInput={setIsSearchInput}
            isFaultPriority={isFaultPriority}
            setIsFaultPriority={setIsFaultPriority}
            isRepetitive={isRepetitive}
            setIsRepetitive={setIsRepetitive}
            isLegs={isLegs}
            setIsLegs={setIsLegs}
            froms={"aircraftStatus"}
          />
        )}
        {(isFaultPriority.length > 0 || isRepetitive.length > 0 || isSearchInput.length > 0) && (
          <div style={{ paddingRight: "45px" }}>
            <ActiveFilters
              isSearchInput={isSearchInput}
              setIsSearchInput={setIsSearchInput}
              isFaultPriority={isFaultPriority}
              setIsFaultPriority={setIsFaultPriority}
              isRepetitive={isRepetitive}
              setIsRepetitive={setIsRepetitive}
              filter={elevadeActiveFilter.ACARS_REPORTS}
            />
          </div>
        )}
*/
