
import React from 'react';
import { getPopupColorBasedOnStatusCode } from "./Popup";
import AirplaneBox from "./AirplaneBox";
import { getBoxDataFromInFleetLocations } from "./AirplaneBox/utils";


/**
 * add box settings
 * cutOffNextRowForEachType
 * cutOffNextColForTypes
 */
export default function AirplaneBoxWithPointer(props) {

	const {
		title,
		code,
		onClick,
		setClosePopup,
		setPopup,
		boxSettings,
		categorizedData,
	} = props ?? {}

	const {
		boxX,
		boxY,
		popupXOffset,
		popupYOffset,
		pointerSettings,
		itemsInARowCap,
		minWidth,
		rowCountThreshold,
	} = boxSettings ?? {}

	const {
		pointerPath,
		pointerPosX,
		pointerPosY,
		pathTranslate,
	} = pointerSettings ?? {}

	const data = getBoxDataFromInFleetLocations(categorizedData?.[code]);

	return (
		<g className="display-box">
			{pointerSettings && (
				<Pointer
					pathTranslate={pathTranslate}
					path={pointerPath}
					pointerPosX={pointerPosX}
					pointerPosY={pointerPosY}
				/>
			)}
			<AirplaneBox
				rowCountThreshold={rowCountThreshold}
				title={title}
				x={boxX}
				y={boxY}
				data={data}
				onClick={onClick}
				minWidth={minWidth ?? 140}
				setClosePopup={setClosePopup}
				biggestArrayLengthCustom={itemsInARowCap}
				setPopup={(rowX, rowY, data) => {
					setPopup({
						status: data?.inventory?.status,
						data: data,
						x: rowX + boxX + popupXOffset,
						y: rowY + boxY + popupYOffset,
						// row: 'row',
						col: data.seatNo,
						color: getPopupColorBasedOnStatusCode(
							data?.inventory?.status?.code
						),
					});
				}}
			/>
		</g>
	);
}

function Pointer({
	path,
	pointerPosX,
	pointerPosY,
	pathTranslate,
}) {

	const { x, y } = pathTranslate ?? { x: 0, y: 0 }

	return (
		<g>
			<path
				transform={`translate(${x},${y})`}
				className="path"
				d={path}
				stroke="#333333"
				stroke-width="0.5"
				stroke-linecap="round"
				stroke-dasharray="2 2"
			/>
			{
				pointerPosX &&
				pointerPosY && (
					<g>
						<circle
							className="pointer-1st"
							cx={`${pointerPosX}`}
							cy={`${pointerPosY}`}
							r="3"
							fill="#74C4E8"
						/>
						<circle
							className="pointer-2nd"
							opacity="0.4"
							cx={`${pointerPosX}`}
							cy={`${pointerPosY}`}
							r="6"
							fill="#74C4E8"
						/>
					</g>
				)
			}
		</g>
	);
}
