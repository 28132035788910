
import React, { useState } from "react";
import RowOfBoxNumbers from "./RowOfBoxNumber";

export const rowHeightSpecing = 30

export default function AirplaneBoxMain(props) {

	const {
		data,
		title,
		x = 0,
		y = 0,
		minWidth = 150,
		onClick,
		setClosePopup,
		setPopup,
		biggestArrayLengthCustom,
		rowCountThreshold,
	} = props;

	let biggestArrayLength = biggestArrayLengthCustom ?? 0;

	if (!biggestArrayLengthCustom) {
		Object.keys(data).map((key, index) => {
			const arrLng = data[key].data.length;
			if (arrLng > biggestArrayLength) {
				biggestArrayLength = arrLng;
			}
		});
	}

	const calculatedWidth = 80 + biggestArrayLength * 24.6;
	const boxWidth = minWidth > calculatedWidth ? minWidth : calculatedWidth;
	const isNoItem = Object.keys(data).length < 1;

	const offsetYMultipliers = Object.keys(data).map((key, index) => {
		const dataLength = data[key]?.data?.length
		const multiplier = Math.ceil(dataLength / biggestArrayLength)
		return multiplier
	})

	const offsetYMultiplier = offsetYMultipliers.length > 0 ? offsetYMultipliers.reduce((accm, val) => accm + (val - 1)) - 1 : 0

	const heightOffset = rowCountThreshold ? rowCountThreshold : Object.keys(data)?.length
	// const boxHeightOri = 30 + 30 * (heightOffset)

	const boxHeight = 30 + 30 * (heightOffset + offsetYMultiplier)

	const boxWithMultiplier = rowCountThreshold ? Math.ceil(Object.keys(data)?.length / rowCountThreshold) : 1

	const colCount = data && Object.keys(data)?.length > 0 ? Math.ceil(Object.keys(data)?.length / rowCountThreshold) : 0

	return (
		<g
			// transform(x,y) x=0 left , y=0 top
			transform={`translate(${x},${y})`}
		>
			<BackgroundColor
				boxHeight={boxHeight}
				boxWidth={boxWidth * boxWithMultiplier}
				isNoItem={isNoItem}
			/>
			{Object.keys(data).map((key, index) => {

				const impactedOfssets = offsetYMultipliers.slice(0, index)
				const indexOffset = impactedOfssets.length > 0 ? impactedOfssets.reduce((accm, val) => accm + (val - 1)) - 1 : 0
				let rowRelativeY = ((index + indexOffset) * rowHeightSpecing)
				const xMultiplier = rowCountThreshold ? Math.floor((index) / rowCountThreshold) : 0

				// to remove rows height of previous columns (created by limiting number or row) and re-added rows right on top of it of the same columns
				if (xMultiplier > 0) {
					const ignoredRows = rowCountThreshold ? Math.floor((index) / rowCountThreshold) * rowCountThreshold : 0
					const rowsAtTop = offsetYMultipliers.slice(ignoredRows, index)
					const accmRowsAtTop = rowsAtTop ? rowsAtTop.reduce((accm, val) => val + accm, 0) : 0
					rowRelativeY = rowRelativeY - ((impactedOfssets.reduce((accm, val) => val + accm) ?? 0) * rowHeightSpecing) + (accmRowsAtTop * rowHeightSpecing)
				}


				return (
					<g transform={xMultiplier > 0 ? `translate(${boxWidth * xMultiplier},0)` : undefined} >
						<RowOfBoxNumbers
							y={rowRelativeY}
							text={key}
							Icon={data[key].Icon}
							numbers={data[key].data}
							onClick={onClick}
							setClosePopup={setClosePopup}
							setPopup={(boxX, inFleetLocation, yOffset) => {
								// yOffset : offset for y positon created by itemsInARowCap
								if (setPopup) {
									setPopup(
										boxX + (xMultiplier ? boxWidth * xMultiplier : 0),
										rowRelativeY + yOffset,
										inFleetLocation
									);
								}
							}}
							boxWidth={boxWidth}
							biggestArrayLength={biggestArrayLength}
						/>
					</g>
				);
			})}
			<NoItemText isNoItem={isNoItem} boxWidth={boxWidth} />
			<TitleText title={title} />
			{[...Array(colCount < 2 ? 0 : colCount + 1)].map((e, i) => {
				return (
					<RightLine x={boxWidth * (i + 1)} height={boxHeight} />
				)
			})}

		</g>
	);
}

function RightLine({
	x = 0,
	height,
}) {

	return (
		<path
			// line at the top of each row
			id="lineCD"
			// -7:x 7:y 5:height
			d={`M ${x - 7} -7 l 0 ${height - rowHeightSpecing}`}
			stroke="#E8E8E8"
		/>
	)
}

function BackgroundColor({
	boxWidth,
	isNoItem,
	boxHeight,
}) {



	return (
		<rect
			x="-7"
			y="-37"
			width={boxWidth}
			height={
				isNoItem ? "60" : `${boxHeight}`
			}
			rx="8"
			stroke="#E8E8E8"
			fill="white"
		/>
	)
}

function NoItemText({
	isNoItem,
	boxWidth,
}) {
	return (
		<g>
			{isNoItem && (
				<g>
					<text
						x={5}
						y="10"
						fontSize={"10px"}
						fill="#838383"
						// fontWeight='600'
						fontStyle="italic"
					>
						No equipment assigned
					</text>
					<path
						id="lineAB"
						d={`M -7  -7 l ${boxWidth} 0`}
						stroke="#E8E8E8"
					/>
				</g>
			)}
		</g>
	)
}

function TitleText({ title }) {
	return (
		<text
			x={5}
			y="-17"
			fontSize={"12px"}
			fill="black"
			fontWeight="600"
		>
			{title}
		</text>
	)
}