
import React from 'react';
import { Box, Skeleton, Typography } from "@mui/material";
import { SquareDefectIcon, SquareErrorIcon, SquareExpiredIcon, SquareMissingIcon, SquareRemindIcon } from "../../icons/statusIcons";
import { gothamBold } from "../../../../constants/fonts";
// import { gothamBold } from "../../../constants/fonts";

/**
 * @typedef {Object} WidgetsProps
 * @property {boolean} isLoading
 * @property {string | number} missing
 * @property {string | number} tagerror
 * @property {string | number} expired
 * @property {string | number} remind
 */

/**
 * Widgets
 * @param {WidgetsProps} props 
 * @returns 
 */
export default function Widgets(props) {
	const {
		isLoading,
		missing,
		tagerror,
		expired,
		remind,
	} = props
	return (
		<Box sx={{
			display: "flex",
			flexDirection: 'row',
			flexWrap: 'wrap',
			justifyContent: 'space-evenly',
			width: '98%',
		}}>
			<CustomBoxItem>
				<MissingWidget value={missing} isLoading={isLoading} />
			</CustomBoxItem>
			<CustomBoxItem>
				<ErrorWidget value={tagerror} isLoading={isLoading} />
			</CustomBoxItem>
			<CustomBoxItem>
				<ExpiredWidget value={expired} isLoading={isLoading} />
			</CustomBoxItem>
			<CustomBoxItem>
				<NearExpiryWidget value={remind} isLoading={isLoading} />
			</CustomBoxItem>
			{/* <CustomBoxItem>
            <DefectsWidget value={defects} isLoading={isLoading} />
        </CustomBoxItem> */}
		</Box>
	);
}

function MissingWidget({ value, isLoading }) {
	return <WidgetContainer icon={<SquareMissingIcon />} isLoading={isLoading} label={"Missing"} value={value} />;
}

function ErrorWidget({ value, isLoading }) {
	return <WidgetContainer icon={<SquareErrorIcon />} isLoading={isLoading} label={"Error"} value={value} />;
}

function ExpiredWidget({ value, isLoading }) {
	return <WidgetContainer icon={<SquareExpiredIcon />} isLoading={isLoading} label={"Expired"} value={value} />;
}

function NearExpiryWidget({ value, isLoading }) {
	return <WidgetContainer icon={<SquareRemindIcon />} isLoading={isLoading} label={"Near Expiry"} value={value} />;
}

// function DefectsWidget({ value, isLoading }) {
//     return <WidgetContainer icon={<SquareDefectIcon />} isLoading={isLoading} label={"Defects"} value={value}/>;
// }

function WidgetContainer({ icon, isLoading, value, label }) {
	return (
		<Box sx={{
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'flex-start',
			gap: '1em',
		}}>
			<Box sx={{
				paddingTop: '0.3em'
			}}>
				{icon}
			</Box>
			<Box>
				<Typography fontFamily={gothamBold} fontWeight='bold' fontSize='24px' sx={{ textAlign: "left" }}>
					{isLoading && <Skeleton variant="text" animation="wave" />}
					{!isLoading && ((value?.toLocaleString && value?.toLocaleString()) ?? 0)}
				</Typography>
				<Typography color='#72767E' sx={{ textAlign: "left" }} fontSize='14px'>
					{label}
				</Typography>
			</Box>
		</Box>
	);
}

function CustomBoxItem({ children }) {
	return (<Box sx={{
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'left',
		minWidth: "150px",
	}}>
		{children}
	</Box>);
}
