import React, { useState } from "react";
import { Grid, Avatar, Modal, Box } from "@mui/material";
import styles from "./DDML.module.css";
import { addingDate, calculateBalanceDay, formatDateDasr, formatTimeDasr } from "pages/elevade/component/Helpers";
import DefermentExpirySummaryStructure from "./DefermentExpirySummaryStructure";
import Preview from "../component/Preview";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const Summary = ({
  dataForm,
  calculateCalendarExpiryDate,
  getSummary,
  calculateCalendarBalance,
  stringAvatar,
  splitToChucks,
  openPreview,
  setOpenPreview,
  handleClose,
  summaryPreviewUrl,
  calculateExpiryDate,
  ...props
}) => {
  let data = props.initialData;
  if (dataForm.ddml) data = dataForm.ddml;
  if (dataForm.length === 1 && dataForm[0].ddml) data = dataForm[0].ddml;
  else if (dataForm.length === 1 && !dataForm[0].ddml) data = dataForm[0];
  if (data?.ddml) data = data.ddml;
  const location = useLocation();
  const isTaskAssignmentPage = location.pathname.includes("/task-assignment");
  const isTaskAssignmentReportPage = location.pathname.includes("/assignment-report");
  const [fileName, setFileName] = useState();

  // Function to get the active assignee from data object
  function getAssignee(col) {
    let ddmlId = data?._id;
    if (Array.isArray(dataForm)) {
      if (dataForm.length > 1) {
        let assignmentData = dataForm.filter((e) => e.formId === ddmlId);
        return assignmentData[0]?.assignee?.name;
      } else return dataForm[0]?.assignee?.name;
    } else {
      let assignmentData = dataForm?.assignment[dataForm.assignment.length - 1];
      return assignmentData?.assignee?.name;
    }
  }

  const AssigneeName = getAssignee("name");

  function calcBalanceDay() {
    let startDate = data.flightDaysStartDate;
    let interval = data.flightDaysInterval || 0;
    let expiry = addingDate(startDate, interval);
    let today = setToday();
    return calculateBalanceDay(today?.split("T")[0], expiry?.split("T")[0]);
  }

  function calcExpiryDate() {
    let startDate = data.flightDaysStartDate;
    let interval = data.flightDaysInterval || 0;
    return formatDateDasr(addingDate(startDate, interval));
  }

  function setToday() {
    // Create a new Date object
    const currentDate = new Date();

    // Get the individual components of the date
    const year = currentDate.getFullYear();
    // JavaScript months are 0-indexed, so we add 1 to get the correct month
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const day = currentDate.getDate().toString().padStart(2, "0");

    // Create a string in the desired format
    const formattedDate = `${year}-${month}-${day}T00:00:00`;
    return formattedDate;
  }

  function displayDefermentExpiry() {
    if (data.formType === "AMD" && data.amdType === "Other") return true;
    else if (data.formType === "Hydraulic Sampling") return true;
    else if (data.formType === "Fuel Sampling") return true;
    else if (data.formType === "STR/FC/FH") return true;
    else return false;
  }

  return (
    <div
      style={{
        overflow: "auto",
        padding: "0px 24px 8px 24px",
        zIndex: "3",
      }}
    >
      <Grid container columns={4}>
        <Grid item xs={1}>
          <div className={styles.titleSummary}>Aircraft Registration</div>
          <div>{data.aircraftReg}</div>
        </Grid>
        <Grid item xs={1}>
          <div className={styles.titleSummary}>Station</div>
          <div>{data.location}</div>
        </Grid>
        <Grid item xs={1}>
          <div className={styles.titleSummary}>DDML No.</div>
          <div style={{ textTransform: "uppercase" }}>{data.ddmlNo}</div>
        </Grid>
        <Grid item xs={1}>
          <div className={styles.titleSummary}>MEL Ref</div>
          <div style={{ textTransform: "uppercase" }}>
            {data.formType === "MEL CAT" && data.melRef !== "" && data.melRef !== null
              ? data.melRef + " (" + data.melCatType + ")"
              : data.melRef !== "" && data.melRef !== null
              ? data.melRef
              : "-"}
          </div>
        </Grid>
        <Grid item xs={1}>
          <div className={styles.titleSummary}>Aircraft Status</div>
          <div>{data.aircraftStatus || "-"}</div>
        </Grid>
        {(data.formType === "MEL CAT" || data.formType === "AMD" || data.formType === "STR/FC/FH") && (
          <>
            <Grid item xs={1}>
              <div className={styles.titleSummary}>Aircraft Status</div>
              <div>{data.aircraftStatus || "-"}</div>
            </Grid>
            <Grid item xs={1}>
              <div className={styles.titleSummary}>Notice to Crew (NTC)</div>
              <div>{data.noticeToCrew ? "Yes" : "No"}</div>
            </Grid>
          </>
        )}
        <Grid item xs={1}>
          <div className={styles.titleSummary}>Raised Date</div>
          <div>{formatDateDasr(data.raisedDate)}</div>
        </Grid>
        {(data.formType === "MEL CAT" || data.formType === "AMD" || isTaskAssignmentPage) && (
          <Grid item xs={1}>
            <div className={styles.titleSummary}>Expiry Date</div>
            {/* <div>{formatDateDasr(data.expiryDate)}</div> */}
            <div>{data.flightDays ? calcExpiryDate() : formatDateDasr(data.expiryDate)}</div>
          </Grid>
        )}
        <Grid item xs={1}>
          <div className={styles.titleSummary}>Aircraft Inspection</div>
          <div>{data.reInspection ? "Yes" : "No"}</div>
        </Grid>
        <Grid item xs={4}>
          <div className={styles.titleSummary}>Defect Description</div>
          <div className={styles.formTextAreaDisable}>{data.defectDesc || "-"}</div>
        </Grid>
        <Grid item xs={4}>
          <div className={styles.titleSummary}>Rectification Status</div>
          <div className={styles.formTextAreaDisable}>
            {data?.formStatus === "closed" || data?.formStatus === "approved"
              ? data.ddmlStatus.rectificationCategory
              : getSummary(data.id, "Rectification Update", "") || data.actionBy145 || "-"}
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className={styles.titleSummary}>Remarks (MOC Highlight)</div>
          <div className={styles.formTextAreaDisable}>{data.mocHighlight || "-"}</div>
        </Grid>
        <Grid item xs={4}>
          <div className={styles.titleSummary}>Material Summary</div>
          <div className={styles.formTextAreaDisable}>{getSummary(data.id, "Material/Tools Update", "") || "-"}</div>
        </Grid>
        <Grid item xs={4}>
          <div className={styles.titleSummary}>Planning/Tech Service Summary</div>
          <div className={styles.formTextAreaDisable}>{getSummary(data.id, "PPC", "") || "-"}</div>
        </Grid>
        {data.formType === "MEL CAT" && data.melCatType === "CAT A" && (
          <Grid item xs={4} sx={{ padding: "0px 0px 0px 0px", fontSize: "14px" }}>
            <div className={styles.titleSummary}>Deferment Expiry</div>
            <div className={`${styles.remainingDiv} ${styles.remainingDivSelected}`}>
              <Grid container columns={2}>
                {/* {data.flightHours && (
                  <>
                    <Grid item xs={2} className={`${styles.title}`} sx={{ padding: "8px 0px" }}>
                      Flight Hours
                    </Grid>
                    <Grid item xs={2}>
                      Interval
                    </Grid>
                    <Grid item xs={2} sx={{ fontSize: "16px" }}>
                      {data.flightHoursInterval || "-"}
                    </Grid>
                  </>
                )}
                {data.flightCycle && (
                  <>
                    <Grid item xs={2} className={`${styles.title}`} sx={{ padding: "8px 0px" }}>
                      Flight Cycle
                    </Grid>
                    <Grid item xs={2}>
                      Interval
                    </Grid>
                    <Grid item xs={2} sx={{ fontSize: "16px" }}>
                      {data.flightCycleInterval || "-"}
                    </Grid>
                  </>
                )}*/}
                {data.flightDays && (
                  <>
                    <Grid item xs={2} className={`${styles.title}`} sx={{ padding: "8px 0px" }}>
                      Flight Days
                    </Grid>
                    <Grid item xs={1}>
                      Interval
                    </Grid>
                    <Grid item xs={1}>
                      Start Date
                    </Grid>
                    <Grid item xs={1} sx={{ fontSize: "16px" }}>
                      {data.flightDaysInterval || "-"}
                    </Grid>
                    <Grid item xs={1} sx={{ fontSize: "16px" }}>
                      {formatDateDasr(data.flightDaysStartDate)}
                    </Grid>
                    <Grid item xs={1} sx={{ paddingTop: "10px" }}>
                      Balance
                    </Grid>
                    <Grid item xs={1} sx={{ paddingTop: "10px" }}>
                      Expiry Date
                    </Grid>
                    <Grid item xs={1} sx={{ fontSize: "16px" }}>
                      {calcBalanceDay()}
                    </Grid>
                    <Grid item xs={1} sx={{ fontSize: "16px" }}>
                      {calcExpiryDate()}
                    </Grid>
                  </>
                )}
                {data.calendarDays && (
                  <>
                    <Grid item xs={2} className={`${styles.title}`} sx={{ padding: "8px 0px" }}>
                      Calendar Days
                    </Grid>
                    <Grid item xs={1}>
                      Interval
                    </Grid>
                    <Grid item xs={1}>
                      Balance
                    </Grid>
                    <Grid item xs={1} sx={{ fontSize: "16px" }}>
                      {data.calendarDaysInterval?.toLocaleString() || "-"}
                    </Grid>
                    <Grid item xs={1} sx={{ fontSize: "16px" }}>
                      {calculateCalendarBalance(data.calendarDaysStartDate, data.calendarDaysInterval)}
                    </Grid>
                  </>
                )}
                {!data.flightHours && !data.flightCycle && !data.flightDays && !data.calendarDays && (
                  <Grid item xs={2}>
                    -
                  </Grid>
                )}
              </Grid>
            </div>
          </Grid>
        )}
        {(data.formType === "MEL CAT" || data.formType === "AMD" || data.formType === "STR/FC/FH") &&
          data.limitations && (
            <Grid item xs={4}>
              <div className={styles.titleSummary}>Limitation Description</div>
              <div className={styles.formTextAreaDisable}>{data.limitationsDesc || "-"}</div>
            </Grid>
          )}
        {displayDefermentExpiry() && (
          <Grid item xs={4} sx={{ padding: "24px 0px 0px 0px", fontSize: "14px" }}>
            <div className={styles.titleSummary}>Deferment Expiry</div>
            <div className={`${styles.remainingDiv} ${styles.remainingDivSelected}`}>
              <Grid container columns={3}>
                {(data.formType === "AMD" ||
                  data.formType === "Hydraulic Sampling" ||
                  data.formType === "Fuel Sampling") && (
                  <>
                    <Grid item xs={3} className={`${styles.title}`} sx={{ padding: "8px 0px" }}>
                      Calendar Days
                    </Grid>
                    <Grid item xs={1}>
                      Interval
                    </Grid>
                    <Grid item xs={1}>
                      Expiry
                    </Grid>
                    <Grid item xs={1}>
                      Balance
                    </Grid>
                    <Grid item xs={1}>
                      {data.calendarDaysInterval?.toLocaleString() || "-"}
                    </Grid>
                    <Grid item xs={1}>
                      {formatDateDasr(
                        addingDate(
                          data.calendarDaysStartDate?.includes("T")
                            ? data.calendarDaysStartDate?.split("T")[0]
                            : data.calendarDaysStartDate,
                          data.calendarDaysInterval
                        )
                      )}
                    </Grid>
                    <Grid item xs={1}>
                      {calculateCalendarBalance(data.calendarDaysStartDate, data.calendarDaysInterval)}
                    </Grid>
                  </>
                )}
                {data.formType === "STR/FC/FH" && (
                  <>
                    <DefermentExpirySummaryStructure
                      dataForm={data}
                      calculateCalendarExpiryDate={calculateCalendarExpiryDate}
                      calculateCalendarBalance={calculateCalendarBalance}
                    />
                  </>
                )}
              </Grid>
            </div>
          </Grid>
        )}
        <Grid item xs={4}>
          <div className={styles.titleSummary}>Assign To</div>
          {/* { ( */}
          <>
            <div style={{ float: "left", marginRight: "10px" }}>
              {data.assignedTo !== "" && data.assignedTo !== null && data.assignedTo !== undefined ? (
                <Avatar {...stringAvatar(data.assignedTo)} />
              ) : (
                "-"
              )}
            </div>
            <div style={{ paddingTop: "8px" }}>{data.assignedTo}</div>
          </>
          {/* )} */}
          {/* {isTaskAssignmentPage && <div style={{ paddingTop: "8px" }}>{data.assignee?.name}</div>} */}
        </Grid>

        <Grid item xs={4}>
          {(isTaskAssignmentPage || isTaskAssignmentReportPage) && (
            <>
              <div className={styles.titleSummary}>Assigned LAE&rsquo;s</div>
              <div style={{ float: "left", marginRight: "10px" }}>
                {AssigneeName !== undefined && AssigneeName !== null ? (
                  <Avatar {...stringAvatar(AssigneeName.toUpperCase())} />
                ) : (
                  "-"
                )}
              </div>
              <div style={{ paddingTop: "8px" }}>{AssigneeName}</div>
            </>
          )}
        </Grid>
        <Grid item xs={4}>
          {/* {isTaskAssignmentPage && (
            <> */}
          <div className={styles.titleSummary}>JML Uploaded</div>
          <Grid container columns={4}>
            {data?.attachment?.[0]?.fileName !== null &&
              data?.attachment
                ?.filter((e) => e.fileName !== "")
                ?.map((e) => {
                  return (
                    <>
                      <Grid item xs={4} sx={{ paddingBottom: "4px", display: "flex" }}>
                        <div
                          style={{
                            width: "110px",
                            height: "65px",
                            overflow: "hidden",
                            borderRadius: "4px",
                            marginRight: "8px",
                          }}
                        >
                          {e.fileType !== "application/pdf" ? (
                            <img
                              src={e.fileLink || "-"}
                              alt={`Preview`}
                              style={{
                                width: "110px",
                                height: "65px",
                                objectFit: "cover",
                              }}
                            />
                          ) : (
                            "PDF"
                          )}
                        </div>
                        <div style={{ color: "#3F79F6", marginLeft: "8px" }} onClick={() => setFileName(e?.fileName)}>
                          {splitToChucks(e) || "-"}
                        </div>
                      </Grid>
                    </>
                  );
                })}
            {(data?.formStatus === "closed" || data?.formStatus === "approved") &&
              data?.ddmlStatus?.attachment.map((e) => {
                return (
                  <>
                    <Grid item xs={4} sx={{ paddingBottom: "4px", display: "flex" }}>
                      <div
                        style={{
                          width: "110px",
                          height: "65px",
                          overflow: "hidden",
                          borderRadius: "4px",
                          marginRight: "8px",
                        }}
                      >
                        {e.fileType !== "application/pdf" ? (
                          <img
                            src={e.fileLink || "-"}
                            alt={`Preview`}
                            style={{
                              width: "110px",
                              height: "65px",
                              objectFit: "cover",
                            }}
                          />
                        ) : (
                          "PDF"
                        )}
                      </div>
                      <div style={{ color: "#3F79F6" }} onClick={() => setFileName(e?.fileName)}>
                        {splitToChucks(e) || "-"}
                      </div>
                    </Grid>
                  </>
                );
              })}
          </Grid>
          {/* </>
          )} */}
        </Grid>
        <Grid item xs={2}>
          <div className={styles.titleSummary}>
            Created {formatDateDasr(data.createdAt)} at {formatTimeDasr(data.createdAt)}
            <br />
            {data.formStatus !== "closed" && data.formStatus !== "approved"
              ? "Latest updated on " +
                formatDateDasr(data.updatedAt ?? data.createdAt) +
                " at " +
                formatTimeDasr(data.updatedAt ?? data.createdAt)
              : "Closed by " + data.closedBy}
          </div>
        </Grid>
        <Grid item xs={2} sx={{ paddingLeft: "10px" }}>
          <div className={styles.titleSummary}>
            {data.updatedAt !== null && data.formStatus !== "closed" && data.formStatus !== "approved"
              ? `Updated ${formatDateDasr(data.updatedAt)} at ${formatTimeDasr(data.updatedAt)}`
              : data.closedAt !== null && (data.formStatus === "closed" || data.formStatus === "approved")
              ? `Closed ${formatDateDasr(data.closedAt)} at ${formatTimeDasr(data.closedAt)}`
              : ""}
          </div>
        </Grid>
      </Grid>
      {/* <Modal
        open={openPreview}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <img
            src={summaryPreviewUrl}
            alt={`Preview`}
            style={{
              width: "100%",
              maxWidth: "1000px",
              height: "100%",
              maxHeight: "700px",
            }}
          />
        </Box>
      </Modal> */}
      <Preview open={openPreview} setOpen={setOpenPreview} fileLink={summaryPreviewUrl} fileName={fileName} />
    </div>
  );
};

export default Summary;
