import React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
// import { useForm } from "react-hook-form";

import { makeAPIRequestRun, showNotificationElevade } from "common/helpers";
import { setAOCBasedOnPermission } from "../component/Helpers";
import { makeAPIRequestMDB } from "common/helpers/makeAPIRequestMDB";
import { dateFnsFormats } from "common/constant";
import MUIPagination from "../component/Pagination";
// import NewEntry from "./component/NewEntryButton";
// import Export from "./component/ExportButton";
import EditDrawer from "./component/EditDrawer";
// import SearchBar from "./component/SearchBar";
// import ActiveFilters from "./component/ActiveFilters";
import AirplaneLoader from "../component/AirplaneLoader";
import CorrelatedDefect from "common/images/icons/elevade/correlatedDefect.svg";
import { AOCList } from "common/constant";

import styles from "./repetitiveDefect.module.css";

export const RepetitiveDefectv2 = () => {
  // const { register, setValue, formState, watch, handleSubmit, reset, setError, getValues } = useForm({ mode: "all" });
  // const { profile: userProfile } = useSelector((state) => state.user);
  const [activeTab, setActiveTab] = useState(1);
  const [repetitiveDefect, setRepetitiveDefect] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const [sort, setSort] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [callApi, setCallApi] = useState(false);
  const [perPage, setPerPage] = useState(50);
  const [totalPages, setTotalPage] = useState(0);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerDetail, setDrawerDetail] = useState();
  const [open, setOpen] = useState(false);
  const [openDefectData, setOpenDefectData] = useState([]);
  const [monitorDefectData, setMonitorDefectData] = useState([]);
  const [closeDefectData, setCloseDefectData] = useState([]);
  const [isSearch, setIsSearch] = useState("");
  const [openCount, setOpenCount] = useState(0);
  const [monitorCount, setMonitorCount] = useState(0);
  const [closedCount, setClosedCount] = useState(0);
  const [isFilters, setIsFilters] = useState({ search: [], operator: [] });
  const [dataLocation, setDataLocation] = useState();
  const [data, setData] = useState([]);
  // const [repetitiveDefectDASR, setRepetitiveDefectDASR] = useState();
  // const [filteredData, setFilteredData] = useState();
  const [newEntry, setNewEntry] = useState(false);
  const { userAOC, userName, companyAOC, userPermission } = useSelector((state) => state.userEntity);
  let offsetValue = page > 0 ? limit * (page - 1) : 0;
  let sliceEnd = parseInt(limit) + offsetValue;
  // const [operator, setOperator] = useState(
  //   Array.isArray(userAOC)
  //     ? userAOC.filter((useraoc) => AOCList.some((AOC) => AOC.code === useraoc))[0]
  //     : AOCList.includes(userAOC)
  //     ? userAOC
  //     : ""
  // );

  let AOC = companyAOC;
  AOC = setAOCBasedOnPermission(AOC, userPermission);
  const [operator, setOperator] = useState(
    Array.isArray(AOC)
    ? AOC.filter((useraoc) => AOCList.some((AOC) => AOC.code === useraoc))[0]
    : AOCList.includes(AOC)
    ? AOC
    : ""
    );
  const [dataDDML, setDataDDML] = useState();
  const [triggerApi, setTriggerApi] = useState(true);

  const opts = [
    {
      code: "1",
      name: "Open",
      value: "Open",
    },
    {
      code: "2",
      name: "Monitoring",
      value: "Monitoring",
    },
    {
      code: "3",
      name: "Close",
      value: "Close",
    },
  ];
  let sliceStart = page > 0 ? perPage * (page - 1) : 0;

  const useStyles = makeStyles(() => ({
    root: {
      width: "64px",
      maxWidth: "64px",
      maxHeight: "22px",
      padding: "0px",
    },
    switchBase: {
      color: "#818181",
      padding: "1px",
      "&$checked": {
        "& + $track": {
          backgroundColor: "#394458 !important",
        },
      },
    },
    thumb: {
      color: "white",
      width: "18px",
      height: "18px",
      margin: "1px",
    },
    ul: {
      "& .Mui-selected": {
        color: "#fff",
        backgroundColor: "#088FD1",
        border: "1px solid #088FD1",
        "&:hover": {
          backgroundColor: "#088FD1",
        },
      },
    },
    messageTootltip: {
      background: "#fffac3 0% 0% no-repeat padding-box",
      color: "#333333",
      boxShadow: "0px 3px 6px #00000040",
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    // setFilteredData(data);
  }, [data]);

  useEffect(() => {
    fetchReport();
    // FetchDASRRDD()
    fetchRepetitiveDefectData(operator);
  },[triggerApi])

  useEffect(() => {
    let data_ = data;

    if (isFilters.operator?.length > 0) {
      data_ = data_?.filter((e) => isFilters.operator?.includes(e.aoc));
    }

    // setFilteredData(data_);
    splitStatus(data_);
    // eslint-disable-next-line
  }, [isSearch, data, isFilters]);

  useEffect(() => {
    (async () => {
      try {
        await Promise.all([fetchRepetitiveDefectData(operator)]);
        // setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        console.log("Error occurred when fetching data");
      }
    })();
    // setIsLoading(false);
    // eslint-disable-next-line
  }, [setIsLoading, isLoading]);

  useEffect(() => {
    FetchDataLocation();
    fetchRepetitiveDefectData(operator);
    // FetchDASRRDD();
    // eslint-disable-next-line
  }, [callApi, operator]);

  async function fetchRepetitiveDefectData(operator) {
    const queryParams = new URLSearchParams({
      search: "",
      ata: "",
      operator: operator,
      sort: sort ?? "",
      limit: limit,
      offset: offsetValue,
    });
    const getRepetitiveDefect = makeAPIRequestRun("get", `acarsdata/repetitivedefect/get/?${queryParams}`);
    getRepetitiveDefect
      .then((response) => {
        if (response.error) {
          setIsLoading(false);
          setTotalRows(0);
          showNotificationElevade("danger", "Error", ["Something went wrong, unable to fetch alerts"]);
        } else if (!response.error) {
          setRepetitiveDefect(response);
          setData(response);
          splitStatus(response);
          setTotalPage(Math.floor(response.length / perPage) + 1);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("err", err);
      });
  }

  async function FetchDataLocation() {
    var currentDate = new Date();
    var options = { month: "long", day: "2-digit", year: "numeric" };
    var formattedDate = currentDate.toLocaleDateString("en-US", options);
    currentDate = new Date(formattedDate);
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const isToday = `${year}-${month}-${day}`;
    if (isToday !== "NaN-NaN-NaN") {
      const fetch = makeAPIRequestMDB(`get`, `api/aircraft/daily-status?date=${isToday}&aoc=${operator}`);
      fetch
        .then((response) => {
          setDataLocation(response.data.sort((a, b) => a.aircraftReg?.localeCompare(b.aircraftReg)));
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  }

  async function fetchReport() {
    const fetch = makeAPIRequestMDB(`get`, `api/aircraft/updated-status?aoc=${operator}&form_status=open`);
    fetch
      .then((response) => {
        setDataDDML(response.data);
        setTriggerApi(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  // async function FetchDASRRDD() {
  //   const fetchRDD = makeAPIRequestMDB(`get`, `api/assignmentRDD`);
  //   fetchRDD.then((response) => {
  //     setRepetitiveDefectDASR(response?.data);
  //   }).catch((error) => {
  //     console.log("error", error)
  //   })
  // }

  function getAtaChapter(ata, report_type) {
    if (ata && report_type === "FAULT")
      return ata.substring(0, 2) + "-" + ata.substring(2, 4) + "-" + ata.substring(4, 6);
    else if (ata && report_type === "WARNING") return ata.substring(0, 2) + "-" + ata.substring(2, 4);
    else if (ata) return ata.substring(0, 2) + "-" + ata.substring(2, 4) + "-" + ata.substring(4, 6);
  }

  // function getCorrelatedItem(id) {
  //   let items = repetitiveDefect.filter((obj) => obj.acreg_msgid === id && obj.correlated === 1);
  //   return items;
  // }

  function toggleCorrelated(e, id) {
    let elementLength = document.getElementsByClassName(id).length;
    for (const element of document.getElementsByClassName(id)) {
      if (element.style.display === "none") {
        element.style.display = "block";
        element.style.opacity = "1";
        if (!--elementLength) {
          element.style.borderBottom = "1px solid #e8e8e8";
        } else {
          element.style.borderBottom = "hidden";
        }
      } else {
        element.style.display = "none";
      }
    }
  }

  function handleActiveTab(tab) {
    setActiveTab(tab);
    setPage(1);
    setLimit(limit);
  }

  function splitStatus(data) {
    if (repetitiveDefect) {
      let open = data.filter((obj) => obj.status === "Open");
      let openCount = data.filter((obj) => obj.status === "Open" && obj.correlated === 0);
      setOpenDefectData(open);
      setOpenCount(openCount.length);

      let monitor = data.filter((obj) => obj.status === "Monitoring");
      let monitorCount = data.filter((obj) => obj.status === "Monitoring" && obj.correlated === 0);
      setMonitorDefectData(monitor);
      setMonitorCount(monitorCount.length);

      let close = data.filter((obj) => obj.status === "Close");
      let closeCount = data.filter((obj) => obj.status === "Close" && obj.correlated === 0);
      setCloseDefectData(close);
      setClosedCount(closeCount.length);
    }
  }

  function getAircraftLocation(acreg) {
    const location = dataLocation?.find((elem) => elem?.aircraftReg === acreg);
    return location?.status?.hub || "-";
  }

  const RepetitiveDefectHeader = () => {
    return (
      <Grid container>
        <Grid item xs={6}>
          <span className="large-font gotham-medium">Repetitive Defect Database</span>
        </Grid>
        <Grid item xs={6} className={styles.gridJustifyEnd}>
          {/* <DateToggle /> */}
          {/* <Export />
          <NewEntry
            open={open}
            setOpen={setOpen}
            newEntry={newEntry}
            setNewEntry={setNewEntry}
            isDrawerOpen={isDrawerOpen}
            setIsDrawerOpen={setIsDrawerOpen}
          /> */}
        </Grid>
      </Grid>
    );
  };

  const ViewBox = () => {
    return (
      <div
        style={{
          marginTop: "33px",
          paddingBottom: "40px",
        }}
      >
        <Grid container columns={12} sx={{ marginBottom: 2 }}>
          <Grid item xs={8}>
            <div
              className={`${
                activeTab === 1
                  ? styles.activeDefectTab
                  : styles.inactiveDefectTab
              }`}
            >
              <p onClick={(e) => handleActiveTab(1)}>
                Open
                {openCount > 0 ? <span className={`${styles.countTooltip}`}>{openCount}</span> : <></>}
              </p>
            </div>
            <div
              className={`${
                activeTab === 2
                  ? styles.activeDefectTab
                  : styles.inactiveDefectTab
              }`}
            >
              <p onClick={(e) => handleActiveTab(2)}>
                Monitor
                {monitorCount > 0 ? <span className={`${styles.countTooltip}`}>{monitorCount}</span> : <></>}
              </p>
            </div>
            <div
              className={`${
                activeTab === 3
                  ? styles.activeDefectTab
                  : styles.inactiveDefectTab
              }`}
            >
              <p onClick={(e) => handleActiveTab(3)}>
                Closed
                {closedCount > 0 ? <span className={`${styles.countTooltip}`}>{closedCount}</span> : <></>}
              </p>
            </div>
          </Grid>
          {/* <Grid item xs={12} sx={{ marginTop: "32px" }}>
            <SearchBar
              data={data}
              isSearch={isSearch}
              setIsSearch={setIsSearch}
              isFilters={isFilters}
              setIsFilters={setIsFilters}
              operatorList={AOC}
              operator={operator}
              setOperator={setOperator}
            />
            <ActiveFilters isFilters={isFilters} setIsFilters={setIsFilters} />
          </Grid> */}
        </Grid>
        {/* {activeTab === 1 ? <OpenDefects /> : <CloseDefects />} */}
        {isLoading === false && showTab()}
      </div>
    );
  };

  const showTab = () => {
    switch (activeTab) {
      case 1:
        return <OpenDefects />;
      case 2:
        return <MonitorDefects />;
      case 3:
        return <CloseDefects />;
      default:
        return <OpenDefects />;
    }
  };

  const TableHeader = () => {
    return (
      <Grid container columns={13} className={styles.headerData}>
        <Grid
          item
          xs={1}
          style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", position: "relative" }}
        >
          Station
        </Grid>
        <Grid
          item
          xs={1}
          style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", position: "relative" }}
        >
          TSM. Ref
        </Grid>
        <Grid
          item
          xs={1}
          style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", position: "relative" }}
        >
          A/C Reg
        </Grid>
        <Grid
          item
          xs={3}
          style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", position: "relative" }}
        >
          Messages
        </Grid>
        <Grid
          item
          xs={2}
          style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", position: "relative" }}
        >
          Date
        </Grid>
        <Grid
          item
          xs={1}
          style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", position: "relative" }}
        >
          ATA
        </Grid>
        <Grid
          item
          xs={2}
          style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", position: "relative" }}
        >
          Department
        </Grid>
        {/* <Grid item xs={1} style={{overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", position:"relative"}}>Status</Grid> */}
        <Grid
          item
          xs={2}
          style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", position: "relative" }}
        >
          Rectification Status
        </Grid>
      </Grid>
    );
  };

  const TableBody = (data, status) => {
    return data.length > 0 ? (
      <div>
        {data.map((item, index, element) => {
          // let correlatedItem = [];
          let count = 0;
          let nextElement = element[index + 1];
          if (item?.correlated === 0) {
            // correlatedItem = getCorrelatedItem(item?.acreg_msgid);
            count = data.filter((obj) => obj.acreg_msgid === item.acreg_msgid && obj.correlated === 1).length;
          }

          return (
            <>
              <Grid
                container
                columns={13}
                className="small-font"
                style={{ padding: "0px 24px", cursor: status === "closed" ? "default" : "pointer" }}
                onClick={() => {
                  if (status !== "closed") {
                    setDrawerDetail(item);
                    setIsDrawerOpen(!isDrawerOpen);
                  }
                }}
                disabled={status === "closed" ? true : false}
              >
                <Grid
                  item
                  xs={1}
                  className={`
                    ${item?.correlated === 1 ? item?.acreg_msgid : ""}
                    ${styles.bodyData}`}
                  style={{
                    opacity: item?.correlated === 1 && 0,
                    // transition:"1s ease 4s",
                    transition: "opacity 10s ease-out",
                    display: item?.correlated === 1 && "none",
                    textOverflow: "ellipsis",
                  }}
                >
                  {/* Station */}
                  {getAircraftLocation(item?.ac_reg)}
                </Grid>
                <Grid
                  item
                  xs={1}
                  className={`
                    ${item?.correlated === 1 ? item?.acreg_msgid : ""}
                    ${styles.bodyData}`}
                  style={{
                    opacity: item?.correlated === 1 && 0,
                    // transition:"1s ease 4s",
                    transition: "opacity 10s ease-out",
                    display: item?.correlated === 1 && "none",
                    textOverflow: "ellipsis",
                  }}
                >
                  {item?.tsm_ref}
                </Grid>
                <Grid
                  item
                  xs={1}
                  className={`
                    ${item?.correlated === 1 ? item?.acreg_msgid : ""}
                    ${styles.bodyData}`}
                  style={{
                    opacity: item?.correlated === 1 && 0,
                    // transition:"1s ease 4s",
                    transition: "opacity 10s ease-out",
                    display: item?.correlated === 1 && "none",
                    textOverflow: "ellipsis",
                  }}
                >
                  {item?.ac_reg}
                </Grid>
                <Grid
                  item
                  xs={3}
                  className={`
                    ${item?.correlated === 1 ? item?.acreg_msgid : ""}
                    ${styles.bodyData}`}
                  style={{
                    opacity: item?.correlated === 1 && 0,
                    // transition:"1s ease 4s",
                    transition: "opacity 10s ease-out",
                    display: item?.correlated === 1 && "none",
                    position: "relative",
                  }}
                >
                  <span>{item?.text}</span>
                  {item?.correlated === 0 && nextElement && nextElement.correlated === 1 ? (
                    <div
                      style={{
                        padding: "6px",
                        // float: "right",
                        borderRadius: "4px",
                        border: "1px solid #E8E8E8",
                        cursor: "pointer",
                        position: "absolute",
                        top: "16px",
                        right: "15px",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        toggleCorrelated(e, item?.acreg_msgid);
                      }}
                    >
                      <img
                        src={CorrelatedDefect}
                        alt="CorrelatedDefect"
                        style={{
                          paddingRight: "6px",
                        }}
                      />
                      <span>{count}</span>
                    </div>
                  ) : (
                    <></>
                  )}
                </Grid>
                <Grid
                  item
                  xs={2}
                  className={`
                    ${item?.correlated === 1 ? item?.acreg_msgid : ""}
                    ${styles.bodyData}`}
                  style={{
                    opacity: item?.correlated === 1 && 0,
                    // transition:"1s ease 4s",
                    transition: "opacity 10s ease-out",
                    display: item?.correlated === 1 && "none",
                    textOverflow: "ellipsis",
                  }}
                >
                  {format(
                    new Date(item?.occurence),
                    dateFnsFormats.STANDARD_DATETIME
                  )}
                </Grid>
                <Grid
                  item
                  xs={1}
                  className={`
                    ${item?.correlated === 1 ? item?.acreg_msgid : ""}
                    ${styles.bodyData}`}
                  style={{
                    opacity: item?.correlated === 1 && 0,
                    // transition:"1s ease 4s",
                    transition: "opacity 10s ease-out",
                    display: item?.correlated === 1 && "none",
                    textOverflow: "ellipsis",
                  }}
                >
                  {getAtaChapter(item?.ata_chapter, item?.report_type)}
                </Grid>
                <Grid
                  item
                  xs={2}
                  className={`
                    ${item?.correlated === 1 ? item?.acreg_msgid : ""}
                    ${styles.bodyData}`}
                  style={{
                    opacity: item?.correlated === 1 && 0,
                    // transition:"1s ease 4s",
                    transition: "opacity 10s ease-out",
                    display: item?.correlated === 1 && "none",
                    textOverflow: "ellipsis",
                  }}
                >
                  {/* Department */}
                </Grid>
                <Grid item 
                  xs={1} 
                  className={`
                    ${item?.correlated === 1 ? item?.acreg_msgid : ""}
                    ${styles.bodyData}`
                  }
                  style={{
                    opacity: item?.correlated === 1 && 0,
                    // transition:"1s ease 4s",
                    transition: "opacity 10s ease-out",
                    display:item?.correlated === 1 && "none",
                    textOverflow:"ellipsis"
                  }}
                >
                  {item?.status}
                </Grid>
                <Grid
                  item
                  xs={2}
                  className={`
                    ${item?.correlated === 1 ? item?.acreg_msgid : ""}
                    ${styles.bodyData}`}
                  style={{
                    opacity: item?.correlated === 1 && 0,
                    // transition:"1s ease 4s",
                    transition: "opacity 10s ease-out",
                    display: item?.correlated === 1 && "none",
                    textOverflow: "ellipsis",
                  }}
                >
                  {/* Rectification Status */}
                </Grid>
                {nextElement && nextElement.correlated === 0 ? (
                  <hr
                    style={{
                      border: "0",
                      clear: "both",
                      display: "block",
                      width: "96%",
                      backgroundColor: "#e8e8e8",
                      height: "1px",
                      margin: 0,
                    }}
                  />
                ) : (
                  <></>
                )}
              </Grid>
            </>
          );
        })}
      </div>
    ) : (
      <span style={{ padding: "24px" }}>No Record Found</span>
    );
  };

  const OpenDefects = () => {
    return (
      <Grid container className={styles.container}>
        <Grid item xs={12}>
          <TableHeader />
        </Grid>
        <Grid item xs={12}>
          {TableBody(openDefectData, "open")}
        </Grid>
      </Grid>
    );
  };

  const MonitorDefects = () => {
    return (
      <Grid container className={styles.container}>
        <Grid item xs={12}>
          <TableHeader />
        </Grid>
        <Grid item xs={12}>
          {TableBody(monitorDefectData, "monitor")}
        </Grid>
      </Grid>
    );
  };

  const CloseDefects = () => {
    return (
      <Grid container className={styles.container}>
        <Grid item xs={12}>
          <TableHeader />
        </Grid>
        <Grid item xs={12}>
          {TableBody(closeDefectData, "closed")}
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {/* {isLoading === true && <AirplaneLoader />} */}
      <div
        className={styles.main}
        style={{
          overflow: "hidden",
          marginLeft: "12px",
          // paddingRight: `74px`,
          paddingBottom: "32px",
          zIndex: 8888,
        }}
        id="main"
      >
        <Grid
          container
          style={
            {
              // display: "flex",
              // paddingLeft: "30px"
            }
          }
          className={styles.gridContainer}
        >
          <Grid item xs={12} sx={{ marginBottom: "15px" }}>
            <RepetitiveDefectHeader />
          </Grid>
          <Grid item xs={12}>
            <ViewBox />
          </Grid>
          {/* {repetitiveDefect?.length !== 0 ? (
            <MUIPagination
              page={page}
              setPage={setPage}
              setPerPage={setPerPage}
              totalPage={totalPages}
              classes={{ ul: classes.ul }}
              totalRows={totalRows}
              sliceStart={sliceStart}
            />
          ) : (
            ""
          )} */}
        </Grid>
        {
          isLoading === true &&
          <AirplaneLoader />
        }
      </div>

      <EditDrawer
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
        drawerDetail={drawerDetail}
        setRepetitiveDefect={setRepetitiveDefect}
        repetitiveDefect={repetitiveDefect}
        activeTab={activeTab}
        opts={opts}
        newEntry={newEntry}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        setCallApi={setCallApi}
        callApi={callApi}
        location={dataLocation}
        // repetitiveDefectDASR={repetitiveDefectDASR}
        triggerApi={triggerApi}
        setTriggerApi={setTriggerApi}
      />
    </>
  );
};
