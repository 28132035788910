import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import styles from "./Dasr.module.css";
import Export from "./component/ExportButton";
import IconBackArrow from "common/images/icons/elevade/Icon-BackArrow.svg";
import IconScheduled from "common/images/icons/elevade/AirplaneInFlight.svg";
import IconTotalAircraft from "common/images/icons/elevade/Icon-TotalAircraft.svg";
import IconFlightLink from "common/images/icons/elevade/Icon-FlightLink.svg";
import IconWifi from "common/images/icons/elevade/Icon-Wifi.svg";
import IconFlightLinkWave from "common/images/icons/elevade/Icon-FlightLinkWave.svg";
import IconEngineType from "common/images/icons/elevade/Icon-EngineType.svg";
import IconEDTO from "common/images/icons/elevade/Icon-EDTO.svg";
import { Link } from "react-router-dom";
import SearchBar from "./component/SearchBar";
import TableList from "./component/TableList";
import DialogBox from "./component/DialogBox";
import { useEffect } from "react";
import { makeAPIRequestMDB } from "common/helpers/makeAPIRequestMDB";
import Overlay from "../component/Overlay";
import ActiveFilters from "./component/ActiveFilters";
import { Helmet } from "react-helmet";

const AircraftList = ({ aoc, setIsLoading }) => {
  const { userPermission } = useSelector((state) => state.userEntity);
  const aircraftListPermission = userPermission.filter((perm) => perm.code === "DASR-002");
  const [isLoadingSub, setIsLoadingSub] = useState(true);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState();
  const [open, setOpen] = useState();
  // const [openNotification, setOpenNotification] = useState(false);
  const [val, setVal] = useState("");
  const [isSearch, setIsSearch] = useState("");
  const [isFilters, setIsFilters] = useState({
    search: [],
    hub: [],
    aircraftReg: [],
    status: [],
    paxCapacity: [],
    wifi: [],
    edto: [],
    engineType: [],
    flightLinkStatus: [],
  });
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [isToday, setIsToday] = useState();
  const [isActiveCard, setIsActiveCard] = useState("");

  const InitialFilters = {
    search: [],
    hub: [],
    aircraftReg: [],
    status: [],
    paxCapacity: [],
    wifi: [],
    edto: [],
    engineType: [],
    flightLinkStatus: [],
  };

  useEffect(() => {
    var currentDate = new Date();
    var options = { month: "long", day: "2-digit", year: "numeric" };
    var formattedDate = currentDate.toLocaleDateString("en-US", options);
    currentDate = new Date(formattedDate);
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    setIsToday(`${year}-${month}-${day}`);
  }, [triggerUpdate, aoc]);

  // Trigger API call (aircraft-daily-status) on page load
  useEffect(() => {
    fetchAPIRequest();
    fetchSchedulePlanned();
    // setIsLoading(true);
    // eslint-disable-next-line
  }, [aoc]);

  // Trigger API call (aircraft-daily-status) on update data
  useEffect(() => {
    if (triggerUpdate) {
      fetchAPIRequest();
      fetchSchedulePlanned();
    }
    // eslint-disable-next-line
  }, [triggerUpdate]);

  function fetchAPIRequest() {
    setIsLoadingSub(true);
    FetchData();
  }

  async function FetchData() {
    var currentDate = new Date();
    var options = { month: "long", day: "2-digit", year: "numeric" };
    var formattedDate = currentDate.toLocaleDateString("en-US", options);
    currentDate = new Date(formattedDate);
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const isToday = `${year}-${month}-${day}`;
    if (isToday !== "NaN-NaN-NaN") {
      const fetch = makeAPIRequestMDB(`get`, `api/aircraft/daily-status?date=${isToday}&aoc=${aoc}`);
      fetch
        .then((response) => {
          setData(response.data.sort((a, b) => a.aircraftReg?.localeCompare(b.aircraftReg)));
          setTriggerUpdate(false);
          setIsLoading(false);
          setIsLoadingSub(false);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    // setIsLoading(false);
    // setIsLoadingSub(false);
  }

  async function fetchSchedulePlanned() {
    var currentDate = new Date();
    var options = { month: "long", day: "2-digit", year: "numeric" };
    var formattedDate = currentDate.toLocaleDateString("en-US", options);
    currentDate = new Date(formattedDate);
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const isToday = `${year}-${month}-${day}`;
    if (isToday !== "NaN-NaN-NaN" && aoc !== undefined) {
      const fetch = makeAPIRequestMDB(`get`, `api/aircraft/planned-schedule?date=${isToday}&aoc=${aoc}`);
      fetch
        .then((response) => {
          setVal(response.data.totalAircraft);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  }

  function GetSummaryCount(type) {
    if (type === "Scheduled") return data?.filter((e) => e.status.status === type).length;
    else if (type === "Active A/C")
      return data?.filter(
        (e) =>
          (e.status.status === "Scheduled" || e.status.status === "Standby" || e.status.status === "Utilised") &&
          e.status.hub !== ""
      ).length;
    else if (type === "Flightlink Status")
      return data?.filter((e) => e.flightLinkStatus === "FLIGHTLINK ACTIVE").length;
    else if (type === "WIFI Availability") return data?.filter((e) => e.wifi === true).length;
    else if (type === "Standby") return data?.filter((e) => e.status.status === type).length;
    else if (type === "CEO") return data?.filter((e) => e.engineType === type).length;
    else if (type === "NEO") return data?.filter((e) => e.engineType === type).length;
    else if (type === "EDTO") return data?.filter((e) => e.edto === true).length;
    else if (type === "Trent 1000") return data?.filter((e) => e.engineType === type).length;
    else return 0;
  }

  const handleClickSchedule = () => {
    setOpen(true);
  };

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  useEffect(() => {
    let data_ = data;

    if (isFilters.search?.length > 0) {
      data_ = data_?.filter(
        (e) =>
          isFilters.search?.some((searchTerm) => e.aircraftReg?.includes(searchTerm)) ||
          isFilters.search?.some((searchTerm) => e.status.hub?.includes(searchTerm)) ||
          isFilters.search?.some((searchTerm) => e.engineType?.includes(searchTerm)) ||
          isFilters.search?.some((searchTerm) => e.flightLinkStatus?.includes(searchTerm)) ||
          isFilters.search?.some((searchTerm) => e.currentService?.includes(searchTerm)) ||
          isFilters.search?.some((searchTerm) => e.status.status?.toUpperCase()?.includes(searchTerm)) ||
          isFilters.search?.includes(e.aircraftReg) ||
          isFilters.search?.includes(e.status.hub) ||
          isFilters.search?.includes(e.engineType) ||
          isFilters.search?.includes(e.flightLinkStatus) ||
          isFilters.search?.includes(e.currentService) ||
          isFilters.search?.includes(e.paxCapacity) ||
          isFilters.search?.includes(e.status.status) ||
          isFilters.search?.includes(e.status.hub)
      );
    }

    if (isFilters.hub?.length > 0) {
      data_ = data_?.filter((e) => isFilters.hub?.includes(e.status.hub));
    }

    if (isFilters.aircraftReg?.length > 0) {
      data_ = data_?.filter((e) => isFilters.aircraftReg?.includes(e.aircraftReg));
    }

    if (isFilters.status?.length > 0) {
      data_ = data_?.filter((e) => isFilters.status?.includes(e.status.status));
    }

    if (isFilters.paxCapacity?.length > 0) {
      data_ = data_?.filter((e) => isFilters.paxCapacity?.includes(e.paxCapacity));
    }

    if (isFilters.wifi?.length > 0) {
      data_ = data_?.filter((e) => e.wifi === true);
    }

    if (isFilters.edto?.length > 0) {
      data_ = data_?.filter((e) => e.edto === true);
    }

    if (isFilters.engineType?.length > 0) {
      data_ = data_?.filter((e) => isFilters.engineType?.includes(e.engineType));
    }

    if (isFilters.flightLinkStatus?.length > 0) {
      data_ = data_?.filter((e) => isFilters.flightLinkStatus?.includes(e.flightLinkStatus));
    }

    setFilteredData(data_);

    // Clear isActiveCard if isFilters is back to initial state
    if (areAllKeysEmpty(isFilters, InitialFilters)) setIsActiveCard("");
    // Clear isActiveCard based on specific key values
    if (isKeyEmpty(isFilters, "status") && isActiveCard === "Active A/C") setIsActiveCard("");
    else if (isKeyEmpty(isFilters, "status") && isActiveCard === "Standby") setIsActiveCard("");
    else if (isKeyEmpty(isFilters, "wifi") && isActiveCard === "WIFI Availability") setIsActiveCard("");
    else if (isKeyEmpty(isFilters, "edto") && isActiveCard === "EDTO") setIsActiveCard("");
    else if (isKeyEmpty(isFilters, "engineType") && isActiveCard === "CEO") setIsActiveCard("");
    else if (isKeyEmpty(isFilters, "engineType") && isActiveCard === "NEO") setIsActiveCard("");
    else if (isKeyEmpty(isFilters, "flightLinkStatus") && isActiveCard === "Flightlink Status") setIsActiveCard("");
  }, [isSearch, data, isFilters]);

  function areAllKeysEmpty(obj) {
    return Object.values(obj).every(
      (value) => value === null || value === undefined || value === "" || value.length === 0
    );
  }

  function isKeyEmpty(obj, key) {
    const value = obj[key];
    return value === null || value === undefined || value === "" || value.length === 0;
  }

  function handleClickCard(status) {
    if (status === isActiveCard) {
      setIsActiveCard("");
      setIsFilters(InitialFilters);
    } else {
      setIsActiveCard(status);
      if (status === "Active A/C") setIsFilters({ ...InitialFilters, status: ["Scheduled", "Standby", "Utilised"] });
      else if (status === "Standby") setIsFilters({ ...InitialFilters, status: ["Standby"] });
      else if (status === "WIFI Availability") setIsFilters({ ...InitialFilters, wifi: ["YES"] });
      else if (status === "EDTO") setIsFilters({ ...InitialFilters, edto: ["YES"] });
      else if (status === "Trent 1000") setIsFilters({ ...InitialFilters, engineType: ["Trent 1000"] });
      else if (status === "NEO") setIsFilters({ ...InitialFilters, engineType: ["NEO"] });
      else if (status === "Flightlink Status")
        setIsFilters({ ...InitialFilters, flightLinkStatus: ["FLIGHTLINK ACTIVE"] });
    }
  }

  return (
    <>
      <Helmet>
        <title>Royal Brunei Airlines - ELEVADE - Aircraft List</title>
      </Helmet>
      {isLoadingSub && <Overlay />}
      <div id="main" className={`${styles.container}`}>
        <>
          <Grid container columns={8.2} className={styles.gridContainer}>
            <Grid item xs={4.2} className={styles.gridTitle} sx={{ paddingBottom: "22px" }}>
              <Link to={"/fleet/dasr"}>
                <img src={IconBackArrow} alt="icon-back" style={{ marginRight: "14px", marginTop: "-4px" }} />
                Aircraft List
              </Link>
            </Grid>
            <Grid item xs={4} className={styles.gridJustifyEnd} sx={{ fontSize: "14px", paddingBottom: "32px" }}>
              {/* <Export /> */}
            </Grid>
            <Grid item xs={1.2} sx={{ borderRight: "1px solid #E8E8E8", paddingRight: "24px" }}>
              <div
                className={`${styles.divBoxAircraftList} ${
                  aircraftListPermission.some((perm) => perm.canUpdate === true) ? styles.hoverState : ""
                }  ${styles.divBoxPlannedSchedule}`}
                style={{
                  float: "left",
                  cursor: aircraftListPermission.some((perm) => perm.canUpdate === true) ? "pointer" : "default",
                }}
                onClick={() => {
                  if (aircraftListPermission.some((perm) => perm.canUpdate === true)) {
                    handleClickSchedule();
                  }
                }}
              >
                <Grid container columns={4}>
                  <Grid
                    item
                    xs={4}
                    className={styles.gridJustifyStart}
                    sx={{ paddingTop: "13px", paddingBottom: "10px" }}
                  >
                    <img src={IconScheduled} alt="icon-total-aircraft" height={24} />
                  </Grid>
                  <Grid item xs={4}>
                    <div style={{ fontSize: "14px" }}>Planned Operations</div>
                    <div style={{ fontFamily: "Gotham-Medium", fontSize: "24px" }}>{val || 0}</div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={1} sx={{ paddingLeft: "24px" }}>
              <div
                className={`${styles.divBoxAircraftList} ${styles.hoverState} ${
                  isActiveCard === "Active A/C" ? styles.divCardActive : ""
                }`}
                onClick={() => handleClickCard("Active A/C")}
              >
                <Grid container columns={4}>
                  <Grid
                    item
                    xs={4}
                    className={styles.gridJustifyStart}
                    sx={{ paddingTop: "13px", paddingBottom: "10px", paddingLeft: "5px" }}
                  >
                    <img src={IconTotalAircraft} alt="icon-total-aircraft" height={24} />
                  </Grid>
                  <Grid item xs={4}>
                    <div style={{ fontSize: "14px" }}>Active A/C</div>
                    <div style={{ fontFamily: "Gotham-Medium", fontSize: "24px" }}>{GetSummaryCount("Active A/C")}</div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={1} sx={{ paddingLeft: "24px" }}>
              <div
                className={`${styles.divBoxAircraftList} ${styles.hoverState} ${
                  isActiveCard === "Standby" ? styles.divCardActive : ""
                }`}
                onClick={() => handleClickCard("Standby")}
              >
                <Grid container columns={4}>
                  <Grid
                    item
                    xs={4}
                    className={styles.gridJustifyStart}
                    sx={{ paddingTop: "13px", paddingBottom: "10px", paddingLeft: "5px" }}
                  >
                    <img src={IconFlightLink} alt="icon-total-aircraft" height={24} />
                  </Grid>
                  <Grid item xs={4}>
                    <div style={{ fontSize: "14px" }}>Standby</div>
                    <div style={{ fontFamily: "Gotham-Medium", fontSize: "24px" }}>{GetSummaryCount("Standby")}</div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={1} sx={{ paddingLeft: "24px" }}>
              <div
                className={`${styles.divBoxAircraftList} ${styles.hoverState} ${
                  isActiveCard === "WIFI Availability" ? styles.divCardActive : ""
                }`}
                onClick={() => handleClickCard("WIFI Availability")}
              >
                <Grid container columns={4}>
                  <Grid
                    item
                    xs={4}
                    className={styles.gridJustifyStart}
                    sx={{ paddingTop: "13px", paddingBottom: "10px", paddingLeft: "5px" }}
                  >
                    <img src={IconWifi} alt="icon-total-aircraft" height={24} />
                  </Grid>
                  <Grid item xs={4}>
                    <div style={{ fontSize: "14px" }}>WIFI Avail.</div>
                    <div style={{ fontFamily: "Gotham-Medium", fontSize: "24px" }}>
                      {GetSummaryCount("WIFI Availability")}
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={1} sx={{ paddingLeft: "24px" }}>
              <div
                className={`${styles.divBoxAircraftList} ${styles.hoverState} ${
                  isActiveCard === "EDTO" ? styles.divCardActive : ""
                }`}
                onClick={() => handleClickCard("EDTO")}
              >
                <Grid container columns={4}>
                  <Grid
                    item
                    xs={4}
                    className={styles.gridJustifyStart}
                    sx={{ paddingTop: "13px", paddingBottom: "10px", paddingLeft: "5px" }}
                  >
                    <img src={IconEDTO} alt="icon-total-aircraft" height={24} />
                  </Grid>
                  <Grid item xs={4}>
                    <div style={{ fontSize: "14px" }}>EDTO A/C</div>
                    <div style={{ fontFamily: "Gotham-Medium", fontSize: "24px" }}>{GetSummaryCount("EDTO")}</div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={1} sx={{ paddingLeft: "24px" }}>
              <div
                className={`${styles.divBoxAircraftList} ${styles.hoverState} ${
                  isActiveCard === "Trent 1000" ? styles.divCardActive : ""
                }`}
                onClick={() => handleClickCard("Trent 1000")}
              >
                <Grid container columns={4}>
                  <Grid
                    item
                    xs={4}
                    className={styles.gridJustifyStart}
                    sx={{ paddingTop: "13px", paddingBottom: "10px", paddingLeft: "5px" }}
                  >
                    <img src={IconEngineType} alt="icon-total-aircraft" height={24} />
                  </Grid>
                  <Grid item xs={4}>
                    <div style={{ fontSize: "14px" }}>Trent 1000</div>
                    <div style={{ fontFamily: "Gotham-Medium", fontSize: "24px" }}>{GetSummaryCount("Trent 1000")}</div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={1} sx={{ paddingLeft: "24px" }}>
              <div
                className={`${styles.divBoxAircraftList} ${styles.hoverState} ${
                  isActiveCard === "NEO" ? styles.divCardActive : ""
                }`}
                onClick={() => handleClickCard("NEO")}
              >
                <Grid container columns={4}>
                  <Grid
                    item
                    xs={4}
                    className={styles.gridJustifyStart}
                    sx={{ paddingTop: "13px", paddingBottom: "10px", paddingLeft: "5px" }}
                  >
                    <img src={IconEngineType} alt="icon-total-aircraft" height={24} />
                  </Grid>
                  <Grid item xs={4}>
                    <div style={{ fontSize: "14px" }}>NEO</div>
                    <div style={{ fontFamily: "Gotham-Medium", fontSize: "24px" }}>{GetSummaryCount("NEO")}</div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={1} sx={{ paddingLeft: "24px" }}>
              <div
                className={`${styles.divBoxAircraftList} ${styles.hoverState} ${
                  isActiveCard === "Flightlink Status" ? styles.divCardActive : ""
                }`}
                onClick={() => handleClickCard("Flightlink Status")}
              >
                <Grid container columns={4}>
                  <Grid
                    item
                    xs={4}
                    className={styles.gridJustifyStart}
                    sx={{ paddingTop: "13px", paddingBottom: "10px", paddingLeft: "5px" }}
                  >
                    <img src={IconFlightLinkWave} alt="icon-total-aircraft" height={24} />
                  </Grid>
                  <Grid item xs={4}>
                    <div style={{ fontSize: "14px" }}>Flightlink</div>
                    <div style={{ fontFamily: "Gotham-Medium", fontSize: "24px" }}>
                      {GetSummaryCount("Flightlink Status")}
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={8.2} sx={{ paddingTop: "40px" }}>
              <SearchBar
                data={data}
                isSearch={isSearch}
                setIsSearch={setIsSearch}
                isFilters={isFilters}
                setIsFilters={setIsFilters}
              />
              <ActiveFilters isFilters={isFilters} setIsFilters={setIsFilters} />
            </Grid>
            <Grid item xs={8.2} sx={{ paddingTop: "0px" }}>
              <TableList
                data={filteredData}
                setTriggerUpdate={setTriggerUpdate}
                isFilters={isFilters}
                aircraftListPermission={aircraftListPermission}
              />
            </Grid>
          </Grid>
        </>
      </div>
      <DialogBox
        aoc={aoc}
        open={open}
        setOpen={setOpen}
        val={val}
        setVal={setVal}
        // setOpenNotification={setOpenNotification}
      />
    </>
  );
};

export default AircraftList;
