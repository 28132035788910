import React from "react";
import { Grid, Avatar, Modal, Box, styled, Tooltip, tooltipClasses } from "@mui/material";
import styles from "./DDML.module.css";
import IconErrorAirBalloon from "common/images/icons/elevade/Icon-ErrorAirBalloon.svg";
import IconPDF from "common/images/icons/elevade/Icon-PDFAttachment.svg";
import IconCopy from "common/images/icons/elevade/Icon-Copy.svg";
import { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { formatDateDasr, formatTimeDasr } from "pages/elevade/component/Helpers";
import { makeAPIRequestMDB } from "common/helpers";
import Preview from "../component/Preview";

const DDMLUpdateLogs = ({
  tab,
  DDMLLogs,
  handleCopy,
  handleOpenComment,
  openPreviewComment,
  handleCloseComment,
  style,
  commentFileLink,
  uploadComment,
  statusTab,
  ...props
}) => {
  const { userPermission } = useSelector((state) => state.userEntity);
  const updateLogsRectificationPermission = userPermission.filter((perm) => perm.code === "DASR-005");
  const updateLogsMaterialPermission = userPermission.filter((perm) => perm.code === "DASR-006");
  const updateLogsPPCPermission = userPermission.filter((perm) => perm.code === "DASR-007");
  const [isRectification, setIsRectification] = useState(false);
  const [isMaterial, setIsMaterial] = useState(false);
  const [isPPC, setIsPPC] = useState(false);
  const scrollingDivRef = useRef(null);
  const posting = useRef(null);
  const [isId, setIsId] = useState();
  const [openPreview, setOpenPreview] = useState(false);
  const [summaryPreviewUrl, setSummaryPreviewUrl] = useState();
  const [fileName, setFileName] = useState();

  useEffect(() => {
    if (props?.initialData?.ddml) {
      setIsId(props?.initialData.formId);
    } else setIsId(props?.initialData._id || props?.initialData.id);

    if (props?.initialData[0]?.ddml) {
      setIsId(props?.initialData[0]?.formId);
    } else if (props?.initialData[0] && !props?.initialData?.ddml) {
      setIsId(props?.initialData[0]?._id);
    }
  }, [props.initialData]);

  useEffect(() => {
    readLogs();
  }, [tab]);

  useEffect(() => {
    return posting?.current?.scrollIntoView({ behavior: "smooth" });
  }, [DDMLLogs, uploadComment]);

  useEffect(() => {
    if (tab === "Rectification Update") {
      if (updateLogsRectificationPermission.some((perm) => perm.canUpdate === true)) {
        setIsRectification(true);
      }
    } else if (tab === "Material/Tools Update") {
      if (updateLogsMaterialPermission.some((perm) => perm.canUpdate === true)) {
        setIsMaterial(true);
      }
    } else if (tab === "PPC") {
      if (updateLogsPPCPermission.some((perm) => perm.canUpdate === true)) {
        setIsPPC(true);
      }
    } else {
      setIsRectification(false);
      setIsMaterial(false);
      setIsPPC(false);
    }
  }, [tab]);

  useEffect(() => {
    if (isId) {
      let col =
        tab === "Rectification Update"
          ? "rectification"
          : tab === "Material/Tools Update"
          ? "materialTool"
          : "planningTech";

      updateArrayId(props.data || DDMLLogs.data, isId, col);
    }
  }, [tab, isId]);

  async function readLogs() {
    const body = {
      ids: props?.unreadLogs || props?.initialData.unreadLogs,
    };

    let length = props?.unreadLogs?.length || props?.initialData?.unreadLogs?.length;

    if (length > 0) {
      const fetch = makeAPIRequestMDB(`put`, `api/ddml/logs/bulk/read`, body);
      fetch
        .then((response) => {
          return;
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  }

  function updateArrayId(array, id, col) {
    // Check if given array is an array
    let isArray = Array.isArray(array);

    if (isArray) {
      // Find the index of the object with the given id
      const index = array.findIndex((element) => element._id === id);

      // Check if the object with the given id was found
      if (index !== -1) {
        // Update the name of the object at the found index
        if (array.ddml) {
          array[index].ddml.unreadLogs[col] = [];
        } else {
          if (array[index] && array[index].unreadLogs) {
            array[index].unreadLogs[col] = [];
          }
        }
      } else {
        // Find the index in ddml object using formId
        const index = array.findIndex((element) => element.formId === id);

        if (index !== -1) {
          if ((array.ddml || array[index].ddml) && array[index].formType !== "rdd") {
            array[index].ddml.unreadLogs[col] = [];
          } else {
            array[index].unreadLogs[col] = [];
          }
        }
      }
    } else {
      array.unreadLogs[col] = [];
    }
  }

  const ErrorAirBallon = () => {
    return (
      <>
        <div
          ref={scrollingDivRef}
          className={styles.errorAirBallon}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* <center> */}
          <img src={IconErrorAirBalloon} alt="error-air-ballon" />
          <div className={styles.errorTitle}>No updates yet</div>
          <div className={styles.errorSubTitle}>
            It looks like no one has made any updates yet.
            <br />
            Insert your update in the comment section below.
          </div>
          {/* </center> */}
        </div>
      </>
    );
  };

  const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#FFFEE8",
        color: "rgba(0, 0, 0, 0.87)",
        boxShadow: theme.shadows[1],
        fontSize: 11,
      },
    })
  );

  const Posting = () => {
    return <div className={styles.newUpdateStatus}>Posting...</div>;
  };

  const NewUpdateStatusLabel = ({ id }) => {
    if (props?.unreadLogs !== undefined && props?.unreadLogs[0] === id) {
      return (
        props?.unreadLogs !== undefined &&
        props?.unreadLogs[0] === id && (
          <Grid item xs={6} sx={{ display: "flex", justifyContent: "center" }}>
            <div className={styles.newUpdateStatus}>New update status</div>
          </Grid>
        )
      );
    } else {
      return <></>;
    }
  };

  const copyIcon = (e) => {
    return e.formStatus !== "deleted" ? (
      <LightTooltip title={"Copy and replace to Summary"} followCursor placement="bottom-start">
        <img
          src={IconCopy}
          alt="copy"
          height={16}
          style={{ cursor: "pointer" }}
          onClick={() => {
            handleCopy(e?.updateLogDesc, e._id, e.updateLogCategory, e.collectionId);
          }}
        />
      </LightTooltip>
    ) : (
      ""
    );
  };

  const copyReplaceSummaryPermission = (e) => {
    if (tab === "Rectification Update" && isRectification) {
      return <>{copyIcon(e)}</>;
    } else if (tab === "Material/Tools Update" && isMaterial) {
      return <>{copyIcon(e)}</>;
    } else if (tab === "PPC" && isPPC) {
      return <>{copyIcon(e)}</>;
    } else {
      return <></>;
    }
  };

  function bytesToMB(bytes) {
    if (bytes !== "" && bytes !== undefined && bytes !== null) {
      const MB = bytes / (1024 * 1024);
      if (MB < 0.1) {
        return <span>&#183; {bytes.toLocaleString()} bytes</span>;
      } else {
        return (
          <span>&#183; {MB.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} MB</span>
        );
      }
    }
    return "";
  }

  function handleOpenPreview(fileLink, fileName) {
    setSummaryPreviewUrl(fileLink);
    setFileName(fileName);
    setOpenPreview(true);
  }

  return DDMLLogs?.data?.filter((item) => item.updateLogCategory === tab).length > 0 ? (
    <>
      {/* <div style={{ width: "100%", height: "100%", border: "0px solid red", marginTop: "-10px" }}> */}
      <div ref={scrollingDivRef} style={{ margin: "24px 24px 12px 24px", height: "100%" }}>
        <Grid container columns={6}>
          {DDMLLogs &&
            DDMLLogs?.data
              ?.filter((item) => item.updateLogCategory === tab)
              .map((e) => {
                return (
                  <>
                    <NewUpdateStatusLabel id={e._id} />
                    <Grid item xs={6} sx={{ paddingBottom: "16px", display: "flex" }}>
                      {<Avatar src={e?.userCreated?.avatarLink} />}
                      <div
                        style={{
                          padding: "10px 16px",
                          borderRadius: "8px",
                          backgroundColor: !e?.pushToSummary ? "#F7F7F7" : "#F3F7FE",
                          width: "100%",
                          marginLeft: "8px",
                        }}
                      >
                        <Grid container columns={5}>
                          <Grid item xs={4.5}>
                            <span
                              style={{
                                fontWeight: "bold",
                                fontSize: "10px",
                                marginRight: "4px",
                              }}
                            >
                              {e?.userCreated?.name || "ELEVADE"}
                            </span>
                            <span style={{ fontSize: "10px", color: "#838383" }}>made an update</span>
                          </Grid>
                          <Grid
                            item
                            xs={0.5}
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            {!e?.pushToSummary && props.formStatus !== "deleted" && (
                              <>{copyReplaceSummaryPermission(e)}</>
                            )}
                          </Grid>
                          <Grid item xs={5} sx={{ fontSize: "12px", padding: "16px 0px", position: "relative" }}>
                            {e?.attachmentLog[0]?.fileLink !== undefined &&
                              e?.attachmentLog[0]?.fileLink !== null &&
                              e?.attachmentLog[0]?.fileLink !== "" && (
                                <div
                                  className={
                                    e.attachmentLog?.[0]?.fileType === "application/pdf"
                                      ? styles.pdfContainer
                                      : styles.imageContainer
                                  }
                                >
                                  <div
                                    style={{
                                      cursor: "pointer",
                                      objectFit: "cover",
                                      display: "flex",
                                    }}
                                    onClick={() => {
                                      handleOpenPreview(e.attachmentLog?.[0]?.fileLink, e.attachmentLog?.[0]?.fileName);
                                    }}
                                  >
                                    <div className={`${styles.pdfIcon}`}>
                                      <img
                                        src={
                                          e.attachmentLog?.[0]?.fileType === "application/pdf"
                                            ? IconPDF
                                            : e.attachmentLog?.[0]?.fileLink
                                        }
                                        alt={""}
                                        width={e.attachmentLog?.[0]?.fileType === "application/pdf" ? 37 : "100%"}
                                      />
                                    </div>
                                    {e.attachmentLog?.[0]?.fileType === "application/pdf" && (
                                      <div className={styles.pdfFileDetails}>
                                        <div className={styles.fileName}>{e.attachmentLog?.[0]?.fileName}</div>
                                        <div className={styles.fileType}>
                                          {e.attachmentLog?.[0]?.fileType === "application/pdf" ? (
                                            <span>PDF Document {bytesToMB(e.attachmentLog?.[0]?.fileSize)}</span>
                                          ) : (
                                            <span>Image Document {bytesToMB(e.attachmentLog?.[0]?.fileSize)}</span>
                                          )}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                            <div
                              style={{
                                whiteSpace: /\r|\n/.test(e?.updateLogDesc) ? "pre-wrap" : "wrap",
                              }}
                            >
                              {e?.updateLogDesc.toUpperCase()}
                            </div>
                          </Grid>
                          <Grid
                            item
                            xs={5}
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <span style={{ fontSize: "10px", color: "#838383" }}>
                              {formatDateDasr(e?.createdAt) + ` at ` + formatTimeDasr(e?.createdAt)}
                            </span>
                          </Grid>
                          <Grid
                            item
                            xs={5}
                            sx={{
                              color: "#088FD1",
                              fontSize: "10px",
                              fontFamily: "Gotham-Medium",
                            }}
                          >
                            {e?.pushToSummary && "This message has moved to the summary."}
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </>
                );
              })}
          {props.ddmlStatus && (
            <>
              <Grid item xs={0.6} sx={{ paddingBottom: "16px" }}>
                {<Avatar src={props.ddmlStatus?.createdBy} />}
              </Grid>
              <Grid item xs={5.4} sx={{ paddingBottom: "16px" }}>
                <div
                  style={{
                    padding: "10px 16px",
                    borderRadius: "8px",
                    backgroundColor: "#F7F7F7",
                    // border: isRead?.length > 0 ? "" : "0px solid red",
                  }}
                >
                  <Grid container columns={5}>
                    <Grid item xs={5}>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "10px",
                          marginRight: "4px",
                        }}
                      >
                        {props.ddmlStatus?.createdBy}
                      </span>
                      <span style={{ fontSize: "10px", color: "#838383" }}>made an updates</span>
                    </Grid>
                    {/* <Grid item xs={5} sx={{ fontSize: "12px", padding: "16px 0px" }}>
                      {props.ddmlStatus?.rectificationCategory.toUpperCase()}
                      {props.ddmlStatus?.attachment[0]?.fileLink !== undefined &&
                        props.ddmlStatus?.attachment[0]?.fileLink !== null &&
                        props.ddmlStatus.attachment.map((item, index) => {
                          return (
                            <div
                              style={{
                                width: "140px",
                                height: "105px",
                                marginTop: "16px",
                                cursor: "pointer",
                                objectFit: "cover",
                              }}
                              onClick={() => {
                                handleOpenComment(item.fileLink);
                              }}
                              key={index}
                            >
                              <img src={item.fileLink} alt={""} height={105} />
                            </div>
                          );
                        })}
                    </Grid> */}
                    <Grid item xs={5} sx={{ fontSize: "12px", padding: "16px 0px", position: "relative" }}>
                      {props.ddmlStatus?.attachment[0]?.fileLink !== undefined &&
                        props.ddmlStatus?.attachment[0]?.fileLink !== null &&
                        props.ddmlStatus?.attachment[0]?.fileLink !== "" && (
                          <div
                            className={
                              props.ddmlStatus?.attachment[0]?.[0]?.fileType === "application/pdf"
                                ? styles.pdfContainer
                                : styles.imageContainer
                            }
                          >
                            <div
                              style={{
                                cursor: "pointer",
                                objectFit: "cover",
                                display: "flex",
                              }}
                              onClick={() => {
                                handleOpenComment(props.ddmlStatus?.attachment[0]?.[0]?.fileLink);
                              }}
                            >
                              <div className={styles.pdfIcon}>
                                <img
                                  src={
                                    props.ddmlStatus?.attachment[0]?.[0]?.fileType === "application/pdf"
                                      ? IconPDF
                                      : props.ddmlStatus?.attachment[0]?.[0]?.fileLink
                                  }
                                  alt={""}
                                  height={37}
                                />
                              </div>
                              <div>
                                <div className={styles.fileName}>{props.ddmlStatus?.attachment[0]?.[0]?.fileName}</div>
                                <div className={styles.fileType}>
                                  {props.ddmlStatus?.attachment[0]?.[0]?.fileType === "application/pdf" ? (
                                    <span>
                                      PDF Document {bytesToMB(props.ddmlStatus?.attachment[0]?.[0]?.fileSize)}
                                    </span>
                                  ) : (
                                    <span>
                                      Image Document {bytesToMB(props.ddmlStatus?.attachment[0]?.[0]?.fileSize)}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      <div
                        style={{
                          whiteSpace: /\r|\n/.test(props.ddmlStatus?.rectificationCategory) ? "pre-wrap" : "wrap",
                        }}
                      >
                        {props.ddmlStatus?.rectificationCategory.toUpperCase()}
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={5}
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <span style={{ fontSize: "10px", color: "#838383" }}>
                        {formatDateDasr(props.ddmlStatus?.createdAt) +
                          ` at ` +
                          formatTimeDasr(props.ddmlStatus?.createdAt)}
                      </span>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </>
          )}
          <Grid item xs={6} sx={{ display: "flex", justifyContent: "center" }}>
            {uploadComment ? <Posting /> : ""}
          </Grid>
          <Grid item xs={6} ref={posting} sx={{ minHeight: "20px" }}></Grid>
        </Grid>
        <Modal
          open={openPreviewComment}
          onClose={handleCloseComment}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={
              !commentFileLink?.includes(".pdf?")
                ? style
                : {
                    // content: {
                    // top: "50%",
                    // left: "50%",
                    // right: "auto",
                    // bottom: "auto",
                    // marginRight: "-50%",
                    // transform: "translate(-50%, -50%)",
                    width: "80%",
                    height: "80%",
                    position: "fixed",
                    top: "10%",
                    right: "10%",
                    // },
                  }
            }
          >
            {commentFileLink?.includes(".pdf?") && (
              <iframe src={commentFileLink} title="PDF Preview" style={{ width: "100%", height: "100%" }} />
            )}
            {!commentFileLink?.includes(".pdf?") && (
              <img
                src={commentFileLink}
                alt={`Preview`}
                style={{
                  width: "100%",
                  maxWidth: "1000px",
                  height: "100%",
                  maxHeight: "700px",
                }}
              />
            )}
          </Box>
        </Modal>
      </div>
      {/* </div> */}
      <Preview open={openPreview} setOpen={setOpenPreview} fileLink={summaryPreviewUrl} fileName={fileName} />
    </>
  ) : props.ddmlStatus ? (
    <>
      <div ref={scrollingDivRef} style={{ margin: "8px 24px", height: "100%" }}>
        <Grid container columns={6}>
          <Grid item xs={0.6} sx={{ paddingBottom: "16px" }}>
            {<Avatar src={props.ddmlStatus?.createdBy} />}
          </Grid>
          <Grid item xs={5.4} sx={{ paddingBottom: "16px" }}>
            <div
              style={{
                padding: "10px 16px",
                borderRadius: "8px",
                backgroundColor: "#F7F7F7",
                // border: isRead?.length > 0 ? "" : "0px solid red",
              }}
            >
              <Grid container columns={5}>
                <Grid item xs={5}>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "10px",
                      marginRight: "4px",
                    }}
                  >
                    {props.ddmlStatus?.createdBy}
                  </span>
                  <span style={{ fontSize: "10px", color: "#838383" }}>made an update</span>
                </Grid>
                <Grid item xs={5} sx={{ fontSize: "12px", padding: "16px 0px" }}>
                  {props.ddmlStatus?.rectificationCategory.toUpperCase()}
                  {props.ddmlStatus?.attachment[0]?.fileLink !== undefined &&
                    props.ddmlStatus?.attachment[0]?.fileLink !== null &&
                    props.ddmlStatus.attachment.map((item, index) => {
                      return (
                        <div
                          key={index}
                          style={{
                            width: "140px",
                            height: "105px",
                            marginTop: "16px",
                            cursor: "pointer",
                            objectFit: "cover",
                          }}
                          onClick={() => {
                            handleOpenComment(item.fileLink);
                          }}
                        >
                          <img src={item.fileLink} alt={""} height={105} />
                        </div>
                      );
                    })}
                </Grid>
                <Grid
                  item
                  xs={5}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <span style={{ fontSize: "10px", color: "#838383" }}>
                    {formatDateDasr(props.ddmlStatus?.createdAt) + ` ` + formatTimeDasr(props.ddmlStatus?.createdAt)}
                  </span>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <Modal
          open={openPreviewComment}
          onClose={handleCloseComment}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <img
              src={commentFileLink}
              alt={`Preview`}
              style={{
                width: "100%",
                maxWidth: "1000px",
                height: "100%",
                maxHeight: "700px",
              }}
            />
          </Box>
        </Modal>
      </div>
    </>
  ) : (
    <ErrorAirBallon />
  );
};

export default DDMLUpdateLogs;
