
import React from 'react';
import {
	Drawer,
	Box,
	IconButton,
	Typography,
	Divider,
	ClickAwayListener,
} from "@mui/material";
import { Fragment } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useAppSelector } from "../../../app/hooks";
import zIndexes from '../../../constants/zIndexes';
export default function RightDrawer({
	title,
	open,
	setClose,
	children,
	// canClose = true,
}) {
	// const { isNotificationOpenSyncDrawer, } = useAppSelector((state) => state.layout);

	const isNotificationOpenSyncDrawer = false;
	const marginTopOverride = "60px";

	// if hide backdrop then cannot make it variant temporary
	return (
		<ClickAwayListener
			mouseEvent="onMouseDown"
			touchEvent="onTouchStart"
			onClickAway={() => {
				// const actuallyCanClose = (canClose !== undefined) ? canClose : !isNotificationOpenSyncDrawer
				if (setClose && !isNotificationOpenSyncDrawer) {
					setClose();
				}
			}}
		>
			<Drawer
				anchor={"right"}
				open={open ?? false}
				variant="persistent"
				// onClose={(_, reason) =>
				//     reason === 'backdropClick' && (setClose ? setClose() : null)
				// }
				PaperProps={{
					style: {
						marginTop: `${marginTopOverride}`,
						zIndex: zIndexes.rightDrawer,
						height: `calc(100% - ${marginTopOverride})`,
					},
				}}
				// sty
				// containerStyle={{ zIndex:'2050 important!', height:"50% !important" }}
				onClose={setClose}
			>
				<Box
					sx={{
						position: "relative",
						height: "94%",
					}}
				>
					<IconButton
						sx={{
							zIndex: zIndexes.modal,
							position: "absolute",
							right: "0px",
							top: "0px",
						}}
						onClick={() => {
							if (setClose) {
								setClose();
							}
						}}
					>
						<CloseIcon />
					</IconButton>
					{title && (
						<Fragment>
							<Typography
								sx={{
									height: "40px",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									paddingRight: "2.5em",
									paddingLeft: "1em",
								}}
							>
								{title}
							</Typography>
							<Divider />
						</Fragment>
					)}
					<Box
						sx={{
							height: "100%",
							overflow: "auto",
							width: "500px",
						}}
					>
						{children ?? null}
					</Box>
				</Box>
			</Drawer>
		</ClickAwayListener>
	);
}
