
import { Box, Button, TextField, Typography } from "@mui/material";
import React from 'react'
import { useRef, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import DropDownCustom from "../../components/inputs/dropdowns/DropDownCustom/Main";
import FilterChipsMain from "../../components/inputs/FilterChips/FilterChipsMain";
import { SmallAcEngineIcon, SmallAcModleIcon } from "../EqManagementPage/Icons";
import DateRangeCustom from "../../components/inputs/dates/DateRangeCustom";
import { getCabinMonitDateFormatWithTime } from "../../utils/dates";

import {
	useGetAllFleetDistinctedEngine,
	useGetAllFleetDistinctedByModel,
	// useGetAllFleetByCompanyCodeSearchedByRegNo,
	useGetAllFleets,
} from "../../../models/custom/fleet-model";

export default function SearchAdv({
	filterRegNos,
	search,
	setSearch,
	setFilterRegNos,
	filterModels,
	setFilterModels,
	filterEnType,
	setFilterEnType,
	createdAtFrom,
	setCreatedAtFrom,
	createdAtTo,
	setCreatedAtTo,
}) {
	const searchInputRef = useRef(null);
	const [searchRegNo, setSearchRegNo] = useState(undefined);

	const allFleetDistinctedByModel = useGetAllFleetDistinctedByModel();
	const allFleetDistinctedByEngine = useGetAllFleetDistinctedEngine();
	const allFleets = useGetAllFleets(true, { searchRegNo });

	// const {
	// 	data: enType,
	// 	isError: enTypeError,
	// 	isLoading: enTypeLoading,
	// } = useGetAllFleetDistinctedEngine();

	// const enTypes = [];
	// enType?.map(({ node }) => {
	// 	if (node?.engineType) {
	// 		enTypes.push(node?.engineType);
	// 	}
	// });

	// const {
	// 	data: models,
	// 	isError: modelsError,
	// 	isLoading: modelsLoading,
	// } = useGetAllFleetDistinctedByModel();
	// const acModels = [];
	// models?.map(({ node }) => {
	// 	if (node?.model) {
	// 		acModels.push(node?.model);
	// 	}
	// });

	// const {
	// 	data: acRegNoList,
	// 	isError: acRegNoListError,
	// 	isLoading: acRegNoListLoading,
	// } = useGetAllFleetByCompanyCodeSearchedByRegNo({ searchRegNo });
	// const acRegNos = [];
	// acRegNoList?.map(({ node }) => {
	// 	if (node?.regNo) {
	// 		acRegNos.push(node.regNo);
	// 	}
	// });

	const searchChip = search ? [
		{
			value: search,
			label: search,
			onRemove: () => {
				setSearch(undefined);
				//@ts-ignore
				searchInputRef.current.value = "";
			},
		},
	] : [];

	const filterDateStart = createdAtFrom ? [
		{
			value: createdAtFrom,
			label: `From : ${getCabinMonitDateFormatWithTime(
				createdAtFrom
			)}`,
			onRemove: () => {
				setCreatedAtFrom(undefined);
			},
		},
	] : [];

	const filterDateEnd = createdAtTo ? [
		{
			value: createdAtTo,
			label: `To : ${getCabinMonitDateFormatWithTime(
				createdAtTo
			)}`,
			onRemove: () => {
				setCreatedAtTo(undefined);
			},
		},
	] : [];

	return (
		<Box>
			<Box
				sx={{
					width: "100%",
					display: "flex",
					justifyContent: "left",
					paddingBottom: "1em",
				}}
			>
				<TextField
					sx={{
						width: "400px",
						borderRight: "1px solid #ccc",
						"& input::placeholder": {
							fontSize: "12px",
						},
					}}
					inputRef={searchInputRef}
					// label="Search"
					placeholder="Search"
					variant="standard"
					size="small"
					onBlur={(e) => {
						setSearch(e.target.value);
					}}
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							setSearch(e.target.value);
						}
					}}
					InputProps={{
						disableUnderline: true,
						startAdornment: (
							<SearchIcon
								sx={{
									fontSize: 50,
									cursor: "pointer",
									padding: "0.3em",
									"&:hover": {
										background: "whitesmoke",
										borderRadius: "1.5em",
									},
								}}
							/>
						),
					}}
				/>
				{(search ||
					filterRegNos.length > 0 ||
					filterModels.length > 0 ||
					filterEnType.length > 0 ||
					createdAtTo ||
					createdAtFrom) && (
						<Button
							style={{
								textTransform: "none",
							}}
							onClick={() => {
								setSearch(undefined);
								setFilterRegNos([]);
								setFilterModels([]);
								setFilterEnType([]);
								setCreatedAtFrom(undefined);
								setCreatedAtTo(undefined);
								// @ts-ignore
								searchInputRef.current.value = "";
							}}
						>
							<Typography
								sx={{ whiteSpace: "nowrap", marginLeft: "10px" }}
							>
								Clear all
							</Typography>
						</Button>
					)}
				<Box
					sx={{
						flexGrow: 1,
						display: "flex",
						justifyContent: "right",
					}}
				>
					<DropDownCustom
						optList={allFleetDistinctedByModel && !allFleetDistinctedByModel?.isLoading ? allFleetDistinctedByModel?.data?.map(({
							model,
						}) => {
							return model;
						}) : []}
						values={filterModels}
						onChange={(values) => {
							setFilterModels(values);
						}}
						buttonProps={{
							sx: {
								border: "none",
								textTransform: "none",
								color: "black",
								"&:hover": {
									border: "none",
								},
							},
							variant: "outlined",
							startIcon: <SmallAcModleIcon color="#394458" />,
							textStr: "Aircraft Model",
						}}
					/>
					<DropDownCustom
						optList={allFleetDistinctedByEngine && !allFleetDistinctedByEngine?.isLoading ? allFleetDistinctedByEngine?.data?.map(({
							engineType,
						}) => {
							return engineType;
						}) : []}
						values={filterEnType}
						onChange={(values) => {
							setFilterEnType(values);
						}}
						buttonProps={{
							sx: {
								border: "none",
								textTransform: "none",
								color: "black",
								"&:hover": {
									border: "none",
								},
							},
							variant: "outlined",
							startIcon: <SmallAcEngineIcon color="#394458" />,
							textStr: "Aircraft Engine Type",
						}}
					/>
					<DropDownCustom
						optList={allFleets && !allFleets?.isLoading ? allFleets?.data?.map((node) => {
							return node?.regNo
						}) : []}
						onChange={(selecteds) => {
							// if (setFilterRegNos) {
							setFilterRegNos(selecteds);
							// }
						}}
						onSearch={(search) => {
							setSearchRegNo(search);
						}}
						values={filterRegNos}
						buttonProps={{
							sx: {
								border: "none",
								textTransform: "none",
								color: "black",
								"&:hover": {
									border: "none",
								},
							},
							variant: "outlined",
							startIcon: (
								<svg
									width="15"
									height="14"
									viewBox="0 0 15 14"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M7.27344 12.5L4.77344 13.5V11.5L5.77344 10.5V8.5L0.773438 9.5V7.5L5.77344 5V2C5.77344 1.60218 5.93147 1.22064 6.21278 0.93934C6.49408 0.658035 6.87561 0.5 7.27344 0.5C7.67126 0.5 8.05279 0.658035 8.3341 0.93934C8.6154 1.22064 8.77344 1.60218 8.77344 2V5L13.7734 7.5V9.5L8.77344 8.5V10.5L9.77344 11.5V13.5L7.27344 12.5Z"
										stroke="#394458"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</svg>
							),
							textStr: "Aircraft Reg.",
						}}
					/>
					<DateRangeCustom
						startVal={createdAtFrom}
						onChangeStartVal={setCreatedAtFrom}
						endVal={createdAtTo}
						onChangeEndVal={setCreatedAtTo}
					/>
				</Box>
			</Box>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "left",
					marginBottom: "1em",
				}}
			>
				<FilterChipsMain
					chipList={[
						...filterEnType?.map((value) => {
							return {
								label: value,
								value,
								onRemove: (value) => {
									const newFilter = [...filterEnType];
									const foundIndex = filterEnType?.findIndex(
										({ value: innerVal }) => {
											return innerVal === value;
										}
									);
									newFilter.splice(foundIndex, 1);
									setFilterEnType(newFilter);
								},
							};
						}),
						...filterModels?.map((value) => {
							return {
								label: value,
								value,
								onRemove: (value) => {
									const newFilter = [...filterModels];
									const foundIndex = filterModels?.findIndex(
										({ value: innerVal }) => {
											return innerVal === value;
										}
									);
									newFilter.splice(foundIndex, 1);
									setFilterModels(newFilter);
								},
							};
						}),
						...filterRegNos?.map((value) => {
							return {
								label: value,
								value,
								onRemove: (value) => {
									const newFilter = [...filterRegNos];
									const foundIndex = filterRegNos?.findIndex(
										({ value: innerVal }) => {
											return innerVal === value;
										}
									);
									newFilter.splice(foundIndex, 1);
									setFilterRegNos(newFilter);
								},
							};
						}),
						...searchChip,
						...filterDateStart,
						...filterDateEnd,
					]}
				/>
			</Box>
		</Box>
	);
}
