import React from 'react'

import ACBody from "./ACBody";
import ACTip from "./ACTip";
import ColNumber from "./ColNumber";
import Doors from "./Doors";
import HeadABC from "./HeadABC";
import HeadDEF from "./HeadDEF";
import HeadLeftToilet from "./HeadToilet";
import LeftWing from "./LeftWing";
import RightWing from "./RightWing";
import TailABC from "./TailABC";
import TailDEF from "./TailDEF";
import TailLine from "./TailLine";
import Windshield from "./Windshield";
import ToiletsBehind from './ToiletsBehind';

export default function A320NeoSkeletonMain() {
	return (
		<g>
			<TailLine />
			<RightWing />
			<LeftWing />
			<ACBody />
			<Windshield />
			<ColNumber />
			<ACTip />
			<HeadDEF />
			<TailABC />
			<HeadABC />
			<TailDEF />
			<ToiletsBehind />
			<HeadLeftToilet />
			<Doors />
		</g>
	)
}