import React from 'react'
import CabinApp from "./js/app/CabinApp";

export default function CabinMonitoring() {
	let paddingRight = "30px";

	return (
		<div
			id="main"
			style={{
				paddingRight: `${paddingRight}`,
				transition: "0.5s",
				paddingLeft: "70px",
				// marginTop:'-30px',
				paddingTop: '10px',
				background: "rgb(255, 255, 255)",
				height: '100%',
				minHeight: "764px",
				position: 'relative',
			}}
		>
			<CabinApp />
		</div>
	);
}