
import React from 'react';

import { Box, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import RightDrawer from "../../../components/modals/RightDrawer";
import LocationTab from "./LocationTab";
// import HistoryTab from "./HistoryTab";
import TabPanel from "./TabPanel";
import LocationHistoryTab from './LocationHistoryTab';

/**
 * @typedef {Object} Inventory
 * @property {string} id
 * @property {string} expiryDate
 * @property {string} manufactureDate
 * @property {string} tag
 * @property {string} serialNo
 * @property {string} expiryDate
 * @property {string} manufactureDate
 */
/**
 * @typedef {Object} Location
 * @property {string} id
 * @property {string} seatNo
 */

/**
 * @typedef {Object} LocationDetailViewMain
 * @property {string} itemCode
 * @property {string} itemName
 * @property {Inventory} inventory
 * @property {Location} location
 * @property {Function} open
 * @property {Function} setClose
 * @property {Function} setDrawerItem
 * @property {Function} refetchCB
 */
/**
 * 
 * @param {LocationDetailViewMain} props 
 * @returns 
 */
export default function LocationDetailViewMain({
	// title,
	itemCode,
	itemName,
	open,
	setClose,
	inventory,
	location,
	refetch,
	setDrawerItem,
}) {
	const [tabPost, setTabPost] = useState(0);
	const itemStyle = {
		paddingTop: "0.5em",
	};
	const handleChange = (event, newValue) => {
		setTabPost(newValue);
	};
	return (
		<RightDrawer open={open} title={`${itemCode} - ${itemName}`} setClose={setClose}>
			<Box
				sx={{
					height: "94%",
					// paddingTop: "0.5em",
				}}
			>
				<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
					<Tabs value={tabPost} onChange={handleChange}>
						<Tab label="Equipment Details" />
						<Tab label="History" />
					</Tabs>
				</Box>
				<TabPanel value={tabPost} index={0}>
					<LocationTab
						itemStyle={itemStyle}
						// inFleetLocation={inFleetLocation}
						inventory={inventory}
						location={location}
						refetch={refetch}
						setDrawerItem={setDrawerItem}
						setClose={setClose}
					/>
				</TabPanel>
				<TabPanel value={tabPost} index={1}>
					<LocationHistoryTab
						// inFleetLocation={inFleetLocation}
						// inventory={inventory}
						location={location}
					/>
				</TabPanel>
			</Box>
		</RightDrawer>
	);
}
