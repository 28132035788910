import {
  Grid,
  Table,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import styles from "./Inbox.module.css";
import { makeAPIRequestRun } from "common/helpers";
import { useSelector } from "react-redux";
import {
  formatDate,
  ConvertLocaleUTCDatetime,
  setAOCBasedOnPermission,
} from "../component/Helpers";
import MUIPagination from "../component/Pagination";
import DataUnavailable from "common/images/icons/elevade/Data-unavailable.svg";
import IconClose from "common/images/icons/elevade/Close-Icon.svg";
import Sorting from "../component/Sorting";
import { subMinutes } from "date-fns";
import { Stack } from "@mui/system";
import AirplaneLoader from "../component/AirplaneLoader";

const InboxAcms = ({
  localTime,
  isACReg,
  setIsACReg,
  isSearchInput,
  isOperator,
  startDate,
  endDate,
  isFaultPriority,
  setListFaultPriority,
  isLoading,
  setIsLoading,
  vertical,
}) => {
  const { profile: userProfile } = useSelector((state) => state.user);
  const { company: companyInfo } = useSelector((state) => state.company);
  const { companyAOC, userAOC, userPermission } = useSelector(
    (state) => state.userEntity
  );
  // let AOC = companyInfo?.permission?.elevadeAOC;
  let AOC = companyAOC;
  // const [checkAOC, setCheckAOC] = useState(setAOCBasedOnPermission(AOC, userPermission));
  // const UserAOC = userProfile?.permission?.elevadeAOC;
  // const UserAOC = userAOC;
  // if (UserAOC && UserAOC !== null && UserAOC !== undefined && UserAOC?.length > 0) AOC = UserAOC;
  // AOC = checkAOC;
  const [Inbox, setInbox] = useState([]);
  const [isInbox, setIsInbox] = useState(Inbox);
  const [perPage, setPerPage] = useState(50);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPage] = useState(0);
  const [tab, setTab] = useState(1);
  const [sortingColumn, setSortingColumn] = useState("event_datetime");
  const [sortingDirection, setSortingDirection] = useState("desc");
  const [reportData, setReportData] = useState();
  const [open, setOpen] = useState(0);
  const [id, setId] = useState(0);
  const [height, setHeight] = useState(
    document.getElementById("data-container")?.clientHeight
  );

  useEffect(() => {
    // if (isLoading) setOpen(false);
  }, [isLoading]);

  const resizeObserver = new ResizeObserver((entries) => {
    for (const entry of entries) {
      if (entry.contentRect) {
        setHeight(entry.contentRect.height);
      }
    }
  });

  let sliceStart = page > 0 ? perPage * (page - 1) : 0;
  if (sliceStart > totalRows) sliceStart = Math.floor(totalRows / perPage);

  let searchQuery = "null";
  if (isOperator !== "All" && isOperator.length > 0)
    searchQuery += `operator=${isOperator}|`;
  if (isFaultPriority !== "All" && isFaultPriority.length > 0)
    searchQuery += `priority=${isFaultPriority}|`;
  if (isACReg !== "All" && isACReg.length > 0)
    searchQuery += `ac_reg=${isACReg}|`;
  if (isSearchInput !== undefined && isSearchInput !== "")
    searchQuery += `search=${isSearchInput}|`;
  if (startDate !== undefined && endDate !== undefined) {
    const start = localTime
      ? subMinutes(
          new Date(startDate),
          -new Date(startDate).getTimezoneOffset()
        )
      : startDate;
    const end = localTime
      ? subMinutes(new Date(endDate), -new Date(endDate).getTimezoneOffset())
      : endDate;
    searchQuery += `event_datetime=${ConvertLocaleUTCDatetime(
      start
    )}~${ConvertLocaleUTCDatetime(end, "end")}|`;
  }
  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    resizeObserver?.observe(document.getElementById("data-container"));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // setOpen(false);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [page]);

  useEffect(() => {
    if (open && !vertical) {
      const element = document.getElementById(open);
      let position = element?.getBoundingClientRect();
      window.scrollTo({
        top: position?.top + window.scrollY - 74,
        behavior: "smooth",
      });
    }
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    setIsLoading(true);
    // setOpen(false);
    // eslint-disable-next-line
  }, [isOperator, isFaultPriority, isACReg, startDate, endDate]);

  useEffect(() => {
    setPage(1);
  }, [
    isSearchInput,
    isOperator,
    isFaultPriority,
    isACReg,
    searchQuery,
    sortingColumn,
    sortingDirection,
  ]);

  useEffect(() => {
    async function fetchData() {
      const fetchInbox = makeAPIRequestRun(
        `get`,
        `acarsdata/acarsinbox/${AOC}/${perPage}/${sliceStart}/${encodeURIComponent(
          searchQuery
        )}/acms/${sortingColumn}/${sortingDirection}`
      );
      fetchInbox
        .then((response) => {
          setInbox(response);
          setIsInbox(response);
          setReportData(response[0]);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    async function fetchTotalRows() {
      const fetchData = makeAPIRequestRun(
        "get",
        `acarsdata/acarsinboxtotalrows/${AOC}/${perPage}/${sliceStart}/${encodeURIComponent(
          searchQuery
        )}/acms`
      );
      fetchData
        .then((response) => {
          setTotalRows(response[0]?.Total);
          setTotalPage(Math.floor(response[0]?.Total / perPage) + 1);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    setIsLoading(true);
    // setOpen(false);
    fetchData();
    fetchTotalRows();
    // eslint-disable-next-line
  }, [
    AOC,
    searchQuery,
    sliceStart,
    page,
    perPage,
    sortingColumn,
    sortingDirection,
  ]);

  useEffect(() => {
    async function fetchDistinctValue(col, tbl) {
      const fetchData = makeAPIRequestRun(
        "get",
        `acarsdata/getdistinctvalue/${AOC}/${searchQuery}/${col}/${tbl}/oooi`
      );
      fetchData
        .then((response) => {
          if (col === "priority") setListFaultPriority(response);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    fetchDistinctValue("msg_type", "acars_inbox");
    // eslint-disable-next-line
  }, []);

  // Custom styles
  const useStyles = makeStyles(() => ({
    ul: {
      "& .Mui-selected": {
        color: "#fff",
        backgroundColor: "#088FD1",
        border: "1px solid #088FD1",
        "&:hover": {
          backgroundColor: "#088FD1",
        },
      },
    },
    messageTootltip: {
      background: "#fffac3 0% 0% no-repeat padding-box",
      color: "#333333",
      boxShadow: "0px 3px 6px #00000040",
    },
  }));

  const classes = useStyles();

  const head = [
    // { title: "Priority", col: "", width: 1 },
    { title: "A/C Reg.", col: "ac_reg", width: 2 },
    { title: "Report No.", col: "report_type", width: 2 },
    { title: "Report Title", col: "msg", width: vertical ? 4 : 4 },
    { title: "Trigger Code", col: "code", width: 2.2 },
    { title: "Flight No.", col: "flight_no", width: 1.8 },
    { title: "Generation Time", col: "ac_reg", width: 4 },
    { title: "Sent Time", col: "time", width: 5 },
  ];

  const DisplayDataHeader = () => {
    return (
      <TableHead>
        <TableRow>
          <Grid
            className={styles.displayHeader}
            style={{ marginRight: "0px", marginLeft: "0px", width: "100%" }}
          >
            <Stack direction="row" spacing={5} justifyContent="flex-start">
              {head.map((item) => {
                return (
                  <Grid item xs={item.width} className={styles.displayHead}>
                    {item.col ? (
                      <Sorting
                        title={item.title}
                        col={item.col}
                        sortingColumn={sortingColumn}
                        setSortingColumn={setSortingColumn}
                        sortingDirection={sortingDirection}
                        setSortingDirection={setSortingDirection}
                      />
                    ) : (
                      item.title
                    )}
                  </Grid>
                );
              })}
            </Stack>
          </Grid>
        </TableRow>
      </TableHead>
    );
  };

  const NoDataAvailable = () => {
    const [show, setShow] = useState(false);
    useEffect(() => {
      const timeoutId = setTimeout(() => {
        setShow(true);
      }, 1000);

      return () => clearTimeout(timeoutId);
    }, []);
    return show ? (
      <>
        <div
          style={{ marginTop: "5%", marginBottom: "10%", textAlign: "center" }}
        >
          <div style={{ marginBottom: "16px" }}>
            <img src={DataUnavailable} alt="data-unavailable" />
          </div>
          <div style={{ fontFamily: "Gotham-Medium", fontSize: "14px" }}>
            Data Unavailable
          </div>
          <div style={{ fontFamily: "Gotham-Book", fontSize: "12px" }}>
            There is no data found. Please try a different search term.
          </div>
        </div>
      </>
    ) : (
      ""
    );
  };

  const DisplayData = () => {
    return isInbox.length > 0 ? (
      isInbox?.map((item, index) => {
        return (
          <Grid style={{ marginRight: "0px" }}>
            <Stack
              className={styles.dataItemsInbox}
              onClick={() => {
                setReportData(item);
                setOpen(index);
                setId(index);
              }}
              id={index}
              style={{
                background:
                  reportData === item
                    ? "#f2f7ff 0% 0% no-repeat padding-box"
                    : "",
              }}
              spacing={5}
              justifyContent="flex-start"
              fullWidth
            >
              <Stack direction="row" spacing={5} justifyContent="flex-start">
                {/* <Grid item xs={1} className={`${styles.displayData}`}>
                  {getACMS(item.acms_priority)}
                </Grid> */}
                <Grid item xs={2} className={`${styles.displayData}`}>
                  {item.ac_reg}
                </Grid>
                <Grid item xs={2} className={`${styles.displayData}`}>
                  {item?.msg?.split(" - ")[0]}
                </Grid>
                <Grid
                  item
                  xs={vertical ? 4 : 4}
                  className={`${styles.displayData}`}
                  sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
                >
                  {item?.msg?.split("-")[1] || item.msg_type}
                </Grid>
                <Grid item xs={2.2} className={`${styles.displayData}`}>
                  {item.code}
                </Grid>
                <Grid item xs={1.8} className={`${styles.displayData}`}>
                  {item.flight_no}
                </Grid>
                <Grid item xs={4} className={`${styles.displayData}`}>
                  {formatDate(item.gen_datetime?.value, "", localTime)}
                </Grid>
                <Grid item xs={5} className={`${styles.displayData}`}>
                  {formatDate(item.sent_datetime?.value, "", localTime)}
                </Grid>
              </Stack>
            </Stack>
            {!vertical && <MessageDetails open={open} id={index} />}
          </Grid>
        );
      })
    ) : !isLoading && isInbox?.length === 0 ? (
      <NoDataAvailable />
    ) : (
      ""
    );
  };

  const MessageDetails = ({ open, id }) => {
    return (
      <>
        {vertical && (
          <div
            className={`row ${styles.displayHeader}`}
            style={{
              marginLeft: "0px",
              marginRight: "0px",
              borderTopLeftRadius: "0px",
            }}
          >
            <Grid className={`${styles.displayHeaderLabel}`}>
              <Grid>Report</Grid>
            </Grid>
          </div>
        )}
        {reportData && ((vertical && open) || open === id) ? (
          <Grid
            className={
              !vertical &&
              (styles.displayMsgDetails, styles.openDisplayMsgDetails)
            }
            style={{
              overflowY: "auto",
              backgroundColor: "#FFFFFF",
              whiteSpace: tab === 1 ? "pre-wrap" : "wrap",
              height: vertical ? height : "200px",
              border: vertical ? "1px solid #e8e8e8" : "1px solid #088FD1",
            }}
          >
            <Grid>
              <Grid
                container
                columns={4}
                sx={{
                  borderBottom: "1px solid #D1D1D1",
                  pt: "9px",
                  pl: "15px",
                  pr: "15px",
                  position: "sticky",
                  right: 0,
                  top: 0,
                  backgroundColor: "#FFFFFF",
                  height: vertical ?? "",
                }}
              >
                {/* <Grid
                  item
                  xs={1}
                  className={`${styles.msgHeaderTab} ${tab === 1 ? styles.active : ""}`}
                  onClick={() => setTab(1)}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  Report Details
                </Grid>
                <Grid
                  item
                  xs={1}
                  className={`${styles.msgHeaderTab} ${tab === 2 ? styles.active : ""}`}
                  onClick={() => setTab(2)}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  Raw Data
                </Grid> */}
                {/* <Grid item xs={2} style={{ float: "right" }} className={styles.msgHeaderTab}>
                  {!vertical && (
                    <img
                      src={CloseIcon}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setOpen(false);
                      }}
                      alt="close"
                    />
                  )}
                </Grid> */}
                <Grid item xs={4}>
                  <div
                    style={{
                      display: "flex",
                      borderBottom: "1px solid #D1D1D1",
                    }}
                  >
                    <div
                      className={`${tab === 1 ? styles.active : ""}`}
                      style={{ padding: "8px 0px", cursor: "pointer" }}
                      onClick={() => setTab(1)}
                    >
                      Report Details
                    </div>
                    <div
                      className={`${tab === 2 ? styles.active : ""}`}
                      style={{
                        padding: "8px 0px",
                        cursor: "pointer",
                        marginLeft: "24px",
                      }}
                      onClick={() => setTab(2)}
                    >
                      Raw
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    padding: "30px 20px",
                    position: "relative",
                    paddingTop: 10,
                    wordWrap: "break-word",
                    width: "1000px",
                  }}
                >
                  <img
                    src={IconClose}
                    alt="close-icon"
                    style={{
                      position: "absolute",
                      top: 30,
                      right: 10,
                      cursor: "pointer",
                    }}
                    onClick={() => setOpen(false)}
                  />
                  {tab === 1 ? (
                    <center>
                      <span>{reportData?.raw_msg}</span>
                    </center>
                  ) : (
                    <center>
                      <span>{reportData?.raw_msg}</span>
                    </center>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          !isLoading &&
          isInbox.length > 0 &&
          vertical &&
          !open && (
            <Grid
              style={{
                backgroundColor: "#FFFFFF",
                height: height,
                minHeight: "741px",
                border: "1px solid #e8e8e8",
              }}
            >
              <center style={{ paddingTop: "10px" }}>
                No ACMS Report Selected
              </center>
            </Grid>
          )
        )}
      </>
    );
  };
  return (
    <>
      <Grid
        container
        columns={vertical && !isLoading && isInbox?.length > 0 ? 2 : 1}
        sx={{ marginLeft: "20px" }}
      >
        <Grid
          item
          xs={vertical && !isLoading && isInbox?.length > 0 ? 1.2 : 1}
          sx={{ overflowX: vertical ? "scroll" : "visible", marginLeft: "0px" }}
          className={styles.wrapper}
        >
          <TableContainer
            sx={{
              overflowY: "hidden",
              overflowX: vertical ? "scroll" : "visible",
            }}
          >
            <Table>
              <DisplayDataHeader />
              <Grid
                id="data-container"
                sx={{
                  minHeight: vertical ? "741px" : "",
                  backgroundColor: "#FFFFFF",
                }}
              >
                <DisplayData />
              </Grid>
            </Table>
          </TableContainer>
        </Grid>
        {vertical && !isLoading && isInbox?.length > 0 && (
          <Grid item xs={0.8} style={{ height: height }}>
            <MessageDetails open={open} id={id} />
          </Grid>
        )}
      </Grid>
      {isInbox?.length !== 0 ? (
        <MUIPagination
          page={page}
          setPage={setPage}
          setPerPage={setPerPage}
          totalPage={totalPages}
          classes={{ ul: classes.ul }}
          totalRows={totalRows}
          sliceStart={sliceStart}
        />
      ) : (
        ""
      )}
      {isLoading && <AirplaneLoader />}
    </>
  );
};

export default InboxAcms;
