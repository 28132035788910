import React from "react";
import { Grid } from "@mui/material";
import styles from "../aircraftReport/DDML.module.css";
import { formatDateDasr, formatTimeLocal } from "pages/elevade/component/Helpers";

const DisplaySummary = ({ ...props }) => {
  function getSummary(category) {
    let ddmlLogs = props.DDMLLogs?.data?.filter((e) => e?.pushToSummary === true && e?.updateLogCategory === category);
    let latest = ddmlLogs?.at(-1);
    return latest?.updateLogDesc;
  }

  function getLimitations(aircraftReg, data) {
    let filteredData = data.filter((e) => e.aircraftReg === aircraftReg);
    if (filteredData.length > 0) {
      let arrayOfLimitations = [];
      filteredData.forEach((e) => {
        arrayOfLimitations.push(e.limitationsDesc);
      });
      let limitationList = [
        ...new Set(
          arrayOfLimitations &&
            arrayOfLimitations?.map((item) => {
              return item.toUpperCase();
            })
        ),
      ].sort();
      return limitationList;
    } else return null;
  }

  function formatArray(array) {
    return (
      array !== null &&
      // <ul className={styles.ul}>
      array.map((item, index) => (
        <div className={styles.li} key={index}>
          {index + 1}. {item}
        </div>
      ))
      // </ul>
    );
  }

  return (
    <div
      style={{
        overflow: "auto",
        padding: "0px 24px 8px 24px",
        zIndex: "3",
      }}
    >
      <Grid container columns={3}>
        <Grid item xs={1}>
          <div className={styles.titleSummary}>Aircraft Registration</div>
          <div>{props.data[0].aircraftReg}</div>
        </Grid>
        <Grid item xs={1}>
          <div className={styles.titleSummary}>Maintenance Location</div>
          <div>{props.data[0].location}</div>
        </Grid>
        <Grid item xs={1}>
          <div className={styles.titleSummary}>Start Date</div>
          <div style={{ textTransform: "uppercase" }}>{formatDateDasr(props.data[0].inputDate)}</div>
        </Grid>
        <Grid item xs={1}>
          <div className={styles.titleSummary}>ETS Date</div>
          <div style={{ textTransform: "uppercase" }}>{formatDateDasr(props.data[0].ets)}</div>
        </Grid>
        <Grid item xs={2}>
          <div className={styles.titleSummary}>ETS Time</div>
          <div style={{ textTransform: "uppercase" }}>{formatTimeLocal(props.data[0].etsTime)}</div>
        </Grid>
        <Grid item xs={3}>
          <div className={styles.titleSummary}>Details</div>
          <div className={styles.textarea}>{props.data[0].defectDesc || "-"}</div>
        </Grid>
        {props.data[0].formType !== "Standby" && (
          <>
            <Grid item xs={3}>
              <div className={styles.titleSummary}>Rectification Status</div>
              <div className={styles.textarea}>
                {getSummary("Rectification Update") || props.data[0].rectificationStatus || "-"}
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className={styles.titleSummary}>Material/Tool Update Summary</div>
              <div className={styles.textarea}>{getSummary("Material/Tools Update") || "-"}</div>
            </Grid>
            <Grid item xs={3}>
              <div className={styles.titleSummary}>Planning/Tech Service Summary</div>
              <div className={styles.textarea}>{getSummary("PPC") || "-"}</div>
            </Grid>
            <Grid item xs={3}>
              <div className={styles.titleSummary}>Remarks (MOC Highlight)</div>
              <div className={styles.textarea}>{props.data[0].mocHighlight || "-"}</div>
            </Grid>
          </>
        )}
        {props.data[0].formType === "Standby" && (
          <Grid item xs={3}>
            <div className={styles.titleSummary}>Limitations</div>
            <div className={styles.textarea}>
              {formatArray(getLimitations(props.data[0].aircraftReg, props.limitationData)) || "-"}
            </div>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default DisplaySummary;
