import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Grid, styled, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import styles from "../Dasr.module.css";
import IconBackArrow from "common/images/icons/elevade/Icon-BackArrow.svg";
import IconPlusCircleWhite from "common/images/icons/elevade/Icon-PlusCircleWhite.svg";
import { makeAPIRequestMDB } from "common/helpers/makeAPIRequestMDB";
import Limitation from "./Limitation";
import AircraftStatusMaintenance from "./AircraftStatusMaintenance";
import ElevadeDrawer from "../component/Drawer";
import DASRForm from "../component/DASRForm";
import { cloneDeep } from "lodash";
import AirplaneLoader from "pages/elevade/component/AirplaneLoader";
import Button from "pages/elevade/component/Button";
import { Helmet } from "react-helmet";

const AircraftStatus = ({ aoc, setIsLoading }) => {
  const [isLoadingSub, setIsLoadingSub] = useState(true);
  const [data, setData] = useState();
  const [value, setValue] = useState("1");
  const [open, setOpen] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [formType, setFormType] = useState();
  const [newForm, setNewForm] = useState(true);
  const [limitationData, setLimitationData] = useState([]);
  const [triggerApi, setTriggerApi] = useState(true);
  const [dataDDML, setDataDDML] = useState();
  const [aircraftList, setAircraftList] = useState([]);
  const location = useLocation();

  useEffect(() => {
    if (location.search) {
      setValue(location.search.split("=")[1]);
    }
  }, [location]);

  useEffect(() => {
    if (Array.isArray(aoc)) {
      const newAOC = cloneDeep(aoc);
      const index = newAOC?.indexOf("ADE");
      // TODO: to check back on this later
      // eslint-disable-next-line no-unused-expressions
      if (index !== -1) newAOC?.splice(index, 1);
      aoc = newAOC;
    }
    // eslint-disable-next-line
  }, [aoc]);

  useEffect(() => {
    if (value === "2") setFormType("Maintenance");
    else if (value === "3") setFormType("AOG");
    else if (value === "4") setFormType("Standby");
    else if (value === "5") setFormType("Storage");
  }, [value]);

  useEffect(() => {
    fetchAPIRequest();
    fetchReport();
    fetchAircraftList();
    // eslint-disable-next-line
  }, [aoc, triggerApi]);

  var currentDate = new Date();
  var options = { month: "long", day: "2-digit", year: "numeric" };
  var formattedDate = currentDate.toLocaleDateString("en-US", options);
  currentDate = new Date(formattedDate);
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  const isToday = `${year}-${month}-${day}`;

  function fetchAPIRequest() {
    if (isToday !== "NaN-NaN-NaN" && aoc !== undefined && aoc !== "undefined") {
      FetchData();
      fetchLimitationData();
    }
  }

  async function FetchData() {
    const fetch = makeAPIRequestMDB(`get`, `api/aircraft/daily-status?aoc=${aoc}&date=${isToday}`);
    fetch
      .then((response) => {
        setData(response?.data?.sort((a, b) => a.aircraftReg.localeCompare(b.aircraftReg)));
        setIsLoadingSub(false);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  async function fetchLimitationData() {
    const fetch = makeAPIRequestMDB(`get`, `api/ddml/limitations?aoc=${aoc}&form_status=open`);
    fetch
      .then((response) => {
        setLimitationData(response?.data?.ddmls_list);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  async function fetchReport() {
    const fetch = makeAPIRequestMDB(`get`, `api/aircraft/updated-status?aoc=${aoc}&form_status=open`);
    fetch
      .then((response) => {
        setDataDDML(response.data.data_list);
        setTriggerApi(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  async function fetchAircraftList() {
    var currentDate = new Date();
    var options = { month: "long", day: "2-digit", year: "numeric" };
    var formattedDate = currentDate.toLocaleDateString("en-US", options);
    currentDate = new Date(formattedDate);
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const isToday = `${year}-${month}-${day}`;
    if (aoc !== undefined) {
      const fetch = makeAPIRequestMDB(`get`, `api/aircraft/daily-status?aoc=${aoc}&date=${isToday}`);
      fetch
        .then((response) => {
          let theList = [];
          let list = response?.data?.sort((a, b) => a.aircraftReg.localeCompare(b.aircraftReg));
          // TODO: to check back on this later
          // eslint-disable-next-line no-unused-expressions
          list?.map((e) => {
            return theList.push({ id: e.aircraftReg, label: e.aircraftReg, name: e.aircraftReg });
          });
          setAircraftList(theList);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  }

  useEffect(() => {
    if (triggerApi) {
      fetchReport();
      fetchAPIRequest();
    }
    // eslint-disable-next-line
  }, [triggerApi]);

  useEffect(() => {
    fetchAircraftList();
    // eslint-disable-next-line
  }, []);

  const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: "rgba(0, 0, 0, 0.85)",
    fontFamily: ["Gotham-Book"].join(","),
    "&:hover": {
      color: "#333333",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#333333",
      fontFamily: "Gotham-Medium",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  }));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOnClick = (e) => {
    setNewForm(true);
    setOpen(!open);
  };

  const Tips = ({ val, unread }) => {
    return (
      <>
        <span
          style={{
            borderRadius: "30px",
            color: unread ? "#ffffff" : "#838383",
            border: unread ? "1px solid #E95048" : "1px solid #E8E8E8",
            backgroundColor: unread ? "#E95048" : "#F7F7F7",
            fontFamily: "Gotham-Book",
            fontSize: "12px",
            marginLeft: "8px",
            padding: "4px 8px 2px 8px",
          }}
        >
          {val}
        </span>
      </>
    );
  };

  function calcTipsCount(data, type) {
    if (type === "") return data?.filter((e) => e.limitations === true && e.formStatus === "open").length;
    let data_ = data?.filter((e) => e.formType === type);
    return data_?.length;
  }

  return (
    <>
      <Helmet>
        <title>Royal Brunei Airlines - ELEVADE - Maintenance Status</title>
      </Helmet>
      {isLoadingSub && <AirplaneLoader />}
      {!isLoadingSub && (
        <div id="main" className={`${styles.container}`}>
          <>
            <Grid container columns={8.2} className={styles.gridContainer}>
              <Grid item xs={4.2} className={styles.gridTitle} onClick={() => setOpenModal(false)}>
                <Link to={"/fleet/dasr"}>
                  <img src={IconBackArrow} alt="icon-back" style={{ marginRight: "14px", marginTop: "-4px" }} />
                  {aoc} Maintenance Status
                </Link>
              </Grid>
              <Grid
                item
                xs={4}
                className={styles.gridJustifyEnd}
                sx={{ fontSize: "14px", paddingBottom: "32px" }}
                onClick={() => {
                  setOpen(false);
                  setOpenModal(false);
                }}
              >
                {/* <Export /> */}
                {value !== "1" && (
                  // <NewReport title={"Add Aircraft"} icon={IconPlusCircleWhite} onClick={handleOnClick} />
                  <Button
                    title="Add Aircraft"
                    icon={IconPlusCircleWhite}
                    iconWhite={IconPlusCircleWhite}
                    iconDisabled={IconPlusCircleWhite}
                    variant={"disabled"} // TODO: change to "fill" when ready
                    onClick={handleOnClick}
                  />
                )}
              </Grid>
              <Grid item xs={8.2}>
                <TabContext value={value}>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                    onClick={() => setOpenModal(false)}
                  >
                    <AntTab
                      label="Limitation"
                      value="1"
                      icon={
                        <Tips
                          val={calcTipsCount(limitationData, "")}
                          unread={calcTipsCount(limitationData, "") > 0 ? false : false}
                        />
                      }
                      iconPosition="end"
                    />
                    <AntTab
                      label="Maintenance"
                      value="2"
                      icon={
                        <Tips
                          val={calcTipsCount(dataDDML, "Maintenance")}
                          unread={calcTipsCount(dataDDML, "Maintenance") > 0 ? false : false}
                        />
                      }
                      iconPosition="end"
                    />
                    <AntTab
                      label="AOG"
                      value="3"
                      icon={
                        <Tips
                          val={calcTipsCount(dataDDML, "AOG")}
                          unread={calcTipsCount(dataDDML, "AOG") > 0 ? false : false}
                        />
                      }
                      iconPosition="end"
                    />
                    <AntTab
                      label="Standby"
                      value="4"
                      icon={
                        <Tips
                          val={calcTipsCount(dataDDML, "Standby")}
                          unread={calcTipsCount(dataDDML, "Standby") > 0 ? false : false}
                        />
                      }
                      iconPosition="end"
                    />
                    <AntTab
                      label="Storage"
                      value="5"
                      icon={
                        <Tips
                          val={calcTipsCount(dataDDML, "Storage")}
                          unread={calcTipsCount(dataDDML, "Storage") > 0 ? false : false}
                        />
                      }
                      iconPosition="end"
                    />
                  </TabList>
                  <div style={{ width: "100%", height: "2px", backgroundColor: "#d1d1d1", marginTop: "-2px" }}></div>
                  <div style={{ marginLeft: "-20px", marginRight: "-20px", marginTop: "-24px" }}>
                    <TabPanel value="1">
                      <Limitation
                        aoc={aoc}
                        tab={value}
                        data={limitationData}
                        openModal={openModal}
                        setOpenModal={setOpenModal}
                        triggerApi={triggerApi}
                        setTriggerApi={setTriggerApi}
                      />
                    </TabPanel>
                    <TabPanel value="2">
                      <AircraftStatusMaintenance
                        setNewForm={setNewForm}
                        newForm={newForm}
                        formType={formType}
                        aoc={aoc}
                        setTriggerApi={setTriggerApi}
                        dataDDML={dataDDML}
                        aircraftList={aircraftList}
                        tab={value}
                        openModal={openModal}
                        setOpenModal={setOpenModal}
                      />
                    </TabPanel>
                    <TabPanel value="3">
                      <AircraftStatusMaintenance
                        setNewForm={setNewForm}
                        newForm={newForm}
                        formType={formType}
                        aoc={aoc}
                        setTriggerApi={setTriggerApi}
                        dataDDML={dataDDML}
                        aircraftList={aircraftList}
                        tab={value}
                        openModal={openModal}
                        setOpenModal={setOpenModal}
                      />
                    </TabPanel>
                    <TabPanel value="4">
                      <AircraftStatusMaintenance
                        setNewForm={setNewForm}
                        newForm={newForm}
                        formType={formType}
                        aoc={aoc}
                        setTriggerApi={setTriggerApi}
                        dataDDML={dataDDML}
                        aircraftList={aircraftList}
                        tab={value}
                        limitationData={limitationData}
                        openModal={openModal}
                        setOpenModal={setOpenModal}
                      />
                    </TabPanel>
                    <TabPanel value="5">
                      <AircraftStatusMaintenance
                        setNewForm={setNewForm}
                        newForm={newForm}
                        formType={formType}
                        aoc={aoc}
                        setTriggerApi={setTriggerApi}
                        dataDDML={dataDDML}
                        aircraftList={aircraftList}
                        tab={value}
                        limitationData={limitationData}
                        openModal={openModal}
                        setOpenModal={setOpenModal}
                      />
                    </TabPanel>
                  </div>
                </TabContext>
              </Grid>
            </Grid>
          </>
        </div>
      )}
      <ElevadeDrawer
        open={open}
        setOpen={setOpen}
        data={data}
        Component={DASRForm}
        newForm={newForm}
        formType={formType}
        aoc={aoc}
        setTriggerApi={setTriggerApi}
        dataDDML={dataDDML}
      />
    </>
  );
};

export default AircraftStatus;
