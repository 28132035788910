
/**
query allinvoCode {
	allInventoryLocations {
		edges {
		node {
			code
			name
		}
		}
	}
}
*/

const commonCategories = [
	{
		code: "PS",
		name: "Passenger Seat"
	},
]

/**
 * @type {InventoryLocation[]} generalizedCategories
 */
export const generalizedCategories = [
	{
		code: "PS",
		name: "Passenger Seat"
	},
	{
		code: 'ETC',
		name: 'Others'
	}
]

/**
 * @type {InventoryLocation[]} inventoryLocationCategoriesA320Ceo
 */
export const inventoryLocationCategoriesA320Ceo = [
	{
		code: "PS",
		name: "Passenger Seat"
	},
	{
		code: "OHB-DEF",
		name: "OHB Row 01DEF"
	},
	{
		code: "BLR-DEF",
		name: "Behind Last Row 31DEF"
	},
	{
		code: "ZODIAC",
		name: "ZODIAC"
	},
	{
		code: "ZODIAC(AFT)",
		name: "ZODIAC(AFT)"
	},
	{
		code: "FD",
		name: "Flight Deck"
	},
	{
		code: "FWD",
		name: "FWD Galley"
	},
	{
		code: "OHB-ABC",
		name: "OHB Row 01ABC"
	},
	{
		code: "BLR-ABC",
		name: "Behind Last Row 31ABC"
	},
	{
		code: "AFT",
		name: "AFT Galley"
	},
	{
		code: "1L",
		name: "1L ATT.STN"
	},
	{
		code: "2L",
		name: "2L ATT.STN"
	},
	{
		code: "2R",
		name: "2R ATT.Swivel Seat"
	},
	{
		code: "B2L",
		name: "Behind 2L ATT.STN"
	}
];

export const inventoryLocationCategoriesA320Neo = [
	{
		code: "PS",
		name: "Passenger Seat"
	},
	{
		code: "FD",
		name: "Flight Deck"
	},
	{
		code: "OHB-DEF",
		name: "OHB Row 01DEF"
	},
	{
		code: "BLR-DEF",
		name: "Behind Last Row 31DEF"
	},
	{
		code: "ZODIAC",
		name: "ZODIAC"
	},
	{
		code: "ZODIAC(AFT)",
		name: "ZODIAC(AFT)"
	},
	{
		code: "FWD",
		name: "FWD Galley"
	},
	{
		code: "OHB-ABC",
		name: "OHB Row 01ABC"
	},
	{
		code: "BLR-ABC",
		name: "Behind Last Row 31ABC"
	},
	{
		code: "AFT",
		name: "AFT Galley"
	},
	{
		code: "1L",
		name: "1L ATT.STN"
	},
	{
		code: "2L",
		name: "2L ATT.STN"
	},
	{
		code: "2R",
		name: "2R ATT.Swivel Seat"
	},
	{
		code: "B2L",
		name: "Behind 2L ATT.STN"
	}
];

/**
 * generalCategorizer
 * @param {InventoryLocation} categories 
 * @param {any[]} inventoryLocationsByApi 
 * @param {Function} funcCBReturnItemTypeCode
 * @param {boolean} [isGeneralized]
 * @returns 
 */
export function generalCategorizer(categories, inventoryLocationsByApi, funcCBReturnItemTypeCode, isGeneralized) {
	const categorized = {};
	if (isGeneralized) {
		generalizedCategories?.forEach(({ code }) => {
			const existed = Object.keys(categorized);
			if (!existed.includes(code)) {
				categorized[code] = [];
			}
		});
		inventoryLocationsByApi?.map((item) => {
			const innerInventoryCode = funcCBReturnItemTypeCode(item);
			if (innerInventoryCode?.toLowerCase() === 'ps') {
				categorized['PS']?.push(item);
			} else {
				categorized['ETC']?.push(item);
			}
		});
	} else {
		categories?.forEach(({ code }) => {
			const existed = Object.keys(categorized);
			if (!existed.includes(code)) {
				categorized[code] = [];
			}
		});
		inventoryLocationsByApi.map((item) => {
			const innerInventoryCode = funcCBReturnItemTypeCode(item);
			categorized[innerInventoryCode]?.push(item);
		});
	}

	// console.log({categorized, isGeneralized, categories, inventoryLocationsByApi})
	return categorized
}