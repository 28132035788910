import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { ClickAwayListener } from "@mui/base";

import { minimizeFleetMenu } from "./SidebarIconsList";
import FleetSidebarIcon from "./FleetSidebarIcon";
import FleetSidebarIconText from "./FleetSidebarIconText";

import styles from "./Sidebar.module.css";

const FleetSidebar = () => {
  const drawerWidth = 290;
  const drawerWidthClose = 52;
  const { sidebarToggle } = useSelector((state) => state.sidebarToggle);
  const [openMenu, setOpenMenu] = useState(false);

  function checkMouseEnter() {
    if (sidebarToggle !== true) {
      setOpenMenu(true);
    }
  }

  function checkMouseLeave() {
    if (sidebarToggle !== true) {
      setOpenMenu(false);
    }
  }

  const handleClickAway = () => {
    if (sidebarToggle) {
      setOpenMenu(true);
    } else {
      setOpenMenu(false);
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box
        /// mouse enter exit function causing the collapse to re-render making all states to default. need to revise?
        onMouseEnter={() => {
          checkMouseEnter();
        }}
        onMouseLeave={() => {
          checkMouseLeave();
        }}
        sx={{
          width: openMenu ? drawerWidth : drawerWidthClose,
          transition: "width 0.3s ease-in-out",
          backgroundColor: "#fff",
          height: "100%",
          left: "0px",
          position: "fixed",
          padding: "24px 8px",
          marginBottom: "16px",
          overflowY: "hidden",
          borderRight: "1px solid #F1F1F1",
          borderLeft: "1px solid #F1F1F1",
          borderTop: "0px",
        }}
        className={`${styles.onScrollbar} ${styles.macScrollbar}`}
      >
        {openMenu ? <FleetSidebarIconText /> : <FleetSidebarIcon />}
      </Box>
    </ClickAwayListener>
  );
};

export default FleetSidebar;
