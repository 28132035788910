import company from "common/store/reducers/company";
import Spinner from "layouts/Spinner";
import React from "react";
import { useSelector } from "react-redux";
import { Route, useHistory } from "react-router-dom";
import _ from "lodash";
import Cookies from "js-cookie";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const history = useHistory();
  const authState = useSelector((state) => state.auth);
  const { currentUser: userAuthEntity } = useSelector((state) => state.authEntity);
  const { companySubElevade, userSubElevade, userPermission } = useSelector((state) => state.userEntity);
  const entityCookies = Cookies.get("elevadeToken");
  // const userState = useSelector((state) => state.user);
  const product = rest.product;
  const feature = rest.feature;
  const isAdmin = rest.isAdmin;
  const path = rest.path;

  const { company: companyInfo } = useSelector((state) => state.company);

  const { profile: userProfile } = useSelector((state) => state.user);

  let redirectPath = `/login?target=${history.location.pathname}`;
  // let redirectPath = `/`;

  // check feature(s) permission available
  function featureCheck() {
    let checkPermission = false;
    switch (feature) {
      case "isAircraftMonitoring":
        // checkPermission = userPermission.some((permission) => permission.code === "ANA-001"); // original permission code
        checkPermission = userPermission.some((permission) => permission.code === "ACM_002"); //to be rectify
        return checkPermission;
      case "isAnalytics":
        // checkPermission = userPermission.some((permission) => permission.code === "ANA-001"); // original permission code
        checkPermission = userPermission.some((permission) => permission.code === "TEMP_ANALYTICS"); //to be rectify
        return checkPermission;
      case "isScheduledCheck":
        // checkPermission = userPermission.some((permission) => permission.code === "SCH-001"); // original permission code
        checkPermission = userPermission.some((permission) => permission.code === "TEMP_SCHEDULEDCHECK"); //to be rectify
        return checkPermission;
      case "isDASR":
        // checkPermission = userPermission.some((permission) => permission.code === "DASR-001"); // original permission code
        // checkPermission = userPermission.some((permission) => permission.code === "TEMP_DASR"); //to be rectify
        checkPermission = checkDASRpages();
        return checkPermission;
      case "isCabinMonitoring":
        checkPermission = userPermission.some((permission) => permission.code === "CABIN-001");
        return checkPermission;
      default:
        return checkPermission;
    }
  }

  // dasr page access permission view
  function checkDASRpages() {
    let viewPagePermission = false;
    switch (history.location.pathname) {
      case "/fleet/dasr":
        viewPagePermission = userPermission
          .filter((perm) => perm.code === "DASR-001")
          .some((perm) => perm.canRetrieve === true);
        return viewPagePermission;
      case "/fleet/dasr/aircraft-list":
        viewPagePermission = userPermission
          .filter((perm) => perm.code === "DASR-002")
          .some((perm) => perm.canRetrieve === true);
        return viewPagePermission;
      case "/fleet/dasr/aircraft-status":
        viewPagePermission = userPermission
          .filter((perm) => perm.code === "DASR-009")
          .some((perm) => perm.canRetrieve === true);
        return viewPagePermission;
      case "/fleet/dasr/aircraft-report":
        viewPagePermission = userPermission
          .filter((perm) => perm.code === "DASR-010")
          .some((perm) => perm.canRetrieve === true);
        return viewPagePermission;
      case "/fleet/dasr/aircraft-report-closed":
        viewPagePermission = userPermission
          .filter((perm) => perm.code === "DASR-011")
          .some((perm) => perm.canRetrieve === true);
        return viewPagePermission;
      case "/fleet/dasr/aircraft-report-archive":
        viewPagePermission = userPermission
          .filter((perm) => perm.code === "DASR-016")
          .some((perm) => perm.canRetrieve === true);
        return viewPagePermission;
      case "/fleet/dasr/assignment":
        viewPagePermission = userPermission
          .filter((perm) => perm.code === "DASR-012")
          .some((perm) => perm.canRetrieve === true);
        return viewPagePermission;
      case "/fleet/dasr/task-assignment":
        viewPagePermission = userPermission
          .filter((perm) => perm.code === "DASR-013")
          .some((perm) => perm.canRetrieve === true);
        return viewPagePermission;
      case "/fleet/dasr/assignment-report":
        viewPagePermission = userPermission
          .filter((perm) => perm.code === "DASR-014")
          .some((perm) => perm.canRetrieve === true);
        return viewPagePermission;
    }
  }

  function checkUrl() {
    let checkPermission = true;

    if (path.includes("/fleet/warning-message-management")) {
      checkPermission = userPermission.some((permission) => permission?.code === "SET-001");
      return checkPermission;
    } else if (path.includes("/fleet/manuals-collection")) {
      checkPermission = userPermission.some((permission) => permission?.code === "SET-001");
      return checkPermission;
    } else if (path.includes("/fleet/inbox")) {
      checkPermission = userPermission.some((permission) => permission?.code === "ACM-003");
      return checkPermission;
    } else if (path.includes("/fleet/aircraft-status") || path.includes("/fleet/aircraft-monitoring")) {
      checkPermission = userPermission.some((permission) => permission?.code === "ACM-002");
      return checkPermission;
    } else if (path.includes("/fleet/repetitive-defect-database")) {
      checkPermission = userPermission.some((permission) => permission?.code === "ACM-006");
      return checkPermission;
    } else if (path.includes("/fleet/email-alert-management")) {
      checkPermission = userPermission.some((permission) => permission?.code === "SET-002");
      return checkPermission;
    } else {
      return checkPermission;
    }
  }

  function verifyAccess() {
    if (!product) {
      return true;
    } else if (product?.toLowerCase() === "ade") {
      return true;
    } else if (product?.toLowerCase() === "elevade") {
      if (companySubElevade === true) {
        if (feature) {
          let allowedPermission = featureCheck();
          return allowedPermission;
        } else {
          return checkUrl();
        }
      } else {
        return false;
      }
    }
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        return (!entityCookies && !userAuthEntity?.token) || !entityCookies ? (
          history.push(redirectPath)
        ) : verifyAccess() ? (
          <Component {...props} />
        ) : (
          history.push("/error-403")
        );
      }}
    ></Route>
  );
};

export default ProtectedRoute;
