import React, { useEffect, useRef, useState } from "react";
import styles from "../aircraftReport/DDML.module.css";
import { Box, Grid, InputBase, Modal, styled, Tab, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import IconClose from "common/images/icons/elevade/Icon-Close.svg";
import IconCheckCircleWhite from "common/images/icons/elevade/Icon-CheckCircleWhite.svg";
import IconCheckCircleDisabled from "common/images/icons/elevade/Icon-CheckCircleDisabled.svg";
import Button from "pages/elevade/component/Button";
import { useSelector } from "react-redux";
import { formatDateDasr, formatTimeDasr } from "pages/elevade/component/Helpers";
import DialogConfirm from "../component/DialogConfirm";
import DDMLUpdateLogs from "../aircraftReport/DDMLUpdateLogs";
import { makeAPIRequestMDB } from "common/helpers/makeAPIRequestMDB";
import IconSendButton from "common/images/icons/elevade/Icon-SendButton.svg";
import IconAttachment from "common/images/icons/elevade/Icon-Attachment.svg";
import IconPDF from "common/images/icons/elevade/Icon-PDFAttachment.svg";
import IconImage from "common/images/icons/elevade/Icon-Image.svg";
import firebase from "firebase/app";
import "firebase/storage";
import { showNotificationElevade } from "common/helpers";
import { useLocation } from "react-router-dom";
import Preview from "../component/Preview";

const AdditionalTaskSummary = ({ data, open, setOpen, setEdit, setTriggerApi, dataForm, setDataForm, ...props }) => {
  const tabRef = useRef(null);
  const commentRef = useRef(null);
  const { userProfile } = useSelector((state) => state.authEntity);
  const { userProfile: userEntity } = useSelector((state) => state.userEntity);
  const { userPermission } = useSelector((state) => state.userEntity);
  const DDMLPermission = userPermission.filter(
    (perm) =>
      perm.code === "DASR-004" || perm.code === "DASR-005" || perm.code === "DASR-006" || perm.code === "DASR-007"
  );
  const [value, setValue] = useState("1");
  const [openDialogConfirm, setOpenDialogConfirm] = useState(false);
  const [DDMLLogs, setDDMLLogs] = useState([]);
  const [uploadComment, setUploadComment] = useState(false);
  const [openConfirmCopy, setOpenConfirmCopy] = useState(false);
  const handleCloseConfirmCopy = () => setOpenConfirmCopy(false);
  const [commentPushSummaryId, setCommentPushSummaryId] = useState("");
  const [commentPushSummary, setCommentPushSummary] = useState("");
  const [category, setCategory] = useState();
  const [DDMLId, setDDMLId] = useState();
  const [openPreviewComment, setOpenPreviewComment] = useState(false);
  const handleCloseComment = () => setOpenPreviewComment(false);
  const [commentFileLink, setCommentFileLink] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [previewUrl, setPreviewUrl] = useState();
  const [openPreviewSummary, setOpenPreviewSummary] = useState(false);
  const handleOpenSummary = () => setOpenPreviewSummary(true);
  const [summaryPreviewUrl, setSummaryPreviewUrl] = useState("");
  const [openPreview, setOpenPreview] = useState(false);
  const handleOpen = () => setOpenPreview(true);
  const [inputCommentError, setInputCommentError] = useState(false);
  const today = new Date();
  const formattedDate = today.toISOString();
  const avatar = localStorage.getItem("avatar");
  const [commentData, setCommentData] = useState([
    {
      updateLogCategory: "",
      updateLogDesc: "",
      userCreated: {
        name: userProfile?.displayname || userEntity.fullName,
        avatarLink: userProfile?.avatar || avatar,
      },
      createdAt: formattedDate,
      attachmentLog: [
        {
          fileName: "",
          fileType: "",
          fileLink: "",
        },
      ],
    },
  ]);
  const [comment, setComment] = useState("");
  const [fileComment, setFileComment] = useState({});
  const [error, setError] = useState(false);
  const storageRef = firebase.storage().ref();
  const [uploadProgress, setUploadProgress] = useState(0);
  const [file, setFile] = useState([]);
  const location = useLocation();
  const isTaskAssignmentPage = location.pathname.includes("/task-assignment");
  const isAssignmentReportPage = location.pathname.includes("/assignment-report");
  const [fileName, setFileName] = useState();

  const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: "rgba(0, 0, 0, 0.85)",
    fontFamily: ["Gotham-Book"].join(","),
    fontSize: "14px",
    "&:hover": {
      color: "#333333",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#333333",
      fontFamily: "Gotham-Medium",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
      paddingBottom: "10px",
    },
    "&.MuiTab-root": {
      marginRight: "10px",
      marginTop: "-10px",
      marginBottom: "-10px",
    },
  }));

  // Custom styles
  const useStyles = makeStyles(() => ({
    splitItems: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontSize: "12px",
      // display: "-webkit-box",
      // "-webkit-box-orient": "vertical",
      // "-webkit-line-clamp": 2, // Number of lines to show
    },
    customTabsStyle: {
      "& .MuiTabScrollButton-root:first-child": {
        marginTop: "-6px",
      },
      "& .MuiTabScrollButton-root:last-child": {
        marginTop: "-6px",
      },
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    // Set to open first tab
    setValue("1");
    // Clear the file state
    setFile([]);
    // Clear the melCatType state
    // setMelCatType();
    // Clear the form error state
    // setFormError([]);
    // Set the initial raisedDate in the formData object
    // setRaisedDate(formData[0].raisedDate);
    // set focus on the dummy input field
    // focusRef.current.focus();
    // Set the DDML Type based on selected tab
    // setFormData(updateArrayColumn("formType", formType));
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    DDMLLogList();
  }, [dataForm]);

  // A function to set "updateLogCategory" key in "commentData" object based on the tab selected in the DDML form
  useEffect(() => {
    let Category = "";
    if (value === "2") Category = "Rectification Update";
    else if (value === "3") Category = "Material/Tools Update";
    else if (value === "4") Category = "PPC";
    setCommentData(updateArrayColumnComment("updateLogCategory", Category));
    // eslint-disable-next-line
  }, [value]);

  // A function to update "attachmentLog" key in "commentData" object on "fileComment" change
  useEffect(() => {
    setCommentData(updateArrayColumnComment("attachmentLog", file));
    // eslint-disable-next-line
  }, [fileComment]);

  // On file state change, update the "attachment" key in "formData" object
  useEffect(() => {
    setCommentData(updateArrayColumnComment("attachmentLog", file));
    // eslint-disable-next-line
  }, [file]);

  // An API called to get the "ddml-logs" list of the selected DDML
  async function DDMLLogList() {
    const params = `api/assignment/adhoc/logs/${dataForm?._id ? dataForm._id : dataForm.id}`;
    const fetch = makeAPIRequestMDB(`get`, `${params}`);
    fetch.then((response) => {
      setDDMLLogs(response);
      setUploadComment(false);
    });
  }

  // A function used to push comment into the summary tab
  function handleCopy(e, id, category, ddmlId) {
    setOpenConfirmCopy(true);
    setCommentPushSummaryId(id);
    setCommentPushSummary(e);
    setCategory(category);
    setDDMLId(ddmlId);
  }

  // A function to set the file links for previewing event
  function handleOpenComment(e) {
    setCommentFileLink(e);
    setOpenPreviewComment(true);
  }

  function ConvertDate(date1_str) {
    // Convert the date string to a Date object
    var date1 = new Date(date1_str);

    // Function to get the month abbreviation
    function getMonthAbbreviation(month) {
      var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      return months[month];
    }

    // Function to format the time as HH:MM AM/PM
    function formatTime(date) {
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12;
      return hours + ":" + (minutes < 10 ? "0" : "") + minutes + " " + ampm;
    }

    // Convert the date to UTC+8 timezone
    var dateUtcPlus8 = new Date(date1.getTime() + 8 * 60 * 60 * 1000);
    // var dateUtcPlus8 = new Date(date1); // UTC datetime

    // Format the date into the desired format
    var formattedDate =
      getMonthAbbreviation(dateUtcPlus8.getUTCMonth()) +
      " " +
      dateUtcPlus8.getUTCDate() +
      ", " +
      dateUtcPlus8.getUTCFullYear() +
      " at " +
      formatTime(dateUtcPlus8);

    return formattedDate;
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  const DDMLTitle = () => {
    return (
      <>
        <span
          style={{
            border: "1px solid #C3C3C3",
            borderRadius: "6px",
            padding: "4px 8px",
            marginRight: "8px",
            whiteSpace: "nowrap",
            marginLeft: "8px",
          }}
        >
          {dataForm?.aircraftReg}
        </span>
        {/* <span>{dataForm?.ddmlNo}</span> */}
      </>
    );
  };

  // check permission for summary DDML Edit
  function SummaryDDMLFormPermission(summaryButton) {
    const summaryPermission = DDMLPermission.filter((perm) => perm.code === "DASR-004");
    if (summaryPermission.some((perm) => perm.canUpdate === true)) {
      switch (summaryButton) {
        case "Edit":
          return dataForm?.formStatus === "closed" ? "disabled" : "none";
        case "Assignment":
          return dataForm?.moveToAssignment === true || dataForm?.formStatus === "closed" ? "disabled" : "outlined";
        case "Close Task":
          return dataForm?.formStatus === "closed" ? "disabled" : "fill";
      }
    } else {
      return "disabled";
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function getSummary(id, category, descr) {
    let ddmlLogs = DDMLLogs?.data?.filter((e) => e?.pushToSummary === true && e?.updateLogCategory === category);
    let latest = ddmlLogs?.at(-1);
    return latest?.updateLogDesc;
  }

  // update data form if data from list(table) is updated
  useEffect(() => {
    if (data && data.find && setDataForm) {
      const currentData = data?.find(({ ddml }) => ddml?.id === dataForm?.id);
      setDataForm(currentData);
    }
  }, [data]);

  const BodySummary = () => {
    return (
      <>
        <div
          style={{
            position: "relative",
            marginLeft: "16px",
            marginTop: isTaskAssignmentPage || isAssignmentReportPage ? "190px" : "140px",
            paddingRight: "32px",
          }}
          className={styles.container}
        >
          <Grid container columns={2}>
            <Grid item xs={1}>
              <div className={styles.titleSummary}>Aircraft Registration</div>
              <div>{dataForm?.aircraftReg}</div>
            </Grid>
            <Grid item xs={1}>
              <div className={styles.titleSummary}>Station</div>
              <div>{dataForm?.location}</div>
            </Grid>
            <Grid item xs={1}>
              <div className={styles.titleSummary}>Raised Date</div>
              <div>{formatDateDasr(dataForm?.raisedDate)}</div>
            </Grid>
            <Grid item xs={1}>
              <div className={styles.titleSummary}>ETS</div>
              <div>{formatDateDasr(dataForm?.ets)}</div>
            </Grid>
            <Grid item xs={2}>
              <div className={styles.titleSummary}>Task Description</div>
              <div className={styles.bodySummary}>{dataForm?.defectDesc}</div>
            </Grid>
            <Grid item xs={2}>
              <div className={styles.titleSummary}>Rectification Status</div>
              <div className={styles.bodySummary}>
                {dataForm?.formStatus === "closed" || dataForm?.formStatus === "approved"
                  ? dataForm?.ddmlStatus.rectificationCategory
                  : getSummary(dataForm?._id ? dataForm._id : dataForm.id, "Rectification Update", "") ||
                    dataForm?.rectificationStatus}
              </div>
            </Grid>
            <Grid item xs={2}>
              <div className={styles.titleSummary}>Material Summary</div>
              <div className={styles.formTextAreaDisable}>
                {getSummary(dataForm.id, "Material/Tools Update", "") || "-"}
              </div>
            </Grid>
            <Grid item xs={2}>
              <div className={styles.titleSummary}>Planning/Tech Service Summary</div>
              <div className={styles.formTextAreaDisable}>{getSummary(dataForm.id, "PPC", "") || "-"}</div>
            </Grid>
            <Grid item xs={2}>
              <div className={styles.titleSummary}>Remarks</div>
              <div className={styles.bodySummary}>{dataForm?.remarks || "-"}</div>
            </Grid>
            <Grid item xs={1}>
              <div className={styles.titleSummary}>
                Created {formatDateDasr(data.createdAt || dataForm.createdAt)} at{" "}
                {formatTimeDasr(data.createdAt || dataForm.createdAt)}
                <br />
                Created by {data.createdBy || dataForm.createdBy}
              </div>
            </Grid>
            <Grid item xs={1} sx={{ paddingLeft: "10px" }}>
              <div className={styles.titleSummary}>
                {data.updatedAt !== null
                  ? `Updated ${formatDateDasr(data.updatedAt || dataForm.updatedAt)} at ${formatTimeDasr(
                      data.updatedAt || dataForm.updatedAt
                    )}`
                  : ""}
              </div>
            </Grid>
          </Grid>
        </div>
      </>
    );
  };

  function StatusDDMLFormPermission(tab) {
    // check permission for rectification, material and ppc add comment
    if (tab === "2") {
      const rectificationPermission = DDMLPermission.filter((perm) => perm.code === "DASR-005");
      // return rectificationPermission.some((perm) => perm.canUpdate === true) ? false : true;
      if (rectificationPermission.some((perm) => perm.canUpdate === true)) {
        return <>{addCommentStatus()}</>;
      } else {
        return <></>;
      }
    } else if (tab === "3") {
      const materialPermission = DDMLPermission.filter((perm) => perm.code === "DASR-006");
      if (materialPermission.some((perm) => perm.canUpdate === true)) {
        return <>{addCommentStatus()}</>;
      } else {
        return <></>;
      }
    } else if (tab === "4") {
      const PPCPermission = DDMLPermission.filter((perm) => perm.code === "DASR-007");
      if (PPCPermission.some((perm) => perm.canUpdate === true)) {
        return <>{addCommentStatus()}</>;
      } else {
        return <></>;
      }
    } else {
      return <></>;
    }
  }

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
      // borderRadius: 8,
      position: "relative",
      backgroundColor: "#ffffff",
      border: "0px solid",
      borderColor: "#E0E3E7",
      fontSize: 14,
      padding: "4px",
      transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
      fontFamily: ["Gotham-Book"].join(","),
      "&:focus": {
        borderColor: theme.palette.primary.main,
      },
      textTransform: "uppercase",
    },
  }));

  const addCommentStatus = () => {
    return (
      <>
        <div
          style={{
            width: "100%",
            position: "absolute",
            bottom: "0px",
            left: "0px",
            borderTop: "1px solid #D1D1D1",
            backgroundColor: "#ffffff",
            borderRadius: "8px 8px 0px 0px",
            // padding: "16px",
            zIndex: 2,
          }}
        >
          <Grid container columns={6}>
            <Grid item xs={0.5} sx={{ padding: "16px" }}>
              <label style={{ cursor: "pointer" }}>
                <input type="file" onChange={handleFileChange} style={{ display: "none" }} />
                <img src={IconAttachment} alt="attachment" />
              </label>
            </Grid>
            <Grid item xs={5} sx={{ padding: "8px 0" }}>
              {selectedFile && (
                <>
                  <div
                    style={{
                      height: "35px",
                      borderRadius: "4px",
                      marginRight: "10px",
                      display: "flex",
                    }}
                  >
                    {fileComment?.Link?.includes(".pdf?") ? (
                      <img src={IconPDF} alt="icon-pdf" />
                    ) : (
                      <img src={IconImage} alt={`Preview`} style={{ width: "40px", height: "35px" }} />
                    )}
                    <div onClick={handleOpenSummary} style={{ cursor: "pointer" }}>
                      {splitToChucks(fileComment)}
                    </div>
                  </div>
                </>
              )}
            </Grid>
            <Grid item xs={0.5} sx={{ padding: "8px 0" }}>
              {selectedFile && (
                <img
                  src={IconClose}
                  alt="icon-close"
                  height={15}
                  style={{ marginTop: "2px", cursor: "pointer" }}
                  onClick={() => setSelectedFile("")}
                />
              )}
            </Grid>
            <Grid item xs={6} sx={{ padding: "8px 16px", borderTop: "1px solid  #D1D1D1", display: "flex" }}>
              <BootstrapInput
                // ref={inputComment}
                className={`${styles.inputComment} ${inputCommentError ? styles.inputCommentError : ""}`}
                placeholder="Comment here"
                onBlur={(e) => handleComment(e)}
                onKeyDown={handleKeyDown}
                defaultValue={commentData[0].updateLogDesc}
                ref={commentRef}
                multiline={true}
                // value={comment.toUpperCase()}
              />
              <img
                src={IconSendButton}
                alt="post-comment"
                style={{ cursor: "pointer", position: "fixed", bottom: "20px", right: "20px" }}
                onClick={() => handleSubmitComment()}
              />
            </Grid>
          </Grid>
        </div>
      </>
    );
  };

  const acceptedFileTypes = ["application/pdf", "image/jpeg", "image/png", "image/tiff"];
  const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (!file) {
      setSelectedFile(null);
      setPreviewUrl(null);
      return;
    }

    if (file.size <= maxFileSize && acceptedFileTypes.includes(file.type)) {
      setError(false);
      setSelectedFile(file);
      setPreviewUrl(URL.createObjectURL(file));
      UploadRenamedFile(file);
    } else {
      setError(true);
    }
  };

  function splitToChucks(str) {
    return (
      <div
        onClick={() => handleOpenPreview(str?.Link, str?.File?.name)}
        style={{
          cursor: "pointer",
          height: "100%",
          display: "flex",
          alignItems: "center",
          width: "340px",
          marginLeft: "8px",
        }}
      >
        {/* {firstChunk}
        <br />
        {otherChunks} */}
        <Typography className={classes.splitItems}>{str?.fileName || str?.File?.name}</Typography>
      </div>
    );
  }

  function handleOpenSummaryPreview(e) {
    setSummaryPreviewUrl(e);
    handleOpen();
  }

  function handleSubmitComment() {
    if (commentRef.current.value === "") {
      setInputCommentError(true);
      commentRef.current.focus();
    } else {
      setUploadComment(true);
      setInputCommentError(false);

      SubmitComment();
    }
  }

  async function SubmitComment() {
    const params = `api/assignment/adhoc/logs`;
    const fetch = makeAPIRequestMDB(`post`, `${params}/${dataForm?._id ? dataForm._id : dataForm.id}`, commentData[0]);
    fetch
      .then((response) => {
        setSelectedFile("");
        setComment("");
        setFileComment("");
        DDMLLogList();
        handleClearInput();
      })
      .catch((error) => {
        console.log("error", error);
        setUploadComment(false);
      });
  }

  const handleComment = (event) => {
    setComment(event.target.value);
    setCommentData(updateArrayColumnComment("updateLogDesc", event.target.value.toUpperCase()));
  };

  // !An important function to set, update and remove any value in the array of object : commentData
  function updateArrayColumnComment(column, value) {
    return commentData.map((item) => {
      if (column === "attachmentLog") {
        return {
          ...item,
          [column]: [
            {
              fileName: fileComment?.File?.name,
              fileType: fileComment?.File?.type,
              fileLink: fileComment?.Link,
            },
          ],
        };
      }
      // Check if the item has the specified column and update it if exists
      else if (item.hasOwnProperty(column)) {
        return { ...item, [column]: value }; // Create a new object with updated column
      } else return { ...item, [column]: value }; // If the specified column doesn't exist, return the original item unchanged
    });
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (event.target.value !== "") {
        // handleSubmitComment();
      }
      // event.currentTarget.value = "";
    }
  };

  // Function to handle the action that should clear the input
  const handleClearInput = () => {
    // Set the value of the input directly using the ref
    commentRef.current.value = "";
    setCommentData(updateArrayColumnComment("updateLogDesc", ""));
  };

  // An async function to upload files to the server
  async function UploadFile(file) {
    if (file) {
      const uploadTask = storageRef.child(`dasr/uploads/${file.name}`).put(file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        },
        (error) => {
          console.error("Error uploading file:", error);
        },
        async () => {
          console.log("File uploaded successfully");
          const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
          setFileComment({ File: file, Link: downloadURL });
          setUploadProgress(0);
        }
      );
    }
  }

  async function UploadRenamedFile(file) {
    if (file) {
      // Generate a timestamp string.
      const timestamp = new Date().toISOString().replace(/:/g, "-");

      // Append the timestamp to the original filename.
      // This assumes file.name is a string like 'example.txt'.
      const fileNameWithTimestamp = `${file.name.split(".").slice(0, -1).join(".")}_${timestamp}.${file.name
        .split(".")
        .pop()}`;

      // Start the upload process with the new filename.
      const uploadTask = storageRef.child(`dasr/uploads/${fileNameWithTimestamp}`).put(file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        },
        (error) => {
          console.error("Error uploading file:", error);
        },
        async () => {
          console.log("File uploaded successfully");
          const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
          setFileComment({ File: file, Link: downloadURL });
          setUploadProgress(0);
        }
      );
    }
  }

  const handleConfirmCopy = () => {
    PushToSummary();
    setOpenConfirmCopy(false);
    setTriggerApi(true);
  };

  // API called once the push to summary is confirmed
  async function PushToSummary() {
    const body = {
      logId: commentPushSummaryId,
      collectionId: DDMLId,
      category: category,
      pushToSummary: true,
    };
    const params = `api/assignment/adhoc/logs/summary`;
    async function UpdateData() {
      const fetch = makeAPIRequestMDB(
        `put`,
        `${params}`,
        body
        // `${params}/${commentPushSummaryId}/true/${DDMLId}/${encodeURIComponent(category.replaceAll("/", "_"))}`
      );
      fetch
        .then((response) => {
          if (response.detail.includes("success")) {
            showNotificationElevade("success", "Update successfully pushed to summary!", [
              "Your successfully pushed new update to summary",
            ]);
            DDMLLogList();
          } else {
            showNotificationElevade("danger", "An error occured!", ["Please contact your system administartor"]);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    UpdateData();
  }

  const Tips = ({ val }) => {
    return (
      <span
        style={{
          borderRadius: "30px",
          color: "#ffffff",
          backgroundColor: "#E95048",
          fontFamily: "Gotham-Book",
          fontSize: "12px",
          marginLeft: "8px",
          padding: "4px 8px 2px 8px",
        }}
      >
        {val}
      </span>
    );
  };

  function handleOpenPreview(fileLink, fileName) {
    setSummaryPreviewUrl(fileLink);
    setFileName(fileName);
    setOpenPreview(true);
  }

  return (
    <>
      <Grid
        container
        columns={4}
        sx={{
          borderBottom: "1px solid #c3c3c3",
          position: "fixed",
          zIndex: 9,
          backgroundColor: "#ffffff",
          top: "60px",
        }}
        className={`${styles.macScrollbar}`}
      >
        <Grid item xs={3} sx={{ padding: "24px" }} className={styles.title} ref={tabRef}>
          Task Details <DDMLTitle />
        </Grid>
        <Grid item xs={1} sx={{ display: "flex", justifyContent: "flex-end", padding: "24px" }}>
          <img
            src={IconClose}
            alt="icon-close"
            height={15}
            style={{ marginTop: "2px", cursor: "pointer" }}
            onClick={() => setOpen(false)}
          />
        </Grid>
      </Grid>
      <Grid container columns={4}>
        <Grid item xs={4} sx={{ paddingBottom: "100px" }}>
          <TabContext value={value} sx={{ marginLeft: "-60px" }}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              variant="scrollable"
              scrollButtons={false}
              classes={{ root: classes.customTabsStyle }}
              sx={{
                paddingTop: "10px",
                // paddingRight: "10px",
                position: "fixed",
                top: "121px",
                transform: "translateX(-0%)",
                zIndex: "10",
                backgroundColor: "#ffffff",
                borderBottom: "2px solid #e8e8e8",
                borderTop: "0.5px solid #C3C3C3",
                width: "100%",
              }}
            >
              <AntTab label="Summary" value="1" />
              <AntTab
                label="Rectification Update"
                value="2"
                icon={
                  dataForm?.unreadLogs?.rectification.length > 0 && (
                    <Tips val={dataForm?.unreadLogs?.rectification.length} />
                  )
                }
                iconPosition="end"
              />
              <AntTab
                label="Material/Tools Update"
                value="3"
                icon={
                  dataForm?.unreadLogs?.materialTool.length > 0 && (
                    <Tips val={dataForm?.unreadLogs?.materialTool.length} />
                  )
                }
                iconPosition="end"
              />
              <AntTab
                label="Planning/Tech Service"
                value="4"
                icon={
                  dataForm?.unreadLogs?.planningTech.length > 0 && (
                    <Tips val={dataForm?.unreadLogs?.planningTech.length} />
                  )
                }
                iconPosition="end"
              />
            </TabList>
            <div
              style={{
                marginLeft: "0px",
                marginRight: "-20px",
                paddingBottom: "60px",
                width: "100%",
                overflow: "auto",
              }}
            >
              <TabPanel value="1" sx={{ padding: "0px" }}>
                <BodySummary />
              </TabPanel>
              <TabPanel
                value="2"
                sx={{ padding: isTaskAssignmentPage ? "160px 0 0 0" : "120px 0 0 0", position: "relative" }}
              >
                <DDMLUpdateLogs
                  tab={"Rectification Update"}
                  DDMLLogs={DDMLLogs}
                  handleCopy={handleCopy}
                  handleOpenComment={handleOpenComment}
                  ConvertDate={ConvertDate}
                  openPreviewComment={openPreviewComment}
                  handleCloseComment={handleCloseComment}
                  style={style}
                  commentFileLink={commentFileLink}
                  uploadComment={uploadComment}
                  setUploadComment={setUploadComment}
                  ddmlStatus={dataForm?.ddmlStatus}
                  initialData={data}
                  data={props.dataList}
                  unreadLogs={dataForm?.unreadLogs?.rectification}
                />
              </TabPanel>
              <TabPanel value="3" sx={{ padding: isTaskAssignmentPage ? "160px 0 0 0" : "120px 0 0 0" }}>
                <DDMLUpdateLogs
                  tab={"Material/Tools Update"}
                  DDMLLogs={DDMLLogs}
                  handleCopy={handleCopy}
                  handleOpenComment={handleOpenComment}
                  ConvertDate={ConvertDate}
                  openPreviewComment={openPreviewComment}
                  handleCloseComment={handleCloseComment}
                  style={style}
                  commentFileLink={commentFileLink}
                  uploadComment={uploadComment}
                  setUploadComment={setUploadComment}
                  initialData={data[0] || data}
                  ddmlStatus={dataForm?.ddmlStatus}
                  data={props.dataList}
                  unreadLogs={dataForm?.unreadLogs?.materialTool}
                />
              </TabPanel>
              <TabPanel value="4" sx={{ padding: isTaskAssignmentPage ? "160px 0 0 0" : "120px 0 0 0" }}>
                <DDMLUpdateLogs
                  tab={"PPC"}
                  DDMLLogs={DDMLLogs}
                  handleCopy={handleCopy}
                  handleOpenComment={handleOpenComment}
                  ConvertDate={ConvertDate}
                  openPreviewComment={openPreviewComment}
                  handleCloseComment={handleCloseComment}
                  style={style}
                  commentFileLink={commentFileLink}
                  uploadComment={uploadComment}
                  setUploadComment={setUploadComment}
                  ddmlStatus={dataForm?.ddmlStatus}
                  initialData={data}
                  data={props.dataList}
                  unreadLogs={dataForm?.unreadLogs?.planningTech}
                />
              </TabPanel>
            </div>
          </TabContext>
          <div
            style={{
              position: "fixed",
              bottom: "0",
              width: "50%",
              borderTop: "1px solid #D1D1D1",
              backgroundColor: "#ffffff",
              padding: "16px 24px 14px 24px",
              display: "flex",
              justifyContent: "flex-end",
              zIndex: 2,
            }}
          >
            {value === "1" && !isAssignmentReportPage && (
              <>
                <Button
                  title="Edit"
                  // variant={dataForm?.formStatus === "closed" ? "disabled" : "none"}
                  variant={SummaryDDMLFormPermission("Edit")}
                  onClick={() => setEdit(true)}
                  marginTop="0px"
                />
                <Button
                  title="Close task"
                  icon={IconCheckCircleWhite}
                  iconWhite={IconCheckCircleWhite}
                  iconDisabled={IconCheckCircleDisabled}
                  // variant={
                  //   dataForm?.moveToAssignment === true || dataForm?.formStatus === "closed" ? "disabled" : "outlined"
                  // }
                  variant={SummaryDDMLFormPermission("Close Task")}
                  onClick={() => setOpenDialogConfirm(true)}
                  marginTop="0px"
                />
              </>
            )}

            {value !== "1" && !isAssignmentReportPage && <>{StatusDDMLFormPermission(value)}</>}
          </div>
        </Grid>
      </Grid>
      <DialogConfirm
        open={openDialogConfirm}
        setOpen={setOpenDialogConfirm}
        data={data}
        setTriggerApi={setTriggerApi}
        title="Close Additional Task"
        content="This will close the assignments. Are you sure you want to close the assignment?"
      />
      <Modal
        open={openConfirmCopy}
        onClose={handleCloseConfirmCopy}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            border: "0px solid #000",
            boxShadow: 24,
            padding: "16px 0px",
            borderRadius: "8px",
          }}
        >
          <Grid container columns={1}>
            <Grid
              item
              xs={0.8}
              sx={{
                padding: "0px 24px 16px 24px",
                borderBottom: "1px solid #D1D1D1",
              }}
            >
              <span style={{ fontFamily: "Gotham-Medium" }}>Copy and replace to Summary</span>
            </Grid>
            <Grid
              item
              xs={0.2}
              sx={{
                padding: "0px 24px 16px 24px",
                borderBottom: "1px solid #D1D1D1",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <img
                src={IconClose}
                alt="icon-close"
                height={12}
                style={{ cursor: "pointer" }}
                onClick={() => setOpenConfirmCopy(false)}
              />
            </Grid>
            <Grid item xs={1} sx={{ padding: "32px 64px" }}>
              <div
                style={{
                  fontFamily: "Gotham-Book",
                  marginBottom: "24px",
                  color: "#838383",
                  fontSize: "14px",
                }}
              >
                This will replace the existing rectification status in Summary Tab with this information:
              </div>
              <div style={{ fontFamily: "Gotham-medium", marginBottom: "24px" }}>“{commentPushSummary}”</div>
              <div style={{ fontFamily: "Gotham-Book", marginBottom: "0px", color: "#838383", fontSize: "14px" }}>
                Do you want to proceed?
              </div>
            </Grid>
            <Grid
              item
              xs={1}
              sx={{
                display: "flex",
                justifyContent: "center",
                paddingBottom: "16px",
              }}
            >
              <Button
                title="Cancel"
                variant=""
                onClick={() => {
                  setOpenConfirmCopy(false);
                }}
              />
              <Button title="Proceed" variant="fill" onClick={handleConfirmCopy} />
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Preview open={openPreview} setOpen={setOpenPreview} fileLink={summaryPreviewUrl} fileName={fileName} />
    </>
  );
};

export default AdditionalTaskSummary;
