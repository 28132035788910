export const AOCListV2 = [
  {
    airline: "airasia",
    AOC: [
      { code: "MAA", name: "Malaysia AirAsia" },
      { code: "AAX", name: "AirAsia X" },
      { code: "TAA", name: "Thai AirAsia" },
      { code: "TAAX", name: "Thai AirAsia X" },
      { code: "IAA", name: "Indonesia AirAsia" },
      { code: "PAA", name: "Philippines AirAsia" },
      { code: "AAC", name: "AirAsia Cambodia" },
    ]
  },{
    airline: "rba",
    AOC: [
      { code: "RBA", name: "Royal Brunei Airlines" }
    ]
  }
]