
import React from 'react';

export default function TailDEF() {
	return (
		<g transform='translate(30 0)'>
			<path
				d="M1159.71 271H1160.5V267.95H1164.29V267.23H1160.5V264.73H1164.74V264H1159.71V271Z"
				fill="#333333"
			/>
			<path
				d="M1159.64 297H1164.75V296.28H1160.43V293.83H1164.25V293.11H1160.43V290.72H1164.7V290H1159.64V297Z"
				fill="#333333"
			/>
			<path
				d="M1159.08 323H1161.51C1163.71 323 1165.23 321.47 1165.23 319.5V319.48C1165.23 317.51 1163.71 316 1161.51 316H1159.08V323ZM1159.87 322.27V316.73H1161.51C1163.28 316.73 1164.41 317.95 1164.41 319.5V319.52C1164.41 321.07 1163.28 322.27 1161.51 322.27H1159.87Z"
				fill="#333333"
			/>
		</g>
	);
}
