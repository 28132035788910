import React, { useEffect } from "react";
import { Redirect, Switch, useRouteMatch, Route, useLocation } from "react-router-dom";
// import Sidebar from "./component/Sidebar";
import Sidebar from "layouts/sidebar/Sidebar";
import AircraftMonitoring from "./aircraftMonitoring/AircraftMonitoring";
import AircraftStatus from "./aircraftMonitoring/AircraftStatus";
import ProtectedRoute from "layouts/auth/ProtectedRoute";
import PageUnavailable from "./aircraftMonitoring/PageUnavailable";
import Inbox from "./aircraftMonitoring/Inbox";
import Analytics from "./analytics/Analytics";
// import { AnalyticsV2 } from "./analytics/Analyticsv2";
import { AnalyticsMain } from "./analytics/AnalyticsMain";
import styles from "./Elevade.module.css";
// import ScheduleTask from "./analytics/ScheduleTask";
import DASR from "./dasr/DASR";
import WarningManagement from "pages/elevade/warningManagement/WarningManagement";
import ManualManagement from "pages/elevade/manualManagement/ManualManagement";
import { RepetitiveDefectv2 } from "./repetitiveDefect/RepetitiveDefectv2";
import EmailAlertManagement from "./emailAlertManagement/EmailAlertManagement";
import { useMediaQuery } from "@mui/material";
import IdleChecker from "./component/IdleChecker";
import CabinMonitoring from "./cabinMonitoring/CabinMonitoring";
import LandingPage from "./home/LandingPage";
import ScheduleTask from "./scheduleTask/ScheduleTask";

const Elevade = () => {
  const { path } = useRouteMatch();
  const location = useLocation();
  const paramsString = location.search;
  const searchParams = new URLSearchParams(paramsString);
  const open = searchParams.get("open");
  const isMobile = useMediaQuery("(max-width:900px)");

  let paddingRight = "0px";
  if (open === "true") paddingRight = "230px";

  const DisableScrollToTop = ({ history }) => {
    useEffect(() => {
      if (history.action === "POP") return;
    });
    return null;
  };

  const handleIdle = () => {
    // Perform actions when the page is idle
    // window.location.reload();
    console.log("Idling...");
  };

  return (
    <div
      id="main"
      style={{
        backgroundColor: "#FFFFFF",
        marginRight: "-70px",
        marginLeft: !isMobile ? "-30px" : "-10px",
        minHeight: "764px",
        marginBottom: "-100px",
        paddingRight: `${paddingRight}`,
        overflow: "hidden",
      }}
      className={`${styles.main}`}
    >
      <IdleChecker idleTime={300000} onIdle={handleIdle} />
      <Route component={DisableScrollToTop} />
      <Sidebar />
      <Switch>
        <ProtectedRoute product="elevade" exact path={`${path}/home`} component={LandingPage} />
        <ProtectedRoute product="elevade" exact path={`${path}/aircraft-monitoring`} component={AircraftMonitoring} />
        <ProtectedRoute
          product="elevade"
          path={`${path}/aircraft-status/:id/failure-messages`}
          component={AircraftStatus}
        />
        <ProtectedRoute
          product="elevade"
          path={`${path}/aircraft-monitoring/:id/schedule`}
          component={PageUnavailable}
        />
        <ProtectedRoute product="elevade" path={`${path}/aircraft-monitoring/:id/uplink`} component={PageUnavailable} />
        <ProtectedRoute product="elevade" path={`${path}/inbox`} component={Inbox} />
        <ProtectedRoute product="elevade" exact path={`${path}/analytics/old`} component={Analytics} />
        <ProtectedRoute
          product="elevade"
          path={`${path}/analytics`}
          component={AnalyticsMain}
          feature={"isAnalytics"}
        />
        <ProtectedRoute
          product="elevade"
          path={`${path}/schedule-task`}
          component={ScheduleTask}
          feature={"isScheduledCheck"}
        />
        <ProtectedRoute product="elevade" path={`${path}/dasr`} component={DASR} feature={"isDASR"} />
        <ProtectedRoute
          product="elevade"
          path={`${path}/cabin-monitoring`}
          component={CabinMonitoring}
          feature={"isCabinMonitoring"}
        />
        <ProtectedRoute product="elevade" path={`${path}/repetitive-defect-database`} component={RepetitiveDefectv2} />
        <ProtectedRoute
          product="elevade"
          path={`${path}/warning-message-management`}
          component={WarningManagement}
          isAdmin
        />
        <ProtectedRoute
          product="elevade"
          path={`${path}/email-alert-management`}
          component={EmailAlertManagement}
          isAdmin
        />
        <ProtectedRoute product="elevade" path={`${path}/manuals-collection`} component={ManualManagement} isAdmin />
        <Redirect to="/fleet/error-404" />
      </Switch>
      <div id="main"></div>
    </div>
  );
};

export default Elevade;
