import { Grid, Checkbox, styled, Fade } from "@mui/material";
import styles from "./FilterBox.module.css";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import React, { useState, useEffect } from "react";
import MagnifyingGlass from "common/images/icons/elevade/Icon-MagnifyingGlassGray.svg";
import IconStandby from "common/images/icons/elevade/Icon-Standby.svg";
import IconUnderMaintenance from "common/images/icons/elevade/Icon-UnderMaintenance.svg";
import IconAOG from "common/images/icons/elevade/Icon-AOG.svg";
import IconStorage from "common/images/icons/elevade/Icon-Storage.svg";
import IconScheduled from "common/images/icons/elevade/Icon-Scheduled.svg";

const FilterModal = ({
  open,
  title,
  list,
  isFilters,
  setIsFilters,
  isLast,
  openTitle,
  disableFuture,
  isSearch,
  setIsSearch,
}) => {
  const [selectDate, setSelectDate] = useState(false);
  const [isFilteredList, setIsFilteredList] = useState([]);
  let titleRef = title.replace(" ", "_") + "_dasr";

  // Set the key based on title
  // Key is the object key in the isFilters
  const titleToKeyMap = {
    Operator: "operator",
    "Aircraft Reg": "aircraftReg",
    Station: "location",
    Department: "assignedTo",
    "Raised Date": "raisedDate",
    "Expiry Date": "expiryDate",
    Status: "aircraftStatus",
  };

  let key = titleToKeyMap[title] || "";

  // Custom list for Summary Status
  // Define your list here if you need a custom list
  if (title === "Summary Status")
    list = ["Rectification Status", "MOC Remarks", "Material Summary", "Planning/Tech Service"];

  useEffect(() => {
    if (title.includes("Date")) {
      const selectedDate = processDate(selectDate);
      if (selectedDate !== "1970-01-01") {
        setFilters(selectedDate, key);
      }
    }
  }, [selectDate, key, title]);

  useEffect(() => {
    if (isSearch !== "") {
      const filteredListData = list.filter((e) => e.toUpperCase().includes(isSearch.toUpperCase()));
      setIsFilteredList(filteredListData);
    } else setIsFilteredList(list);
  }, [isSearch]);

  const StyledCheckbox = styled(Checkbox)(() => ({
    [`.MuiCheckbox-root`]: {
      marginTop: "0px",
      border: "1px solid blue",
    },
    marginTop: "0px",
    color: "#94A3B8",
  }));

  const processDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const iconMap = {
    Scheduled: IconScheduled,
    Maintenance: IconUnderMaintenance,
    AOG: IconAOG,
    Standby: IconStandby,
    Utilised: IconStandby, // If this is correct and not a typo
    Storage: IconStorage,
  };

  const getStatusItem = (item, icon) => (
    <div>
      <img src={icon} alt={`icon-${item.toLowerCase()}`} height={24} style={{ marginRight: "10px" }} />
      {item}
    </div>
  );

  function setFilters(value, key) {
    const theDate = { ...isFilters, [key]: [value] };
    setIsFilters(theDate);
  }

  function handleChecked(value) {
    const updatedChecked = [...isFilters[key], value];
    const updatedFilters = { ...isFilters, [key]: updatedChecked };
    setIsFilters(updatedFilters);
  }

  function handleUnChecked(value) {
    const updatedChecked = isFilters[key].filter((item) => item !== value);
    const updatedFilters = { ...isFilters, [key]: updatedChecked };
    setIsFilters(updatedFilters);
  }

  function handleClick(item, title) {
    if (title === "Summary Status") {
      setIsFilters({ ...isFilters, summaryStatus: [item] });
    }
    if (title === "Status") {
      setIsFilters({ ...isFilters, status: [item] });
    }
  }

  function handleSelectAll(cond) {
    if (cond) {
      const updatedFilters = { ...isFilters, [key]: isFilteredList };
      setIsFilters(updatedFilters);
    } else {
      const updatedFilters = { ...isFilters, [key]: [] };
      setIsFilters(updatedFilters);
    }
  }

  function getItemList(title, item) {
    if (title === "Summary Status") {
      return item;
    } else if (title === "Status" && iconMap[item]) {
      return getStatusItem(item, iconMap[item]);
    } else {
      return item;
    }
  }

  return (
    open &&
    title === openTitle && (
      <>
        <Fade in={open && title === openTitle}>
          <div
            className={`${styles.filterModal} ${isLast ? styles.isLastModal : ""} ${
              title.includes("Date")
                ? styles.filterModalDate
                : title === "Aircraft Reg" || title.includes("Department")
                ? styles.filterModalLarge
                : ""
            } ${styles.macScrollbar} ${styles.filterModalOpen}`}
            id={titleRef}
          >
            <div style={{ color: "#838383", marginBottom: "0px", padding: "0px 16px" }} key="">
              <Grid container columns={2}>
                <Grid item xs={title === "Aircraft Reg" || title === "Department" ? 1 : 2}>
                  {title}
                </Grid>
                {(title === "Aircraft Reg" || title === "Department") && (
                  <Grid
                    item
                    xs={1}
                    sx={{ display: "flex", justifyContent: "flex-end", paddingTop: "6px", color: "#088FD1" }}
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => handleSelectAll(isFilters[key].length > 0 ? false : true)}
                    >
                      {isFilters[key].length > 0 ? "Unselect" : "Select"} All
                    </span>
                  </Grid>
                )}
                {(title === "Aircraft Reg" || title === "Department") && (
                  <Grid item xs={2} sx={{ padding: "8px 0" }}>
                    <input
                      type="text"
                      className={styles.searchBox}
                      placeholder="Search..."
                      value={isSearch}
                      style={{ backgroundImage: `url(${MagnifyingGlass})`, backgroundRepeat: "no-repeat" }}
                      onChange={(e) => setIsSearch(e.target.value)}
                    />
                  </Grid>
                )}
              </Grid>
            </div>
            {title.includes("Date") ? (
              <div style={{ padding: "8px 16px" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label={""}
                    format="LL"
                    disableFuture={disableFuture}
                    value={isFilters[key].length === 0 ? undefined : dayjs(isFilters[key])}
                    onChange={(newValue) => {
                      setSelectDate(newValue);
                    }}
                  />
                </LocalizationProvider>
              </div>
            ) : (
              <ul className={`${styles.ul}`}>
                {isFilteredList.filter((e) => e !== undefined).length > 0 &&
                  isFilteredList?.map((item, index) => {
                    if (key === "paxCapacity") item = parseInt(item);
                    return (
                      <li className={`${styles.li}`} key={index}>
                        <Grid container columns={3}>
                          <Grid
                            item
                            xs={title !== "Summary Status" && title !== "Status" ? 2.5 : 3}
                            sx={{
                              padding: "8px 16px",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              color: "#333333",
                              fontSize: "14px",
                            }}
                            className={`${title === "Summary Status" || title === "Status" ? styles.onHover : ""} ${
                              ((title === "Summary Status" || title === "Status") &&
                                isFilters["summaryStatus"]?.filter((e) => e === item).length > 0) ||
                              isFilters["status"]?.filter((e) => e === item).length > 0
                                ? styles.selected
                                : ""
                            }`}
                            onClick={() => handleClick(item, title)}
                          >
                            {getItemList(title, item)}
                          </Grid>
                          {title !== "Summary Status" && title !== "Status" && (
                            <Grid item xs={0.5} sx={{ display: "flex", justifyContent: "flex-end" }}>
                              <StyledCheckbox
                                value={item}
                                checked={isFilters[key]?.filter((e) => e === item).length > 0}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    handleChecked(e.target.value);
                                  } else handleUnChecked(e.target.value);
                                }}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </li>
                    );
                  })}
                {list.length === 0 && (
                  <div style={{ marginTop: "15%", display: "flex", justifyContent: "center" }}>
                    Filter not available
                  </div>
                )}
              </ul>
            )}
          </div>
        </Fade>
      </>
    )
  );
};

export default FilterModal;
