import React from "react";
import { Modal, Box, Grid, Fade, Divider, Typography } from "@mui/material";
import { AdeChipStatus, AdeTab, AdeTabs } from "common/components";
import { useState } from "react";
import PropTypes from "prop-types";
import CloseIcon from "common/images/icons/elevade/CloseIconSmall.svg";
import Circle from "common/images/icons/elevade/CircleWhite.svg";
import Download from "common/images/icons/elevade/DownloadSimple.svg";
import Printer from "common/images/icons/elevade/PrinterBlack.svg";
import { flightReportPdf, flightReportPdfColoured } from "../aircraftMonitoring/generatePdf";
import { useSelector } from "react-redux";

export const ReportPreview = ({ data, openModal, setOpenModal, reportType, localTime }) => {
  const [index, setIndex] = useState(0);
  const [chipLabel, setChipLabel] = useState(true);
  const { userName, userProfile } = useSelector((state) => state.userEntity);
  const name = userProfile.fullName ?? userName;

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ py: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  const allyProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const style = {
    box: {
      position: "absolute",
      top: "100px",
      left: "50%",
      transform: "translate(-50%, 0)",
      width: "850px",
      bgcolor: "background.paper",
      borderRadius: "8px",
      boxShadow: 24,
      p: 4,
      paddingBottom: "10px",
      overflow: "auto",
      maxHeight: "calc(90vh - 100px)",
    },
  };

  const RawData = () => {
    return (
      <>
        <span style={{ fontFamily: "Gotham-Medium" }}>Telex Raw Data</span>
        <Grid style={{ border: "1px solid #D1D1D1", borderRadius: "4px", wordBreak: "break-word", marginTop: "10px" }}>
          <p> {data?.raw_msg}</p>
        </Grid>
      </>
    );
  };

  const pdfPrep = () => {
    let legData = [];
    legData.push({
      aircraftID: data?.ac_reg,
      date: data?.telex_gen?.value.split("T")[0],
      GMT: data?.telex_gen?.value.split("T")[1],
      flightNo: data?.flight_no,
      departure: data?.departure_airport,
      arrival: data?.destination_airport,
    });
    return legData;
  };

  const handleClose = () => {
    setOpenModal(false);
    setIndex(0);
  };
  const handleChange = (event, newValue) => {
    setIndex(newValue);
  };
  const toggleChip = () => {
    setChipLabel(!chipLabel);
  };
  const handleDownload = () => {
    chipLabel
      ? flightReportPdf(data?.warnings, data?.faults, pdfPrep(), name, "save", localTime)
      : flightReportPdfColoured(data?.warnings, data?.faults, pdfPrep(), name, "save", localTime);
  };
  const handlePrint = () => {
    chipLabel
      ? flightReportPdf(data?.warnings, data?.faults, pdfPrep(), name, "print", localTime)
      : flightReportPdfColoured(data?.warnings, data?.faults, pdfPrep(), name, "print", localTime);
  };
  const PdfPreview = () => {
    let frame = chipLabel
      ? flightReportPdf(data?.warnings, data?.faults, pdfPrep(), name, "", localTime)
      : flightReportPdfColoured(data?.warnings, data?.faults, pdfPrep(), name, "", localTime);

    return (
      <>
        <Grid style={{ paddingBottom: "10px" }}>
          <span style={{ fontFamily: "Gotham-Medium" }}>Report Preview</span>
          <Grid style={{ float: "right" }}>
            <span style={{ fontFamily: "Gotham-Book", color: "#838383" }}>Color Mode</span>
            <AdeChipStatus
              label={chipLabel ? "B&W" : "Color"}
              onClick={(e) => toggleChip()}
              sx={{ mx: 1 }}
              color={chipLabel ? "beta" : "blue"}
              icon={chipLabel && <img src={Circle} alt="round" />}
              deleteIcon={!chipLabel ? <img src={Circle} alt="round" /> : <img src="" alt="" />}
              onDelete={() => <></>}
            ></AdeChipStatus>
            <img
              src={Download}
              alt="Download"
              style={{ marginRight: "10px", cursor: "pointer" }}
              onClick={() => handleDownload()}
            />
            <img src={Printer} alt="Printer" style={{ cursor: "pointer" }} onClick={() => handlePrint()} />
          </Grid>
          <Grid id="preview-container">
            <iframe
              id="frame"
              name="frame"
              src={(frame += "#toolbar=0")}
              title="pdf"
              style={{
                border: "none",
                marginTop: "15px",
                width: "788px",
                minHeight: "530px",
                marginBottom: "5px",
              }}
            ></iframe>
          </Grid>
        </Grid>
      </>
    );
  };
  return (
    <Modal
      open={openModal}
      onClose={handleClose}
      BackdropProps={{
        style: {
          boxShadow: "none",
        },
      }}
      sx={{
        padding: "24px",
        zIndex: 9000,
      }}
    >
      <>
        {
          <Fade in={openModal}>
            <Box sx={style.box}>
              <>
                <Grid style={{ paddingBottom: "10px" }}>
                  <span style={{ fontFamily: "Gotham-Medium" }}>Post Flight Report</span>
                  <img
                    src={CloseIcon}
                    alt="eye"
                    style={{ cursor: "pointer", float: "right" }}
                    onClick={() => handleClose()}
                  />
                </Grid>
                <Divider orientation="horizontal" fullWidth />
                <AdeTabs
                  value={index}
                  onChange={handleChange}
                  centered
                  variant="fullWidth"
                  selectionFollowsFocus={true}
                  TabIndicatorProps={{
                    sx: {
                      height: "2px",
                    },
                  }}
                >
                  <AdeTab
                    className="tabOne"
                    label={`${reportType} Details`}
                    style={{ color: index === 0 ? "#088FD1" : "#838383" }}
                    {...allyProps(0)}
                  />
                  <AdeTab
                    className="tabTwo"
                    label="Telex Raw Data"
                    style={{ color: index === 1 ? "#088FD1" : "#838383" }}
                    {...allyProps(1)}
                  />
                </AdeTabs>
                <TabPanel value={index} index={0}>
                  <PdfPreview />
                </TabPanel>
                <TabPanel value={index} index={1}>
                  <RawData />
                </TabPanel>
              </>
            </Box>
          </Fade>
        }
      </>
    </Modal>
  );
};
