
import { Box, Button, Icon, TextField, Typography } from "@mui/material";
import React from 'react'
import { useRef, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import {
	HourglassIcon,
	SmallAcModleIcon,
	SmallAirplaneIcon,
	SmallLifebouyIcon,
} from "./Icons";
import DropDownCustom from "../../components/inputs/dropdowns/DropDownCustom/Main";
import FilterChipsMain from "../../components/inputs/FilterChips/FilterChipsMain";
import { useGetAllInventoryTypes, useGetSearchedInvItem } from "../../../models/custom/inventory-item-model";
import { useGetAllFleetDistinctedByModel, useGetAllFleets } from "../../../models/custom/fleet-model";
import { useGetAllItemStatuses } from "../../../models/custom/ac-item-model";

export default function SearchAdv({
	filterEqTypes,
	setFilterEqTypes,
	search,
	filterRegnos,
	filterAcModels,
	filterStatuses,
	filterLocationExist,
	setSearch,
	setFilterRegnos,
	setFilterAcModels,
	setFilterStatuses,
	setFilterLocationExist,
}) {
	const [searchEqType, setSearchEqType] = useState();
	const [searchRegNo, setSearchRegNo] = useState(undefined);
	const [filterChipLabelList, setFilterChipListLabel] = useState([]);

	const allInventoryTypes = useGetAllInventoryTypes({ searchEqType });
	const allFleetDistinctedByModel = useGetAllFleetDistinctedByModel();

	const allItemStatus = useGetAllItemStatuses();

	const allFleets = useGetAllFleets(true, { searchRegNo });

	const searchInputRef = useRef(null);
	const searchChip = search ? [
		{
			value: search,
			label: search,
			onRemove: () => {
				setSearch(undefined);
				//@ts-ignore
				searchInputRef.current.value = "";
			},
		},
	] : [];

	return (
		<Box>
			<Box
				sx={{
					width: "100%",
					display: "flex",
					justifyContent: "left",
					paddingBottom: "1em",
				}}
			>
				<TextField
					// label="Search"
					inputRef={searchInputRef}
					placeholder="Search"
					variant="standard"
					size="small"
					sx={{
						width: "400px",
						borderRight: "1px solid #ccc",
						"& input::placeholder": {
							fontSize: "12px",
						},
					}}
					onBlur={(e) => {
						setSearch(e.target.value);
					}}
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							setSearch(e.target.value);
						}
					}}
					InputProps={{
						disableUnderline: true,
						startAdornment: (
							<SearchIcon
								sx={{
									fontSize: 50,
									cursor: "pointer",
									padding: "0.3em",
									"&:hover": {
										background: "whitesmoke",
										borderRadius: "1.5em",
									},
								}}
							/>
						),
						// endAdornment: <ClearIcon
						//     onClick={() => {
						//         setSearch(null)
						//     }}
						//     sx={{
						//         cursor: "pointer",
						//         padding: "0.3em",
						//         color: '#E0E0E0',
						//         '&:hover': {
						//             background: "whitesmoke",
						//             borderRadius: "1.5em",
						//         }
						//     }}
						// />
					}}
				/>
				{(search ||
					filterRegnos.length > 0 ||
					filterAcModels.length > 0 ||
					filterStatuses.length > 0 ||
					filterEqTypes.length > 0) && (
						<Button
							style={{
								textTransform: "none",
							}}
							onClick={() => {
								setSearch(undefined);
								setFilterRegnos([]);
								setFilterAcModels([]);
								setFilterStatuses([]);
								setFilterEqTypes([]);
								//@ts-ignore
								searchInputRef.current.value = "";
							}}
						>
							<Typography
								sx={{ whiteSpace: "nowrap", marginLeft: "10px" }}
							>
								Clear all
							</Typography>
						</Button>
					)}
				<Box
					sx={{
						flexGrow: 1,
						display: "flex",
						justifyContent: "right",
						alignItems: "center",
					}}
				>
					<DropDownCustom
						optList={
							allInventoryTypes && !allInventoryTypes.isLoading ? allInventoryTypes?.data?.map((node) => {
								return {
									value: node?.code,
									label: node?.name,
								};
							}) : []
						}
						values={filterEqTypes}
						onChange={(values) => {
							setFilterEqTypes(values);
						}}
						onSearch={(search) => {
							setSearchEqType(search);
						}}
						buttonProps={{
							sx: {
								border: "none",
								textTransform: "none",
								color: "black",
								"&:hover": {
									border: "none",
								},
							},
							variant: "outlined",
							startIcon: <SmallLifebouyIcon color="#394458" />,
							textStr: "Eq. Type",
						}}
					/>
					<DropDownCustom
						optList={allFleetDistinctedByModel && !allFleetDistinctedByModel?.isLoading ? allFleetDistinctedByModel?.data?.map(({
							model,
						}) => {
							return model;
						}) : []}
						values={filterAcModels}
						onChange={(values) => {
							setFilterAcModels(values);
						}}
						buttonProps={{
							sx: {
								border: "none",
								textTransform: "none",
								color: "black",
								"&:hover": {
									border: "none",
								},
							},
							variant: "outlined",
							startIcon: <SmallAcModleIcon color="#394458" />,
							textStr: "Aircraft Model",
						}}
					/>
					<DropDownCustom
						optList={allFleets && !allFleets?.isLoading ? allFleets?.data?.map((node) => {
							return node?.regNo
						}) : []}
						onChange={(values) => {
							setFilterRegnos(values);
						}}
						onSearch={(search) => {
							setSearchRegNo(search);
						}}
						values={filterRegnos}
						buttonProps={{
							textStr: "Aircraft Reg.",
							sx: {
								border: "none",
								textTransform: "none",
								color: "black",
								"&:hover": {
									border: "none",
								},
							},
							variant: "outlined",
							startIcon: <SmallAirplaneIcon color="#394458" />,
						}}
					/>
					<DropDownCustom
						optList={
							allItemStatus && !allItemStatus?.isLoading ? allItemStatus?.data?.map((node) => {
								return {
									value: node?.code,
									label: node?.name,
								};
							}) : []
						}
						values={filterStatuses}
						onChange={(values) => {
							setFilterStatuses(values);
						}}
						buttonProps={{
							sx: {
								border: "none",
								textTransform: "none",
								color: "black",
								"&:hover": {
									border: "none",
								},
							},
							variant: "outlined",
							startIcon: <HourglassIcon color="#394458" />,
							textStr: "Status",
						}}
					/>
				</Box>
			</Box>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "left",
					marginBottom: "1em",
				}}
			>
				<FilterChipsMain
					chipList={[
						...generateFilterChipObject(
							filterChipLabelList,
							setFilterChipListLabel,
							filterEqTypes,
							setFilterEqTypes,
							allInventoryTypes?.data?.map((node) => {
								return {
									node: {
										label: node?.name,
										value: node?.code,
									},
								};
							})
						),
						...filterAcModels?.map((value) => {
							return {
								label: value,
								value,
								onRemove: (value) => {
									const newFilter = [...filterAcModels];
									const foundIndex =
										filterAcModels?.findIndex(
											({ value: innerVal }) => {
												return innerVal === value;
											}
										);
									newFilter.splice(foundIndex, 1);
									setFilterAcModels(newFilter);
								},
							};
						}),
						...filterRegnos?.map((value) => {
							return {
								label: value,
								value,
								onRemove: (value) => {
									const newFilter = [...filterRegnos];
									const foundIndex = filterRegnos?.findIndex(
										({ value: innerVal }) => {
											return innerVal === value;
										}
									);
									newFilter.splice(foundIndex, 1);
									setFilterRegnos(newFilter);
								},
							};
						}),
						...filterStatuses?.map((value) => {
							const labelNode = allItemStatus?.data?.find((node) => {
								return value === node?.code;
							});
							const label = labelNode?.name ?? '';
							return {
								label,
								value,
								onRemove: (value) => {
									const newFilter = [...filterStatuses];
									const foundIndex =
										filterStatuses?.findIndex(
											({ value: innerVal }) => {
												return innerVal === value;
											}
										);
									newFilter.splice(foundIndex, 1);
									setFilterStatuses(newFilter);
								},
							};
						}),
						...searchChip,
					]}
				/>
			</Box>
		</Box>
	);
}

function generateFilterChipObject(
	labelList,
	setLabelList,
	values,
	setValues,
	listFromApi
) {
	const filterObjects = values?.map((value) => {
		const foundInStored = labelList.find(({ label, value: innerVal }) => {
			return value === innerVal;
		});
		let label = "";
		if (foundInStored) {
			label = foundInStored.label;
		} else {
			const labelNode = listFromApi?.find(({ node }) => {
				return value === node?.value;
			})?.node;
			const newFilter = [...labelList];
			label = labelNode?.label ?? "";
			newFilter.push({
				label: label ?? "",
				value,
			});
			setLabelList(newFilter);
		}
		return {
			label,
			value,
			onRemove: (value) => {
				const newFilter = [...values];
				const foundIndex = values?.findIndex(({ value: innerVal }) => {
					return innerVal === value;
				});
				newFilter.splice(foundIndex, 1);
				setValues(newFilter);
			},
		};
	});
	return filterObjects;
}
