import React from 'react';
import { Grid, Modal, Box } from "@mui/material";
import IconClose from "common/images/icons/elevade/Icon-Close.svg";
import Button from "pages/elevade/component/Button";
import { makeAPIRequestRun } from "common/helpers";
import { makeAPIRequestMDB } from "common/helpers/makeAPIRequestMDB";
import { showNotificationElevade } from "common/helpers";

const DialogSubmitShiftReport = ({ open, setOpen, data, setTriggerApi, selectRdd }) => {
  const isArray = {
    ids: data,
  };

  const handleClose = () => {
    setOpen(false);
  };

  // TODO
  const handleConfirm = () => {
    // Call API for task submission
    const params = `api/assignment/submit`;
    const fetch = makeAPIRequestMDB(`put`, `${params}`, isArray);
    fetch.then((response) => {
      if (response.detail?.includes("success")) {
        setOpen(false);
        showNotificationElevade("success", "Assignment reports submitted!", [
          "The report assignment for today has been submitted. You can view it in Report Assignment Page",
        ]);
        setTriggerApi(true);
      } else showNotificationElevade("danger", "An error occured!", ["Please contact your system administartor"]);
    });
    if (selectRdd && selectRdd.length > 0) {
      const body = {
        acreg_msgid: selectRdd
      }
      const update = makeAPIRequestRun("post", "acarsdata/repetitivedefect/update-assignment-status", body)
    }
  };
  return (
    <>
      <Modal
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEnforceFocus // Let the user focus on elements outside the dialog
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            border: "0px solid #000",
            boxShadow: 24,
            padding: "16px 0px",
            borderRadius: "8px",
            outline: "none",
          }}
        >
          <Grid container columns={1}>
            <Grid item xs={0.8} sx={{ padding: "0px 24px 16px 24px", borderBottom: "1px solid #D1D1D1" }}>
              <span style={{ fontFamily: "Gotham-Medium" }}>Submit Shift Report</span>
            </Grid>
            <Grid
              item
              xs={0.2}
              sx={{
                padding: "0px 24px 16px 24px",
                borderBottom: "1px solid #D1D1D1",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <img
                src={IconClose}
                alt="icon-close"
                height={12}
                style={{ cursor: "pointer" }}
                onClick={() => handleClose()}
              />
            </Grid>
            <Grid item xs={1} sx={{ padding: "32px 64px" }}>
              <center>
                <div style={{ fontFamily: "Gotham-Book", color: "#838383", fontSize: "14px" }}>
                  This will submit all current assignments. Are you sure you want to submit the assignment report?
                </div>
              </center>
            </Grid>
            <Grid item xs={1} sx={{ display: "flex", justifyContent: "center", paddingBottom: "16px" }}>
              <Button title="Cancel" variant="" onClick={() => handleClose()} />
              <Button title="Submit" variant="fill" onClick={handleConfirm} />
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default DialogSubmitShiftReport;
