

/**
 * @type {LopaBoxSettings[]} generalizedBoxes
 */
export const generalizedBoxes = [
	{
		title: 'Others',
		code: 'ETC',
		boxSettings: {
			itemsInARowCap: 15,
			rowCountThreshold: 5,
			boxX: 60,
			boxY: 360,
			popupXOffset: -217,
			popupYOffset: -260,
			minWidth: 140,
			pointerSettings: {
				// pathTranslate : {x: 0,y: 0,},
				pointerPath: 'M 473 383 H 565 C 568 383 570 380 570 377 V 261',
				// pointerPosX: 145,
				// pointerPosY: 346,
			},
		}
	},
	// {
	// 	title: 'Galley Front',
	// 	code: 'GF',
	// 	boxSettings: {
	// 		// itemsInARowCap: 3,
	// 		// rowCountThreshold: 5, // @TODO without item it might cause the border to disappear
	// 		boxX: 190,
	// 		boxY: 80,
	// 		popupXOffset: -217,
	// 		popupYOffset: -410,
	// 		minWidth: 140,
	// 		pointerSettings: {
	// 			pathTranslate: { x: 0, y: 0, },
	// 			pointerPath: 'M 214 290 V 104.5',
	// 			pointerPosX: 214,
	// 			pointerPosY: 287,
	// 		},
	// 	}
	// },
]