import React, { useEffect, useState } from "react";
import IconAssignee from "common/images/icons/elevade/Filter-operator.svg";
import IconAssigneeActive from "common/images/icons/elevade/Filter-operator-active.svg";
import IconAtaChapter from "common/images/icons/elevade/BookOpen.svg";
import IconAtaChapterActive from "common/images/icons/elevade/BookOpen-active.svg";
import IconStatus from "common/images/icons/elevade/Icon-Status.svg";
import IconStatusActive from "common/images/icons/elevade/Icon-Status-active.svg";
import IconStatusChart from "common/images/icons/elevade/Icon-StatusChart.svg";
import IconStatusChartActive from "common/images/icons/elevade/Icon-StatusChart-active.svg";
import IconACType from "common/images/icons/elevade/AirplaneTilt.svg";
import IconACTypeActive from "common/images/icons/elevade/AirplaneTilt-active.svg";
import IconStation from "common/images/icons/elevade/Icon-Station.svg";
import IconStationActive from "common/images/icons/elevade/Icon-Station-active.svg";
import IconCalendarBlank from "common/images/icons/elevade/CalendarBlank.svg";
import IconCalendarBlankActive from "common/images/icons/elevade/CalendarBlank-active.svg";
import IconDepartment from "common/images/icons/elevade/Filter-operator.svg";
import IconDepartmentActive from "common/images/icons/elevade/Filter-operator-active.svg";
import IconSummaryStatus from "common/images/icons/elevade/Icon-SummaryStatus.svg";
import IconSummaryStatusActive from "common/images/icons/elevade/Icon-SummaryStatusActive.svg";
import styles from "./FilterBox.module.css";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import FilterModal from "./FilterModal";

const FilterBox = ({
  open,
  setOpen,
  data,
  title,
  objectKey,
  isFilters,
  setIsFilters,
  isLast,
  openTitle,
  setOpenTitle,
  disableFuture,
}) => {
  const handleClickAway = () => {
    // if (!title.includes("Date")) setOpen(false);
  };
  const [isSearch, setIsSearch] = useState(sessionStorage.getItem(`isSearch-${objectKey}`) || "");

  useEffect(() => {
    if (objectKey !== undefined) sessionStorage.setItem(`isSearch-${objectKey}`, isSearch);
  }, [isSearch]);

  useEffect(() => {
    if (!open) sessionStorage.removeItem(`isSearch-${objectKey}`);
  }, [open]);

  const titleToKeyMap = {
    Operator: "fleet",
    "ATA Chapter": "ata_head",
    "Aircraft Reg": "aircraftReg",
    Station: "location",
    Department: "assignedTo",
    "Raised Date": "raisedDate",
    "Expiry Date": "expiryDate",
    Status: "status",
  };

  let key = titleToKeyMap[title] || "";

  const iconMap = {
    Operator: { active: IconAssigneeActive, inactive: IconAssignee },
    "ATA Chapter": { active: IconAtaChapterActive, inactive: IconAtaChapter },
    "Aircraft Reg": { active: IconACTypeActive, inactive: IconACType },
    Status: { active: IconStatusChartActive, inactive: IconStatusChart },
    Station: { active: IconStationActive, inactive: IconStation },
    "Raised Date": { active: IconCalendarBlankActive, inactive: IconCalendarBlank },
    "Expiry Date": { active: IconCalendarBlankActive, inactive: IconCalendarBlank },
    Department: { active: IconDepartmentActive, inactive: IconDepartment },
    "Summary Status": { active: IconSummaryStatusActive, inactive: IconSummaryStatus },
  };

  function getIcon(title, active) {
    return iconMap[title] ? (active ? iconMap[title].active : iconMap[title].inactive) : null;
  }

  const getFilterLabel = (data, title) => {
    const items = data[key];
    if (items && items.length > 0) {
      const dataLabel = items[0];
      const additionalLabel = items.length > 1 ? ` +${items.length - 1}` : "";
      return dataLabel + additionalLabel;
    }
    return title;
  };

  const columnMap = {
    Operator: "fleet",
    "ATA Chapter": "ata_head",
    "Aircraft Reg": "aircraftReg",
    Station: "location",
    Department: "assignedTo",
    Status: "status",
  };

  function getFilterList(title) {
    const col = columnMap[title] || "";
    if (!col) return [];

    const options =
      data?.[objectKey]
        ?.map((item) => item)
        .filter((item) => item !== null && item.trim() !== "")
        .sort() || [];

    return [...new Set(options)];
  }

  function handleOpenFilter(title) {
    if (title !== undefined) {
      setOpenTitle(title);
      setOpen(title !== openTitle || !open);
    }
  }

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={`${styles.filterDivPosition} ${isFilters[key]?.length > 0 ? styles.active : ""}`}>
          <span className={`${styles.filterDiv}`} onClick={() => handleOpenFilter(title)}>
            <img
              src={getIcon(title, isFilters[key]?.length > 0 ? true : false)}
              height="16px"
              alt="filter-icon"
              className={styles.filterIcon}
            />
            <div style={{ marginTop: "4px" }}>{getFilterLabel(isFilters, title)}</div>
          </span>
          <FilterModal
            open={open}
            setOpen={setOpen}
            openTitle={openTitle}
            title={title}
            list={getFilterList(title)}
            isFilters={isFilters}
            setIsFilters={setIsFilters}
            isLast={isLast}
            disableFuture={disableFuture}
            isSearch={isSearch}
            setIsSearch={setIsSearch}
          />
        </div>
      </ClickAwayListener>
    </>
  );
};

export default FilterBox;
