import React, { useState, useEffect } from "react";
import { Grid, Box, Tooltip } from "@mui/material";
import MSNPin from "common/images/icons/elevade/MSN-pin.svg";
import Operator from "common/images/icons/elevade/Filter-operator.svg";
import Calendar from "common/images/icons/elevade/CalendarBlank.svg";
import Hourglass from "common/images/icons/elevade/Hourglass.svg";
import Airplane from "common/images/icons/elevade/Airplane.svg";
import AirplaneInFlight from "common/images/icons/elevade/AirplaneInFlight.svg";
import { ConvertUTCDatetime, calculatePercentage, formatDateDasr } from "../component/Helpers";
// import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import GetOOOIStatus from "./GetOOOIStatus";
import { format } from "date-fns";
import { dateFnsFormats } from "common/constant";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link } from "react-router-dom";
import Slider, { SliderThumb } from "@mui/material/Slider";
import AirplaneIcon from "common/images/icons/elevade/AirplaneIcon.svg";
import Eye from "common/images/icons/elevade/Eye.svg";
import { makeStyles } from "@mui/styles";
import { ReportPreview } from "./ReportPreview";
import { makeAPIRequestRun } from "common/helpers";
import styles from "./OOOIInfo.module.css";
import { makeAPIRequestMDB } from "common/helpers/makeAPIRequestMDB";
import IconAOG from "common/images/icons/elevade/Icon-AOGClear.svg";
import IconMaintenance from "common/images/icons/elevade/Icon-MaintenanceClear.svg";

const useStyles = makeStyles({
  // Styles for the card view's tooltip
  messageTootltip: {
    background: "#fffac3 0% 0% no-repeat padding-box",
    color: "#333333",
    boxShadow: "0px 3px 6px #00000040",
  },
});

const CheckMarkSlider = styled(Slider)(({ theme }) => ({
  height: 8,
  borderRadius: 5,
  "& .MuiSlider-thumb": {
    height: 20,
    width: 20,
    backgroundColor: "#9FCC2E",
    "&:hover": {
      boxShadow: "0 0 0 8px rgba(58, 133, 137, 0.16)",
    },
    "& .checkmark-bar": {
      height: 9,
      width: 1,
      backgroundColor: "currentColor",
      marginLeft: 1,
      marginRight: 1,
    },
  },
  "& .MuiSlider-track": {
    // backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 800 : 200],
    color: "#6AB47B",
  },
  "& .MuiSlider-rail": {
    color: theme.palette.mode === "dark" ? "#bfbfbf" : "#d8d8d8",
    borderRadius: 5,
    // backgroundColor: theme.palette.mode === "light" ? "#6AB47B" : "#D1D1D1",
    opacity: theme.palette.mode === "light" ? undefined : 1,
  },
}));

const CheckMarkThumbComponent = (props) => {
  const { children, ...other } = props;

  return (
    <SliderThumb {...other}>
      {children}
      <img src={AirplaneIcon} alt="AirplaneIcon" />
    </SliderThumb>
  );
};

const OOOIInfo = ({ id, data, localTime, activeOOOI }) => {
  const classes = useStyles();
  const isWeb = useMediaQuery("(min-width:1200px)");
  // const isTablet = useMediaQuery("(min-width:760px)");
  const isMobile = useMediaQuery("(max-width:900px)");

  const [reportData, setReportData] = useState(data);
  const [openModal, setOpenModal] = useState(false);
  const [coordinates, setCoordinates] = useState([]);
  const [counter, setCounter] = useState(10);
  const [isAircraftStatus, setIsAircraftStatus] = useState([]);

  // A function to draw a flight linear progress bar, based on off datetime, eta datetime & current datetime
  // The function will be calculate the percentage value every 1 second
  function LineProgress(data) {
    return (
      <Box>
        <CheckMarkSlider value={counter || 0} disabled components={{ Thumb: CheckMarkThumbComponent }} />
      </Box>
    );
  }
  const getOOOI = () => {
    const getCoordinates = makeAPIRequestRun(
      "get",
      `acarsdata/oooi/coordinates/${data?.AC_REG}/${data?.start_time?.value}`
    );
    getCoordinates.then((res) => {
      setCoordinates(res[0]);
      setCounter(data?.leg !== 1 && !data?.fromAircraftStatus ? 100 : calculatePercentage(res[0]));
    });
  };

  useEffect(() => {
    setReportData(data);
    getOOOI();
    fetchAircraftStatus();
    //eslint-disable-next-line
  }, [data]);

  useEffect(() => {}, [reportData]);

  // Fetching Aircraft Daily Status from DASR Module
  var currentDate = new Date();
  var options_ = { month: "long", day: "2-digit", year: "numeric" };
  var formattedDate = currentDate.toLocaleDateString("en-US", options_);
  currentDate = new Date(formattedDate);
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  const isToday = `${year}-${month}-${day}`;
  async function fetchAircraftStatus() {
    if (isToday !== "NaN-NaN-NaN") {
      const fetch = makeAPIRequestMDB(
        `get`,
        `api/aircraft/daily-status?date=${isToday}&aoc=${data?.Operator}&aircraft_reg=${id}`
      );
      fetch
        .then((response) => {
          setIsAircraftStatus(
            {
              aircraftReg: response?.data[0]?.aircraftReg,
              status: response?.data[0]?.status.status,
              tba: response?.data[0]?.updated_status.tba,
              ets: response?.data[0]?.updated_status.ets,
              etsTime: response?.data[0]?.updated_status.etsTime,
            }
            // })
          );
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  }

  const options = {
    month: "short",
    day: "numeric",
  };

  let EtaDateTime =
    data?.eta_datetime_format_view || data?.oooi?.eta_datetime_format_view || data?.on_datetime_format_view;
  let EtaUTC = new Date(data?.eta_datetime_format + "Z");

  if (data?.on_datetime_format !== null && data?.on_datetime_format !== undefined) {
    EtaDateTime = data?.oooi?.on_datetime_format_view || data?.on_datetime_format_view;
    EtaUTC = new Date(data?.oooi?.on_datetime_format || data?.on_datetime.value + "Z");
  }

  let OnDateTime = data?.on_datetime_format_view;

  if (!EtaDateTime) {
    EtaDateTime = data?.on_datetime_format_view;
    EtaUTC = new Date(data?.on_datetime_format + "Z");
  }

  if (localTime) {
    const DateUTC = EtaUTC.toLocaleString("en-US", options).split(",")[0];
    const TimeUTC = EtaUTC.toLocaleString().split(",")[1];
    if (EtaDateTime !== null && EtaDateTime !== undefined && EtaDateTime !== "") {
      EtaDateTime =
        DateUTC?.split(" ")[0] +
        ", " +
        DateUTC?.split(" ")[1].padStart(2, "0") +
        " " +
        TimeUTC?.split(":")[0] +
        ":" +
        TimeUTC?.split(":")[1];
    }
  }

  function chkEts(id) {
    let array = isAircraftStatus;
    if (array?.tba || array?.ets === null || array?.ets === "") return "TBA";
    else return formatDateDasr(array?.ets) + ", " + array?.etsTime?.split("T")[1].substring(0, 5);
  }

  return (
    <>
      <div className={styles.container}>
        <Grid container columns={16} sx={{ fontFamily: "Gotham-Book", fontSize: "12px" }}>
          <Grid
            item
            xs={16}
            sm={16}
            md={16}
            lg={2}
            xl={2}
            sx={{
              borderRight: isWeb ? "1px solid #D1D1D1" : "",
              paddingRight: isWeb ? "24px" : "",
              paddingBottom: "9px",
            }}
          >
            {isAircraftStatus.status !== "AOG" && isAircraftStatus.status !== "Maintenance" && (
              <>
                <div style={{ float: "left" }}>
                  <div style={{ marginBottom: "12px", fontFamily: "Gotham-Medium", fontSize: "14px" }}>{id}</div>
                  <div>{data?.departure_airport || "N/A"}</div>
                </div>
                <div style={{ float: "right" }}>
                  <div style={{ marginBottom: "12px", fontFamily: "Gotham-Medium", fontSize: "14px" }}>
                    {data?.flight_no}
                  </div>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    {data?.destination_airport || "N/A"}
                  </div>
                </div>
                {data?.leg === 1 ? (
                  <Tooltip
                    title={`Click for additional information`}
                    placement="bottom-end"
                    classes={{ tooltip: classes.messageTootltip }}
                    followCursor
                  >
                    <Link
                      id="map"
                      target="_blank"
                      to={{
                        pathname: `https://flightaware.com/live/flight/${id}`,
                      }}
                      rel="noreferrer"
                      style={{ clear: "both", paddingTop: "8px", cursor: "pointer" }}
                    >
                      {LineProgress(data?.oooi || data)}
                    </Link>
                  </Tooltip>
                ) : (
                  <div style={{ clear: "both", paddingTop: "8px" }}> {LineProgress(data?.oooi || data)}</div>
                )}
              </>
            )}
            {(isAircraftStatus.status === "AOG" || isAircraftStatus.status === "Maintenance") && (
              <>
                <div style={{ float: "left" }}>
                  <div style={{ marginBottom: "12px", fontFamily: "Gotham-Medium", fontSize: "14px" }}>{id}</div>
                </div>
                <div style={{ float: "right" }}>
                  <div style={{ marginBottom: "12px", fontFamily: "Gotham-Medium", fontSize: "14px" }}>
                    {data?.flight_no}
                  </div>
                </div>
                <div style={{ clear: "both", paddingTop: "8px", display: "flex" }}>
                  {isAircraftStatus.status === "AOG" && (
                    <>
                      <div>
                        <img src={IconAOG} alt="icon-aog" />
                      </div>
                      <span style={{ marginLeft: "10px", fontFamily: "Gotham-Medium", fontSize: "12px" }}>
                        Aircraft on Ground (AOG)
                      </span>
                    </>
                  )}
                  {isAircraftStatus.status === "Maintenance" && (
                    <>
                      <div>
                        <img src={IconMaintenance} alt="icon-maintenance" height={20} style={{ marginTop: "4px" }} />
                      </div>
                      <span style={{ marginLeft: "10px", fontFamily: "Gotham-Medium", fontSize: "12px" }}>
                        Aircraft on Maintenance (MTC)
                      </span>
                    </>
                  )}
                </div>
              </>
            )}
          </Grid>
          {!isWeb && (
            <Grid item xs={16} sx={{ paddingBottom: "10px" }}>
              <div style={{ clear: "both", fontSize: "10px", color: "#838383" }}>
                {isAircraftStatus.status !== "AOG" && isAircraftStatus.status !== "Maintenance" && (
                  <>
                    {data?.on_datetime_format !== null ? "ARR:" : "ETA:"} {EtaDateTime || "--:--"}{" "}
                  </>
                )}
                {(isAircraftStatus.status === "AOG" || isAircraftStatus.status === "Maintenance") && <>ETS: TBA</>}
              </div>
            </Grid>
          )}
          <Grid
            item
            xs={16}
            sm={16}
            md={16}
            lg={9}
            xl={9}
            sx={{
              borderRight: isWeb ? "1px solid #D1D1D1" : "",
              paddingBottom: "9px",
            }}
          >
            <Grid container columns={8}>
              <Grid item xs={1} sm={1} md={1} lg={0.5} xl={0.5} sx={{ paddingLeft: isWeb ? "24px" : "" }}>
                <img src={MSNPin} alt="msn" style={{ marginTop: "-3px" }} />
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={2} xl={2} sx={{}}>
                <span style={{ fontFamily: "Gotham-Medium", marginRight: "8px" }}>MSN:</span>
                <span>{data?.MSN}</span>
              </Grid>
              <Grid item xs={1} sm={1} md={1} lg={0.5} xl={0.5} sx={{ paddingLeft: "24px" }}>
                <img src={Operator} alt="operator" style={{ marginTop: "-3px" }} />
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={2} xl={2} sx={{ paddingLeft: isMobile ? "12px" : "" }}>
                <span style={{ fontFamily: "Gotham-Medium", marginRight: "8px" }}>Operator:</span>
                <span>{data?.Operator}</span>
              </Grid>
              <Grid item xs={1} sm={1} md={1} lg={0.5} xl={0.5} sx={{ paddingLeft: isWeb ? "24px" : "" }}>
                <img
                  src={Calendar}
                  alt="operator"
                  style={{
                    marginTop: "-3px",
                    paddingTop: !isWeb ? "18px" : "0px",
                  }}
                />
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={2.4} xl={2.4} sx={{ paddingTop: !isWeb ? "18px" : "0px" }}>
                <span style={{ fontFamily: "Gotham-Medium", marginRight: "8px" }}>MFG. Date:</span>
                {isMobile && <br />}
                <span>{data?.Manufacturing_Date?.value}</span>
              </Grid>
              <Grid item xs={1} sm={1} md={1} lg={0.5} xl={0.5} sx={{ paddingLeft: "24px", paddingTop: "18px" }}>
                <img src={Hourglass} alt="msn" style={{ marginTop: "-3px" }} />
              </Grid>
              <Grid
                item
                xs={3}
                sm={3}
                md={3}
                lg={2}
                xl={2}
                sx={{ paddingTop: "18px", paddingLeft: isMobile ? "12px" : "" }}
              >
                <span style={{ fontFamily: "Gotham-Medium", marginRight: "8px" }}>OOOI Status:</span>
                {<GetOOOIStatus data={data?.oooi || data} />}
              </Grid>
              <Grid
                item
                xs={1}
                sm={1}
                md={1}
                lg={0.5}
                xl={0.5}
                sx={{ paddingLeft: isWeb ? "24px" : "", paddingTop: "18px" }}
              >
                <img src={Airplane} alt="msn" style={{ marginTop: "-3px" }} />
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={2} xl={2} sx={{ paddingTop: "18px" }}>
                <span style={{ fontFamily: "Gotham-Medium", marginRight: "8px" }}>Aircraft Model:</span>
                {isMobile && <br />}
                <span>
                  {data?.Type}-{data?.Model}
                </span>
              </Grid>
              <Grid item xs={1} sm={1} md={1} lg={0.5} xl={0.5} sx={{ paddingLeft: "24px", paddingTop: "18px" }}>
                <img src={AirplaneInFlight} alt="operator" style={{ marginTop: "-3px" }} />
              </Grid>
              <Grid
                item
                xs={3}
                sm={3}
                md={3}
                lg={2.4}
                xl={2.4}
                sx={{ paddingTop: "18px", paddingLeft: isMobile ? "12px" : "" }}
              >
                <span
                  style={{
                    fontFamily: "Gotham-Medium",
                    marginRight: "8px",
                    whiteSpace: isMobile ? "nowrap" : "normal",
                  }}
                >
                  FH/FC Count:
                </span>
                {isMobile && <br />}
                <span>
                  {parseInt(data?.flight_hour).toLocaleString()} FH / {isMobile && <br />}{" "}
                  {parseInt(data?.flight_cycle).toLocaleString()} FC
                </span>
                <span>
                  {data?.flight_hour !== null ? parseInt(data?.flight_hour).toLocaleString() + " FH" : "-"} /{" "}
                  {data?.flight_cycle !== null ? parseInt(data?.flight_cycle).toLocaleString() + " FC" : "-"}
                </span>
                <div
                  style={{
                    fontStyle: "italic",
                    fontSize: "10px",
                    color: "#838383",
                    paddingTop: "4px",
                  }}
                >
                  {data?.fhc_updated_datetime?.value &&
                    "*AMOS as of " +
                      format(new Date(data?.fhc_updated_datetime?.value), dateFnsFormats.STANDARD_DATETIME)}
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={16} sm={16} md={16} lg={5} xl={5} sx={{ paddingBottom: "9px" }}>
            <Grid container columns={6} sx={{ display: activeOOOI ? "" : "none" }}>
              <Grid item xs={3} sm={3} md={3} lg={2} xl={2} sx={{ paddingLeft: isWeb ? "24px" : "" }}>
                <span style={{ fontFamily: "Gotham-Medium", marginRight: "8px" }}>Report</span> <br />
                <Grid
                  style={{
                    cursor: data?.flight_report_type?.match("PFR") && "pointer",
                  }}
                  onClick={() => {
                    data?.flight_report_type?.match("PFR") && setOpenModal(true);
                  }}
                >
                  {data?.flight_report_type?.match("PFR") && <img src={Eye} alt="eye" style={{ marginTop: "-3px" }} />}{" "}
                  <span
                    style={{
                      color: data?.flight_report_type?.match("PFR") && "#088FD1",
                      fontFamily: "Gotham-Medium",
                    }}
                    // style={{ color: "#333333", fontFamily: "Gotham-Book" }}
                  >
                    {data?.flight_report_type?.match("PFR") ? "PFR" : "No PFR"}
                  </span>
                </Grid>
              </Grid>

              <Grid item xs={3} sm={3} md={3} lg={2} xl={2} sx={{ paddingLeft: isWeb ? "24px" : "" }}>
                <span style={{ fontFamily: "Gotham-Medium", marginRight: "8px" }}>OUT</span> <br />
                <span>
                  {/* {data?.out_datetime_format !== null
                    ? ConvertLocaleUTCDatetime(data?.out_datetime_format, localTime)
                    : "n/a"} */}
                  {ConvertUTCDatetime(data?.out_datetime_format, localTime)}
                </span>
              </Grid>

              <Grid item xs={3} sm={3} md={3} lg={2} xl={2} sx={{ paddingLeft: isWeb ? "24px" : "" }}>
                <span style={{ fontFamily: "Gotham-Medium", marginRight: "8px" }}>OFF</span> <br />
                <span>
                  {data?.off_datetime_format !== null
                    ? ConvertUTCDatetime(data?.off_datetime_format, localTime)
                    : "n/a"}
                </span>
              </Grid>
              <Grid
                item
                xs={3}
                sm={3}
                md={3}
                lg={2}
                xl={2}
                sx={{ paddingLeft: isWeb ? "24px" : "", paddingTop: "10px" }}
              >
                <span style={{ marginRight: "8px" }} />
              </Grid>

              <Grid
                item
                xs={3}
                sm={3}
                md={3}
                lg={2}
                xl={2}
                sx={{ paddingLeft: isWeb ? "24px" : "", paddingTop: "10px" }}
              >
                <span style={{ fontFamily: "Gotham-Medium", marginRight: "8px" }}>ON</span> <br />
                <span>
                  {data?.on_datetime_format !== null ? ConvertUTCDatetime(data?.on_datetime_format, localTime) : "n/a"}
                </span>
              </Grid>

              <Grid
                item
                xs={3}
                sm={3}
                md={3}
                lg={2}
                xl={2}
                sx={{ paddingLeft: isWeb ? "24px" : "", paddingTop: "10px" }}
              >
                <span style={{ fontFamily: "Gotham-Medium", marginRight: "8px" }}>IN</span> <br />
                <span>
                  {data?.in_datetime_format !== null ? ConvertUTCDatetime(data?.in_datetime_format, localTime) : "n/a"}
                </span>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {isWeb && (
          <div style={{ clear: "both", fontSize: "10px", color: "#838383", marginTop: "-9px" }}>
            {isAircraftStatus.status !== "AOG" && isAircraftStatus.status !== "Maintenance" && (
              <>
                {data?.on_datetime_format !== null ? "ARR:" : "ETA:"} {EtaDateTime || "--:--"}{" "}
              </>
            )}
            {(isAircraftStatus.status === "AOG" || isAircraftStatus.status === "Maintenance") && <>ETS: {chkEts(id)}</>}
          </div>
        )}
      </div>
      <ReportPreview
        data={reportData?.report}
        openModal={openModal}
        setOpenModal={setOpenModal}
        reportType={reportData?.flight_report_type?.match("PFR") || reportData?.flight_report_type?.match("CFR") || ""}
        localTime={localTime}
      />
    </>
  );
};

export default OOOIInfo;
