export const seatsB = [
    // 1-31
    {
        x: 0,
        y: -26,
        row: 'b',
        col: 1,
		rotation : 0,
    },
    {
        x: 28,
        y: -26,
        row: 'b',
        col: 2,
		rotation : 0,
    },
    {
        x: 56,
        y: -26,
        row: 'b',
        col: 3,
		rotation : 0,
    },
    {
        x: 84,
        y: -26,
        row: 'b',
        col: 4,
		rotation : 0,
    },
    {
        x: 112,
        y: -26,
        row: 'b',
        col: 5,
		rotation : 0,
    },
    {
        x: 140,
        y: -26,
        row: 'b',
        col: 6,
		rotation : 0,
    },
    {
        x: 168,
        y: -26,
        row: 'b',
        col: 7,
		rotation : 0,
    },
    {
        x: 196,
        y: -26,
        row: 'b',
        col: 8,
		rotation : 0,
    },
    {
        x: 224,
        y: -26,
        row: 'b',
        col: 9,
		rotation : 0,
    },
    {
        x: 252,
        y: -26,
        row: 'b',
        col: 10,
		rotation : 0,
    },
    {
        x: 280,
        y: -26,
        row: 'b',
        col: 11,
		rotation : 0,
    },
    {
        // divided by isle
        x: 324,
        y: -26,
        row: 'b',
        col: 12,
		rotation : 0,
    },
    // row 13 is not there
    {
        // divided by isle
        x: 368,
        y: -26,
        row: 'b',
        col: 14,
		rotation : 0,
    },
    {
        x: 396,
        y: -26,
        row: 'b',
        col: 15,
		rotation : 0,
    },
    {
        x: 424,
        y: -26,
        row: 'b',
        col: 16,
		rotation : 0,
    },
    {
        x: 452,
        y: -26,
        row: 'b',
        col: 17,
		rotation : 0,
    },
    {
        x: 480,
        y: -26,
        row: 'b',
        col: 18,
		rotation : 0,
    },
    {
        x: 508,
        y: -26,
        row: 'b',
        col: 19,
		rotation : 0,
    },
    {
        x: 536,
        y: -26,
        row: 'b',
        col: 20,
		rotation : 0,
    },
    {
        x: 564,
        y: -26,
        row: 'b',
        col: 21,
		rotation : 0,
    },
    {
        x: 592,
        y: -26,
        row: 'b',
        col: 22,
		rotation : 0,
    },
    {
        x: 620,
        y: -26,
        row: 'b',
        col: 23,
		rotation : 0,
    },
    {
        x: 648,
        y: -26,
        row: 'b',
        col: 24,
		rotation : 0,
    },
    {
        x: 676,
        y: -26,
        row: 'b',
        col: 25,
		rotation : 0,
    },
    {
        x: 704,
        y: -26,
        row: 'b',
        col: 26,
		rotation : 0,
    },
    {
        x: 732,
        y: -26,
        row: 'b',
        col: 27,
		rotation : 0,
    },
    {
        x: 760,
        y: -26,
        row: 'b',
        col: 28,
		rotation : 0,
    },
    {
        x: 788,
        y: -26,
        row: 'b',
        col: 29,
		rotation : 0,
    },
    {
        x: 816,
        y: -26,
        row: 'b',
        col: 30,
		rotation : 0,
    },
    {
        x: 844,
        y: -26,
        row: 'b',
        col: 31,
		rotation : 0,
    },
];
