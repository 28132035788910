import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import styles from "./Dasr.module.css";
import { Grid } from "@mui/material";
import IconTotalAircraft from "common/images/icons/elevade/Icon-TotalAircraft.svg";
import IconScheduled from "common/images/icons/elevade/AirplaneInFlight.svg";
import IconLimitation from "common/images/icons/elevade/Icon-LimitationDashboard.svg";
import IconAOCs from "common/images/icons/elevade/Icon-AOCs.svg";
// import IconHUB from "common/images/icons/elevade/Icon-HUB.svg";
import IconStandby from "common/images/icons/elevade/Icon-Standby.svg";
import IconUnderMaintenance from "common/images/icons/elevade/Icon-UnderMaintenance.svg";
import IconAOG from "common/images/icons/elevade/Icon-AOG.svg";
import IconStorage from "common/images/icons/elevade/Icon-Storage.svg";
import IconMoreDetails from "common/images/icons/elevade/Icon-ArrowRightBlue.svg";
import IconAirplaneTilt from "common/images/icons/elevade/AirplaneTilt.svg";
import { PieChart } from "../component/Charts";
import DDMLSummary from "./component/DDMLSummary";
import StandbyAircraft from "./component/StandbyAircraft";
// import HubAircraft from "./component/HubAircraft";
import IconNewReportWhite from "common/images/icons/elevade/Icon-NewReportWhite.svg";
import { makeAPIRequestMDB } from "common/helpers/makeAPIRequestMDB";
import DateToggle from "./component/DateToggle";
import ElevadeDrawer from "./component/Drawer";
import DDML from "./aircraftReport/DDML";
import Button from "pages/elevade/component/Button";
import { useMediaQuery } from "@mui/material";
// import IconCalendarDASR from "common/images/icons/elevade/Icon-CalendarDASR.svg";
import Overlay from "../component/Overlay";
import usePostRecentActivity from "common/helpers/hooks/usePostRecentActivity";
import { Helmet } from "react-helmet";

const Dashboard = ({ aoc, isLoading, setIsLoading, aircraftData, setAircraftData }) => {
  const { userPermission } = useSelector((state) => state.userEntity);
  const addDDMlPermission = userPermission.filter((perm) => perm.code === "DASR-003");
  const [subLoading, setSubLoading] = useState(false);
  const isMobile = useMediaQuery("(max-width:900px)");
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [chartData, setChartData] = useState();
  const [hubData, setHubData] = useState();
  const [today, setToday] = useState();
  const [date, setDate] = useState();
  const [totalPlanned, setTotalPlanned] = useState();
  const [dataDDML, setDataDDML] = useState();
  const [dataDDMLLimitation, setDataDDMLLimitation] = useState();
  const [edit, setEdit] = useState(true);
  const [openNotification, setOpenNotification] = useState(false);
  const [newForm, setNewForm] = useState(false);
  const [triggerApi, setTriggerApi] = useState(true);
  const [selectToMove, setSelectToMove] = useState([]);
  const [openDialogMoveToAssignment, setOpenDialogMoveToAssignment] = useState(false);
  const [aircraftList, setAircraftList] = useState([]);
  const [ddmlLoading, setDddmlLoading] = useState(true);

  function setInitialDate() {
    var currentDate = new Date();
    var options = { month: "long", day: "2-digit", year: "numeric" };
    var formattedDate = currentDate.toLocaleDateString("en-US", options);
    setDate(formattedDate);
    setToday(formattedDate);
    // Set yesterday's date
    currentDate.setDate(currentDate.getDate() - 1);
    // setYesterday(currentDate.toLocaleDateString("en-US", options));
  }

  useEffect(() => {
    setInitialDate();
  }, []);

  const currentDate = new Date(date);
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  const isToday = `${year}-${month}-${day}`;
  let theList = [];

  // API Calling
  useEffect(() => {
    async function FetchData() {
      setSubLoading(true);

      if (isToday !== "NaN-NaN-NaN" && aoc !== undefined) {
        FetchDailyStatus();
      }
    }

    async function FetchDailyStatus() {
      const fetch = makeAPIRequestMDB(`get`, `api/aircraft/daily-status?aoc=${aoc}&date=${isToday}`);
      fetch
        .then((response) => {
          setAircraftData(response?.data?.sort((a, b) => a.aircraftReg?.localeCompare(b.aircraftReg)));
          let list = response?.data?.sort((a, b) => a.aircraftReg.localeCompare(b.aircraftReg));
          // eslint-disable-next-line no-unused-expressions
          list?.map((e) => {
            return theList.push({ id: e.aircraftReg, label: e.aircraftReg, name: e.aircraftReg });
          });
          setAircraftList(theList);
          setSubLoading(false);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }

    async function fetchDDML() {
      setDddmlLoading(true);
      const fetch = makeAPIRequestMDB(`get`, `api/ddml/ddml_summary?aoc=${aoc}&form_status=open`);
      fetch
        .then((response) => {
          setDataDDML(response.data);
          setDddmlLoading(false);
        })
        .catch((error) => {
          console.log("error", error);
        });

      // Fetching data for Limitations
      const fetchLimitation = makeAPIRequestMDB(`get`, `api/ddml/limitations?aoc=${aoc}&form_status=open`);
      fetchLimitation
        .then((response) => {
          setDataDDMLLimitation(response.data?.ddmls_list);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }

    async function fetchSchedulePlanned() {
      var currentDate = new Date(date);
      var options = { month: "long", day: "2-digit", year: "numeric" };
      var formattedDate = currentDate.toLocaleDateString("en-US", options);
      currentDate = new Date(formattedDate);
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const day = String(currentDate.getDate()).padStart(2, "0");
      const isToday = `${year}-${month}-${day}`;
      if (isToday !== "NaN-NaN-NaN" && aoc !== undefined) {
        const fetch = makeAPIRequestMDB(`get`, `api/aircraft/planned-schedule?date=${isToday}&aoc=${aoc}`);
        fetch
          .then((response) => {
            setTotalPlanned(response?.data?.totalAircraft);
          })
          .catch((error) => {
            console.log("error", error);
          });
      }
    }

    FetchData();
    fetchSchedulePlanned();
    if (aoc !== undefined && !subLoading) fetchDDML();
    // eslint-disable-next-line
  }, [location, date, aoc, triggerApi]);

  // Set the loading status = false once the data has been fully fetched
  useEffect(() => {
    if (aircraftData?.length > 0) setIsLoading(false);
    // eslint-disable-next-line
  }, [aircraftData]);

  usePostRecentActivity("You viewed DASR Dashboard", "DASR Overview", "DASR Dashboard");

  function formattedDate(type, date) {
    const currentDate = date ? new Date(date) : new Date();
    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const monthsOfYear = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const dayOfWeek = daysOfWeek[currentDate.getDay()];
    const month = monthsOfYear[currentDate.getMonth()];
    const day = currentDate.getDate();
    const year = currentDate.getFullYear();
    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();

    return type
      ? `${month} ${day}, ${year} ${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes}`
      : `${dayOfWeek}, ${month} ${day}, ${year}`;
  }

  useEffect(() => {
    data_(aircraftData);
    data_hub(aircraftData);
    // eslint-disable-next-line
  }, [aircraftData]);

  function data_(statusData) {
    let data = [];
    let returnData = [{ total: 0, data: {} }];
    // Filter data for Hub = BWN
    data.push({
      name: "BWN",
      value:
        statusData?.filter((e) => {
          return e.status.hub === "BWN";
        }).length ?? 0,
    });
    // Filter data for Hub != BWN
    data.push({
      name: "HUB",
      value:
        statusData?.filter((e) => {
          return e.status.hub === "BKI" || e.status.hub === "KCH" || e.status.hub === "JHB" || e.status.hub === "PEN";
        }).length ?? 0,
    });
    // Filter data for Remarks = Standby
    data.push({
      name: "Standby",
      value:
        statusData?.filter((e) => {
          return e.status.status === "Standby";
        }).length ?? 0,
    });
    // Filter data for Remarks = Under Maintenance
    data.push({
      name: "Under Maintenance",
      value:
        statusData?.filter((e) => {
          return e.status.status === "Maintenance";
        }).length ?? 0,
    });
    // Filter data for Remarks = AOG
    data.push({
      name: "AOG",
      value:
        statusData?.filter((e) => {
          return e.status.status === "AOG";
        }).length ?? 0,
    });
    // Filter data for Remarks = Storage
    data.push({
      name: "Storage",
      value:
        statusData?.filter((e) => {
          return e.status.status === "Storage";
        }).length ?? 0,
    });
    // Filter data for Cargo
    data.push({
      name: "Cargo",
      value:
        statusData?.filter((e) => {
          return (
            e.fleetType === "Cargo Aircraft" && (e.status.status === "Scheduled" || e.status.status === "Utilised")
          );
        }).length ?? 0,
    });
    // Filter data for Cargo Standby
    data.push({
      name: "Cargo Standby",
      value:
        statusData?.filter((e) => {
          return e.fleetType === "Cargo Aircraft" && e.status.status === "Standby";
        }).length ?? 0,
    });
    // Calculate Total Aircraft
    let total = statusData?.filter((e) => {
      return (
        e.status.hub !== "" &&
        (e.status.status === "Standby" ||
          e.status.status === "Storage" ||
          e.status.status === "Utilised" ||
          e.status.status === "AOG" ||
          e.status.status === "Maintenance" ||
          e.status.status === "Scheduled")
      );
    }).length;
    // Set the data for chart
    let firstObject = returnData[0];
    let dataProperty = firstObject;
    dataProperty.total = total;
    dataProperty.data = data;
    setChartData(dataProperty);
  }

  function data_hub(data) {
    let data_ = [];
    // Filter data for Hub = BKI
    data_.push({
      name: "BKI",
      value:
        data?.filter((e) => {
          return e.status.hub === "BKI";
        }).length ?? 0,
    });
    // Filter data for Hub = KCH
    data_.push({
      name: "KCH",
      value:
        data?.filter((e) => {
          return e.status.hub === "KCH";
        }).length ?? 0,
    });
    // Filter data for Hub = JHB
    data_.push({
      name: "JHB",
      value:
        data?.filter((e) => {
          return e.status.hub === "JHB";
        }).length ?? 0,
    });
    // Filter data for Hub = PEN
    data_.push({
      name: "PEN",
      value:
        data?.filter((e) => {
          return e.status.hub === "PEN";
        }).length ?? 0,
    });
    setHubData(data_);
  }

  const AircraftStatus = () => {
    return (
      <>
        <div className={styles.divCard}>
          <Grid container columns={10}>
            <Grid
              item
              xs={10}
              sm={10}
              md={10}
              lg={10}
              xl={10}
              className={`${styles.gridTitle} ${styles.gridTitleCard}`}
            >
              {aoc + ` Aircraft Status`}
            </Grid>
            <Grid item xs={5} sm={5} md={5} lg={2} xl={2}>
              <div className={`${styles.divCard} ${styles.divCardBlue}`} style={{ width: "90%" }}>
                <Link to={"/fleet/dasr/aircraft-list"}>
                  <Grid container columns={4}>
                    <Grid
                      item
                      xs={1}
                      className={styles.gridJustifyCenter}
                      sx={{ paddingTop: "13px", paddingRight: "10px" }}
                    >
                      <img src={IconTotalAircraft} alt="icon-total-aircraft" height={24} />
                    </Grid>
                    <Grid item xs={3}>
                      <div style={{ fontSize: "14px" }}>Total Aircraft</div>
                      <div style={{ fontFamily: "Gotham-Medium", fontSize: "24px" }}>
                        {(aircraftData?.length > 0 && aircraftData?.length) || 0}
                      </div>
                    </Grid>
                  </Grid>
                </Link>
              </div>
              <div
                className={`${styles.divCard} ${styles.divCardBlue} ${styles.noPointerEvent}`}
                style={{ width: "90%" }}
              >
                <Grid container columns={4}>
                  <Grid
                    item
                    xs={1}
                    className={styles.gridJustifyCenter}
                    sx={{ paddingTop: "13px", paddingRight: "10px" }}
                  >
                    <img src={IconScheduled} alt="icon-scheduled" height={24} />
                  </Grid>
                  <Grid item xs={3}>
                    <div style={{ fontSize: "14px" }}>Active Aircraft</div>
                    <div style={{ fontFamily: "Gotham-Medium", fontSize: "24px" }}>
                      {(aircraftData?.length > 0 &&
                        aircraftData?.filter((e) => {
                          return (
                            (e.status.status === "Scheduled" ||
                              e.status.status === "Standby" ||
                              e.status.status === "Utilised") &&
                            e.status.hub !== ""
                          );
                        }).length) ||
                        0}
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div
                className={`${styles.divCard} ${styles.divCardBlue} ${styles.noPointerEvent}`}
                style={{ width: "90%" }}
              >
                {/* <Link to={"/fleet/dasr/aircraft-status"}> */}
                <Grid container columns={4}>
                  <Grid
                    item
                    xs={1}
                    className={styles.gridJustifyCenter}
                    sx={{ paddingTop: "13px", paddingRight: "10px" }}
                  >
                    <img src={IconAirplaneTilt} alt="icon-limitation" height={24} />
                  </Grid>
                  <Grid item xs={3}>
                    <div style={{ fontSize: "14px" }}>Planned Operations</div>
                    <div style={{ fontFamily: "Gotham-Medium", fontSize: "24px" }}>{totalPlanned || 0}</div>
                  </Grid>
                </Grid>
                {/* </Link> */}
              </div>
              <div className={styles.asOfInfo}>*as of {formattedDate("includeTime")}</div>
            </Grid>
            <Grid item xs={5} sm={5} md={5} lg={3.5} xl={3}>
              {!isLoading && !isMobile && !subLoading && <PieChart data={chartData} hubData={hubData} />}
            </Grid>
            <Grid item xs={10} sm={10} md={10} lg={4} xl={5}>
              <ScheduledOperations />
              {/* <Cargo /> */}
            </Grid>
            <Grid item xs={10} className={`${styles.gridJustifyCenter}`}>
              <Link to="/fleet/dasr/aircraft-report" className={`${styles.moreDetails}`}>
                View DDML Reports
                <img src={IconMoreDetails} alt="more-details" style={{ marginLeft: "10px", marginTop: "-2px" }} />
              </Link>
            </Grid>
          </Grid>
        </div>
      </>
    );
  };

  const ScheduledOperations = () => {
    return (
      <>
        <Grid container columns={4}>
          <Grid item xs={4} className={styles.gridTitle} sx={{ paddingBottom: "23px" }}>
            Operation Summary
          </Grid>
          <Grid item xs={4}>
            <Grid container columns={3}>
              <Grid item xs={1} sx={{ paddingBottom: "32px" }}>
                <Grid container columns={3}>
                  <Grid item xs={3} sx={{ paddingLeft: "8px", display: "flex" }}>
                    <img src={IconAOCs} alt="icon-total-aircraft" height={24} />
                    <div style={{ marginLeft: "8px" }}>
                      <div style={{ fontSize: "14px" }}>Main Base</div>
                      <div style={{ fontFamily: "Gotham-Medium", fontSize: "24px" }}>
                        {(aircraftData?.length > 0 &&
                          aircraftData.filter((e) => {
                            return (
                              e.status.hub === "BWN" &&
                              e.fleetType === "Passenger Aircraft" &&
                              (e.status.status === "Scheduled" ||
                                e.status.status === "Standby" ||
                                e.status.status === "Utilised")
                            );
                          }).length) ||
                          0}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={1} sx={{ paddingBottom: "32px" }}>
                <Grid container columns={3}>
                  <Grid item xs={3} sx={{ paddingLeft: "8px", display: "flex" }}>
                    <Link to="/fleet/dasr/aircraft-status?tab=4">
                      <img src={IconStandby} alt="icon-total-aircraft" height={24} />
                    </Link>
                    <div style={{ marginLeft: "8px" }}>
                      <div style={{ fontSize: "14px" }}>
                        <Link to="/fleet/dasr/aircraft-status?tab=4">Standby</Link>
                      </div>
                      <div style={{ fontFamily: "Gotham-Medium", fontSize: "24px" }}>
                        <Link to="/fleet/dasr/aircraft-status?tab=4">
                          {(aircraftData?.length > 0 &&
                            aircraftData?.filter((e) => {
                              return (
                                e.status.status === "Scheduled" ||
                                e.status.status === "Standby" ||
                                e.status.status === "Utilised"
                              );
                            }).length) - totalPlanned || 0}
                        </Link>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={1} sx={{ paddingBottom: "32px" }}></Grid>
              <Grid item xs={4} className={styles.gridTitle} sx={{ paddingBottom: "23px" }}>
                Maintenance Status
              </Grid>
              <Grid item xs={1} sx={{ paddingBottom: "32px" }}>
                <Grid container columns={3}>
                  <Grid item xs={3} sx={{ paddingLeft: "8px", display: "flex" }}>
                    <Link to="/fleet/dasr/aircraft-status?tab=2">
                      <img src={IconUnderMaintenance} alt="icon-total-aircraft" height={24} />
                    </Link>
                    <div style={{ marginLeft: "8px" }}>
                      <div style={{ fontSize: "14px" }}>
                        <Link to="/fleet/dasr/aircraft-status?tab=2">Maintenance</Link>
                      </div>
                      <div style={{ fontFamily: "Gotham-Medium", fontSize: "24px" }}>
                        <Link to="/fleet/dasr/aircraft-status?tab=2">
                          {(aircraftData?.length > 0 &&
                            aircraftData?.filter((e) => {
                              return e.status.status === "Maintenance";
                            }).length) ||
                            0}
                        </Link>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={1} sx={{ paddingBottom: "32px" }}>
                <Grid container columns={3}>
                  <Grid item xs={3} sx={{ paddingLeft: "8px", display: "flex" }}>
                    <Link to="/fleet/dasr/aircraft-status?tab=3">
                      <img src={IconAOG} alt="icon-total-aircraft" height={24} />
                    </Link>
                    <div style={{ marginLeft: "8px" }}>
                      <div style={{ fontSize: "14px" }}>
                        <Link to="/fleet/dasr/aircraft-status?tab=3">AOG</Link>
                      </div>
                      <div style={{ fontFamily: "Gotham-Medium", fontSize: "24px" }}>
                        <Link to="/fleet/dasr/aircraft-status?tab=3">
                          {(aircraftData?.length > 0 &&
                            aircraftData?.filter((e) => {
                              return e.status.status === "AOG";
                            }).length) ||
                            0}
                        </Link>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={1} sx={{ paddingBottom: "32px" }}>
                <Grid container columns={3}>
                  <Grid item xs={3} sx={{ paddingLeft: "8px", display: "flex" }}>
                    <img src={IconStorage} alt="icon-total-aircraft" height={24} />
                    <div style={{ marginLeft: "8px" }}>
                      <div style={{ fontSize: "14px" }}>Storage</div>
                      <div style={{ fontFamily: "Gotham-Medium", fontSize: "24px" }}>
                        {(aircraftData?.length > 0 &&
                          aircraftData?.filter((e) => {
                            return e.status.status === "Storage";
                          }).length) ||
                          0}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={1} sx={{ paddingBottom: "32px" }}>
                <Grid container columns={3}>
                  <Grid item xs={3} sx={{ paddingLeft: "8px", display: "flex" }}>
                    <Link to="/fleet/dasr/aircraft-status?tab=1">
                      <img src={IconLimitation} alt="icon-total-aircraft" height={24} />
                    </Link>
                    <div style={{ marginLeft: "8px" }}>
                      <div style={{ fontSize: "14px" }}>
                        <Link to="/fleet/dasr/aircraft-status?tab=1">Limitation</Link>
                      </div>
                      <div style={{ fontFamily: "Gotham-Medium", fontSize: "24px" }}>
                        <Link to="/fleet/dasr/aircraft-status?tab=1">{dataDDMLLimitation?.length || 0}</Link>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    !isLoading && (
      <>
        <Helmet>
          <title>Royal Brunei Airlines - ELEVADE - DASR Dashboard</title>
        </Helmet>
        {subLoading && <Overlay />}
        <div id="main" className={`${styles.container}`}>
          <>
            <Grid container columns={3} className={styles.gridContainer}>
              <Grid item xs={1} className={styles.gridTitle}>
                DASR Dashboard
              </Grid>
              <Grid item xs={2} className={styles.gridJustifyEnd} sx={{ fontSize: "14px" }}>
                <DateToggle date={date} setDate={setDate} today={today} setToday={setToday} />
                {/* <Button
                  title="Add DDML"
                  icon={IconNewReportWhite}
                  iconWhite={IconNewReportWhite}
                  iconDisabled={IconNewReportWhite}
                  variant={addDDMlPermission.some((perm) => perm.canCreate === true) ? "fill" : "disabled"}
                  onClick={() => setOpen(true)}
                  flexend
                /> */}
              </Grid>
              <Grid item xs={3}>
                <AircraftStatus />
              </Grid>
              <Grid item xs={1.5} sx={{ paddingRight: "24px" }}>
                <StandbyAircraft data={aircraftData} />
              </Grid>
              <Grid item xs={1.5}>
                <DDMLSummary data={dataDDML} aoc={aoc} loading={ddmlLoading} />
              </Grid>
            </Grid>
            {/* <NewReportForm open={open} setOpen={setOpen} /> */}
            <ElevadeDrawer
              open={open}
              setOpen={setOpen}
              data={[]}
              Component={DDML}
              aoc={aoc}
              type={""}
              index={""}
              dataForm={[]}
              edit={true}
              setEdit={setEdit}
              openNotification={openNotification}
              setOpenNotification={setOpenNotification}
              newForm={true}
              setNewForm={setNewForm}
              triggerApi={triggerApi}
              setTriggerApi={setTriggerApi}
              setSelectToMove={setSelectToMove}
              setOpenDialogMoveToAssignment={setOpenDialogMoveToAssignment}
              date={date}
              aircraftList={aircraftList}
            />
          </>
        </div>
      </>
    )
  );
};

export default Dashboard;
