import React, { useEffect, useState } from "react";
import IconStation from "common/images/icons/elevade/Icon-Station.svg";
import IconStationActive from "common/images/icons/elevade/Icon-Station-active.svg";
import IconAirplaneTilt from "common/images/icons/elevade/AirplaneTilt.svg";
import IconAirplaneTiltActive from "common/images/icons/elevade/AirplaneTilt-active.svg";
import IconCalendar from "common/images/icons/elevade/CalendarBlank.svg";
import IconAssignee from "common/images/icons/elevade/Filter-operator.svg";
import IconAssigneeActive from "common/images/icons/elevade/Filter-operator-active.svg";
import IconStatus from "common/images/icons/elevade/Icon-Status.svg";
import IconStatusActive from "common/images/icons/elevade/Icon-Status-active.svg";
import styles from "./FilterBox.module.css";
import { Grid, Checkbox, styled } from "@mui/material";
import { ClickAwayListener } from "@mui/base";
import MagnifyingGlass from "common/images/icons/elevade/Icon-MagnifyingGlassGray.svg";

const FilterBox = ({ open, setOpen, data, title, isFilters = [], setIsFilters, isLast }) => {
  let objectKey = title?.replace(" ", "") || "";
  const [isSearch, setIsSearch] = useState(sessionStorage.getItem(`isSearch-${objectKey}`) || "");

  const handleClickAway = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (objectKey !== undefined && objectKey !== "") sessionStorage.setItem(`isSearch-${objectKey}`, isSearch);
  }, [isSearch]);

  useEffect(() => {
    if (!open) {
      sessionStorage.removeItem(`isSearch-${objectKey}`);
      setIsSearch("");
    }
  }, [open]);

  let key = "";
  if (title === "Station") key = "hub";
  else if (title === "Aircraft Reg.") key = "aircraftReg";
  else if (title === "Pax Cap.") key = "paxCapacity";
  else if (title === "Status") key = "status";

  function getIcon(title, active) {
    if (title === "Station") return active ? IconStationActive : IconStation;
    else if (title === "Aircraft Reg.") return active ? IconAirplaneTiltActive : IconAirplaneTilt;
    else if (title === "Raised Date") return IconCalendar;
    else if (title === "Expiry Date") return IconCalendar;
    else if (title === "Assignee") return active ? IconAssigneeActive : IconAssignee;
    else if (title === "Pax Cap.") return active ? IconAssigneeActive : IconAssignee;
    else if (title === "Status") return active ? IconStatusActive : IconStatus;
  }
  const getFilterLabel = (data, title) => {
    const dataLength = data[key]?.length;
    const dataLengthLabel = data[key]?.length - 1;
    let dataLabel = "";
    let additionalLabel = "";
    if (dataLength > 0) {
      dataLabel = data[key][0];
      if (dataLength > 1) additionalLabel = ` +${dataLengthLabel}`;
      return dataLabel + additionalLabel;
    }

    return title;
  };

  function handleOpenFilter() {
    setOpen(!open);
  }

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={`${styles.filterDivPosition} ${isFilters[key]?.length > 0 ? styles.active : ""}`}>
          <span className={`${styles.filterDiv}`} onClick={() => handleOpenFilter()}>
            <img
              src={getIcon(title, isFilters[key]?.length > 0 ? true : false)}
              height="16px"
              alt="filter-icon"
              className={styles.filterIcon}
            />
            <div style={{ marginTop: "4px" }}>{getFilterLabel(isFilters, title)}</div>
          </span>
          <FilterModal
            open={open}
            title={title}
            data={data}
            isFilters={isFilters}
            setIsFilters={setIsFilters}
            isLast={isLast}
            isSearch={isSearch}
            setIsSearch={setIsSearch}
          />
        </div>
      </ClickAwayListener>
    </>
  );
};

const FilterModal = ({ open, title, data, isFilters = [], setIsFilters, isLast, isSearch, setIsSearch }) => {
  let [list, setList] = useState();
  const [isFilteredList, setIsFilteredList] = useState([]);
  let titleRef = title.replace(" ", "_") + "_dasr";

  let key = "";
  if (title === "Station") key = "hub";
  else if (title === "Aircraft Reg.") key = "aircraftReg";
  else if (title === "Pax Cap.") key = "paxCapacity";
  else if (title === "Status") key = "status";

  useEffect(() => {
    setList(getFilterList(title));
  }, [data]);

  useEffect(() => {
    if (isSearch !== "") {
      const filteredListData = list.filter((e) => e.includes(isSearch.toUpperCase()));
      setIsFilteredList(filteredListData);
    } else setIsFilteredList(list);
  }, [isSearch, list]);

  function handleChecked(value) {
    if (key === "paxCapacity") value = parseInt(value);
    const updatedChecked = [...isFilters[key], value];
    const updatedFilters = { ...isFilters, [key]: updatedChecked };
    setIsFilters(updatedFilters);
  }

  function handleUnChecked(value) {
    if (key === "paxCapacity") value = parseInt(value);
    const updatedChecked = isFilters[key]?.filter((item) => item !== value);
    const updatedFilters = { ...isFilters, [key]: updatedChecked };
    setIsFilters(updatedFilters);
  }

  function handleSelectAll(cond) {
    if (cond) {
      const updatedFilters = { ...isFilters, [key]: isFilteredList };
      setIsFilters(updatedFilters);
    } else {
      const updatedFilters = { ...isFilters, [key]: [] };
      setIsFilters(updatedFilters);
    }
  }

  function getFilterList(title) {
    let col = "";
    if (title === "Station") col = "hub";
    if (title === "Aircraft Reg.") col = "aircraftReg";
    if (title === "Pax Cap.") col = "paxCapacity";
    if (title === "Status") col = "status";
    let options = [];
    let option = [];
    if (title === "Station" || title === "Status") {
      option = [
        ...new Set(
          data &&
            data?.map((item) => {
              return item.status[col];
            })
        ),
      ];
    } else {
      option = [
        ...new Set(
          data &&
            data?.map((item) => {
              return item[col];
            })
        ),
      ];
    }
    let sorted = option.sort();
    sorted.forEach((item) => {
      if (item !== null && item !== "" && item !== " ") {
        options.push(item);
      }
    });
    return options;
  }

  const StyledCheckbox = styled(Checkbox)(() => ({
    [`.MuiCheckbox-root`]: {
      marginTop: "6px",
      border: "1px solid blue",
    },
    marginTop: "6px",
    color: "#94A3B8",
  }));

  return (
    open && (
      <>
        <div
          className={`${styles.filterModal} ${isLast ? styles.isLastModal : ""} ${
            title.includes("Date")
              ? styles.filterModalDate
              : title === "Aircraft Reg." || title.includes("Department")
              ? styles.filterModalLarge
              : ""
          } ${styles.macScrollbar}`}
          id={titleRef}
        >
          <span style={{ color: "#838383", marginBottom: "0px" }} key="">
            <Grid container columns={2}>
              <Grid item xs={1} sx={{ padding: "10px 0 0 8px" }}>
                {title}
              </Grid>
              {(title === "Aircraft Reg." || title === "Department") && (
                <Grid
                  item
                  xs={1}
                  sx={{ display: "flex", justifyContent: "flex-end", paddingTop: "6px", color: "#088FD1" }}
                >
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => handleSelectAll(isFilters[key].length > 0 ? false : true)}
                  >
                    {isFilters[key].length > 0 ? "Unselect" : "Select"} All
                  </span>
                </Grid>
              )}
              {(title === "Aircraft Reg." || title === "Department") && (
                <Grid item xs={2} sx={{ padding: "8px 0" }}>
                  <input
                    type="text"
                    className={styles.searchBox}
                    placeholder="Search..."
                    value={isSearch}
                    style={{ backgroundImage: `url(${MagnifyingGlass})`, backgroundRepeat: "no-repeat" }}
                    onChange={(e) => setIsSearch(e.target.value)}
                  />
                </Grid>
              )}
            </Grid>
          </span>
          <ul className={`${styles.ul}`}>
            {isFilteredList &&
              isFilteredList?.map((item, index) => {
                if (key === "paxCapacity") item = parseInt(item);
                return (
                  <li className={`${styles.li}`} key={index}>
                    <Grid container columns={3}>
                      <Grid item xs={2} sx={{ paddingTop: "18px" }}>
                        <span
                          style={{
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            color: "#333333",
                          }}
                        >
                          {item}
                        </span>
                      </Grid>
                      <Grid item xs={1} sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <StyledCheckbox
                          value={item}
                          checked={isFilters[key]?.filter((e) => e === item).length > 0}
                          onChange={(e) => {
                            if (e.target.checked) {
                              handleChecked(e.target.value);
                            } else handleUnChecked(e.target.value);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </li>
                );
              })}
          </ul>
        </div>
      </>
    )
  );
};

export default FilterBox;
