import styles from "../EmailAlertManagement.module.css";
import {
  TableContainer,
  Paper,
  Table,
  TableRow,
  TableHead,
  tableCellClasses,
  TableCell,
  TableBody,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { isEmpty, omit } from "lodash";
import SortIconAscGray from "common/images/icons/elevade/SortIconAscGray.svg";
import SortIconDescGray from "common/images/icons/elevade/SortIconDescGray.svg";
import SortIconAscDescGray from "common/images/icons/elevade/SortIconAscDescGray.svg";
import Switch from "react-switch";
import { makeAPIRequestRun, showNotificationElevade } from "common/helpers";
import IconErrorAirBalloon from "common/images/icons/elevade/Icon-ErrorAirBalloon.svg";

const StyledTableCell = styled(TableCell)({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F2F7FF",
    color: "#333",
    fontSize: 16,
    fontFamily: "Gotham-Medium",
    position: "start",
    "&:hover": {
      cursor: "pointer",
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
});

const headCells = [
  {
    id: "sendEmail",
    label: "Send Alert",
    isRTI: false,
    sort: true,
    width: "10%",
  },
  {
    id: "ac_family",
    label: "A/C Family",
    isRTI: false,
    sort: true,
  },
  {
    id: "ATA",
    label: "ATA",
    isRTI: false,
    sort: true,
    width: "5%",
  },
  {
    id: "text",
    label: "Warning Message",
    isRTI: false,
    sort: true,
    width: "10%",
  },
  {
    id: "observation",
    label: "Observation",
    isRTI: true,
    sort: false,
  },
  {
    id: "analysis",
    label: "Analysis",
    isRTI: true,
    sort: false,
    width: "10%",
  },
  {
    id: "recommendations",
    label: "Recommendations",
    isRTI: true,
    sort: false,
  },
  {
    id: "rootcause",
    label: "Possible Root Cause",
    isRTI: true,
    sort: false,
  },
  {
    id: "contactpoint",
    label: "Contact Point",
    isRTI: true,
    sort: false,
  },
];

const EmailAlerts = ({
  data,
  setUpdateData,
  setOpenDrawer,
  setAction,
  setSort,
  setCallApi,
  callApi,
  email,
  setIsSelectOpen,
  setData,
  sliceStart,
  sliceEnd,
}) => {
  /* ------- states -------- */

  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();

  /* ------- use effects -------- */
  useEffect(() => {
    window.scroll(0, 0);
  }, [sliceStart, sliceEnd]);
  /* ------- functions -------- */

  function handleSort(property, db) {
    if (property !== "addedBy") {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
      // setMouseX(document.getElementById("main-table").scrollLeft);
      setSort(`${property} ${isAsc ? "desc" : "asc"}`);
    }
  }

  function checkEmptyFields(obj) {
    let error = false;
    for (const key in omit(obj, "sendEmail")) {
      if (!obj[key]) {
        showNotificationElevade("danger", "Failed", [`${key} cannot be empty`]);
        error = true;
        return error;
      } else error = false;
    }
  }

  const updateEmailNotification = (data) => {
    try {
      const isEmpty = checkEmptyFields(data);
      if (!isEmpty) {
        const update = makeAPIRequestRun("post", `ecamalerts/emailalerts/update`, data);
        update.then((response) => {
          response?.message
            ? showNotificationElevade("danger", "Failed", [response.message])
            : showNotificationElevade("success", "Success!", [response]);

          response && setCallApi(!callApi);
        });
      }
    } catch (err) {
      showNotificationElevade("danger", "Failed", [err]);
    }
  };

  function extractTextFromHTML(html) {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = html;
    return tempElement.textContent;
  }

  /* ------- components -------- */

  const ErrorAirBallon = () => {
    return (
      <TableRow>
        <StyledTableCell colSpan={12}>
          <div className={styles.errorAirBallon}>
            <center>
              <img src={IconErrorAirBalloon} alt="error-air-ballon" />
              <div className={styles.errorTitle}>No data available</div>
              <div className={styles.errorSubTitle}>
                Add a new alert to stay informed about aircraft warnings and failures.
              </div>
            </center>
          </div>
        </StyledTableCell>
      </TableRow>
    );
  };

  const TableHeads = () => {
    return (
      <TableHead
        sx={{
          "& .MuiTableCell-head": {
            backgroundColor: "#F2F7FF",
            width: "100%",
          },
        }}
      >
        <TableRow sx={{ backgroundColor: "#F2F7FF", minWidth: "100%" }}>
          {headCells.map((head, index) => (
            <StyledTableCell key={index} style={{ width: head.width ?? "auto" }}>
              <div
                className={`${styles.row} ${styles.tableHead}`}
                onClick={(e) => {
                  handleSort(head.id, head.db);
                }}
              >
                <span>{head.label}</span>
                <div className={styles.sortIcon}>
                  {head.sort ? (
                    order === "asc" && orderBy === head.id ? (
                      <img src={SortIconAscGray} alt="" />
                    ) : order === "desc" && orderBy === head.id ? (
                      <img src={SortIconDescGray} alt="" />
                    ) : (
                      <img src={SortIconAscDescGray} alt="" />
                    )
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </StyledTableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  const DisplayData = () => {
    const toggleSwitch = (sendEmail, e, item) => {
      const apidata = {
        sendEmail: sendEmail,
        recipients: typeof item.recipients === "string" ? [item.recipients] : item.recipients,
        ataChapter: item.ATA,
        event: item.text,
        email: email,
        acFamily: item.ac_family,
        ...omit(item, "sendEmail", "recipients", "ATA", "text"),
      };
      const isEmpty = checkEmptyFields(apidata);
      if (!isEmpty) {
        updateEmailNotification(apidata);
        const newList = data.map((email) => {
          if (email.id === item.id) {
            return { ...email, sendEmail: sendEmail };
          } else return email;
        });
        setData(newList);
      }
    };

    return data.length > 0 ? (
      data.slice(sliceStart, sliceEnd).map((item, index) => {
        return (
          <React.Fragment key={index}>
            {isEmpty(item) ? (
              ""
            ) : (
              <TableBody>
                <TableRow hover>
                  {headCells.map((head, index) => {
                    const isChecked = data.find((ele) => ele.id === item.id);
                    return (
                      <StyledTableCell
                        scope="row"
                        key={index}
                        onClick={(e) => {
                          if (head.id !== "sendEmail") {
                            setOpenDrawer(true);
                            setIsSelectOpen(true);
                            setUpdateData(item);
                            setAction("update");
                          }
                        }}
                      >
                        {head.id === "sendEmail" ? (
                          <div className={styles.switchDiv}>
                            <Switch
                              onChange={(sendEmail, e) => toggleSwitch(sendEmail, e, item)}
                              checked={isChecked?.sendEmail}
                              height={22}
                              width={40}
                              onColor="#088FD1"
                              offColor="#D9D9D9"
                              handleDiameter={16}
                              uncheckedIcon={
                                <div
                                  style={{
                                    color: "#ffffff",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100%",
                                    marginRight: "8px",
                                    fontFamily: "Gotham-Book",
                                    fontSize: "12px",
                                  }}
                                />
                              }
                              checkedIcon={
                                <div
                                  style={{
                                    color: "#ffffff",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100%",
                                    marginLeft: "15px",
                                    fontFamily: "Gotham-Book",
                                    fontSize: "12px",
                                  }}
                                />
                              }
                            />
                          </div>
                        ) : head.isRTI ? (
                          extractTextFromHTML(item[head.id])
                        ) : (
                          item[head.id]
                        )}
                      </StyledTableCell>
                    );
                  })}
                </TableRow>
              </TableBody>
            )}
          </React.Fragment>
        );
      })
    ) : (
      <>
        <ErrorAirBallon />
      </>
    );
  };
  return (
    <>
      <TableContainer component={Paper} sx={{ marginTop: "20px", maxHeight: undefined }} id="main-table">
        {isEmpty(data) ? (
          <>
            <Table stickyHeader aria-label="sticky table">
              <TableHeads />

              <ErrorAirBallon />
            </Table>
          </>
        ) : (
          <Table stickyHeader aria-label="sticky table">
            <TableHeads />
            <DisplayData />
          </Table>
        )}
      </TableContainer>
    </>
  );
};

export default EmailAlerts;
