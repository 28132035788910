import { Grid, Checkbox, styled, Fade } from "@mui/material";
import styles from "./FilterBox.module.css";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import React, { useState, useEffect } from "react";
import MagnifyingGlass from "common/images/icons/elevade/Icon-MagnifyingGlassGray.svg";

const FilterModal = ({
  open,
  setOpen,
  title,
  list,
  isFilters,
  setIsFilters,
  isLast,
  openTitle,
  disableFuture,
  isSearch,
  setIsSearch,
  disableOptions = [],
}) => {
  const [selectDate, setSelectDate] = useState(false);
  const [isFilteredList, setIsFilteredList] = useState([]);
  let titleRef = title.replace(" ", "_") + "_dasr";

  let key = "";
  if (title === "Operator") key = "operator";
  else if (title === "Aircraft Reg") key = "aircraftReg";
  else if (title === "Station") key = "location";
  else if (title === "Department") key = "assignedTo";
  else if (title === "Assignee") key = "assignee";
  else if (title === "Raised Date") key = "raisedDate";
  else if (title === "Expiry Date") key = "expiryDate";

  if (title === "Summary Status") {
    list = ["Rectification Status", "MOC Remarks", "Material Summary", "Planning/Tech Service"].filter((item) => {
      return !disableOptions.includes(item);
    });
  }

  useEffect(() => {
    if (title.includes("Date")) {
      // Create a Date object from the given date string
      const date = new Date(selectDate);
      // Get the components of the date
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");

      const selectedDate = `${year}-${month}-${day}`;
      if (selectedDate !== "1970-01-01") setFilters(selectedDate, key);
    }
  }, [selectDate, key, title]);

  useEffect(() => {
    if (isSearch !== "" && (title.includes("Aircraft Reg") || title.includes("Department"))) {
      const filteredListData = list.filter((e) => e.includes(isSearch.toUpperCase()));
      setIsFilteredList(filteredListData);
    } else setIsFilteredList(list);
  }, [isSearch]);

  function setFilters(value, key) {
    const theDate = { ...isFilters, [key]: [value] };
    setIsFilters(theDate);
  }

  function handleChecked(value) {
    const updatedChecked = [...isFilters[key], value];
    const updatedFilters = { ...isFilters, [key]: updatedChecked };
    setIsFilters(updatedFilters);
  }

  function handleUnChecked(value) {
    const updatedChecked = isFilters[key].filter((item) => item !== value);
    const updatedFilters = { ...isFilters, [key]: updatedChecked };
    setIsFilters(updatedFilters);
  }

  const StyledCheckbox = styled(Checkbox)(() => ({
    [`.MuiCheckbox-root`]: {
      marginTop: "0px",
      border: "1px solid blue",
    },
    marginTop: "0px",
    color: "#94A3B8",
  }));

  function onChangeInput(newValue) {
    // Create a Date object from the given date string
    const date = new Date(newValue);

    // Get the components of the date
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    const selectedDate = new Date(`${year}-${month}-${day}`);

    const theDate = { ...isFilters, [key]: selectedDate };
    setIsFilters(theDate);
  }

  function handleClick(item, title) {
    if (title === "Summary Status") {
      setIsFilters({ ...isFilters, summaryStatus: [item] });
    }
  }

  function handleSelectAll(cond) {
    if (cond) {
      const updatedFilters = { ...isFilters, [key]: isFilteredList };
      setIsFilters(updatedFilters);
    } else {
      const updatedFilters = { ...isFilters, [key]: [] };
      setIsFilters(updatedFilters);
    }
  }

  return (
    open &&
    title === openTitle && (
      <>
        <Fade in={open && title === openTitle}>
          <div
            className={`${styles.filterModal} ${isLast ? styles.isLastModal : ""} ${
              title.includes("Date")
                ? styles.filterModalDate
                : title === "Aircraft Reg" || title.includes("Department")
                ? styles.filterModalLarge
                : ""
            } ${styles.macScrollbar} ${
              isLast && (title.includes("Aircraft Reg") || title.includes("Department")) ? styles.isLastModalLarge : ""
            } ${styles.filterModalOpen}`}
            id={titleRef}
          >
            <div style={{ color: "#838383", marginBottom: "0px", padding: "0px 16px" }} key="">
              <Grid container columns={2}>
                <Grid
                  item
                  xs={title === "Aircraft Reg" || title === "Department" ? 1 : 2}
                  sx={{ padding: "10px 0 0 0px" }}
                >
                  {title}
                </Grid>
                {(title === "Aircraft Reg" || title === "Department") && (
                  <Grid
                    item
                    xs={1}
                    sx={{ display: "flex", justifyContent: "flex-end", paddingTop: "6px", color: "#088FD1" }}
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => handleSelectAll(isFilters[key].length > 0 ? false : true)}
                    >
                      {isFilters[key].length > 0 ? "Unselect" : "Select"} All
                    </span>
                  </Grid>
                )}
                {(title === "Aircraft Reg" || title === "Department") && (
                  <Grid item xs={2} sx={{ padding: "8px 0" }}>
                    <input
                      type="text"
                      className={styles.searchBox}
                      placeholder="Search..."
                      value={isSearch}
                      style={{ backgroundImage: `url(${MagnifyingGlass})`, backgroundRepeat: "no-repeat" }}
                      onChange={(e) => setIsSearch(e.target.value)}
                    />
                  </Grid>
                )}
              </Grid>
            </div>
            {title.includes("Date") ? (
              <div style={{ padding: "8px 16px" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label={""}
                    format="LL"
                    disableFuture={disableFuture}
                    value={isFilters[key].length === 0 ? undefined : dayjs(isFilters[key])}
                    // defaultValue={date}
                    onChange={(newValue) => {
                      setSelectDate(newValue);
                    }}
                  />
                </LocalizationProvider>
              </div>
            ) : (
              <ul className={`${styles.ul}`}>
                {isFilteredList &&
                  isFilteredList
                    ?.filter((element) => element !== undefined && element !== null)
                    .map((item, index) => {
                      if (key === "paxCapacity") item = parseInt(item);
                      return (
                        <li className={`${styles.li}`} key={index}>
                          <Grid container columns={3}>
                            <Grid
                              item
                              xs={title !== "Summary Status" ? 2 : 3}
                              sx={{
                                padding: "8px 16px",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                color: "#333333",
                              }}
                              className={`${title === "Summary Status" ? styles.onHover : ""} ${
                                title === "Summary Status" &&
                                isFilters["summaryStatus"]?.filter((e) => e === item).length > 0
                                  ? styles.selected
                                  : ""
                              }`}
                              onClick={() => handleClick(item, title)}
                            >
                              {item}
                            </Grid>
                            {title !== "Summary Status" && (
                              <Grid item xs={1} sx={{ display: "flex", justifyContent: "flex-end" }}>
                                <StyledCheckbox
                                  value={item}
                                  checked={isFilters[key]?.filter((e) => e === item).length > 0}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      handleChecked(e.target.value);
                                    } else handleUnChecked(e.target.value);
                                  }}
                                />
                              </Grid>
                            )}
                          </Grid>
                        </li>
                      );
                    })}
              </ul>
            )}
          </div>
        </Fade>
      </>
    )
  );
};

export default FilterModal;
