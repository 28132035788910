import React, { useRef, useEffect } from "react";
import styles from "./LandingPage.module.css";
import { Grid, styled, Tooltip, tooltipClasses, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import lottie from "lottie-web";
import IconMonitoring from "common/images/icons/elevade/Icon-Monitoring.svg";
import IconAnalytics from "common/images/icons/elevade/Icon-Analytics.svg";
import IconDASR from "common/images/icons/elevade/Icon-DASR.svg";
import IconCabinMonitoring from "common/images/icons/elevade/Icon-CabinMonitoring.svg";
import AvatarInterimPostLogin from "common/images/icons/elevade/AvatarInterimPostLogin.json";
import { Helmet } from "react-helmet";

const LandingPage = () => {
  const { userPermission, userProfile: userEntity } = useSelector((state) => state.userEntity);
  const isMobile = useMediaQuery("(max-width:900px)");

  const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    () => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(51, 51, 51, 0.90)",
        color: "rgba(255, 255, 255)",
        fontSize: 10,
        borderRadius: 10,
        padding: "6px",
        fontFamily: "Gotham-Book",
      },
      whiteSpace: "pre-line",
    })
  );

  const AvatarGif = ({ style }) => {
    const anime = useRef(null);
    useEffect(() => {
      lottie.loadAnimation({
        container: anime.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: AvatarInterimPostLogin,
      });
      return () => lottie.stop();
    }, []);
    return <div ref={anime} style={style}></div>;
  };

  // check feature(s) permission available
  function featureCheck(feature, url) {
    let checkPermission = false;
    switch (feature) {
      case "isAircraftMonitoring":
        checkPermission = userPermission.some((permission) => permission.code === "ACM-002"); //to be rectify
        return checkPermission;
      case "isInbox":
        checkPermission = userPermission.some((permission) => permission.code === "ACM-003"); //to be rectify
        return checkPermission;
      case "isRepetitiveDefect":
        checkPermission = userPermission.some((permission) => permission.code === "ACM-006"); //to be rectify
        return checkPermission;
      case "isAnalytics":
        checkPermission = userPermission.some((permission) => permission.code === "TEMP_ANALYTICS"); //to be rectify
        return checkPermission;
      case "isScheduledCheck":
        checkPermission = userPermission.some((permission) => permission.code === "TEMP_SCHEDULEDCHECK-xx"); //to be rectify
        return checkPermission;
      case "isDASR":
        checkPermission = checkDASRPages(feature, url);
        // checkPermission = userPermission.some((permission) => permission.code === "TEMP_DASR"); //to be rectify
        return checkPermission;

      case "isCabinMonitoring":
        checkPermission = userPermission.some((permission) => permission.code === "CABIN-001"); //to be rectify
        return checkPermission;
      default:
        return checkPermission;
    }
  }

  // check DASR view page permission
  function checkDASRPages(feature, url) {
    let viewPagePermission = false;
    switch (url) {
      case "/fleet/dasr":
        viewPagePermission = userPermission
          .filter((perm) => perm.code === "DASR-001")
          .some((perm) => perm.canRetrieve === true);
        return viewPagePermission;
      case "/fleet/dasr/aircraft-list":
        viewPagePermission = userPermission
          .filter((perm) => perm.code === "DASR-002")
          .some((perm) => perm.canRetrieve === true);
        return viewPagePermission;
      case "/fleet/dasr/aircraft-status":
        viewPagePermission = userPermission
          .filter((perm) => perm.code === "DASR-009")
          .some((perm) => perm.canRetrieve === true);
        return viewPagePermission;
      case "/fleet/dasr/aircraft-report":
        viewPagePermission = userPermission
          .filter((perm) => perm.code === "DASR-010")
          .some((perm) => perm.canRetrieve === true);
        return viewPagePermission;
      case "/fleet/dasr/aircraft-report-closed":
        viewPagePermission = userPermission
          .filter((perm) => perm.code === "DASR-011")
          .some((perm) => perm.canRetrieve === true);
        return viewPagePermission;
      case "/fleet/dasr/aircraft-report-archive":
        viewPagePermission = userPermission
          .filter((perm) => perm.code === "DASR-016")
          .some((perm) => perm.canRetrieve === true);
        return viewPagePermission;
      case "/fleet/dasr/assignment":
        viewPagePermission = userPermission
          .filter((perm) => perm.code === "DASR-012")
          .some((perm) => perm.canRetrieve === true);
        return viewPagePermission;
      case "/fleet/dasr/task-assignment":
        viewPagePermission = userPermission
          .filter((perm) => perm.code === "DASR-013")
          .some((perm) => perm.canRetrieve === true);
        return viewPagePermission;
      case "/fleet/dasr/assignment-report":
        viewPagePermission = userPermission
          .filter((perm) => perm.code === "DASR-014")
          .some((perm) => perm.canRetrieve === true);
        return viewPagePermission;
      default:
        return false;
    }
  }

  const ModuleMenu = ({ ...props }) => {
    const menu = [
      {
        module: "MONITORING",
        name: "Aircraft Monitoring",
        case: "isAircraftMonitoring",
        url: "/fleet/aircraft-monitoring",
      },
      { module: "MONITORING", name: "Inbox", case: "isInbox", url: "/fleet/inbox" },
      {
        module: "MONITORING",
        name: "Repetitive Defect",
        case: "isRepetitiveDefect",
        url: "/fleet/repetitive-defect-database",
      },
      { module: "ANALYTICS", name: "Predefined Dashboard", case: "isAnalytics", url: "/fleet/analytics" },
      { module: "ANALYTICS", name: "Custom Report", case: "isAnalyticsCustomReport", url: "#" },
      { module: "DASR", name: "DASR Overview", case: "isDASR", url: "/fleet/dasr" },
      { module: "DASR", name: "Aircraft List", case: "isDASR", url: "/fleet/dasr/aircraft-list" },
      { module: "DASR", name: "Maintenance Status", case: "isDASR", url: "/fleet/dasr/aircraft-status" },
      { module: "DASR", name: "DDML Reports", case: "isDASR", url: "/fleet/dasr/aircraft-report" },
      { module: "DASR", name: "Closed Reports", case: "isDASR", url: "/fleet/dasr/aircraft-report-closed" },
      { module: "DASR", name: "Archive Records", case: "isDASR", url: "/fleet/dasr/aircraft-report-archive" },
      { module: "DASR", name: "Scheduled Task", case: "isScheduledCheck", url: "/fleet/schedule-task" },
      {
        module: "DASR",
        name: "Assignment",
        url: "#",
        subMenu: [
          { name: "Assignment Drafts", case: "isDASR", url: "/fleet/dasr/assignment" },
          { name: "Task Assigment", case: "isDASR", url: "/fleet/dasr/task-assignment" },
          { name: "Assigment Report", case: "isDASR", url: "/fleet/dasr/assignment-report" },
        ],
      },
      {
        module: "CABIN MONITORING",
        name: "Overview",
        case: "isCabinMonitoring",
        url: "/fleet/cabin-monitoring/overview",
      },
      {
        module: "CABIN MONITORING",
        name: "Inspection Reports",
        case: "isCabinMonitoring",
        url: "/fleet/cabin-monitoring/inspection-reports",
      },
      {
        module: "CABIN MONITORING",
        name: "Equipment Management",
        case: "isCabinMonitoring",
        url: "/fleet/cabin-monitoring/equipment-management",
      },
    ];

    let filteredMenu = menu.filter((e) => e.module === props.name);

    return (
      <>
        <div className={styles.menuItem}>
          {filteredMenu.map((item, index) => {
            return (
              <>
                <div className={`${styles.moduleItem}`} key={index}>
                  <Link
                    to={featureCheck(item.case, item.url) ? item.url : "#"}
                    className={`${featureCheck(item.case, item.url) ? styles.available : styles.unavailable} ${
                      item?.subMenu?.length > 0 ? styles.subTitle : ""
                    }`}
                  >
                    <LightTooltip
                      title={!featureCheck(item.case, item.url) ? "This feature is currently unavailable to you" : ""}
                      followCursor
                      placement="bottom-start"
                    >
                      {item.name}
                    </LightTooltip>
                  </Link>
                </div>
                {item?.subMenu?.length > 0 &&
                  item.subMenu.map((subMenu, index) => {
                    return (
                      <>
                        <div className={`${styles.moduleItem} ${styles.moduleItemSub}`} key={index}>
                          <Link
                            to={featureCheck(subMenu.case, subMenu.url) ? subMenu.url : "#"}
                            className={`${
                              featureCheck(subMenu.case, subMenu.url) ? styles.available : styles.unavailable
                            }`}
                          >
                            <LightTooltip
                              title={
                                !featureCheck(subMenu.case, subMenu.url)
                                  ? "This feature is currently unavailable to you"
                                  : ""
                              }
                              followCursor
                              placement="bottom-start"
                            >
                              {subMenu.name}
                            </LightTooltip>
                          </Link>
                        </div>
                      </>
                    );
                  })}
              </>
            );
          })}
        </div>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Royal Brunei Airlines - ELEVADE - Home</title>
      </Helmet>
      <div className={styles.container} style={{ marginLeft: isMobile ? "92px" : "" }}>
        <Grid container columns={4}>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4} className={styles.headerContainer}>
            <div className={styles.avatar}>
              <AvatarGif />
            </div>
            <div className={styles.welcome}>
              <span className={styles.hello}>Hello</span>{" "}
              <span className={styles.username}>{userEntity.fullName}!</span>
              <br />
              <span className={styles.welcomeText}>
                Welcome to <span className={styles.medium}>ELEVADE FLEET!</span>
              </span>
            </div>
          </Grid>
          <Grid item xs={4} sm={2} md={2} lg={1} xl={1}>
            <div className={styles.module}>
              <div className={styles.icon}>
                <img src={IconMonitoring} alt="icon-monitoring" height={16} />
              </div>
              <div className={styles.moduleName}>MONITORING</div>
            </div>
            <ModuleMenu name={"MONITORING"} />
          </Grid>
          <Grid item xs={4} sm={2} md={2} lg={1} xl={1}>
            <div className={styles.module}>
              <div className={styles.icon}>
                <img src={IconAnalytics} alt="icon-analytics" height={16} />
              </div>
              <div className={styles.moduleName}>ANALYTICS</div>
            </div>
            <ModuleMenu name={"ANALYTICS"} />
          </Grid>
          <Grid item xs={4} sm={2} md={2} lg={1} xl={1}>
            <div className={styles.module}>
              <div className={styles.icon}>
                <img src={IconDASR} alt="icon-dasr" height={16} />
              </div>
              <div className={styles.moduleName}>DASR</div>
            </div>
            <ModuleMenu name={"DASR"} />
          </Grid>
          <Grid item xs={4} sm={2} md={2} lg={1} xl={1}>
            <div className={styles.module}>
              <div className={styles.icon}>
                <img src={IconCabinMonitoring} alt="icon-cabin-monitoring" height={16} />
              </div>
              <div className={styles.moduleName}>CABIN MONITORING</div>
            </div>
            <ModuleMenu name={"CABIN MONITORING"} />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default LandingPage;
