import React, { useRef, useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
import styles from "./ECharts.module.css";
import * as echarts from "echarts";
import DialogBox from "./DialogBox";
import { Grid } from "@mui/material";
import Sorting from "pages/elevade/component/Sorting";
import { formatAtaChapter, formatDate } from "pages/elevade/component/Helpers";
import { cloneDeep } from "lodash";
import { Link } from "react-router-dom";
import AirplaneLoader from "../../component/AirplaneLoader";

export const EChartss = ({
  title,
  data,
  color,
  isLoading,
  initialData,
  isACReg,
  chartIndex,
  deep,
  setDeep,
  tab,
  ...props
}) => {
  const chartRef = useRef();
  const [dialogType, setDialogType] = useState(false);
  const [open, setOpen] = useState(false);
  const [isTitle, setIsTitle] = useState();
  const [isData, setIsData] = useState([]);
  const [dataIndex, setDataIndex] = useState();
  const [posX, setPosX] = useState(0);
  const [posY, setPosY] = useState(0);
  const refDialogBox = useRef(null);
  const [style, setStyle] = useState(styles.fadeOut);
  const [tableId, setTableId] = useState();
  const [params, setParams] = useState();
  const [message, setMessage] = useState();
  const chartHeight = data?.length < 12 ? data?.length * 50 + 100 + "px" : data?.length * 40 + "px";
  const titleStyle = { position: "sticky", top: 0, zIndex: 10, backgroundColor: "#FFFFFF" };
  const containerStyle = props.latestOccurence ? { height: "500px", overflowX: "hidden", overflowY: "scroll" } : {};

  useEffect(() => {
    if (!deep && deep !== 0) {
      setIsData(data);
      // setParams();
    }
    // eslint-disable-next-line
  }, [deep]);

  function useOutsideClicker(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setDeep(0);
          setOpen(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);

    useEffect(() => {
      if (!open) setDeep(0);
    }, [open]);
  }

  useOutsideClicker(refDialogBox);

  useEffect(() => {
    // An onclick event on chart
    if (data?.length > 0) {
      let instance = chartRef.current.getEchartsInstance();
      props.latestOccurence &&
        instance.on("mousemove", (params) => {
          const dataIndex = params.dataIndex;
          setMessage(params.name);
          instance.setOption({
            series: [
              {
                itemStyle: {
                  color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                    { offset: 0, color: color.start },
                    { offset: 1, color: color.end },
                  ]),
                  opacity: 0.3, // Adjust the opacity or other visual properties to indicate the highlight
                  emphasis: {
                    opacity: 1,
                  },
                  encode: {
                    x: 0,
                    y: dataIndex,
                  },
                },
                label: {
                  show: true,
                  formatter: (param) => {
                    return param.name === params.name ? param.name + `  (` + param.value[2].toLocaleString() + `)` : "";
                  },
                },
              },
            ],
          });
        });

      props.latestOccurence &&
        instance.on("mouseout", (params) => {
          setMessage("");
          instance.setOption({
            series: [
              {
                itemStyle: {
                  opacity: 1,
                  emphasis: {
                    opacity: 1,
                  },
                },
                label: {
                  show: true,
                  opacity: 1,
                },
              },
            ],
          });
        });

      instance.on("click", (params) => {
        let tableId = 8;
        if (params.data[0] === "top_high_priority") tableId = 8;
        else if (params.data[0] === "top_ecam") tableId = 8;
        else if (params.data[0] === "top_ata") tableId = 11;
        else if (props.tableId) tableId = props.tableId;
        let isData = FilterDataForIsData(params.name, params.data[3], tableId);
        // Filter data according to the selected A/C Registration
        if (isACReg.length > 0) {
          if (tableId === 8 || tableId === 12 || tableId === 10)
            isData = initialData
              .filter((e) => e.table_id === tableId && e.text === params.name && isACReg.includes(e["ac_reg"]))
              .sort(function (a, b) {
                return tableId === 8 || tableId === 12 || tableId === 10
                  ? b.occurrence - a.occurrence
                  : a.occurrence - b.occurrence;
              });
          else {
            let ata_head = params.name.split(" ")[1];
            isData = initialData
              .filter((e) => e.table_id === tableId && e.ata_head === ata_head && isACReg.includes(e["ac_reg"]))
              .sort(function (a, b) {
                return tableId === 11 ? b.occurrence - a.occurrence : a.occurrence - b.occurrence;
              });
          }
        }
        // Set which dialog to be opened
        let dialogType = 1;
        if (tableId === 11 || tableId === 8 || tableId === 12 || tableId === 15) dialogType = 2;
        setPosX(params.event.event.zrX);
        setPosY(params.event.event.zrY);
        setDialogType(dialogType);
        setOpen(true);
        setIsTitle(params.name);
        setDataIndex(params.dataIndex);
        setIsData(isData.slice(0, 11));
        setTableId(tableId);
        setParams(params);
        if (tableId === 11 || tableId === 8 || tableId === 12) setDeep(0);
      });
    }
    // eslint-disable-next-line
  }, [data]);

  function FilterDataForIsData(name, aoc, tableId) {
    if (tableId === 8 || tableId === 12 || tableId === 10)
      return initialData
        .filter((e) => e.table_id === tableId && e.text === name && e.aoc === aoc)
        .sort(function (a, b) {
          return tableId === 8 || tableId === 12 || tableId === 10
            ? b.occurrence - a.occurrence
            : a.occurrence - b.occurrence;
        });
    else if (tableId === 15) {
      return props.acRegList.filter((acreg) => acreg.text === name).sort((a, b) => b.occurrence - a.occurrence);
    } else {
      let ata_head = name.split(" ")[1];
      return initialData
        .filter((e) => e.table_id === tableId && e.ata_head === ata_head && e.aoc === aoc)
        .sort(function (a, b) {
          return tableId === 11 ? b.occurrence - a.occurrence : a.occurrence - b.occurrence;
        });
    }
  }
  // Graph's option
  var options = {
    dataset: {
      source: data,
    },
    grid: { containLabel: false },
    title: {
      show: data?.length === 1,
      textStyle: {
        color: "#999999",
        fontSize: 20,
        fontFamily: "Gotham-Book",
      },
      text: "No data available",
      left: "center",
      top: "center",
    },
    xAxis: { name: "", axisLabel: { show: true, fontFamily: "Gotham-Book" }, axisLine: { show: false } },
    yAxis: {
      type: "category",
      axisLabel: {
        show: false,
        fontFamily: "Gotham-Book",
      },
      axisLine: { show: false },
      axisTick: { show: false },
    },
    series: [
      {
        type: "bar",
        encode: {
          x: "occurrence",
          y: "text",
        },
        itemStyle: {
          color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
            { offset: 0, color: color.start },
            { offset: 1, color: color.end },
          ]),
          barBorderRadius: 30,
        },
        // Set the width of the bar
        barWidth: 8,
        showBackground: true,
        label: {
          show: true,
          precision: 1,
          position: [0, -15],
          valueAnimation: true,
          fontFamily: "Gotham-Book",
          formatter: function (params) {
            return params.name + `  (` + params.value[2].toLocaleString() + `)`; //display series name
          },
        },
        animationDuration: 1000,
      },
    ],
  };

  useEffect(() => {
    setTimeout(function () {
      setStyle(styles.fadeIn);
    }, 250);
  });

  return (
    <>
      <div className={`${styles.container} ${styles.macScrollbar}`} style={containerStyle}>
        <div className={styles.title} style={titleStyle}>
          <Grid container columns={2}>
            <Grid item xs={1.3}>
              {title}
            </Grid>
            <Grid
              className="seraphinatest"
              item
              xs={0.7}
              sx={{ display: "flex", justifyContent: "flex-end", fontSize: "12px" }}
            >
              {/* <span
                style={{
                  fontFamily: "Gotham-Medium",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setDeep(4);
                  setIsData(data);
                  setOpen(true);
                  setParams();
                }}
              >
                View All Messages
              </span> */}
            </Grid>
          </Grid>
        </div>
        <div className={`${styles.body} ${dialogType === 2 ? styles.bodyFixed : ""}`}>
          {data?.length > 0 && !props.latestOccurence ? (
            <>
              <div container columns={2} className={`${style}`}>
                <ReactEcharts
                  option={options}
                  style={{
                    height: "490px",
                    marginTop: "-40px",
                    marginBottom: "-30px",
                    fontFamily: "Gotham-Book",
                  }}
                  showLoading={isLoading ? true : false}
                  ref={chartRef}
                ></ReactEcharts>
              </div>
            </>
          ) : (
            <>
              <div container columns={2} className={`${style}`}>
                <Grid container columns={2} style={{ zIndex: 1 }}>
                  <Grid item xs={1.7}>
                    <ReactEcharts
                      option={options}
                      style={{
                        minHeight: chartHeight,
                        marginTop: "-20px",
                        marginBottom: "-30px",
                        fontFamily: "Gotham-Book",
                        marginLeft: "-20px",
                      }}
                      showLoading={isLoading}
                      ref={chartRef}
                    ></ReactEcharts>
                  </Grid>
                  <Grid item xs={0.2}>
                    <Grid
                      sx={{
                        mt: "10px",
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                        ml: -5,
                      }}
                    >
                      <Grid>
                        <span style={{ fontFamily: "Gotham-Medium", fontSize: "12px" }}>Latest Occurrence</span>
                        {data && (
                          <DisplayLatestOccurrence
                            acRegList={props.acRegList}
                            data={data}
                            chartHeight={chartHeight}
                            message={message}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </>
          )}
          <DialogBox
            chartIndex={chartIndex}
            dialogType={dialogType}
            open={open}
            setOpen={setOpen}
            title={isTitle}
            refs={refDialogBox}
            initialData={initialData}
            data={isData}
            dataIndex={dataIndex}
            posX={posX}
            posY={posY}
            deep={deep}
            setDeep={setDeep}
            isACReg={isACReg}
            params={params}
            tableId={tableId}
            color={color}
            tab={tab}
            boardTitle={title}
          />
        </div>
      </div>
    </>
  );
};

export const BarCharts = ({
  data,
  color,
  deep,
  setDeep,
  acReg,
  setAcReg,
  ataChapter,
  setAtaChapter,
  chartType,
  tab,
}) => {
  const [dialogType, setDialogType] = useState(false);
  const [open, setOpen] = useState(false);
  const [isTitle, setIsTitle] = useState();
  const [isData, setIsData] = useState([]);
  const refDialogBox = useRef(null);
  const chartRef = useRef(null);
  const [style, setStyle] = useState(styles.fadeOut);

  function refactor(deep, str) {
    return str.length > 0 && deep === 0 ? str : deep === 1 ? str.substr(0, 2) + "-" + str.substr(2, 2) : undefined;
  }

  // Set the height of the graph based on the number of lines to be drawed
  const chartHeight = data?.length * 50 + 200 + "px";
  useEffect(() => {
    let add = 1;
    if (chartType === "top_high_priority" || chartType === "top_ecam" || chartType === 15) add = 2;
    if (data?.length > 0) {
      // An onclick event on chart
      let instance = chartRef?.current.getEchartsInstance();
      instance.on("click", (params) => {
        setDialogType(2);
        if (deep === 0) setAcReg(params.name);
        if (deep === 1) setAtaChapter(params.name);
        setOpen(true);
        setDeep(deep + add);
      });
    }
    // eslint-disable-next-line
  }, [data]);

  // Graph's options
  var options = {
    dataset: {
      source: data,
    },
    grid: { containLabel: false },
    title: {
      show: data?.length === 0,
      textStyle: {
        color: "#999999",
        fontSize: 20,
        fontFamily: "Gotham-Book",
      },
      text: "No data available",
      left: "center",
      top: "center",
    },
    xAxis: { name: "", axisLabel: { show: true, fontFamily: "Gotham-Book" }, axisLine: { show: false } },
    yAxis: {
      type: "category",
      axisLabel: {
        show: false,
        fontFamily: "Gotham-Book",
      },
      axisLine: { show: false },
      axisTick: { show: false },
    },
    series: [
      {
        type: "bar",
        encode: {
          x: "occurrence",
          y: "text",
        },
        itemStyle: {
          color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
            { offset: 0, color: color.start },
            { offset: 1, color: color.end },
          ]),
          barBorderRadius: 30,
        },
        barWidth: 10, // Set the width of the bar
        showBackground: true,
        label: {
          show: true,
          precision: 1,
          position: [0, -15],
          valueAnimation: true,
          fontFamily: "Gotham-Book",
          formatter: function (params) {
            return refactor(deep, params?.name) + `  (` + params?.data?.occurrence?.toLocaleString() + `)`; //display series name
          },
        },
        animationDuration: 1000,
      },
    ],
  };

  useEffect(() => {
    setTimeout(function () {
      setStyle(styles.fadeIn);
    }, 250);
  });

  return (
    <>
      {data?.length > 0 && (
        <>
          <div className={`${style} ${styles.macScrollbar}`} style={{ overflowX: "hidden", maxHeight: "470px" }}>
            <ReactEcharts
              option={options}
              style={{
                minHeight: chartHeight,
                marginTop: "-40px",
                marginBottom: "-30px",
                marginLeft: "-100px",
                marginRight: "-100px",
                fontFamily: "Gotham-Book",
              }}
              ref={chartRef}
            ></ReactEcharts>
            <DialogBox
              dialogType={dialogType}
              open={open}
              setOpen={setOpen}
              title={isTitle}
              refs={refDialogBox}
              data={isData}
              deep={deep}
              setDeep={setDeep}
              ataChapter={ataChapter}
              color={color}
              tab={tab}
            />
          </div>
        </>
      )}
    </>
  );
};

export const TableChart = ({ data, initialData, aoc, acReg, ataChapter, chartType, name, setTableData, tab, open }) => {
  //if tab == 0 , take all index 1 , if tab === 1, take first index 1, then filter initial array to include all messages in

  let params = "";

  if (tab === 0 && data.length > 0) {
    params = data.slice(1).map((x) => x[1]);
  } else if (tab === 1 && data.length > 0) {
    params = data?.[1]?.[0];
  }

  const [sortingColumn, setSortingColumn] = useState("");
  const [sortingDirection, setSortingDirection] = useState("desc");
  const [isData, setIsData] = useState();

  useEffect(() => {
    let filteredData = "";
    if (chartType === "top_ata") {
      filteredData = initialData.filter(
        (e) => e.aoc === aoc && e.ac_reg === acReg && e.ata_4d === ataChapter && e.text !== ""
      );
    } else if (chartType === "all" || chartType === undefined) {
      console.log("HOLA");
      filteredData = name
        ? initialData.filter(
            (e) =>
              e.aoc === aoc &&
              (name?.includes("ATA") ? e?.ata_head === name.split(" ")[1] : e?.text === name) &&
              e.table_id === 0
          )
        : initialData.filter(
            (e) =>
              e.aoc === aoc &&
              e.table_id === 0 &&
              (tab === 0
                ? params[0]?.match(/ATA \d{2}/)
                  ? params?.includes(`ATA ${e.ata_head}`)
                  : params?.includes(e.text)
                : params?.includes(e.condition))
          );
    } else {
      filteredData = initialData.filter(
        (e) => e.aoc === aoc && e.ac_reg === acReg && e.text === name && e.table_id === 0
      );
    }

    let tableData = filteredData?.sort((a, b) => {
      if (sortingDirection === "desc") {
        if (sortingColumn === "occurrence_timestamp" || sortingColumn === "telex_sent") {
          return new Date(b[sortingColumn]?.value) - new Date(a[sortingColumn]?.value);
        } else return a[sortingColumn] < b[sortingColumn] ? 1 : -1;
      } else {
        if (sortingColumn === "occurrence_timestamp" || sortingColumn === "telex_sent")
          return new Date(a[sortingColumn]?.value) - new Date(b[sortingColumn]?.value);
        else return a[sortingColumn] > b[sortingColumn] ? 1 : -1;
      }
    });
    setIsData(tableData);
    setTableData(tableData);

    // eslint-disable-next-line
  }, [initialData, aoc, acReg, ataChapter, sortingColumn, sortingDirection, chartType, name, data]);

  function SetTime() {
    const [style, setStyle] = useState(styles.fadeOut);
    setTimeout(function () {
      setStyle(styles.fadeIn);
    }, 250);
    return style;
  }

  const handleClick = ({ ...props }) => {
    const eventTime = props.eventTime.split(":")[0] + ":" + props.eventTime.split(":")[1];
    localStorage.setItem(
      "inboxMsg",
      JSON.stringify({
        ac_reg: props.ac_reg,
        flightNo: props.flightNo,
        msg: props.msg,
        telexTime: props.telexTime,
        eventDate: props.eventDate,
        eventTime: eventTime,
        msgType: props.msgType,
      })
    );
  };

  return open ? (
    <>
      <div
        style={{ borderRadius: "4px", overflow: "hidden", borderBottom: "1px solid #E8E8E8" }}
        className={`${SetTime()} ${styles.macScrollbar}`}
      >
        <Grid container columns={11} className={`${styles.tableHeader}`}>
          <Grid item xs={3} className={`${styles.nowrap}`}>
            <div className={`${styles.wrapper}`}>
              <Sorting
                title={"Message"}
                col={"text"}
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
              />
            </div>
          </Grid>
          {chartType === "all" && params?.[0]?.match(/ATA \d{2}/) && (
            <Grid item xs={1} className={`${styles.nowrap}`}>
              <div className={`${styles.wrapper}`}>
                <Sorting
                  title={"ATA"}
                  col={"ata_4d"}
                  sortingColumn={sortingColumn}
                  setSortingColumn={setSortingColumn}
                  sortingDirection={sortingDirection}
                  setSortingDirection={setSortingDirection}
                />
              </div>
            </Grid>
          )}
          {chartType === "all" && (
            <Grid item xs={1} className={`${styles.nowrap}`}>
              <div className={`${styles.wrapper}`}>
                <Sorting
                  title={"A/C Reg"}
                  col={"ac_reg"}
                  sortingColumn={sortingColumn}
                  setSortingColumn={setSortingColumn}
                  sortingDirection={sortingDirection}
                  setSortingDirection={setSortingDirection}
                />
              </div>
            </Grid>
          )}
          <Grid item xs={1} className={`${styles.nowrap}`}>
            <Sorting
              title={"Flight No."}
              col={"flight_no"}
              sortingColumn={sortingColumn}
              setSortingColumn={setSortingColumn}
              sortingDirection={sortingDirection}
              setSortingDirection={setSortingDirection}
            />
          </Grid>
          <Grid item xs={1} className={`${styles.nowrap}`}>
            <Sorting
              title={"Phase"}
              col={"phase"}
              sortingColumn={sortingColumn}
              setSortingColumn={setSortingColumn}
              sortingDirection={sortingDirection}
              setSortingDirection={setSortingDirection}
            />
          </Grid>
          <Grid item xs={2} className={`${styles.nowrap}`}>
            <Sorting
              title={"Occurrence"}
              col={"occurrence_timestamp"}
              sortingColumn={sortingColumn}
              setSortingColumn={setSortingColumn}
              sortingDirection={sortingDirection}
              setSortingDirection={setSortingDirection}
            />
          </Grid>
          <Grid item xs={2} className={`${styles.nowrap}`}>
            <Sorting
              title={"Telex Sent"}
              col={"telex_sent"}
              sortingColumn={sortingColumn}
              setSortingColumn={setSortingColumn}
              sortingDirection={sortingDirection}
              setSortingDirection={setSortingDirection}
            />
          </Grid>
        </Grid>
        <Grid
          container
          columns={11}
          sx={{
            borderLeft: "1px solid #E8E8E8",
            borderRight: "1px solid #E8E8E8",
            maxHeight: "420px",
            overflow: "auto",
          }}
          className={`${styles.macScrollbar}`}
        >
          <Grid item xs={11}>
            {isData?.map((item, index) => {
              return (
                <>
                  <Link
                    to={`/fleet/aircraft-status/${item.ac_reg}/failure-messages`}
                    target="_blank"
                    key={index}
                    onClick={() =>
                      handleClick({
                        ac_reg: item.ac_reg,
                        flightNo: item.flight_no,
                        msg: item.text,
                        telexTime: item.telex_sent?.value,
                        eventDate: item.occurrence_timestamp?.value.split("T")[0],
                        eventTime: item.occurrence_timestamp?.value.split("T")[1],
                        msgType: "WARNING",
                      })
                    }
                  >
                    <Grid container columns={11}>
                      <Grid item xs={3} className={`${styles.tableBody}`}>
                        <span className={`${styles.wrapper}`}>{item.text}</span>
                      </Grid>
                      {chartType === "all" && params?.[0]?.match(/ATA \d{2}/) && (
                        <Grid item xs={1} className={`${styles.tableBody}`}>
                          <span className={`${styles.wrapper}`}>{formatAtaChapter(item.ata_4d)}</span>
                        </Grid>
                      )}
                      {chartType === "all" && (
                        <Grid item xs={1} className={`${styles.tableBody}`}>
                          <span className={`${styles.wrapper}`}>{item.ac_reg}</span>
                        </Grid>
                      )}
                      <Grid item xs={1} className={`${styles.tableBody}`}>
                        {item.flight_no}
                      </Grid>
                      <Grid item xs={1} className={`${styles.tableBody}`}>
                        {item.phase}
                      </Grid>
                      <Grid item xs={2} className={`${styles.tableBody}`}>
                        {formatDate(item.occurrence_timestamp?.value, "", "", true)}
                      </Grid>
                      <Grid item xs={2} className={`${styles.tableBody}`}>
                        {formatDate(item.telex_sent?.value, "", "", true)}
                      </Grid>
                    </Grid>
                  </Link>
                </>
              );
            })}
          </Grid>
        </Grid>
      </div>
    </>
  ) : (
    <AirplaneLoader />
  );
};

const DisplayLatestOccurrence = (props) => {
  const { acRegList, data, chartHeight, message } = props;
  let _data = cloneDeep(data).reverse().slice(0, -1);
  let height = (chartHeight.split("px")[0] - 122) / _data.length;
  if (_data.length === 2) height = height + 20;
  const containerHeight = chartHeight.split("px")[0] - 122;
  let mt = 0;
  if (_data.length === 1) mt = "70px";
  else if (_data.length === 2) mt = "20px";
  else if (_data.length === 3) mt = "8px";
  else mt = "0px";
  // mt: `${0.3 * height}px`

  return (
    <Grid sx={{ height: containerHeight, mt: mt }}>
      {_data.map((item, index) => {
        const ac_reg = acRegList.filter((acreg) => acreg.text === item[1])[0];

        return (
          <>
            <Grid
              className={styles.latestOccurence}
              key={index}
              style={{
                height: height,
                borderBottom: "solid 1px #E8E8E8",
                backgroundColor: message === item[1].split("(")[0] ? "#74c4e81a" : "#FFFFFF",
              }}
            >
              <Grid sx={{ fontFamily: "Gotham-Medium", fontSize: "10px" }} key={index + 1}>
                {ac_reg?.ac_reg}
              </Grid>
              <Grid sx={{ fontFamily: "Gotham-Book", fontSize: "10px" }} key={index + 2}>
                {formatDate(ac_reg?.occurrence_timestamp?.value, "", "", true)}
              </Grid>
            </Grid>
          </>
        );
      })}
    </Grid>
  );
};

export default EChartss;
