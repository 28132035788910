import React from "react";
import { useEffect, useState } from "react";
import MagnifyingGlass from "common/images/icons/elevade/MagnifyingGlass.svg";
import styles from "./EleSearchBox.module.css";
import useMediaQuery from "@mui/material/useMediaQuery";

const EleSearchBox = ({ placeholder, isSearchInput, setIsSearchInput, isAircraftType, setIsAircraftType }) => {
  const [query] = useState("");
  const isWeb = useMediaQuery("(min-width:900px)");

  const style = {
    input: {
      border: "0px",
      borderRight: isWeb ? "0px solid #c3c3c3" : "0px solid #c3c3c3",
      paddingLeft: "32px",
      width: "100%",
      maxWidth: "310px",
      marginTop: "10px",
      backgroundImage: `url(${MagnifyingGlass})`,
      backgroundRepeat: "no-repeat",
      backgroundPositionY: "5px",
      backgroundSize: "16px",
      backgroundColor: "transparent",
      "&&:focus": {
        outline: "none",
      },
    },
  };

  useEffect(() => {
    const timeOut = setTimeout(() => setIsSearchInput(query), 500);
    return () => clearTimeout(timeOut);
    // eslint-disable-next-line
  }, [query]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (event.target.value !== "") setIsSearchInput([...isSearchInput, event.target.value.toUpperCase()]);
      event.currentTarget.value = "";
    }
  };

  return (
    <>
      <input
        type="text"
        placeholder={placeholder}
        style={style.input}
        className={`${styles.input}`}
        // value={isSearch}
        // defaultValue={isSearch}
        // onChange={(e) => setQuery(e.target.value)}
        onKeyDown={handleKeyDown}
      />
    </>
  );
};

export default EleSearchBox;
