import { useDispatch, useSelector } from 'react-redux';
// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = useDispatch

/**
 * @typedef {import('./types').RootState} RootState
 */

/**
 * @callback UseSelector
 * @param {RootState} appState
 */
/**
 * @callback UseAppSelector
 * @param {UseSelector} state
 */

/**
 * @type {UseAppSelector} useAppSelector
 */
export const useAppSelector = useSelector

