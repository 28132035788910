
import React, { useEffect } from 'react';
import { Box, Typography } from "@mui/material";
import PageTemplate from "../../components/templates/page-template";
import InspectionListAdv from "./InspectionListAdv";
import { useHistory } from 'react-router';
import { cabinCheckPermission } from '../../../controllers/permissions/permission-codes';
import { useAppSelector } from '../../../app/hooks';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';

export default function Main() {

	const userEntity = useAppSelector((state) => state.userEntity)
	const permissions = userEntity?.userPermission

	const history = useHistory();

	useEffect(() => {
		const isUserAllowed = cabinCheckPermission(permissions).isAllowedViewInspectionReport
		if (!isUserAllowed) {
			history.push("/error-403")
		}
	}, [permissions])

	return (
		<Fragment>
			<Helmet>
				<title>ELEVADE - Cabin Monitoring Inspection Report</title>
			</Helmet>

			<PageTemplate title="Inspection Reports">
				<Typography
					marginTop="-15px"
					fontSize="12px"
					fontStyle="italic"
					color="#838383"
					width="100%"
					textAlign="left"
					marginBottom="24px"
				>
					*The data shown is for the most recent 20 inspections.
					{/*' To view more data, please export it.' */}
				</Typography>
				<Box>
					<InspectionListAdv />
					{/* <InspectionList /> */}
					<Box
						sx={{
							width: "100%",
							height: "2em",
						}}
					/>
				</Box>
			</PageTemplate>
		</Fragment>
	);
}
