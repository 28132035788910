
import React from 'react';

export default function ColNumber() {
	return (
		<g>
			<path // 1
				d="M293.998 348H294.778V340.95H294.198L292.488 341.54L292.678 342.18L293.998 341.77V348Z"
				fill="#333333"
			/>
			<path // 2
				d="M319.487 348H324.377V347.28H320.667L322.537 345.62C323.767 344.55 324.307 343.92 324.307 342.9V342.88C324.307 341.74 323.397 340.9 322.137 340.9C320.907 340.9 320.247 341.45 319.617 342.39L320.197 342.81C320.757 342.03 321.277 341.61 322.077 341.61C322.837 341.61 323.487 342.13 323.487 342.95C323.487 343.65 323.097 344.19 322.027 345.15L319.487 347.39V348Z"
				fill="#333333"
			/>
			<path // 3
				d="M350.044 348.12C351.344 348.12 352.384 347.26 352.384 346.01V345.99C352.384 344.71 351.294 344.12 350.074 344.02L352.274 341.58V341H347.804V341.71H351.224L349.014 344.2L349.174 344.67H349.624C350.774 344.67 351.584 345.15 351.584 346.02V346.04C351.584 346.86 350.904 347.4 350.054 347.4C349.194 347.4 348.544 347.02 348.004 346.36L347.434 346.87C348.004 347.61 348.874 348.12 350.044 348.12Z"
				fill="#333333"
			/>
			<path // 4
				d="M378.966 348H379.726V346.34H380.816V345.68H379.726V340.95H379.036L375.076 345.77L375.276 346.34H378.966V348ZM376.026 345.68L378.966 342.07V345.68H376.026Z"
				fill="#333333"
			/>
			<path // 5
				d="M405.968 348.12C407.428 348.12 408.468 347.19 408.468 345.82V345.8C408.468 344.47 407.408 343.66 406.048 343.66C405.468 343.66 405.078 343.79 404.668 343.97L404.818 341.73H408.158V341H404.128L403.928 344.41L404.458 344.76C404.868 344.54 405.338 344.36 405.928 344.36C406.948 344.36 407.668 344.96 407.668 345.83V345.85C407.668 346.74 406.978 347.4 405.978 347.4C405.268 347.4 404.598 347.03 403.988 346.44L403.468 347.01C404.098 347.64 404.968 348.12 405.968 348.12Z"
				fill="#333333"
			/>
			<path // 6
				d="M434.093 348.12C435.513 348.12 436.603 347.14 436.603 345.79V345.77C436.603 344.46 435.483 343.62 434.183 343.62C433.163 343.62 432.573 344.13 432.193 344.69C432.183 342.88 432.973 341.6 434.243 341.6C434.883 341.6 435.373 341.85 435.893 342.29L436.353 341.68C435.743 341.18 435.123 340.88 434.283 340.88C432.453 340.88 431.373 342.55 431.373 344.68V344.7C431.373 346.13 431.693 346.85 432.253 347.41C432.693 347.85 433.323 348.12 434.093 348.12ZM434.103 347.42C433.073 347.42 432.333 346.74 432.333 345.85V345.83C432.333 345.05 433.033 344.3 434.083 344.3C435.093 344.3 435.793 344.94 435.793 345.8V345.82C435.793 346.71 435.133 347.42 434.103 347.42Z"
				fill="#333333"
			/>
			<path // 7
				d="M460.391 348H461.271L464.451 341.59V341H459.701V341.72H463.551L460.391 348Z"
				fill="#333333"
			/>
			<path // 8
				d="M490.001 348.1C491.491 348.1 492.631 347.3 492.631 346.11V346.09C492.631 345.26 492.021 344.69 491.191 344.38C491.841 344.08 492.391 343.58 492.391 342.77V342.75C492.391 341.67 491.281 340.9 490.001 340.9C488.721 340.9 487.611 341.67 487.611 342.75V342.77C487.611 343.58 488.161 344.08 488.811 344.38C487.971 344.68 487.371 345.27 487.371 346.1V346.12C487.371 347.29 488.511 348.1 490.001 348.1ZM490.001 344.1C489.111 344.1 488.401 343.58 488.401 342.82V342.8C488.401 342.1 489.091 341.59 490.001 341.59C490.911 341.59 491.601 342.11 491.601 342.8V342.82C491.601 343.58 490.891 344.1 490.001 344.1ZM490.001 347.41C488.851 347.41 488.171 346.81 488.171 346.1V346.08C488.171 345.3 488.981 344.75 490.001 344.75C491.021 344.75 491.831 345.3 491.831 346.08V346.1C491.831 346.81 491.151 347.41 490.001 347.41Z"
				fill="#333333"
			/>
			<path // 9
				d="M517.713 348.12C519.443 348.12 520.623 346.55 520.623 344.33V344.31C520.623 342.91 520.283 342.13 519.753 341.6C519.303 341.15 518.713 340.88 517.913 340.88C516.423 340.88 515.393 341.93 515.393 343.26V343.28C515.393 344.54 516.393 345.45 517.833 345.45C518.803 345.45 519.433 344.94 519.813 344.33C519.843 346.12 518.993 347.4 517.733 347.4C517.113 347.4 516.583 347.15 516.013 346.65L515.553 347.26C516.143 347.75 516.803 348.12 517.713 348.12ZM517.923 344.77C516.903 344.77 516.203 344.13 516.203 343.24V343.22C516.203 342.35 516.833 341.58 517.883 341.58C518.933 341.58 519.663 342.28 519.663 343.17V343.19C519.663 343.99 518.993 344.77 517.923 344.77Z"
				fill="#333333"
			/>
			<path // 10
				d="M542.424 348H543.204V340.95H542.624L540.914 341.54L541.104 342.18L542.424 341.77V348ZM547.788 348.12C549.588 348.12 550.748 346.46 550.748 344.5V344.48C550.748 342.52 549.608 340.88 547.808 340.88C546.008 340.88 544.838 342.54 544.838 344.5V344.52C544.838 346.48 545.978 348.12 547.788 348.12ZM547.808 347.4C546.498 347.4 545.658 346.03 545.658 344.5V344.48C545.658 342.94 546.478 341.6 547.788 341.6C549.088 341.6 549.928 342.97 549.928 344.5V344.52C549.928 346.05 549.118 347.4 547.808 347.4Z"
				fill="#333333"
			/>
			<path // 11
				d="M572.206 348H572.986V340.95H572.406L570.696 341.54L570.886 342.18L572.206 341.77V348ZM575.79 348H576.57V340.95H575.99L574.28 341.54L574.47 342.18L575.79 341.77V348Z"
				fill="#333333"
			/>

			<path // 12
				d="M615.005 348H615.785V340.95H615.205L613.495 341.54L613.685 342.18L615.005 341.77V348ZM617.279 348H622.169V347.28H618.459L620.329 345.62C621.559 344.55 622.099 343.92 622.099 342.9V342.88C622.099 341.74 621.189 340.9 619.929 340.9C618.699 340.9 618.039 341.45 617.409 342.39L617.989 342.81C618.549 342.03 619.069 341.61 619.869 341.61C620.629 341.61 621.279 342.13 621.279 342.95C621.279 343.65 620.889 344.19 619.819 345.15L617.279 347.39V348Z"
				fill="#333333"
			/>

			<path // 14
				d="M658.644 348H659.424V340.95H658.844L657.134 341.54L657.324 342.18L658.644 341.77V348ZM664.758 348H665.518V346.34H666.608V345.68H665.518V340.95H664.828L660.868 345.77L661.068 346.34H664.758V348ZM661.818 345.68L664.758 342.07V345.68H661.818Z"
				fill="#333333"
			/>
			<path // 15
				d="M686.936 348H687.716V340.95H687.136L685.426 341.54L685.616 342.18L686.936 341.77V348ZM691.76 348.12C693.22 348.12 694.26 347.19 694.26 345.82V345.8C694.26 344.47 693.2 343.66 691.84 343.66C691.26 343.66 690.87 343.79 690.46 343.97L690.61 341.73H693.95V341H689.92L689.72 344.41L690.25 344.76C690.66 344.54 691.13 344.36 691.72 344.36C692.74 344.36 693.46 344.96 693.46 345.83V345.85C693.46 346.74 692.77 347.4 691.77 347.4C691.06 347.4 690.39 347.03 689.78 346.44L689.26 347.01C689.89 347.64 690.76 348.12 691.76 348.12Z"
				fill="#333333"
			/>
			<path // 16
				d="M714.761 348H715.541V340.95H714.961L713.251 341.54L713.441 342.18L714.761 341.77V348ZM719.885 348.12C721.305 348.12 722.395 347.14 722.395 345.79V345.77C722.395 344.46 721.275 343.62 719.975 343.62C718.955 343.62 718.365 344.13 717.985 344.69C717.975 342.88 718.765 341.6 720.035 341.6C720.675 341.6 721.165 341.85 721.685 342.29L722.145 341.68C721.535 341.18 720.915 340.88 720.075 340.88C718.245 340.88 717.165 342.55 717.165 344.68V344.7C717.165 346.13 717.485 346.85 718.045 347.41C718.485 347.85 719.115 348.12 719.885 348.12ZM719.895 347.42C718.865 347.42 718.125 346.74 718.125 345.85V345.83C718.125 345.05 718.825 344.3 719.875 344.3C720.885 344.3 721.585 344.94 721.585 345.8V345.82C721.585 346.71 720.925 347.42 719.895 347.42Z"
				fill="#333333"
			/>
			<path // 17
				d="M743.019 348H743.799V340.95H743.219L741.509 341.54L741.699 342.18L743.019 341.77V348ZM746.183 348H747.063L750.243 341.59V341H745.493V341.72H749.343L746.183 348Z"
				fill="#333333"
			/>
			<path // 18
				d="M770.849 348H771.629V340.95H771.049L769.339 341.54L769.529 342.18L770.849 341.77V348ZM775.793 348.1C777.283 348.1 778.423 347.3 778.423 346.11V346.09C778.423 345.26 777.813 344.69 776.983 344.38C777.633 344.08 778.183 343.58 778.183 342.77V342.75C778.183 341.67 777.073 340.9 775.793 340.9C774.513 340.9 773.403 341.67 773.403 342.75V342.77C773.403 343.58 773.953 344.08 774.603 344.38C773.763 344.68 773.163 345.27 773.163 346.1V346.12C773.163 347.29 774.303 348.1 775.793 348.1ZM775.793 344.1C774.903 344.1 774.193 343.58 774.193 342.82V342.8C774.193 342.1 774.883 341.59 775.793 341.59C776.703 341.59 777.393 342.11 777.393 342.8V342.82C777.393 343.58 776.683 344.1 775.793 344.1ZM775.793 347.41C774.643 347.41 773.963 346.81 773.963 346.1V346.08C773.963 345.3 774.773 344.75 775.793 344.75C776.813 344.75 777.623 345.3 777.623 346.08V346.1C777.623 346.81 776.943 347.41 775.793 347.41Z"
				fill="#333333"
			/>
			<path // 19
				d="M798.761 348H799.541V340.95H798.961L797.251 341.54L797.441 342.18L798.761 341.77V348ZM803.505 348.12C805.235 348.12 806.415 346.55 806.415 344.33V344.31C806.415 342.91 806.075 342.13 805.545 341.6C805.095 341.15 804.505 340.88 803.705 340.88C802.215 340.88 801.185 341.93 801.185 343.26V343.28C801.185 344.54 802.185 345.45 803.625 345.45C804.595 345.45 805.225 344.94 805.605 344.33C805.635 346.12 804.785 347.4 803.525 347.4C802.905 347.4 802.375 347.15 801.805 346.65L801.345 347.26C801.935 347.75 802.595 348.12 803.505 348.12ZM803.715 344.77C802.695 344.77 801.995 344.13 801.995 343.24V343.22C801.995 342.35 802.625 341.58 803.675 341.58C804.725 341.58 805.455 342.28 805.455 343.17V343.19C805.455 343.99 804.785 344.77 803.715 344.77Z"
				fill="#333333"
			/>
			<path // 20
				d="M823.913 348H828.803V347.28H825.093L826.963 345.62C828.193 344.55 828.733 343.92 828.733 342.9V342.88C828.733 341.74 827.823 340.9 826.563 340.9C825.333 340.9 824.673 341.45 824.043 342.39L824.623 342.81C825.183 342.03 825.703 341.61 826.503 341.61C827.263 341.61 827.913 342.13 827.913 342.95C827.913 343.65 827.523 344.19 826.453 345.15L823.913 347.39V348ZM832.989 348.12C834.789 348.12 835.949 346.46 835.949 344.5V344.48C835.949 342.52 834.809 340.88 833.009 340.88C831.209 340.88 830.039 342.54 830.039 344.5V344.52C830.039 346.48 831.179 348.12 832.989 348.12ZM833.009 347.4C831.699 347.4 830.859 346.03 830.859 344.5V344.48C830.859 342.94 831.679 341.6 832.989 341.6C834.289 341.6 835.129 342.97 835.129 344.5V344.52C835.129 346.05 834.319 347.4 833.009 347.4Z"
				fill="#333333"
			/>
			<path // 21
				d="M853.695 348H858.585V347.28H854.875L856.745 345.62C857.975 344.55 858.515 343.92 858.515 342.9V342.88C858.515 341.74 857.605 340.9 856.345 340.9C855.115 340.9 854.455 341.45 853.825 342.39L854.405 342.81C854.965 342.03 855.485 341.61 856.285 341.61C857.045 341.61 857.695 342.13 857.695 342.95C857.695 343.65 857.305 344.19 856.235 345.15L853.695 347.39V348ZM860.991 348H861.771V340.95H861.191L859.481 341.54L859.671 342.18L860.991 341.77V348Z"
				fill="#333333"
			/>
			<path // 22
				d="M880.494 348H885.384V347.28H881.674L883.544 345.62C884.774 344.55 885.314 343.92 885.314 342.9V342.88C885.314 341.74 884.404 340.9 883.144 340.9C881.914 340.9 881.254 341.45 880.624 342.39L881.204 342.81C881.764 342.03 882.284 341.61 883.084 341.61C883.844 341.61 884.494 342.13 884.494 342.95C884.494 343.65 884.104 344.19 883.034 345.15L880.494 347.39V348ZM886.48 348H891.37V347.28H887.66L889.53 345.62C890.76 344.55 891.3 343.92 891.3 342.9V342.88C891.3 341.74 890.39 340.9 889.13 340.9C887.9 340.9 887.24 341.45 886.61 342.39L887.19 342.81C887.75 342.03 888.27 341.61 889.07 341.61C889.83 341.61 890.48 342.13 890.48 342.95C890.48 343.65 890.09 344.19 889.02 345.15L886.48 347.39V348Z"
				fill="#333333"
			/>
			<path // 23
				d="M908.42 348H913.31V347.28H909.6L911.47 345.62C912.7 344.55 913.24 343.92 913.24 342.9V342.88C913.24 341.74 912.33 340.9 911.07 340.9C909.84 340.9 909.18 341.45 908.55 342.39L909.13 342.81C909.69 342.03 910.21 341.61 911.01 341.61C911.77 341.61 912.42 342.13 912.42 342.95C912.42 343.65 912.03 344.19 910.96 345.15L908.42 347.39V348ZM917.037 348.12C918.337 348.12 919.377 347.26 919.377 346.01V345.99C919.377 344.71 918.287 344.12 917.067 344.02L919.267 341.58V341H914.797V341.71H918.217L916.007 344.2L916.167 344.67H916.617C917.767 344.67 918.577 345.15 918.577 346.02V346.04C918.577 346.86 917.897 347.4 917.047 347.4C916.187 347.4 915.537 347.02 914.997 346.36L914.427 346.87C914.997 347.61 915.867 348.12 917.037 348.12Z"
				fill="#333333"
			/>
			<path // 24
				d="M936.264 348H941.154V347.28H937.444L939.314 345.62C940.544 344.55 941.084 343.92 941.084 342.9V342.88C941.084 341.74 940.174 340.9 938.914 340.9C937.684 340.9 937.024 341.45 936.394 342.39L936.974 342.81C937.534 342.03 938.054 341.61 938.854 341.61C939.614 341.61 940.264 342.13 940.264 342.95C940.264 343.65 939.874 344.19 938.804 345.15L936.264 347.39V348ZM945.827 348H946.587V346.34H947.677V345.68H946.587V340.95H945.897L941.937 345.77L942.137 346.34H945.827V348ZM942.887 345.68L945.827 342.07V345.68H942.887Z"
				fill="#333333"
			/>
			<path // 25
				d="M964.425 348H969.315V347.28H965.605L967.475 345.62C968.705 344.55 969.245 343.92 969.245 342.9V342.88C969.245 341.74 968.335 340.9 967.075 340.9C965.845 340.9 965.185 341.45 964.555 342.39L965.135 342.81C965.695 342.03 966.215 341.61 967.015 341.61C967.775 341.61 968.425 342.13 968.425 342.95C968.425 343.65 968.035 344.19 966.965 345.15L964.425 347.39V348ZM972.962 348.12C974.422 348.12 975.462 347.19 975.462 345.82V345.8C975.462 344.47 974.402 343.66 973.042 343.66C972.462 343.66 972.072 343.79 971.662 343.97L971.812 341.73H975.152V341H971.122L970.922 344.41L971.452 344.76C971.862 344.54 972.332 344.36 972.922 344.36C973.942 344.36 974.662 344.96 974.662 345.83V345.85C974.662 346.74 973.972 347.4 972.972 347.4C972.262 347.4 971.592 347.03 970.982 346.44L970.462 347.01C971.092 347.64 971.962 348.12 972.962 348.12Z"
				fill="#333333"
			/>
			<path // 26
				d="M992.25 348H997.14V347.28H993.43L995.3 345.62C996.53 344.55 997.07 343.92 997.07 342.9V342.88C997.07 341.74 996.16 340.9 994.9 340.9C993.67 340.9 993.01 341.45 992.38 342.39L992.96 342.81C993.52 342.03 994.04 341.61 994.84 341.61C995.6 341.61 996.25 342.13 996.25 342.95C996.25 343.65 995.86 344.19 994.79 345.15L992.25 347.39V348ZM1001.09 348.12C1002.51 348.12 1003.6 347.14 1003.6 345.79V345.77C1003.6 344.46 1002.48 343.62 1001.18 343.62C1000.16 343.62 999.566 344.13 999.186 344.69C999.176 342.88 999.966 341.6 1001.24 341.6C1001.88 341.6 1002.37 341.85 1002.89 342.29L1003.35 341.68C1002.74 341.18 1002.12 340.88 1001.28 340.88C999.446 340.88 998.366 342.55 998.366 344.68V344.7C998.366 346.13 998.686 346.85 999.246 347.41C999.686 347.85 1000.32 348.12 1001.09 348.12ZM1001.1 347.42C1000.07 347.42 999.326 346.74 999.326 345.85V345.83C999.326 345.05 1000.03 344.3 1001.08 344.3C1002.09 344.3 1002.79 344.94 1002.79 345.8V345.82C1002.79 346.71 1002.13 347.42 1001.1 347.42Z"
				fill="#333333"
			/>
			<path // 27
				d="M1020.57 348H1025.46V347.28H1021.75L1023.62 345.62C1024.85 344.55 1025.39 343.92 1025.39 342.9V342.88C1025.39 341.74 1024.48 340.9 1023.22 340.9C1021.99 340.9 1021.33 341.45 1020.7 342.39L1021.28 342.81C1021.84 342.03 1022.36 341.61 1023.16 341.61C1023.92 341.61 1024.57 342.13 1024.57 342.95C1024.57 343.65 1024.18 344.19 1023.11 345.15L1020.57 347.39V348ZM1027.33 348H1028.21L1031.39 341.59V341H1026.64V341.72H1030.49L1027.33 348Z"
				fill="#333333"
			/>
			<path // 28
				d="M1048.34 348H1053.23V347.28H1049.52L1051.39 345.62C1052.62 344.55 1053.16 343.92 1053.16 342.9V342.88C1053.16 341.74 1052.25 340.9 1050.99 340.9C1049.76 340.9 1049.1 341.45 1048.47 342.39L1049.05 342.81C1049.61 342.03 1050.13 341.61 1050.93 341.61C1051.69 341.61 1052.34 342.13 1052.34 342.95C1052.34 343.65 1051.95 344.19 1050.88 345.15L1048.34 347.39V348ZM1056.99 348.1C1058.48 348.1 1059.62 347.3 1059.62 346.11V346.09C1059.62 345.26 1059.01 344.69 1058.18 344.38C1058.83 344.08 1059.38 343.58 1059.38 342.77V342.75C1059.38 341.67 1058.27 340.9 1056.99 340.9C1055.71 340.9 1054.6 341.67 1054.6 342.75V342.77C1054.6 343.58 1055.15 344.08 1055.8 344.38C1054.96 344.68 1054.36 345.27 1054.36 346.1V346.12C1054.36 347.29 1055.5 348.1 1056.99 348.1ZM1056.99 344.1C1056.1 344.1 1055.39 343.58 1055.39 342.82V342.8C1055.39 342.1 1056.08 341.59 1056.99 341.59C1057.9 341.59 1058.59 342.11 1058.59 342.8V342.82C1058.59 343.58 1057.88 344.1 1056.99 344.1ZM1056.99 347.41C1055.84 347.41 1055.16 346.81 1055.16 346.1V346.08C1055.16 345.3 1055.97 344.75 1056.99 344.75C1058.01 344.75 1058.82 345.3 1058.82 346.08V346.1C1058.82 346.81 1058.14 347.41 1056.99 347.41Z"
				fill="#333333"
			/>
			<path // 29
				d="M1076.25 348H1081.14V347.28H1077.43L1079.3 345.62C1080.53 344.55 1081.07 343.92 1081.07 342.9V342.88C1081.07 341.74 1080.16 340.9 1078.9 340.9C1077.67 340.9 1077.01 341.45 1076.38 342.39L1076.96 342.81C1077.52 342.03 1078.04 341.61 1078.84 341.61C1079.6 341.61 1080.25 342.13 1080.25 342.95C1080.25 343.65 1079.86 344.19 1078.79 345.15L1076.25 347.39V348ZM1084.71 348.12C1086.44 348.12 1087.62 346.55 1087.62 344.33V344.31C1087.62 342.91 1087.28 342.13 1086.75 341.6C1086.3 341.15 1085.71 340.88 1084.91 340.88C1083.42 340.88 1082.39 341.93 1082.39 343.26V343.28C1082.39 344.54 1083.39 345.45 1084.83 345.45C1085.8 345.45 1086.43 344.94 1086.81 344.33C1086.84 346.12 1085.99 347.4 1084.73 347.4C1084.11 347.4 1083.58 347.15 1083.01 346.65L1082.55 347.26C1083.14 347.75 1083.8 348.12 1084.71 348.12ZM1084.92 344.77C1083.9 344.77 1083.2 344.13 1083.2 343.24V343.22C1083.2 342.35 1083.83 341.58 1084.88 341.58C1085.93 341.58 1086.66 342.28 1086.66 343.17V343.19C1086.66 343.99 1085.99 344.77 1084.92 344.77Z"
				fill="#333333"
			/>
			<path // 30
				d="M1106.47 348.12C1107.77 348.12 1108.81 347.26 1108.81 346.01V345.99C1108.81 344.71 1107.72 344.12 1106.5 344.02L1108.7 341.58V341H1104.23V341.71H1107.65L1105.44 344.2L1105.6 344.67H1106.05C1107.2 344.67 1108.01 345.15 1108.01 346.02V346.04C1108.01 346.86 1107.33 347.4 1106.48 347.4C1105.62 347.4 1104.97 347.02 1104.43 346.36L1103.86 346.87C1104.43 347.61 1105.3 348.12 1106.47 348.12ZM1113.06 348.12C1114.86 348.12 1116.02 346.46 1116.02 344.5V344.48C1116.02 342.52 1114.88 340.88 1113.08 340.88C1111.28 340.88 1110.11 342.54 1110.11 344.5V344.52C1110.11 346.48 1111.25 348.12 1113.06 348.12ZM1113.08 347.4C1111.77 347.4 1110.93 346.03 1110.93 344.5V344.48C1110.93 342.94 1111.75 341.6 1113.06 341.6C1114.36 341.6 1115.2 342.97 1115.2 344.5V344.52C1115.2 346.05 1114.39 347.4 1113.08 347.4Z"
				fill="#333333"
			/>
			<path // 31
				d="M1136.25 348.12C1137.55 348.12 1138.59 347.26 1138.59 346.01V345.99C1138.59 344.71 1137.5 344.12 1136.28 344.02L1138.48 341.58V341H1134.01V341.71H1137.43L1135.22 344.2L1135.38 344.67H1135.83C1136.98 344.67 1137.79 345.15 1137.79 346.02V346.04C1137.79 346.86 1137.11 347.4 1136.26 347.4C1135.4 347.4 1134.75 347.02 1134.21 346.36L1133.64 346.87C1134.21 347.61 1135.08 348.12 1136.25 348.12ZM1141.06 348H1141.84V340.95H1141.26L1139.55 341.54L1139.74 342.18L1141.06 341.77V348Z"
				fill="#333333"
			/>
			<path // 32
				transform='translate(1160 340)'
				d="M3.05043 8.12C1.88043 8.12 1.01043 7.61 0.44043 6.87L1.01043 6.36C1.55043 7.02 2.20043 7.4 3.06043 7.4C3.91043 7.4 4.59043 6.86 4.59043 6.04V6.02C4.59043 5.15 3.78043 4.67 2.63043 4.67H2.18043L2.02043 4.2L4.23043 1.71H0.81043V1H5.28043V1.58L3.08043 4.02C4.30043 4.12 5.39043 4.71 5.39043 5.99V6.01C5.39043 7.26 4.35043 8.12 3.05043 8.12ZM6.55324 8V7.39L9.09324 5.15C10.1632 4.19 10.5532 3.65 10.5532 2.95C10.5532 2.13 9.90324 1.61 9.14324 1.61C8.34324 1.61 7.82324 2.03 7.26324 2.81L6.68324 2.39C7.31324 1.45 7.97324 0.9 9.20324 0.9C10.4632 0.9 11.3732 1.74 11.3732 2.88V2.9C11.3732 3.92 10.8332 4.55 9.60324 5.62L7.73324 7.28H11.4432V8H6.55324Z"
				fill="#333333"
			/>
		</g>
	);
}
