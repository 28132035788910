
import React from 'react';
import NumberedBox from "./NumberedBox";
import { rowHeightSpecing } from './AirplaneBoxMain';

export function GroupedNumberedBoxes({
	numbers = [],
	x = 0,
	y = 0,
	onClick,
	setClosePopup,
	setPopup,
	biggestArrayLength,
}) {

	return (
		<g
			// transform(x,y) x=0 left , y=0 top
			transform={`translate(${x},${y})`}
		>
			{numbers.map((num, indxRaw) => {

				const yMultiplier = (indxRaw + 1) > biggestArrayLength ? Math.floor(indxRaw / biggestArrayLength) : 0

				let xOffset = yMultiplier !== 0 ? biggestArrayLength * -1 * (yMultiplier) : 0

				return (
					<g
						onMouseEnter={() => {
							if (setPopup) {
								setPopup((indxRaw + xOffset) * 23, num.data, (yMultiplier + 1) * rowHeightSpecing);
							}
							// console.log(selfRef.current?.getBoundingClientRect())
						}}
						onMouseLeave={() => {
							setClosePopup();
						}}
						transform={`translate(0 ${yMultiplier * 30})`}
					>
						<NumberedBox
							number={num.value}
							x={(indxRaw + xOffset) * 23}
							boxColor={num.color}
							data={num.data}
							onClick={onClick}
						/>
					</g>
				);
			})}
		</g>
	);
}
