

/**
 * @param {Object} outputList // object with key-value pair of => key:boolean/object object
 * @description {node:{type: {id:false, name:true, age:true}}}
 * @returns string gqlstring
 */

export function generateQueryBody(outputList) {

	let outputStr = ''

	outputStr = '{'
	Object.keys(outputList).map((key) => {
		if (_.isObject(outputList?.[key])) {
			// object
			outputStr = `${outputStr} ${key} ${generateQueryBody(outputList?.[key])}`
		} else {
			if (outputList?.[key]) {
				outputStr = `${outputStr} ${key}`
			}
		}
		// else if (outputList?.[key]?.length) {// array}
	})
	outputStr = `${outputStr} }`

	return outputStr
}

/**
 * @typedef {'string' | 'number' | 'arrayOfStrings' | 'object'} GQLInputType
 */

/**
 * @typedef {Object} GQLInput
 * @property {string} name
 * @property {string} value
 * @property {GQLInputType} type
 */

/**
 * @param {GQLInput[]} inputList
 * @returns 
 */
export function generateQueryInput(inputList) {

	let inputStr = ''

	let inputListHasValues = false

	inputList.forEach((inputObj) => {

		let valStr = ''
		if (inputObj?.value) {
			switch (inputObj?.type) {
				case 'number':
					valStr = `${inputObj?.value}`
					break;
				case 'arrayOfStrings':
					valStr = `${JSON.stringify(inputObj?.value)}`
					break;
				case 'object':
					valStr = `${JSON.stringify(inputObj?.value)}`
					break;
				case 'string':
					valStr = `"${inputObj?.value}"`
					break;
				default:
					// add quotes
					valStr = `"${inputObj?.value}"`
					break;
			}
			inputStr = `${inputStr} ${inputObj?.name} : ${valStr}`
			inputListHasValues = true
		}

	})

	// place the start parenthesis
	if (inputList.length > 0 && inputListHasValues) { inputStr = `( ${inputStr}` }

	// place the end parenthesis
	if (inputList.length > 0 && inputListHasValues) { inputStr = `${inputStr} )` }

	return inputStr;
}

/**
 * @typedef {'query' | 'mutation'} gqlStringQueryType
 */

/**
 * @typedef {Object} generateGQLQueryProps
 * @property {GQLInput[]} inputList
 * @property {Object} outputList // object with key-value pair of => key:boolean/object object
 * @property {string} queryUnsignificantName
 * @property {string} querySign
 * @property {gqlStringQueryType} gqlStringQueryType
 */

/**
 * @param {generateGQLQueryProps} props 
 */
export function generateGQLQuery(props) {
	return (`
		${props?.gqlStringQueryType} ${props?.queryUnsignificantName} {
			${props?.querySign}
			${generateQueryInput(props?.inputList)} 
			${generateQueryBody(props?.outputList)}
		}
	`)
}