import React from "react";
import { Grid } from "@mui/material";
import styles from "../Dasr.module.css";
import IconAirplaneTilt from "common/images/icons/elevade/Icon-AirplaneTilt.svg";
import { LightTooltip } from "common/components";

const StandbyAircraft = ({ data }) => {
  function formatACReg(e) {
    return <div className={`${styles.formatAcRegActive}`}>{e}</div>;
  }
  return (
    <>
      <div className={styles.divCard} style={{ marginBottom: "24px" }}>
        <Grid container columns={2}>
          <Grid item xs={1} className={styles.gridTitle} style={{ paddingBottom: "24px" }}>
            Standby Aircraft
          </Grid>
          <Grid item xs={1} className={`${styles.gridTitle} ${styles.gridJustifyEnd}`}>
            <img
              src={IconAirplaneTilt}
              alt="airplane-tilt"
              height={16}
              style={{ marginRight: "6px", marginTop: "2px" }}
            />
            {data?.filter((e) => e.status.status === "Standby" || e.status.status === "Utilised").length}
          </Grid>
          <Grid item xs={2}>
            <div
              style={{ maxHeight: "230px", minHeight: "115px", paddingTop: "10px" }}
              className={`${styles.hideScrollBar}  ${styles.macScrollbar}`}
            >
              {data
                ?.filter((item) => item.status.status === "Standby" || item.status.status === "Utilised")
                .map((e, index) => {
                  return (
                    <Grid container columns={2} sx={{ paddingBottom: "16px" }} key={index}>
                      <Grid item xs={2} sm={2} md={2} lg={2} xl={2} sx={{ display: "flex" }}>
                        {formatACReg(e.aircraftReg)}
                        <LightTooltip title={e.status.remarks || ""} followCursor placement="bottom-start">
                          <div className={`${styles.divCardRemarks} ${styles.divStandbyRemarks}`}>
                            {e.status.remarks || "-"}
                          </div>
                        </LightTooltip>
                      </Grid>
                    </Grid>
                  );
                })}
              {(data?.filter((e) => e.status.status === "Standby" || e.status.status === "Utilised").length === 0 ||
                data === undefined) && (
                <Grid container columns={1} sx={{ paddingBottom: "16px" }}>
                  <Grid item xs={1} sx={{ fontSize: "12px", fontStyle: "italic", color: "#818181" }}>
                    No data found
                  </Grid>
                </Grid>
              )}
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default StandbyAircraft;
