import { env_var_REACT_APP_API_URL, env_var_REACT_APP_API_URL_ADV } from "../../envVar/vars";
import useGetGQL from "../useGetGQL";
import { useAppSelector } from "../../app/hooks";

export function useGetAllLocationInventoryDetails({
	fleetId,
	locationType, // code
	searchSeat,
}) {
	const locationInventoryDetails = useGetGQL({
		gqlquery: `
		query location_seat{
			allLocationInventoryDetail (
				fleetId:"${fleetId}",
				 # inventoryLocation:"${locationType}"
			${searchSeat ? `, icontainsSeatNo:"${searchSeat}"` : ""}
			) {
			  edges{
				node{
				  id
				  inventoryLocation{
					id
					name
				  }
				  occupied
				  seatNo
				}
			  }
			}
		  }
		`,
		querySign: "allLocationInventoryDetail",
		url: env_var_REACT_APP_API_URL ?? "",
		defaultIsLoading: true,
	});

	return locationInventoryDetails;
}

/**
 * @description Status : migrated!
 * @change useGetAllInvLocations => useGetAllLocationTypes
 */
export function useGetAllLocationTypes() {

	const data = useGetGQL({
		gqlquery: `
		query location{
			allInventoryLocations{
			  edges{
				node{
				  id
				  name
				  code
				}
			  }
			}
		  }
		`,
		querySign: "allInventoryLocations",
		url: env_var_REACT_APP_API_URL ?? "",
		defaultIsLoading: true,
	});

	const dataAdv = useGetGQL({
		gqlquery: `
		query allLocationType {
			allLocationTypes {
				items {
				_id
				name
				code
				}
			}
		}
		`,
		querySign: "allLocationTypes",
		url: env_var_REACT_APP_API_URL_ADV ?? "",
		defaultIsLoading: true,
		isEdges: false,
	});

	function convertFromNew(dataAdv) {
		return {
			...dataAdv,
			data: dataAdv?.data?.items?.map((node) => {
				return {
					...node,
					id: node?._id,
				}
			}),
		}
	}

	function convertFromOld(data) {
		return {
			...data,
			data: data?.data?.map(({ node }) => {
				return node
			})
		}
	}

	// console.log({
	// 	title: 'useGetAllInvLocations => useGetAllLocationTypes',
	// 	data,
	// 	dataAdv,
	// 	convertedOld: convertFromOld(data),
	// 	convertedNew: convertFromNew(dataAdv),
	// })

	// return convertFromOld(data);
	return convertFromNew(dataAdv);
}

export function useGetAllLocationInventoryDetailsFiltered({
	fleetId,
	inventoryLocId,
	searchSeatNo,
}) {
	// { data: locNoData, isLoading: locNoIsLoading, }
	const data = useGetGQL({
		gqlquery: `
		query location_seat{
			allLocationInventoryDetail (fleetId:"${fleetId
			}", inventoryLocation:"${inventoryLocId}"
			${searchSeatNo ? `, icontainsSeatNo:"${searchSeatNo}"` : ""}
			) {
			  edges{
				node{
				  id
				  inventoryLocation{
					id
					name
				  }
				  occupied
				  seatNo
				}
			  }
			}
		  }
		`,
		querySign: "allLocationInventoryDetail",
		url: env_var_REACT_APP_API_URL ?? "",
		defaultIsLoading: true,
	});
	return data;
}