import React from "react";
import { Grid, Switch } from "@mui/material";
import { makeStyles } from "@mui/styles";
import styles from "./DDML.module.css";
import { InputField, InputFieldCalendar } from "pages/elevade/component/InputForm";
import { useState } from "react";
import { useEffect } from "react";
import { makeAPIRequestRun } from "common/helpers";
import { formatDateDasr, addingDate, calculateBalanceDay } from "pages/elevade/component/Helpers";

const DefermentExpiryStructure = ({ formData, setFormData, updateArrayColumn }) => {
  const entity = process.env.REACT_APP_ENTITY;
  const [showInTable, setShowInTable] = useState("");
  const [aircraftReg, setAircraftReg] = useState(formData[0].aircraftReg);
  const [flightHour, setFlightHour] = useState("");
  const [flightCycle, setFlightCycle] = useState("");
  const [expiryFH, setExpiryFH] = useState(0);
  const [balanceFH, setBalanceFH] = useState(0);
  const [expiryFC, setExpiryFC] = useState(0);
  const [balanceFC, setBalanceFC] = useState(0);
  const [balanceCalendar, setBalanceCalendar] = useState(0);
  const [expiryCalendar, setExpiryCalendar] = useState(0);

  useEffect(() => {
    setAircraftReg(formData[0].aircraftReg);
    // eslint-disable-next-line
  }, [formData[0].aircraftReg]);

  useEffect(() => {
    if (!formData[0].flightHours && formData[0].showInTable === "fh") setFormData(updateArrayColumn("showInTable", ""));
    else if (!formData[0].flightCycle && formData[0].showInTable === "fc")
      setFormData(updateArrayColumn("showInTable", ""));
    else if (!formData[0].calendarDays && formData[0].showInTable === "cd")
      setFormData(updateArrayColumn("showInTable", ""));
  }, [formData[0].flightHours, formData[0].flightCycle, formData[0].calendarDays]);

  useEffect(() => {
    if (aircraftReg !== undefined && aircraftReg !== "") getFlightDate();
    // eslint-disable-next-line
  }, [aircraftReg]);

  useEffect(() => {
    setFormData(updateArrayColumn("flightHoursCurrent", parseInt(flightHour).toLocaleString()));
    // eslint-disable-next-line
  }, [flightHour]);

  useEffect(() => {
    setFormData(updateArrayColumn("flightCycleCurrent", parseInt(flightCycle).toLocaleString()));
    // eslint-disable-next-line
  }, [flightCycle]);

  useEffect(() => {
    calcExpiryFH();
    calcBalanceFH();
    // eslint-disable-next-line
  }, [formData[0].flightHoursInterval, formData[0].flightHoursRaisedAt, flightHour, flightCycle]);

  useEffect(() => {
    calcExpiryFC();
    calcBalanceFC();
    // eslint-disable-next-line
  }, [formData[0].flightCycleInterval, formData[0].flightCycleRaisedAt, flightHour, flightCycle]);

  useEffect(() => {
    calcExpiryCD();
    calcBalanceCD();
    // eslint-disable-next-line
  }, [formData[0].calendarDaysInterval, formData[0].calendarDaysStartDate]);

  async function getFlightDate() {
    const fetchData = makeAPIRequestRun(`get`, `acarsdata/fleetgetfcfh/${aircraftReg}`);

    fetchData
      .then((response) => {
        setFlightHour(response[0].flight_hours);
        setFlightCycle(response[0].flight_cycle);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  const handleChange = (e) => {
    if (e !== showInTable) {
      setShowInTable(e);
      setFormData(updateArrayColumn("showInTable", e));
    } else {
      setShowInTable("");
      setFormData(updateArrayColumn("showInTable", ""));
    }
  };

  // Custom styles
  const useStyles = makeStyles({
    root: {
      width: "40px",
      maxWidth: "40px",
      maxHeight: "22px",
      padding: "0px",
    },
    switchBase: {
      color: "#818181",
      padding: "1px",
      "&$checked": {
        "& + $track": {
          backgroundColor: "#088FD1",
        },
      },
    },
    thumb: {
      color: "white",
      width: "18px",
      height: "18px",
      margin: "1px",
    },
    track: {
      borderRadius: "20px",
      backgroundColor: "#D9D9D9",
      opacity: "1 !important",
      "&:after": {
        content: ``,
        left: "8px",
      },
      "&:before": {
        content: ``,
        right: "8px",
      },
    },
    checked: {
      color: "#394458 !important",
      transform: "translateX(18px) !important",
    },
    labelPlacementStart: {
      marginRight: "12px",
      color: "#838383",
    },
  });

  const classes = useStyles();

  const EyeBlack = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        style={{ marginLeft: "10px" }}
      >
        <g clip-path="url(#clip0_22383_127813)">
          <path
            d="M3.75 3.125L16.25 16.875"
            stroke="#333333"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12.1024 12.3125C11.4891 12.8701 10.6794 13.1612 9.85146 13.1218C9.02351 13.0824 8.24514 12.7157 7.68756 12.1023C7.12998 11.489 6.83888 10.6793 6.87829 9.8514C6.91771 9.02345 7.2844 8.24508 7.89772 7.6875"
            stroke="#333333"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10.5883 6.92969C11.2526 7.05691 11.8578 7.39628 12.3128 7.89681C12.7678 8.39735 13.0482 9.03201 13.1117 9.70547"
            stroke="#333333"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M16.2977 13.2109C18.0008 11.6859 18.75 10 18.75 10C18.75 10 16.25 4.37501 10 4.37501C9.45879 4.37427 8.91842 4.41817 8.3844 4.50626"
            stroke="#333333"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M5.78125 5.35938C2.59609 6.97187 1.25 10 1.25 10C1.25 10 3.75 15.625 10 15.625C11.4645 15.6365 12.9106 15.2991 14.2188 14.6406"
            stroke="#333333"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_22383_127813">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  };

  const EyeGreen = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        style={{ marginLeft: "10px" }}
      >
        <g clip-path="url(#clip0_22383_127763)">
          <path
            d="M10 4.375C3.75 4.375 1.25 10 1.25 10C1.25 10 3.75 15.625 10 15.625C16.25 15.625 18.75 10 18.75 10C18.75 10 16.25 4.375 10 4.375Z"
            stroke="#08D1AF"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10 13.125C11.7259 13.125 13.125 11.7259 13.125 10C13.125 8.27411 11.7259 6.875 10 6.875C8.27411 6.875 6.875 8.27411 6.875 10C6.875 11.7259 8.27411 13.125 10 13.125Z"
            stroke="#08D1AF"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_22383_127763">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  };

  function setToday() {
    // Create a new Date object
    const currentDate = new Date();

    // Get the individual components of the date
    const year = currentDate.getFullYear();
    // JavaScript months are 0-indexed, so we add 1 to get the correct month
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const day = currentDate.getDate().toString().padStart(2, "0");

    // Create a string in the desired format
    const formattedDate = `${year}-${month}-${day}T00:00:00`;
    return formattedDate;
  }

  function handleSwitch(event, col) {
    setFormData(updateArrayColumn(col, event.target.checked));
  }

  function calcExpiryFH() {
    let interval = formData[0].flightHoursInterval;
    let raisedAt = formData[0].flightHoursRaisedAt;
    let expiry = parseInt(interval) + parseInt(raisedAt);
    setExpiryFH(expiry);
  }

  function calcExpiryFC() {
    let interval = formData[0].flightCycleInterval;
    let raisedAt = formData[0].flightCycleRaisedAt;
    let expiry = parseInt(interval) + parseInt(raisedAt);
    setExpiryFC(expiry);
  }

  function calcBalanceFH() {
    let interval = formData[0].flightHoursInterval;
    let raisedAt = formData[0].flightHoursRaisedAt;
    let expiry = parseInt(interval) + parseInt(raisedAt);
    let current = flightHour;
    let balance = parseInt(expiry) - parseInt(current);
    setBalanceFH(balance);
  }

  function calcBalanceFC() {
    let interval = formData[0].flightCycleInterval;
    let raisedAt = formData[0].flightCycleRaisedAt;
    let expiry = parseInt(interval) + parseInt(raisedAt);
    let current = flightCycle;
    let balance = parseInt(expiry) - parseInt(current);
    setBalanceFC(balance);
  }

  function calcExpiryCD() {
    let startDate = formData[0].calendarDaysStartDate;
    let interval = formData[0].calendarDaysInterval || 0;
    let expiry = addingDate(startDate, interval);
    setExpiryCalendar(formatDateDasr(expiry));
  }

  function calcBalanceCD() {
    let startDate = formData[0].calendarDaysStartDate;
    let interval = formData[0].calendarDaysInterval || 0;
    let expiry = addingDate(startDate, interval);
    let today = setToday();
    let balance = calculateBalanceDay(today?.split("T")[0], expiry?.split("T")[0]);
    setBalanceCalendar(balance);
  }

  return (
    <>
      <Grid item xs={4} className={`${styles.title}`} sx={{ paddingTop: "24px" }}>
        Deferment Expiry
      </Grid>
      <Grid item xs={4} sx={{ color: "#838383", fontSize: "14px" }}>
        Select the deferment below to be displayed in the Aircraft Reports table
      </Grid>
      <Grid
        item
        xs={1}
        className={`${styles.title}`}
        sx={{
          paddingTop: "24px",
          paddingBottom: formData?.[0].flightHours ? "" : "24px",
          borderBottom: formData?.[0].flightHours ? "" : "1px solid #E8E8E8",
        }}
      >
        Flight Hours
      </Grid>
      <Grid
        item
        xs={1}
        sx={{
          paddingTop: "21px",
          paddingBottom: formData?.[0].flightHours ? "" : "24px",
          borderBottom: formData?.[0].flightHours ? "" : "1px solid #E8E8E8",
        }}
      >
        <Switch
          classes={{
            root: classes.root,
            switchBase: classes.switchBase,
            thumb: classes.thumb,
            track: classes.track,
            checked: classes.checked,
          }}
          checked={formData?.[0].flightHours}
          onChange={(event) => handleSwitch(event, "flightHours")}
          disabled={entity === "RBA" ? true : false}
        />
      </Grid>
      <Grid
        item
        xs={2}
        sx={{
          paddingTop: "21px",
          paddingBottom: formData?.[0].flightHours ? "" : "24px",
          borderBottom: formData?.[0].flightHours ? "" : "1px solid #E8E8E8",
          display: "flex",
          justifyContent: "flex-end",
          fontSize: "14px",
          color: "#838383",
        }}
      >
        {formData?.[0].flightHours && (
          <span style={{ cursor: "pointer" }} onClick={() => handleChange("fh")}>
            Show in the table {formData?.[0].showInTable === "fh" ? <EyeGreen /> : <EyeBlack />}
          </span>
        )}
      </Grid>
      {formData?.[0].flightHours && (
        <>
          <Grid item xs={1.4} className={`${styles.rowTitle}`}>
            Interval FH
          </Grid>
          <Grid item xs={1.4} className={`${styles.rowTitle}`}>
            Raised at FH
          </Grid>
          <Grid item xs={1.2} className={`${styles.rowTitle}`}>
            Current FH
          </Grid>
          <Grid item xs={1.4} className={`${styles.rowInput}`} sx={{ paddingRight: "24px" }}>
            <InputField
              fullWidth
              placeholder={""}
              formData={formData}
              setFormData={setFormData}
              col={"flightHoursInterval"}
              updateArrayColumn={updateArrayColumn}
              numbersonly
              disabled={entity === "RBA" ? true : false}
            />
          </Grid>
          <Grid item xs={1.4} className={`${styles.rowInput}`} sx={{ paddingRight: "24px" }}>
            <InputField
              fullWidth
              placeholder={""}
              formData={formData}
              setFormData={setFormData}
              col={"flightHoursRaisedAt"}
              updateArrayColumn={updateArrayColumn}
              numbersonly
              disabled={entity === "RBA" ? true : false}
            />
          </Grid>
          <Grid item xs={1.2} className={`${styles.rowInput}`}>
            <InputField
              fullWidth
              placeholder={""}
              formData={formData}
              setFormData={setFormData}
              col={"flightHoursCurrent"}
              updateArrayColumn={updateArrayColumn}
              numbersonly
              disabled={entity === "RBA" ? true : false}
            />
          </Grid>
          <Grid
            item
            xs={1.4}
            className={`${styles.rowTitle}`}
            sx={{ borderBottom: "1px solid #E8E8E8", paddingBottom: "24px" }}
          >
            Expiry
            <div className={`${styles.title}`} style={{ marginTop: "8px" }}>
              {!isNaN(expiryFH) ? parseInt(expiryFH).toLocaleString() : "-"} FH
            </div>
          </Grid>
          <Grid
            item
            xs={1.4}
            className={`${styles.rowTitle}`}
            sx={{ borderBottom: "1px solid #E8E8E8", paddingBottom: "24px" }}
          >
            Balance
            <div className={`${styles.title}`} style={{ marginTop: "8px" }}>
              {!isNaN(balanceFH) ? parseInt(balanceFH).toLocaleString() : "-"} FH
            </div>
          </Grid>
          <Grid
            item
            xs={1.2}
            className={`${styles.rowTitle}`}
            sx={{ borderBottom: "1px solid #E8E8E8", paddingBottom: "24px" }}
          ></Grid>
        </>
      )}
      <Grid
        item
        xs={1}
        className={`${styles.title}`}
        sx={{
          paddingTop: "24px",
          paddingBottom: formData?.[0].flightCycle ? "" : "24px",
          borderBottom: formData?.[0].flightCycle ? "" : "1px solid #E8E8E8",
        }}
      >
        Flight Cycles
      </Grid>
      <Grid
        item
        xs={1}
        sx={{
          paddingTop: "21px",
          paddingBottom: formData?.[0].flightCycle ? "" : "24px",
          borderBottom: formData?.[0].flightCycle ? "" : "1px solid #E8E8E8",
        }}
      >
        <Switch
          classes={{
            root: classes.root,
            switchBase: classes.switchBase,
            thumb: classes.thumb,
            track: classes.track,
            checked: classes.checked,
          }}
          checked={formData?.[0].flightCycle}
          onChange={(event) => handleSwitch(event, "flightCycle")}
          disabled={entity === "RBA" ? true : false}
        />
      </Grid>
      <Grid
        item
        xs={2}
        sx={{
          paddingTop: "21px",
          paddingBottom: formData?.[0].flightCycle ? "" : "24px",
          borderBottom: formData?.[0].flightCycle ? "" : "1px solid #E8E8E8",
          display: "flex",
          justifyContent: "flex-end",
          fontSize: "14px",
          color: "#838383",
        }}
      >
        {formData?.[0].flightCycle && (
          <span style={{ cursor: "pointer" }} onClick={() => handleChange("fc")}>
            Show in the table {formData?.[0].showInTable === "fc" ? <EyeGreen /> : <EyeBlack />}
          </span>
        )}
      </Grid>
      {formData?.[0].flightCycle && (
        <>
          <Grid item xs={1.4} className={`${styles.rowTitle}`}>
            Interval FC
          </Grid>
          <Grid item xs={1.4} className={`${styles.rowTitle}`}>
            Raised at FC
          </Grid>
          <Grid item xs={1.2} className={`${styles.rowTitle}`}>
            Current FC
          </Grid>
          <Grid item xs={1.4} className={`${styles.rowInput}`} sx={{ paddingRight: "24px" }}>
            <InputField
              fullWidth
              placeholder={""}
              formData={formData}
              setFormData={setFormData}
              col={"flightCycleInterval"}
              updateArrayColumn={updateArrayColumn}
              disabled={entity === "RBA" ? true : false}
              numbersonly
            />
          </Grid>
          <Grid item xs={1.4} className={`${styles.rowInput}`} sx={{ paddingRight: "24px" }}>
            <InputField
              fullWidth
              placeholder={""}
              formData={formData}
              setFormData={setFormData}
              col={"flightCycleRaisedAt"}
              updateArrayColumn={updateArrayColumn}
              disabled={entity === "RBA" ? true : false}
              numbersonly
            />
          </Grid>
          <Grid item xs={1.2} className={`${styles.rowInput}`}>
            <InputField
              fullWidth
              placeholder={""}
              formData={formData}
              setFormData={setFormData}
              col={"flightCycleCurrent"}
              updateArrayColumn={updateArrayColumn}
              disabled={entity === "RBA" ? true : false}
              numbersonly
            />
          </Grid>
          <Grid
            item
            xs={1.4}
            className={`${styles.rowTitle}`}
            sx={{ borderBottom: "1px solid #E8E8E8", paddingBottom: "24px" }}
          >
            Expiry
            <div className={`${styles.title}`} style={{ marginTop: "8px" }}>
              {!isNaN(expiryFC) ? parseInt(expiryFC).toLocaleString() : "-"} FC
            </div>
          </Grid>
          <Grid
            item
            xs={1.4}
            className={`${styles.rowTitle}`}
            sx={{ borderBottom: "1px solid #E8E8E8", paddingBottom: "24px" }}
          >
            Balance
            <div className={`${styles.title}`} style={{ marginTop: "8px" }}>
              {!isNaN(balanceFC) ? parseInt(balanceFC).toLocaleString() : "-"} FC
            </div>
          </Grid>
          <Grid
            item
            xs={1.2}
            className={`${styles.rowTitle}`}
            sx={{ borderBottom: "1px solid #E8E8E8", paddingBottom: "24px" }}
          ></Grid>
        </>
      )}
      <Grid
        item
        xs={1}
        className={`${styles.title}`}
        sx={{
          paddingTop: "24px",
          paddingBottom: formData?.[0].calendarDays ? "" : "24px",
          borderBottom: formData?.[0].calendarDays ? "" : "1px solid #E8E8E8",
        }}
      >
        Calendar Days
      </Grid>
      <Grid
        item
        xs={1}
        sx={{
          paddingTop: "21px",
          paddingBottom: formData?.[0].calendarDays ? "" : "24px",
          borderBottom: formData?.[0].calendarDays ? "" : "1px solid #E8E8E8",
        }}
      >
        <Switch
          classes={{
            root: classes.root,
            switchBase: classes.switchBase,
            thumb: classes.thumb,
            track: classes.track,
            checked: classes.checked,
          }}
          checked={formData?.[0].calendarDays}
          onChange={(event) => handleSwitch(event, "calendarDays")}
          disabled={entity === "RBA" ? true : false}
        />
      </Grid>
      <Grid
        item
        xs={2}
        sx={{
          paddingTop: "21px",
          paddingBottom: formData?.[0].calendarDays ? "" : "24px",
          borderBottom: formData?.[0].calendarDays ? "" : "1px solid #E8E8E8",
          display: "flex",
          justifyContent: "flex-end",
          fontSize: "14px",
          color: "#838383",
        }}
      >
        {formData?.[0].calendarDays && (
          <span style={{ cursor: "pointer" }} onClick={() => handleChange("cd")}>
            Show in the table {formData?.[0].showInTable === "cd" ? <EyeGreen /> : <EyeBlack />}
          </span>
        )}
      </Grid>
      {formData?.[0].calendarDays && (
        <>
          <Grid item xs={2} className={`${styles.rowTitle}`}>
            Interval Days<span className={styles.red}>*</span>
          </Grid>
          <Grid item xs={2} className={`${styles.rowTitle} ${styles.rowInputEnd}`}>
            First Flight Date<span className={styles.red}>*</span>
          </Grid>
          <Grid item xs={2} className={`${styles.rowInputStart}`}>
            <InputField
              fullWidth
              placeholder={""}
              formData={formData}
              setFormData={setFormData}
              col={"calendarDaysInterval"}
              updateArrayColumn={updateArrayColumn}
              disabled={entity === "RBA" ? true : false}
              numbersonly
            />
          </Grid>
          <Grid item xs={2} className={`${styles.rowInputEnd}`}>
            <InputFieldCalendar
              fullWidth
              placeholder={"Select date"}
              formData={formData}
              setFormData={setFormData}
              col={"calendarDaysStartDate"}
              startDate={formData[0].raisedDate}
            />
          </Grid>
          <Grid
            item
            xs={2}
            className={`${styles.rowTitle}`}
            sx={{ borderBottom: "1px solid #E8E8E8", paddingBottom: "24px" }}
          >
            Expiry
            <div className={`${styles.title}`} style={{ marginTop: "8px" }}>
              {expiryCalendar}
            </div>
          </Grid>
          <Grid
            item
            xs={2}
            className={`${styles.rowTitle}`}
            sx={{ borderBottom: "1px solid #E8E8E8", paddingBottom: "24px" }}
          >
            Balance
            <div className={`${styles.title}`} style={{ marginTop: "8px" }}>
              {balanceCalendar} Days
            </div>
          </Grid>
        </>
      )}
    </>
  );
};

export default DefermentExpiryStructure;
