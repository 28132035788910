import { Typography, Box, TextField, Divider, Grid, Avatar } from "@mui/material";
import React from 'react'
import { Fragment, useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import ItemTemplate from "./ItemTemplate";
import HistoryTemplate from "./HistoryTemplate";
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import {
	useGetLocationHistoryByIdWithHistorySearch,
	useGetLocationHistoryByIdWithHistorySearchAdv,
} from "../../../../models/custom/location-history-model";

export default function LocationHistoryTab({ location: inFleetLocation }) {
	const [search, setSearch] = useState(undefined);
	const itemTitleStyle = {
		textAlign: 'left',
		fontSize: '14px',
		color: '#838383',
		paddingRight: '1em',
	};
	// const history = useGetLocationHistoryByIdWithHistorySearch({ inFleetLocationId: inFleetLocation?.id, historySearch: search });
	const history = useGetLocationHistoryByIdWithHistorySearchAdv({ inFleetLocationId: inFleetLocation?.id, historySearch: search });

	if (history?.data?.items?.length < 1) {
		return (
			<Box
				sx={{
					width: "94%",
					marginLeft: "1em",
					marginRight: "1em",
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					paddingTop: '14px',
				}}
			>
				<Typography fontSize={'14px'} fontStyle='italic' color='GrayText' >No record(s) found</Typography>
			</Box>
		)
	}

	// const datas: InventoriesHistorySet = history.data
	return (
		<Fragment>
			<Box sx={{ width: '94%', marginLeft: '1em', marginRight: '1em', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
				<Typography sx={{ ...itemTitleStyle }}>Newest First</Typography>
				<VerticalAlignBottomIcon />
			</Box>
			<Box sx={{ width: '94%', marginLeft: '1em', marginRight: '1em' }}>
				<TextField
					fullWidth
					variant="standard"
					placeholder="Search"
					value={search} // Bind the search state as the value
					onChange={(event) => setSearch(event.target.value)} // Update the search state on change
					InputProps={{
						disableUnderline: true,
						startAdornment: (
							<SearchIcon
								sx={{
									fontSize: 50,
									cursor: "pointer",
									padding: "0.3em",
									'&:hover': {
										background: "whitesmoke",
										borderRadius: "1.5em",
									}
								}}
							/>
						),
					}} sx={{
						"& input::placeholder": {
							fontSize: "12px"
						}
					}} />
				<Divider />
			</Box>
			<Box sx={{ width: '100%', }}>
				{/* <pre>
					{JSON.stringify(history, null, 4)}
				</pre> */}
				{history?.data?.items?.map((item) => {
					return (<NodeBox locationhistorydetailsSet={item} />);
				})}
				{/* {inFleetLocation?.inventory?.id} */}
			</Box>
		</Fragment>
	);
}
function NodeBox({ locationhistorydetailsSet }) {
	return (
		<Fragment>
			<Divider />
			<Grid container sx={{ marginTop: "2em", marginBottom: "1em" }}>
				<Grid item xs={12} md={6}>
					<ItemTemplate icon={null} title={'Date/Time'} value={formatDatetime(locationhistorydetailsSet?.createdAt) ?? ""} />
				</Grid>
				<Grid item xs={12} md={6}>
					<ItemTemplate icon={locationhistorydetailsSet?.avatarCreated ? <Avatar src={locationhistorydetailsSet?.avatarCreated} /> : <Avatar />} title={'Updated By'} value={locationhistorydetailsSet?.createdBy ?? ""} />
				</Grid>
				<Grid item sx={{ marginTop: "1em", marginBottom: "1em" }}>
					<ItemTemplate icon={null} title={'Description'} value={
						<Fragment>
							{locationhistorydetailsSet?.descriptions?.map(() => {
								return (
									<HistoryTemplate icon={null} value={locationhistorydetailsSet?.descriptions} />
								)
							})}
						</Fragment>
					} />
				</Grid>
			</Grid>
		</Fragment>
	);
}
function formatDatetime(datetimeString) {
	const datetime = new Date(datetimeString);
	const month = datetime.toLocaleString('en-US', { month: 'long' });
	const day = datetime.getDate();
	const year = datetime.getFullYear();
	const hour = datetime.getHours();
	const minute = datetime.getMinutes();
	return `${month} ${day}, ${year} ${hour}:${minute}`;
}
