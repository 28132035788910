export default function ToiletsBehind() {
	return (
		<g transform='translate(1238 355)'>
			<rect x="48" y="33" width="30" height="47" rx="6" transform="rotate(90 48 33)" fill="white" stroke="#74C4E8" />
			<path d="M19.3875 47.375C19.3875 47.589 19.214 47.7625 19 47.7625C18.786 47.7625 18.6125 47.589 18.6125 47.375C18.6125 47.161 18.786 46.9875 19 46.9875C19.214 46.9875 19.3875 47.161 19.3875 47.375Z" fill="black" stroke="#394458" stroke-width="1.1" />
			<path d="M19 53.625C20.7259 53.625 22.125 50.8268 22.125 47.375C22.125 43.9232 20.7259 41.125 19 41.125C17.2741 41.125 15.875 43.9232 15.875 47.375C15.875 50.8268 17.2741 53.625 19 53.625Z" stroke="#394458" stroke-width="1.1" stroke-linecap="round" stroke-linejoin="round" />
			<path d="M22.125 47.375V54.25C22.125 54.4158 22.1908 54.5747 22.3081 54.6919C22.4253 54.8092 22.5842 54.875 22.75 54.875H31.5C31.6658 54.875 31.8247 54.8092 31.9419 54.6919C32.0592 54.5747 32.125 54.4158 32.125 54.25V47.375C32.125 43.9219 30.7266 41.125 29 41.125H19" stroke="#394458" stroke-width="1.1" stroke-linecap="round" stroke-linejoin="round" />
			<path d="M22.125 47.375H24" stroke="#394458" stroke-width="1.1" stroke-linecap="round" stroke-linejoin="round" />
			<path d="M26.5 47.375H27.75" stroke="#394458" stroke-width="1.1" stroke-linecap="round" stroke-linejoin="round" />
			<path d="M30.25 47.375H32.125" stroke="#394458" stroke-width="1.1" stroke-linecap="round" stroke-linejoin="round" />
			<rect x="1" y="30.5" width="30" height="47" rx="6" transform="rotate(-90 1 30.5)" fill="white" stroke="#74C4E8" />
			<path d="M20.3875 14.875C20.3875 15.089 20.214 15.2625 20 15.2625C19.786 15.2625 19.6125 15.089 19.6125 14.875C19.6125 14.661 19.786 14.4875 20 14.4875C20.214 14.4875 20.3875 14.661 20.3875 14.875Z" fill="black" stroke="#394458" stroke-width="1.1" />
			<path d="M20 21.125C21.7259 21.125 23.125 18.3268 23.125 14.875C23.125 11.4232 21.7259 8.625 20 8.625C18.2741 8.625 16.875 11.4232 16.875 14.875C16.875 18.3268 18.2741 21.125 20 21.125Z" stroke="#394458" stroke-width="1.1" stroke-linecap="round" stroke-linejoin="round" />
			<path d="M23.125 14.875V21.75C23.125 21.9158 23.1908 22.0747 23.3081 22.1919C23.4253 22.3092 23.5842 22.375 23.75 22.375H32.5C32.6658 22.375 32.8247 22.3092 32.9419 22.1919C33.0592 22.0747 33.125 21.9158 33.125 21.75V14.875C33.125 11.4219 31.7266 8.625 30 8.625H20" stroke="#394458" stroke-width="1.1" stroke-linecap="round" stroke-linejoin="round" />
			<path d="M23.125 14.875H25" stroke="#394458" stroke-width="1.1" stroke-linecap="round" stroke-linejoin="round" />
			<path d="M27.5 14.875H28.75" stroke="#394458" stroke-width="1.1" stroke-linecap="round" stroke-linejoin="round" />
			<path d="M31.25 14.875H33.125" stroke="#394458" stroke-width="1.1" stroke-linecap="round" stroke-linejoin="round" />
		</g>
	)
}