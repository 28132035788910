import React from "react";
import { useState, useEffect, useRef } from "react";
import { Grid, styled, Tab, Modal, Box, InputBase } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { makeStyles } from "@mui/styles";
import IconClose from "common/images/icons/elevade/Icon-Close.svg";
import styles from "./DASRForm.module.css";
import { AdeButton } from "common/components";
import {
  BasicCheckbox,
  BasicTimePicker,
  CheckBoxField,
  InputField,
  InputFieldCalendar,
  SelectFieldAutocomplete,
} from "pages/elevade/component/InputForm";
import { makeAPIRequestMDB } from "common/helpers/makeAPIRequestMDB";
import { useSelector } from "react-redux";
import firebase from "firebase/app";
import "firebase/storage";
import IconAttachment from "common/images/icons/elevade/Icon-Attachment.svg";
import IconSendButton from "common/images/icons/elevade/Icon-SendButton.svg";
import { showNotificationElevade } from "common/helpers";
import DDMLUpdateLogs from "../aircraftReport/DDMLUpdateLogs";
import Button from "pages/elevade/component/Button";
import DisplaySummary from "../aircraftStatus/Summary";

const DASRForm = ({ data, open, setOpen, ...props }) => {
  const [value, setValue] = useState("1");
  const [formError, setFormError] = useState([]);
  const [file] = useState([]);
  const { userProfile } = useSelector((state) => state.authEntity);
  const authEntity = useSelector((state) => state.authEntity);
  const { userProfile: userEntity, userPermission } = useSelector((state) => state.userEntity);
  const summaryPermission = userPermission.filter((perm) => perm.code === "DASR-004");
  const summaryPermissioncanUpdate = summaryPermission.some((perm) => perm.canUpdate === true);
  const rectificationPermission = userPermission.filter((perm) => perm.code === "DASR-005");
  const materialPermission = userPermission.filter((perm) => perm.code === "DASR-006");
  const ppcPermission = userPermission.filter((perm) => perm.code === "DASR-007");
  const today = new Date();
  const formattedDate = today.toISOString();
  const avatar = localStorage.getItem("avatar");
  const InitialData = [
    {
      formType: props.formType,
      formStatus: "open",
      aircraftReg: "",
      aoc: props.aoc,
      location: "",
      defectDesc: "",
      inputDate: formattedDate,
      ets: null,
      // etsTime: DateConversion(today),
      etsTime: null,
      tba: false,
      limitationSummary: "",
      // createdBy: userAuthEntity.userName,
      createdAt: formattedDate,
      mocHighlight: "",
    },
  ];
  const [formData, setFormData] = useState(data);
  const [DDMLLogs, setDDMLLogs] = useState([]);
  const [commentFileLink, setCommentFileLink] = useState();
  const [openPreviewComment, setOpenPreviewComment] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);
  const [previewUrl, setPreviewUrl] = useState([]);
  const storageRef = firebase.storage().ref();
  // const [uploadProgress, setUploadProgress] = useState(0);
  const [fileComment, setFileComment] = useState({});
  const [commentData, setCommentData] = useState([
    {
      ddmlId: "",
      updateLogCategory: "",
      updateLogDesc: "",
      userCreated: {
        name: userProfile?.displayname || userEntity.fullName,
        email: authEntity.currentUser.userName,
        avatarLink: userProfile?.avatar || avatar,
      },
      attachmentLog: [
        {
          fileName: "",
          fileType: "",
          fileLink: "",
        },
      ],
    },
  ]);
  const commentRef = useRef(null);
  const [id, setId] = useState();
  const [uploadComment, setUploadComment] = useState(false);
  const [inputCommentError, setInputCommentError] = useState(false);

  const [openConfirmCopy, setOpenConfirmCopy] = useState(false);
  const [commentPushSummaryId, setCommentPushSummaryId] = useState("");
  const [commentPushSummary, setCommentPushSummary] = useState("");
  const [category, setCategory] = useState();
  const [DDMLId, setDDMLId] = useState();
  const [isTBA, setIsTBA] = useState(formData.tba);
  const [isETSTime, setIsETSTime] = useState(formData.etsTime);

  const handleCloseConfirmCopy = () => setOpenConfirmCopy(false);
  const handleCloseComment = () => setOpenPreviewComment(false);

  const acceptedFileTypes = ["application/pdf", "image/jpeg", "image/png", "image/tiff"];
  const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (!file) {
      setSelectedFile(null);
      setPreviewUrl(null);
      return;
    }

    if (file.size <= maxFileSize && acceptedFileTypes.includes(file.type)) {
      // setError(false);
      setSelectedFile([...selectedFile, file]);
      setPreviewUrl([...previewUrl, URL.createObjectURL(file)]);
      UploadRenamedFile(file);
    } else {
      // setError(true);
      // setSelectedFile(null);
      // setPreviewUrl(null);
    }
  };

  useEffect(() => {
    setId(formData[0]?.id);
    setCommentData(updateArrayColumnComment("ddmlId", formData[0]?.id));
    // eslint-disable-next-line
  }, [formData]);

  useEffect(() => {
    if (isTBA) {
      updateArrayAsync();
    } else setFormData(updateArrayColumn("tba", false));
  }, [isTBA]);

  useEffect(() => {
    if (formData[0]?._id !== undefined) DDMLLogList();
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    let Category = "";
    if (value === "2") Category = "Rectification Update";
    else if (value === "3") Category = "Material/Tools Update";
    else if (value === "4") Category = "PPC";
    setCommentData(updateArrayColumnComment("updateLogCategory", Category));
    // eslint-disable-next-line
  }, [value]);

  useEffect(() => {
    let defaultTime = new Date().toISOString().split("T")[0] + "T23:59:00.000";
    if (formData[0].ets !== null && formData[0].etsTime === null) {
      setFormData(updateArrayColumn("etsTime", defaultTime));
    }
  }, [formData[0].ets]);

  const updateArrayAsync = async () => {
    setFormData(
      formData.map((item) => {
        return { ...item, ets: null, etsTime: null, tba: true };
      })
    );
    setFormError(formError.filter((err) => err !== "ets" && err !== "etsTime"));
  };

  async function DDMLLogList() {
    const params = `api/aircraft/updated-status/logs/${formData[0]._id}`;
    const fetch = makeAPIRequestMDB(`get`, `${params}`);
    fetch.then((response) => {
      setDDMLLogs(response);
      setUploadComment(false);
    });
  }

  async function UploadRenamedFile(file) {
    if (file) {
      // Generate a timestamp string.
      const timestamp = new Date().toISOString().replace(/:/g, "-");

      // Append the timestamp to the original filename.
      // This assumes file.name is a string like 'example.txt'.
      const fileNameWithTimestamp = `${file.name.split(".").slice(0, -1).join(".")}_${timestamp}.${file.name
        .split(".")
        .pop()}`;

      // Start the upload process with the new filename.
      const uploadTask = storageRef.child(`dasr/uploads/${fileNameWithTimestamp}`).put(file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.error("Error uploading file:", error);
        },
        async () => {
          const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
          setFileComment({ File: file, Link: downloadURL });
        }
      );
    }
  }

  useEffect(() => {
    setCommentData(updateArrayColumnComment("attachmentLog", file));
    // eslint-disable-next-line
  }, [fileComment]);

  useEffect(() => {
    props.newForm ? setFormData(InitialData) : setFormData(data);
    setFormError([]);
    // eslint-disable-next-line
  }, [open, data, props.newForm]);

  const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: "rgba(0, 0, 0, 0.85)",
    fontFamily: ["Gotham-Book"].join(","),
    fontSize: "14px",
    "&:hover": {
      color: "#333333",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#333333",
      fontFamily: "Gotham-Medium",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
      paddingBottom: "10px",
    },
    "&.MuiTab-root": {
      marginRight: "10px",
      marginTop: "-10px",
      marginBottom: "-10px",
    },
  }));

  const handleChange = (event, newValue) => {
    if (newValue === "2" && rectificationPermission.some((perm) => perm.canRetrieve === true)) {
      setValue(newValue);
    } else if (newValue === "3" && materialPermission.some((perm) => perm.canRetrieve === true)) {
      setValue(newValue);
    } else if (newValue === "4" && ppcPermission.some((perm) => perm.canRetrieve === true)) {
      setValue(newValue);
    } else if (newValue === "1") {
      setValue(newValue);
    }
    // setValue(newValue);
  };

  useEffect(() => {
    // fetchAircraftList();
    // eslint-disable-next-line
  }, [props.aoc]);

  useEffect(() => {
    setFormData(updateArrayColumn("attachment", file));
    // eslint-disable-next-line
  }, [file]);

  function DateConversion(date) {
    let year = date.getFullYear();
    let month = ("0" + (date.getMonth() + 1)).slice(-2);
    let day = ("0" + date.getDate()).slice(-2);
    let hours = ("0" + date.getHours()).slice(-2);
    let minutes = ("0" + date.getMinutes()).slice(-2);
    let seconds = ("0" + date.getSeconds()).slice(-2);
    let milliseconds = ("00" + date.getMilliseconds()).slice(-3);

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
  }

  function updateArrayColumnComment(column, value) {
    return commentData.map((item) => {
      if (column === "attachmentLog") {
        return {
          ...item,
          [column]: [
            { fileName: fileComment?.File?.name, fileType: fileComment?.File?.type, fileLink: fileComment?.Link },
          ],
        };
      }
      // Check if the item has the specified column and update it if exists
      else if (item.hasOwnProperty(column)) {
        return { ...item, [column]: value }; // Create a new object with updated column
      } else return { ...item, [column]: value }; // If the specified column doesn't exist, return the original item unchanged
    });
  }

  const DDMLTitle = () => {
    return props.newForm ? (
      <>
        <span
          style={{
            fontFamily: "Gotham-Medium",
          }}
        >
          {props.formType} New Report
        </span>
      </>
    ) : (
      <>
        <span
          style={{
            border: "1px solid #C3C3C3",
            borderRadius: "6px",
            padding: "4px 8px",
            marginRight: "8px",
            whiteSpace: "nowrap",
            fontFamily: "Gotham-Medium",
          }}
        >
          {data?.[0]?.aircraftReg}
        </span>
        <span>{data?.ddmlNo}</span>
      </>
    );
  };

  function updateArrayColumn(column, value) {
    return formData.map((item) => {
      if (column === "attachment") {
        return {
          ...item,
          [column]: value,
        };
      }
      // Check if the item has the specified column and update it if exists
      else if (item.hasOwnProperty(column)) {
        return { ...item, [column]: value }; // Create a new object with updated column
      } else return { ...item, [column]: value }; // If the specified column doesn't exist, return the original item unchanged
    });
  }

  function removeFromArray(col) {
    const indexToRemove = formError.indexOf(col);
    if (indexToRemove !== -1) {
      formError.splice(indexToRemove, 1);
    }
  }

  function validateError(col) {
    return formError.includes(col);
  }

  function getLimitations(aircraftReg) {
    let data = props.limitationData.filter((e) => e.aircraftReg === aircraftReg);
    if (data.length > 0) {
      return <div style={{ marginTop: "6px" }}>{data[0].limitationsDesc}</div>;
    } else return "-";
  }

  function tbaPermissionRelate() {
    if (formData[0]?.tba === false && summaryPermissioncanUpdate === false) {
      return true;
    } else if (formData[0]?.tba === false && summaryPermissioncanUpdate === true) {
      return false;
    } else if (formData[0]?.tba === true) {
      return true;
    }
  }

  const Summary = () => {
    return (
      <>
        <Grid container columns={4} id={"ddml-form"}>
          <Grid
            item
            xs={props.tab && props.tab !== "4" ? 2 : 4}
            className={`${styles.rowTitle} ${styles.rowInputStart}`}
          >
            Aircraft Registration<span className={styles.red}>*</span>
          </Grid>
          {props.tab && props.tab !== "4" && (
            <Grid item xs={2} className={`${styles.rowTitle} ${styles.rowInputEnd}`}>
              {props.formType === "Maintenance" ? "Maintenance " : ""}Location<span className={styles.red}>*</span>
            </Grid>
          )}
          <Grid item xs={props.tab && props.tab !== "4" ? 2 : 4} className={`${styles.rowInputStart}`}>
            <SelectFieldAutocomplete
              placeholder="Select A/C"
              fullWidth
              options={props.aircraftList}
              formData={formData}
              col={"aircraftReg"}
              disabled={true}
              onChange={(e) => {
                setFormData(updateArrayColumn("aircraftReg", e.target.textContent));
                removeFromArray("aircraftReg");
              }}
              value={formData[0].aircraftReg}
              error={validateError("aircraftReg")}
            />
          </Grid>
          {props.tab && props.tab !== "4" && (
            <Grid item xs={2} className={`${styles.rowInputEnd}`}>
              <InputField
                disabled={!summaryPermissioncanUpdate}
                fullWidth
                placeholder={"Insert Station"}
                formData={formData}
                setFormData={setFormData}
                col={"location"}
                updateArrayColumn={updateArrayColumn}
                error={validateError("location")}
              />
            </Grid>
          )}
          {props.tab && props.tab !== "4" && (
            <>
              <Grid item xs={4} className={`${styles.rowTitle} ${styles.rowInputStart}`}>
                Start Date<span className={styles.red}>*</span>
              </Grid>
              <Grid item xs={4}>
                <InputFieldCalendar
                  fullWidth
                  placeholder={"Select date"}
                  formData={formData}
                  setFormData={setFormData}
                  col={"inputDate"}
                  error={validateError("inputDate")}
                  disableFuture
                  disabled={!summaryPermissioncanUpdate}
                />
              </Grid>
              <Grid item xs={2} className={`${styles.rowTitle} ${styles.rowInputEnd}`}>
                ETS Date
              </Grid>
              <Grid item xs={2} className={`${styles.rowTitle} ${styles.rowInputEnd}`}>
                ETS Time
              </Grid>
              <Grid item xs={2} className={`${styles.rowInputStart}`}>
                <InputFieldCalendar
                  fullWidth
                  placeholder={"Select date"}
                  formData={formData}
                  setFormData={setFormData}
                  col={"ets"}
                  error={validateError("ets")}
                  disabled={tbaPermissionRelate()}
                  // disabled={formData[0]?.tba}
                />
              </Grid>
              <Grid item xs={2} className={`${styles.rowInputEnd}`}>
                <BasicTimePicker
                  formData={formData}
                  setFormData={setFormData}
                  col={"etsTime"}
                  value={isETSTime}
                  setValue={setIsETSTime}
                  error={validateError("etsTime")}
                  disabled={tbaPermissionRelate("TIMEEESSS", formData[0]?.aircraftReg)}
                  // disabled={formData[0]?.tba}
                />
              </Grid>
              <Grid item xs={4} className={`${styles.rowTitle} ${styles.rowInputStart}`}>
                <BasicCheckbox
                  label="To be announced"
                  checked={formData[0]?.tba}
                  setCheck={setIsTBA}
                  disabled={!summaryPermissioncanUpdate}
                />
                To be announced
              </Grid>
            </>
          )}
          <Grid item xs={4} className={`${styles.rowTitle} ${styles.rowInputStart}`}>
            Details<span className={styles.red}>*</span>
          </Grid>
          <Grid item xs={4}>
            <InputField
              fullWidth
              placeholder={"Insert text"}
              formData={formData}
              setFormData={setFormData}
              col={"defectDesc"}
              updateArrayColumn={updateArrayColumn}
              error={validateError("defectDesc")}
              multiline
              disabled={!summaryPermissioncanUpdate}
            />
          </Grid>
          <Grid item xs={4} className={`${styles.rowTitle} ${styles.rowInputStart}`}>
            {props.tab && props.tab !== "4" ? "Rectification Status" : "Limitation"}
          </Grid>
          <Grid item xs={4}>
            {props.tab && props.tab !== "4" ? (
              <InputField
                fullWidth
                placeholder={"Insert text"}
                formData={formData}
                setFormData={setFormData}
                col={"rectificationStatus"}
                updateArrayColumn={updateArrayColumn}
                multiline
                disabled={!summaryPermissioncanUpdate}
              />
            ) : (
              getLimitations(formData[0]?.aircraftReg)
            )}
          </Grid>
          <Grid item xs={4} className={`${styles.rowTitle} ${styles.rowInputStart}`}>
            Remarks (MOC Highlight)
          </Grid>
          <Grid item xs={4}>
            <InputField
              fullWidth
              placeholder={"Insert text"}
              formData={formData}
              setFormData={setFormData}
              col={"mocHighlight"}
              updateArrayColumn={updateArrayColumn}
              multiline
              disabled={!summaryPermissioncanUpdate}
            />
          </Grid>
        </Grid>
      </>
    );
  };

  const handleComment = (event) => {
    setCommentData(updateArrayColumnComment("updateLogDesc", event.target.value));
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (event.target.value !== "") {
        // handleSubmitComment();
      }
      // event.currentTarget.value = "";
    }
  };

  function handleSubmitComment() {
    // if (commentRef.current.value === "") {
    //   setInputCommentError(true);
    //   commentRef.current.focus();
    // } else {
    setUploadComment(true);
    setInputCommentError(false);

    SubmitComment();
    // }
  }

  async function SubmitComment() {
    const params = `api/aircraft/updated-status/logs`;
    const fetch = makeAPIRequestMDB(`post`, `${params}/${formData[0]._id}`, commentData[0]);
    fetch
      .then((response) => {
        setSelectedFile("");
        setFileComment("");
        DDMLLogList();
        handleClearInput();
      })
      .catch((error) => {
        console.log("error", error);
        setUploadComment(false);
      });
  }

  // Function to handle the action that should clear the input
  const handleClearInput = () => {
    // Set the value of the input directly using the ref
    commentRef.current.value = "";
    setCommentData(updateArrayColumnComment("updateLogDesc", ""));
  };

  async function AddAircraft() {
    const params = `api/aircraft/updated-status`;
    const fetch = makeAPIRequestMDB(`post`, `${params}`, formData[0]);
    fetch
      .then((response) => {
        if (response.detail.includes("success")) {
          setFormData(InitialData);
          showNotificationElevade("success", `New ${props.formType} Report Added!`, [
            `Your successfully add new ${props.formType?.toLowerCase()}`,
          ]);
          props.setTriggerApi(true);
          setOpen(false);
        } else showNotificationElevade("danger", "An error occured!", ["Please contact your system administartor"]);
      })
      .catch((error) => {
        console.log("error", error);
        showNotificationElevade("danger", "An error occured!", ["Please contact your system administartor"]);
      });
  }

  async function UpdateAircraft() {
    const params = `api/aircraft/updated-status/${formData[0]._id}`;
    const fetch = makeAPIRequestMDB(`put`, `${params}`, formData[0]);
    fetch
      .then((response) => {
        if (response.detail.includes("success")) {
          setFormData(InitialData);
          showNotificationElevade("success", `${props.formType} successfully updated!`, [
            `Your successfully update ${props.formType?.toLowerCase()} report`,
          ]);
          props.setTriggerApi(true);
          setOpen(false);
        } else showNotificationElevade("danger", "An error occured!", ["Please contact your system administartor"]);
      })
      .catch((error) => {
        console.log("error", error);
        showNotificationElevade("danger", "An error occured!", ["Please contact your system administartorhjhgjhg"]);
      });
  }

  function handleSubmit() {
    if (validateForm()) {
      AddAircraft();
    }
  }

  function handleUpdate() {
    if (validateForm()) {
      UpdateAircraft();
    }
  }

  function validateForm() {
    // Set mandatory fields
    let array = [];
    // All forms
    if (formData[0].formType === "" || formData[0].formType === null) array.push("formType");
    if (formData[0].aircraftReg === "" || formData[0].aircraftReg === null) array.push("aircraftReg");
    if (formData[0].defectDesc === "" || formData[0].defectDesc === null) array.push("defectDesc");

    // Check for formType !== "Standby"
    if (formData[0].formType !== "Standby") {
      if (formData[0].location === "" || formData[0].location === null) array.push("location");
      if (formData[0].inputDate === "" || formData[0].inputDate === null) array.push("inputDate");
    }

    // Check for ETS
    if ((formData[0].tba === false || formData[0].tba === null) && formData[0].formType !== "Standby") {
      if (formData[0].ets === "" || formData[0].ets === null) array.push("ets");
      if (formData[0].etsTime === "" || formData[0].etsTime === null) array.push("etsTime");
    }

    setFormError(array);
    if (array.length === 0) return true;
    else return false;
  }

  // A function used to push comment into the summary tab
  function handleCopy(e, id, category, ddmlId) {
    setOpenConfirmCopy(true);
    setCommentPushSummaryId(id);
    setCommentPushSummary(e);
    setCategory(category);
    setDDMLId(ddmlId);
  }

  // A function to set the file links for previewing event
  function handleOpenComment(e) {
    setCommentFileLink(e);
    setOpenPreviewComment(true);
  }

  // API called once the push to summary is confirmed
  async function PushToSummary() {
    const body = {
      logId: commentPushSummaryId,
      collectionId: DDMLId,
      category: category,
      pushToSummary: true,
    };
    const params = `api/aircraft/updated-status/logs/summary`;
    async function UpdateData() {
      const fetch = makeAPIRequestMDB(
        `put`,
        `${params}`,
        body
        // `${params}/${commentPushSummaryId}/true/${DDMLId}/${encodeURIComponent(category.replaceAll("/", "_"))}`
      );
      fetch
        .then((response) => {
          if (response.detail.includes("success")) {
            showNotificationElevade("success", "Update successfully pushed to summary!", [
              "Your successfully pushed new update to summary",
            ]);
            DDMLLogList();
          } else {
            showNotificationElevade("danger", "An error occured!", ["Please contact your system administartor"]);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    UpdateData();
  }

  const handleConfirmCopy = () => {
    PushToSummary();
    setOpenConfirmCopy(false);
    props.setTriggerApi(true);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  const Tips = ({ val }) => {
    return (
      <span
        style={{
          borderRadius: "30px",
          color: "#ffffff",
          backgroundColor: "#E95048",
          fontFamily: "Gotham-Book",
          fontSize: "12px",
          marginLeft: "8px",
          padding: "4px 8px 2px 8px",
        }}
      >
        {val}
      </span>
    );
  };

  const useStyles = makeStyles((theme) => ({
    customTabsStyle: {
      "& .MuiTabScrollButton-root:first-child": {
        marginTop: "-6px",
      },
      "& .MuiTabScrollButton-root:last-child": {
        marginTop: "-6px",
      },
    },
  }));

  const classes = useStyles();

  // permission check to attach file in DASR tab
  function attachFilePermission() {
    if (value === "2" && rectificationPermission.some((perm) => perm.canUpdate === true)) {
      return "inline-block";
    } else if (value === "3" && materialPermission.some((perm) => perm.canUpdate === true)) {
      return "inline-block";
    } else if (value === "4" && ppcPermission.some((perm) => perm.canUpdate === true)) {
      return "inline-block";
    } else {
      return "none";
    }
  }

  // permission check to comment in DASR tab
  const allowCommentPermission = () => {
    let display = false;

    if (value === "2" && rectificationPermission.some((perm) => perm.canUpdate === true)) {
      display = true;
      return display;
    } else if (value === "3" && materialPermission.some((perm) => perm.canUpdate === true)) {
      display = true;
      return display;
    } else if (value === "4" && ppcPermission.some((perm) => perm.canUpdate === true)) {
      display = true;
      return display;
    } else {
      display = false;
      return display;
    }
  };

  const handleEdit = () => {
    props.setEdit(true);
  };

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
      // borderRadius: 8,
      position: "relative",
      backgroundColor: "#ffffff",
      border: "0px solid",
      borderColor: "#E0E3E7",
      fontSize: 14,
      padding: "4px",
      transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
      fontFamily: ["Gotham-Book"].join(","),
      "&:focus": {
        borderColor: theme.palette.primary.main,
      },
      textTransform: "uppercase",
    },
  }));

  return (
    <>
      <div
        style={{
          width: "100%",
          position: "absolute",
          top: "60px",
          backgroundColor: "#ffffff",
          padding: "16px",
          zIndex: 3,
          borderBottom: "0.5px solid #C3C3C3",
          overflowX: "auto",
          overflowY: "hidden",
        }}
        className={`${styles.macScrollbar}`}
      >
        <Grid container columns={4}>
          <Grid item xs={3} sx={{ padding: "4px" }} className={styles.title}>
            <DDMLTitle />
          </Grid>
          <Grid item xs={1} sx={{ display: "flex", justifyContent: "flex-end", padding: "4px" }}>
            {/* <img src={IconExternalLink} alt="icon-external-link" height={20} />
            <div style={{ height: "20px", margin: "0 20px", width: "1px", backgroundColor: "#D1D1D1" }}></div> */}
            <img
              src={IconClose}
              alt="icon-close"
              height={15}
              style={{ marginTop: "2px", cursor: "pointer" }}
              onClick={() => setOpen(false)}
            />
          </Grid>
        </Grid>
      </div>
      <div
        style={{
          marginTop: props.tab && props.tab !== "4" ? "50px" : "0px",
          padding: "18px 0px",
          display: props.newForm ? "none" : "inline-block",
          position: "relative",
        }}
      >
        <Grid container columns={4}>
          <TabContext value={value} sx={{ marginLeft: "-60px" }}>
            {props.tab && props.tab !== "4" && (
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                variant="scrollable"
                scrollButtons={false}
                classes={{ root: classes.customTabsStyle }}
                sx={{
                  paddingTop: "10px",
                  position: "absolute",
                  top: "70px",
                  transform: "translateX(-0%)",
                  zIndex: "10",
                  backgroundColor: "#ffffff",
                  borderBottom: "2px solid #e8e8e8",
                  borderTop: "0.5px solid #C3C3C3",
                  width: "100%",
                }}
              >
                <AntTab label="Summary" value="1" />
                <AntTab
                  label="Rectification Update"
                  value="2"
                  icon={
                    props?.initialData?.unreadLogs?.rectification.length > 0 && (
                      <Tips val={props?.initialData?.unreadLogs?.rectification.length} />
                    )
                  }
                  iconPosition="end"
                />
                <AntTab
                  label="Material/Tools Update"
                  value="3"
                  icon={
                    props?.initialData?.unreadLogs?.materialTool.length > 0 && (
                      <Tips val={props?.initialData?.unreadLogs?.materialTool.length} />
                    )
                  }
                  iconPosition="end"
                />
                <AntTab
                  label="Planning/Tech Service"
                  value="4"
                  icon={
                    props?.initialData?.unreadLogs?.planningTech.length > 0 && (
                      <Tips val={props?.initialData?.unreadLogs?.planningTech.length} />
                    )
                  }
                  iconPosition="end"
                />
              </TabList>
            )}
            <div style={{ width: "100%", height: "2px", backgroundColor: "#E8E8E8", marginTop: "-2px" }}></div>
            <div
              style={{
                marginLeft: "0px",
                marginRight: "-20px",
                width: "100%",
                overflow: "auto",
                padding: "120px 18px 100px 18px",
              }}
            >
              <TabPanel value="1" sx={{ padding: "0px" }}>
                {props.edit ? <Summary /> : <DisplaySummary data={data} DDMLLogs={DDMLLogs} {...props} />}
              </TabPanel>
              <TabPanel value="2" sx={{ padding: "0px" }}>
                <DDMLUpdateLogs
                  tab={"Rectification Update"}
                  DDMLLogs={DDMLLogs}
                  handleCopy={handleCopy}
                  handleOpenComment={handleOpenComment}
                  ConvertDate={props.ConvertDate}
                  openPreviewComment={openPreviewComment}
                  handleCloseComment={handleCloseComment}
                  style={style}
                  commentFileLink={commentFileLink}
                  uploadComment={uploadComment}
                  setUploadComment={setUploadComment}
                  statusTab={props.tab}
                  initialData={data}
                  data={props.dataList}
                  unreadLogs={props.dataForm?.unreadLogs?.rectification}
                />
              </TabPanel>
              <TabPanel value="3" sx={{ padding: "0px" }}>
                <DDMLUpdateLogs
                  tab={"Material/Tools Update"}
                  DDMLLogs={DDMLLogs}
                  handleCopy={handleCopy}
                  handleOpenComment={handleOpenComment}
                  ConvertDate={props.ConvertDate}
                  openPreviewComment={openPreviewComment}
                  handleCloseComment={handleCloseComment}
                  style={style}
                  commentFileLink={commentFileLink}
                  uploadComment={uploadComment}
                  setUploadComment={setUploadComment}
                  statusTab={props.tab}
                  initialData={data}
                  data={props.dataList}
                  unreadLogs={props.dataForm?.unreadLogs?.materialTool}
                />
              </TabPanel>
              <TabPanel value="4" sx={{ padding: "0px" }}>
                <DDMLUpdateLogs
                  tab={"PPC"}
                  DDMLLogs={DDMLLogs}
                  handleCopy={handleCopy}
                  handleOpenComment={handleOpenComment}
                  ConvertDate={props.ConvertDate}
                  openPreviewComment={openPreviewComment}
                  handleCloseComment={handleCloseComment}
                  style={style}
                  commentFileLink={commentFileLink}
                  uploadComment={uploadComment}
                  setUploadComment={setUploadComment}
                  statusTab={props.tab}
                  initialData={data}
                  data={props.dataList}
                  unreadLogs={props.dataForm?.unreadLogs?.planningTech}
                />
              </TabPanel>
            </div>
          </TabContext>
        </Grid>
        {/* <Preview open={openPreview} setOpen={setOpenPreview} fileLink={commentFileLink} /> */}
      </div>
      {props.newForm && (
        <div style={{ marginTop: "0px", padding: "18px" }}>
          <Summary />
        </div>
      )}
      <div
        style={{
          width: "100%",
          position: "fixed",
          bottom: "0px",
          borderTop: "1px solid #D1D1D1",
          backgroundColor: "#ffffff",
          borderRadius: "8px 8px 0px 0px",
          // padding: "16px",
          zIndex: 2,
          display: value !== "1" ? attachFilePermission() : "none",
        }}
      >
        <Grid container columns={6}>
          <Grid item xs={5.5} sx={{ padding: "16px" }}>
            <div style={{ display: "flex" }}>
              <label style={{ cursor: "pointer" }}>
                <input type="file" onChange={handleFileChange} style={{ display: "none" }} />
                <img src={IconAttachment} alt="attachment" />
              </label>
              {selectedFile.length > 0 &&
                previewUrl.map((e) => {
                  return (
                    <>
                      <div
                        style={{
                          maxWidth: "40px",
                          maxHeight: "35px",
                          overflow: "hidden",
                          borderRadius: "4px",
                          marginRight: "20px",
                        }}
                      >
                        <img src={e} alt={`Preview`} style={{ width: "40px", height: "35px" }} />
                      </div>
                    </>
                  );
                })}
            </div>
          </Grid>
          <Grid item xs={0.5}>
            {selectedFile.length > 0 && (
              <img
                src={IconClose}
                alt="icon-close"
                height={15}
                style={{ marginTop: "2px", cursor: "pointer" }}
                onClick={() => setSelectedFile("")}
              />
            )}
          </Grid>
          {value !== "1" && allowCommentPermission() && (
            <Grid item xs={6} sx={{ padding: "8px 16px", borderTop: "1px solid  #D1D1D1" }}>
              <BootstrapInput
                // ref={inputComment}
                className={`${styles.inputComment} ${inputCommentError ? styles.inputCommentError : ""}`}
                placeholder="Comment here"
                onBlur={(e) => handleComment(e)}
                onKeyDown={handleKeyDown}
                defaultValue={commentData[0].updateLogDesc}
                ref={commentRef}
                multiline={true}
                // value={comment.toUpperCase()}
              />
              <img
                src={IconSendButton}
                alt="post-comment"
                style={{ cursor: "pointer", position: "fixed", bottom: "20px", right: "20px" }}
                onClick={() => handleSubmitComment()}
              />
            </Grid>
          )}
        </Grid>
      </div>
      {value === "1" && (
        <div
          style={{
            position: "fixed",
            bottom: "0",
            width: "50%",
            borderTop: "1px solid #D1D1D1",
            backgroundColor: "#ffffff",
            padding: "20px 24px 16px 24px",
            display: "flex",
            justifyContent: "flex-end",
            zIndex: 2,
          }}
        >
          <AdeButton
            variant="outlined"
            sx={{
              borderRadius: "8px",
              border: "0px solid #e8e8e8",
              fontFamily: "Gotham-Medium",
              pl: 12,
              pr: 12,
              mr: 4,
              "&.MuiButton-root:hover": { border: "transparent" },
            }}
            onClick={() => {
              props.edit ? props.setEdit(false) : setOpen(false);
            }}
          >
            {props.edit ? "Back" : "Cancel"}
          </AdeButton>
          {props.edit ? (
            <AdeButton
              disabled={!summaryPermissioncanUpdate}
              variant="contained"
              sx={{
                borderRadius: "8px",
                fontFamily: "Gotham-Medium",
                pl: 12,
                pr: 12,
                "&.MuiButton-root": { border: "transparent" },
              }}
              onClick={!props.newForm ? handleUpdate : handleSubmit}
            >
              {!props.newForm ? "Save Changes" : "Add Aircraft"}
            </AdeButton>
          ) : (
            <AdeButton
              disabled={!summaryPermissioncanUpdate}
              variant="contained"
              sx={{
                borderRadius: "8px",
                fontFamily: "Gotham-Medium",
                pl: 12,
                pr: 12,
                "&.MuiButton-root": { border: "transparent" },
              }}
              onClick={handleEdit}
            >
              Edit
            </AdeButton>
          )}
        </div>
      )}
      <Modal
        open={openConfirmCopy}
        onClose={handleCloseConfirmCopy}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            border: "0px solid #000",
            boxShadow: 24,
            padding: "16px 0px",
            borderRadius: "8px",
          }}
        >
          <Grid container columns={1}>
            <Grid
              item
              xs={0.8}
              sx={{
                padding: "0px 24px 16px 24px",
                borderBottom: "1px solid #D1D1D1",
              }}
            >
              <span style={{ fontFamily: "Gotham-Medium" }}>Copy and replace to Summary</span>
            </Grid>
            <Grid
              item
              xs={0.2}
              sx={{
                padding: "0px 24px 16px 24px",
                borderBottom: "1px solid #D1D1D1",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <img
                src={IconClose}
                alt="icon-close"
                height={12}
                style={{ cursor: "pointer" }}
                onClick={() => setOpenConfirmCopy(false)}
              />
            </Grid>
            <Grid item xs={1} sx={{ padding: "32px 64px" }}>
              <div
                style={{
                  fontFamily: "Gotham-Book",
                  marginBottom: "24px",
                  color: "#838383",
                  fontSize: "14px",
                }}
              >
                This will replace the existing rectification status in Summary Tab with this information:
              </div>
              <div style={{ fontFamily: "Gotham-medium", marginBottom: "24px" }}>“{commentPushSummary}”</div>
              <div style={{ fontFamily: "Gotham-Book", marginBottom: "0px", color: "#838383", fontSize: "14px" }}>
                Do you want to proceed?
              </div>
            </Grid>
            <Grid
              item
              xs={1}
              sx={{
                display: "flex",
                justifyContent: "center",
                paddingBottom: "16px",
              }}
            >
              <Button
                title="Cancel"
                variant=""
                onClick={() => {
                  setOpenConfirmCopy(false);
                }}
              />
              <Button title="Proceed" variant="fill" onClick={handleConfirmCopy} />
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default DASRForm;
