import { Grid } from "@mui/material";
import styles from "./DDML.module.css";
import { makeAPIRequestRun } from "common/helpers";
import React, { useState, useEffect } from "react";
import { addingDate, calculateBalanceDay, formatDateDasr } from "pages/elevade/component/Helpers";

const DefermentExpirySummaryStructure = ({ dataForm, calculateCalendarExpiryDate, calculateCalendarBalance }) => {
  const [flightHour, setFlightHour] = useState("");
  const [flightCycle, setFlightCycle] = useState("");
  const [balanceCalendar, setBalanceCalendar] = useState(0);

  useEffect(() => {
    if (dataForm.aircraftReg !== undefined && dataForm.aircraftReg !== "") getFlightDate();
    calcBalanceCD();
    // eslint-disable-next-line
  }, [dataForm]);

  async function getFlightDate() {
    const fetchData = makeAPIRequestRun(`get`, `acarsdata/fleetgetfcfh/${dataForm.aircraftReg}`);

    fetchData
      .then((response) => {
        setFlightHour(response[0]?.flight_hour);
        setFlightCycle(response[0]?.flight_cycle);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  function setToday() {
    // Create a new Date object
    const currentDate = new Date();

    // Get the individual components of the date
    const year = currentDate.getFullYear();
    // JavaScript months are 0-indexed, so we add 1 to get the correct month
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const day = currentDate.getDate().toString().padStart(2, "0");

    // Create a string in the desired format
    const formattedDate = `${year}-${month}-${day}T00:00:00`;
    return formattedDate;
  }

  function calcExpiryFH() {
    let interval = dataForm.flightHoursInterval;
    let raisedAt = dataForm.flightHoursRaisedAt;
    let expiry = parseInt(interval) + parseInt(raisedAt);
    return parseInt(expiry).toLocaleString() || "-";
  }

  function calcBalanceFH() {
    let interval = dataForm.flightHoursInterval;
    let raisedAt = dataForm.flightHoursRaisedAt;
    let expiry = parseInt(interval) + parseInt(raisedAt);
    let current = flightHour;
    let balance = parseInt(expiry) - parseInt(current);
    return parseInt(balance) || "-";
  }

  function calcExpiryFC() {
    let interval = dataForm.flightCycleInterval;
    let raisedAt = dataForm.flightCycleRaisedAt;
    let expiry = parseInt(interval) + parseInt(raisedAt);
    return parseInt(expiry).toLocaleString() || "-";
  }

  function calcBalanceFC() {
    let interval = dataForm.flightCycleInterval;
    let raisedAt = dataForm.flightCycleRaisedAt;
    let expiry = parseInt(interval) + parseInt(raisedAt);
    let current = flightCycle;
    let balance = parseInt(expiry) - parseInt(current);
    return parseInt(balance) || "-";
  }

  function calcBalanceCD() {
    let startDate = dataForm.calendarDaysStartDate;
    let interval = dataForm.calendarDaysInterval || 0;
    let expiry = addingDate(startDate, interval);
    let today = setToday();
    let balance = calculateBalanceDay(today?.split("T")[0], expiry?.split("T")[0]);
    setBalanceCalendar(balance);
  }

  return (
    <>
      <Grid container columns={3}>
        <Grid item xs={3} className={`${styles.title}`}>
          Flight Hours (FH)
        </Grid>
        <Grid item xs={1}>
          Interval
        </Grid>
        <Grid item xs={1}>
          Expiry
        </Grid>
        <Grid item xs={1}>
          Balance
        </Grid>
        <Grid item xs={1}>
          {dataForm.flightHours ? dataForm.flightHoursInterval?.toLocaleString() : "-"}
        </Grid>
        <Grid item xs={1}>
          {/* {calculateCalendarExpiryDate(dataForm.calendarDaysStartDate, dataForm.calendarDaysInterval)} */}
          {dataForm.flightHours ? calcExpiryFH() : "-"}
        </Grid>
        <Grid item xs={1}>
          {/* {calculateCalendarBalance(dataForm.calendarDaysStartDate, dataForm.calendarDaysInterval)} */}
          {dataForm.flightHours ? calcBalanceFH() : "-"}
        </Grid>
        <Grid item xs={3} className={`${styles.title}`} sx={{ paddingTop: "10px" }}>
          Flight Cycle (FC)
        </Grid>
        <Grid item xs={1}>
          Interval
        </Grid>
        <Grid item xs={1}>
          Expiry
        </Grid>
        <Grid item xs={1}>
          Balance
        </Grid>
        <Grid item xs={1}>
          {dataForm.flightCycle ? dataForm.flightCycleInterval?.toLocaleString() : "-"}
        </Grid>
        <Grid item xs={1}>
          {/* {calculateCalendarExpiryDate(dataForm.calendarDaysStartDate, dataForm.calendarDaysInterval)} */}
          {dataForm.flightCycle ? calcExpiryFC() : "-"}
        </Grid>
        <Grid item xs={1}>
          {/* {calculateCalendarBalance(dataForm.calendarDaysStartDate, dataForm.calendarDaysInterval)} */}
          {dataForm.flightCycle ? calcBalanceFC() : "-"}
        </Grid>
        <Grid item xs={3} className={`${styles.title}`} sx={{ paddingTop: "10px" }}>
          Calendar Days (Days)
        </Grid>
        <Grid item xs={1}>
          Interval
        </Grid>
        <Grid item xs={1}>
          Expiry
        </Grid>
        <Grid item xs={1}>
          Balance
        </Grid>
        <Grid item xs={1}>
          {dataForm.calendarDays ? dataForm.calendarDaysInterval?.toLocaleString() : "-"}
        </Grid>
        <Grid item xs={1}>
          {dataForm.calendarDays
            ? formatDateDasr(addingDate(dataForm.calendarDaysStartDate?.split("T")[0], dataForm.calendarDaysInterval))
            : "-"}
        </Grid>
        <Grid item xs={1}>
          {dataForm.calendarDays ? balanceCalendar : "-"}
        </Grid>
      </Grid>
    </>
  );
};

export default DefermentExpirySummaryStructure;
