
import React from 'react';

import { GroupedNumberedBoxes } from "./GroupedNumberedBoxes";

export default function RowOfBoxNumbers({
	x = 0,
	y = 10,
	text = "PBE",
	Icon = <DefaultIcon />,
	numbers,
	onClick,
	setClosePopup,
	setPopup,
	boxWidth,
	biggestArrayLength,
}) {

	// const boxHeight = Math.ceil(numbers?.length / biggestArrayLength)

	return (
		<g>
			<g
				transform={`translate(${x},${y})`}
			>
				<Icon />
				<TextViewer text={text} />
				<GroupedNumberedBoxes
					biggestArrayLength={biggestArrayLength}
					x={70}
					y={-2}
					onClick={(num, data) => {
						if (onClick) {
							onClick(data);
						}
					}}
					numbers={numbers}
					setClosePopup={setClosePopup}
					setPopup={(boxX, inFleetLocation, yOffset) => {
						if (setPopup) {
							setPopup(boxX, inFleetLocation, yOffset);
						}
					}}
				/>
			</g>
			<TopRowLine
				boxWidth={boxWidth}
				y={y}
			/>
			{/* <LeftLine y={y} boxHeight={boxHeight} /> */}
			{/* <RightLine y={y} boxHeight={boxHeight} boxWidth={boxWidth} /> */}
		</g>
	);
}

function TopRowLine({
	boxWidth,
	y,
}) {

	return (
		<path
			// line at the top of each row
			id="lineAB"
			d={`M -7 ${(y) - 7} l ${boxWidth} 0`}
			stroke="#E8E8E8"
		/>
	)
}

// function LeftLine({
// 	boxHeight,
// 	y,
// }) {

// 	return (
// 		<path
// 			// line at the top of each row
// 			id="lineCD"
// 			// -7:x 7:y 5:height
// 			d={`M -7 ${(y) - 7} l 0 ${boxHeight * 30}`}
// 			stroke="#E8E8E8"
// 		/>
// 	)
// }

// function RightLine({
// 	boxWidth,
// 	boxHeight,
// 	y,
// }) {

// 	return (
// 		<path
// 			// line at the top of each row
// 			id="lineCD"
// 			// -7:x 7:y 5:height
// 			d={`M ${boxWidth + (-7)} ${(y) - 7} l 0 ${boxHeight * 30}`}
// 			stroke="#E8E8E8"
// 		/>
// 	)
// }

function TextViewer({ text }) {
	return (
		<text x="25" y="12" fill="black" fontSize={"13px"}>
			{text}
		</text>
	)
}


function DefaultIcon() {
	return (
		<svg
			width="14"
			height="14"
			viewBox="0 0 14 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M13 11.9437C13 11.6636 12.8887 11.3949 12.6906 11.1969L12.092 10.5982C11.7169 10.2232 11.5062 9.71445 11.5062 9.18402V3.98755C11.5062 2.33758 10.1686 1 8.51867 1H5.48133C3.83135 1 2.49378 2.33758 2.49378 3.98755V9.18402C2.49378 9.71445 2.28306 10.2232 1.90799 10.5982L1.30937 11.1969C1.11128 11.3949 1 11.6636 1 11.9437V11.9437C1 12.5271 1.4729 13 2.05626 13H11.9437C12.5271 13 13 12.5271 13 11.9437V11.9437Z"
				stroke="#333333"
				stroke-miterlimit="10"
			/>
			<path
				d="M5.5124 9C4.67713 9 4 8.51875 4 7.92511V7C4 5.89543 4.89543 5 6 5H8C9.10457 5 10 5.89543 10 7V7.92511C10 8.51875 9.32287 9 8.4876 9"
				stroke="#333333"
				stroke-miterlimit="10"
			/>
			<path
				d="M6.99964 11.1321C7.82463 11.1321 8.49341 10.4633 8.49341 9.63831C8.49341 8.81332 7.82463 8.14453 6.99964 8.14453C6.17465 8.14453 5.50586 8.81332 5.50586 9.63831C5.50586 10.4633 6.17465 11.1321 6.99964 11.1321Z"
				stroke="#333333"
				stroke-miterlimit="10"
			/>
			<path
				d="M7 11.1328V13"
				stroke="#333333"
				stroke-miterlimit="10"
			/>
		</svg>
	)
}