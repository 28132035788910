import { env_var_REACT_APP_API_URL_ADV } from "../../envVar/vars";
import { jsArraySortFunctionComparator } from "../../utils/arrayUtils";
import useGetGQL from "../useGetGQL";

/**
 * @description Status : migrated! - new endpoint
 */
export function useGetAllPassangerSeats(props) {

	const { model, regNo, search, } = props ?? {}

	const data = useGetGQL({
		gqlquery: `
		query allPassangerSeats {
			allPassengerSeats
			${model || regNo || search ? `(` : ''}
			
			${search ? `search:"${search}"` : ''}
			${model ? `model:"${model}"` : ''}
			${regNo ? `regNo:"${regNo}"` : ''}

			${model || regNo || search ? `)` : ''}

			{
			  items {
				_id
				seatNo
				fleetApplicable {
				  _id
				  manufacturer
				  model
				  engineType
				}
				occupied
			  }
			}
		  }
		`,
		querySign: "allPassengerSeats",
		url: env_var_REACT_APP_API_URL_ADV ?? "",
		defaultIsLoading: true,
		isEdges: false,
	});

	function converter(data) {
		return {
			...data,
			data: data?.data?.items?.map((node) => {
				return {
					...node,
					id: node?._id
				}
			})?.sort((a, b) => {
				return jsArraySortFunctionComparator(a, b, 'seatNo')
			})
		}
	}

	return converter(data);
}