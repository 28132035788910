
import React, { useState } from 'react';

import SeatA1Popup from './Popup';
import SeatA1 from './SeatA1';
import AirplaneBoxWithPointer from './AirplaneBoxWithPointer';
import '../configs/main.css';

// DEFAULT VALUES
import FilterDefs from '../../A320Ceo/A320CeoSkeleton/FilterDefs';
import A320CeoSkeleton from '../../A320Ceo/A320CeoSkeleton';
import { a320CeoBoxConfigs } from '../../A320Ceo/a320Ceo-box-settings';
import seats from '../../A320Ceo/seats-180/seats';
import { generalCategorizer, inventoryLocationCategoriesA320Ceo } from '../utils/item-data-categorizer';
import { getColorSeatFillByInvStatus } from '../utils/getColorSeatFillByInvStatus';


/**
 * @typedef {Object} ACTemplateTranslateCoord
 * @property {number} translateX
 * @property {number} translateY
 */

/**
 * @typedef {Object} ACTemplateProps
 * @property {any} itemsData
 * @property {Function} [onClickItem]
 * @property {any} skeleton
 * @property {any} boxesConfig
 * @property {any} seatsConfig
 * @property {any} filters
 * @property {any} categories
 * @property {ACTemplateTranslateCoord} [translateCoord]
 * @property {boolean} isGeneralized
 */
/**
 * ACTemplate properties
 * @param {ACTemplateProps} props 
 * @returns {React.ReactNode}
 */
export default function ACTemplate(props) {

	const {
		itemsData,
		onClickItem,
		skeleton = <A320CeoSkeleton />,
		boxesConfig = a320CeoBoxConfigs,
		seatsConfig = seats,
		filters = (<g><FilterDefs /><filter id='boxshadow' color-interpolation-filters="sRGB"><feDropShadow dx="-1.5" dy="1.5" stdDeviation="4" flood-opacity="0.1" /></filter></g>),
		categories = inventoryLocationCategoriesA320Ceo,
		translateCoord,
		isGeneralized,
	} = props ?? {}

	const {
		translateX,
		translateY
	} = translateCoord ?? {}

	const [popUp, setPopUp] = useState(undefined);
	// const [zoom, setZoom] = useState(0);

	const [center, setCenter] = useState({ x: 0, y: 0, });

	// function onScroll(e) {
	// 	const delta = e.deltaY; //* -0.01;
	// 	if (delta < 1) {setZoom(zoom + 100);}
	// 	else if (delta > 1) {setZoom(zoom - 100);}
	// }

	const categorized = generalCategorizer(categories, itemsData, (item) => item?.locationType?.code, isGeneralized);
	const passengerSeats = categorized['PS'];

	return (
		<div
			style={{
				// overflow: "hidden",
				width: "100%",
				height: "100%",
				overflow: 'visible',
			}}
		>
			<svg
				height="100%"
				width="100%"
				// onWheelCapture={onScroll}
				// onMouseMove={(e: any) => {
				//     var rect = e.target.getBoundingClientRect();
				//     var x = e.clientX - rect.left; //x position within the element.
				//     var y = e.clientY - rect.top;  //y position within the element.
				//     console.log("Left? : " + x + " ; Top? : " + y + ".");
				//     setCenter({x, y,})
				// }}
				// width={1100 + zoom} height={500 + zoom / 2}
				// transform={`translate (100 200)`}
				viewBox={`${0 - center.x} ${0 - center.y} 1363 631`}
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g
					transform={translateX !== undefined && translateY !== undefined ? `translate (${translateX} ${translateY})` : undefined}
				// transform={`translate (100 200)`}
				>
					{skeleton}
					{seatsConfig.map((seat) => { // {code: "PS", name: "Passenger Seat" }
						const { x, y, row, col, rotation } = seat;
						const foundSeat = passengerSeats?.find(({ seatNo }) => {
							return `${col}${row}`?.toLowerCase() === seatNo?.toLowerCase();
						}) ?? undefined;
						let fill = "white";
						fill = getColorSeatFillByInvStatus(foundSeat?.inventory?.status?.code ?? "NA");
						return (
							<SeatA1
								rotationDegree={rotation}
								fill={fill}
								noLocation={foundSeat === undefined}
								x={x}
								y={y}
								setPopup={() => {
									let xOffset = 0;
									if (col === 30 || col === 31) {
										xOffset = -280;
									}
									setPopUp({
										status: foundSeat?.inventory?.status,
										data: foundSeat,
										x: x + xOffset,
										y,
										row: row,
										col: col,
										color: fill,
										isItemAssingedHere: foundSeat?.inventory !== undefined && foundSeat?.inventory !== null
									});
								}}
								setClosePopup={() => {
									setPopUp(undefined);
								}}
								onClick={() => {
									if (onClickItem) {
										if (foundSeat) {
											onClickItem(foundSeat);
										}
									}
								}}
							/>
						);
					})}
				</g>

				{
					boxesConfig.map(({ title, code, boxSettings }) => {
						return (
							<AirplaneBoxWithPointer
								title={title}
								// invsDatas={itemsData}
								code={code}
								boxSettings={boxSettings}
								categorizedData={categorized}
								onClick={(inFleetLocation) => {
									if (inFleetLocation && onClickItem) {
										onClickItem(inFleetLocation);
									}
								}}
								setPopup={(innerprops) => {
									setPopUp({
										...innerprops,
										isItemAssingedHere: true,
									});
								}}
								setClosePopup={() => {
									setPopUp(undefined);
								}}
							/>
						)
					})
				}
				<g
					transform={translateX !== undefined && translateY !== undefined ? `translate (${translateX} ${translateY})` : undefined}
				// transform={`translate (100 200)`}
				>
					<SeatA1Popup
						isOpen={popUp !== undefined}
						x={popUp?.x ?? 0}
						y={popUp?.y ?? 0}
						row={popUp?.row}
						col={popUp?.col ?? 0}
						data={popUp?.data}
						status={popUp?.status}
						fill={popUp?.color ?? "white"}
						isItemAssingedHere={popUp?.isItemAssingedHere}
					/>
				</g>

				{filters}
			</svg>
		</div>
	);
}

