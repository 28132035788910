import React, { useState, useEffect } from "react";
import { Grid, Typography, styled, Tooltip, tooltipClasses } from "@mui/material";
import styles from "../Dasr.module.css";
import {
  addingDate,
  calculateBalanceDay,
  calculateCalendarBalance,
  calculateCalendarExpiryDate,
  formatDateDasr,
} from "pages/elevade/component/Helpers";
import { makeStyles } from "@mui/styles";
import MUIPagination from "pages/elevade/component/Pagination";
import ElevadeDrawer from "../component/Drawer";
import AirplaneLoader from "pages/elevade/component/AirplaneLoader";
import SearchBar from "./SearchBar";
import ActiveFilters from "./ActiveFilters";
import DDML from "../aircraftReport/DDML";
import NoData from "pages/elevade/component/DataUnavailable";
import DialogMoveAssignment from "../aircraftReport/DialogMoveAssigment";
import { makeAPIRequestRun } from "common/helpers";

const Limitation = ({ aoc, ...props }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState();
  const [dataDDML, setDataDDML] = useState(props.data);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [data, setData] = useState([]);
  const [openTitle, setOpenTitle] = useState(false);
  const InitialFilters = { search: [], location: [], aircraftReg: [], assignedTo: [], raisedDate: [], expiryDate: [] };
  const [isFilters, setIsFilters] = useState(InitialFilters);
  const [filteredData, setFilteredData] = useState(props.data);

  // TODO: For ddml form drawer
  const [type] = useState("1");
  const [index, setIndex] = useState();
  const [dataForm, setDataForm] = useState([]);
  const [edit, setEdit] = useState(true);
  const [openNotification, setOpenNotification] = useState(false);
  const [newForm, setNewForm] = useState(false);
  const [activeIndex, setActiveIndex] = useState("");
  const [selectToMove, setSelectToMove] = useState([]);
  const [openDialogMoveToAssignment, setOpenDialogMoveToAssignment] = useState(false);
  const [dataFHFC, setDataFHFC] = useState([]);

  let sliceStart = page > 0 ? perPage * (page - 1) : 0;
  let sliceEnd = parseInt(perPage) + sliceStart;

  const today = new Date();
  const formattedDate = today.toISOString();

  // Custom styles
  const useStyles = makeStyles(() => ({
    ul: {
      "& .Mui-selected": {
        color: "#fff",
        backgroundColor: "#088FD1",
        border: "1px solid #088FD1",
        "&:hover": {
          backgroundColor: "#088FD1",
        },
      },
    },
    gridItem: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-box-orient": "vertical",
      "-webkit-line-clamp": 2, // Number of lines to show
      fontSize: "14px",
    },
  }));

  const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#FFFEE8",
        color: "rgba(0, 0, 0, 0.87)",
        boxShadow: theme.shadows[1],
        fontSize: 11,
      },
    })
  );

  const classes = useStyles();

  useEffect(() => {
    setDataDDML(props.data);
    setOpen(false);
  }, [props.data]);

  useEffect(() => {
    setTotalRows(filteredData?.length);
    setTotalPage(Math.floor(filteredData?.length / perPage) + 1);
  }, [filteredData, perPage]);

  // Applying filters
  useEffect(() => {
    let theData = dataDDML;
    let data_ = [];
    let filteredData = theData;
    setFilteredData(theData);
    // Search input fields
    if (isFilters.search.length !== 0) {
      data_ = filteredData.filter(
        (item) =>
          isFilters.search.some((searchTerm) => item.location?.toLowerCase()?.includes(searchTerm?.toLowerCase())) ||
          isFilters.search.some((searchTerm) => item.aircraftReg?.toLowerCase()?.includes(searchTerm?.toLowerCase())) ||
          isFilters.search.some((searchTerm) => item.defectDesc?.toLowerCase()?.includes(searchTerm?.toLowerCase())) ||
          isFilters.search.some((searchTerm) => item.ddmlNo?.toLowerCase()?.includes(searchTerm?.toLowerCase())) ||
          isFilters.search.some((searchTerm) =>
            item.limitationsDesc?.toLowerCase()?.includes(searchTerm?.toLowerCase())
          ) ||
          isFilters.search.some((searchTerm) => item.assignedTo?.toLowerCase()?.includes(searchTerm?.toLowerCase()))
      );
      filteredData = data_;
    }
    // Station (location)
    if (isFilters.location.length > 0)
      filteredData = filteredData.filter((e) => isFilters.location.includes(e.location));
    // Aircraft Registration
    if (isFilters.aircraftReg.length > 0)
      filteredData = filteredData.filter((e) => isFilters.aircraftReg.includes(e.aircraftReg));
    // Assigned To (Department)
    if (isFilters.assignedTo.length > 0)
      filteredData = filteredData.filter((e) => isFilters.assignedTo.includes(e.assignedTo));
    // Raised Date
    if (isFilters.raisedDate.length > 0)
      filteredData = filteredData.filter((e) => e.raisedDate.startsWith(isFilters.raisedDate));
    // Expiry Date
    if (isFilters.expiryDate.length > 0)
      filteredData = filteredData.filter((e) => e.expiryDate.startsWith(isFilters.expiryDate));

    setFilteredData(filteredData);
  }, [isFilters, dataDDML]);

  useEffect(() => {
    if (!open) setActiveIndex();
  }, [open]);

  useEffect(() => {
    setPage(1);
  }, [isFilters]);

  async function fetchFHFC() {
    // setSubLoading(true);
    const fetch = makeAPIRequestRun(`get`, `acarsdata/fleetgetfcfh/null`);
    fetch
      .then((response) => {
        setDataFHFC(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  useEffect(() => {
    fetchFHFC();
  }, []);

  function handleClick(e, index) {
    setOpen(true);
    setData(e);
    setIndex(index);
    setDataForm(e);
    setEdit(false);
    setActiveIndex(index);
    setNewForm(false);
  }

  function setToday() {
    // Create a new Date object
    const currentDate = new Date();

    // Get the individual components of the date
    const year = currentDate.getFullYear();
    // JavaScript months are 0-indexed, so we add 1 to get the correct month
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const day = currentDate.getDate().toString().padStart(2, "0");

    // Create a string in the desired format
    const formattedDate = `${year}-${month}-${day}T00:00:00`;
    return formattedDate;
  }

  function calcBalanceFH(interval, raisedAt, val) {
    let expiry = parseInt(interval) + parseInt(raisedAt);
    let current = val;
    let balance = parseInt(expiry) - parseInt(current);
    return parseInt(balance) || "-";
  }

  function calcBalanceFC(interval, raisedAt, val) {
    let expiry = parseInt(interval) + parseInt(raisedAt);
    let current = val;
    let balance = parseInt(expiry) - parseInt(current);
    return parseInt(balance) || "-";
  }

  function calcBalanceCD(calendarDaysStartDate, calendarDaysInterval) {
    let startDate = calendarDaysStartDate;
    let interval = calendarDaysInterval || 0;
    let expiry = addingDate(startDate, interval);
    let today = setToday();
    let balance = calculateBalanceDay(today?.split("T")[0], expiry?.split("T")[0]);
    return balance;
  }

  const IconUnreadIndicator = ({ data }) => {
    let unreadLogs = data.unreadLogs;
    let count = unreadLogs?.materialTool.length + unreadLogs?.planningTech.length + unreadLogs?.rectification.length;

    return (
      count > 0 && (
        <div style={{ position: "absolute", right: "24px" }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
            <circle cx="5" cy="5" r="5" fill="#0ADDDD" />
          </svg>
        </div>
      )
    );
  };

  function getValue(value, fallback) {
    return value || fallback;
  }

  function getCurrentFlightData(reg, data) {
    const record = data.find((item) => item.AC_REG === reg);
    return record ? { flightHour: record.flight_hour, flightCycle: record.flight_cycle } : {};
  }

  function calculateBalance(e, calendarDaysStartDate, calendarDaysInterval, currentFlightHour, currentFlightCycle) {
    if (e.calendarDays) return calcBalanceCD(calendarDaysStartDate, calendarDaysInterval);

    if (e.formType === "STR/FC/FH") {
      switch (e.showInTable) {
        case "fh":
          return e.flightHours ? calcBalanceFH(e.flightHoursInterval, e.flightHoursRaisedAt, currentFlightHour) : null;
        case "fc":
          return e.flightCycle ? calcBalanceFC(e.flightCycleInterval, e.flightCycleRaisedAt, currentFlightCycle) : null;
        default:
          return calcBalanceCD(calendarDaysStartDate, calendarDaysInterval);
      }
    }

    if (e.formType === "AMD" || e.formType === "Fuel Sampling" || e.formType === "Hydraulic Sampling") {
      return calculateCalendarBalance(e.calendarDaysStartDate, e.calendarDaysInterval);
    }

    return !e.flightCycle && !e.flightHours && !e.calendarDays && e.formType === "STR/FC/FH" ? "-" : null;
  }

  function getExpiryDate(e) {
    if (e.formType === "MEL CAT" && e.flightDays) {
      return calculateCalendarExpiryDate(e.flightDaysStartDate, e.flightDaysInterval);
    }

    if (e.calendarDays) {
      return calculateCalendarExpiryDate(e.calendarDaysStartDate, e.calendarDaysInterval);
    }

    return formatDateDasr(e.expiryDate);
  }

  function getFCFHIndicator(showInTable, e) {
    switch (showInTable) {
      case "fh":
        return e.flightHours ? " (FH)" : " Days";
      case "fc":
        return e.flightCycle ? " (FC)" : " Days";
      default:
        return " Days";
    }
  }

  function calculateRemainingDays(expiryDate) {
    const currentDate = new Date();
    const timeDifferenceMs = new Date(expiryDate) - currentDate;
    return Math.ceil(timeDifferenceMs / (1000 * 60 * 60 * 24));
  }

  return !isLoading ? (
    <>
      <div style={{ padding: "40px 0px 0px 24px" }}>
        {/* {filteredData.length > 0 && ( */}
        <SearchBar
          open={props.openModal}
          setOpen={props.setOpenModal}
          data={dataDDML}
          isFilters={isFilters}
          setIsFilters={setIsFilters}
          openTitle={openTitle}
          setOpenTitle={setOpenTitle}
          tab={props.tab}
        />
        {/* )} */}
        <ActiveFilters
          isFilters={isFilters}
          setIsFilters={setIsFilters}
          initialFilters={InitialFilters}
          setOpen={props.setOpenModal}
        />
      </div>
      {filteredData?.length > 0 ? (
        <>
          <div className={styles.tableDiv} style={{ marginTop: "20px" }} onClick={() => props.setOpenModal(false)}>
            <Grid container columns={10}>
              <Grid item xs={0.5} className={styles.tableHeader}>
                Station
              </Grid>
              <Grid item xs={0.7} className={styles.tableHeader}>
                A/C Reg.
              </Grid>
              <Grid item xs={3} className={styles.tableHeader} sx={{ justifyContent: "flex-start" }}>
                Defect Description
              </Grid>
              <Grid item xs={1} className={styles.tableHeader}>
                DDML No.
              </Grid>
              <Grid item xs={1} className={styles.tableHeader}>
                Raised Date
              </Grid>
              <Grid item xs={1} className={styles.tableHeader}>
                Expiry/Balance
              </Grid>
              <Grid item xs={2.8} className={styles.tableHeader} sx={{ justifyContent: "flex-start" }}>
                Limitation Description
              </Grid>
              {filteredData &&
                filteredData.slice(sliceStart, sliceEnd).map((e, index) => {
                  let calendarDaysInterval = getValue(e.calendarDaysInterval, 0);
                  let calendarDaysStartDate = getValue(e.calendarDaysStartDate, e.raisedDate);
                  let expiry = addingDate(calendarDaysStartDate, calendarDaysInterval);
                  let date1 = e.formType === "AMD" ? e.calendarDaysStartDate : formattedDate?.split("T")[0];
                  let date2 = expiry;

                  let currentFlightData = getCurrentFlightData(e.aircraftReg, dataFHFC);
                  let balance = calculateBalance(
                    e,
                    calendarDaysStartDate,
                    calendarDaysInterval,
                    currentFlightData.flightHour,
                    currentFlightData.flightCycle
                  );
                  let fcfhIndicator = getFCFHIndicator(e.showInTable, e);

                  if (balance === null) {
                    balance = calculateBalanceDay(date1, date2);
                  }

                  let expiryDate_ = getExpiryDate(e);
                  let remainingDays = calculateRemainingDays(e.expiryDate);

                  // Setting up the balance string
                  let balanceSTR = !isNaN(parseInt(balance)) && balance !== "-" ? balance + fcfhIndicator : "-";
                  if (e.flightHours && e.showInTable === "fh") balanceSTR = balance + " (FH)";
                  else if (e.flightCycle && e.showInTable === "fc") balanceSTR = balance + " (FC)";
                  else if (e.calendarDays && e.showInTable === "cd") balanceSTR = balance + " Days";
                  else balanceSTR = "-";

                  // Setting up the color indicator
                  let color = "";
                  if (type === "STR/FC/FH") {
                    if ((e.flightHours || e.flightCycle) && parseInt(balance) <= 20) color = "#E95048";
                    else if (e.calendarDays && balance <= 3) color = "#E95048";
                  } else if (type === "MEL CAT" && remainingDays <= 3) {
                    color = "#E95048";
                  } else if (type !== "MEL CAT" && type !== "STR/FC/FH" && remainingDays <= 3) {
                    color = "#E95048";
                  }
                  return (
                    <>
                      <Grid item xs={10}>
                        <Grid
                          container
                          columns={10}
                          className={`${styles.lineItems} ${activeIndex === index ? styles.selectedIndex : ""}`}
                          sx={{ zIndex: 2 }}
                          onClick={() => handleClick(e, index)}
                        >
                          <Grid
                            item
                            xs={0.5}
                            className={`${styles.tableBody} ${styles.toUpperCase}`}
                            sx={{ alignItems: "center" }}
                          >
                            {e.location}
                          </Grid>
                          <Grid
                            item
                            xs={0.7}
                            className={`${styles.tableBody} ${styles.toUpperCase}`}
                            sx={{ alignItems: "center" }}
                          >
                            {e.aircraftReg}
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            className={`${styles.tableBody} ${styles.toUpperCase}`}
                            sx={{ alignItems: "center", justifyContent: "flex-start" }}
                          >
                            <LightTooltip title={e.defectDesc} followCursor placement="bottom-start">
                              <Typography className={classes.gridItem}>{e.defectDesc}</Typography>
                            </LightTooltip>
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            className={`${styles.tableBody} ${styles.toUpperCase}`}
                            sx={{ alignItems: "center" }}
                          >
                            {e.ddmlNo}
                          </Grid>
                          <Grid item xs={1} className={`${styles.tableBody}`} sx={{ alignItems: "center" }}>
                            {formatDateDasr(e.raisedDate)}
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            className={`${styles.tableBody}`}
                            sx={{ alignItems: "center", color: remainingDays <= 3 ? "#E95048" : "" }}
                          >
                            {e.formType === "STR/FC/FH"
                              ? balanceSTR
                              : e.formType === "MEL CAT" || e.formType === "AMD"
                              ? formatDateDasr(e.expiryDate)
                              : calculateCalendarExpiryDate(e.calendarDaysStartDate, e.calendarDaysInterval)}
                          </Grid>
                          <Grid
                            item
                            xs={2.8}
                            className={`${styles.tableBody} ${styles.gridJustifyStart} ${styles.toUpperCase}`}
                            sx={{
                              alignItems: "center",
                              justifyContent: "flex-start",
                              position: "relative",
                              paddingRight: "34px",
                            }}
                          >
                            <LightTooltip title={e.limitationsDesc.toUpperCase()} followCursor placement="bottom-start">
                              <Typography className={classes.gridItem}>{e.limitationsDesc}</Typography>
                            </LightTooltip>
                            <IconUnreadIndicator data={e} />
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  );
                })}
              <Grid item xs={10} className={`${styles.lineItems}`}>
                <MUIPagination
                  page={parseInt(page)}
                  setPage={setPage}
                  setPerPage={setPerPage}
                  totalPage={parseInt(totalPages)}
                  classes={{ ul: classes.ul }}
                  totalRows={parseInt(totalRows)}
                  sliceStart={sliceStart}
                  initialPerPage={perPage}
                />
              </Grid>
            </Grid>
          </div>
        </>
      ) : (
        <div onClick={() => props.setOpenModal(false)}>
          <NoData />
        </div>
      )}
      <ElevadeDrawer
        open={open}
        setOpen={setOpen}
        data={data}
        Component={DDML}
        aoc={aoc}
        type={type}
        index={index}
        dataForm={dataForm}
        edit={edit}
        setEdit={setEdit}
        openNotification={openNotification}
        setOpenNotification={setOpenNotification}
        newForm={newForm}
        setNewForm={setNewForm}
        triggerApi={props.triggerApi}
        setTriggerApi={props.setTriggerApi}
        setSelectToMove={setSelectToMove}
        setOpenDialogMoveToAssignment={setOpenDialogMoveToAssignment}
      />
      <DialogMoveAssignment
        open={openDialogMoveToAssignment}
        setOpen={setOpenDialogMoveToAssignment}
        data={selectToMove}
        setTriggerApi={props.setTriggerApi}
        setData={setSelectToMove}
      />
    </>
  ) : (
    <AirplaneLoader />
  );
};

export default Limitation;
