import React, { useState, useEffect, useRef } from "react";
import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import _, { isEmpty } from "lodash";
import { ConvertUTCDatetime, changeFormatDate, formatDate, formatDateDasrWithTime } from "./Helpers";
import Live from "../aircraftMonitoring/Live";
import { calculatePercentage } from "./Helpers";

import PastFlightLine from "common/images/icons/elevade/PastFlightLine.svg";
import FutureFlightLine from "common/images/icons/elevade/FutureFlightLine.svg";
import GearIcon from "common/images/icons/elevade/GearIcon.svg";
import Calendar from "common/images/icons/elevade/CalendarBlank.svg";
import IconCaret from "common/images/icons/elevade/Icon-CaretRight.svg";
import IconCaretDisabled from "common/images/icons/elevade/Icon-CaretDisabled.svg";
import styles from "../aircraftMonitoring/AircraftStatus.module.css";

const FlightMaintenanceSchedule = ({
  aircraftScheduleTask,
  setShowSchedule,
  showSchedule,
  setThirtySixHourCheck,
  thirtySixHourCheck,
  setWeeklyCheck,
  weeklyCheck,
  localTime,
  data,
}) => {
  const [date, setDate] = useState();
  const [today, setToday] = useState();
  const [hourCheckLeg, setHourCheckLeg] = useState();
  const [previousArrival, setPreviousArrival] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const elRef = useRef();
  const [isStartScroll, setIsStartScroll] = useState(true);
  const [isEndScroll, setIsEndScroll] = useState(false);
  const currentTime = getCurrentTimeUTC();

  let flightSchedule = _.groupBy(aircraftScheduleTask?.rows?.[0], "date.value");
  flightSchedule = _.mapValues(flightSchedule, (v) => {
    return _.sortBy(v, "leg");
  });
  let todayDate = new Date().toISOString().slice(0, 10);
  // Get today's date
  let currentDate = new Date();
  // Subtract one day
  currentDate.setDate(currentDate.getDate() - 1);
  // Convert to ISO string with only date part
  let yesterdayDate = currentDate.toISOString().slice(0, 10);

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 8,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#6AB47B" : "#D1D1D1",
    },
  }));

  const myRef = useRef(null);
  const myRefTo = useRef();
  const scrollToElement = () => {
    // eslint-disable-next-line no-unused-expressions
    myRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      alignToTop: true,
      // inline: "start" // an issue
    });
  };

  useEffect(() => {
    setInitialDate();
  }, []);

  useEffect(() => {
    const scheduleDiv = elRef.current;
    if (scheduleDiv?.scrollWidth === scheduleDiv?.clientWidth) setIsEndScroll(true);
    else setIsEndScroll(false);
  }, [filteredData]);

  useEffect(() => {
    if (showSchedule) {
      setTimeout(() => {
        scrollToElement();
      }, 2000);
    }
  }, [showSchedule]);

  useEffect(() => {
    const el = elRef.current;
    if (el) {
      const onWheel = (e) => {
        if (e.deltaY === 0) return;
        e.preventDefault();
        el.scrollBy(e.deltaY, 0);
      };
      el.addEventListener("wheel", onWheel);
      return () => el.removeEventListener("wheel", onWheel);
    }
  }, []);

  useEffect(() => {
    if (flightSchedule.hasOwnProperty(yesterdayDate)) {
      delete flightSchedule[yesterdayDate];
    }
    setFilteredData(flightSchedule);
  }, [yesterdayDate, aircraftScheduleTask]);

  const outsider = document.getElementById("outsider");
  const distance = 600;

  function scrollLft() {
    outsider.scrollBy({
      left: -distance,
      behavior: "smooth",
    });
  }

  function scrollRight() {
    outsider.scrollBy({
      left: distance,
      behavior: "smooth",
    });
  }

  function getCurrentTimeUTC() {
    const now = new Date();
    const year = now.getUTCFullYear();
    const month = (now.getUTCMonth() + 1).toString().padStart(2, "0"); // Adding 1 because months are zero-indexed
    const day = now.getUTCDate().toString().padStart(2, "0");
    const hours = now.getUTCHours().toString().padStart(2, "0");
    const minutes = now.getUTCMinutes().toString().padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}:00`;
  }

  const scheduleDiv = elRef.current;
  if (scheduleDiv) {
    scheduleDiv.addEventListener("scroll", function () {
      const clientWidth = scheduleDiv.clientWidth;
      const scrollWidth = scheduleDiv.scrollWidth;
      const scrollLeft = Math.floor(scheduleDiv.scrollLeft);
      if (scrollLeft === 0) {
        setIsStartScroll(true);
        setIsEndScroll(false);
      } else if (scrollLeft === scrollWidth - clientWidth) {
        setIsStartScroll(false);
        setIsEndScroll(true);
      } else if (scrollWidth === clientWidth) {
        setIsStartScroll(true);
        setIsEndScroll(true);
      } else {
        setIsStartScroll(false);
        setIsEndScroll(false);
      }
    });
  }

  function setInitialDate() {
    var currentDate = new Date();
    var options = { month: "long", day: "2-digit", year: "numeric" };
    var formattedDate = currentDate.toLocaleDateString("en-US", options);
    setDate(formattedDate);
    setToday(formattedDate);
    // Set yesterday's date
    currentDate.setDate(currentDate.getDate() - 1);
    // setYesterday(currentDate.toLocaleDateString("en-US", options));
  }

  const maintenanceTemplate = (flight, type) => {
    return (
      <div
        className="maintenance"
        style={{
          height: "107px",
          width: "186px",
          border: flight?.date?.value === todayDate && flight?.leg === 0 ? "1px solid #07C2A2" : "1px solid #E7E7E7",
          borderRadius: "12px",
          marginRight: "12px",
          minWidth: "186px",
          minHeight: "107px",
          // padding: "16px",
          lineHeight: "normal",
          // backgroundColor: todayDate <= flight?.date?.value ? "#FFF" : "#F7F7F7",
          display: "flex",
          alignItems: "center",
          background: "repeating-linear-gradient( 135deg, #eceff7, #eceff7 25px, #f4f7f7 10px, #f4f7f7 35px )",
        }}
      >
        <Grid container spacing={2} className="mx-auto">
          <Grid item xs={3} sx={{ padding: "0" }}>
            <div
              style={{ borderRadius: "50%", backgroundColor: "#ffffff", width: "38px", height: "38px", padding: "7px" }}
            >
              <img src={GearIcon} alt="flight-line" style={{ width: "24px", height: "24px" }} />
            </div>
          </Grid>
          <Grid item xs={9} sx={{ padding: "0" }}>
            <div className="small-font gotham-medium" style={{ color: "#088FD1" }}>
              {type === "hour" ? <span>36 Hour Check</span> : <span>Weekly Check</span>}
            </div>
            <div className="xsmall-font">
              {type === "hour" ? (
                <>{formatDate(thirtySixHourCheck?.next_exp_time_utc?.value, "time", localTime, "", "maintenance")}</>
              ) : (
                <>{formatDate(weeklyCheck?.next_exp_time_utc?.value, "time", localTime, "", "maintenance")}</>
              )}
            </div>
          </Grid>
        </Grid>
      </div>
    );
  };

  function scheduleCard(flight) {
    return (
      <div
        style={{
          height: "107px",
          width: "186px",
          border: checkLiveFlight(flight) ? "1px solid #07C2A2" : "1px solid #E7E7E7",
          borderRadius: "12px",
          marginRight: "12px",
          minWidth: "186px",
          minHeight: "107px",
          padding: "16px",
          lineHeight: "normal",
          backgroundColor: todayDate <= flight?.date?.value ? "#FFF" : "#F7F7F7",
        }}
      >
        <div className="d-flex" style={{ width: "100%", marginBottom: "12px" }}>
          {checkLiveFlight(flight) ? (
            <Live
              style={{
                height: "18px",
                margin: "-3px 8px 0px -4px",
              }}
            />
          ) : (
            <></>
          )}
          <div className="mr-auto small-font gotham-medium">{flight?.flight_no}</div>
          <div className="xsmall-font" style={{ color: "#838383" }}>
            {flight?.outMonthDay}
          </div>
        </div>
        <div className="d-flex" style={{ width: "100%", marginBottom: "14px" }}>
          <div className="mr-auto small-font gotham-medium">{flight?.dep}</div>

          {checkLiveFlight(flight) ? (
            <div className="mx-auto progressBar" style={{ width: "50%", marginTop: "4px" }}>
              <BorderLinearProgress variant="determinate" value={calculatePercentage(data)} />
            </div>
          ) : todayDate === flight?.date?.value && flight?.leg < 0 ? (
            <div className="mx-auto">
              <img src={PastFlightLine} alt="flight-line" style={{ marginTop: "-5px" }} />
            </div>
          ) : todayDate <= flight?.date?.value && flight?.leg !== 0 ? (
            <div className="mx-auto">
              <img src={FutureFlightLine} alt="flight-line" style={{ marginTop: "-5px" }} />
            </div>
          ) : todayDate === flight?.date?.value && flight?.leg < 0 ? (
            <div className="mx-auto">
              <img src={PastFlightLine} alt="flight-line" style={{ marginTop: "-5px" }} />
            </div>
          ) : (
            <div className="mx-auto">
              <img src={PastFlightLine} alt="flight-line" style={{ marginTop: "-5px" }} />
            </div>
          )}

          <div className="small-font gotham-medium ml-auto">{flight?.arr}</div>
        </div>
        <div className="d-flex xsmall-font gotham-medium" style={{ width: "100%", color: "#838383" }}>
          <div className="mr-auto">
            {ConvertUTCDatetime(changeFormatDate(flight?.std?.value), localTime).split(" ")[1]}
          </div>
          <div>{ConvertUTCDatetime(changeFormatDate(flight?.sta?.value), localTime).split(" ")[1]}</div>
        </div>
      </div>
    );
  }

  function checkLiveFlight(flight) {
    if (
      flight?.flight_no === data?.flight_no &&
      flight?.dep === data?.departure_airport &&
      todayDate === data?.start_time?.value.split("T")[0] &&
      todayDate === flight?.date?.value
    )
      return true;
    else return false;
  }

  function checkHour(flight, prevData, nextData, type) {
    if (thirtySixHourCheck?.next_exp_time_utc?.value?.split("T")[0] === flight?.date?.value) {
      if (
        thirtySixHourCheck?.next_exp_time_utc?.value > flight?.std?.value &&
        thirtySixHourCheck?.next_exp_time_utc?.value < flight?.sta?.value &&
        type === "before"
      ) {
        return <>{maintenanceTemplate(flight, "hour")}</>;
      } else if (
        thirtySixHourCheck?.next_exp_time_utc?.value <= flight?.std?.value &&
        isEmpty(prevData) &&
        type === "before"
      ) {
        return <>{maintenanceTemplate(flight, "hour")}</>;
      } else if (
        thirtySixHourCheck?.next_exp_time_utc?.value >= prevData?.sta?.value &&
        thirtySixHourCheck?.next_exp_time_utc?.value <= prevData?.std?.value &&
        type === "before"
      ) {
        return <>{maintenanceTemplate(flight, "hour")}</>;
      } else if (
        thirtySixHourCheck?.next_exp_time_utc?.value <= flight?.std?.value &&
        thirtySixHourCheck?.next_exp_time_utc?.value >= prevData?.sta?.value &&
        type === "before"
      ) {
        return <>{maintenanceTemplate(flight, "hour")}</>;
      } else if (
        thirtySixHourCheck?.next_exp_time_utc?.value >= flight?.sta?.value &&
        isEmpty(nextData) &&
        type === "after"
      ) {
        return <>{maintenanceTemplate(flight, "weekly")}</>;
      } else {
        return <></>;
      }
    } else {
      return <div></div>;
    }
  }

  function checkWeekly(flight, prevData, nextData, type) {
    if (weeklyCheck?.next_exp_time_utc?.value?.split("T")[0] === flight?.date?.value) {
      if (
        weeklyCheck?.next_exp_time_utc?.value > flight?.std?.value &&
        weeklyCheck?.next_exp_time_utc?.value < flight?.sta?.value &&
        type === "before"
      ) {
        return <>{maintenanceTemplate(flight, "weekly")}</>;
      } else if (
        weeklyCheck?.next_exp_time_utc?.value >= prevData?.sta?.value &&
        weeklyCheck?.next_exp_time_utc?.value <= prevData?.std?.value &&
        type === "before"
      ) {
        return <>{maintenanceTemplate(flight, "weekly")}</>;
      } else if (
        weeklyCheck?.next_exp_time_utc?.value <= flight?.std?.value &&
        weeklyCheck?.next_exp_time_utc?.value >= prevData?.sta?.value &&
        type === "before"
      ) {
        return <>{maintenanceTemplate(flight, "weekly")}</>;
      } else if (weeklyCheck?.next_exp_time_utc?.value >= flight?.sta?.value && isEmpty(nextData) && type === "after") {
        return <>{maintenanceTemplate(flight, "weekly")}</>;
      } else {
        return <></>;
      }
    } else {
      return <div></div>;
    }
  }

  const onWheel = (e) => {
    let elem = document.querySelector("#containera");

    elem.addEventListener("wheel", (event) => {
      event.preventDefault();

      let [x, y] = [event.deltaX, event.deltaY];
      let magnitude;

      if (x === 0) {
        magnitude = y > 0 ? -200 : 200;
      } else {
        magnitude = x;
      }

      elem.scrollBy({
        left: magnitude,
        behavior: "smooth",
      });
    });
  };

  function IconScrollPrevious() {
    if (isStartScroll) {
      return (
        <img
          src={IconCaretDisabled}
          alt={"icon-arrow-left"}
          height={20}
          onClick={() => scrollLft()}
          className={styles.caret}
        />
      );
    } else {
      return (
        <img
          src={IconCaret}
          alt={"icon-arrow-left"}
          height={20}
          onClick={() => scrollLft()}
          className={styles.caretRotate}
        />
      );
    }
  }

  function IconScrollNext() {
    if (isEndScroll) {
      return (
        <img
          src={IconCaretDisabled}
          alt={"icon-arrow-right"}
          height={20}
          onClick={() => scrollRight()}
          className={styles.caretRotate}
        />
      );
    } else {
      return (
        <img
          src={IconCaret}
          alt={"icon-arrow-right"}
          height={20}
          onClick={() => scrollRight()}
          className={styles.caret}
        />
      );
    }
  }

  return (
    <>
      {showSchedule ? (
        <>
          <div className={`row ${styles.displayScheduleHeader} ${styles.mainBodyData}`} style={{ marginLeft: "16px" }}>
            <Grid container spacing={1}>
              <Grid item xs={6} sx={{ fontFamily: "Gotham-Medium" }}>
                <div className="small-font gotham-medium">Flight & Maintenance Schedule</div>
              </Grid>
              <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
                {/* <div style={{ cursor: "pointer" }} onClick={() => setShowSchedule(!showSchedule)}> */}
                {/* {formattedDate("", date)}
                  <DateToggle /> */}
                <div onClick={() => setShowSchedule(!showSchedule)} style={{ cursor: "pointer" }}>
                  <img
                    src={Calendar}
                    height="20px"
                    alt="calendar"
                    style={{ marginRight: "14px", marginLeft: "16px" }}
                  />
                  <span className="small-font gotham-medium">Hide Schedule</span>
                </div>
                {/* </div> */}
              </Grid>
            </Grid>
          </div>

          <Grid className={`${styles.flightScheduleSection}`} style={{ marginLeft: "16px" }} ref={elRef} id="outsider">
            {!isEmpty(filteredData) ? (
              Object.values(filteredData)
                .reverse()
                .map((flights, index) => {
                  return (
                    <>
                      {flights
                        .filter((e) => new Date(e.sta.value) >= new Date(currentTime))
                        .map((flight, index, arr) => {
                          let prevData = arr[index - 1];
                          let nextData = arr[index + 1];
                          return (
                            <div key={index}>
                              {/* Display Month Day Grouping */}
                              {index === 0 ? (
                                <div
                                  ref={flight?.date?.value === todayDate ? myRef : myRefTo}
                                  id={flight?.date?.value === todayDate ? "today" : "other"}
                                  className="small-font gotham-medium"
                                  style={{
                                    height: "22px",
                                    marginBottom: "16px",
                                    color: flight?.date?.value === todayDate ? "#08D1AF" : "#333",
                                  }}
                                >
                                  {flight.outMonthDay}
                                </div>
                              ) : (
                                <div style={{ height: "22px", marginBottom: "16px" }}></div>
                              )}
                              <div classname="schedule-cards" style={{ display: "flex" }}>
                                {checkHour(flight, prevData, nextData, "before")}
                                {checkWeekly(flight, prevData, nextData, "before")}
                                {scheduleCard(flight)}
                                {checkHour(flight, prevData, nextData, "after")}
                                {checkWeekly(flight, prevData, nextData, "after")}
                              </div>
                            </div>
                          );
                        })}
                    </>
                  );
                })
            ) : (
              <></>
            )}
          </Grid>

          <Grid container columns={2} sx={{ marginTop: "18px", paddingLeft: "18px" }}>
            <Grid item xs={1} sx={{ fontStyle: "italic", fontSize: "10px", color: "#838383" }}>
              *AIMS as of{" "}
              {formatDateDasrWithTime(aircraftScheduleTask?.last_modified_dt?.[0]?.last_modified_dt?.value, localTime)}
              {", "}
              AIMS data is just for reference
            </Grid>
            <Grid item xs={1} sx={{ display: "flex", justifyContent: "flex-end" }}>
              {IconScrollPrevious()}
              {IconScrollNext()}
            </Grid>
          </Grid>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default FlightMaintenanceSchedule;
