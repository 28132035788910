import styles from "./EmailAlertManagement.module.css";
import { Button, Container, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState, useEffect } from "react";
import NewFile from "common/images/icons/elevade/NewFile.svg";
import SearchBar from "../component/backoffice/searchbar/SearchBar";
import ActiveFilters from "../component/backoffice/activefilters/ActiveFilters";
import Overlay from "../component/Overlay";
import EmailAlerts from "./emailAlertsTab/EmailAlerts";
import NewAlert from "./emailAlertsTab/NewAlert";
// import PopoutIcon from "common/images/icons/elevade/PopoutIconBold.svg";
import NewRecipients from "./recipientsTab/NewRecipients";
import AlertRecipients from "./recipientsTab/AlertRecipients";
import { useSelector } from "react-redux";
import { makeAPIRequestRun, showNotificationElevade } from "common/helpers";
import MUIPagination from "../component/Pagination";
import { makeStyles } from "@mui/styles";
import { reverse, sortBy } from "lodash";
import ElevadeDrawer from "../dasr/component/Drawer";
import { AOCList } from "common/constant";
import IconErrorAirBalloon from "common/images/icons/elevade/Icon-ErrorAirBalloon.svg";

const useStyles = makeStyles(() => ({
  ul: {
    "& .Mui-selected": {
      color: "#fff",
      backgroundColor: "#088FD1",
      border: "1px solid #088FD1",
      "&:hover": {
        backgroundColor: "#088FD1",
      },
    },
  },
}));

const ButtonStyled = styled(Button)(({ theme }) => ({
  textTransform: "none",
  fontFamily: "Gotham-Medium",
  fontSize: "14px",
  fontWeight: "350",
  lineHeight: "20px",
  letterSpacing: "0em",
  textAlign: "center",
  padding: "11px 24px 11px 24px",
  borderRadius: "8px",
  gap: "8px",
  backgroundColor: "#088FD1",
  height: "42px",
}));

const EmailAlertManagement = () => {
  /* ---------- states -------------- */

  const [activeTab, setActiveTab] = useState(1);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const [totalRows, setTotalRows] = useState(0);
  const [sort, setSort] = useState("");
  const [recipientsPage, setRecipientsPage] = useState(1);
  const [recipientsLimit, setRecipientsLimit] = useState(50);
  const [recipientsTotalRows, setRecipientsTotalRows] = useState(0);
  const [recipientsSort, setRecipientsSort] = useState("");
  const [isSearch, setIsSearch] = useState();
  const [isRecipientsSearch, setIsRecipientsSearch] = useState();
  const [loading, setLoading] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [updateData, setUpdateData] = useState([]);
  const [alertData, setAlertData] = useState();
  const [recipientData, setRecipientData] = useState();
  const { userAOC, userName } = useSelector((state) => state.userEntity);
  const [operator, setOperator] = useState(
    Array.isArray(userAOC)
      ? userAOC.filter((useraoc) => AOCList.some((AOC) => AOC.code === useraoc))[0]
      : AOCList.includes(userAOC)
      ? userAOC
      : ""
  );
  const [ataEventList, setAtaEventList] = useState([]);
  const [action, setAction] = useState("");
  const [isFilters, setIsFilters] = useState({
    search: [],
    // operator: operator,
    ata_head: [],
  });
  const [filterData, setFilterData] = useState(
    userAOC.filter((fleet) => AOCList.some((AOC) => AOC.code === fleet)).map((fleet) => ({ fleet: fleet }))
  );
  const [recipientsFilter, setRecipientsFilter] = useState({
    search: [],
  });
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [graphQLEmail, setGraphQLEmail] = useState();
  const [callApi, setCallApi] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [filteredRecipientData, setFilteredRecipientData] = useState([]);
  const classes = useStyles();
  let offsetValue = page > 0 ? limit * (page - 1) : 0;
  let sliceEnd = parseInt(limit) + offsetValue;

  let recipientsOffsetValue = recipientsPage > 0 ? recipientsLimit * (recipientsPage - 1) : 0;
  let recipientSliceEnd = parseInt(recipientsLimit) + recipientsOffsetValue;

  /* ---------- use effects ---------- */

  useEffect(() => {
    setPage(1);
    // eslint-disable-next-line
  }, [limit]);

  useEffect(() => {
    let data_ = alertData;

    if (isFilters.search?.length > 0) {
      data_ = data_?.filter(
        (e) =>
          isFilters.search?.some((searchTerm) => e.ATA?.includes(searchTerm)) ||
          isFilters.search?.some((searchTerm) => e.analysis?.includes(searchTerm)) ||
          isFilters.search?.some((searchTerm) => e.ata_head?.includes(searchTerm)) ||
          isFilters.search?.some((searchTerm) => e.contactpoint?.includes(searchTerm)) ||
          isFilters.search?.some((searchTerm) => e.fleet?.includes(searchTerm)) ||
          isFilters.search?.some((searchTerm) => e.observation?.toUpperCase()?.includes(searchTerm)) ||
          isFilters.search?.some((searchTerm) => e.recipients?.toUpperCase()?.includes(searchTerm)) ||
          isFilters.search?.some((searchTerm) => e.recommendations?.toUpperCase()?.includes(searchTerm)) ||
          isFilters.search?.some((searchTerm) => e.rootcause?.toUpperCase()?.includes(searchTerm)) ||
          isFilters.search?.some((searchTerm) => e.text?.toUpperCase()?.includes(searchTerm)) ||
          isFilters.search?.some((searchTerm) => e.ac_family?.toUpperCase()?.includes(searchTerm)) ||
          isFilters.ata_head?.includes(e.ATA)
      );
    } else setIsSearch();

    if (isFilters.ata_head?.length > 0) {
      data_ = data_?.filter((e) => isFilters.ata_head?.includes(e.ATA));
    }
    if (sort) {
      const property = sort.split(" ")[0];
      const order = sort.split(" ")[1];
      data_ = sortBy(data_, [property]);
      if (order === "desc") reverse(data_);
    }

    setFilteredData(data_);
    setTotalRows(data_?.length);
  }, [isSearch, alertData, isFilters, sort]);

  useEffect(() => {
    let data_ = recipientData;
    if (recipientsFilter.search?.length > 0) {
      data_ = data_?.filter(
        (e) =>
          recipientsFilter.search?.some((searchTerm) => e.fullname?.toUpperCase()?.includes(searchTerm)) ||
          recipientsFilter.search?.some((searchTerm) => e.email?.toUpperCase()?.includes(searchTerm)) ||
          recipientsFilter.search?.some((searchTerm) => e.phoneNo?.toUpperCase()?.includes(searchTerm)) ||
          recipientsFilter.search?.some((searchTerm) => e.department?.toUpperCase()?.includes(searchTerm))
      );
    } else setIsRecipientsSearch();
    if (recipientsSort) {
      const property = recipientsSort.split(" ")[0];
      const order = recipientsSort.split(" ")[1];
      data_ = sortBy(data_, [property]);
      if (order === "desc") data_ = reverse(data_);
    }
    setFilteredRecipientData(data_);
    setRecipientsTotalRows(data_?.length);
    //eslint-disable-next-line
  }, [isRecipientsSearch, recipientData, recipientsSort, recipientsFilter]);

  useEffect(() => {
    if (isSearch) {
      setIsFilters((prev) => {
        const _prev = { search: [], ...prev };
        if (prev.search.includes(isSearch)) return { ...prev };
        else return { search: _prev.search.push(isSearch), ..._prev };
      });
    }
  }, [isSearch]);

  useEffect(() => {
    if (isRecipientsSearch) {
      setRecipientsFilter((prev) => {
        const _prev = { search: [], ...prev };
        if (prev.search.includes(isRecipientsSearch)) return { ...prev };
        else return { search: _prev.search.push(isRecipientsSearch), ..._prev };
      });
    }
  }, [isRecipientsSearch]);

  useEffect(() => {
    fetchAlertData(operator);
    fetchRecipientData(operator);
    //eslint-disable-next-line
  }, [callApi]);

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        await Promise.all([fetchAlertData(operator), fetchRecipientData(operator)]);
      } catch (err) {
        setLoading(false);
        console.log("Error occurred when fetching data");
      }
    })();
    // eslint-disable-next-line
  }, [operator]);

  useEffect(() => {
    if (alertData) setLoading(false);
    else setLoading(true);
  }, [alertData]);

  /* ---------- functions ------------ */

  async function fetchAlertData(operator) {
    // Construct the URL with query parameters
    const queryParams = new URLSearchParams({
      search: "",
      ata: "",
      operator: operator,
      sort: sort ?? "",
      limit: limit,
      offset: offsetValue,
    });

    const getAlertData = makeAPIRequestRun("get", `ecamalerts/emailalerts/get/?${queryParams}`);
    getAlertData
      .then((response) => {
        if (response.error) {
          setLoading(false);
          setTotalRows(0);
          showNotificationElevade("danger", "Error", ["Something went wrong, unable to fetch alerts"]);
        } else if (!response.error) {
          setAlertData(response.alerts);
          setAtaEventList(response.result);
          setFilterData((prev) => {
            const data = userAOC
              .filter((fleet) => AOCList.some((AOC) => AOC.code === fleet))
              .map((fleet) => ({ fleet: fleet }));
            return [...response.ataList, ...data];
          });
          setTotalRows(response?.totalCount ?? 0);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
      });
  }

  async function fetchRecipientData(operator) {
    const recipientParams = new URLSearchParams({
      search: "",
      operator: operator,
      sort: sort ?? "",
      limit: recipientsLimit,
      offset: recipientsOffsetValue,
    });

    const getEmailRecipient = makeAPIRequestRun("get", `ecamalerts/recipients/get/?${recipientParams}`).catch((err) => {
      setRecipientsTotalRows(0);
      setLoading(false);
      console.log(err);
    });
    getEmailRecipient.then((response) => {
      setRecipientData(response.recipients);
      setRecipientsTotalRows(response?.totalCount ?? 0);
      // setLoading(false);
    });
  }

  function handleActiveTab(tab) {
    setActiveTab(tab);
    setPage(1);
    setLimit(limit);
    handleCloseDrawer();
  }

  function handleOpenDrawer(event) {
    event.stopPropagation(); // Prevent the click event from propagating to ClickAwayListener
    // setOpenDrawer((prev) => !prev);
    setOpenDrawer(true);
    setUpdateData();
    setAction("add");
    fetchDataGraphQLEmail();
  }

  function handleCloseDrawer() {
    if (!isSelectOpen) {
      setOpenDrawer(false);
    }
  }

  const fetchDataGraphQLEmail = async () => {
    const mane = "null";
    const mail = "null";
    const depts = "null";
    const data = await makeAPIRequestRun("get", `api/company/companyDetails/${operator}/${mane}/${mail}/${depts}`);
    setGraphQLEmail(data?.allUsers?.edges);
  };

  /* ---------- components ---------- */

  const ViewBox = () => {
    return (
      <>
        <div className={`${styles.tabs} ${styles.row}`}>
          <div className={`${activeTab === 1 ? styles.activeAircraftTab : styles.inactiveAircraftTab} ${styles.row}`}>
            <div onClick={(e) => handleActiveTab(1)}>
              <span>Email Alerts</span>
            </div>
            <span className={`${styles.toolTip} ${styles.centerItem}`}>{totalRows}</span>
          </div>
          <div className={`${activeTab === 2 ? styles.activeAircraftTab : styles.inactiveAircraftTab} ${styles.row}`}>
            <div onClick={(e) => handleActiveTab(2)}>
              <span>Alert Recipients</span>
            </div>
            <span className={`${styles.toolTip} ${styles.centerItem}`}>
              {recipientsTotalRows}
              {/* {recipientData?.length} */}
            </span>
          </div>
        </div>
        <SearchBar
          data={filterData}
          // data={alertData}
          isSearch={activeTab === 1 ? isSearch : isRecipientsSearch}
          setIsSearch={activeTab === 1 ? setIsSearch : setIsRecipientsSearch}
          isFilters={activeTab === 1 ? isFilters : recipientsFilter}
          setIsFilters={activeTab === 1 ? setIsFilters : setRecipientsFilter}
          isAcType={false}
          isAtaChapter={activeTab === 1}
          isStatus={false}
          isOperator={false}
          setOperator={setOperator}
          operator={operator ?? ""}
        />
        <ActiveFilters
          isFilters={activeTab === 1 ? isFilters : recipientsFilter}
          setIsFilters={activeTab === 1 ? setIsFilters : setRecipientsFilter}
        />
        {activeTab === 1 && (
          <EmailAlerts
            data={filteredData}
            setUpdateData={setUpdateData}
            setOpenDrawer={setOpenDrawer}
            setAction={setAction}
            setSort={setSort}
            email={userName}
            setCallApi={setCallApi}
            callApi={callApi}
            setIsSelectOpen={setIsSelectOpen}
            setData={setFilteredData}
            sliceStart={offsetValue}
            sliceEnd={sliceEnd}
          />
        )}
        {activeTab === 2 && (
          <AlertRecipients
            loading={loading}
            limit={limit}
            data={filteredRecipientData}
            setSort={setRecipientsSort}
            setData={setRecipientData}
            sliceStart={recipientsOffsetValue}
            sliceEnd={recipientSliceEnd}
            operator={operator}
            setCallApi={setCallApi}
            callApi={callApi}
            setLoading={setLoading}
            alertData={alertData}
          />
        )}
      </>
    );
  };

  const ErrorAirBallon = () => {
    return (
      <>
        <div className={styles.errorAirBallon}>
          <center>
            <img src={IconErrorAirBalloon} alt="error-air-ballon" />
            <div className={styles.errorTitle}>No data available</div>
          </center>
        </div>
      </>
    );
  };

  return (
    <>
      {loading && <Overlay />}
      {!loading && (
        <>
          <div className={styles.main}>
            <div className={styles.row}>
              <div className={`${styles.textLarge}`}>Email Alert Management</div>
              {operator && (
                <div className={`${styles.alignEnd}`}>
                  {activeTab === 1 && (
                    <ButtonStyled
                      variant="contained"
                      color="primary"
                      sx={{ width: "150px", height: "42px" }}
                      startIcon={<img src={NewFile} alt="new file" />}
                      onClick={(e) => handleOpenDrawer(e)}
                    >
                      New Alert
                    </ButtonStyled>
                  )}
                  {activeTab === 2 && (
                    <div className={`${styles.row} ${styles.alignEnd}`}>
                      <ButtonStyled
                        variant="contained"
                        color="primary"
                        sx={{ width: "186px" }}
                        startIcon={<img src={NewFile} alt="new file" />}
                        onClick={(e) => handleOpenDrawer(e)}
                      >
                        New Recipients
                      </ButtonStyled>
                    </div>
                  )}
                </div>
              )}
            </div>
            {operator ? <ViewBox /> : <ErrorAirBallon />}
            {activeTab === 1 && alertData?.length > 0 && (
              <MUIPagination
                page={parseInt(page)}
                setPage={setPage}
                setPerPage={setLimit}
                totalPage={parseInt(totalRows ? Math.floor(totalRows / limit) + 1 : 0)}
                classes={{ ul: classes.ul }}
                totalRows={parseInt(totalRows)}
                sliceStart={offsetValue}
                initialPerPage={limit}
              />
            )}
            {activeTab === 2 && recipientData?.length > 0 && (
              <MUIPagination
                page={recipientsPage}
                setPage={setRecipientsPage}
                setPerPage={setRecipientsLimit}
                totalPage={recipientsTotalRows ? Math.floor(recipientsTotalRows / recipientsLimit) + 1 : 0}
                classes={{ ul: classes.ul }}
                totalRows={recipientsTotalRows}
                sliceStart={recipientsOffsetValue}
                initialPerPage={recipientsLimit}
              />
            )}
          </div>
          <div>
            {activeTab === 1 && (
              <ElevadeDrawer
                open={openDrawer}
                setOpen={setOpenDrawer}
                data={updateData}
                Component={NewAlert}
                setOpenDrawer={setOpenDrawer}
                openDrawer={openDrawer}
                userList={recipientData ?? []}
                ataEventList={ataEventList}
                userAOC={userAOC}
                action={action}
                email={userName}
                existingData={alertData}
                setCallApi={setCallApi}
                callApi={callApi}
                top={"6%"}
                height={"95%"}
                operator={operator}
              />
            )}
            {activeTab === 2 && (
              <ElevadeDrawer
                open={openDrawer}
                setOpen={setOpenDrawer}
                data={recipientData}
                Component={NewRecipients}
                setOpenDrawer={setOpenDrawer}
                recipientData={recipientData}
                openDrawer={openDrawer}
                setGraphQLEmail={setGraphQLEmail}
                graphQLEmail={graphQLEmail}
                setRecipientData={setRecipientData}
                setCallApi={setCallApi}
                callApi={callApi}
                top={"6%"}
                height={"95%"}
                operator={operator}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default EmailAlertManagement;
