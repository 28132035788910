import React from "react";
import { Box } from "@mui/material";
import FleetSidebar from "./FleetSidebar";
import { ElevadeSidebar } from "@ade/global-component-web";

const Sidebar = () => {
  return (
    <>
      <Box
        sx={{
          width: "52px",
          position: "fixed",
          left: 0,
          top: "60px",
          minHeight: "100%",
          display: "flex",
          zIndex: "1998",
          backgroundColor: "#fff",
        }}
      >
        <FleetSidebar />
      </Box>
    </>
  );
};

export default Sidebar;
