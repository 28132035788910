export function arrayStringConcatUnique(arr1, arr2) {
	const holder = [...arr1];
	arr2.map((item2) => {
		if (!holder.includes(item2)) {
			holder.push(item2);
		}
	});
	return holder;
}
export function hasSubArray(master, sub) {
	return sub.every((el) => master.includes(el));
}


export function jsArraySortFunctionComparator(a, b, propName) {
	if (a?.[propName] < b?.[propName]) {
		return -1;
	}
	if (a?.[propName] > b?.[propName]) {
		return 1;
	}
	return 0;
}