import { env_var_REACT_APP_API_URL, env_var_REACT_APP_API_URL_ADV } from "../../envVar/vars";
import useGetGQL, { axiosGraphqlRequest } from "../useGetGQL";
import useMutateGQL from "../useMutateGQL";
import { useAppSelector } from "../../app/hooks";

import { getMonth2D, getDate2D } from "../../views/utils/dates";
import { DEFAULT_LIMIT_PER_PAGE } from "../../constants/utils";
import { useGetAllInventoryTypes } from "./inventory-item-model";
import { useGetAllItemStatuses } from "./ac-item-model";
import { useGetAllFleets } from "./fleet-model";
import { useEffect } from "react";
import { useGetAllLocationTypes } from "./location-inventory-model";
import { generateGQLQuery } from "./utils";

/**
 * @deprecated Status : migrated!
 */
export function useGetAllInventories() {
	const { companyProfile } = useAppSelector((state) => state.userEntity);
	const code = companyProfile?.code;

	const data = useGetGQL({
		gqlquery: code ? `
        query allitem {
			allInventories (aocCode: "${code}"){
				totalCount
			}
        }
        ` : "",
		url: env_var_REACT_APP_API_URL ?? "",
		querySign: "allInventories",
		isEdges: false,
	});

	const dataAdv = useGetGQL({
		gqlquery: code ? `
        query allitem {
			allInventories (aocCode: "${code}"){
				totalItems
			}
        }
        ` : "",
		url: env_var_REACT_APP_API_URL_ADV ?? "",
		querySign: "allInventories",
		isEdges: false,
	});

	function convertNewToOld(dataAdv) {
		return {
			...dataAdv,
			data: {
				...dataAdv?.data,
				totalCount: dataAdv?.data?.totalItems,
			}
		}
	}

	// console.log({
	// 	title: "useGetAllInventories : allInventories > allInventories",
	// 	data,
	// 	dataAdv,
	// 	converted: convertNewToOld(dataAdv),
	// })

	// return data;
	return convertNewToOld(dataAdv);
}

/**
 * @description Status : migrating...
 */
export function useGetAllInvStatus() {
	const { companyProfile } = useAppSelector((state) => state.userEntity);
	const code = companyProfile?.code;
	// totalInventoryStatus(aocCode: "${code}") {

	const query = generateGQLQuery({
		gqlStringQueryType: 'query',
		inputList: [
			{
				name: 'aocCode',
				type: 'string',
				value: code,
			},
		],
		outputList: {
			totalItems: true,
			totalFilters: true,
			totalAircraftActive: true,
			totalAircraftLost: true,
			totalAircraftTagError: true,
			totalAircraftStatus: true,
			totalAircraftNearExpiry: true,
			totalAircraftExpired: true,
			totalAircraftUnassign: true,
		},
		querySign: 'allFleets',
		queryUnsignificantName: 'allFleets',
	})

	const dataAdv = useGetGQL({
		gqlquery: query,
		isUnfiltered:true,
		url: env_var_REACT_APP_API_URL_ADV ?? "",
		querySign: "totalInventoryStatus",
		isEdges: false,
	});

	return dataAdv;
}

/**
 * @description Status : kiv
 */
export function useGetAllInventoriesWithOffset({ offset }) {
	const allInventoryLists = useGetGQL({
		defaultIsLoading: true,
		gqlquery: `
			query allitem {
				allScanners 
					${offset ? `(offset:${offset})` : ""}
					{
					totalCount
					edges {
						node {
							id
							createdAt
							createdBy
							updatedAt
							deviceId
							name
							model {
								id
								code
								name
								manufacturer{
									id
									name
									code
								}
							}
							status {
								id
								code
								name
							}
						}
					}
				}
			}
        `,
		querySign: "allScanners",
		url: env_var_REACT_APP_API_URL ?? "",
	});

	return allInventoryLists;
}

/**
 * @description Status : migrated!
	- new structure :
		id
		tag
		serialNo
		expiryDate
		manufactureDate
		aocCode
		createdAt
		createdBy
		haveLocation
		model
		regNo
		seatNo
		type
		updatedAt
		updatedBy
		type {
			_id
			name
			code
		}
		status : {
			_id
			name
			code
		}
 */
export function useGetFilteredInventory({
	page,
	limit,
	offset,
	search,
	filterRegnos,
	filterAcModels,
	filterStatuses,
	filterEqTypes = [],
	filterLocationExist,
}) {
	const { companyProfile } = useAppSelector((state) => state.userEntity);
	const code = companyProfile?.code;

	const allInventoryTypes = useGetAllInventoryTypes()
	const allInventoryTypeIds = allInventoryTypes?.data?.filter(({ id, code }) => {
		const found = filterEqTypes?.find((item) => {
			return item === code
		})
		return found ? true : false
	})?.map(({ id }) => id)

	const allItemStatus = useGetAllItemStatuses()
	const allItemStatusIds = allItemStatus?.data?.filter(({ id, code }) => {
		const found = filterStatuses?.find((item) => {
			return item === code
		})
		return found ? true : false
	})?.map(({ id }) => id)

	const allFleets = useGetAllFleets()

	// console.log({
	// 	filterRegnos,
	// 	dats: allFleets?.data,
	// })

	// const data = useGetGQL({
	// 	defaultIsLoading: true,
	// 	gqlquery: `
	//     query allitem {
	//         allInventories (
	//             offset:${offset}
	//             ,aocCode: "${code}"
	//             ,haveLocation:${filterLocationExist}
	//             ${search && search !== "" && search !== null ? `, searchInventory:"${search}"` : ""}
	//             ${filterRegnos && filterRegnos.length > 0 ? `, multipleAircraftRegno:${JSON.stringify(filterRegnos)}` : ""}
	//             ${filterAcModels && filterAcModels.length > 0 ? `, multipleAircraftModel:${JSON.stringify(filterAcModels)}` : ""}
	//             ${filterStatuses && filterStatuses.length > 0 ? `, multipleItemStatusId:${JSON.stringify(allItemStatusIds)}` : ""}
	//             ${filterEqTypes && filterEqTypes.length > 0 ? `, multipleEqTypeId:${JSON.stringify(allInventoryTypeIds)}` : ""}
	//         ) {
	//         totalCount
	//         edges {
	//           node {
	//             id
	//             createdAt
	//             createdBy
	//             updatedAt
	//             tag
	//             serialNo
	//             expiryDate
	//             manufactureDate
	//             updatedBy
	//             createdBy
	//             item {
	//               id
	//               code
	//               name
	//               description
	//             }
	//             status {
	//               id
	//               code
	//               name
	//             }
	//             locationlistSet{
	//                 edges{
	//                   node{
	//                     id
	//                     fleetId{
	//                       id
	//                       regNo
	//                       model
	//                       aocCode
	//                     }
	//                     locationId{
	//                       id
	//                       seatNo
	//                       inventoryLocation{
	//                         id
	//                         name
	// 						code
	//                       }
	//                     }
	//                   }
	//                 }
	//               }
	//           }
	//         }
	//       }
	//     }
	//     `,
	// 	querySign: "allInventories",
	// 	url: env_var_REACT_APP_API_URL ?? "",
	// });

	const dataAdv = useGetGQL({
		defaultIsLoading: true,
		gqlquery: `
		query allInventories{
			allInventories(
				page: ${page ?? 1}
				limit: ${limit ?? DEFAULT_LIMIT_PER_PAGE}
                aocCode: "${code}"
                haveLocation:${filterLocationExist}
                ${search && search !== "" && search !== null ? `, searchInventory:"${search}"` : ""}
                ${filterRegnos && filterRegnos.length > 0 ? `, multipleAircraftRegNo:${JSON.stringify(filterRegnos)}` : ""}
                ${filterAcModels && filterAcModels.length > 0 ? `, multipleAircraftModel:${JSON.stringify(filterAcModels)}` : ""}
                ${filterStatuses && filterStatuses.length > 0 ? `, multipleItemStatusId:${JSON.stringify(allItemStatusIds)}` : ""}
                ${filterEqTypes && filterEqTypes.length > 0 ? `, multipleEqTypeId:${JSON.stringify(allInventoryTypeIds)}` : ""}
			){
				totalFilters
				totalItems
				items{
					id
					tag
					serialNo
					haveLocation
					model
					regNo
					seatNo
					createdAt
					createdBy
					expiryDate
					manufactureDate
					updatedAt
					updatedBy
					aocCode
    				model
					type {
						_id
						name
						code
					}
					status {
						_id
						name
						code
					}
				}
			}
		}
        `,
		querySign: "allInventories",
		url: env_var_REACT_APP_API_URL_ADV ?? "",
		isEdges: false,
	});

	function convertNewToOld(dataAdv) {
		return {
			...dataAdv,
			data: dataAdv?.data?.items?.map((node) => {
				return { ...node }
			}),
			totalCount: dataAdv?.data?.totalItems,
		}
	}

	// function convertOldToNew(data) {
	// 	return {
	// 		...data,
	// 		data: data?.data?.map(({ node }) => {

	// 			const {
	// 				createdAt,
	// 				createdBy,
	// 				expiryDate,
	// 				id,
	// 				item,
	// 				locationlistSet,
	// 				manufactureDate,
	// 				serialNo,
	// 				status,
	// 				tag,
	// 				updatedAt,
	// 				updatedBy,
	// 			} = node ?? {}

	// 			return {
	// 				aocCode: locationlistSet?.edges?.[0]?.node?.fleetId?.aocCode,
	// 				createdAt,
	// 				createdBy,
	// 				expiryDate,
	// 				haveLocation: false,
	// 				id,
	// 				manufactureDate,
	// 				model: locationlistSet?.edges?.[0]?.node?.fleetId?.model,
	// 				regNo: locationlistSet?.edges?.[0]?.node?.fleetId?.regNo,
	// 				seatNo: locationlistSet?.edges?.[0]?.node?.locationId?.seatNo,
	// 				serialNo,
	// 				status,
	// 				tag,
	// 				type: {
	// 					_id: item?.id,
	// 					name: item?.name,
	// 					code: item?.code,
	// 				},
	// 				updatedAt,
	// 				updatedBy,
	// 			}
	// 		}),
	// 		totalCount: dataAdv?.data?.totalItems,
	// 	}
	// }

	// console.log({
	// 	title: "useGetFilteredInventory : allInventories > allInventories",
	// 	data,
	// 	dataAdv,
	// 	converted: convertNewToOld(dataAdv),
	// 	convertedOld: convertOldToNew(data),
	// })

	// return convertOldToNew(data)
	return convertNewToOld(dataAdv)
}

/**
 * @description Status : migrated! - not backward compatible
 */
export function useCreateInventory({
	addEqStates,
	allItemStatuses,
	allInventoryTypes,
	allLocationTypes,
	allFleetsFilteredByModel,
}) {

	const statusIdFromCode = allItemStatuses?.data?.find((node) => {
		const { id, name, code, } = node ?? {};
		return code === addEqStates?.status?.value
	})?._id
	const typeId = allInventoryTypes?.data?.find((item) => item?.code === addEqStates?.eqType?.value)?._id
	const fleetId = allFleetsFilteredByModel?.data?.find(item => item?.regNo === addEqStates?.inputRegNo?.value)?.id
	const locationTypeId = allLocationTypes?.data?.find(item => item?.code === addEqStates?.locationType?.value)?._id

	const { companyProfile, userProfile } = useAppSelector((state) => state.userEntity);

	const code = companyProfile?.code;
	const fullName = userProfile?.fullName;
	const photo = userProfile?.photo;

	const dateFormattedmfgDate = addEqStates?.mfgDate?.value ? `${new Date(addEqStates?.mfgDate?.value).getFullYear()}-${getMonth2D(new Date(addEqStates?.mfgDate?.value))}-${getDate2D(new Date(addEqStates?.mfgDate?.value))}` : undefined;
	const dateFormattedexpDate = addEqStates?.expDate?.value ? `${new Date(addEqStates?.expDate?.value).getFullYear()}-${getMonth2D(new Date(addEqStates?.expDate?.value))}-${getDate2D(new Date(addEqStates?.expDate?.value))}` : undefined;

	// const data = useMutateGQL({
	// 	gqlquery: `
	//     mutation addEQ{
	//         createInventories(input:{
	//           tag:"${addEqStates.tagId?.value}"
	//           ${addEqStates?.eqType?.value ? `item:"${addEqStates?.eqType?.value}"` : ''}
	//           ${addEqStates?.serialNo?.value ? `serialNo:"${addEqStates?.serialNo?.value}"` : ''}
	//           ${addEqStates?.status?.value ? `status:"${statusIdFromCode}"` : ''}
	//           ${dateFormattedexpDate ? `expiryDate:"${dateFormattedexpDate}"` : ''}
	//           ${dateFormattedmfgDate ? `manufactureDate:"${dateFormattedmfgDate}"` : ''}
	//           ${addEqStates?.inputRegNoId?.value ? `fleetId:"${addEqStates?.inputRegNoId?.value}"` : ''}
	//           ${addEqStates?.locationDetailsId?.value ? `locationDetailsId:"${addEqStates?.locationDetailsId?.value}"` : ''}
	//           aocCode:"${code}"
	//           ${photo ? `userAvatar:"${photo}"` : ''}
	//           userName:"${fullName}"
	//         }){
	//           inventories{
	//             id
	//             serialNo
	//             status {
	//               code
	//               name
	//             }
	//           }
	//         }
	//       }
	//     `,
	// 	url: env_var_REACT_APP_API_URL ?? '',
	// });

	const gqlquery = `
	mutation createInventory {
		createInventory(inventoryInput: {
			tag:"${addEqStates.tagId?.value}"
			${addEqStates?.eqType?.value ? `typeId:"${typeId}"` : ''}
			${addEqStates?.serialNo?.value ? `serialNo:"${addEqStates?.serialNo?.value}"` : ''}
			${addEqStates?.status?.value ? `statusId:"${statusIdFromCode}"` : ''}
			${dateFormattedexpDate ? `expiryDate:"${dateFormattedexpDate}"` : ''}
			${dateFormattedmfgDate ? `manufactureDate:"${dateFormattedmfgDate}"` : ''}
			${addEqStates?.inputRegNo?.value ? `fleetId:"${fleetId}"` : ''}
			${addEqStates?.locationType?.value ? `locationTypeId:"${locationTypeId}"` : ''}
			${addEqStates?.locationDetailsId?.value ? `seatNo:"${addEqStates?.locationDetailsId?.value}"` : ''}
			aocCode:"${code}"
			${photo ? `userAvatar:"${photo}"` : ''}
			userName:"${fullName}"
		}) {
		  _id
		  tag
		  serialNo
		}
	  }
	`

	const dataAdv = useMutateGQL({
		gqlquery,
		url: env_var_REACT_APP_API_URL_ADV ?? '',
	});

	return dataAdv
}

/**
 * @description Status : pending
 */
export async function replaceLocation(tag, locationDetailsId, fleetId, fullname, photo) {
	const inventoryId = await getInventoriesByTag(tag);
	const url = env_var_REACT_APP_API_URL ?? '';
	const queryStr = `
    mutation updateInvs {
        updateInventories (input:{
          id:"${inventoryId}",
          locationDetailsId:"${locationDetailsId}",
          fleetId:"${fleetId}",
          userName:"${fullname}",
          ${photo ? `userAvatar:"${photo}"` : ''}
        }){
          inventories{
            id
          }
        }
      }
    `;
	const result = await axiosGraphqlRequest(url, queryStr);
	return result;
}

/**
 * @description Status : pending
 */
export async function getInventoriesByTag(tag) {
	const url = env_var_REACT_APP_API_URL ?? '';
	const queryStr = `
      query inventory {
        allInventories(tag:"${tag}"){
          edges{
            node{
                id
                tag
                serialNo
            }
          }
        }
      }
    `;
	const result = await axiosGraphqlRequest(url, queryStr);
	const inventoryId = result?.data?.data?.allInventories?.edges[0]?.node?.id ?? undefined;
	return inventoryId;
}

/**
 * @description Status : migrated! - not backward compatible
 */
export function useUpdateInventory({
	invId,
	serialNo,
	eqType,
	status,
	fleetId,
	inputLocNo,
	inputLocType,
	mfgDate,
	expDate,
	seatNo,
}) {

	const allItemStatuses = useGetAllItemStatuses();
	const allInventoryTypes = useGetAllInventoryTypes();
	const allLocationTypes = useGetAllLocationTypes();

	const statusIdFromCode = allItemStatuses?.data?.find((node) => {
		const { id, name, code, } = node ?? {};
		return code === status
	})?._id

	const typeId = allInventoryTypes?.data?.find((item) => item?.code === eqType)?._id

	const locationTypeId = allLocationTypes?.data?.find((item) => {
		return item?.code === inputLocType
	})?.id

	const { userProfile } = useAppSelector((state) => state.userEntity);
	const fullName = userProfile?.fullName;
	const photo = userProfile?.photo;

	// const data = useMutateGQL({
	// 	gqlquery: () => {
	// 		const dateFormattedmfgDate = mfgDate ? `${new Date(mfgDate).getFullYear()}-${getMonth2D(new Date(mfgDate))}-${getDate2D(new Date(mfgDate))}` : undefined;
	// 		const dateFormattedexpDate = expDate ? `${new Date(expDate).getFullYear()}-${getMonth2D(new Date(expDate))}-${getDate2D(new Date(expDate))}` : undefined;
	// 		return `
	//           mutation updateEQ {
	//             updateInventories (input:{
	//               id:"${invId ?? ""}"
	//               ${serialNo?.value ? `serialNo:"${serialNo?.value}"` : ''}
	//               ${eqType?.value ? `item:"${eqType?.value}"` : ''}
	//               ${status?.value ? `status:"${statusIdFromCode}"` : ''}
	//               ${mfgDate ? `manufactureDate:"${dateFormattedmfgDate}"` : ''}
	//               ${expDate ? `expiryDate:"${dateFormattedexpDate}"` : ''}
	//               ${fleetId?.value ? `fleetId:"${fleetId?.value}"` : ''}
	//               ${inputLocNo?.value ? `locationDetailsId:"${inputLocNo?.value}"` : ''}
	//               ${photo ? `userAvatar:"${photo}"` : ''}
	//               userName:"${fullName}"
	//             }){
	//               inventories {
	//                 id
	//                 serialNo
	//                 item{
	//                   name
	//                 }
	//                 status {
	//                   name
	//                 }
	//                 manufactureDate
	//                 expiryDate
	//               }
	//             }
	//           }
	//         `;
	// 	},
	// 	url: env_var_REACT_APP_API_URL ?? ""
	// });
	const dataAdv = useMutateGQL({
		gqlquery: () => {
			const dateFormattedmfgDate = mfgDate ? `${new Date(mfgDate).getFullYear()}-${getMonth2D(new Date(mfgDate))}-${getDate2D(new Date(mfgDate))}` : undefined;
			const dateFormattedexpDate = expDate ? `${new Date(expDate).getFullYear()}-${getMonth2D(new Date(expDate))}-${getDate2D(new Date(expDate))}` : undefined;
			return `
              mutation updateInventory {
                updateInventory (
					inventoryId: "${invId}", 
					inventoryInput: {
						# tag:"", tag is not updatable
						${eqType ? `typeId:"${typeId}"` : ''}
						${serialNo ? `serialNo:"${serialNo}"` : ''}
						${status ? `statusId:"${statusIdFromCode}"` : ''}
						${expDate ? `expiryDate:"${dateFormattedexpDate}"` : ''}
						${mfgDate ? `manufactureDate:"${dateFormattedmfgDate}"` : ''}
						${fleetId?.value ? `fleetId:"${fleetId}"` : ''}

						${inputLocType ? `locationTypeId:"${locationTypeId}"` : ''}
						${seatNo ? `seatNo:"${seatNo}"` : ''}

						# ${inputLocNo?.value ? `locationDetailsId:"${inputLocNo?.value}"` : ''}

						${photo ? `userAvatar:"${photo}"` : ''}
						userName:"${fullName}"
					}
				){
					_id
					serialNo
					tag
				  }
              }
            `;
		},
		url: env_var_REACT_APP_API_URL_ADV ?? ""
	});

	return dataAdv
}

/**
 * @description Status : pending
 */
export function useDeleteInventory({ invId }) {

	// { mutate: mutateDelete }
	const data = useMutateGQL({
		gqlquery: `
		mutation deleteEQ {
			deleteInventories (input:{
			  id:"${invId ?? ""}"
			}){success}
		  }
		`,
		url: env_var_REACT_APP_API_URL ?? "",
	});

	return data
}

/**
 * @description Status : migrating...
 * @deprecated?
 */
export function useGetAllInvByACRegNo({ acRegNo }) {

	const data = useGetGQL({
		gqlquery: `
		query allitem {
			allInventories (multipleAircraftRegno:"${acRegNo}") {
				totalCount
				edges {
					node {
						id
						tag
						serialNo
						item {
							id
							code
							name
						}
						locationlistSet {
							edges {
								node {
									locationId {
										seatNo
									}
								}
							}
						}
						status {
							name
						}
					}
				}
		  	}
		}
		`,
		querySign: 'allInventories',
		url: env_var_REACT_APP_API_URL ?? "",
		isEdges: true,
	});

	const dataAdv = useGetGQL({
		gqlquery: `
		query allInventories{
			allInventories(
			  # searchInventory:"",
			  # multipleItemStatusId:[],
			  # multipleEqTypeId:[],
			  # multipleAircraftRegNo:["${acRegNo}"],
			  multipleAircraftRegNo:["9M-AFD"],
			  # multipleAircraftModel:[]
			){
			  items{
				id
				tag
				serialNo
				haveLocation
				model
				regNo
				seatNo
				type {
					_id
					name
					code
				}
				status {
					_id
					name
					code
				}
			  }
			}
		  }
		`,
		querySign: 'allInventories',
		url: env_var_REACT_APP_API_URL_ADV ?? "",
		isEdges: false,
	});

	function convertNewToOld(dataAdv) {
		const converted = {
			...dataAdv,
			data: dataAdv?.items?.map((item) => {
				return {
					node: {
						...item
					}
				}
			})
		}
		return converted;
	}

	// console.log({
	// 	dataAdv,
	// 	data,
	// 	converted: convertNewToOld(dataAdv),
	// })

	return data
}

/**
 * @description Status : migrated!
 */
export function useGetInventory({ inventoryId }) {


	const dataAdv = useGetGQL({
		url: env_var_REACT_APP_API_URL_ADV ?? "",
		gqlquery: inventoryId ? `
        query inventory {
			inventory(inventoryId:"${inventoryId}"){
					_id
				aocCode
				tag
				serialNo
				expiryDate
				manufactureDate
				status {
				  _id
				  name
				  code
				}
				type {
				  _id
				  name
				  code
				}
			}
		  }
        ` : "",
		querySign: 'inventory',
		isEdges: false,
	});

	return {
		...dataAdv,
		data: {
			...dataAdv?.data,
			id: dataAdv?.data?._id,
		}
	}
}