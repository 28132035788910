import { styled, Tooltip, tooltipClasses } from "@mui/material";

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#FFFEE8",
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.08)",
      fontSize: 11,
    },
  })
);

export default LightTooltip;
