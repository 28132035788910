export default function NoItemHere() {
	return (
		<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
			<svg
				width="268"
				height="250"
				viewBox="0 0 268 250"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g clip-path="url(#clip0_19802_207111)">
					<path
						d="M40.8328 112.531C37.4925 112.585 34.8526 117.61 39.7014 120.257C43.9038 122.58 50.4767 112.315 40.8328 112.531Z"
						fill="#E1ECFF"
					/>
					<path
						d="M46.2204 132.467C41.6409 135.655 18.8513 137.384 13.0326 135.979C9.53066 135.168 7.42948 132.467 8.02212 129.657C8.39926 127.928 9.69229 126.199 12.2245 124.687C12.44 124.579 12.6016 124.47 12.8171 124.362C15.4032 122.958 3.65814 124.687 0.748822 120.364C0.156183 119.446 -0.220951 118.527 -0.220951 117.609C-0.436456 114.043 3.65814 110.477 13.8946 109.396C26.8788 108.045 35.9839 108.802 34.3137 114.583C32.6436 120.418 32.1587 121.607 38.4083 123.174C42.5568 124.2 46.6514 126.578 47.5673 128.955C48.1061 130.144 47.7828 131.386 46.2204 132.467Z"
						fill="#E1ECFF"
					/>
					<path
						d="M18.3127 122.85C17.4507 123.931 14.6492 124.363 12.2247 124.687C12.4402 124.579 12.6019 124.471 12.8174 124.363C15.4034 122.958 3.65839 124.687 0.74907 120.365C0.15643 119.446 -0.220703 118.528 -0.220703 117.609C0.371936 118.582 1.82659 119.717 5.27468 120.203C11.9553 121.175 19.6596 121.229 18.3127 122.85Z"
						fill="#CAE0FF"
					/>
					<path
						d="M46.2202 132.469C41.6407 135.657 18.8511 137.386 13.0324 135.981C9.53048 135.17 7.4293 132.469 8.02194 129.659C9.09947 130.902 12.1165 133.225 19.8208 133.171C30.1112 133.063 42.9338 132.631 45.9508 130.848C46.8667 130.308 47.3516 129.659 47.621 128.957C48.1059 130.146 47.7826 131.388 46.2202 132.469Z"
						fill="#CAE0FF"
					/>
					<path
						d="M228.645 130.086C228.376 131.059 227.676 132.085 226.383 133.112C221.049 137.488 210.435 135.273 207.257 136.246C206.341 136.516 206.125 137.002 206.341 137.596C206.933 139.109 210.435 141.216 213.506 142.081C218.894 143.594 221.642 146.079 222.665 148.51C224.228 152.238 221.803 155.912 218.301 156.291C212.536 156.939 196.589 153.643 189.046 155.642C181.504 157.641 177.086 158.83 170.675 155.264C166.095 152.725 164.748 148.618 165.718 145.269C166.095 143.918 166.795 142.729 167.765 141.757C171.267 138.299 178.109 140.676 178.702 138.893C179.295 137.11 174.446 131.491 177.247 126.953C180.049 122.414 186.029 121.82 193.356 123.062C200.684 124.305 215.661 122.9 221.372 123.819C225.79 124.575 229.507 127.007 228.645 130.086Z"
						fill="#E1ECFF"
					/>
					<path
						d="M225.359 137.22C221.157 137.274 219.217 140.462 222.503 143.271C225.79 146.081 231.447 143.596 231.285 141.218C231.124 138.841 228.16 137.166 225.359 137.22Z"
						fill="#E1ECFF"
					/>
					<path
						d="M218.301 156.346C212.536 156.995 196.589 153.699 189.046 155.698C181.504 157.697 177.086 158.886 170.675 155.32C166.095 152.78 164.748 148.674 165.718 145.324C166.041 147.269 167.496 149.971 172.56 151.754C181.288 154.833 187.053 150.349 194.757 150.349C202.462 150.349 210.651 155.266 218.624 153.429C222.18 152.618 222.935 150.565 222.665 148.512C224.228 152.294 221.803 155.968 218.301 156.346Z"
						fill="#CAE0FF"
					/>
					<path
						d="M228.645 130.086C228.376 131.058 227.676 132.085 226.383 133.112C221.049 137.488 210.435 135.273 207.256 136.245C206.341 136.515 206.125 137.002 206.341 137.596C205.91 137.488 205.479 137.326 205.101 137.218C201.599 135.975 200.899 133.814 204.563 132.571C208.226 131.329 214.799 132.733 222.557 131.437C225.09 131.058 227.137 130.572 228.645 130.086Z"
						fill="#CAE0FF"
					/>
					<path
						d="M134.578 87.3516L125.526 198.545C125.473 198.977 125.203 199.355 124.772 199.409C124.072 199.517 123.371 198.923 123.425 198.167L132.207 86.9734C132.261 86.4871 132.638 86.1089 133.177 86.1089H133.446C134.039 86.0549 134.632 86.6492 134.578 87.3516Z"
						fill="#172B4D"
					/>
					<path
						d="M162.593 91.1892C161.246 94.9173 159.845 98.4292 158.445 101.725C152.626 115.557 146.7 125.93 141.851 133.224C137.056 140.41 133.392 144.571 131.938 146.083C131.56 146.516 131.291 146.732 131.291 146.732C131.291 146.732 130.375 147.056 128.705 147.596C125.365 148.623 119.223 150.352 112.219 151.324C110.818 151.54 109.363 151.703 107.963 151.811C102.737 152.243 97.2951 152.189 92.3385 151.054C90.4528 150.622 88.621 150.028 86.897 149.217C86.8431 149.217 86.7892 149.163 86.7354 149.163C86.7354 149.163 81.8865 144.841 75.3136 137.385C65.993 126.849 53.1704 110.046 45.6816 90.2167C44.7657 87.8394 44.0115 85.408 43.3111 82.9226C34.9064 52.9901 41.6409 15.6015 82.1559 3.28274C86.4121 1.98603 91.0994 0.959459 96.1637 0.257072C97.349 0.0949829 98.5343 -0.0671048 99.6657 -0.121135C102.198 -0.391284 104.676 -0.445312 107.047 -0.445312C114.266 -0.337253 120.839 0.851402 126.712 3.06662C161.246 15.7096 173.907 60.1221 162.593 91.1892Z"
						fill="#198FCF"
					/>
					<path
						d="M158.444 101.724C145.245 133.17 131.345 146.731 131.345 146.731C131.345 146.731 130.429 147.055 128.759 147.596C125.418 148.622 119.276 150.351 112.273 151.324C110.872 151.54 109.417 151.702 108.016 151.81C102.79 152.242 97.3488 152.188 92.3922 151.053C90.4526 150.567 88.5131 149.973 86.789 149.162C86.789 149.162 58.5579 124.093 45.7353 90.2159C44.8194 87.8386 44.0651 85.4072 43.3647 82.9219C53.9784 92.4311 74.5052 105.398 108.07 106.209C133.715 106.749 149.232 104.318 158.444 101.724Z"
						fill="#46ADE4"
					/>
					<path
						d="M107.963 151.757C102.737 152.189 97.2952 152.135 92.3386 151C85.3347 139.114 76.1757 121.014 69.9799 99.0236C68.2559 92.9182 66.7474 86.5427 65.5621 79.897C59.2047 43.589 71.7579 17.6547 82.1021 3.28278C86.3583 1.98607 91.0456 0.9595 96.1099 0.257113C97.2952 0.0950237 98.4805 -0.067064 99.6119 -0.121094C95.1402 6.36248 84.8498 25.5431 87.2742 62.4454C88.1901 76.3851 90.8839 91.1892 94.1704 105.129C98.4805 123.553 103.976 140.464 107.963 151.757Z"
						fill="#A3E0FF"
					/>
					<path
						d="M147.293 70.0629C146.969 82.5438 145.353 94.1602 143.09 104.696C139.265 122.526 133.554 137.222 128.705 147.542C125.365 148.568 119.223 150.297 112.219 151.27C115.559 141.004 120.031 124.903 122.456 106.155C123.856 95.1327 124.557 83.1381 123.856 70.9814C121.971 38.2934 112.65 12.5212 106.993 -0.5C114.213 -0.39194 120.785 0.796715 126.658 3.01194C137.11 17.8701 148.155 40.6167 147.293 70.0629Z"
						fill="#124F88"
					/>
					<path
						d="M107.963 151.757C102.737 152.189 97.2953 152.135 92.3386 151C85.3347 139.114 76.1758 121.014 69.98 99.0234C76.8761 101.671 84.9037 103.886 94.1166 105.129C98.4805 123.553 103.976 140.464 107.963 151.757Z"
						fill="#B4E4FD"
					/>
					<path
						d="M143.09 104.695C139.265 122.525 133.554 137.221 128.705 147.541C125.365 148.567 119.223 150.296 112.219 151.269C115.559 141.003 120.031 124.902 122.455 106.154C130.591 105.992 137.433 105.452 143.09 104.695Z"
						fill="#2C659A"
					/>
					<path
						d="M141.851 133.172C137.056 140.358 133.392 144.519 131.937 146.031C127.412 144.032 119.923 141.547 110.441 141.925C100.42 142.303 91.8533 146.247 86.9506 149.111C86.8967 149.111 86.8428 149.057 86.789 149.057C86.789 149.057 81.9401 144.735 75.3672 137.279C80.0005 133.01 90.9374 125.122 109.471 124.365C125.634 123.771 136.247 129.174 141.851 133.172Z"
						fill="#3D6F9E"
					/>
					<path
						d="M86.897 149.162C86.897 149.162 95.7866 143.219 109.202 141.976C122.617 140.734 131.938 146.083 131.938 146.083C131.938 146.083 132.045 149 109.633 151.864C92.7156 153.971 86.897 149.162 86.897 149.162Z"
						fill="#124F88"
					/>
					<path
						d="M157.259 50.7227C157.259 50.7227 155.912 56.3417 156.828 61.2044C157.744 66.1211 159.953 68.5525 159.953 68.5525C159.953 68.5525 160.654 65.6889 159.845 59.9617C158.983 54.2886 157.259 50.7227 157.259 50.7227Z"
						fill="#0AAEFF"
					/>
					<path
						d="M47.5677 92.7564L103.437 206.327C103.653 206.759 103.545 207.3 103.168 207.624L103.006 207.786C102.468 208.272 101.659 208.11 101.336 207.462L45.3049 93.9451C45.0355 93.4048 45.251 92.8105 45.7359 92.5403L46.113 92.3242C46.6518 92 47.2983 92.2161 47.5677 92.7564Z"
						fill="#172B4D"
					/>
					<path
						d="M81.4019 89.999L112.057 202.867C112.165 203.299 112.004 203.786 111.573 204.11L111.411 204.272C110.764 204.758 109.956 204.596 109.795 203.894L78.9236 91.0796C78.762 90.5934 79.0852 89.945 79.624 89.6749L80.0011 89.4587C80.5938 89.1886 81.2403 89.4047 81.4019 89.999Z"
						fill="#172B4D"
					/>
					<path
						d="M162.809 85.9488L126.442 201.465C126.281 201.951 126.496 202.491 126.927 202.707L127.574 203.031C128.166 203.356 128.867 203.085 129.082 202.437L165.718 86.9754C165.88 86.4351 165.61 85.8948 165.125 85.6787L164.263 85.3005C163.671 85.0844 163.024 85.3545 162.809 85.9488Z"
						fill="#172B4D"
					/>
					<path
						d="M82.4793 170.126C82.2638 169.262 82.9642 168.505 83.8262 168.559C88.4057 168.776 101.282 169.208 114.805 166.939C127.628 164.831 136.733 162.13 140.342 160.995C141.204 160.725 142.066 161.319 142.12 162.238C142.12 162.778 141.743 163.319 141.204 163.481C138.026 164.453 127.197 167.695 114.643 169.694C101.767 171.747 87.6514 171.423 83.7185 171.261C83.1258 171.261 82.5871 170.829 82.4793 170.234V170.126Z"
						fill="#172B4D"
					/>
					<path
						d="M46.7592 94.6993L164.371 87.8376C165.664 87.7835 166.688 86.7029 166.742 85.4062V85.1901C166.796 83.6773 165.556 82.4886 164.102 82.5967L46.4898 89.7286C44.9274 89.8367 43.8499 91.2955 44.1732 92.8083C44.4425 93.9429 45.5201 94.8074 46.7592 94.6993Z"
						fill="#172B4D"
					/>
					<path
						d="M133.015 182.445C133.015 182.445 141.097 184.498 145.083 186.66C149.07 188.875 146.43 194.764 142.821 194.116C140.235 193.629 136.463 187.686 135.116 185.849C133.769 184.066 133.015 182.445 133.015 182.445Z"
						fill="#F5F7FB"
					/>
					<path
						d="M147.023 218.537L116.152 230.531L111.734 232.26C106.831 234.151 101.605 232.368 98.696 228.532C97.4568 226.857 96.6487 224.804 96.4871 222.535L94.5475 195.79C94.4398 193.737 95.6789 191.9 97.6723 191.306L105.969 188.82L129.244 181.851C131.183 181.256 133.231 182.121 134.254 183.904L134.416 184.228L149.717 211.135C149.717 211.189 149.771 211.243 149.771 211.297C151.171 213.998 149.932 217.402 147.023 218.537Z"
						fill="#198FCF"
					/>
					<path
						d="M116.152 230.531L111.734 232.26C106.831 234.151 101.605 232.368 98.6958 228.532C97.4567 226.857 96.6485 224.804 96.4869 222.535L94.7629 198.654L94.5474 195.79C94.5474 195.682 94.5474 195.52 94.5474 195.358C94.6012 193.467 95.8404 191.846 97.6722 191.306L105.969 188.82C106.077 189.631 106.239 190.495 106.4 191.414C106.616 192.656 106.831 194.007 107.047 195.412C108.394 203.624 110.171 214.322 111.249 221.292C111.626 223.778 112.273 225.669 113.081 227.127C113.943 228.802 115.02 229.883 116.152 230.531Z"
						fill="#A3E0FF"
					/>
					<path
						d="M106.993 195.463C106.023 197.084 104.03 199.569 100.42 200.001C97.834 200.325 95.9483 199.569 94.7092 198.705L94.4937 195.841C94.4937 195.733 94.4937 195.571 94.4937 195.409C94.5475 193.518 95.7867 191.897 97.6185 191.356L105.915 188.871C106.023 189.682 106.185 190.546 106.346 191.465C106.508 192.653 106.777 194.004 106.993 195.463Z"
						fill="#088FD1"
					/>
					<path
						d="M124.826 196.6C114.59 199.41 106.993 195.411 106.993 195.411L105.916 188.766L129.19 181.796C131.13 181.201 133.177 182.066 134.201 183.849L134.362 184.173L134.47 184.767C134.524 184.821 135.063 193.79 124.826 196.6Z"
						fill="#172B4D"
					/>
					<path
						d="M105.915 188.82L106.346 191.305C106.346 191.305 113.996 198.059 124.125 194.871C134.308 191.738 132.745 182.337 132.745 182.337C132.745 182.337 131.614 181.742 130.537 181.742C129.459 181.742 127.304 182.445 127.304 182.445L105.915 188.82Z"
						fill="#124F88"
					/>
					<path
						d="M106.346 191.414C106.131 192.278 105.053 196.493 101.228 197.789C97.4568 199.086 94.9785 196.006 94.4937 195.358C94.5475 193.467 95.7867 191.846 97.6185 191.306L105.915 188.82C106.023 189.631 106.185 190.495 106.346 191.414Z"
						fill="#124F88"
					/>
					<path
						d="M147.023 218.537L116.152 230.531L111.734 232.26C106.831 234.151 101.605 232.368 98.6958 228.532C104.568 228.911 109.202 228.046 113.835 226.966C118.899 225.723 135.17 219.726 142.928 215.727C146.107 214.052 148.316 212.54 149.824 211.297C151.171 213.998 149.932 217.402 147.023 218.537Z"
						fill="#191E26"
					/>
					<path
						d="M116.152 230.529L111.734 232.258C106.831 234.149 101.605 232.366 98.6958 228.53C104.245 228.908 108.663 228.152 113.081 227.125C113.943 228.8 115.02 229.881 116.152 230.529Z"
						fill="#088FD1"
					/>
					<path
						d="M111.465 158.509C106.293 159.157 102.413 154.564 105.592 150.836C108.717 147.108 109.364 143.812 109.364 143.812C109.364 143.812 115.721 147.378 116.529 151.323C117.122 154.348 116.691 157.86 111.465 158.509Z"
						fill="#088FD1"
					/>
					<path
						d="M111.088 157.159C109.04 157.267 106.885 156.24 107.263 153.809C107.64 151.378 109.795 150.459 110.226 148.838C110.657 147.217 110.226 146.461 110.226 146.461C110.226 146.461 113.997 149.162 114.428 152.728C114.805 156.294 112.489 157.105 111.088 157.159Z"
						fill="#74C4E8"
					/>
					<path
						d="M103.599 161.426L103.329 158.941C103.275 158.238 103.706 157.644 104.353 157.482C105.592 157.212 107.855 156.779 111.249 156.563C114.32 156.347 116.26 156.347 117.337 156.401C117.984 156.401 118.576 156.941 118.63 157.59L118.953 160.021C119.007 160.615 118.684 161.21 118.091 161.48C116.96 161.912 114.697 162.506 110.98 162.723C107.693 162.885 105.808 162.831 104.784 162.723C104.191 162.615 103.653 162.074 103.599 161.426Z"
						fill="#124F88"
					/>
					<path
						d="M150.04 185.468C150.04 185.468 161.3 174.878 165.287 175.256C167.658 175.472 165.072 178.606 161.623 180.659C158.175 182.712 150.04 185.468 150.04 185.468Z"
						fill="#088FD1"
					/>
					<path
						d="M152.572 189.195C151.171 188.979 151.979 186.926 153.596 186.926C155.212 186.926 209.842 187.574 208.603 188.06C207.418 188.601 154.027 189.357 152.572 189.195Z"
						fill="#088FD1"
					/>
					<path
						d="M172.722 69.9552L180.157 71.468C180.911 71.6301 181.396 72.3325 181.234 73.1429C181.073 73.8994 180.265 73.9534 179.51 73.7913C179.349 73.7373 179.241 73.6832 179.079 73.6292L172.291 70.9277C172.022 70.7656 171.968 70.4955 172.075 70.2253C172.237 70.0092 172.506 69.9011 172.722 69.9552Z"
						fill="#088FD1"
					/>
					<path
						d="M171.321 74.9254L177.517 80.3824C178.056 80.8147 178.056 81.6251 177.625 82.1654C177.194 82.7057 176.386 82.7057 175.847 82.2735C175.793 82.2195 175.739 82.1654 175.685 82.1114L170.621 75.5738C170.459 75.3577 170.513 75.0335 170.729 74.8714C170.89 74.7633 171.16 74.8174 171.321 74.9254Z"
						fill="#088FD1"
					/>
					<path
						d="M164.048 35.6478L165.664 29.2723C165.879 28.624 166.58 28.2998 167.226 28.5159C167.873 28.732 168.196 29.4344 167.981 30.0828C167.981 30.1368 167.927 30.1908 167.927 30.1908L165.125 36.0801C165.017 36.3502 164.64 36.5123 164.371 36.3502C164.102 36.2422 163.994 35.918 164.048 35.6478Z"
						fill="#088FD1"
					/>
					<path
						d="M166.795 40.728L177.301 34.5145C177.894 34.1363 178.648 34.3524 178.971 34.8927C179.348 35.4871 179.133 36.2435 178.594 36.5677C178.54 36.6217 178.486 36.6217 178.433 36.6217L167.226 41.5924C166.957 41.7005 166.634 41.5924 166.526 41.3223C166.526 41.1602 166.58 40.8901 166.795 40.728Z"
						fill="#088FD1"
					/>
					<path
						d="M168.735 47.5911L176.763 45.538C177.409 45.3759 178.056 45.7001 178.217 46.2404C178.379 46.7806 178.002 47.375 177.355 47.5911C177.302 47.5911 177.194 47.6451 177.14 47.6451L168.897 48.3475C168.681 48.3475 168.466 48.2395 168.466 48.0233C168.412 47.8072 168.52 47.6451 168.735 47.5911Z"
						fill="#088FD1"
					/>
					<path
						d="M200.683 58.9324C184.521 64.4975 161.569 60.0671 161.569 60.0671C159.845 59.7969 159.468 59.2566 159.737 58.6623C160.168 57.7978 162.162 56.7713 163.724 56.393C168.088 55.3665 174.715 54.7721 179.941 51.5844C185.221 48.3966 179.941 43.3178 183.174 40.022C186.406 36.7262 191.147 40.4002 193.572 36.9423C195.835 33.7005 185.867 29.3781 187.107 25.542C188.346 21.7059 192.171 21.7059 192.386 17.4376C192.548 13.8716 201.492 5.82116 208.495 10.7919C209.896 11.7644 211.189 13.2773 212.321 15.3844C219.378 28.3516 216.846 53.3133 200.683 58.9324Z"
						fill="#E1ECFF"
					/>
					<path
						d="M200.683 58.9296C184.521 64.4947 161.569 60.0642 161.569 60.0642C159.845 59.7941 159.468 59.2538 159.737 58.6595C164.317 58.7135 177.84 58.8215 183.766 57.0926C190.932 54.9854 190.231 50.7711 188.831 50.0687C187.43 49.3663 186.298 49.7986 186.298 49.7986C186.298 49.7986 186.837 47.2592 191.902 48.2317C196.858 49.2042 195.888 53.2565 195.835 53.4186C196.104 53.2565 205.425 48.2857 206.34 43.9634C207.31 39.5869 201.168 35.2105 204.078 32.1308C207.041 29.0511 208.98 30.9962 209.842 32.5631C210.704 34.1299 210.812 34.6702 210.812 34.6702C210.812 34.6702 211.944 30.1858 211.351 22.4055C210.92 16.6783 209.304 12.572 208.442 10.7891C209.842 11.7616 211.135 13.2744 212.267 15.3816C219.378 28.3487 216.846 53.3105 200.683 58.9296Z"
						fill="#CAE0FF"
					/>
					<path
						d="M268.083 55.7456C268.675 61.905 225.089 60.5543 199.929 60.1761C174.769 59.7979 165.448 59.7439 165.825 57.7988C165.879 57.5286 166.31 57.2045 167.011 56.8803C171.105 55.0433 184.951 52.666 193.356 49.046C203.216 44.8316 204.724 29.0009 220.294 29.217C228.699 29.3251 226.813 38.8884 227.46 40.9415C228.106 42.9406 233.656 30.6758 241.899 33.2693C250.142 35.8627 241.144 48.0734 242.761 49.1C244.323 50.0725 265.443 49.6403 267.867 54.9892C267.975 55.2054 268.029 55.4755 268.083 55.7456Z"
						fill="#E1ECFF"
					/>
					<path
						d="M268.083 55.7464C268.675 61.9058 225.089 60.555 199.929 60.1768C174.769 59.7986 165.448 59.7446 165.825 57.7995C165.879 57.5294 166.31 57.2052 167.011 56.881C174.338 56.6649 202.785 55.6383 210.219 54.3956C218.786 52.9909 215.284 47.3718 215.284 47.3718C215.284 47.3718 219.378 46.9395 220.456 50.0733C221.587 53.153 218.894 56.5568 218.894 56.5568C218.894 56.5568 251.65 57.5294 261.725 56.827C264.85 56.6109 266.736 55.9085 267.813 54.99C267.975 55.2061 268.029 55.4762 268.083 55.7464Z"
						fill="#CAE0FF"
					/>
					<path
						d="M224.281 68.1172C224.12 68.8736 223.85 69.7381 223.419 70.6026C219.702 78.707 202.3 69.576 201.923 69.3599C202.192 69.576 208.226 75.2491 201.653 78.2208C194.973 81.2464 192.602 75.7894 182.904 69.1978C173.153 62.5521 160.222 61.5256 160.222 61.5256C159.145 61.5256 158.391 61.0933 158.014 60.499C157.259 59.2563 158.391 57.3112 162.809 57.5814C165.233 57.7435 206.017 56.825 212.752 57.7975C218.84 58.662 225.574 61.6336 224.281 68.1172Z"
						fill="#E1ECFF"
					/>
					<path
						d="M224.281 68.1168C224.12 68.8732 223.85 69.7377 223.419 70.6022C219.702 78.7067 202.3 69.5756 201.923 69.3595C202.192 69.5756 208.226 75.2488 201.653 78.2204C194.973 81.2461 192.602 75.7891 182.905 69.1974C173.153 62.5518 160.223 61.5252 160.223 61.5252C159.145 61.5252 158.391 61.0929 158.014 60.4986C160.869 60.2825 170.19 60.1204 180.049 65.3613C191.956 71.6288 194.919 76.1673 198.583 73.7359C202.138 71.3586 197.774 66.0097 197.559 65.7395C197.72 65.7935 200.953 67.0903 209.25 68.9273C216.308 70.4941 221.534 69.8998 224.281 68.1168Z"
						fill="#CAE0FF"
					/>
				</g>
				<defs>
					<clipPath id="clip0_19802_207111">
						<rect width="268" height="250" fill="white" />
					</clipPath>
				</defs>
			</svg>
			<Box sx={{ marginTop: "32px", }}>
				<Typography fontSize={commonFontSizes.l} fontWeight={"bold"}>
					No equipment assigned
				</Typography>
				<Typography
					fontSize={commonFontSizes.s}
					sx={{ marginTop: "4px" }}
				>
					No equipment assigned to this seat/location.
					<br />
					Go to{" "}
					<a
						style={{
							textDecoration: "none",
							cursor: "pointer",
							color: "#00A8E8",
							fontFamily: gothamMedium,
						}}
					>
						Cabin Inventory
					</a>{" "}
					to assign an equipment
				</Typography>
			</Box>
		</div>
	);
}