import AccessDenied from "common/images/Access-Denied.svg";
import firebase from "firebase/app";
import React, { useRef, useState } from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { Link, useHistory, useLocation } from "react-router-dom";
import { app } from "../../firebase";
import styles from "./Login.module.css";

// import { app } from "../../firebase";

// import basketImage from "../../images/basket-image.png"

const Login = () => {
  // const history = useHistory();
  // const emailRef = useRef();
  // const passwordRef = useRef();
  // const [alert, setAlert] = useState({ show: false, message: '' });
  // var from = "";

  // if (history.location.state) {
  //   from = history.location.state.from || "";
  // }

  // const handleSubmitSignup = async (e) => {

  //   setAlert({ show: false, message: '' });
  //   e.preventDefault();

  //   if (!passwordRef.current.value) {
  //     console.log("Password required");
  //   }

  //   try {
  //     await app.auth().signInWithEmailAndPassword(emailRef.current.value, passwordRef.current.value);

  //     if (from) {
  //       history.push(from);
  //     } else {
  //       history.push("/");
  //     }

  //   } catch (error) {
  //     if (
  //       error.code === "auth/user-not-found" ||
  //       error.code === "auth/wrong-password"
  //     ) {
  //       console.log("Invalid credentials");
  //     }

  //     if (error.code === "auth/user-disabled") {
  //       console.log("Unable to login");
  //     }

  //     setAlert({ show: true, message: error.message });
  //   }
  // };

  // return (
  //   <div
  //     className='d-flex align-items-center justify-content-center mb-5 large-screen-footer'
  //     style={{ minHeight: "65vh" }}
  //   >      <div className='w-100' style={{ maxWidth: "400px" }}>
  //       {
  //         alert.show ? (
  //           <div className="alert alert-danger" role="alert">
  //             <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="mr-1 mb-1 bi bi-exclamation-triangle" viewBox="0 0 16 16">
  //               <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z" />
  //               <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z" />
  //             </svg>
  //             {alert.message}
  //           </div>
  //         ) : ''
  //       }
  //       <div className='d-flex align-items-center justify-content-center mt-5'>
  //         <img src={basketImage} alt='Be Ready' width='400px' />
  //       </div>
  //       <hr className='mb-0' />
  //       <div
  //         className='d-flex align-items-center justify-content-center mb-5 large-screen-footer'
  //         style={{ minHeight: "65vh" }}
  //       >
  //         <div className='w-100' style={{ maxWidth: "450px" }}>
  //           <p className='mb-0'><b>Beta-tester Login (by Invite only)</b></p>
  //           <div className='card'>
  //             <div className='card-body' style={{ height: '400px' }}>
  //               <h2 className='mb-4 text-center'>Log in</h2>
  //               <form onSubmit={handleSubmitSignup} className='form-login'>
  //                 <div className='form-group'>
  //                   <label htmlFor='email'>Email</label>
  //                   <input
  //                     className='form-control'
  //                     type='email'
  //                     id='email'
  //                     placeholder='Email'
  //                     ref={emailRef}
  //                   />
  //                 </div>
  //                 <div className='form-group'>
  //                   <label htmlFor='password'>Password</label>
  //                   <input
  //                     className='form-control'
  //                     type='password'
  //                     id='password'
  //                     placeholder='Password'
  //                     ref={passwordRef}
  //                   />
  //                 </div>

  //                 <button type='submit' className='btn btn-dark btn-block'>
  //                   Login
  //               </button>
  //               </form>
  //               <div className='w-100 text-right mt-3'>
  //                 <Link to='/forgot-password'>Forgot password?</Link>
  //               </div>

  //               <div className='w-100 text-center' style={{ marginTop: 30, color: 'grey' }}>
  //                 New to the site? <a style={{ color: '#007bff' }} target="_blank" href='https://docs.google.com/forms/d/e/1FAIpQLSecUQXzrwg_nUMpGycg-NfHp2QDxklo4-W4bBneBZx1MMIvvg/viewform'>Sign up now</a>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );

  const emailRef = useRef();
  const passwordRef = useRef();
  const location = useLocation();
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [alert, setAlert] = useState({ show: false, message: "" });
  const firebaseAuth = firebase.auth;
  const firebaseAuth_ = firebase.auth();
  var provider = new firebaseAuth.GoogleAuthProvider();

  const paramsString = location.search;
  let searchParams = new URLSearchParams(paramsString);
  let isTargetUrl = searchParams.has("target");
  let target = searchParams.get("target");

  const AUTHGS = process.env.REACT_APP_AUTH_GS_URL;

  // Redirect based on target if exist
  if (isTargetUrl) {
    localStorage.setItem("targetUrl", target);
  }

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Google SSO Login
  async function googleLoginViaSSO(e) {
    setAlert({ show: false, message: "" });
    e.preventDefault();
    try {
      await firebaseAuth_.signInWithRedirect(provider);
    } catch (error) {
      console.log(error);
    }
  }

  async function handleSubmitSignup(e) {
    setAlert({ show: false, message: "" });
    e.preventDefault();

    if (!emailRef.current.value || !passwordRef.current.value) {
      setAlert({ show: true, message: "Please enter email address and password." });
      return;
    }

    try {
      await app.auth().signInWithEmailAndPassword(emailRef.current.value, passwordRef.current.value);
      isTargetUrl ? history.push(target) : history.push("/");
      closeModal();
    } catch (error) {
      if (error.code === "auth/user-not-found" || error.code === "auth/wrong-password") {
        console.log("Invalid credentials");
      }

      if (error.code === "auth/user-disabled") {
        console.log("Unable to login");
      }
      setAlert({ show: true, message: error.message });
    }
  }

  return (
    <>
      <div className="">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <img src={AccessDenied} className={styles.accessDeniedImage} alt="icon" />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12" style={{ margin: "auto", textAlign: "center" }}>
            <p style={{ fontFamily: "Gotham-Bold", fontSize: "24px" }}>Please login to access this page</p>
            <p style={{ fontFamily: "Gotham-Book", fontSize: "16px" }}>
              The page that you are trying to access is restricted. Please{" "}
              <Link
                to={"#"}
                onClick={() => (window.location.href = AUTHGS)}
                style={{ textDecoration: "underline", color: "#2F77FF" }}
              >
                login
              </Link>{" "}
              or refer to your system administrator to view this page.
            </p>
          </div>
        </div>
      </div>
      <Modal
        open={isModalOpen}
        onClose={() => closeModal()}
        showCloseIcon={false}
        classNames={{
          modal: styles.adeLoginModal,
        }}
      >
        {alert.show ? (
          <div className={`alert alert-danger ${styles.adeAlertDanger}`} role="alert">
            <div className="row">
              <div className="col-1">
                <i className={`fas fa-alert-danger ${styles.adeFaAlertDanger}`}></i>
              </div>
              <div className="col">{alert.message}</div>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="form-login">
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              className={`form-control ${alert.show ? styles.inputError : ""}`}
              type="email"
              id="email"
              placeholder="Email"
              ref={emailRef}
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              className={`form-control ${alert.show ? styles.inputError : ""}`}
              type="password"
              id="password"
              placeholder="Password"
              ref={passwordRef}
            />
          </div>
          <div className="row">
            <div className="col-6"></div>
            <div className="col-6">
              <Link to="/forgot-password" onClick={closeModal}>
                Forgot password?
              </Link>
            </div>
          </div>
          <button onClick={handleSubmitSignup} className={`btn btn-block ${styles.adeLoginModalButton}`}>
            Login
          </button>
        </div>
        {/* <hr style={{ marginLeft: "-20px", width: "111%" }} />
        <div className="w-100 text-center">
          Need an account?{" "}
          <a
            style={{ color: "#007bff" }}
            target="_blank"
            href="https://docs.google.com/forms/d/183pEEPl2ZrdX2mSr2hnFwwAeSwjeP6UZ6wzsnRT5rA4/edit?ts=62d7a667"
          >
            Sign up
          </a>
        </div> */}
        <hr style={{ marginLeft: "-20px", width: "111%" }} />
        <div className="w-100 text-center">
          <Link onClick={googleLoginViaSSO} className={`${styles.staffAccessButton}`}>
            Staff Access
          </Link>
        </div>
      </Modal>
    </>
  );
};

export default Login;
