import React, { useRef, useState, useEffect } from "react";
import { AppBar, Grid, Button as MUIButton, styled, Fade } from "@mui/material";
import { ClickAwayListener } from "@mui/base";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./ElevadeFleetHeader.module.css";
// import "./Header.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useLocation } from "react-router-dom";
import ElevadeFleet from "common/images/icons/elevade/Fleet-logo.svg";
import Avatar from "react-avatar";
import Help from "common/images/icons/elevade/Help-icon.png";
import Legend from "../../pages/elevade/component/Legend";
import useMediaQuery from "@mui/material/useMediaQuery";
import HamburgerMenu from "common/images/Hamburger-Menu.svg";
import CloseMenu from "common/images/icons/elevade/Close-Icon.svg";
import Cookies from "js-cookie";
import { logOut } from "common/store/reducers/elevade/auth/thunks";
import { clearUserDetail } from "common/store/reducers/elevade/user/thunks";
import Airplane from "common/images/icons/elevade/Icon-Airplane-Tilt-Green.svg";
import ContactUs from "common/images/icons/elevade/Icon-ContactUs.svg";
import People from "common/images/icons/elevade/Icon-ElevadePeople.svg";
import Cart from "common/images/icons/elevade/Icon-Cart.svg";
import { hamburgerIcon } from "common/images/icons/elevade/fleetSidebar";
import { setSidebarToggle } from "common/store/reducers/elevade/sidebarToggle";
import { setAocToggle } from "common/store/reducers/elevade/aocToggle";
import IconSwap from "common/images/icons/elevade/Icon-Swap.svg";
import Boost from "common/images/icons/elevade/Icons-InfinityBlack.svg";
import DarkMode from "common/images/icons/elevade/Icon-DarkMode.svg";
import { setAOCBasedOnPermission } from "pages/elevade/component/Helpers";
import AdeSelect from "common/components/adeSelect/AdeSelect";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import IconLoginFleet from "common/images/icons/Icon-LoginFleet.svg";
import IconLoginPeople from "common/images/icons/Icon-LoginPeople.svg";
import IconArrowUpGreen from "common/images/icons/Icon-ArrowUpGreen.svg";
import IconADE from "common/images/icons/elevade/Icon-ADE.svg";
import MUIAvatar from "common/components/avatar/MUIAvatar";

const ElevadeFleetHeader = () => {
  const dispatch = useDispatch();

  const location = useLocation();
  const [showSolutionsMenu, setShowSolutionsMenu] = useState(false);
  const [openHelpModal, setOpenHelpModal] = useState(false);
  const [openOtherProductsModal, setOpenOtherProductsModal] = useState(false);
  const [openLegend, setOpenLegend] = useState(false);
  const myAccountRef = useRef(null);
  const mySolutionsRef = useRef(null);
  const myHelpRef = useRef(null);
  const myOtherProductsRef = useRef(null);
  const [closeIcon] = useState(false);
  const isWeb = useMediaQuery("(min-width:900px)");
  const { currentUser: userAuthEntity } = useSelector((state) => state.authEntity);
  const { companyAOC, userPermission } = useSelector((state) => state.userEntity);
  let AOC = companyAOC;
  AOC = setAOCBasedOnPermission(AOC, userPermission);
  const { companyProfile } = useSelector((state) => state.userEntity);
  const { sidebarToggle } = useSelector((state) => state.sidebarToggle);
  const cookies = Cookies.get("elevadeToken");
  const { aocToggle } = useSelector((state) => state.aocToggle);
  const [openLogin, setOpenLogin] = useState(false);
  const [openSolutions, setOpenSolutions] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const avatar = userAuthEntity.avatar || localStorage.getItem("avatar");
  const displayName = userAuthEntity.displaName || localStorage.getItem("displayName");
  const email = "mailto:elevade_support@ade.aero";

  // AUTH GS URL for PEOPLE Application
  const AUTH_GS_PEOPLE_URL = process.env.REACT_APP_AUTH_GS_PEOPLE;

  let aoclist = [];
  if (AOC) {
    aoclist = AOC.map((item) => {
      return { value: item, label: item };
    });
  }

  useEffect(() => {}, []);

  function useOutsideClicker(ref, component) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          if (component === "myAccountRef") setShowMenu(false);
          else if (component === "mySolutionsRef") setShowSolutionsMenu(false);
          else if (component === "myHelpRef") setOpenHelpModal(false);
          else if (component === "myOtherProductsRef") setOpenOtherProductsModal(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, component]);
  }

  useOutsideClicker(myAccountRef, "myAccountRef");
  useOutsideClicker(mySolutionsRef, "mySolutionsRef");
  useOutsideClicker(myHelpRef, "myHelpRef");
  useOutsideClicker(myOtherProductsRef, "myOtherProductsRef");

  function removeClass() {
    // var element = document.getElementById("navbarNav");
    // element?.classList?.remove("show");
  }

  const handleLogout = async () => {
    console.log("User logged out");
    setShowMenu(false);
    try {
      Cookies.remove("elevadeToken");
      localStorage.removeItem("avatar");
      localStorage.removeItem("inboxMsg");
      localStorage.removeItem("prevURLNewTab");
      dispatch(logOut());
      dispatch(clearUserDetail());
      if (location.pathname === "/fleet") {
        window.location.reload();
      } else {
        // history.push("/");
        window.location.replace("/fleet");
      }
    } catch (error) {
      console.info("Error during logout", error);
    }
    removeClass();
  };

  const StyledButton = styled(MUIButton)(() => ({
    outline: "none",
    padding: "11px 24px",
    backgroundColor: "#088FD1",
    borderRadius: "8px",
    textTransform: "none",
    "&&:focus": {
      outline: "none",
    },
    boxShadow: "none",
    border: "0px solid #D1D1D1",
    marginTop: "-4px",
    whiteSpace: "nowrap",
  }));

  function toggleModal() {
    if (modalIsOpen) closeModal();
    else openModal();
  }

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const LoginButton = () => {
    return isWeb ? (
      <div style={{ marginTop: "4px", position: "relative" }}>
        <StyledButton
          variant="contained"
          // endIcon={openLogin ? <KeyboardArrowUpOutlinedIcon /> : <KeyboardArrowDownOutlinedIcon />}
          onClick={() => {
            // setOpenLogin(!openLogin);
            setOpenSolutions(false);
            window.location.href = process.env.REACT_APP_AUTH_GS_URL;
          }}
        >
          Login
        </StyledButton>
        <ClickAwayListener onClickAway={() => setOpenLogin(false)}>
          <Fade in={openLogin}>
            <div
              style={{
                position: "absolute",
                top: "56px",
                right: "0px",
                minHeight: "60px",
                minWidth: "200px",
                border: "1px solid #E8E8E8",
                boxShadow: "4px 8px 24px 0px rgba(57, 68, 88, 0.08)",
                backgroundColor: "#ffffff",
                borderRadius: "8px",
                color: "#333333",
                fontSize: "14px",
                overflow: "hidden",
              }}
              className={styles.loginMenu}
            >
              <div
                className={styles.menuLogin}
                onClick={() => {
                  toggleModal();
                  setOpenLogin(false);
                  window.location.href = process.env.REACT_APP_AUTH_GS_URL;
                }}
              >
                <img src={IconLoginFleet} alt="icon-fleet" style={{ marginRight: "8px" }} />
                ELEVADE FLEET
              </div>
              <a href={AUTH_GS_PEOPLE_URL} rel="noopener noreferrer">
                <div
                  className={styles.menuLogin}
                  onClick={() => {
                    toggleModal();
                    setOpenLogin(false);
                  }}
                >
                  <img src={IconLoginPeople} alt="icon-fleet" style={{ marginRight: "8px" }} />
                  ELEVADE PEOPLE
                </div>
              </a>
            </div>
          </Fade>
        </ClickAwayListener>
      </div>
    ) : (
      <>
        <StyledButton
          variant="contained"
          endIcon={openLogin ? <KeyboardArrowUpOutlinedIcon /> : <KeyboardArrowDownOutlinedIcon />}
          onClick={() => {
            setOpenLogin(!openLogin);
            setOpenSolutions(false);
          }}
          sx={{ marginTop: "1px" }}
        >
          Login to
        </StyledButton>
        <ClickAwayListener onClickAway={() => setOpenLogin(false)}>
          <Fade in={openLogin}>
            <div
              style={{
                position: "absolute",
                top: "56px",
                right: "0px",
                minHeight: "60px",
                minWidth: "200px",
                border: "1px solid #E8E8E8",
                boxShadow: "4px 8px 24px 0px rgba(57, 68, 88, 0.08)",
                backgroundColor: "#ffffff",
                borderRadius: "8px",
                color: "#333333",
                fontSize: "14px",
                overflow: "hidden",
              }}
              className={styles.loginMenu}
            >
              <div
                className={styles.menuLogin}
                onClick={() => {
                  toggleModal();
                  setOpenLogin(false);
                  window.location.href = process.env.REACT_APP_AUTH_GS_URL;
                }}
              >
                <img src={IconLoginFleet} alt="icon-fleet" style={{ marginRight: "8px" }} />
                ELEVADE FLEET
              </div>
              <a href={AUTH_GS_PEOPLE_URL} rel="noopener noreferrer">
                <div
                  className={styles.menuLogin}
                  onClick={() => {
                    toggleModal();
                    setOpenLogin(false);
                  }}
                >
                  <img src={IconLoginPeople} alt="icon-fleet" style={{ marginRight: "8px" }} />
                  ELEVADE PEOPLE
                </div>
              </a>
            </div>
          </Fade>
        </ClickAwayListener>
        {/* <img
          src={!closeIcon ? HamburgerMenu : CloseMenu}
          style={{ maxHeight: "20px", marginLeft: "10px", marginRight: "-20px", marginTop: "14px" }}
          alt=""
          onClick={() => {
            setShowSolutionsMenu(true);
          }}
        /> */}
      </>
    );
  };

  const HelpModal = () => {
    return (
      openHelpModal && (
        <>
          <div className={`${styles.MyHelpMenu}`} ref={myHelpRef}>
            <div className={styles.MyHelpMenuContainer}>
              <div
                className={styles.MyHelpMenuList}
                onClick={() => {
                  setOpenLegend(true);
                  setOpenHelpModal(false);
                }}
              >
                <div className={styles.MyHelpMenuOptions} style={{ marginTop: "0px" }}>
                  Show Legend
                </div>
              </div>
              <div
                className={styles.MyHelpMenuList}
                style={{
                  background: "#fff",
                  color: "#D1D1D1",
                  fontFamily: "Gotham-Book",
                  cursor: "default",
                }}
              >
                <div className={styles.MyHelpMenuOptions} style={{ color: "#D1D1D1" }}>
                  ELEVADE Training Video
                </div>
              </div>
            </div>
          </div>
        </>
      )
    );
  };
  const OtherProductsModal = () => {
    return (
      openOtherProductsModal && (
        <>
          <div className={`${styles.MyOtherProductsMenu}`} ref={myOtherProductsRef}>
            <div className={styles.MyOtherProductsHeader}>OTHER PRODUCTS</div>
            <Grid container columns={2} sx={{ mt: -2 }}>
              <Grid item xs={1}>
                <a
                  href="https://ade.aero/aerotrade/marketplace"
                  onClick={() => {
                    removeClass();
                    handleClickAway();
                    window.location.href = "https://ade.aero/aerotrade/marketplace";
                  }}
                  target="_blank"
                  rel="noopener noreferrer" // Recommended for security
                  className={styles.MyOtherProductsMenuOptions}
                >
                  <div>
                    <img src={Cart} alt="Cart" />
                  </div>
                  <div className={styles.MyOtherProductsText}>AEROTRADE</div>
                </a>
              </Grid>

              <Grid item xs={1}>
                <div className={styles.MyOtherProductsMenuOptions}>
                  <div>
                    <img src={Boost} alt="Infinity" />
                  </div>
                  <div className={styles.MyOtherProductsText} style={{ color: "#D1D1D1" }}>
                    BOOST
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </>
      )
    );
  };

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return (
      name && {
        sx: {
          bgcolor: stringToColor(name),
        },
        children: name.includes(" ")
          ? `${name?.split(" ")[0][0]}${name?.split(" ")[1][0]}`
          : `${name?.split("")[0][0]}`,
      }
    );
  }

  const LoggedInLinks = () => {
    function handleChange(e) {
      let selectedOption = { label: e, value: e };
      dispatch(setAocToggle(selectedOption));
    }

    return (
      <>
        {isWeb ? (
          <>
            {location.pathname.startsWith("/fleet") && location.pathname !== "/fleet" ? <></> : <HomepageMenuList />}
            <div className={`${styles.loggedInMenu}`}>
              {(location.pathname.startsWith("/fleet/dasr") ||
                location.pathname.startsWith("/fleet/schedule-task")) && (
                <>
                  <div style={{ color: "#333333", width: "200px", marginRight: "32px", position: "relative" }}>
                    <AdeSelect options={aoclist} selected={aocToggle} title={"Operator"} handleChange={handleChange} />
                  </div>
                </>
              )}
              {location.pathname.startsWith("/fleet") && location?.pathname !== "/fleet" && (
                <>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setOpenHelpModal(!openHelpModal);
                      setShowMenu(false);
                      setOpenOtherProductsModal(false);
                    }}
                  >
                    <div>
                      <img
                        src={Help}
                        style={{
                          maxHeight: "20px",
                          fill: "white",
                          marginRight: "34px",
                        }}
                        alt="headset"
                      />
                    </div>
                  </span>

                  <HelpModal />
                  <OtherProductsModal />
                </>
              )}
              <span style={{ cursor: "pointer" }} onClick={() => setShowMenu(!showMenu)}>
                <MUIAvatar
                  name={displayName}
                  width="40px"
                  height="40px"
                  fontSize="16px"
                  src={avatar}
                  marginTop="-8px"
                />
              </span>
              <MyAccountLink />
            </div>
          </>
        ) : (
          <>
            <img
              src={!closeIcon ? HamburgerMenu : CloseMenu}
              style={{
                maxHeight: "20px",
                marginLeft: "10px",
                marginTop: "14px",
                marginRight: "-40px",
                cursor: "pointer",
              }}
              alt=""
              onClick={() => {
                setShowMenu(true);
              }}
            />
            <MyAccountLink />
          </>
        )}
        <Resources loggedIn={true} />
      </>
    );
  };

  const MyAccountLink = () => {
    return (
      showMenu && (
        <>
          <div className={`${styles.MyAccountMenu}`} ref={myAccountRef} style={{ right: !isWeb ? "20px" : "" }}>
            <Grid container columns={2}>
              <Grid item xs={1}>
                <div className={styles.MyOtherProductsMenuOptions}>
                  <div>
                    <img src={DarkMode} alt="DarkMode" />
                  </div>
                  <div className={styles.MyOtherProductsText} style={{ color: "#D1D1D1" }}>
                    Dark Mode
                  </div>
                </div>
              </Grid>

              <Grid item xs={1}>
                <NavLink
                  to="#"
                  onClick={() => {
                    removeClass();
                    handleClickAway();
                    handleLogout();
                    setOpenHelpModal(false);
                    setOpenOtherProductsModal(false);
                    window.localStorage.removeItem("LOPA_KEY_STORED_AL_AM");
                  }}
                  className={styles.MyOtherProductsMenuOptions}
                >
                  <div>
                    <img src={People} alt="Logout" />
                  </div>
                  <div className={styles.MyOtherProductsText}>Logout</div>
                </NavLink>
              </Grid>
            </Grid>
          </div>
        </>
      )
    );
  };

  const Resources = () => {
    return (
      <ClickAwayListener onClickAway={handleClickAway}>
        <Fade in={openSolutions}>
          <div
            style={{
              position: "fixed",
              top: "60px",
              left: "0px",
              width: "100%",
              backgroundColor: "#ffffff",
              boxShadow: "0px 8px 19px 0px rgba(0, 0, 0, 0.20)",
            }}
            className={styles.solutionsMenu}
          >
            <>
              <Grid container columns={2} sx={{ height: "100%", padding: "0px 24px" }}>
                <Grid item xs={1.1} sx={{ padding: "24px 0px" }}>
                  <span className={styles.modalHeader}>MODULES</span>
                  <Grid container columns={3}>
                    <Grid item xs={2} sm={2} md={2} lg={1} xl={1}>
                      <NavLink
                        to="/fleet"
                        onClick={() => {
                          removeClass();
                          handleClickAway();
                        }}
                      >
                        <Grid container columns={2} sx={{ p: 2, pl: 0 }}>
                          <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.3} xl={0.3}>
                            <img src={Airplane} alt="Airplane" />
                          </Grid>
                          <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.7} xl={1.7}>
                            <Grid className={styles.title}>FLEET</Grid>
                            <Grid className={styles.textSmall}>Aircraft monitoring and predictive maintenance</Grid>
                          </Grid>
                        </Grid>
                      </NavLink>
                    </Grid>
                    <Grid item xs={2} sm={2} md={2} lg={1} xl={1}>
                      <a
                        href="https://airasia.elevade.io/people"
                        target="_blank"
                        onClick={() => {
                          removeClass();
                          handleClickAway();
                        }}
                        rel="noopener noreferrer" // Recommended for security
                      >
                        <Grid container columns={2} sx={{ p: 2, pl: 0 }}>
                          <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.3} xl={0.3}>
                            <img src={People} alt="People" />
                          </Grid>
                          <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.7} xl={1.7}>
                            <Grid className={styles.title}>PEOPLE</Grid>
                            <Grid className={styles.textSmall}>People & productivity management</Grid>
                          </Grid>
                        </Grid>
                      </a>
                    </Grid>
                    {/* <Grid item xs={2} sm={2} md={2} lg={1} xl={1}>
                    <Grid container columns={2} sx={{ p: 2, pl: 0 }}>
                      <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.3} xl={0.3} className={styles.disable}>
                        <img src={Document} alt="Document" />
                      </Grid>
                      <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.7} xl={1.7} className={styles.disable}>
                        <Grid className={styles.title}>DOCUMENT</Grid>
                        <Grid className={styles.textSmall}>Aircraft digital technical records and documentation</Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={1} xl={1}>
                    <Grid container columns={2} sx={{ p: 2, pl: 0 }}>
                      <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.3} xl={0.3} className={styles.disable}>
                        <img src={Learning} alt="Learning" />
                      </Grid>
                      <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.7} xl={1.7} className={styles.disable}>
                        <Grid className={styles.title}>LEARNING</Grid>
                        <Grid className={styles.textSmall}>E-Learning and content creation platform</Grid>
                      </Grid>
                    </Grid>
                  </Grid> */}
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={0.9}
                  sx={{
                    borderLeft: "1px solid #F1F1F1",
                    height: "100%",
                    padding: "24px 0px 24px 84px",
                  }}
                >
                  <span className={styles.modalHeader}>OTHER PRODUCTS/LINKS</span>
                  <Grid container columns={2}>
                    <Grid item xs={2} sm={2} md={2} lg={1} xl={1}>
                      <a
                        href="https://ade.aero/aerotrade/marketplace"
                        target="_blank"
                        onClick={() => {
                          removeClass();
                          handleClickAway();
                          // window.location.href = "https://ade.aero/aerotrade/marketplace";
                        }}
                        // target="_blank"
                        rel="noopener noreferrer" // Recommended for security
                      >
                        <Grid container columns={2} sx={{ p: 2, pl: 0 }}>
                          <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.3} xl={0.3}>
                            <img src={Cart} alt="Cart" />
                          </Grid>
                          <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.7} xl={1.7}>
                            <Grid className={styles.title}>AEROTRADE</Grid>
                            <Grid className={styles.textSmall}>B2B aviation marketplace</Grid>
                          </Grid>
                        </Grid>
                      </a>
                    </Grid>
                    <Grid item xs={2} sm={2} md={2} lg={1} xl={1}>
                      <a
                        href="https://ade.aero"
                        target="_blank"
                        onClick={() => {
                          removeClass();
                          handleClickAway();
                          // window.location.href = "https://ade.aero";
                        }}
                        // target="_blank"
                        rel="noopener noreferrer" // Recommended for security
                      >
                        <Grid container columns={2} sx={{ p: 2, pl: 0 }}>
                          <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.3} xl={0.3}>
                            <img src={IconADE} alt="People" />
                          </Grid>
                          <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.7} xl={1.7}>
                            <Grid className={styles.title}>ADE.AERO</Grid>
                            <Grid className={styles.textSmall}>ADE corporate website</Grid>
                          </Grid>
                        </Grid>
                      </a>
                    </Grid>
                    {/* <Grid item xs={2} sm={2} md={2} lg={1} xl={1}>
                    <Grid container columns={2} sx={{ p: 2, pl: 0 }}>
                      <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.3} xl={0.3} className={styles.disable}>
                        <img src={Document} alt="Document" />
                      </Grid>
                      <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.7} xl={1.7} className={styles.disable}>
                        <Grid className={styles.title}>BOOST</Grid>
                        <Grid className={styles.textSmall}>Organization financial application tracking</Grid>
                      </Grid>
                    </Grid>
                  </Grid> */}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                columns={12}
                sx={{
                  borderTop: "1px solid #F1F1F1",
                  pb: 3,
                  // pt: 2,
                  mt: -1.5,
                  display: "flex",
                  alignSelf: "start",
                  px: 2,
                }}
              >
                <Grid item xs={0.4} lg={0.13} sx={{ mt: 2 }}>
                  <img src={ContactUs} alt="ContactUs" />
                </Grid>
                <Grid item sx={{ mt: 2, pl: 3.6 }}>
                  <Grid className={styles.title}>
                    <Link
                      to="#"
                      onClick={(e) => {
                        window.location.href = email;
                        e.preventDefault();
                      }}
                      style={{ color: "#394458" }}
                    >
                      Contact Us
                    </Link>
                  </Grid>
                  <Link
                    to="#"
                    onClick={(e) => {
                      window.location.href = email;
                      e.preventDefault();
                    }}
                    style={{ color: "#394458" }}
                  >
                    <Grid className={styles.textSmall}>Get in touch with our sales team for any enquiries</Grid>
                  </Link>
                </Grid>
              </Grid>
            </>
          </div>
        </Fade>
      </ClickAwayListener>
    );
  };

  const handleClickAway = () => {
    setOpenSolutions(false);
  };

  const HomepageMenuList = () => {
    return (
      <>
        <div
          className={`${styles.loggedInMenu} ${styles.loggedInMenuBold}`}
          style={{ display: location.pathname === "/login" || !isWeb ? "none" : "", color: "#394458" }}
        >
          {location.pathname.startsWith("/settings") ? (
            <></>
          ) : (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                setOpenSolutions(!openSolutions);
                setShowSolutionsMenu(!showSolutionsMenu);
                setOpenLogin(false);
              }}
            >
              Solutions
              <img
                src={IconArrowUpGreen}
                alt="icon-arrow-up"
                height={12}
                style={{
                  transform: openSolutions ? "" : "rotate(180deg)",
                  marginLeft: "8px",
                  marginTop: openSolutions ? "-4px" : "",
                }}
              />
            </div>
          )}
        </div>
        <div
          className={`${styles.loggedInMenu}`}
          style={{ color: "#394458", marginRight: "20px", display: !isWeb ? "none" : "inline" }}
        >
          <Link
            to="#"
            onClick={(e) => {
              window.location.href = email;
              e.preventDefault();
            }}
            style={{ color: "#394458" }}
          >
            Contact
          </Link>
        </div>
        {!userAuthEntity.userName && <Resources loggedIn={false} />}
        {/* {<Resources />} */}
      </>
    );
  };
  const LoggedOutLinks = () => {
    return (
      <>
        <div
          style={{
            position: "relative",
            display: "flex",
            paddingRight: isWeb ? "0px" : "0px",
            direction: "row",
            gap: "24px",
            marginRight: !isWeb ? "-20px" : "0px",
          }}
        >
          <HomepageMenuList />
          <LoginButton />
        </div>
      </>
    );
  };

  const useStyles = makeStyles(() => ({
    root: {
      zIndex: 1999,
      backgroundColor: "transparent",
      backdropFilter: "blur(25px)",
      boxShadow: "none",
      marginTop: "-70px",
    },
  }));

  const classes = useStyles();

  const LogoElevade = () => {
    return (
      <img
        src={ElevadeFleet}
        className={`${styles.adelogo}`}
        style={{
          maxHeight: "50px",
          marginLeft: "0px",
        }}
        alt="logo"
      />
    );
  };

  return (
    <>
      {location.pathname.startsWith("/fleet/callback") || location.pathname.startsWith("/callback") ? (
        <AppBar position="sticky" className={classes.root}>
          <div className={styles.headerCallback}></div>
        </AppBar>
      ) : (
        <>
          <AppBar position="sticky" className={classes.root}>
            <div className={styles.header}>
              <Grid container columns={12} sx={{ border: "0px solid red" }}>
                {isWeb && (
                  <Grid
                    item
                    xs={!userAuthEntity.userName ? 0 : 2}
                    sm={
                      !userAuthEntity.userName
                        ? 0
                        : location?.pathname?.startsWith("/fleet") && location?.pathname !== "/fleet"
                        ? 1
                        : 0.05
                    }
                    md={
                      !userAuthEntity.userName
                        ? 0
                        : location?.pathname?.startsWith("/fleet") && location?.pathname !== "/fleet"
                        ? 1
                        : 0.05
                    }
                    lg={
                      !userAuthEntity.userName
                        ? 0
                        : location?.pathname?.startsWith("/fleet") && location?.pathname !== "/fleet"
                        ? 0.75
                        : 0.05
                    }
                    xl={
                      !userAuthEntity.userName
                        ? 0
                        : location?.pathname?.startsWith("/fleet") && location?.pathname !== "/fleet"
                        ? 0.5
                        : 0
                    }
                  >
                    {userAuthEntity.userName &&
                      location?.pathname?.startsWith("/fleet") &&
                      location?.pathname !== "/fleet" && (
                        <div
                          style={{
                            position: "relative",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Avatar
                            className="avatar-dashboard"
                            style={{ marginTop: "6px", cursor: "pointer" }}
                            round={true}
                            src={hamburgerIcon}
                            size={36}
                            onClick={() => {
                              dispatch(setSidebarToggle(!sidebarToggle));
                            }}
                          ></Avatar>
                        </div>
                      )}
                  </Grid>
                )}
                <Grid
                  item
                  xs={userAuthEntity.userName ? 7 : 8}
                  sm={
                    userAuthEntity.userName &&
                    location?.pathname?.startsWith("/fleet") &&
                    location?.pathname !== "/fleet"
                      ? 7
                      : 7.95
                  }
                  md={
                    userAuthEntity.userName &&
                    location?.pathname?.startsWith("/fleet") &&
                    location?.pathname !== "/fleet"
                      ? 5
                      : 5.95
                  }
                  lg={
                    userAuthEntity.userName &&
                    location?.pathname?.startsWith("/fleet") &&
                    location?.pathname !== "/fleet"
                      ? 4
                      : !userAuthEntity.userName
                      ? 4.5
                      : 4
                  }
                  xl={
                    userAuthEntity.userName &&
                    location?.pathname?.startsWith("/fleet") &&
                    location?.pathname !== "/fleet"
                      ? 5.5
                      : 5.95
                  }
                  sx={{ paddingTop: "4px", border: "0px solid red" }}
                >
                  <Link to="/fleet" className="navbar-brand" style={{ marginLeft: isWeb ? "24px" : "0px" }}>
                    <LogoElevade />
                  </Link>
                </Grid>
                <Grid
                  item
                  xs={3} // 3
                  sm={3} // 3
                  md={6} // 6
                  lg={
                    !userAuthEntity.userName
                      ? 7.25
                      : location?.pathname?.startsWith("/fleet") && location?.pathname !== "/fleet"
                      ? 7.25
                      : 7.95
                  }
                  xl={!userAuthEntity.userName ? 6 : 6}
                  sx={{ display: "flex", justifyContent: "flex-end", border: "0px solid red" }}
                >
                  {!cookies || (!cookies && !userAuthEntity?.token) ? <LoggedOutLinks /> : <LoggedInLinks />}
                </Grid>
              </Grid>
            </div>
          </AppBar>
          <Legend openLegend={openLegend} setOpenLegend={setOpenLegend} />
        </>
      )}
    </>
  );
};

export default ElevadeFleetHeader;
